import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export enum ButtonType {
  Basic,
  Raised,
  Stroked,
  Flat
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input()
  public name: string;

  @Input()
  public tooltip: string;

  @Input()
  public type = ButtonType.Basic;

  @Input()
  public color = 'basic';

  @Input()
  public disabled = false;

  @Input()
  public loading = false;

  @Input()
  public count = 0;

  @Input()
  public countAlign: 'center' | 'top' = 'top';

  @Output()
  public action = new EventEmitter<MouseEvent>();

  public Buttontype = ButtonType;
}
