<ng-container [formGroup]="form">
    <div class="label-container">
        <label>{{displayName | translate}} </label>
        <ng-container *ngIf="form.get(name).value as value">
            <span *ngIf="values">{{valuePrefix ? (valuePrefix + values.values[value] | lowercase | translate) : (values.values[value])}}</span>
            <span *ngIf="!values">{{value}}</span>
        </ng-container>
    </div>
    <div class="slider-container">
        <label class="min" *ngIf="rangeLabel && values">{{valuePrefix ? (valuePrefix + values.values[min] | lowercase | translate) : (values.values[min])}}</label>        
        <label class="min" *ngIf="rangeLabel && !values">{{min}}</label>
        <!-- NOTE: removed thumbLabel -->
        <mat-slider class="slider" [showTickMarks]="showTicks" 
                    [discrete]="discrete" [min]="min" [max]="max" [step]="step">
            <input matSliderThumb [formControlName]="name">
        </mat-slider>
        <label class="max" *ngIf="rangeLabel && values">{{valuePrefix ? (valuePrefix + values.values[max] | lowercase | translate) : (values.values[max])}}</label>
        <label class="max" *ngIf="rangeLabel && !values">{{max}}</label>
    </div>
</ng-container>