import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileData } from '@app/class/file-data';
import { CaptureDialogComponent, CaptureDialogSettings } from '@shared/component/popup-modal/capture-dialog/capture-dialog.component';
import { Assert } from '@shared/helper/assert';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CaptureDialogService {
    constructor(private readonly matDialog: MatDialog) {
        Assert.notNullOrUndefined(matDialog, 'matDialog');
    }

    public captureImage(title: string, skipSave = false): Observable<FileData<ArrayBuffer>[]> {
        Assert.notNullOrUndefined(title, 'title');

        const settings: CaptureDialogSettings = { title, skipSave };

        return this.matDialog.open<CaptureDialogComponent, CaptureDialogSettings, Observable<FileData<ArrayBuffer>[]>>(CaptureDialogComponent, {
            data: settings
        }).afterClosed().pipe(
            flatMap(files => files ? files : of(null))
        );
    }
}
