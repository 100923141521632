<mat-form-field class="input-form-field">
  <input matInput #filter/>
  <ng-container matSuffix>
    <button mat-button (click)="addClicked.emit(filter.value)" [disabled]="filter.value.length === 0">
      <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
    </button>
  </ng-container>
</mat-form-field>

<ng-container *ngFor="let umfang of umfaenge.controls; let index = index">
  <app-accordion [multi]="true">
    <app-expansion-panel>
      <app-expansion-header>
        <app-panel-title class="title">
          <strong>{{umfang.value.obergruppe}}</strong>
        </app-panel-title>
      </app-expansion-header>
      <app-textbausteine [form]="umfang" [name]="'beschreibung'" [displayName]="'Textbausteine'"
                         [feature]="umfang.value.obergruppeName" [produktArt]="this.produktArt.Zustandsbericht">
      </app-textbausteine>
      <di class="button-container">
        <button mat-button (click)="onDelete(index)">
          <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
        </button>
      </di>
    </app-expansion-panel>
  </app-accordion>
</ng-container>

<!-- Delete dialog -->
<ng-template #dialogDelete>
  <app-delete-dialog>
  </app-delete-dialog>
</ng-template>

