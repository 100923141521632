import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { guid } from '@app/function/guid';
import { ProduktArt } from '@data/domain/schema/enum';
import { Abrechnung, Ausstattung, Produkt } from '@data/domain/schema/type';
import { ProduktAbrechnungService } from '@data/domain/service/feature/produkt-abrechnung.service';
import { ProduktAusstattungService } from '@data/domain/service/feature/produkt-ausstattung.service';
import { ProduktFahrzeugService } from '@data/domain/service/feature/produkt-fahrzeug.service';
import { FeatureFields, ProduktFeatureFieldsMap, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailFeatureFormViewProvider } from '@modules/produkt/provider/produkt-detail-feature-form-view.provider';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureValidService } from '@modules/produkt/service/produkt-detail-feature-valid.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ProduktDetailFeatureDialogData {
  required: ProduktFeatureFieldsMap;
}

interface ProduktDetailFeatureDialogForm {
  form: ViewFormGroup;
  name: string;
  fields: FeatureFields;
  visibleFields: FeatureFields;
}

@Component({
  selector: 'app-produkt-detail-feature-dialog',
  templateUrl: './produkt-detail-feature-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailFeatureDialogComponent implements OnInit {
  private produkt: Produkt;

  public trackByField = TrackBy.trackByField;

  public data: ProduktDetailFeatureDialogData;
  public forms: ProduktDetailFeatureDialogForm[] = [];

  constructor(
    private readonly produktDetailFeatureFormViewProvider: ProduktDetailFeatureFormViewProvider,
    private readonly produktDetailFeatureValidService: ProduktDetailFeatureValidService,
    private readonly produktConfigResolveService: ProduktConfigResolveService,
    private readonly produktDetailResolveService: ProduktDetailResolveService,
    private readonly produktFahrzeugService: ProduktFahrzeugService,
    private readonly produktAusstattungService: ProduktAusstattungService,
    private readonly produktAbrechnungService: ProduktAbrechnungService) {
    Assert.notNullOrUndefined(produktDetailFeatureFormViewProvider, 'produktDetailFeatureFormViewProvider');
    Assert.notNullOrUndefined(produktDetailFeatureValidService, 'produktDetailFeatureValidService');
    Assert.notNullOrUndefined(produktConfigResolveService, 'produktConfigResolveService');
    Assert.notNullOrUndefined(produktDetailResolveService, 'produktDetailResolveService');
    Assert.notNullOrUndefined(produktFahrzeugService, 'produktFahrzeugService');
    Assert.notNullOrUndefined(produktAusstattungService, 'produktAusstattungService');
    Assert.notNullOrUndefined(produktAbrechnungService, 'produktAbrechnungService');

  }

  public ngOnInit(): void {
    this.produkt = this.produktDetailResolveService.get();
    this.createForms();
  }

  public isVtiTooling(): boolean {
    if (!this.produkt) {
      return false;
    }

    if (this.produkt.art === ProduktArt.VtiTooling) {
      return true;
    } else {
      return false;
    }
  }

  public onAction(action: string): Observable<boolean> {
    if (action === 'feature.save') {
      return this.saveForms();
    }
    return of(true);
  }

  public onAusstattungResolved(ausstattung: Ausstattung): void {
    Assert.notNullOrUndefined(ausstattung, 'ausstattung');
    // TODO: ???
    ausstattung.gruppen.push(this.produktAusstattungService.getErfassteAusstattung(this.produkt));
    this.produktAusstattungService.save(this.produkt.id, ausstattung);
  }

  public onAbrechnungResolved(abrechnung: Abrechnung): void {
    Assert.notNullOrUndefined(abrechnung, 'abrechnung');
    const abrechnungId = guid();
    this.produktAbrechnungService.saveAbrechnung(this.produkt.art, this.produkt.id, abrechnungId, abrechnung);
  }

  private createForms(): void {
    const config = this.produktConfigResolveService.get();

    const required = this.data.required;
    Object.keys(required).forEach(featureName => {
      if (this.produktDetailFeatureValidService.isFeatureValid(this.produkt, featureName, required[featureName])) {
        return;
      }
      const fields = config.features.find(x => x.name === featureName).fields;
      const form = this.produktDetailFeatureFormViewProvider.provide(this.produkt, featureName, fields);
      form.markAllAsTouched();
      this.forms.push({
        form,
        name: featureName,
        fields,
        visibleFields: required[featureName]
      });
    });
  }

  private saveForms(): Observable<boolean> {
    const produktId = this.produkt.id;

    const saveForms$ = this.forms.map(form => {
      if ( form.name === PRODUKT_CONFIG_FEATURES.Fahrzeug.name ) {
        return this.produktFahrzeugService.save(produktId, form.form.getRawValue());
      } else {
        throw new Error(`no service registered for '${form.name}'.`);
      }
    });

    return forkJoin(saveForms$).pipe(map(() => true));
  }
}
