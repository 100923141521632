import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-action-row',
  templateUrl: './action-row.component.html',
  styleUrls: ['./action-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionRowComponent {
}
