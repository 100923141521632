import { Clipboard } from '@angular/cdk/clipboard';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { UntypedFormGroup } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AuthService, AuthUserClaims } from '@app/service/auth.service';
import { KalkulationDownloadService } from '@data/api-gateway/service/kalkulation-download.service';
import { VtiKalkulationService } from '@data/api-gateway/service/vti-kalkulation.service';
import { ProduktArt, SchadenIntensitaet, SchadenObergruppe, SchadenUntergruppe } from '@data/domain/schema/enum';
import { Notiz, Produkt, Schaden, SchadenInput, SchadenPosition } from '@data/domain/schema/type';
import { ProduktSchadenService } from '@data/domain/service/feature';
import {
  ProduktDetailFeatureInputComponent
} from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import {
  FeatureField,
  FeatureFieldArray,
  FeatureFieldGroup,
  FeatureFields,
  PRODUKT_CONFIG_FEATURES
} from '@modules/produkt/config/produkt-config';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { OBERGRUPPE_TO_UNTERGRUPPE_MAP } from '@modules/produkt/config/produkt-schaden-gruppen.config';
import {
  ProduktDetailKalkulationFormViewFactory
} from '@modules/produkt/factory/kalkulation/produkt-detail-kalkulation-form-view.factory';
import { ProduktDetailSchadenFormViewFactory } from '@modules/produkt/factory/schaden/produkt-detail-schaden-form-view.factory';
import { ProduktDetailSchadenPositionFormViewFactory } from '@modules/produkt/factory/schaden/produkt-detail-schaden-position-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { DatCalculateProService } from '@modules/produkt/service/dat-calculate-pro-service';
import { ModelFileService } from '@modules/produkt/service/model-file.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { TableRowMoveEvent } from '@shared/component/data-table';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ArrayValues, EnumValues, ObjectValues, Values } from '@shared/helper/values';
import { DisplayService } from '@shared/service/display.service';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateButtonDisableEvent, TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { filter, first, flatMap, map, startWith } from 'rxjs/operators';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';

export enum SchadenGruppenDisplay {
  Umfang = 0,
  Exterieur = 1,
  Interieur = 2,
  Technik = 3,
  Manuell = 4,
  Text = 5,
  Daten = 6,
  Kalkulation = 7
}

interface ProduktDetailSchadenDialogData {
  form: ViewFormGroup;
  fields: FeatureFields;
  relativerWert: number;
}

interface ProduktDetailSchadenUntergruppeDialogData {
  form: ViewFormGroup;
  values: Values;
  produkt: Produkt;
}

declare let SphinxClassCP: any;

@Component({
  selector: 'app-produkt-detail-schaden',
  templateUrl: './produkt-detail-schaden.component.html',
  styleUrls: ['./produkt-detail-schaden.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailSchadenComponent extends ProduktDetailFeatureInputComponent<Schaden, SchadenInput> implements OnInit, OnDestroy {
  public claims$: Observable<AuthUserClaims>;
  private positionenName = PRODUKT_CONFIG_FEATURES.Schaden.fields.Positionen.name;

  public trackByField = TrackBy.trackByField;
  public ButtonType = ButtonType;

  public notizen$: Observable<Notiz[]>;
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;
  public mobileLandscapeOptimization: Subscription;

  public positionen: ViewFormArray;
  public positionenFields: FeatureFields;

  public displayEnum = SchadenGruppenDisplay;
  public display$ = new BehaviorSubject(this.displayEnum.Exterieur);
  public kalkulation: UntypedFormGroup;
  public kalkulationFields;
  public sphinx;
  private callbackUrlKalkulationSuccess = '';
  private callbackUrlKalkulationError = '';

  public loading$ = new BehaviorSubject<boolean>(false);
  public hasFahrzeugExternalServiceReference = false;

  public obergruppe = new ObjectValues(SchadenObergruppe);
  public intensitaet = new EnumValues(SchadenIntensitaet);

  public rows$: Observable<AbstractViewFormControl[]>;
  public sums$: Observable<string>;

  public modelFileConfigs: ModelFileConfig[];
  public manuellDisabled$ = new BehaviorSubject<boolean>(false);

  @ViewChild('untergruppe', {static: true})
  public untergruppeTemplate: TemplateRef<any>;

  @ViewChild('dialog', {static: true})
  public dialogTemplate: TemplateRef<any>;

  @ViewChild('dialogKalkulationConfirm', { static: true })
  public dialogKalkulationConfirmTemplate: TemplateRef<any>;

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktSchadenService: ProduktSchadenService,
    private clipboard: Clipboard,
    private readonly auth: AuthService,
    private readonly formViewFactory: ProduktDetailSchadenFormViewFactory,
    private readonly positionFormViewFactory: ProduktDetailSchadenPositionFormViewFactory,
    private readonly templateDialogService: TemplateDialogService,
    private readonly datCalculateProService: DatCalculateProService,
    private readonly vtiKalkulationService: VtiKalkulationService,
    private readonly kalkulationDownloadService: KalkulationDownloadService,
    private readonly currencyFormatter: CurrencyFormatterService,
    private readonly produktDetailKalkulationFormViewFactory: ProduktDetailKalkulationFormViewFactory,
    private readonly modelFileService: ModelFileService,
    private readonly snackBarService: SnackBarService,
    private readonly viewportService: ViewportService,
    private readonly displayService: DisplayService,
    private readonly notizenService: ProduktDetailFeatureNotizenService,
    private readonly updateWerteService: UpdateWerteService) {
    super(produktConfigResolveService, produktDetailResolveService, produktSchadenService);
    Assert.notNullOrUndefined(clipboard, 'clipboard');
    Assert.notNullOrUndefined(auth, 'auth');
    Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
    Assert.notNullOrUndefined(positionFormViewFactory, 'positionFormViewFactory');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
    Assert.notNullOrUndefined(datCalculateProService, 'datCalculateProService');
    Assert.notNullOrUndefined(vtiKalkulationService, 'vtiKalkulationService');
    Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    Assert.notNullOrUndefined(modelFileService, 'modelFileService');
    Assert.notNullOrUndefined(produktDetailKalkulationFormViewFactory, 'produktDetailKalkulationFormViewFactory');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
    Assert.notNullOrUndefined(viewportService, 'viewportService');
    Assert.notNullOrUndefined(displayService, 'displayService');
  }

  public ngOnInit(): void {
    this.claims$ = this.auth.getClaims();
    const schadenName = PRODUKT_CONFIG_FEATURES.Schaden.name;
    const nameKalkulation = PRODUKT_CONFIG_FEATURES.Kalkulation.name;
    this.notizen$ = this.notizenService.init(this.produkt, schadenName);
    this.viewport$ = this.viewportService.observe();
    this.modelFileConfigs = this.modelFileService.get(this.produkt.fahrzeug.fahrzeugart, this.produkt.fahrzeug.bauform);
    this.hasFahrzeugExternalServiceReference = !!this.produkt.fahrzeug?.fahrzeugExternalServiceReference?.identifier;
    this.init(schadenName);
    this.initKalkulation(nameKalkulation);
    this.callbackUrlKalkulationSuccess = window.location.href + '/kalkulation/';
    this.callbackUrlKalkulationError = window.location.href + '/kalkulation/error';
    this.sphinx = SphinxClassCP.getInstance();
    this.mobileLandscapeOptimization = this.viewportService.mobileLandscapeOptimization();
  }

  public ngOnDestroy(): void {
    this.mobileLandscapeOptimization.unsubscribe();
  }

  public onDisplayChange(display: SchadenGruppenDisplay): void {
    Assert.notNullOrUndefined(display, 'display');
    this.display$.next(display);
  }

  public onOpenKalkulation(): void {
    if (this.produkt.kalkulation?.dokument) {
      this.display$.next(this.displayEnum.Daten);
      this.vtiKalkulationService.initIframe(this.datCalculateProService, this.snackBarService, document,
        this.sphinx, this.loading$, this.produkt, this.kalkulation, true);
    } else {
      this.openConfirmKalkulationDialog();
    }
  }

  public onObergruppeAction(key: string): void {
    Assert.notNullOrUndefined(key, 'key');
    const obergruppe = SchadenObergruppe[ key ];
    this.createPosition(obergruppe);
  }

  public onObergruppeSelect(value: string): void {
    Assert.notNullOrEmpty(value, 'value');
    const obergruppe = <SchadenObergruppe>ViewFormControlFormatters.firstLetterToUppercase.format(value);
    this.createPosition(obergruppe);
  }

  public onRowOpen(row: ViewFormGroup): void {
    Assert.notNullOrUndefined(row, 'row');
    const index = this.positionen.controls.indexOf(row);
    this.editPosition(index, row.getRawValue());
  }

  public onRowOpenByIndex(index: number, item: any, panel: MatExpansionPanel, $event: MouseEvent): void {
    Assert.notNullOrUndefined(index, 'index');
    panel.close();
    $event.stopPropagation();
    this.editPosition(index, item);
  }

  public onRowRemove(row: ViewFormGroup): void {
    Assert.notNullOrUndefined(row, 'row');
    const index = this.positionen.controls.indexOf(row);
    this.positionen.removeAt(index);
    if (this.positionen.length === 0) {
      this.updateWerteService.resetAufwendungen(this.produkt);
    }
  }

  public onRowRemoveByIndex(index: number, panel: MatExpansionPanel, $event: MouseEvent): void {
    Assert.notNullOrUndefined(index, 'index');
    panel.close();
    $event.stopPropagation();
    this.positionen.removeAt(index);
    if (this.positionen.length === 0) {
      this.updateWerteService.resetAufwendungen(this.produkt);
    }
  }

  public onRowMove(event: TableRowMoveEvent): void {
    Assert.notNullOrUndefined(event, 'event');
    const index = this.positionen.controls.indexOf(event.row);
    this.positionen.controls.splice(index, 1);
    this.positionen.controls.splice(index + event.offset, 0, event.row);
    this.positionen.updateValueAndValidity();
  }

  public onModelLoad(result: ModelLoadResult): void {
    Assert.notNullOrUndefined(result, 'modelLoadStatus');
    if (result === ModelLoadResult.None) {
      this.manuellDisabled$.next(true);
      this.onDisplayChange(SchadenGruppenDisplay.Manuell);
      this.snackBarService.warning('modell.couldNotLoad');
    } else if (result === ModelLoadResult.Fallback) {
      this.snackBarService.info('modell.fallback');
    }
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  public copy(elementId: string): void {
    const inputValue = (<HTMLInputElement>document.getElementById(elementId)).value;
    this.clipboard.copy(inputValue);
    this.snackBarService.success('Wert kopiert: ' + inputValue);
  }

  public downloadKalkulation(): void {
     this.auth.getClaims().pipe(first()).subscribe( claims => {
       if (claims && claims['custom:buero_id']) {
         this.kalkulationDownloadService.download(claims['custom:buero_id'], this.produkt.id).pipe(first()).subscribe(url => {
            if(url) {
              window.open(url);
            } else {
              this.snackBarService.error('schaden.kalkulation.download.error');
            }
           }
         );
       }
     });
  }

  protected createForm(): ViewFormGroup {
    const form = this.formViewFactory.create(this.produkt.schaden, this.fields);
    this.positionen = <ViewFormArray>form.get(this.positionenName);
    this.positionenFields = (<FeatureFieldArray>this.fields
      .find((x: FeatureFieldArray) => x.arrayName === this.positionenName))
      .fields;
    this.rows$ = this.getRows$();
    this.sums$ = this.getSums$();
    return form;
  }

  private getRows$(): Observable<AbstractViewFormControl[]> {
    return this.positionen.valueChanges.pipe(
      startWith({}),
      map(() => <AbstractViewFormControl[]>[...this.positionen.controls])
    );
  }

  private getSums$(): Observable<string> {
    const fields = this.positionenFields.reduce((a, value: FeatureField & FeatureFieldGroup) => {
      if (value.groupName === 'minderwertGroup') {
        return a.concat(value.fields.map(field => field.name));
      }
      if (value.name === 'preis') {
        a.push(value.name);
      }
      return a;
    }, []);

    return this.positionen.valueChanges.pipe(
      startWith({}),
      map(() => {
        const bestaetigtePositionen: ViewFormArray = this.getBestaetigtePositionen();
        const sums = fields.map(field => {
          const sum = bestaetigtePositionen.controls
            .reduce((a, b: ViewFormGroup) => a + b.getRawValue()[field], 0);
          return this.currencyFormatter.format(sum) + ' €';
        });
        return sums.join(' | ');
      })
    );
  }

  private getBestaetigtePositionen(): ViewFormArray {
    const bestaetigtePositionen: ViewFormArray = new ViewFormArray([]);
    if (!this.positionen?.controls) {
      console.warn(`Unable to get bestaetigtePositionen: positionen.controls is undefined.`);
      return bestaetigtePositionen;
    }

    for (const control of this.positionen.controls) {
      const extern = control.get('extern')?.value;
      const bestaetigt = control.get('bestaetigt')?.value;

      if (!extern) {
        bestaetigtePositionen.push(control);
      } else {
        if (bestaetigt) {
          bestaetigtePositionen.push(control);
        }
      }
    }

    return bestaetigtePositionen;
  }

  private createPosition(obergruppe: SchadenObergruppe): void {
    this.selectUntergruppe(obergruppe).pipe(
      filter(value => !!value),
      flatMap(value => this.openPosition(value))
    ).subscribe(position => {
      this.positionen.push(position);
    });
  }

  private editPosition(index: number, position: SchadenPosition): void {
    this.openPosition(position).subscribe(update => {
      (<ViewFormGroup>this.positionen.controls[ index ]) = update;
      this.positionen.updateValueAndValidity();
    });
  }

  private openPosition(position: SchadenPosition): Observable<ViewFormGroup> {
    const title = position.obergruppe === position.untergruppe
      ? position.obergruppe
      : `${position.obergruppe} / ${position.untergruppe}`;
    const fields = this.positionenFields;
    const form = this.positionFormViewFactory.create(position, fields);
    const buttons = [`${this.name}.cancel`, `${this.name}.save`];
    const relativerWert = this.produkt.art === ProduktArt.Ruecknahmebewertung ? this.produkt.werte?.relativerWert : undefined;
    const data: ProduktDetailSchadenDialogData = {form, fields, relativerWert};

    return this.templateDialogService.openTemplate(title, buttons, this.dialogTemplate, data, true).pipe(
      filter(result => result && result.name === buttons[ 1 ]),
      map(result => result.data.form)
    );
  }

  private selectUntergruppe(obergruppe: SchadenObergruppe): Observable<SchadenPosition> {
    const fields = this.positionenFields;
    const form = this.positionFormViewFactory.create({obergruppe}, fields);
    const button = [`${this.name}.cancel`];

    let untergruppen = OBERGRUPPE_TO_UNTERGRUPPE_MAP.get(obergruppe);
    if (!untergruppen) {
      untergruppen = [<any>obergruppe];
    }
    const values = new ArrayValues(<string[]>untergruppen, SchadenUntergruppe);
    const produkt = this.produkt;

    const data: ProduktDetailSchadenUntergruppeDialogData = {form, values, produkt};
    const dialog = this.templateDialogService.open(obergruppe, button, this.untergruppeTemplate, data, true);

    const promise = new Promise<SchadenPosition>((resolve, reject) => {
      let valueChangeSubscription = form.valueChanges.subscribe(
        value => {
          valueChangeSubscription.unsubscribe();
          valueChangeSubscription = null;
          dialog.close();
          resolve(value);
        },
        () => reject()
      );
      dialog.afterClosed().subscribe(
        () => {
          if (valueChangeSubscription) {
            valueChangeSubscription.unsubscribe();
          } else {
            resolve(null);
          }
        },
        () => reject()
      );
    });
    return from(promise);
  }

  private drop(event: CdkDragDrop<string[]>): void {
    const item = this.positionen.at(event.previousIndex);
    this.positionen.controls.splice(event.previousIndex, 1);
    this.positionen.controls.splice(event.currentIndex, 0, item);
    this.positionen.updateValueAndValidity();
  }

  private initKalkulation(name: string) {
    this.kalkulationFields = this.getFeatureByName(name).fields;
    this.kalkulation = this.produktDetailKalkulationFormViewFactory.create(this.produkt.kalkulation, this.kalkulationFields);
  }

  private openConfirmKalkulationDialog(): void {
    const title = `${this.name}.kalkulation.dialog.title`;
    const buttons = [`${this.name}.cancel`, `${this.name}.confirm`];

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogKalkulationConfirmTemplate).subscribe(result => {
      if (result.name && result.name === buttons[1]) {
        this.display$.next(this.displayEnum.Daten);
        this.vtiKalkulationService.initIframe(this.datCalculateProService, this.snackBarService, document,
          this.sphinx, this.loading$, this.produkt, this.kalkulation, true);
      }
    });
  }

  public checkFieldBestaetigt(item: SchadenPosition) {
    if (!item) {
      console.warn('checkFieldBestaetigt() - warning: item is null or undefined.');
    }
    let ret = false;

    if (!item.extern) {
      ret = true;
    } else {
      if (item.bestaetigt) {
        ret = true;
      }
    }

    return ret;
  }

  public changeTemplateButtonDisabled(event: TemplateButtonDisableEvent): void {
    if (!event) {
      return;
    }
    if (event.disable) {
      this.templateDialogService.disableButton(event.index);
    } else {
      this.templateDialogService.enableButton(event.index);
    }
  }
}
