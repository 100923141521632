<ng-container *ngFor="let field of fields;trackBy:trackByField">
    <ng-container [ngSwitch]="field.name || field.arrayName">
        <!-- Dicke -->
        <ng-container *ngSwitchCase="'dicke'">
            <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
                inputmode="numeric">
                <ng-container appSuffix>&nbsp;µm</ng-container>
            </app-input>
        </ng-container>

        <!-- Ergebnis -->
        <ng-container *ngSwitchCase="'ergebnis'">
            <app-extendable-list [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [values]="[]"
                property="bezeichnung" (add)="onErgebnisAdd($event)" (remove)="onErgebnisRemove($event)"
                [property]="null" [inputmode]="'decimal'">
            </app-extendable-list>
        </ng-container>

        <!-- Beschreibung -->
        <ng-container *ngSwitchCase="'beschreibung'">
            <app-textarea [form]="form" [name]="field.name" [displayName]="name + '.' + field.name"> </app-textarea>
        </ng-container>

        <!-- Bemerkungen -->
        <ng-container *ngSwitchCase="'bemerkungen'">
            <app-textbausteine #bemerkungen [form]="form" [name]="field.arrayName" [displayName]="'Bemerkungen'"
            [feature]="'Lackmessung'" [produktArt]="this.produkt.art">
            </app-textbausteine>
        </ng-container>

        <!-- Bilder -->
        <ng-container *ngSwitchCase="'bilder'">
            <label>{{name + '.bilder' | translate}}</label>
            <ng-container *ngIf="form.get('bilder') as control">
                <ng-container *ngIf="((control.valueChanges | async) || control.value) as files">
                    <app-file-gallery [fileIds]="files" [dialogTitle]="name + '.addfile'" [showCamera]="true"
                        [showFolder]="true" [resetable]="isFileResetable.bind(this)" (add)="onFileAdd(control, $event)"
                        (delete)="onFileDelete(control, $event)" (update)="onFileUpdate(control, $event)"
                        (reset)="onFileReset(control, $event)">
                    </app-file-gallery>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
