import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-form-field',
  template:
    '<ng-template #content>' +
      '<ng-content></ng-content>' +
    '</ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldComponent {
  @Input()
  public colspan = 1;

  @ViewChild('content', { static: true })
  public template: TemplateRef<any>;
}
