<div class="main-content">
    <div class="toggle">
        <app-toggle-group [value]="display" (valueChange)="onDisplayChange($event)">
            <app-toggle [value]="1" [disabled]="manuellDisabled$ | async">
                {{'vorschaden.modell' | translate}}
            </app-toggle>
            <app-toggle [value]="2">
                {{'vorschaden.manuell' | translate}}
            </app-toggle>
        </app-toggle-group>
    </div>

    <ng-container [ngSwitch]="display">
        <app-produkt-detail-vorschaden-scene *ngSwitchCase="1" [modelFileConfigs]="modelFileConfigs" (obergruppeSelect)="onObergruppeSelect($event)" (modelLoad)="onModelLoad($event)" [activeObergruppen]="activeObergruppen$ | async">
        </app-produkt-detail-vorschaden-scene>
        <app-card *ngSwitchCase="2">
            <app-action-list [values]="obergruppenManuell" (action)="onObergruppeAction($event)" (submit)="onObergruppeSelect($event)" [searchable]="true">
            </app-action-list>
        </app-card>
    </ng-container>
</div>

<!--side panel-->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop">
    <h4>{{('vorschaden.vorschaeden' | translate) + '&nbsp;(' + positionen.controls.length + ')&nbsp;'}}</h4>
    <ng-container *ngTemplateOutlet="vorschadenKosten"></ng-container>
</app-side-panel>

<!--bottom sheet-->
<app-bottom-sheet *ngIf="(viewport$ | async) !== viewport.Desktop"
    [title]="('vorschaden.vorschaeden' | translate) + '&nbsp;(' + positionen.controls.length + ')&nbsp;'"
    [value]="(sums$ | async)"
    [seamless]="true">
    <ng-container *ngTemplateOutlet="vorschadenKosten"></ng-container>
</app-bottom-sheet>

<!--notizen button-->
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)">
</app-produkt-detail-notizen-button>



<!--templates-->
<ng-template #vorschadenKosten>
    <!--header-->
    <div class="header">
        <div class="group-header">
            <label>{{(name + '.' + 'obergruppe') | translate}}</label>
            <label>{{(name + '.' + 'untergruppe') | translate}}</label>
        </div>
        <div class="group-header">
            <label>{{(name + '.' + 'art') | translate}}</label>
            <label>{{(name + '.' + 'reparatur') | translate}}</label>
        </div>
        <div class="group-header">
            <label>{{(name + '.' + 'schadendatum') | translate}}</label>
            <label>{{(name + '.' + 'bilder') | translate}}</label>
        </div>
        <ng-container *ngFor="let field of positionenFields;trackBy:trackByField">
            <ng-container [ngSwitch]="field.name || field.groupName">
                <ng-container *ngSwitchCase="'wertminderungGroup'">
                    <div class="group-header align-right">
                        <label>{{(name + '.' + 'schadenhoehe') | translate}}</label>
                        <label>{{(name + '.' + 'wertminderung') | translate}}</label>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <!--header of expansion panel-->
    <div class="drop-list" cdkDropList [class.mobile]="(viewport$ | async) !== viewport.Desktop" (cdkDropListDropped)="drop($event)">
            <mat-expansion-panel #panel="matExpansionPanel" cdkDrag hideToggle togglePosition="before" *ngFor="let item of positionen.getRawValue() ;index as index; trackBy:trackByField">
                <mat-expansion-panel-header>

                    <ng-container *ngFor="let field of positionenFields;trackBy:trackByField">
                        <ng-container [ngSwitch]="field.name || field.groupName">
                            <ng-container *ngSwitchCase="'obergruppe'">
                                <div class="group-header">
                                    <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{item.obergruppe}}</label>
                                    <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{item.untergruppe}}</label>
                                </div>
                            </ng-container>

                            <ng-container *ngSwitchCase="'art'">
                                <div class="group-header">
                                    <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{item.art ? art.values[item.art] : '-'}}</label>
                                    <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{item.reparatur ? item.reparatur: '-'}}</label>
                                </div>
                            </ng-container>

                            <ng-container *ngSwitchCase="'bilder'">
                                <div class="group-header">
                                    <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{item.schadendatum ? (item.schadendatum | date) : '-'}}</label>
                                    <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{(item.bilder.length == 0 ? 0 : item.bilder.length)}}</label>
                                </div>
                            </ng-container>

                            <ng-container *ngSwitchCase="'wertminderungGroup'">
                                <div class="group-header align-right">
                                    <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{(item.schadenhoehe ? item.schadenhoehe: '0') | number:'0.2-2'}} €</label>
                                    <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{(item.wertminderung ? item.wertminderung: '0') | number:'0.2-2'}} €</label>
                                </div>
                            </ng-container>
                        </ng-container>

                    </ng-container>
                    <button class="button drag-button" type="button" mat-icon-button cdkDragHandle>
            <mat-icon>drag_indicator</mat-icon>
          </button>
                </mat-expansion-panel-header>

                <!--body of expansion panel-->
                <div class="button-group">
                    <button class="button edit-button" type="button" mat-icon-button (click)="onRowOpenByIndex(index, item, panel, $event)">
            <mat-icon>edit</mat-icon>
          </button>
                    <button class="button delete-button" type="button" mat-icon-button (click)="onRowRemoveByIndex(index, panel, $event)">
            <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
          </button>
                </div>
            </mat-expansion-panel>
    </div>

    <!--footer-->
    <div class="footer">
        <label>{{(sums$ | async)}}</label>
    </div>

</ng-template>

<!--templates-->
<ng-template #untergruppe let-data="data">
    <ng-container *ngFor="let field of positionenFields;trackBy:trackByField">
        <ng-container [ngSwitch]="field.name">
            <ng-container *ngSwitchCase="'untergruppe'">
                <app-extendable-radio-list [form]="data.form" [name]="field.name" [displayName]="name + '.' + field.name" [values]="data.values" keys="4"></app-extendable-radio-list>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #dialog let-data="data">
    <app-produkt-detail-vorschaden-position (disableTemplateButton)="changeTemplateButtonDisabled($event)" [form]="data.form" [name]="name" [fields]="data.fields">
    </app-produkt-detail-vorschaden-position>
</ng-template>
