import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RadBauart, RadFelge, RadGeschwindigkeitsindex, RadReifenTyp, RadZulaessig } from '@data/domain/schema/enum';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { EnumValues } from '@shared/helper/values';

@Component({
  selector: 'app-produkt-detail-nfz-raeder-rad',
  templateUrl: './produkt-detail-nfz-raeder-rad.component.html',
  styleUrls: ['./produkt-detail-nfz-raeder-rad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailNfzRaederRadComponent {
  public trackByField = TrackBy.trackByField;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public fields: FeatureFields;

  public reifentyp = new EnumValues(RadReifenTyp);
  public felge = new EnumValues(RadFelge);
  public bauart = new EnumValues(RadBauart);
  public geschwindigkeitsindex = new EnumValues(RadGeschwindigkeitsindex);
  public zulaessig = new EnumValues(RadZulaessig);
}
