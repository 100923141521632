import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveRaeder, SaveRaederData } from '../../graphql/mutations';
import { Produkt, Raeder, RaederInput } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktRaederService extends ProduktFeatureService<Raeder, RaederInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Raeder): RaederInput {
        const input: RaederInput = {
            id: produktId,
            radSaetze: (feature.radSaetze || []).map((radSatz, radSatzIndex) => ({
                    ...radSatz,
                    id: `${produktId}:${radSatzIndex}`,
                    rads: (radSatz.rads || []).map((rad, radIndex) => ({
                            ...rad,
                            id: `${produktId}:${radSatzIndex}:${radIndex}`
                        }))
                })),
            notlaufeinrichtung: feature.notlaufeinrichtung,
            bemerkungen: feature.bemerkungen
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveRaeder;
    }

    protected getSaveVariables(input: RaederInput): any {
        return {
            raeder: input
        };
    }

    protected getSaveOptimisticResponse(input: RaederInput): SaveRaederData {
        const data: SaveRaederData = {
            saveRaeder: {
                ...input,
                __typename: 'Raeder',
                bemerkungen: (input.bemerkungen || []).map( bemerkung => ({
                        ...bemerkung,
                        __typename: 'Textbaustein'
                    })),
                radSaetze: (input.radSaetze || []).map(radSatz => ({
                        ...radSatz,
                        __typename: 'RadSatz',
                        rads: (radSatz.rads || []).map(rad => ({
                                ...rad,
                                __typename: 'Rad',
                            }))
                    }))
            }
        };
        return data;
    }

    protected getSaveResponse(response: GraphQLResponse<SaveRaederData>): Raeder {
        return response.data.saveRaeder;
    }

    protected update(produkt: Produkt, feature: Raeder): void {
        produkt.raeder.id = feature.id;
        produkt.raeder.radSaetze = feature.radSaetze;
        produkt.raeder.notlaufeinrichtung = feature.notlaufeinrichtung;
        produkt.raeder.bemerkungen = feature.bemerkungen;
    }
}
