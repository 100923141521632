<div class="main-content">
  <app-card>
    <ng-container *ngFor="let field of fields;trackBy:trackByField">
      <ng-container [ngSwitch]="field.name">
        <ng-container *ngSwitchCase="'bezugsdatum'">
          <ng-container *ngTemplateOutlet="dateDAT, context: { field: field }">
          </ng-container>
          <ng-container *ngIf="!hasExternalServiceIdentifier">
            <p class="form-warn">
              {{'werte.externalServiceIdentifier.info' | translate}}
            </p>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'haendlereinkaufswert'">
          <h4>{{'vtiWerte.ueberschrift.dat' | translate}}</h4>
          <ng-container *ngTemplateOutlet="currency, context: { value: 'haendlereinkaufswert'}">
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'haendlereinkaufswertNetto'">
          <ng-container *ngTemplateOutlet="currency, context: { value: 'haendlereinkaufswertNetto'}">
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'haendlerverkaufswert'">
          <ng-container *ngTemplateOutlet="currency, context: { value: 'haendlerverkaufswert'}">
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'haendlerverkaufswertNetto'">
          <ng-container *ngTemplateOutlet="currency, context: { value: 'haendlerverkaufswertNetto'}">
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </app-card>
</div>

<!--Templates-->

<ng-template #dateDAT let-field="field">
  <app-date [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [readonly]="hasExternalServiceIdentifier" [hasDatepicker]="!hasExternalServiceIdentifier">
    <ng-container appSuffix *ngIf="!(datRequestActive$ | async);else loading">
      <app-mini-fab *ngIf="hasExternalServiceIdentifier" [color]="'basic'" (action)="onGetWerteClick($event)"
                    [disabled]="!hasExternalServiceIdentifier || (datRequestActive$ |async)">
        <app-mat-icon icon="search"></app-mat-icon>
      </app-mini-fab>
    </ng-container>
  </app-date>
</ng-template>

<ng-template #currency let-value="value" let-placeholder="placeholder">
  <div class="currency-container">
    <app-input #userinput  [form]="form" [name]="value" [displayName]="name + '.' + value" [placeholder]="placeholder" type="text" inputmode="numeric">
      <ng-container appSuffix>&nbsp;€</ng-container>
    </app-input>
    <app-button class="copy-button" (action)="copy(userinput.input.nativeElement.id)">
      <app-mat-icon icon="file_copy"></app-mat-icon>
    </app-button>
  </div>
</ng-template>

<ng-template #loading>
  <app-progress-spinner class="loading-spinner" [diameter]="25"></app-progress-spinner>
</ng-template>
