import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { TrackBy } from '@shared/helper/track-by';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormGroupComponent {
  public trackByInstance = TrackBy.trackByInstance;

  public readonly rowHeight = '5.5rem';
  public readonly gutterSize = '0.5rem';

  @Input()
  public cols = 1;

  @Input()
  public hasDeleteButton = false;

  @Output()
  public delete = new EventEmitter();

  @ContentChildren(FormFieldComponent)
  public fields: QueryList<FormFieldComponent>;
}
