import { ProduktArt } from '@data/domain/schema/enum';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { FeatureField, FeatureFieldArray, FeatureFieldGroup, FeatureFields } from '../config/produkt-config';

export abstract class FormViewBaseFactory {
    public create(fields: FeatureFields): ViewFormGroup {
        Assert.notNullOrUndefined(fields, 'fields');
        const controls: {
            [key: string]: AbstractViewFormControl | ViewFormArray;
        } = {};
        fields.forEach((entry: any) => {
            const array = <FeatureFieldArray>entry;
            if (array.arrayName) {
                controls[array.arrayName] = this.createArray(array.arrayName, array.fields);
                return;
            }

            const group = <FeatureFieldGroup>entry;
            if (group.groupName) {
                group.fields.forEach(groupField => {
                    controls[groupField.name] = this.createField(groupField.name);
                });
                return;
            }

            const field = <FeatureField>entry;
            if (field.name) {
                controls[field.name] = this.createField(field.name);
            }
        });
        return new ViewFormGroup(controls, {
            updateOn: 'blur'
        });
    }

    protected abstract createField(name: string): AbstractViewFormControl;

    protected createArray(_arrayName: string, _arrayFields: FeatureFields): ViewFormArray {
        return new ViewFormArray([]);
    }
}

export abstract class FormViewModelBaseFactory<TModel> {
    public create(model: TModel, fields: FeatureFields, produktArt: ProduktArt = ProduktArt.None, updateOn: 'change' | 'blur' = 'blur'): ViewFormGroup {
        Assert.notNullOrUndefined(fields, 'fields');
        const controls: {
            [key: string]: AbstractViewFormControl | ViewFormArray;
        } = {};
        fields.forEach((entry: any) => {
            const array = <FeatureFieldArray>entry;
            if (array.arrayName) {
                controls[array.arrayName] = this.createArray(model, array.arrayName, array.fields, produktArt);
                return;
            }

            const group = <FeatureFieldGroup>entry;
            if (group.groupName) {
                group.fields.forEach(groupField => {
                    controls[groupField.name] = this.createField(model, groupField.name, produktArt);
                });
                return;
            }

            const field = <FeatureField>entry;

            if (field.name) {
              controls[field.name] = this.createField(model, field.name, produktArt);
            }
        });
        return new ViewFormGroup(controls, { updateOn });
    }

    protected abstract createField(model: TModel, name: string, produktArt: ProduktArt): AbstractViewFormControl;

    protected createArray(_model: TModel, _arrayName: string, arrayFields: FeatureFields, produktArt: ProduktArt): ViewFormArray {
        return new ViewFormArray([]);
    }
}
