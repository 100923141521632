import { Injectable } from '@angular/core';

import { PrivacyService } from '@app/service/privacy.service';
import { Assert } from '@shared/helper/assert';
import { PrivacyDialogService } from '@shared/service/privacy-dialog.service';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PrivacyGuard  {
    constructor(
        private readonly privacyService: PrivacyService,
        private readonly privacyDialogService: PrivacyDialogService) {
        Assert.notNullOrUndefined(privacyService, 'privacyService');
        Assert.notNullOrUndefined(privacyDialogService, 'privacyDialogService');
    }

    public canActivate(): Observable<boolean> {
        return this.privacyService.isAccepted().pipe(
            flatMap(result => {
                if (result) {
                    return of(true);
                }
                return this.privacyDialogService.open().pipe(
                    flatMap(() => this.privacyService.setIsAccepted(true))
                );
            })
        );
    }
}
