<ng-container [formGroup]="form">
    <div class="nachbewertung-dialog-wrapper">
        <h4 class="mat-caption text-field-label">{{ 'nachbewertung.produktart' | translate }}</h4>
        <mat-form-field floatLabel="never" [class.disabled]="disabled">
            <mat-select #matSelect disableRipple disableOptionCentering [id]="'produktArt'"
                formControlName="produktArtControl" [placeholder]="'nachbewertung.produktart' | translate"
                panelClass="mat-dropdown">
                <mat-option *ngFor="let key of produktArtNachbewertungValues.keys" [value]="key">
                    {{ ('toolbar.' + (produktArtNachbewertungValues.values[key]).toLowerCase()) | translate }}
                </mat-option>
            </mat-select>
            <mat-icon class="dropdown-icon" [ngClass]="matSelect.panelOpen ? 'icon-chevron-up' : 'icon-chevron-down'">
            </mat-icon>
        </mat-form-field>
    </div>
</ng-container>