import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-step',
  template: `
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
  <ng-template #labelContent>
    <ng-content select="app-step-label"></ng-content>
  </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepComponent {
  public completedChange = new BehaviorSubject<boolean>(undefined);
  public editableChange = new BehaviorSubject<boolean>(false);

  @Input()
  public set completed(completed: boolean) {
    this.completedChange.next(completed);
  }

  @Input()
  public set editable(editable: boolean) {
    this.editableChange.next(editable);
  }

  @ViewChild('content', { static: true })
  public content: TemplateRef<any>;

  @ViewChild('labelContent', { static: true })
  public labelContent: TemplateRef<any>;
}
