import { VorschadenObergruppe, VorschadenUntergruppe } from '@data/domain/schema/enum';

const o = VorschadenObergruppe;
const u = VorschadenUntergruppe;

export const VORSCHADEN_GRUPPEN = [
    'None',
    'Frontschaden',
    'Schwellerschaden links',
    'Seitenschaden links',
    'Heckschaden',
    'Seitenschaden rechts',
    'Schwellerschaden rechts',
    'Schaden am Unterboden',
    'Schaden am Dach',
];

export const OBERGRUPPE_TO_UNTERGRUPPE_MAP = new Map<VorschadenObergruppe, VorschadenUntergruppe[]>([
    [o.Frontschaden, [
        u.Motorhaube,
        u.ScheinwerferLinks,
        u.ScheinwerferRechts,
        u.StossfaengerVorne,
        u.Windschutzscheibe,
        u.Fronttraeger,
        u.Sensorik,
        u.Aufnahmen
    ]],
    [o.SchadenAmUnterboden, [
        u.Unterboden,
        u.Verstaerkungen,
        u.Lastpfad,
        u.Abgasanlage,
        u.Antriebsaggregat,
        u.Antriebsstrang,
        u.Achsen,
        u.Getriebe
    ]],
    [o.SchwellerschadenLinks, [
        u.SchwellerLinks,
        u.Schwellerverkleidung,
        u.Schwelleraufnahme,
        u.Unterbau
    ]],
    [o.SchwellerschadenRechts, [
        u.SchwellerRechts,
        u.Schwellerverkleidung,
        u.Schwelleraufnahme,
        u.Unterbau
    ]],
    [o.SeitenschadenLinks, [
        u.AussenspiegelLinks,
        u.KotfluegelVorneLinks,
        u.SeitenwandHintenLinks,
        u.TuerHintenLinks,
        u.TuerVorneLinks,
        u.TuerverkleidungVorneLinks,
        u.TuerverkleidungHintenLinks,
        u.RadHintenLinks,
        u.RadVorneLinks,
        u.ASaeule,
        u.BSaeule,
        u.CSaeule,
        u.DSaeule,
        u.Dachholm
    ]],
    [o.SeitenschadenRechts, [
        u.AussenspiegelRechts,
        u.KotfluegelVorneRechts,
        u.SeitenwandHintenRechts,
        u.TuerHintenRechts,
        u.TuerVorneRechts,
        u.TuerverkleidungVorneRechts,
        u.TuerverkleidungHintenRechts,
        u.RadHintenRechts,
        u.RadVorneRechts,
        u.ASaeule,
        u.BSaeule,
        u.CSaeule,
        u.DSaeule,
        u.Dachholm
    ]],
    [o.Heckschaden, [
        u.Heckklappe,
        u.HeckleuchteLinks,
        u.HeckleuchteRechts,
        u.StossfaengerHinten,
        u.Hecktraeger,
        u.Aufnahmen,
        u.Sensorik
    ]],
    [o.SchadenAmDach, [
        u.Dach,
        u.Dachhimmel,
        u.DachholmLinks,
        u.DachholmRechts,
        u.Antenne,
        u.Schiebedach,
        u.Panoramadach,
        u.Railing
    ]]
]);
