import { Injectable } from '@angular/core';
import { ProduktStatus } from '@data/domain/schema/enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ProduktStatusService {
  private statusStream$ = new BehaviorSubject<ProduktStatus>(null);
  public currentStatus$ = this.statusStream$.asObservable();

  public update(status: ProduktStatus): void {
    this.statusStream$.next(status);
  }
}
