import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileData } from '@app/class/file-data';
import { UploadDialogComponent, UploadDialogSettings } from '@shared/component/popup-modal/upload-dialog/upload-dialog.component';
import { Assert } from '@shared/helper/assert';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

export type FileArray = FileData<ArrayBuffer>[];

@Injectable({
    providedIn: 'root'
})
export class UploadDialogService {
    constructor(private readonly matDialog: MatDialog) {
        Assert.notNullOrUndefined(matDialog, 'matDialog');
    }

    public uploadFiles(title: string, accept: string, multiple = true): Observable<FileArray> {
        Assert.notNullOrUndefined(title, 'title');
        Assert.notNullOrUndefined(accept, 'accept');
        Assert.notNullOrUndefined(multiple, 'multiple');
        const settings: UploadDialogSettings = {
            title,
            accept,
            multiple
        };
        const options = {
            data: settings,
            maxWidth: '95vw',
            maxHeight: '95vh',
        };
        return this.matDialog.open(UploadDialogComponent, options).afterClosed().pipe(
            flatMap((files$: Observable<FileArray>) => files$ || of([]))
        );
    }
}
