import {Component, OnInit} from '@angular/core';
import {AuthService} from '@app/service/auth.service';
import { Assert } from '@shared/helper/assert';
import {PrivacyDialogService} from '@shared/service/privacy-dialog.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import packageInfo from '../../../../package.json';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss']
})
export class HelpMenuComponent implements OnInit {

  public context$: Observable<any>;
  public version = packageInfo.version;
  public environment = '';

  constructor(private readonly privacyDialogService: PrivacyDialogService,
              private readonly authService: AuthService) {
    Assert.notNullOrUndefined(privacyDialogService, 'privacyDialogService');
    Assert.notNullOrUndefined(authService, 'authService');
  }

  public ngOnInit(): void {
    this.context$ = this.authService.getClaims().pipe(
      map(claims => ({
        ...claims,
        version: this.version,
        userAgent: `${location}, ${navigator.userAgent}`
      }))
    );
    this.environment = environment.name === 'PROD' ? '' : environment.name;
  }

  public goToUrl(url: string): void {
    window.open(
      url,
      '_blank'
    );
  }

  public onPolicyClick(): void {
    this.privacyDialogService.open().subscribe();
  }

  public sendMailTo(mail: string, subject: string, body: string): void {
    const mailUrl = `mailto:${mail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    this.goToUrl(mailUrl);
  }

}
