import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import { VorschadenArt, VorschadenReparatur, VorschadenUntergruppe } from '@data/domain/schema/enum';
import { VorschadenPosition } from '@data/domain/schema/type';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { VORSCHADEN_GRUPPEN} from '@modules/produkt/config/produkt-vorschaden-gruppen.config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';
import { EnumValues, ObjectValues } from '@shared/helper/values';
import { TemplateButtonDisableEvent } from '@shared/service/template-dialog.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-produkt-detail-vorschaden-position',
  templateUrl: './produkt-detail-vorschaden-position.component.html',
  styleUrls: ['./produkt-detail-vorschaden-position.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailVorschadenPositionComponent implements OnInit {
  public trackByField = TrackBy.trackByField;
  public now = new Date();
  private subscriptions: Subscription[] = [];

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public fields: FeatureFields;

  @Output()
  public disableTemplateButton = new EventEmitter<TemplateButtonDisableEvent>();

  public obergruppe = new EnumValues(VORSCHADEN_GRUPPEN);
  public untergruppe = new ObjectValues(VorschadenUntergruppe);
  public untergruppen = [];

  public beschaedigungart = new ObjectValues(VorschadenArt);
  public beschaedigungarten = [
    'Nachlackierung',
    'Unfall',
    'Hagelschaden',
    'Sonstige',
  ];

  public vorschadenReparatur = new ObjectValues(VorschadenReparatur);
  public vorschadenReparaturen = [
    'SachUndFachgerechtRepariert',
    'Teilrepariert',
    'UnsachgemaessRepariert',
    'Sonstige',
  ];

  constructor(
    private readonly fileFieldService: ProduktDetailFileFieldService) {
    Assert.notNullOrUndefined(fileFieldService, 'fileFieldService');
  }

  public ngOnInit(): void {
    this.handleVorschadenBestaetigung(this.form.getRawValue());
    this.subscriptions.push(this.form.valueChanges.subscribe(
      value => {
        this.handleVorschadenBestaetigung(value);
      }
    ));
  }

  public onFileAdd(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(files, 'files');
    this.fileFieldService.add(field, files);
  }

  public onFileDelete(field: UntypedFormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.remove(field, fileId);
  }

  public onFileUpdate(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(event, 'event');
    this.fileFieldService.update(field, event);
  }

  public onFileReset(field: UntypedFormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.reset(field, fileId);
  }

  public isFileResetable(fileId: string): boolean {
    Assert.notNullOrEmpty(fileId, 'fileId');
    return this.fileFieldService.isUpdateable(fileId);
  }

  public getEmptyFieldsForValidation(): string[] {
    if (!this.form.get('extern')?.value) {
      return [];
    }
    const ret: string[] = [];
    const fields = ['obergruppe', 'untergruppe', 'wertminderung'];

    fields.forEach(field => {
      const value = this.form.get(field)?.value;
      if (value === null || value === undefined) {
        ret.push(field);
      }
    });

    return ret;
  }

  private handleVorschadenBestaetigung(value: VorschadenPosition): void {
    if (!value) {
      return;
    }
    if (value.extern) {
      if (value.obergruppe && value.untergruppe && value.wertminderung) {
        this.form.get('bestaetigt')?.setValue(true, { emitEvent: false });
        this.disableTemplateButton.emit({ index: 1, disable: false });
      } else {
        this.form.get('bestaetigt')?.setValue(false, { emitEvent: false });
        this.disableTemplateButton.emit({ index: 1, disable: true });
      }
    }
  }
}
