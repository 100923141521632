<h3>{{name + '.title' | translate}}</h3>

<app-file-gallery *ngIf="produkt.art !== produktArt.VtiTooling"
                  [fileIds]="[]"
                  [dialogTitle]="name + '.addfile'"
                  [showCamera]="false"
                  [showFolder]="true"
                  [accept]="acceptPDF"
                  [fileValidationType]="fileValidationType"
                  [multiple]="multiple"
                  [disabled]="disabled$ | async"
                  [resetable]="isFileResetable.bind(this)" (add)="onFileAdd(control, $event)"
                  (delete)="onFileDelete(control, $event)" (update)="onFileUpdate(control, $event)"
                  (reset)="onFileReset(control, $event)">
</app-file-gallery>

  <div class="file-row" *ngFor="let anhang of (anhaenge$ | async); let index = index">
    <ng-container *ngIf="anhang">
      <app-button  [disabled]="disabled$ | async" class="file-button">
        <app-mat-icon icon="picture_as_pdf" size="2rem"></app-mat-icon>
      </app-button>
      <label class="file-label">{{anhang.bezeichnung}}</label>
      <button *ngIf="produkt.art !== produktArt.VtiTooling" [disabled]="disabled$ | async" class="delete-button" type="button" (click)="onDelete(index, anhang)" mat-icon-button>
        <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
      </button>
    </ng-container>
  </div>



