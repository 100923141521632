<ng-container *ngIf="(visible$ | async)">
  <div class="toolbar" *ngIf="produkt$ | async as produkt">
    <div class="entry">
      <app-select *ngIf="produkt.art === produktArt.Bewertung || produkt.art === produktArt.Ruecknahmebewertung"
                  class="select" [form]="SelectProduktArt" [name]="'SelectProduktArt'" [displayName]="'Produkt'"
                  [values]="produkt.art === produktArt.Bewertung || produkt.art === produktArt.Ruecknahmebewertung ? artBewertungValues : artZustandsberichtValues"
                  [valuePrefix]="'toolbar' + '.'" [hasEmptyField]="false"
                  (selectionChange)="onChangeProduktart($event)">
      </app-select>
    </div>
  </div>
</ng-container>
