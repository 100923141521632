import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/guard/auth.guard';
import { AUTH_MODULE_ROUTES } from '@modules/auth/auth-module.routes';
import { DASHBOARD_MODULE_ROUTES } from '@modules/dashboard/dashboard-module.routes';
import { PRODUKT_MODULE_ROUTES } from '@modules/produkt/produkt-module.routes';
import { VERWALTUNG_MODULE_ROUTES } from '@modules/verwaltung/verwaltung-module.routes';
import { PrivacyGuard } from '@shared/guard/privacy.guard';
import { ContentLayoutComponent } from './layout';

const routes: Routes = [
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [PrivacyGuard],
    children: [
      {
        path: 'auth',
        children: AUTH_MODULE_ROUTES,
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        children: DASHBOARD_MODULE_ROUTES,
      },
      {
        path: 'produkt',
        canActivate: [AuthGuard],
        children: PRODUKT_MODULE_ROUTES,
      },
      {
        path: 'verwaltung',
        canActivate: [AuthGuard],
        children: VERWALTUNG_MODULE_ROUTES,
      },
      // fallback
      { path: '**', redirectTo: 'dashboard' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
