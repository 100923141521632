import { Injectable } from '@angular/core';
import { ManuellerWert } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailWerteManuelleWerteFormViewFactory extends FormViewModelBaseFactory<ManuellerWert> {

    constructor() {
        super();
    }

    protected createField(model: ManuellerWert, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Werte.fields.ManuelleWerte.fields;
        switch (name) {
            case fields.Bezeichnung.name:
                return new ViewFormControl(model.bezeichnung);
            case fields.Wert.name:
                return new ViewFormControl(model.wert);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
