import { Injectable } from '@angular/core';
const VIN_DIGIT_VALUE_TABLE = {
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
    G: 7,
    H: 8,
    // 'i': 9, -> forbidden
    J: 1,
    K: 2,
    L: 3,
    M: 4,
    N: 5,
    // 'o':6, -> forbidden
    P: 7,
    // 'q': 8 -> forbidden
    R: 9,
    S: 2,
    T: 3,
    U: 4,
    V: 5,
    W: 6,
    X: 7,
    Y: 8,
    Z: 9
};

const VIN_DIGIT_WEIGHT_TABLE = [9, 8, 7, 6, 5, 4, 3, 2, 10, 9, 8, 7, 6, 5, 4, 3, 2];

@Injectable()
export class VinDigitService {
    public calculateDigit(vin: string): string {
        let sum = 0;
        for (let i = 0; i < vin.length; ++i) {
            const char = vin.charAt(i);
            const value = VIN_DIGIT_VALUE_TABLE[char];
            if (value === undefined) {
                return '-';
            }
            const weight = VIN_DIGIT_WEIGHT_TABLE[i];
            sum += value * weight;
        }
        const mod = sum % 11;
        return mod === 10 ? 'x' : `${mod}`;
    }
}
