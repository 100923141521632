import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ProduktStatusService } from '@app/service/produkt-status.service';
import { ProduktStatus } from '@data/domain/schema/enum';
import { ProduktAbschlussService } from '@data/domain/service/feature/produkt-abschluss.service';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { ProduktDetailFeatureComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { Feature, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailAbschlussFormViewFactory } from '@modules/produkt/factory/abschluss/produkt-detail-abschluss-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureValidService } from '@modules/produkt/service/produkt-detail-feature-valid.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { BehaviorSubject } from 'rxjs';

interface FeatureValidation {
  name: string;
  incorrectFields: string[];
}

@Component({
  selector: 'app-produkt-detail-abschluss',
  templateUrl: './produkt-detail-abschluss.component.html',
  styleUrls: ['./produkt-detail-abschluss.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailAbschlussComponent extends ProduktDetailFeatureComponent implements OnInit, AfterViewInit {

  public feature = PRODUKT_CONFIG_FEATURES.Abschluss.name;
  public fieldFazit = PRODUKT_CONFIG_FEATURES.Abschluss.fields.Fazit.name;

  public trackByField = TrackBy.trackByField;

  public form: ViewFormGroup;

  public adressen: Feature;

  public validations: FeatureValidation[] = [];

  public statusChanged$ = new BehaviorSubject<ProduktStatus>(undefined);

  @ViewChild('fazit')
  public fazitElement: TextbausteineComponent;

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    private readonly featureValidService: ProduktDetailFeatureValidService,
    private readonly produktDetailAbschlussFormViewFactory: ProduktDetailAbschlussFormViewFactory,
    private readonly produktAbschlussService: ProduktAbschlussService,
    private readonly updateWerteService: UpdateWerteService,
    private readonly produktStatusService: ProduktStatusService,
    private readonly textbausteineService: TextbausteineService
  ) {
    super(produktConfigResolveService, produktDetailResolveService);
    Assert.notNullOrUndefined(featureValidService, 'featureValidService');
    Assert.notNullOrUndefined(updateWerteService, 'updateWerteService');
    Assert.notNullOrUndefined(produktStatusService, 'produktStatusService');
  }

  public ngOnInit(): void {
    this.init(PRODUKT_CONFIG_FEATURES.Abschluss.name);
    this.adressen = this.getFeatureByName(PRODUKT_CONFIG_FEATURES.Adressen.name);
    this.form = this.produktDetailAbschlussFormViewFactory.create(this.produkt.abschluss, this.fields);

    if ( this.form.get('fazit')) {
      this.form.get('fazit').valueChanges.subscribe(value => {
        this.produkt.abschluss.fazit = value;
        this.produktAbschlussService.save(this.produkt.id, this.produkt.abschluss);
      });
    }

    this.getFeatures().forEach(({ name, fields }) => {
      const incorrectFields = this.featureValidService.getFeatureIncorrectFields(this.produkt, name, fields);
      if (incorrectFields.length > 0) {
        this.validations.push({ name, incorrectFields });
      }
    });

    this.updateWerteService.updateWerteBerechneteFelder(this.produkt);
  }
  public ngAfterViewInit(): void {
    if (this.form.get(this.fieldFazit) && this.validations.length <= 0) {
      try {
        if (this.produkt.abschluss.fazit === null) {
          this.textbausteineService.prefillWithStandardTextbausteine(this.feature, this.fieldFazit, this.produkt.art, this.form, this.fazitElement);
        }
      } catch (error) {
      }
    }
  }

  public onStatusChanged($event: ProduktStatus): void {
    this.statusChanged$.next($event);
    this.produktStatusService.update($event);
  }
}
