import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TableColumnComponent } from '../table-column/table-column.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnDestroy, AfterViewInit {

  public displayedColumns$ = new BehaviorSubject<string[]>([]);
  public columns$ = new BehaviorSubject<TableColumnComponent[]>([]);
  public dataSource = new MatTableDataSource<any>();

  @Input()
  public filter$ = new BehaviorSubject<string>('');

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  public sort: MatSort;

  @ContentChildren(TableColumnComponent)
  public columns: QueryList<TableColumnComponent>;

  @Input()
  public data: any[];

  @Input()
  public pageSizeOptions: number[];

  @Input()
  public active: string;

  @Input()
  public direction: 'asc' | 'desc';

  @Input()
  public filterPredicate: (date: any, filter: string) => boolean;

  @Output()
  public action = new EventEmitter<any>();

  @Input()
  public customFilterVisible = true;

  private subscriptions: Subscription[] = [];

  public ngOnInit(): void {
    this.dataSource.data = this.data;
    this.dataSource.sortingDataAccessor = (item, property) => property.split('.').reduce((value, key) => value ? value[key] : undefined, item);
    this.dataSource.sort = this.sort;
    this.dataSource.filter = this.filter$.value;
    this.dataSource.filterPredicate = this.filterPredicate;
    this.subscriptions.push(this.filter$.subscribe(value => this.dataSource.filter = value));
  }

  public ngAfterViewInit(): void {
    const columns = this.columns.toArray();
    setTimeout(() => {
      this.displayedColumns$.next(columns.map(x => x.name));
      this.columns$.next(columns);
      this.dataSource.paginator = this.paginator;
      this.sort.sort({ id: this.active, start: this.direction, disableClear: false });
    }, 1);
  }

  public onRowClick(entry: any): void {
    this.action.next(entry);
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public refresh(tabledata: any[]): void {
    this.dataSource.data = tabledata;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
