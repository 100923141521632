import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ProduktArt, RadPosition, RaederNotlaufeinrichtung } from '@data/domain/schema/enum';
import { Notiz, RadSatz, Raeder, RaederInput } from '@data/domain/schema/type';
import { ProduktRaederService } from '@data/domain/service/feature';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailRaederFormViewFactory } from '@modules/produkt/factory/raeder/produkt-detail-raeder-form-view.factory';
import { ProduktDetailRaederRadSatzFormViewFactory } from '@modules/produkt/factory/raeder/produkt-detail-raeder-radsatz-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-produkt-detail-raeder',
  templateUrl: './produkt-detail-raeder.component.html',
  styleUrls: ['./produkt-detail-raeder.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailRaederComponent extends ProduktDetailFeatureInputComponent<Raeder, RaederInput> implements OnInit, AfterViewInit {

  public feature = PRODUKT_CONFIG_FEATURES.Raeder.name;
  public fieldBemerkungen = PRODUKT_CONFIG_FEATURES.Raeder.fields.Bemerkungen.name;

  public trackByField = TrackBy.trackByField;
  public trackByIndex = TrackBy.trackByIndex;

  public notizen$: Observable<Notiz[]>;

  public notlaufeinrichtung = new EnumValues(RaederNotlaufeinrichtung);

  @ViewChild('bemerkungen')
  public bemerkungenElement: TextbausteineComponent;

  public get radSaetze(): ViewFormArray {
    return <ViewFormArray>this.form.get(PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.name);
  }

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktRaederService: ProduktRaederService,
    private readonly formViewFactory: ProduktDetailRaederFormViewFactory,
    private readonly textbausteineService: TextbausteineService,
    private readonly radSatzFormViewFactory: ProduktDetailRaederRadSatzFormViewFactory,
    private readonly notizenService: ProduktDetailFeatureNotizenService) {
    super(produktConfigResolveService, produktDetailResolveService, produktRaederService);
    Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
    Assert.notNullOrUndefined(radSatzFormViewFactory, 'radSatzFormViewFactory');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
  }

  public ngOnInit(): void {
    const name = PRODUKT_CONFIG_FEATURES.Raeder.name;
    this.notizen$ = this.notizenService.init(this.produkt, name);
    this.init(name);
    if (this.radSaetze && this.radSaetze.length === 0) {
      this.addSatz();
    }
  }
  public ngAfterViewInit(): void {
    if (this.form.get(this.fieldBemerkungen) && this.produkt.raeder.bemerkungen === null) {
      this.textbausteineService.prefillWithStandardTextbausteine(this.feature, this.fieldBemerkungen, this.produkt.art, this.form, this.bemerkungenElement);
    }
  }


  public onAddSatzClick(): void {
    this.addSatz();
  }

  public onDuplicateSatzClick(): void {
    this.duplicateSatz();
  }

  public onRemoveSatzClick(): void {
    this.removeSatz();
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  protected getFeature(): Raeder {
    return this.form.getRawValue();
  }

  protected createForm(): ViewFormGroup {
    return this.formViewFactory.create(this.produkt.raeder, this.fields, this.produkt.art);
  }

  private addSatz(): void {
    const radSaetzeFields = (<FeatureFieldArray>this.fields.find((x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.name)).fields;
    const radSatz: RadSatz = {
      rads: [
        { radPosition: RadPosition.VL },
        { radPosition: RadPosition.VR },
        { radPosition: RadPosition.HL },
        { radPosition: RadPosition.HR },
      ]
    };
    const form = this.radSatzFormViewFactory.create(radSatz, radSaetzeFields);
    this.radSaetze.push(form);
  }

  private duplicateSatz(): void {
    const radSaetzeFields = (<FeatureFieldArray>this.fields.find((x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.Raeder.fields.RadSaetze.name)).fields;
    const radSatz = <RadSatz>(<ViewFormGroup>this.radSaetze.controls[0]).getRawValue();
    const form = this.radSatzFormViewFactory.create(radSatz, radSaetzeFields);
    this.radSaetze.push(form);
  }

  private removeSatz(): void {
    this.radSaetze.removeAt(this.radSaetze.length - 1);
  }
}
