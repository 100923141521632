<ng-container *ngIf="!saving; else spinner">
    <label>{{(name + '.' + 'stempel') | translate}}</label>
    <app-inline-image [form]="form" [size]="1024 * maxFileUploadSizeInKb" name="stempel" [displayName]="name + '.stempel'" isEinstellungen="true">
    </app-inline-image>
    <label>{{(name + '.' + 'unterschrift') | translate}}</label>
    <app-inline-image [form]="form" [size]="1024 * maxFileUploadSizeInKb" name="unterschrift" [displayName]="name + '.unterschrift'" isEinstellungen="true">
    </app-inline-image>

    <ng-container [formGroup]="form">
        <gtuema-max-character-textfield class="drucktext" [labelText]="(name+ '.sachverstaendigerText') | translate"
                                  [formControlName]="'sachverstaendigerText'" name="sachverstaendigerText"
                                  maxLength="500" [inputHeight]="'10vh'"></gtuema-max-character-textfield>
    </ng-container>

    <app-select [form]="form" name="watermarkEnable" [displayName]="name + '.watermarkEnable'" [values]="selectValues"
        [hasEmptyField]="false"></app-select>

    <div class="settings-container">
        <h4>{{ name + '.fileSync.title' | translate }}</h4>
        <app-slide-toggle [form]="form" name="directFileSync" [displayName]="name + '.directFileSync'"></app-slide-toggle>
        <!-- <app-slide-toggle [form]="form" name="wifiOnlyFileSync" [displayName]="name + '.wifiOnlyFileSync'"></app-slide-toggle> -->
    </div>
    
    <div class="settings-container">
        <h4>{{ name + '.customTexts.title' | translate }}</h4>
        <app-input [readonly]="false" [form]="form" name="customTextBewertung" [displayName]="name + '.customTextBewertung'"
            type="text" inputmode="text"></app-input>
        <app-textarea [readonly]="false" [form]="form" name="customDescriptionBewertung"
            [displayName]="name + '.customDescriptionBewertung'">
        </app-textarea>
        <app-input [readonly]="false" [form]="form" name="customTextRuecknahmebewertung"
            [displayName]="name + '.customTextRuecknahmebewertung'" type="text" inputmode="text"></app-input>
        <app-textarea [readonly]="false" [form]="form" name="customDescriptionRuecknahmebewertung"
            [displayName]="name + '.customDescriptionRuecknahmebewertung'">
        </app-textarea>
        <app-input [readonly]="false" [form]="form" name="customTextZustandsbericht"
            [displayName]="name + '.customTextZustandsbericht'" type="text" inputmode="text"></app-input>
        <app-textarea [readonly]="false" [form]="form" name="customDescriptionZustandsbericht"
            [displayName]="name + '.customDescriptionZustandsbericht'">
        </app-textarea>
    </div>
    
    <div class="settings-container">
        <h4>{{ name + '.dat.title' | translate }}</h4>
        <app-input [readonly]="true" [form]="form" name="datCustomerNumber" [displayName]="name + '.datCustomerNumber'"
            type="text" inputmode="text"></app-input>
        <app-input [readonly]="true" [form]="form" name="datCustomerLogin" [displayName]="name + '.datCustomerLogin'"
            type="text" inputmode="text"></app-input>
        <app-input [readonly]="true" [form]="form" name="datInterfacePartnerNumber"
            [displayName]="name + '.datInterfacePartnerNumber'" type="text" inputmode="text"></app-input>
        <app-input [readonly]="true" [form]="form" name="datProductVariant" [displayName]="name + '.datProductVariant'"
            type="text" inputmode="text"></app-input>
        <app-input [readonly]="true" [form]="form" name="datInterfacePartnerSignature"
            [displayName]="name + '.datInterfacePartnerSignature'" type="text" inputmode="text"></app-input>
        <app-input [readonly]="true" [form]="form" name="datCustomerPassword" [displayName]="name + '.datCustomerPassword'"
            type="password" inputmode="text"></app-input>
    </div>
</ng-container>

<ng-template #spinner>
    <app-progress-spinner></app-progress-spinner>
</ng-template>
<div align="end">
    <app-button (action)="form.valid && onSave()" [disabled]="!form.valid || saving">
        {{name + '.save' | translate}}
    </app-button>
</div>
