import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { MatTabNavPanel } from '@angular/material/tabs';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { ProduktArt } from '@data/domain/schema/enum';
import { Restwertprognose } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { DatSearchService } from '@modules/produkt/service/dat-search.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TabNavLink } from '@shared/component';
import { TabNavComponent } from '@shared/component/layout/tabs/tab-nav/tab-nav.component';
import { Assert } from '@shared/helper/assert';
import { DisplayService } from '@shared/service/display.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

const LINKS: {
  [name: string]: TabNavLink;
} = {
  [PRODUKT_CONFIG_FEATURES.Auftrag.name]: {
    icon: 'order',
    path: PRODUKT_CONFIG_FEATURES.Auftrag.name,
    id: 'id-auftrag',
    title: 'Auftrag'
  },
  [PRODUKT_CONFIG_FEATURES.VtiAuftrag.name]: {
    icon: 'order',
    path: PRODUKT_CONFIG_FEATURES.VtiAuftrag.name,
    id: 'id-vtiauftrag',
    title: 'Auftrag'
  },
  [PRODUKT_CONFIG_FEATURES.Fahrzeug.name]: {
    icon: 'car',
    path: PRODUKT_CONFIG_FEATURES.Fahrzeug.name,
    id: 'id-fahrzeug',
    title: 'Fahrzeug'
  },
  [PRODUKT_CONFIG_FEATURES.Uebersicht.name]: {
    icon: 'overview',
    path: PRODUKT_CONFIG_FEATURES.Uebersicht.name,
    id: 'id-uebersicht',
    title: 'Übersicht'
  },
  [PRODUKT_CONFIG_FEATURES.Raeder.name]: {
    icon: 'wheels',
    path: PRODUKT_CONFIG_FEATURES.Raeder.name,
    id: 'id-raeder',
    title: 'Räder'
  },
  [PRODUKT_CONFIG_FEATURES.NfzRaeder.name]: {
    icon: 'wheels',
    path: PRODUKT_CONFIG_FEATURES.NfzRaeder.name,
    id: 'id-nfz-raeder',
    title: 'Räder'
  },
  [PRODUKT_CONFIG_FEATURES.Vorschaden.name]: {
    icon: 'previous-damage',
    path: PRODUKT_CONFIG_FEATURES.Vorschaden.name,
    id: 'id-vorschaden',
    title: 'Vorschaden'
  },
  [PRODUKT_CONFIG_FEATURES.Schaden.name]: {
    icon: 'damage',
    path: PRODUKT_CONFIG_FEATURES.Schaden.name,
    id: 'id-schaden',
    title: 'Schaden'
  },
  [PRODUKT_CONFIG_FEATURES.Feststellungen.name]: {
    icon: 'damage',
    path: PRODUKT_CONFIG_FEATURES.Feststellungen.name,
    id: 'id-feststellungen',
    title: 'Feststellungen'
  },
  [PRODUKT_CONFIG_FEATURES.Ausstattung.name]: {
    icon: 'equipment',
    path: PRODUKT_CONFIG_FEATURES.Ausstattung.name,
    id: 'id-ausstattung',
    title: 'Ausstattung'
  },
  [PRODUKT_CONFIG_FEATURES.Unterlagen.name]: {
    icon: 'documents',
    path: PRODUKT_CONFIG_FEATURES.Unterlagen.name,
    id: 'id-unterlagen',
    title: 'Unterlagen'
  },
  [PRODUKT_CONFIG_FEATURES.Wartung.name]: {
    icon: 'service',
    path: PRODUKT_CONFIG_FEATURES.Wartung.name,
    id: 'id-wartung',
    title: 'Wartung'
  },
  [PRODUKT_CONFIG_FEATURES.Werte.name]: {
    icon: 'value',
    path: PRODUKT_CONFIG_FEATURES.Werte.name,
    id: 'id-werte',
    title: 'Werte'
  },
  [PRODUKT_CONFIG_FEATURES.VtiWerte.name]: {
    icon: 'value',
    path: PRODUKT_CONFIG_FEATURES.VtiWerte.name,
    id: 'id-vtiwerte',
    title: 'Werte'
  },
  [PRODUKT_CONFIG_FEATURES.Fehlteile.name]: {
    icon: 'missing-parts',
    path: PRODUKT_CONFIG_FEATURES.Fehlteile.name,
    id: 'id-fehlteile',
    title: 'Fehlteile'
  },
  [PRODUKT_CONFIG_FEATURES.Abschluss.name]: {
    icon: 'closure',
    path: PRODUKT_CONFIG_FEATURES.Abschluss.name,
    id: 'id-abschluss',
    title: 'Abschluss'
  },
  [PRODUKT_CONFIG_FEATURES.VtiAbschluss.name]: {
    icon: 'closure',
    path: PRODUKT_CONFIG_FEATURES.VtiAbschluss.name,
    id: 'id-vtiabschluss',
    title: 'Abschluss'
  },
  [PRODUKT_CONFIG_FEATURES.AcAbschluss.name]: {
    icon: 'closure',
    path: PRODUKT_CONFIG_FEATURES.AcAbschluss.name,
    id: 'id-acabschluss',
    title: 'Abschluss'
  },
  [PRODUKT_CONFIG_FEATURES.Adressen.name]: {
    icon: 'contact',
    path: PRODUKT_CONFIG_FEATURES.Adressen.name,
    id: 'id-adressen',
    title: 'Adressen'
  },
  [PRODUKT_CONFIG_FEATURES.Lackmessung.name]: {
    icon: 'paint',
    path: PRODUKT_CONFIG_FEATURES.Lackmessung.name,
    id: 'id-lackmessung',
    title: 'Lackmessung'
  },
  [PRODUKT_CONFIG_FEATURES.Restwertprognose.name]: {
    icon: 'service',
    path: PRODUKT_CONFIG_FEATURES.Restwertprognose.name,
    id: 'id-restwertprognose',
    title: 'Restwertprognose'
  },
  [PRODUKT_CONFIG_FEATURES.Kalkulation.name]: {
    icon: 'paint',
    path: PRODUKT_CONFIG_FEATURES.Kalkulation.name,
    id: 'id-kalkulation',
    title: 'Kalkulation'
  }
};

@Component({
  selector: 'app-produkt-detail-nav',
  templateUrl: './produkt-detail-nav.component.html',
  styleUrls: ['./produkt-detail-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailNavComponent implements OnInit, OnDestroy {

  public links: TabNavLink[];

  public datRequestActive$: BehaviorSubject<boolean>;

  public visible$: BehaviorSubject<boolean>;

  @Input()
  public tabPanel: MatTabNavPanel;

  private subscriptions: Subscription[] = [];

  @ViewChild(TabNavComponent)
  private nav: TabNavComponent;


  constructor(
    private readonly datSearchService: DatSearchService,
    private readonly produktConfigResolveService: ProduktConfigResolveService,
    private readonly produktDetailResolveService: ProduktDetailResolveService,
    private readonly displayService: DisplayService,
    private readonly router: Router,
    private readonly activateRoute: ActivatedRoute) {
    Assert.notNullOrUndefined(produktConfigResolveService, 'produktConfigResolveService');
    Assert.notNullOrUndefined(produktDetailResolveService, 'produktDetailResolveService');
    Assert.notNullOrUndefined(datSearchService, 'datSearchService');
    Assert.notNullOrUndefined(displayService, 'displayService');
    Assert.notNullOrUndefined(router, 'router');
    Assert.notNullOrUndefined(activateRoute, 'activateRoute');
  }

  public ngOnInit(): void {
    this.buildLinks();
    this.navigateToFirstLinkIfNoActiveLinks();
    this.registerNavigationEndSubscription();
    this.datRequestActive$ = this.datSearchService.getDatRequestActive();
    this.visible$ = this.displayService.showNav;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  public next(): void {
    const nextLinkIndex = this.links.findIndex(link => this.router.isActive(this.router.createUrlTree([link.path], {
      relativeTo: this.activateRoute
    }), true)) + 1;
    this.router.navigate([this.links[nextLinkIndex >= this.links.length ? 0 : nextLinkIndex].path], {
      relativeTo: this.activateRoute
    });
  }

  public prev(): void {
    const nextLinkIndex = this.links.findIndex(link => this.router.isActive(this.router.createUrlTree([link.path], {
      relativeTo: this.activateRoute
    }), true)) - 1;
    this.router.navigate([this.links[nextLinkIndex < 0 ? (this.links.length - 1) : nextLinkIndex].path], {
      relativeTo: this.activateRoute
    });
  }

  private buildLinks(): void {
    const produkt = this.produktDetailResolveService.get();
    const config = this.produktConfigResolveService.get();
    this.links = config.features
      .filter(links => {
        if (produkt.art === ProduktArt.VtiTooling) {
          if (links.name === PRODUKT_CONFIG_FEATURES.Adressen.name) {
            return false;
          }
          return (links.name === PRODUKT_CONFIG_FEATURES.VtiAbschluss.name || !!produkt[links.name]);
        } else {
          if (links.name === PRODUKT_CONFIG_FEATURES.Kalkulation.name) {
            return false;
          }
          return (links.name === PRODUKT_CONFIG_FEATURES.Abschluss.name || !!produkt[links.name]);
        }
      })
      .map(x => {
        const link = LINKS[x.name];
        Assert.notNullOrUndefined(link, 'link');
        return link;
      });
  }

  private navigateToFirstLinkIfNoActiveLinks(): void {
    const activeLinkIndex = this.links.findIndex(link => this.router.isActive(this.router.createUrlTree([link.path], {
      relativeTo: this.activateRoute
    }), true));
    if (activeLinkIndex === -1) {
      this.router.navigate([this.links[0].path], {
        relativeTo: this.activateRoute,
        replaceUrl: true
      });
    }
  }

  private registerNavigationEndSubscription(): void {
    this.subscriptions.push(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd
          || event instanceof NavigationCancel
          || event instanceof NavigationError)
      ).subscribe(() => this.nav.update()));
  }
}
