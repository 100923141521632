<ng-container *ngFor="let field of fields;trackBy:trackByField">
    <ng-container [ngSwitch]="field.arrayName">
        <!-- RadSaetze -->
        <ng-container *ngSwitchCase="'radSaetze'">
            <app-card class="satz"
                      *ngFor="let radsatz of form.get(field.arrayName).controls;trackBy:trackByIndex; let i = index"
                      spacing="8px">
                <h3>{{('raeder.radsatz.' + i) | translate}}</h3>
                <app-select [form]="radsatz" [name]="'lagerort'" [displayName]="name + '.' + 'lagerort'"
                            [values]="valuesLagerort"
                            [valuePrefix]="name + '.'">
                </app-select>

                <app-tab-group>
                    <app-tab [label]="(name + '.linkeSeite') | translate">
                        <ng-container *ngTemplateOutlet="achse, context: { radsatzIndex: i, seite: 0, radsatz: radsatz}">
                        </ng-container>
                    </app-tab>

                    <app-tab [label]="(name + '.rechteSeite') | translate">
                        <ng-container *ngTemplateOutlet="achse, context: { radsatzIndex: i, seite: 1, radsatz: radsatz}">
                        </ng-container>
                    </app-tab>
                </app-tab-group>

                <button class="button delete-button" type="button" mat-icon-button
                        (click)="onRemoveSatzClick(i)">
                    <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
                </button>

            </app-card>
            <app-card *ngIf="form.get(field.arrayName).controls.length !== 2" spacing="8px">
                <app-button (action)="onAddSatzClick()">
                    <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
                    {{'nfzRaeder.addsatz' | translate}}
                </app-button>
            </app-card>
        </ng-container>
    </ng-container>
</ng-container>

<app-card>
    <h3>{{'raeder.sonstiges' | translate}}</h3>
    <ng-container *ngFor="let field of fields;trackBy:trackByField">
        <ng-container [ngSwitch]="field.name || field.arrayName">
            <!-- Notlaufeinrichtung -->
            <ng-container *ngSwitchCase="'notlaufeinrichtung'">
                <app-select [form]="form" [name]="field.name" [displayName]="name + '.' + field.name"
                            [values]="notlaufeinrichtung" [valuePrefix]="name + '.'">
                </app-select>
            </ng-container>
            <!-- Bemerkungen -->
            <ng-container *ngSwitchCase="'bemerkungen'">
                <app-textbausteine #bemerkungen [form]="form" [name]="'bemerkungen'" [displayName]="'Bemerkungen'"
                                   [feature]="'raeder'" [produktArt]="this.produkt.art">
                </app-textbausteine>
            </ng-container>
        </ng-container>
    </ng-container>
</app-card>
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)">
</app-produkt-detail-notizen-button>


<ng-template #achse  let-radsatzIndex="radsatzIndex" let-seite="seite" let-radsatz="radsatz">

    <app-accordion *ngFor="let achse of radsatz.get('achsen').controls;trackBy:trackByIndex; let indexAchse = index"
                   [multi]="false">
        <app-expansion-panel>

            <app-expansion-header>
                <app-panel-title class="title">
                    <strong>{{indexAchse + 1 + '. Achse'}}</strong>
                </app-panel-title>
            </app-expansion-header>
            <app-input *ngIf="seite === 0" [form]="achse" [name]="'bewertung'" [displayName]="name + '.' + 'bewertung'"
                       type="text">
            </app-input>

            <app-accordion [multi]="false">
                <app-expansion-panel
                    *ngFor="let rad of achse.get('seiten').controls[seite].get('achsenRaeder').controls;trackBy:trackByIndex; let indexRad = index">
                    <app-expansion-header>
                        <app-panel-title class="title">
                            <span>{{indexRad + 1 + '. Rad'}}</span>
                        </app-panel-title>
                    </app-expansion-header>
                    <app-produkt-detail-nfz-raeder-rad [form]="rad" [name]="'achsenRaeder'"
                                                       [fields]="this.fieldsAchsenRaeder">
                    </app-produkt-detail-nfz-raeder-rad>
                    <app-button (action)="onDuplicateRadClick(achse, seite, rad)">
                        <app-mat-icon icon="icon-duplicate-report" [newStyle]="true" class="new-style"></app-mat-icon>
                        {{indexRad + 1 + '. ' + ('achsenRaeder.duplicateRad' | translate)}}
                    </app-button>
                    <app-button mat-icon-button
                          (click)="onDeleteRadClick(achse, seite, indexRad)">
                        <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
                        {{('achsenRaeder.deleteRad' | translate)}}
                    </app-button>
                </app-expansion-panel>
            </app-accordion>

            <div>
                <app-button (action)="onAddRadClick(achse, seite)">
                    <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
                    {{'achsenRaeder.addRad' | translate}}
                </app-button>
                <app-button (action)="onDuplicateAchseClick(radsatz, achse)">
                    <app-mat-icon icon="icon-duplicate-report" [newStyle]="true" class="new-style"></app-mat-icon>
                    {{indexAchse + 1 + '. ' + ('achsenRaeder.duplicateAchse' | translate)}}
                </app-button>
                <app-button mat-icon-button
                      (click)="onDeleteAchseClick(radsatz, indexAchse)">
                    <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
                    {{('achsenRaeder.deleteAchse' | translate)}}
                </app-button>
            </div>
        </app-expansion-panel>
    </app-accordion>

    <div>
        <app-button (action)="onAddAchseClick(radsatz)">
            <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
            {{'achsenRaeder.addAchse' | translate}}
        </app-button>
      <app-button *ngIf="seite === 0" (action)="onDuplicateSeiteClick(radsatz, radsatzIndex, seite)">
        <app-mat-icon icon="icon-duplicate-report" [newStyle]="true" class="new-style"></app-mat-icon>
        {{(seite === 0 ? 'Linke ' : 'Rechte ') + ('achsenRaeder.duplicateSeite' | translate)}}
      </app-button>
        <app-button *ngIf="form.get('radSaetze').controls.length <= 1" (action)="onDuplicateSatzClick(radsatzIndex)">
            <app-mat-icon icon="icon-duplicate-report" [newStyle]="true" class="new-style"></app-mat-icon>
            {{radsatzIndex + 1 + '. ' + ('nfzRaeder.duplicatesatz' | translate)}}
        </app-button>
    </div>
</ng-template>
