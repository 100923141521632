import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { FormControlBase } from '../form-control-base.component';
import { CustomDateAdapter } from './date.format.adapter';
import { DateFormatService, DEFAULT_FORMAT, MONTH_YEAR_FORMAT } from './date.format.service';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CustomDateAdapter,
    DateFormatService,
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [DateFormatService, Platform] },
  ]
})
export class DateComponent extends FormControlBase implements OnInit {
  @ViewChild(MatInput, { static: true, read: ElementRef })
  public input: ElementRef<HTMLElement>;

  @ViewChild(MatDatepicker, { static: true })
  public datepicker: MatDatepicker<Date>;

  @Input()
  public hasDatepicker = true;

  @Input()
  public readonly: boolean;

  @Input()
  public startView: 'month' | 'year' | 'multi-year' = 'multi-year';

  @Input()
  public min: Date;

  @Input()
  public max: Date;

  @Input()
  public value: Date;

  @Input()
  public disableDay: boolean;

  @Input()
  public disabled = false;

  @Output()
  public focusInputField = new EventEmitter<any>();

  @Output()
  public dateChange = new EventEmitter<any>();

  public required = false;

  @HostListener('keydown', ['$event'])
  public onKeydown(event: KeyboardEvent): void {
    // eslint-disable-next-line
    if (!(event.key === 'Enter' || event.keyCode === 13)) {
      return;
    }
    if (!event.shiftKey) {
      this.next();
    } else {
      this.prev();
    }
  }

  constructor(formControlFocusService: FormControlFocusService, private readonly dateFormatService: DateFormatService) {
    super(formControlFocusService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.dateFormatService.format = this.disableDay ? MONTH_YEAR_FORMAT : DEFAULT_FORMAT;
    this.setRequired();
  }

  public blur(): void {
    this.input.nativeElement.blur();
  }

  public focus(): void {
    this.input.nativeElement.focus();
    if (this.hasDatepicker) {
      this.datepicker.open();
    }
  }

  public chosenYearHandler(normalizedYear: Moment): void {
    const ctrlValue = moment(this.control.value ? this.control.value : undefined);
    if (this.disableDay) {
      ctrlValue.date(1);
    }
    ctrlValue.year(normalizedYear.year());
    this.control.setValue(ctrlValue.toISOString());
  }

  public chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    const ctrlValue = moment(this.control.value ? this.control.value : undefined);
    ctrlValue.month(normalizedMonth.month());
    this.control.setValue(ctrlValue.toISOString());

    if (this.disableDay) {
      datepicker.close();
    }
  }

  private setRequired(): void {
    if (!this.form || !this.control) {
      return;
    }

    const validator = this.control?.validator?.({} as AbstractControl);
    if (validator && validator.required) {
      this.required = true;
    }
  }
}
