import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { File } from '@app/class/file';
import { FileData } from '@app/class/file-data';
import { FileSize } from '@app/enum/file-size.enum';
import { FileService } from '@app/service/file.service';
import { Assert } from '@shared/helper/assert';
import { FileDialogService } from '@shared/service/file-dialog.service';
import { ImageEditorService } from '@shared/service/image-editor.service';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, flatMap, takeUntil, tap } from 'rxjs/operators';

interface FileInput {
  id: string;
  thumb: boolean;
}

interface FileResult extends FileInput {
  file?: File;
  image?: boolean;
}

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent implements OnInit, OnDestroy {
  private fileInput: FileInput = {
    id: undefined,
    thumb: true
  };
  private fileInputChange = new Subject<FileInput>();
  private fileInputChangeSubscription: Subscription;

  public result$ = new BehaviorSubject<FileResult>(undefined);

  @Input()
  public buttonLabel: string;

  @Input()
  public set fileId(id: string) {
    this.fileInput.id = id;
    this.fileInputChange.next(this.fileInput);
  }

  @Input()
  public set thumb(thumb: boolean) {
    this.fileInput.thumb = thumb;
    this.fileInputChange.next(this.fileInput);
  }

  @Output()
  public save = new EventEmitter<FileData<ArrayBuffer>>();

  @Output()
  public buttonClick = new EventEmitter<string>();

  constructor(
    private readonly fileService: FileService,
    private readonly fileDialog: FileDialogService,
    private readonly imageEditorService: ImageEditorService) {
    Assert.notNullOrUndefined(fileService, 'file');
    Assert.notNullOrUndefined(fileDialog, 'fileDialog');
    Assert.notNullOrUndefined(imageEditorService, 'imageEditorService');
  }

  public ngOnInit(): void {
    this.fileInputChangeSubscription = this.fileInputChange.pipe(
      tap(() => this.clear()),
      debounceTime(100)
    ).subscribe(input => this.loadFile(input));
    this.loadFile(this.fileInput);
  }

  public ngOnDestroy(): void {
    this.fileInputChangeSubscription?.unsubscribe();
  }

  public onFileClick(fileResult: FileResult): void {
    if (!fileResult.file && !fileResult.image) {
      return;
    }

    if (!fileResult.thumb) {
      return;
    }

    if (this.buttonLabel) {
      this.fileDialog.displayFile(fileResult.file.name || '', fileResult.id, [this.buttonLabel]).subscribe(
        result => {
          if (result?.data?.fileId) {
            this.buttonClick.emit(result.data.fileId);
          }
        }
      );
    } else {
      this.fileDialog.displayFile(fileResult.file.name || '', fileResult.id);
    }
  }

  public onReloadClick(): void {
    this.fileInputChange.next(this.fileInput);
  }

  public edit(): void {
    const {image, file} = this.result$.value;
    if (image && file) {
      this.fileService.get(file.id, FileSize.Fullscreen).pipe(
        flatMap(data => this.imageEditorService.edit(data))
      ).subscribe(result => {
        this.save.next(result);
      });
    } else {
      alert('todo');
    }
  }

  private loadFile(input: FileInput): void {
    const size = input.thumb ? FileSize.Thumbnail : FileSize.Fullscreen;
    this.fileService.get(input.id, size).pipe(
      takeUntil(this.fileInputChange),
      catchError(() => of(null)),
    ).subscribe(file => this.result$.next({
      ...input,
      file,
      image: file?.type?.startsWith('image/')
    }));
  }

  private clear(): void {
    this.result$.next(undefined);
  }
}
