export const environment = {
    name: 'NRO',
    production: false,
    appUrl: 'http://localhost:4200',
    aws: {
      region: 'eu-central-1',
      appSync: {
        url: 'https://ednz3rykazd27khrixf6w7ia2e.appsync-api.eu-central-1.amazonaws.com/graphql'
      },
      cognito: {
        url: 'https://auth.nro.bewertung-stage.gtue.de',
        endsession: 'https://webservicestesterde.gtue.de/identity/connect/endsession',
        clientId: '1f3hb7ks75dbsd9s8j5ddstpis',
        userPoolId: 'Hw9tWvbyN',
        identityPoolId: '80e573d1-ff18-4663-b647-3db1712baae2'
      },
      apiGateway: {
        url: 'https://api.nro.bewertung-stage.gtue.de'
      },
      s3: {
        upload: {
          name: 'gtue-evaluate-mobility-produkt-upload-nro'
        },
        entwurf: {
          name: 'gtue-evaluate-mobility-entwurf-pdf-nro'
        },
        einstellungen: {
          name: 'gtue-evaluate-mobility-einstellungen-bucket-nro'
        }
      }
    },
    bvws: {
      combiPlusOnline: {
        url: 'https://online.stag.combi-plus.de/Account/GtueSignIn'
      }
    }
};
