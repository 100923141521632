import { Injectable } from '@angular/core';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProduktUebersichtResolveService {

    private produkte: Produkt[];

    constructor(
        private readonly produktService: ProduktService) {
        Assert.notNullOrUndefined(produktService, 'produktService');
    }

    public get(): Produkt[] {
        return this.produkte;
    }

    public resolve(identnummer?: string): Observable<Produkt[]> {
        return this.produktService.get(identnummer).pipe(
            tap(produkte => this.produkte = produkte)
        );
    }
}
