<app-fluid-container [disabled]="!fluid">
    <ng-container *ngFor="let field of (visibleFields || fields);trackBy:trackByField">
        <ng-container [ngSwitch]="field.name || field.groupName || field.arrayName">
            <!-- Kennzeichen -->
            <app-fluid-cell *ngSwitchCase="'kennzeichen'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- Identnummer -->
            <app-fluid-cell *ngSwitchCase="'identnummer'">
                <ng-container *ngTemplateOutlet="textDAT, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- CodeGroup -->
            <app-fluid-cell *ngSwitchCase="'codeGroup'">
                <ng-container *ngTemplateOutlet="codeGroup">
                </ng-container>
            </app-fluid-cell>
            <!-- ZulassungGroup -->
            <app-fluid-cell *ngSwitchCase="'zulassungGroup'">
                <ng-container *ngTemplateOutlet="zulassungGroup">
                </ng-container>
            </app-fluid-cell>
            <!-- Erstzulassung für Required Fields -->
            <app-fluid-cell *ngSwitchCase="'erstzulassung'">
                <ng-container *ngTemplateOutlet="dateErstzulassung, context: { field: field, max: now }">
                </ng-container>
            </app-fluid-cell>
            <!-- Laufleistung -->
            <app-fluid-cell *ngSwitchCase="'laufleistung'">
                <ng-container *ngTemplateOutlet="distanceKm, context: { field: field, unit: 'laufleistungEinheit' }">
                </ng-container>
                <!-- Zusätzliche Laufleistung -->
                <div *ngIf="laufleistungenVisible" class="additional-laufleistung-container">
                    <app-button *ngIf="buttonLaufleistungenIndex0Visible$ | async" #laufleistungenIndex0Button
                                class="btn-add-laufleistung"
                                [type]="Buttontype.Stroked" [color]="'primary'"
                                (action)="onAddLaufleistungen(0)">{{name + '.addLaufleistung' | translate}}</app-button>
                    <ng-container *ngIf="formLaufleistungen.controls.at(0) as control">
                        <ng-container *ngTemplateOutlet="distanceKmAdditional, context: { control: control, index: 0 }">
                        </ng-container>
                    </ng-container>

                    <app-button *ngIf="buttonLaufleistungenIndex1Visible$ | async" #laufleistungenIndex1Button
                                class="btn-add-laufleistung"
                                [type]="Buttontype.Stroked" [color]="'primary'"
                                (action)="onAddLaufleistungen(1)">{{name + '.addLaufleistung' | translate}}</app-button>
                    <ng-container *ngIf="formLaufleistungen.controls.at(1) as control">
                        <ng-container *ngTemplateOutlet="distanceKmAdditional, context: { control: control, index: 1 }">
                        </ng-container>
                    </ng-container>
                </div>
            </app-fluid-cell>

            <!-- BauformGroup -->
            <ng-container *ngIf="this.produkt?.art === 6; else defaultBauform">
                <app-fluid-cell *ngSwitchCase="'acBauformGroup'">
                    <ng-container *ngTemplateOutlet="acBauformGroup">
                    </ng-container>
                </app-fluid-cell>
            </ng-container>
            <ng-template #defaultBauform>
                <app-fluid-cell *ngSwitchCase="'bauformGroup'">
                    <ng-container *ngTemplateOutlet="bauformGroup">
                    </ng-container>
                </app-fluid-cell>
            </ng-template>
            
            
            <!-- Fahrzeugart für Required Fields-->
            <app-fluid-cell *ngSwitchCase="'fahrzeugart'">
                <ng-container *ngTemplateOutlet="select, context: { field: field, values: fahrzeugart }">
                </ng-container>
            </app-fluid-cell>
            <!-- Bauform für Required Fields -->
            <app-fluid-cell *ngSwitchCase="'bauform'">
                <ng-container *ngTemplateOutlet="select, context: { field: field, values: bauform }">
                </ng-container>
            </app-fluid-cell>
            <!-- Hersteller -->
            <app-fluid-cell *ngSwitchCase="'hersteller'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- Modell -->
            <app-fluid-cell *ngSwitchCase="'modell'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- Untertyp -->
            <app-fluid-cell *ngSwitchCase="'untertyp'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- Lackcode -->
            <app-fluid-cell *ngSwitchCase="'lackcode'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <app-fluid-cell *ngSwitchCase="'acLackfarbeGroup'">
                <ng-container *ngTemplateOutlet="acLackfarbeGroup, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- Innenausstattung -->
            <app-fluid-cell *ngSwitchCase="'innenausstattung'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- Polsterfarbe -->
            <app-fluid-cell *ngSwitchCase="'polsterfarbe'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- Polstermaterial -->
            <app-fluid-cell *ngSwitchCase="'polstermaterial'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- Antriebstechnologie -->
            <app-fluid-cell *ngSwitchCase="'antriebstechnologie'">
                <ng-container *ngIf="!this.isAcProdukt">
                    <ng-container *ngTemplateOutlet="select, context: { field: field, values: antriebstechnologie }">
                    </ng-container>
                </ng-container>
            </app-fluid-cell>
            <!-- Antriebstechnologie -->
            <app-fluid-cell *ngSwitchCase="'acAntriebstechnologie'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- Antriebsart -->
            <app-fluid-cell *ngSwitchCase="'antriebsart'">
                <ng-container *ngTemplateOutlet="select, context: { field: field, values: antriebsart }">
                </ng-container>
            </app-fluid-cell>
            <!-- Schadstoffklasse -->
            <app-fluid-cell *ngSwitchCase="'schadstoffklasse'">
                <ng-container *ngIf="!this.isAcProdukt">

                <ng-container *ngTemplateOutlet="select, context: { field: field, values: schadstoffklasse }">
                </ng-container>
                </ng-container>
            </app-fluid-cell>
            <!-- AC Schadstoffklasse -->
            <app-fluid-cell *ngSwitchCase="'acSchadstoffklasse'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- MotorGroup -->
            <app-fluid-cell *ngSwitchCase="'motorGroup'">
                <ng-container *ngTemplateOutlet="motorGroup">
                </ng-container>
            </app-fluid-cell>
            <!-- GetriebeGroup -->
            <app-fluid-cell *ngSwitchCase="'getriebeGroup'">
                <ng-container *ngTemplateOutlet="getriebeGroup">
                </ng-container>
            </app-fluid-cell>
            <!-- GewichtGroup -->
            <app-fluid-cell *ngSwitchCase="'gewichtGroup'">
                <ng-container *ngTemplateOutlet="gewichtGroup">
                </ng-container>
            </app-fluid-cell>
            <!-- AnzahlGroup -->
            <app-fluid-cell *ngSwitchCase="'anzahlGroup'">
                <ng-container *ngTemplateOutlet="anzahlGroup">
                </ng-container>
            </app-fluid-cell>
            <!-- MasseGroup -->
            <app-fluid-cell *ngSwitchCase="'masseGroup'">
                <ng-container *ngTemplateOutlet="masseGroup">
                </ng-container>
            </app-fluid-cell>
            <!-- AnzahlVorbesitzer -->
            <app-fluid-cell *ngSwitchCase="'anzahlVorbesitzer'">
                <ng-container *ngTemplateOutlet="select, context: { field: field, values: anzahlVorbesitzer }">
                </ng-container>
            </app-fluid-cell>
            <!-- ScheckheftGepflegt -->
            <app-fluid-cell *ngSwitchCase="'scheckheftGepflegt'">
                <ng-container *ngTemplateOutlet="select, context: { field: field, values: scheckheftGepflegt, hasEmptyField: false}">
                </ng-container>
            </app-fluid-cell>

            <!-- HV-Batterie -->
            <app-fluid-cell *ngSwitchCase="'hvBatterie'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- AlphaController -->
            <app-fluid-cell *ngSwitchCase="'acFahrzeugart'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <app-fluid-cell *ngSwitchCase="'acBauform'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <app-fluid-cell *ngSwitchCase="'acEhemaligeNutzung'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- Bemerkungen -->
            <app-fluid-cell *ngSwitchCase="'bemerkungen'">
                <app-textbausteine #bemerkungen [form]="form" [name]="'bemerkungen'" [displayName]="'Bemerkungen'"
                                   [feature]="'Fahrzeug'" [produktArt]="this.produkt.art">
                </app-textbausteine>
            </app-fluid-cell>
            <!-- DatECode -->
            <app-fluid-cell *ngSwitchCase="'datECode'">
                <ng-container *ngTemplateOutlet="text, context: { field: field }">
                </ng-container>
            </app-fluid-cell>
            <!-- FahrzeugExternalServiceReference -->
            <ng-container *ngSwitchCase="'fahrzeugExternalServiceReference'">
                <ng-container *ngIf="form.get(field.name) as control">
                    <p class="form-error" *ngIf="(control.touchedChanges | async) && !(control.validChanges | async)">
                        {{'fahrzeug.fahrzeugExternalServiceReference.invalid' | translate}}
                    </p>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</app-fluid-container>

<!-- CodeGroup -->
<ng-template #codeGroup>
    <app-form-group cols="2">
        <!-- HerstellerCode -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="text, context: { field: {
                            name: 'herstellerCode'
                        } }">
            </ng-container>
        </app-form-field>
        <!-- TypCode -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="textKBA, context: { field: {
                            name: 'typCode'
                        } }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- ZulassungGroup -->
<ng-template #zulassungGroup>
    <app-form-group cols="2">
        <!-- Erstzulassung -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="dateErstzulassung, context: { field: {
                            name: 'erstzulassung'
                        }, max: now }">
            </ng-container>
        </app-form-field>
        <!-- LetzteZulassung -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="date, context: { field: {
                            name: 'letzteZulassung'
                        }, max: now }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- BauformGroup -->
<ng-template #bauformGroup>
    <app-form-group cols="2">
        <!-- Fahrzeugart -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="selectBauformGroup, context: { field: {
                            name: 'fahrzeugart'
                        }, values: fahrzeugart }">
            </ng-container>
        </app-form-field>
        <!-- Bauform -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="selectBauformGroup, context: { field: {
                            name: 'bauform'
                        }, values: bauform }">
            </ng-container>
        </app-form-field>
        <!-- Fahrzeugart manuell -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="textFahrzeugart, context: { field: {
                name: 'fahrzeugartManuell'
            } }">
            </ng-container>
        </app-form-field>
        <!-- Bauform manuell -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="textBauform, context: { field: {
                name: 'bauformManuell'
            }, readonly: true }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>

<ng-template #acBauformGroup>
    <app-form-group cols="2">
        <!-- Fahrzeugart -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="text, context: { field: {
                            name: 'acFahrzeugart'
                        } }">
            </ng-container>
        </app-form-field>
        <!-- Bauform -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="text, context: { field: {
                            name: 'acBauform'
                        } }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>
<ng-template #acLackfarbeGroup>
    <app-form-group cols="3">
        <!-- Fahrzeugart -->
        <app-form-field colspan="2">
            <ng-container *ngTemplateOutlet="text, context: { field: {
                            name: 'acLackfarbe'
                        } }">
            </ng-container>
        </app-form-field>
        <!-- Bauform -->
        <app-form-field colspan="1">
            <ng-container *ngTemplateOutlet="text, context: { field: {
                            name: 'acLackierung'
                        } }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- MotorGroup -->
<ng-template #motorGroup>
    <app-form-group cols="3">
        <!-- Hubraum -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="hubraum, context: { field: {
                name: 'hubraum'
            } }">
            </ng-container>
        </app-form-field>
        <!-- Leistung -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="leistung, context: { field: {
                name: 'leistung'
            } }">
            </ng-container>
        </app-form-field>
        <!-- Zylinder -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="number, context: { field: {
                    name: 'zylinder'
                } }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- GetriebeGroup -->
<ng-template #getriebeGroup>
    <app-form-group cols="3">
        <!-- Getriebeart -->
        <app-form-field *ngIf="isAcProdukt" colspan="2">
            <ng-container *ngTemplateOutlet="text, context: { field: {
                        name: 'acGetriebeart'
                     }}">
            </ng-container>
        </app-form-field>
        <app-form-field *ngIf="!isAcProdukt" colspan="2">
            <ng-container *ngTemplateOutlet="select, context: { field: {
                        name: 'getriebeart'
                     },
                     values: getriebeart }">
            </ng-container>
        </app-form-field>
        <!-- AnzahlGaenge -->
        <app-form-field colspan="1">
            <ng-container *ngTemplateOutlet="number, context: { field: {
                            name: 'anzahlGaenge'
                        } }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- GewichtGroup -->
<ng-template #gewichtGroup>
    <app-form-group cols="2">
        <!-- Leergewicht -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="gewicht, context: { field: {
                            name: 'leergewicht'
                        } }">
            </ng-container>
        </app-form-field>
        <!-- ZulaessigesGesamtgewicht -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="gewicht, context: { field: {
                            name: 'zulaessigesGesamtgewicht'
                        } }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- AnzahlGroup -->
<ng-template #anzahlGroup>
    <app-form-group cols="2">
        <!-- AnzahlTueren -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="number, context: { field: {
                name: 'anzahlTueren'
            } }">
            </ng-container>
        </app-form-field>
        <!-- AnzahlSitze -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="number, context:  { field: {
                name: 'anzahlSitze'
            } }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- MasseGroup -->
<ng-template #masseGroup>
    <app-form-group cols="3">
        <!-- MasseLaenge -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="distanceMm, context: { field: {
                            name: 'masseLaenge'
                        } }">
            </ng-container>
        </app-form-field>
        <!-- MasseBreite -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="distanceMm, context: { field: {
                            name: 'masseBreite'
                        } }">
            </ng-container>
        </app-form-field>
        <!-- MasseHoehe -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="distanceMm, context: { field: {
                            name: 'masseHoehe'
                        } }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>

<!-- Templates -->
<ng-template #textDAT let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="vin" inputmode="text"
               [vinDisabled]="vinDisabled || !(datRequestEnabled$ | async)">
        <ng-container appSuffix *ngIf="form.get(field.name) as control">
            <ng-container *ngIf="control.validChanges | async as valid; else capture">
                <ng-container *ngIf="!(datRequestActive$ | async);else loading">
                    <app-mini-fab *ngIf="!this.isAcProdukt" [color]="'basic'" (action)="onVinSearchClick($event)"
                                  [disabled]="(datRequestActive$ | async) || !(datRequestEnabled$ | async)"
                                  [tooltip]="(datRequestEnabled$ | async) ? '' : (name + '.fin.tooltip' | translate)">
                        <app-mat-icon icon="search"></app-mat-icon>
                    </app-mini-fab>
                </ng-container>
            </ng-container>
            <ng-template #capture>
                <app-mini-fab [color]="'basic'" (action)="onVinCaptureClick($event)">
                    <app-mat-icon icon="camera"></app-mat-icon>
                </app-mini-fab>
            </ng-template>
        </ng-container>
    </app-input>
</ng-template>

<ng-template #loading>
    <app-progress-spinner class="loading-spinner" [diameter]="25"></app-progress-spinner>
</ng-template>

<ng-template #textKBA let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" inputmode="text">
        <ng-container appSuffix *ngIf="[form.get('herstellerCode'), form.get('typCode')] as controls">
            <app-mini-fab [color]="'basic'" (action)="onKbaSearchClick($event)"
                          [disabled]="!(controls[0].validChanges | async) || !(controls[1].validChanges | async)
                          || (kbaRequestActiveChange | async) || !(datRequestEnabled$ | async)"
                          [tooltip]="(datRequestEnabled$ | async) ? '' : (name + '.kba.tooltip' | translate)">
                <app-mat-icon icon="search"></app-mat-icon>
            </app-mini-fab>
        </ng-container>
    </app-input>
</ng-template>

<ng-template #select let-field="field" let-values="values" let-hasEmptyField="hasEmptyField">
    <app-select [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [values]="values" [hasEmptyField]="hasEmptyField"
                [valuePrefix]="name + '.'">
    </app-select>
</ng-template>

<ng-template #text let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" inputmode="text">
    </app-input>
</ng-template>

<ng-template #number let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
               inputmode="numeric">
    </app-input>
</ng-template>

<ng-template #date let-field="field" let-min="min" let-max="max">
    <app-date [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [min]="min" [max]="max">
    </app-date>
</ng-template>

<ng-template #dateErstzulassung let-field="field" let-min="min" let-max="max">
    <app-date [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [min]="min" [max]="max"
              (dateChange)="setLetzteZulassung($event)">
    </app-date>
</ng-template>

<ng-template #distanceKm let-field="field" let-unit="unit">
    <app-form-group cols="21">
        <app-form-field colspan="8">
            <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
                       inputmode="numeric">
            </app-input>
        </app-form-field>
        <app-form-field colspan="5">
            <app-select [form]="form" [name]="unit" [displayName]="name + '.' + unit"
                        [values]="fahrzeugLaufleistungEinheit"
                        [valuePrefix]="name + '.'" [hasEmptyField]="false">
            </app-select>
        </app-form-field>
        <app-form-field colspan="8">
            <app-select [form]="form" [name]="'laufleistungBeschreibung'"
                        [displayName]="name + '.' + 'laufleistungBeschreibung'"
                        [values]="laufleistungBeschreibung$ | async"
                        (selectionChange)="onBeschreibungChange($event)"
                        [valuePrefix]="name + '.'" [hasEmptyField]="false">
            </app-select>
        </app-form-field>
    </app-form-group>
</ng-template>

<ng-template #distanceKmAdditional let-control="control" let-index="index">
    <app-form-group cols="21" [hasDeleteButton]="true" (delete)="onRemoveLaufleistungen(index)">
        <app-form-field colspan="8">
            <app-input [form]="control" [name]="'wert'" [displayName]="name + '.' + 'wert'" type="text"
                       inputmode="numeric">
            </app-input>
        </app-form-field>
        <app-form-field colspan="5">
            <app-select [form]="control" [name]="'einheit'" [displayName]="name + '.' + 'einheit'"
                        [values]="fahrzeugLaufleistungEinheit"
                        [valuePrefix]="name + '.'" [hasEmptyField]="false">
            </app-select>
        </app-form-field>
        <app-form-field colspan="8">
            <app-select [form]="control" [name]="'beschreibung'" [displayName]="name + '.' + 'beschreibung'"
                        [values]="index === 0 ? (laufleistungenBeschreibungFirst$ | async) : (laufleistungenBeschreibungSecond$ | async)"
                        [valuePrefix]="name + '.'" [hasEmptyField]="false"
                        (selectionChange)="onLaufleistungenBeschreibungChange($event, index)">
            </app-select>
        </app-form-field>
    </app-form-group>
</ng-template>

<ng-template #distanceMm let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
               inputmode="numeric">
        <ng-container appSuffix>&nbsp;mm</ng-container>
    </app-input>
</ng-template>
<ng-template #hubraum let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
               inputmode="numeric">
        <ng-container appSuffix>&nbsp;ccm</ng-container>
    </app-input>
</ng-template>
<ng-template #leistung let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
               inputmode="numeric">
        <ng-container appSuffix>&nbsp;kW</ng-container>
    </app-input>
</ng-template>
<ng-template #gewicht let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
               inputmode="numeric">
        <ng-container appSuffix>&nbsp;kg</ng-container>
    </app-input>
</ng-template>
<ng-template #textFahrzeugart let-field="field" let-readonly="manuellFahrzeugartDisabled$">
    <app-input (selectionChange)="($event)" [disabled]="manuellFahrzeugartDisabled$ | async" [form]="form"
               [name]="field.name" [displayName]="name + '.' + field.name" type="text" inputmode="text">
    </app-input>
</ng-template>
<ng-template #textBauform let-field="field" let-readonly="manuellBauformDisabled$">
    <app-input (selectionChange)="($event)" [disabled]="manuellBauformDisabled$ | async" [form]="form"
               [name]="field.name" [displayName]="name + '.' + field.name" type="text" inputmode="text">
    </app-input>
</ng-template>
<ng-template #selectBauformGroup let-field="field" let-values="values">
    <app-select (selectionChange)="onChangeBauformGroup($event.value, field.name)" [form]="form" [name]="field.name"
                [displayName]="name + '.' + field.name" [values]="values" [valuePrefix]="name + '.'">
    </app-select>
</ng-template>

<!-- Confirm dialog -->
<ng-template #dialogConfirm>
    <mat-label>{{ 'fahrzeug.confirm.text' | translate}}</mat-label>
</ng-template>

<!-- Confirm dialog -->
<ng-template #dialogSelectProdukt let-data="data">
    <mat-label>{{('fahrzeug.selectProdukt' | translate) + data.identnummer }}</mat-label>
    <mat-radio-group #group class="single">
        <mat-radio-button *ngFor="let produkt of data.produkte; index as index" [value]="produkt"
                          (change)="setSelectedProduktIndex(index)" [checked]="selectedProduktIndex == index">
            {{(produkt.erstelltAm | date) + ' ' + (produkt.auftrag.nummer ? produkt.auftrag.nummer : '')}}
        </mat-radio-button>
    </mat-radio-group>

</ng-template>
