import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-divider',
  templateUrl: './divider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerComponent {
  @Input()
  public spacing = '0px';

  @Input()
  public vertical = false;

  @HostBinding('style.display')
  public get display(): string {
    return !this.vertical ? 'block' : 'inline-block';
  }
}
