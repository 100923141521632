import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { Assert } from '@shared/helper/assert';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deleteAdresse, saveAdresse } from '../../graphql/mutations';
import { Adresse, AdresseInput, Produkt } from '../../schema/type';
import { ProduktService } from '../produkt.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktAdressenService {
    constructor(
        private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider,
        private readonly produktService: ProduktService) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
        Assert.notNullOrUndefined(produktService, 'produktService');
    }

    public saveAdresse(produktId: string, adressId: string, adresse: Adresse): Observable<boolean> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrEmpty(adressId, 'adressId');
        Assert.notNullOrUndefined(adresse, 'adresse');

        const input = this.mapInput(adresse);
        const client = this.awsAppSyncClientProvider.provide();

        const mutatePromise = client.mutate({
            mutation: saveAdresse,
            variables: {
                id: produktId,
                adressId,
                adresse: input,
            },
            optimisticResponse: {
                saveAdresse: true
            },
            update: store => this.produktService.updateGetByIdCache(store, produktId, produkt => this.add(produkt, adresse)),
        });
        return from(mutatePromise).pipe(map(response => response.data.saveAdresse));
    }

    public deleteAdresse(produktId: string, adressId: string): Observable<boolean> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrEmpty(adressId, 'adressId');

        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client.mutate({
            mutation: deleteAdresse,
            variables: {
                id: produktId,
                adressId
            },
            optimisticResponse: {
                deleteAdresse: true
            },

            update: store => this.produktService.updateGetByIdCache(store, produktId, produkt => this.delete(produkt, adressId)),
        });
        return from(mutatePromise).pipe(map(response => response.data.deleteAdresse));
    }

    private mapInput(adresse: Adresse): AdresseInput {
        const input: AdresseInput = {
            ...adresse
        };
        return input;
    }

    private add(produkt: Produkt, adresse: Adresse): Produkt {
        Assert.notNullOrUndefined(produkt, 'produkt');
        Assert.notNullOrUndefined(adresse, 'adresse');

        const newAdresse: Adresse = {
            __typename: 'Adresse',
            id: adresse.id,
            createdAt: adresse.createdAt,
            anrede: adresse.anrede || null,
            arten: adresse.arten || null,
            email: adresse.email || null,
            firma: adresse.firma || null,
            mobil: adresse.mobil || null,
            name: adresse.name || null,
            ort: adresse.ort || null,
            postleitzahl: adresse.postleitzahl || null,
            strasseNr: adresse.strasseNr || null,
            telefon: adresse.telefon || null,
            vorname: adresse.vorname || null,
            externalId: adresse.externalId || null,
            bestellnummer: adresse.bestellnummer || null,
        };

        if (produkt.adressen && produkt.adressen.adressen) {
            const adresseIndex = produkt.adressen.adressen.findIndex(x => x.id === adresse.id);
            if (adresseIndex === -1) {
                produkt.adressen.adressen.push(newAdresse);
            } else {
                produkt.adressen.adressen[adresseIndex] = newAdresse;
            }
        }

        return produkt;
    }

    private delete(produkt: Produkt, adressId: string): Produkt {
        Assert.notNullOrUndefined(produkt, 'produkt');
        Assert.notNullOrEmpty(adressId, 'adressId');

        const adresseIndex = produkt.adressen.adressen.findIndex(x => x.id === adressId);
        if (adresseIndex !== -1) {
            produkt.adressen.adressen.splice(adresseIndex, 1);
        }
        return produkt;
    }
}
