import { Injectable } from '@angular/core';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { Assert } from '@shared/helper/assert';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailResolveService {
    private produkt$ = new BehaviorSubject<Produkt>(null);

    constructor(
        private readonly produktService: ProduktService) {
        Assert.notNullOrUndefined(produktService, 'produktService');
    }

    public get(): Produkt {
        return this.produkt$.value;
    }

    public change(): Observable<Produkt> {
        return this.produkt$;
    }

    public resolve(id: string, ignoreCache: boolean): Observable<Produkt> {
        Assert.notNullOrEmpty(id, 'id');
        return this.produktService.getById(id, ignoreCache).pipe(
            tap(produkt => this.produkt$.next(produkt))
        );
    }
}
