<div class="main-content">
    <div class="first-row">
        <div class="toggle">
           <app-toggle-group [value]="uploadArt" (valueChange)="onUploadArtChange($event)">
               <app-toggle [value]="1">
                    <app-mat-icon icon="photo_camera"></app-mat-icon>
                </app-toggle>
                <app-toggle [value]="2">
                   <app-mat-icon icon="folder"></app-mat-icon>
               </app-toggle>
            </app-toggle-group>
        </div>
        <app-button class="delete-all-button" (action)="onDeleteAll()" [disabled]="!(hasImages$ | async)" >
            <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style" size="2rem"></app-mat-icon>
        </app-button>
    </div>
    <app-scene class="scene">
        <app-gltf [modelFileConfigs]="modelFileConfigs" [scaleMin]="0.6" (sceneLoad)="onSceneLoad($event)"
                  (modelLoad)="onModelLoad($event)">
            <ng-container *ngFor="let field of fields;trackBy:trackByField">
                <ng-container [ngSwitch]="field.name">
                    <app-gltf-indicator *ngSwitchCase="'vorneLinks'" [positions]="['fbl']">
                        <ng-container *ngTemplateOutlet="button, context: { field: field }"></ng-container>
                    </app-gltf-indicator>
                    <app-gltf-indicator *ngSwitchCase="'vorneRechts'" [positions]="['btl']">
                        <ng-container *ngTemplateOutlet="button, context: { field: field }"></ng-container>
                    </app-gltf-indicator>
                    <app-gltf-indicator *ngSwitchCase="'hintenLinks'" [positions]="['fbr']">
                        <ng-container *ngTemplateOutlet="button, context: { field: field }"></ng-container>
                    </app-gltf-indicator>
                    <app-gltf-indicator *ngSwitchCase="'hintenRechts'" [positions]="['btr']">
                        <ng-container *ngTemplateOutlet="button, context: { field: field }"></ng-container>
                    </app-gltf-indicator>
                    <app-gltf-indicator *ngSwitchCase="'innenraum'" [positions]="['bbl', 'bbr']">
                        <ng-container *ngTemplateOutlet="button, context: { field: field }"></ng-container>
                    </app-gltf-indicator>
                    <app-gltf-indicator *ngSwitchCase="'sonstiges'" [positions]="['btl', 'btr']">
                        <ng-container *ngTemplateOutlet="button, context: { field: field }"></ng-container>
                    </app-gltf-indicator>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let field of fields;trackBy:trackByField">
                <ng-container [ngSwitch]="field.arrayName">
                    <app-gltf-indicator *ngSwitchCase="'sonstiges'" [positions]="['btl', 'btr']">
                        <ng-container *ngTemplateOutlet="buttonSonstiges, context: { field: field }"></ng-container>
                    </app-gltf-indicator>
                </ng-container>
            </ng-container>
            <div class="button-container" *ngIf="!(isModelLoaded$ | async)">
                <ng-container *ngFor="let field of fields;trackBy:trackByField">
                    <ng-container [ngSwitch]="field.name">
                        <ng-container *ngSwitchCase="'vorneLinks'">
                            <ng-container *ngTemplateOutlet="buttonFixed, context: { field: field }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'vorneRechts'">
                            <ng-container *ngTemplateOutlet="buttonFixed, context: { field: field }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'hintenLinks'">
                            <ng-container *ngTemplateOutlet="buttonFixed, context: { field: field }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'hintenRechts'">
                            <ng-container *ngTemplateOutlet="buttonFixed, context: { field: field }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'innenraum'">
                            <ng-container *ngTemplateOutlet="buttonFixed, context: { field: field }"></ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngFor="let field of fields;trackBy:trackByField">
                    <ng-container [ngSwitch]="field.arrayName">
                        <ng-container *ngSwitchCase="'sonstiges'">
                            <div class="drop-list" cdkDropList (cdkDropListDropped)="drop($event)">
                                <ng-container
                                    *ngTemplateOutlet="buttonFixedSonstiges, context: { field: field }"></ng-container>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </app-gltf>
        <app-object-view-control [target]="scene" [disabled]="true"></app-object-view-control>
    </app-scene>
</div>

<!-- side panel -->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop">
    <h3>{{('uebersicht.bilder' | translate)}}</h3>
    <ng-container *ngTemplateOutlet="uebersichtBilder"></ng-container>
</app-side-panel>

<!-- bottom sheet -->
<app-bottom-sheet *ngIf="(viewport$ | async) !== viewport.Desktop"
                  [title]="('uebersicht.bilder' | translate)">
    <ng-container *ngTemplateOutlet="uebersichtBilder"></ng-container>
</app-bottom-sheet>

<!-- notizen button -->
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)">
</app-produkt-detail-notizen-button>

<!-- templates -->
<ng-template #uebersichtBilder>
    <ng-container [ngSwitch]="count$ | async">
        <div *ngSwitchCase="0"> {{'uebersicht.keineBilder' | translate}}</div>
        <ng-container *ngSwitchDefault>
          <app-file-gallery class="deckblatt-img" *ngIf="this.form.get('deckblatt').value" [title]="name + '.' + 'deckblatt'" [fileIds]="[this.form.get('deckblatt').value]"
                            [showCamera]="false" [showFolder]="false"
                            [resetable]="isFileResetable.bind(this)">
          </app-file-gallery>
            <ng-container *ngFor="let field of fields;trackBy:trackByField">
                <ng-container *ngIf="field.name !== 'deckblatt' && form.get(field.name) as control">
                    <ng-container *ngIf="((control.valueChanges | async) || control.value) as files">
                        <app-file-gallery *ngIf="files.length > 0" [title]="name + '.' + field.name" [fileIds]="files"
                                          [buttonLabel]="'Deckblatt Bild'"
                                          [showCamera]="false" [showFolder]="false"
                                          [resetable]="isFileResetable.bind(this)"
                                          (buttonClick)="onDeckblattSelect($event)"
                                          (delete)="onFileDelete(control, $event)"
                                          (update)="onFileUpdate(control, $event)"
                                          (reset)="onFileReset(control, $event)">
                        </app-file-gallery>
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let field of fields;trackBy:trackByField;">
                <ng-container *ngIf="form.get(field.arrayName) as sonstiges">
                    <div class="drop-list" cdkDropList [class.mobile]="(viewport$ | async) !== viewport.Desktop"
                         (cdkDropListDropped)="drop($event)">
                        <ng-container *ngFor="let sonstige of sonstiges.value;trackBy:trackByField; index as id">
                            <ng-container *ngIf="sonstige as control">
                                <ng-container
                                    *ngIf="((control?.bilder?.valueChanges | async) || control.bilder) as files">

                                    <mat-expansion-panel #panel="matExpansionPanel" class="drag-container" cdkDrag>
                                        <mat-expansion-panel-header>
                                            <app-textbausteine [form]="this.getSonstigesFormGroup(id)"
                                                               [name]="sonstigeFields.Bezeichnungen.name"
                                                               [displayName]="'Bezeichnung'"
                                                               [feature]="'Uebersicht'" [produktArt]="this.produkt.art"
                                                               [multiple]="false">
                                            </app-textbausteine>
                                            <button class="button drag-button" type="button" mat-icon-button
                                                    cdkDragHandle>
                                                <mat-icon>drag_indicator</mat-icon>
                                            </button>
                                        </mat-expansion-panel-header>


                                        <app-file-gallery *ngIf="files.length > 0" [fileIds]="files"
                                                          [showCamera]="false" [showFolder]="false"
                                                          [resetable]="isFileResetable.bind(this)"
                                                          (delete)="onFileDeleteSonstiges(control, $event, id)"
                                                          (update)="onFileUpdate(control, $event)"
                                                          (reset)="onFileReset(control, $event)">
                                        </app-file-gallery>

                                    </mat-expansion-panel>

                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<!-- Field Templates -->
<ng-template #button let-field="field">
    <ng-container *ngIf="form.get(field.name) as control">
        <app-fab (click)="onIndicatorClick(control, field.name)"
                 [count]="((control.valueChanges | async) || control.value).length">
            <div class="icon">
                <app-mat-icon [icon]="icon$ | async"></app-mat-icon>
                <div class="text">
                    {{name + '.' + field.name + '.short' | translate}}
                </div>
            </div>
        </app-fab>
    </ng-container>
</ng-template>

<ng-template #buttonSonstiges let-field="field">
    <ng-container *ngIf="form.get(field.arrayName) as control">
        <app-fab (click)="onSonstigesClick(control, field.arrayName)"
                 [count]="((control.valueChanges | async) || control.value).length">
            <div class="icon">
                <app-mat-icon [icon]="iconSonstiges"></app-mat-icon>
                <div class="text">{{name + '.' + field.arrayName + '.short' | translate}}</div>
            </div>
        </app-fab>
    </ng-container>
</ng-template>

<ng-template #buttonFixed let-field="field">
    <div [class]="'button ' + (field.name | lowercase)">
        <ng-container *ngTemplateOutlet="button, context: { field: field }"></ng-container>
    </div>
</ng-template>

<ng-template #buttonFixedSonstiges let-field="field">
    <div [class]="'button ' + (field.arrayName | lowercase)">
        <ng-container *ngTemplateOutlet="buttonSonstiges, context: { field: field }"></ng-container>
    </div>
</ng-template>

<!-- dialog -->
<ng-template #dialog let-data="data">
    <app-produkt-detail-uebersicht-sonstiges [form]="data.form" [name]="name" [fields]="data.fields"
                                             [produktArt]="data.produktArt">
    </app-produkt-detail-uebersicht-sonstiges>
</ng-template>

<!-- Delete all dialog -->
<ng-template #dialogDeleteAll>
  <mat-label>{{ 'uebersicht.deleteAll.text' | translate}}</mat-label>
</ng-template>
