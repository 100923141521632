import { Injectable } from '@angular/core';
import { FormControlBase } from '@shared/component/form-controls/form-control-base.component';

@Injectable({
    providedIn: 'root'
})
export class FormControlFocusService {
    private state: {
        [key: string]: FormControlBase[];
    } = {};
    private id = 0;

    public register(control: FormControlBase): void {
        const formId = this.hijackId(control.form);
        if (this.state[formId] === undefined) {
            this.state[formId] = [control];
        } else {
            this.state[formId].push(control);
        }
    }

    public unregister(control: FormControlBase): void {
        const formId = this.hijackId(control.form);
        const index = this.state[formId].indexOf(control);
        if (index !== -1) {
            this.state[formId].splice(index, 1);
        }
        if (this.state[formId].length === 0) {
            delete this.state[formId];
        }
    }

    public next(control: FormControlBase, step = 1): void {
        const formId = this.hijackId(control.form);
        const index = this.state[formId].indexOf(control);
        const nextIndex = index + Number(step);
        const nextControl = this.state[formId][nextIndex];
        if (nextControl) {
            nextControl.focus();
        } else {
            control.blur();
        }
    }

    public prev(control: FormControlBase): void {
        const formId = this.hijackId(control.form);
        const index = this.state[formId].indexOf(control);

        const prevIndex = index - 1;
        const prevControl = this.state[formId][prevIndex];
        if (prevControl) {
            prevControl.focus();
        } else {
            control.blur();
        }
    }

    private hijackId(obj: any): string {
        if (obj.focusId === undefined) {
            obj.focusId = ++this.id;
        }
        return obj.focusId;
    }
}
