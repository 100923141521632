import { FileData } from '@app/class/file-data';
import { from, Observable } from 'rxjs';

export function getDataFromBlob(blob: Blob): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = <ArrayBuffer>event.target.result;
            resolve(data);
        };
        reader.onerror = error => reject(error);
        reader.readAsArrayBuffer(blob);
    });
}

export function readFile(file: File): Observable<FileData<ArrayBuffer>> {
    const promise = new Promise<FileData<ArrayBuffer>>((resolve, reject) => {
        getDataFromBlob(file).then(data => {
            const fileData: FileData<ArrayBuffer> = {
                name: file.name,
                size: file.size,
                type: file.type,
                data
            };
            resolve(fileData);
        }, error => reject(error));
    });
    return from(promise);
}
