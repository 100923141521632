<app-card *ngIf="validations.length > 0; else valid">
    <div class="form-error" *ngFor="let validation of validations">
        <h3>{{validation.name | translate}}</h3>
        <ul>
            <li *ngFor="let field of validation.incorrectFields">
                {{name + '.field' | translate:{name: validation.name + '.' + field | translate} }}
            </li>
        </ul>
    </div>
</app-card>

<ng-template #valid>
  <app-card spacing="8px">
    <app-produkt-detail-abschluss-entwurf [name]="name" [produkt]="produkt" [fields]="fields" [adressen]="adressen">
    </app-produkt-detail-abschluss-entwurf>
  </app-card>
    <app-card spacing="8px">
        <app-produkt-detail-ac-abschluss-workflow [name]="name" [produkt]="produkt" [form]="form"
                                                  (statusChangedEvent)="onStatusChanged($event)">
        </app-produkt-detail-ac-abschluss-workflow>
    </app-card>
</ng-template>
