import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  AbschlussDownloadResponse,
  AbschlussDownloadService,
  AbschlussGetResponse,
  AbschlussRechnungsArt,
  AbschlussRechnungsService,
  AbschlussService,
  AbschlussVersandArt,
  AbschlussVersandService,
  AbschlussWorkflowStatus,
  AbschlussWorkflowStep
} from '@data/api-gateway';
import { AcAbschlussVersendenService } from '@data/api-gateway/service/alphacontroller/ac-abschluss-versenden.service';
import { AcProdukt, AcProduktStatus } from '@data/api-gateway/service/alphacontroller/ac-vorgang.service';
import { ProduktRechnungsArt, ProduktStatus, ProduktVersandArt } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { StepperComponent } from '@shared/component/layout/stepper';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { BehaviorSubject, iif, Observable, of, PartialObserver, Subscription, throwError } from 'rxjs';
import { concatMap, delay, distinctUntilChanged, finalize, first, mergeMap, retryWhen, tap } from 'rxjs/operators';

const STATUS_INTERVAL = 1000;
const STATUS_INTERVAL_FACTOR = [3, 2, 1, 2, 3, 5, 5, 10, 10, 15, 30];

@Component({
  selector: 'app-produkt-detail-ac-abschluss-workflow',
  templateUrl: './produkt-detail-ac-abschluss-workflow.component.html',
  styleUrls: ['./produkt-detail-ac-abschluss-workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailAcAbschlussWorkflowComponent implements OnInit, OnDestroy, AfterViewChecked {
  private subscriptions: Subscription[] = [];

  public trackByField = TrackBy.trackByField;

  @Input()
  public name: string;

  @Input()
  public produkt: AcProdukt;

  @Input()
  public form: ViewFormGroup;

  @ViewChild('stepperComponent', {static: false})
  public stepperComponent: StepperComponent;

  @ViewChild('acAbschlussResetDialog', {static: true})
  public acAbschlussResetTemplate: TemplateRef<any>;

  @ViewChild('acAbschlussVersendenDialog', {static: true})
  public acAbschlussVersendenTemplate: TemplateRef<any>;

  @Output()
  public statusChangedEvent = new EventEmitter<ProduktStatus>();

  public statusEnum = ProduktStatus;

  public statusChanged$ = new BehaviorSubject<ProduktStatus>(1);
  // public acVersendetChanged$ = new BehaviorSubject<boolean>(null);

  public rechnungsArt = new EnumValues(ProduktRechnungsArt);
  public rechnungsArtChanged = new BehaviorSubject<ProduktRechnungsArt>(undefined);

  public versandArt = new EnumValues(ProduktVersandArt);
  public versandArtChanged = new BehaviorSubject<ProduktVersandArt>(undefined);

  public loading$ = new BehaviorSubject(false);
  public downloadUrl$ = new BehaviorSubject<string>(undefined);

  constructor(
    private readonly abschlussService: AbschlussService,
    private readonly acAbschlussVersendenService: AcAbschlussVersendenService,
    private readonly abschlussRechnungsService: AbschlussRechnungsService,
    private readonly abschlussVersandService: AbschlussVersandService,
    private readonly abschlussDownloadService: AbschlussDownloadService,
    private readonly snackbarService: SnackBarService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly produktService: ProduktService,
    private readonly templateDialogService: TemplateDialogService) {
    Assert.notNullOrUndefined(abschlussService, 'abschlussService');
    Assert.notNullOrUndefined(abschlussRechnungsService, 'abschlussRechnungsService');
    Assert.notNullOrUndefined(abschlussVersandService, 'abschlussVersandService');
    Assert.notNullOrUndefined(abschlussDownloadService, 'abschlussDownloadService');
    Assert.notNullOrUndefined(snackbarService, 'snackbarService');
    Assert.notNullOrUndefined(changeDetector, 'changeDetector');
    Assert.notNullOrUndefined(produktService, 'produktService');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.checkStatus().subscribe());
  }

  public ngAfterViewChecked() {
    this.changeDetector.detectChanges();
    this.subscriptions.push(this.statusChanged$.pipe(distinctUntilChanged()).subscribe(produktStatus => this.selectIndex(produktStatus)));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  public onProduktCloseClick(): void {
    const observer = this.getCheckStatusObserverBeendet(`${this.name}.workflow.close.failed`);
    this.loading$.next(true);
    this.subscriptions.push(
      this.checkStatus(this.abschlussService.post(this.produkt.id))
        .subscribe(observer)
    );
  }

  public onProduktOpenClick(): void {
    const observer = this.getCheckStatusObserver(`${this.name}.workflow.reopen.failed`);
    this.subscriptions.push(
      this.checkStatus(this.abschlussRechnungsService.post(
        this.produkt.id, AbschlussRechnungsArt.Aborted
      )).subscribe()
    );
  }

  public onVersendenClick(): void {
    this.changeDetector.detectChanges();
    this.openAbschlussVersendenConfirmDialog();
  }


  public onDownloadClick(): void {
    const observer = this.getActionObserver<AbschlussDownloadResponse>(
      response => this.onDownloadResponse(response),
      `${this.name}.workflow.download.failed`
    );
    this.loading$.next(true);
    this.subscriptions.push(
      this.abschlussDownloadService.get(this.produkt.id).pipe(
        finalize(() => this.loading$.next(false))
      ).subscribe(observer));
  }

  public onOpenDownloadClick(): void {
    const {value} = this.downloadUrl$;
    window.open(value, '_blank');
  }

  public onAbschlussResetClick(): void {
    this.openAbschlussResetConfirmDialog();
  }

  private postDefaultRechnungsart() {
    const art = AbschlussRechnungsArt.Without;
    const observer = this.getCheckStatusObserver(`${this.name}.workflow.rechnung.failed`);
    this.subscriptions.push(
      this.checkStatus(this.abschlussRechnungsService.post(
        this.produkt.id, art
      )).subscribe(observer)
    );
  }

  private postDefaultVersandArt() {
    const art = AbschlussVersandArt.NoDelivery;
    const observer = this.getCheckStatusObserver(`${this.name}.workflow.versand.failed`);
    this.subscriptions.push(
      this.checkStatus(this.abschlussVersandService.post(
        this.produkt.id, art
      )).subscribe(observer)
    );
  }

  private openAbschlussResetConfirmDialog() {
    const title = this.name + '.workflow.reset.title';
    const buttons = ['feature.cancel', 'feature.confirm'];

    this.templateDialogService.openTemplate(title, buttons, this.acAbschlussResetTemplate).subscribe(
      result => {
        if (result.name && result.name === buttons[ 1 ]) {
          this.resetAbschluss();
        }
      }
    );
  }

  private openAbschlussVersendenConfirmDialog() {
    const title = this.name + '.workflow.versenden.title';
    const buttons = ['feature.cancel', 'feature.confirm'];

    this.templateDialogService.openTemplate(title, buttons, this.acAbschlussVersendenTemplate).subscribe(
      result => {
        if (result.name && result.name === buttons[ 1 ]) {
          this.versenden();
        }
      }
    );
  }

  private resetAbschluss() {
    const observer = this.getActionObserver(
      () => window.location.reload(),
      `${this.name}.workflow.reset.failed`
    );
    this.subscriptions.push(this.abschlussService.delete(
      this.produkt.id).subscribe(observer));

    this.produkt.acMetaInformation.acVorgangStatus = AcProduktStatus.InBearbeitung;
  }

  private nextStep(count: number = 1): void {
    setTimeout(() => this.onNextStep(count), 1);
  }

  private updateProdukt(): Observable<Produkt> {
    return this.produktService.getById(this.produkt.id, false).pipe(
      tap(produkt => this.onUpdateProdukt(produkt))
    );
  }

  private getActionObserver<T>(action: (value: T) => void, error: string): PartialObserver<T> {
    return {
      next: value => action(value),
      error: ex => {
        console.warn('An unexpected error occured while checking the status', ex);
        this.snackbarService.error(error);
      }
    };
  }

  private getCheckStatusObserver(error: string): PartialObserver<boolean> {
    return {
      next: (success: boolean) => {
        if (!success) {
          this.snackbarService.error(error);
        }
      },
      error: ex => {
        console.warn('An unexpected error occured while checking the status', ex);
        this.snackbarService.error(error);
      }
    };
  }

  private getCheckStatusObserverBeendet(error: string): PartialObserver<boolean> {
    return {
      next: (success: boolean) => {
        if (!success) {
          this.snackbarService.error(error);
          return;
        }
        this.statusChanged$.next(this.statusEnum.Beendet);
      },
      error: ex => {
        console.warn('An unexpected error occured while checking the status', ex);
        this.snackbarService.error(error);
      }
    };
  }

  private checkStatus(start?: Observable<any>): Observable<boolean | Produkt> {
    return (start ? start : of(null)).pipe(
      mergeMap(() => this.waitUntilStep([
        AbschlussWorkflowStep.Abgebrochen,
        AbschlussWorkflowStep.InvoiceTypeDecision,
        AbschlussWorkflowStep.ProductDeliveryTypeDecision,
        AbschlussWorkflowStep.WaitForInvoice,
        AbschlussWorkflowStep.StartBilling,
        AbschlussWorkflowStep.FinishProduct,
      ])),
      mergeMap(reached => iif(() => reached, this.updateProdukt(), of(null))),
      tap(() => this.nextStep(6)),
    );
  }

  private waitUntilStep(requiredSteps: AbschlussWorkflowStep[]): Observable<boolean> {
    return this.abschlussService.get(this.produkt.id).pipe(
      mergeMap(response => this.onStatusResponse(response, requiredSteps)),
      retryWhen(errors => errors.pipe(
        concatMap((error, retry) => this.onStatusError(error, retry))
      ))
    );
  }

  private onNextStep(count: number): void {
    if (this.stepperComponent) {
      for (let i = 0; i < count; ++i) {
        this.stepperComponent.next();
      }
    }
  }

  private onUpdateProdukt(produkt: Produkt): void {
    this.statusChanged$.next(produkt.status);
    this.statusChangedEvent.emit(produkt.status);
    this.rechnungsArtChanged.next(produkt.rechnungsArt);
    this.versandArtChanged.next(produkt.versandArt);
  }

  private onDownloadResponse(response: AbschlussDownloadResponse): void {
    if (response?.url?.length) {
      window.open(response.url, '_blank');
      this.downloadUrl$.next(response.url);
    }
  }

  private onStatusError(error: any, retry: number): Observable<void> {
    const retryDelay = (STATUS_INTERVAL_FACTOR[ retry ] || 30) * STATUS_INTERVAL;
    return of(error).pipe(delay(retryDelay));
  }

  private onStatusResponse(response: AbschlussGetResponse, requiredSteps: AbschlussWorkflowStep[]): Observable<boolean> {
    if (!response) {
      return throwError('could not retrieve status.');
    }

    const {status} = response;
    if (status === AbschlussWorkflowStatus.None
      || status === AbschlussWorkflowStatus.Aborted) {
      return of(true);
    }
    if (!response.details || !response.details.step) {
      return throwError('could not retrieve details.');
    }

    const {details} = response;
    if (status !== AbschlussWorkflowStatus.Running
      && status !== AbschlussWorkflowStatus.Succeeded) {
      return of(false);
    }

    const {step} = details;
    if (!requiredSteps.includes(step)) {
      return throwError('step not reached yet.');
    }

    switch (step) {
      case AbschlussWorkflowStep.InvoiceTypeDecision:
        this.postDefaultRechnungsart();
        break;
      case AbschlussWorkflowStep.ProductDeliveryTypeDecision:
        this.postDefaultVersandArt();
        break;
      case AbschlussWorkflowStep.FinishProduct:
        this.loading$.next(false);
        break;
      case AbschlussWorkflowStep.StartBilling:
        this.loading$.next(false);
        break;
    }

    return of(true);
  }

  private versenden(): void {
    this.loading$.next(true);

    this.acAbschlussVersendenService.post(this.produkt.id).pipe(first()).subscribe(
      value => {
        console.log('versenden() - value: ', value);
        this.snackbarService.success('acAbschluss.workflos.versenden.success');
      },
      error => {
        console.error('versenden() - error: ', error);
        this.snackbarService.error('acAbschluss.workflos.versenden.failed');
      },
      () => this.loading$.next(false)
    );
  }

  private selectIndex(produktStatus: ProduktStatus): void {
    this.changeDetector.detectChanges();
    if (!this.stepperComponent) {
      return;
    }

    switch (produktStatus) {
      case ProduktStatus.Offen: this.stepperComponent.setIndex(0);
        break;
      case ProduktStatus.Beendet: this.stepperComponent.setIndex(1);
        break;
      case ProduktStatus.AcVersendet: this.stepperComponent.setIndex(2);
      default: this.stepperComponent.setIndex(0);
    }
  }
}
