<ng-container [ngSwitch]="state">
    <button *ngSwitchCase="3" type="button" class="camera-save-button" mat-fab (click)="onSave()">
        <mat-icon>save</mat-icon>
    </button>
    <ng-container *ngSwitchDefault>
        <button class="flip" type="button" mat-mini-fab (click)="onFlipClick()" *ngIf="state === 2" color="">
            <mat-icon>flip_camera_android</mat-icon>
        </button>
        <button type="button" class="camera-trigger-button" mat-fab (click)="onTrigger()" [disabled]="state !== 2">
            <mat-icon>photo_camera</mat-icon>
        </button>
        <button class="orientation" #orientation type="button" mat-mini-fab (click)="onOrientationChange()"
            *ngIf="state === 2" color="">
            <mat-icon>
                {{(orientation$ | async) === orientations.values['Portrait'] ? 'portrait' : 'landscape'}}
            </mat-icon>
        </button>
    </ng-container>
</ng-container>
