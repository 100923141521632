import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { Router } from '@angular/router';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { Assert } from '@shared/helper/assert';
import * as _THREE from 'three';
import { Camera, PMREMGenerator, Scene, WebGLRenderer } from 'three';
import { GltfIndicatorComponent } from '../gltf-indicator/gltf-indicator.component';

export enum ModelLoadResult {
  Origin = 1,
  Fallback = 2,
  None = 3,
}

declare const require: any;
// eslint-disable-next-line import/no-extraneous-dependencies
require('imports-loader?THREE=three!three/examples/js/loaders/GLTFLoader.js');
// eslint-disable-next-line import/no-extraneous-dependencies
require('imports-loader?THREE=three!three/examples/js/loaders/RGBELoader.js');
const THREE = <any>_THREE;

@Component({
  selector: 'app-gltf',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GltfComponent {
  private _modelFileConfigs: ModelFileConfig[];

  private camera: Camera;
  private renderer: WebGLRenderer;
  private parentScene: Scene;
  private scene: Scene;
  private indicators: GltfIndicatorComponent[] = [];

  @Input()
  public set modelFileConfigs(positionen: ModelFileConfig[]) {
    if (positionen) {
      this._modelFileConfigs = [...positionen].reverse();
    }
  }

  @Input()
  public scaleMax = 1.0;

  @Input()
  public scaleMin = 0.4;

  @Output()
  public sceneLoad = new EventEmitter<Scene>();

  @Output()
  public modelLoad = new EventEmitter<ModelLoadResult>();

  @ContentChildren(GltfIndicatorComponent, {
    descendants: true
  })
  public indicatorsQuery: QueryList<GltfIndicatorComponent>;


  constructor(
    private readonly router: Router) {
    Assert.notNullOrUndefined(router, 'router');
  }

  public init(camera: Camera, renderer: WebGLRenderer, scene: Scene): void {
    Assert.notNullOrUndefined(camera, 'camera');
    Assert.notNullOrUndefined(renderer, 'renderer');
    Assert.notNullOrUndefined(scene, 'scene');
    this.camera = camera;
    this.renderer = renderer;
    this.parentScene = scene;
    this.indicators = this.indicatorsQuery.toArray();
    this.load();
  }

  public animate(): void {
    let min = Number.MAX_VALUE;
    let max = Number.MIN_VALUE;
    const distances = Array(this.indicators.length);
    this.indicators.forEach((indicator, index) => {
      const distance = indicator.update();
      if (distance > max) {
        max = distance;
      }
      if (distance < min) {
        min = distance;
      }
      distances[index] = distance;
    });

    this.indicators.forEach((indicator, index) => {
      const distance = distances[index];
      const factor = max === min ? 1 : 1 / (max - min);
      const normalized = (distance - min) * factor;
      const inverse = 1 - normalized;
      const bound = (this.scaleMax - this.scaleMin) * inverse + this.scaleMin;
      const scale = Math.round(bound * 100) / 100;
      if (scale !== indicator.scale$.value) {
        indicator.scale$.next(scale);
      }
    });
  }

  private load(): void {
    if (!this._modelFileConfigs || this._modelFileConfigs.length <= 0) {
      this.modelLoad.emit(ModelLoadResult.None);
      return;
    }

    new THREE.RGBELoader()
      .setDataType(THREE.UnsignedByteType)
      .setPath('assets/three/')
      .load('venice_sunset_1k.hdr', (texture: any) => {

        const pmremGenerator = new PMREMGenerator(this.renderer);
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;

        // model
        this.loadModel(envMap, ModelLoadResult.Origin);

        pmremGenerator.dispose();
      }, () => { /* This is intentional */ }, () => {
        this.modelLoad.emit(ModelLoadResult.None);
      });
  }

  private loadModel(envMap: any, result: ModelLoadResult): void {
    const config = this._modelFileConfigs.pop();
    if (!config) {
      this.modelLoad.emit(ModelLoadResult.None);
    } else {
      const loader = new THREE.GLTFLoader().setPath('assets/three/');
      loader.load(`${config.file}.json`, (gltf: any) => {
        this.scene = gltf.scene;
        this.scene.traverse((child: any) => {
          if (child.isMesh) {
            child.material.envMap = envMap;
          }
        });
        this.scene.rotateY(THREE.Math.degToRad(config.yOffset));
        this.scene.updateMatrixWorld(true);
        this.parentScene.add(this.scene);
        this.indicators.forEach(indicator => indicator.init(this.camera, this.renderer.domElement, this.scene));
        this.sceneLoad.emit(this.scene);
        this.modelLoad.emit(result);
      }, () => { /* This is intentional */ }, () => {
        this.loadModel(envMap, ModelLoadResult.Fallback);
      });
    }
  }
}
