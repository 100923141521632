import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FileData } from '@app/class/file-data';
import { FileValidationService, FileValidationTypes } from '@app/service/file-validation.service';
import { Assert } from '@shared/helper/assert';
import { CaptureDialogService } from '@shared/service/capture-dialog.service';
import { UploadDialogService } from '@shared/service/upload-dialog.service';
import { filter, map } from 'rxjs/operators';

export enum FileGalleryAddType {
  Capture = 1,
  File = 2,
}

export interface FileGalleryUpdateEvent {
  id: string;
  data: FileData<ArrayBuffer>;
}

export type isResetableFn = (fileId: string) => boolean;

@Component({
  selector: 'app-file-gallery',
  templateUrl: './file-gallery.component.html',
  styleUrls: ['./file-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileGalleryComponent {
  private readonly updated: {
    [key: string]: number;
  } = {};

  @Input()
  public fileIds: string[];

  @Input()
  public title: string;

  @Input()
  public dialogTitle: string;

  @Input()
  public accept = '.png, .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .bmp, .dib';

  @Input()
  public fileValidationType = FileValidationTypes.Image;

  @Input()
  public resetable: isResetableFn;

  @Input()
  public showCamera = true;

  @Input()
  public showFolder = true;

  @Input()
  public buttonLabel;

  @Input()
  public disabled = false;

  @Input()
  public multiple = true;

  @Output()
  public add = new EventEmitter<FileData<ArrayBuffer>[]>();

  @Output()
  public delete = new EventEmitter<string>();

  @Output()
  public reset = new EventEmitter<string>();

  @Output()
  public update = new EventEmitter<FileGalleryUpdateEvent>();

  @Output()
  public buttonClick = new EventEmitter<string>();

  constructor(
    private readonly uploadService: UploadDialogService,
    private readonly captureService: CaptureDialogService,
    private readonly fileValidationService: FileValidationService ) {
  }

  public onAddClick(type: FileGalleryAddType): void {
    Assert.notNullOrUndefined(type, 'type');
    const files$ = (type === FileGalleryAddType.Capture
      ? this.captureService.captureImage(this.dialogTitle)
      : this.uploadService.uploadFiles(this.dialogTitle, this.accept, this.multiple));
    files$.pipe(
      filter(files => !!files && files.length > 0),
      map(files => this.fileValidationService.validateFileTypeAndExtension(files, this.fileValidationType).validFiles)
    ).subscribe(files => {
      this.add.emit(files);
    });
  }

  public onFileSaved(id: string, data: FileData<ArrayBuffer>): void {
    Assert.notNullOrUndefined(id, 'id');
    Assert.notNullOrUndefined(data, 'data');
    this.updated[id] = Date.now();
    this.update.emit({ data, id });
  }

  public trackByKey(_index: number, key: string): string {
    if (!this.updated[key]) {
      this.updated[key] = Date.now();
    }
    return `${key}_${this.updated[key]}`;
  }
}
