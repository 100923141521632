<mat-form-field (click)="onFieldClick()" class="textbausteine-field-wrapper">
  <mat-label>{{displayName | translate}}</mat-label>
  <mat-icon matSuffix class="open-dialog-button material-icons-outlined"
            [class.disabled]="!multiple && control.value.length >= 1"
            (click)="(!multiple && control.value.length >= 1) ? {} : onAddTextbausteinClicked()">font_download</mat-icon>
  <mat-chip-grid #list>
    <mat-chip-row *ngFor="let value of control.value;let index = index;trackBy:trackByKey"
              (removed)="onChipRemoveClicked(index)" matTooltip="{{value.langtext}}"
              (click)="onChipClicked(viewControlArray.controls[index], index)">
      {{value.kurztext}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <input #input #trigger="matAutocompleteTrigger" matInput [placeholder]="displayName | translate" [readonly]="true"
           [id]="name" [formControl]="viewControl" [matAutocomplete]="autocomplete" [matChipInputFor]="list"
           [matChipInputSeparatorKeyCodes]="seperators" (matChipInputTokenEnd)="onOptionAdd($event, input)">
  </mat-chip-grid>
  <mat-autocomplete #autocomplete (optionSelected)="onOptionSelected($event, input)">
    <mat-option *ngFor="let option of optionsFiltered$ | async" [value]="option">
      {{option}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<!-- Delete dialog -->
<ng-template #delete>
  <app-delete-dialog>
  </app-delete-dialog>
</ng-template>

<!-- Edit dialog -->
<ng-template #dialogEdit let-data="data">
    <app-textbausteine-dialog-edit [form]="data.form" [feature]="data.feature" [feld]="data.feld"
                                   [data]="data" [feldDisplayName]="displayName">
    </app-textbausteine-dialog-edit>
</ng-template>

<!-- dialog -->
<ng-template #dialog let-data="data">
  <app-textbausteine-dialog [form]="form" [feature]="data.feature" [feld]="data.feld"
    (textbausteinEventEmitter)="onTextbausteinAdd($event)" [feldDisplayName]="displayName"
  [feature]="feature" [feld]="name" [produktArt]="produktArt">
  </app-textbausteine-dialog>
</ng-template>



