<app-progress-spinner class="spinner" *ngIf="(loading$ |async)" [diameter]="150">
</app-progress-spinner>

<div class="search-field">
    <app-input [form]="form" [readonly]="false" name="searchTerm" [displayName]="'Suche'"
        minLength="3" maxlength="17"
        [placeholder]="name +'.identnummer' | translate"
        (keypress)="alphaNumericOnly($event)"
        (ngModelChange)="searchTerm = searchTerm.toUpperCase()"
        (keydown.enter)="onClickProduktSearch(form.get('searchTerm').value)"
        type="text" inputmode="text" #input>
        <div appSuffix class="icons">
          <app-mini-fab [color]="'basic'" [disabled]="form.get('searchTerm').value.length < 3"
            (action)="onClickProduktSearch(form.get('searchTerm').value)">
            <app-mat-icon icon="search"></app-mat-icon>
          </app-mini-fab>
          <app-mini-fab [color]="'basic'" [disabled]="form.get('searchTerm').value.length === 0"
            (action)="onResetIdentAndSearch()">
            <app-mat-icon icon="delete"></app-mat-icon>
          </app-mini-fab>
        </div>
    </app-input>
</div>

<app-expansion-panel-single>
  <app-expansion-header>
    <div class="filter-container" [class.mobile]="(viewport$ | async) === viewport.Mobile">
      <app-slide-toggle class="filter" [form]="form" [name]="'status'" displayName="nur offene"></app-slide-toggle>
    </div>
  </app-expansion-header>

  <div class="filter-container" [class.mobile]="(viewport$ | async) === viewport.Mobile">
    <app-select class="filter" [form]="form" [name]="'art'" [displayName]="'Art'"
                [values]="produktArtEnumValues"
                [valuePrefix]="name + '.'" [hasEmptyField]="true">
    </app-select>
    <app-input class="filter" [form]="form" [name]="'nummer'" [displayName]="'Nummer'" type="text"></app-input>
    <app-input class="filter" [form]="form" [name]="'kennzeichen'" [displayName]="'Kennzeichen'" type="text"></app-input>
    <app-input class="filter" [form]="form" [name]="'fin'" [displayName]="'FIN'" type="text"></app-input>
    <app-date class="filter" [form]="form" [name]="'von'" [displayName]="'Von'" [min]="minDate" [max]="maxDate"></app-date>
    <app-date class="filter" [form]="form" [name]="'bis'" [displayName]="'Bis'" [min]="minDate" [max]="maxDate"></app-date>
    <app-button class="reset-button" [type]="ButtonType.Flat" [color]="'primary'" (action)="onResetFilter()">
      {{'produktuebersicht.reset' | translate}}
    </app-button>
  </div>
</app-expansion-panel-single>





<app-table *ngIf="(loading$ | async) === false" [data]="produkte" [filter$]="filter$" [filterPredicate]="filterPredicate"
           [pageSizeOptions]="[25, 50, 100]" active="erstelltAm" direction="desc" (action)="onAction($event)"
           [customFilterVisible]="false" #table>
  <app-table-column name="art" [displayName]="name + '.art'" [sort]="true">
    <ng-template let-element="element">
      <app-mat-icon [svgIcon]="'bewertung'" color="primary" *ngIf="element.art === produktArt.Bewertung"></app-mat-icon>
      <app-mat-icon [svgIcon]="'ruecknahmebewertung'" color="primary" *ngIf="element.art === produktArt.Ruecknahmebewertung"></app-mat-icon>
      <app-mat-icon [svgIcon]="'zustandsbericht'" color="primary" *ngIf="element.art === produktArt.Zustandsbericht"></app-mat-icon>
      <h4 *ngIf="element.art === produktArt.VtiTooling">VTI</h4>
      <app-mat-icon [svgIcon]="'alphacontroller'" color="primary" *ngIf="element.art === produktArt.AlphaController"></app-mat-icon>
    </ng-template>
  </app-table-column>
  <app-table-column name="auftrag.nummer" [displayName]="name + '.nummer'" [sort]="true">
    <ng-template let-element="element">{{element.auftrag.nummer}}</ng-template>
  </app-table-column>
  <app-table-column name="fahrzeug.kennzeichen" [displayName]="name + '.kennzeichen'" [sort]="true">
    <ng-template let-element="element">
      {{element.fahrzeug.kennzeichen ? element.fahrzeug.kennzeichen : element.fahrzeug.identnummer}}
    </ng-template>
  </app-table-column>
  <app-table-column name="erstelltAm" [displayName]="name + '.erstelltAm'" [sort]="true" start="desc">
    <ng-template let-element="element">{{element.erstelltAm | date}}</ng-template>
  </app-table-column>
  <app-table-column name="status" [displayName]="name + '.status'" [sort]="true">
    <ng-template let-element="element">{{produktStatus.values[element.status]}}
    </ng-template>
  </app-table-column>
  <app-table-column name="download" [displayName]="name + '.download'" [sort]="false">
    <ng-template let-element="element">
      <div class="button-container">
        <app-button [matBadge]="element.anzahlBilder" [matBadgeHidden]="element.anzahlBilder <= 0" [class.disabled]="element.anzahlBilder <= 0" (action)="onBilderDownload($event, element.id, element.anzahlBilder <= 0)">
          <ng-container>
            <app-mat-icon icon="photo_gallery" color="primary"></app-mat-icon>
          </ng-container>
        </app-button>
        <app-button [class.disabled]="element.status !== produktStatusEnum.Beendet" (action)="onDownloadClick($event, element.id, element.status !== produktStatusEnum.Beendet)">
          <ng-container>
            <app-mat-icon icon="picture_as_pdf" color="primary"></app-mat-icon>
          </ng-container>
        </app-button>
      </div>
    </ng-template>
  </app-table-column>
  <app-table-column name="duplicate" [displayName]="name + '.duplicate'" [sort]="false">
    <ng-template let-element="element">
      <app-button *ngIf="element.art !== produktArt.VtiTooling" (action)="onClickDuplicate($event, element)">
        <ng-container>
          <app-mat-icon icon="control_point_duplicate" color="primary"></app-mat-icon>
        </ng-container>
      </app-button>
    </ng-template>
  </app-table-column>
</app-table>

<!-- Confirm dialog -->
<ng-template #dialogConfirmDuplicate let-data="data">

  <mat-label class="dialog-text">{{ 'nachbewertung.text' | translate}}</mat-label>
  <app-produkt-detail-nachbewertung-dialog #nachbewertungArtSelection [defaultSelection]="data.produkt.art">

  </app-produkt-detail-nachbewertung-dialog>
</ng-template>

