<ng-container *ngIf="!saving; else spinner">
    <label>{{(name + '.' + 'logo') | translate}}</label>
    <app-inline-image [form]="form" [size]="1024 * maxFileUploadSizeInKb" name="logo" [displayName]="name + '.logo'" isEinstellungen="true"></app-inline-image>
    <label>{{(name + '.' + 'logoTop') | translate}}</label>
    <app-inline-image [form]="form" [size]="1024 * maxFileUploadSizeInKb" name="logoTop" [displayName]="name + '.logoTop'" isEinstellungen="true"></app-inline-image>
    <app-input [form]="form" name="fusszeile" [displayName]="name + '.fusszeile'" type="text" inputmode="text">
    </app-input>
    <app-input [readonly]="true" [form]="form" name="datCustomerNumber" [displayName]="name + '.datCustomerNumber'" type="text"
        inputmode="text"></app-input>
    <app-input [readonly]="true" [form]="form" name="datCustomerLogin" [displayName]="name + '.datCustomerLogin'" type="text"
        inputmode="text"></app-input>
    <app-input [readonly]="true" [form]="form" name="datInterfacePartnerNumber" [displayName]="name + '.datInterfacePartnerNumber'"
        type="text" inputmode="text"></app-input>
    <app-input [readonly]="true" [form]="form" name="datProductVariant" [displayName]="name + '.datProductVariant'" type="text"
        inputmode="text"></app-input>
    <app-input [readonly]="true" [form]="form" name="datInterfacePartnerSignature" [displayName]="name + '.datInterfacePartnerSignature'"
        type="text" inputmode="text"></app-input>
</ng-container>
<ng-template #spinner>
    <app-progress-spinner></app-progress-spinner>
</ng-template>
<div align="end">
    <app-button (action)="form.valid && onSave()" [disabled]="!form.valid || saving">
        {{name + '.save' | translate}}
    </app-button>
</div>
