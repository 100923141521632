import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FileData } from '@app/class/file-data';
import { TrackBy } from '@shared/helper/track-by';
import { AudioRecorderService } from '@shared/service/audio-recorder.service';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-audio-gallery',
  templateUrl: './audio-gallery.component.html',
  styleUrls: ['./audio-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioGalleryComponent implements OnDestroy {
  private timerSubscription: Subscription = null;

  public trackByKey = TrackBy.trackByKey;

  public timer$ = new BehaviorSubject<number>(-1);

  @Input()
  public files: string[];

  @Input()
  public title: string;

  @Output()
  public add = new EventEmitter<FileData<ArrayBuffer>>();

  @Output()
  public delete = new EventEmitter<string>();

  @Output()
  public error = new EventEmitter<void>();

  constructor(private readonly audioRecorderService: AudioRecorderService) { }

  public onAction(input: MatInput): void {
    if (!this.timerSubscription) {
      this.audioRecorderService.start().subscribe(
        () => this.onRecordStart(),
        error => this.onError(error)
      );
    } else {
      this.timer$.next(-1);
      this.timerSubscription.unsubscribe();
      const name = input.value;
      input.value = '';
      this.audioRecorderService.stop().subscribe(
        data => this.onRecordStop(data, name),
        error => this.onError(error)
      );
    }
  }

  public ngOnDestroy(): void {
    this.audioRecorderService.stop().subscribe().unsubscribe();
  }

  private onRecordStart(): void {
    const timer = interval(1000)
      .pipe(tap(() => this.timer$.next(this.timer$.value + 1)));
    this.timer$.next(0);
    this.timerSubscription = timer.subscribe();
  }

  private onRecordStop(data: FileData<ArrayBuffer>, name: string): void {
    if (data) {
      data.name = name;
      this.add.emit(data);
    }
    this.timerSubscription = null;
  }

  private onError(error: Error): void {
    console.warn('An unexpected error occured while recording.', error);
    this.error.next();
  }
}
