import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ProduktArt } from '@data/domain/schema/enum';
import { Verfuegbarkeit } from '@modules/textbausteine-verwalten/config/textbausteine-verwalten.config';
import { ObjectValues, Values } from '@shared/helper/values';

export enum EditMode {
  Textbaustein = 0,
  Verwaltung = 1,
}


@Component({
  selector: 'app-textbausteine-dialog-edit',
  templateUrl: './textbausteine-dialog-edit.component.html',
  styleUrls: ['./textbausteine-dialog-edit.component.scss']
})
export class TextbausteineDialogEditComponent {

  @Input()
  public feature = '';

  @Input()
  public feld = '';

  @Input()
  public feldDisplayName = '';

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public mode = EditMode.Textbaustein;

  @Input()
  public data: any;

  @Input()
  public readonly = false;

  public editMode = EditMode;

  public produktArt = ProduktArt;

  public verfuegbarkeit: Values = new ObjectValues(Verfuegbarkeit);

  public verfuegbarkeitEnum = Verfuegbarkeit;

  public isVerfuegbarkeitChecked(input: string): boolean {
    return this.form.get('verfuegbarkeit').value.find( value => value === input);
  }

  public isProduktChecked(art: ProduktArt): boolean {
    return this.form.get('produkte').value.find(value => value === art);
  }

  public setProdukt(art: ProduktArt, $event: MatSlideToggleChange): void {
    const currentlyChecked = this.form.get('produkte').value.find(value => value === art);
    if (currentlyChecked !== $event.checked) {
      if ($event.checked) {
        this.form.get('produkte').value.push(art);
      } else {
        this.form.patchValue({['produkte']: this.form.get('produkte').value.filter(value => value !== art)});
      }
    }
  }

  public onVerfuegbarkeitChange($event: MatRadioChange): void {
    this.form.patchValue({['verfuegbarkeit']: [$event.value]});
  }
}
