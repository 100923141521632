<div matTooltip="{{tooltip}}">
  <ng-container [ngSwitch]="type">
    <button *ngSwitchCase="Buttontype.Basic"
            mat-button disableRipple color="{{color}}" [id]="name" [class.loading]="loading"
            [class.count-center]="countAlign === 'center'" [disabled]="disabled || loading"
            [matBadgeHidden]="count <= 0"
            [matBadge]="count" [style.margin]="0" (click)="disabled ? null : action.emit($event)">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </button>

    <button *ngSwitchCase="Buttontype.Raised"
            mat-raised-button disableRipple color="{{color}}" [id]="name" [class.loading]="loading"
            [class.count-center]="countAlign === 'center'" [disabled]="disabled || loading"
            [matBadgeHidden]="count <= 0"
            [matBadge]="count" [style.margin]="0" (click)="disabled ? null : action.emit($event)">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </button>

    <button *ngSwitchCase="Buttontype.Stroked"
            mat-stroked-button disableRipple color="{{color}}" [id]="name" [class.loading]="loading"
            [class.count-center]="countAlign === 'center'" [disabled]="disabled || loading"
            [matBadgeHidden]="count <= 0"
            [matBadge]="count" [style.margin]="0" (click)="disabled ? null : action.emit($event)">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </button>

    <button *ngSwitchCase="Buttontype.Flat"
            mat-flat-button disableRipple color="{{color}}" [id]="name" [class.loading]="loading"
            [class.count-center]="countAlign === 'center'" [disabled]="disabled || loading"
            [matBadgeHidden]="count <= 0"
            [matBadge]="count" [style.margin]="0" (click)="disabled ? null : action.emit($event)">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </button>
  </ng-container>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
