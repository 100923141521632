import { Injectable } from '@angular/core';
import { S3EntwurfFileService } from '@app/service/s3-entwurf-file.service';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KalkulationDownloadService {

  constructor(
    private readonly s3EntwurfFileService: S3EntwurfFileService) {
    Assert.notNullOrUndefined(s3EntwurfFileService, 's3EntwurfFileService');
  }

  public download(bueroNummer: string, produktId): Observable<string> {
    Assert.notNullOrUndefined(bueroNummer, 'produktId');
    Assert.notNullOrUndefined(produktId, 'produktId');

    const path = `${bueroNummer}/${produktId}/kalkulation.pdf`;
    const currentTime = new Date().getTime();
    const filename = `${currentTime}_kalkulation.pdf`;

    return this.s3EntwurfFileService.getSignedUrl(path, filename);
  }
}
