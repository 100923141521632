import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { VinComponent } from '@shared/module/material/component/vin/vin.component';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { FormControlBase } from '../form-control-base.component';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComponent extends FormControlBase implements OnInit{
  @Input()
  public autoTabLength: number;

  @Input()
  public autoTabIndexSkip = 1;

  @Input()
  public type: string;

  @Input()
  public inputmode: 'numeric' | 'decimal';

  @Input()
  public readonly: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public vinDisabled = false;

  @Input()
  public placeholder: string;

  @Input()
  public onlyNumber = false;

  @ViewChild(MatInput, { read: ElementRef })
  public input: ElementRef<HTMLElement>;

  @ViewChild(VinComponent)
  public vin: VinComponent;

  public required = false;

  @HostListener('keydown', ['$event'])
  public onKeydown(event: KeyboardEvent): void {
    // eslint-disable-next-line
    if (!(event.key === 'Enter' || event.keyCode === 13)) {
      return;
    }
    if (!event.shiftKey) {
      this.next();
    } else {
      this.prev();
    }
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(event: KeyboardEvent): void {
    // eslint-disable-next-line
    if (!this.autoTabLength) {
      return;
    }

    switch (event.code) {
      case 'Delete':
      case 'Backspace':
      case 'Space':
      case 'Tab':
      case 'Enter':
      case 'ShiftLeft':
        return;
    }

    const target = event.target as
      | HTMLInputElement
      | null;

    if (target.value.length === Number(this.autoTabLength)) {
      this.next(this.autoTabIndexSkip);
    }
  }

  constructor(formControlFocusService: FormControlFocusService) {
    super(formControlFocusService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.setRequired();
  }

  public blur(): void {
    if (this.input) {
      this.input.nativeElement.blur();
    } else if (this.vin) {
      this.vin.blur();
    }
  }

  public focus(): void {
    if (this.input) {
      this.input.nativeElement.focus();
    } else if (this.vin) {
      this.vin.focus();
    }
  }

  private setRequired(): void {
    if (!this.form || !this.control) {
      return;
    }

    const validator = this.control?.validator?.({} as AbstractControl);
    if (validator && validator.required) {
      this.required = true;
    }
  }
}
