import { Injectable } from '@angular/core';
import { File } from '@app/class/file';
import { FileData } from '@app/class/file-data';
import { guid } from '@app/function/guid';
import { FileService } from '@app/service/file.service';
import { Assert } from '@shared/helper/assert';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProduktDetailResolveService } from './produkt-detail-resolve.service';

const UPDATE_SUFFIX = '_updated';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailFileService {
    constructor(
        private readonly produktDetailResolveService: ProduktDetailResolveService,
        private readonly fileService: FileService) {
        Assert.notNullOrUndefined(produktDetailResolveService, 'produktDetailResolveService');
        Assert.notNullOrUndefined(fileService, 'fileService');
    }

    public put(fileData: FileData<ArrayBuffer>): Observable<string> {
        Assert.notNullOrUndefined(fileData, 'fileData');
        const file: File = {
            id: this.generateId(),
            name: fileData.name,
            type: fileData.type,
            size: fileData.size,
            data: fileData.data,
        };
        return this.fileService.put(file);
    }

    public update(id: string, fileData: FileData<ArrayBuffer>): Observable<string> {
        Assert.notNullOrEmpty(id, 'id');
        Assert.notNullOrUndefined(fileData, 'fileData');
        const file: File = {
            id: `${id.replace(UPDATE_SUFFIX, '')}${UPDATE_SUFFIX}`,
            name: fileData.name,
            type: fileData.type,
            size: fileData.size,
            data: fileData.data,
        };
        return this.fileService.put(file);
    }

    public reset(id: string): Observable<string> {
        Assert.notNullOrEmpty(id, 'id');
        if (!this.isUpdated(id)) {
            return throwError(`Could not reset file: '${id}' because it was not updated in first place.`);
        }
        return this.fileService.delete(id).pipe(
            map(() => id.replace(UPDATE_SUFFIX, ''))
        );
    }

    public delete(id: string): Observable<void> {
        Assert.notNullOrEmpty(id, 'id');
        return this.fileService.delete(id);
    }

    public isUpdated(id: string): boolean {
        Assert.notNullOrEmpty(id, 'id');
        return id.indexOf(UPDATE_SUFFIX) !== -1;
    }

    private generateId(): string {
        const produkt = this.produktDetailResolveService.get();
        return `${produkt.id}/${guid()}`;
    }
}
