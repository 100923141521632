import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ProduktArt, ProduktArtNachbewertung } from '@data/domain/schema/enum';
import { EnumValues } from '@shared/helper/values';


@Component({
    selector: 'app-produkt-detail-nachbewertung-dialog',
    templateUrl: 'produkt-detail-nachbewertung-dialog.component.html',
    styleUrls: ['produkt-detail-nachbewertung-dialog.component.scss']
})
export class ProduktDetailNachbewertungDialogComponent implements OnInit{
    public produktArtNachbewertungValues = new EnumValues(ProduktArtNachbewertung);

    @Input()
    public defaultSelection: ProduktArt;

    @Input()
    public disabled = false;

    @ViewChild(MatSelect, { static: true })
    public select: MatSelect;

    public form = new FormGroup({
        produktArtControl: new FormControl()
    });

    public ngOnInit(): void {
        this.form.get('produktArtControl').setValue(this.defaultSelection);
    }

    public getSelectedValue() {
        return this.form.get('produktArtControl').getRawValue();
    }
}
