import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { ProduktArt } from '@data/domain/schema/enum';
import { Assert } from '@shared/helper/assert';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { saveAbrechnung } from '../../graphql/mutations';
import { Abrechnung, AbrechnungInput } from '../../schema/type';

@Injectable({
    providedIn: 'root'
})
export class ProduktAbrechnungService {
    constructor(
        private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
    }

    public saveAbrechnung(produktArt: ProduktArt, produktId: string, abrechnungId: string, abrechnung: Abrechnung): Observable<boolean> {
        Assert.notNullOrUndefined(produktArt, 'produktArt');
        if (produktArt === ProduktArt.VtiTooling) {
            return null;
        }

        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrEmpty(abrechnungId, 'abrechnungId');
        Assert.notNullOrUndefined(abrechnung, 'abrechnung');
        const input = this.mapInput(abrechnung);
        const client = this.awsAppSyncClientProvider.provide();

        const mutatePromise = client.mutate({
            mutation: saveAbrechnung,
            variables: {
                id: produktId,
                abrechnungId,
                abrechnung: input,
            },
            optimisticResponse: {
                saveAbrechnung: true
            },
        });
        return from(mutatePromise).pipe(map(response => response.data.saveAbrechnung));
    }

    private mapInput(abrechnung: Abrechnung): AbrechnungInput {
        const input: AbrechnungInput = {
            ...abrechnung
        };
        return input;
    }
}
