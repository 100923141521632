import { Routes } from '@angular/router';
import { ErrorComponent } from './page/error/error.component';
import { SigninComponent } from './page/signin/signin.component';
import { SignoutComponent } from './page/signout/signout.component';

export const AUTH_MODULE_ROUTES: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: 'signin',
        component: SigninComponent
    },
    {
        path: 'signout',
        component: SignoutComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    }
];
