import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Abschluss } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import {
    ProduktDetailTextbausteinFormViewFactory
} from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailAbschlussFormViewFactory extends FormViewModelBaseFactory<Abschluss> {

    constructor(private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory) {
        super();
        Assert.notNullOrUndefined(produktDetailTextbausteinFormViewFactory, 'produktDetailTextbausteinFormViewFactory');
    }

    protected createArray(model: Abschluss, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Abschluss.fields;
        if (arrayName === fields.Fazit.name) {
            let fazit;
            try {
                fazit = model.fazit || [];
            } catch (error) {
                fazit = [];
            }
            return new ViewFormArray(
                fazit.map(
                    value => this.produktDetailTextbausteinFormViewFactory
                        .create(value, arrayFields)
                ));
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }

    protected createField(model: Abschluss, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Abschluss.fields;
        if (name === fields.Adresse.name) {
            return new ViewFormControl('', {
                validators: Validators.required
            });
        } else {
            throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
