import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { LackmessungPosition } from '@data/domain/schema/enum';
import { Lackmessung, LackmessungInput, LackmessungMessung, Notiz } from '@data/domain/schema/type';
import { ProduktLackmessungService } from '@data/domain/service/feature';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { ProduktDetailLackmessungFormViewFactory } from '@modules/produkt/factory/lackmessung/produkt-detail-lackmessung-form-view.factory';
import { ProduktDetailLackmessungMessungFormViewFactory } from '@modules/produkt/factory/lackmessung/produkt-detail-lackmessung-messung-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ModelFileService } from '@modules/produkt/service/model-file.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


enum ProduktDetailLackmessungDisplay {
  Modell = 1,
  Manuell = 2
}

interface ProduktDetailLackmessungDialogData {
  form: ViewFormGroup;
  fields: FeatureFields;
}

@Component({
  selector: 'app-produkt-detail-lackmessung',
  templateUrl: './produkt-detail-lackmessung.component.html',
  styleUrls: ['./produkt-detail-lackmessung.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailLackmessungComponent extends ProduktDetailFeatureInputComponent<Lackmessung, LackmessungInput> implements OnInit, OnDestroy {
  private messungenName = PRODUKT_CONFIG_FEATURES.Lackmessung.fields.Messungen.name;
  public name = PRODUKT_CONFIG_FEATURES.Lackmessung.name;

  public trackByField = TrackBy.trackByField;

  public notizen$: Observable<Notiz[]>;
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;
  public mobileLandscapeOptimization: Subscription;

  public messungen: ViewFormArray;
  public messungenFields: FeatureFields;

  public rows$: Observable<AbstractViewFormControl[]>;

  public modelFileConfigs: ModelFileConfig[];
  public modelDisabled$ = new BehaviorSubject<boolean>(false);

  public display = ProduktDetailLackmessungDisplay.Modell;
  public positionen = new EnumValues(LackmessungPosition);

  @ViewChild('dialog', { static: true })
  public dialogTemplate: TemplateRef<any>;

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktLackmessungService: ProduktLackmessungService,
    private readonly notizenService: ProduktDetailFeatureNotizenService,
    private readonly formViewFactory: ProduktDetailLackmessungFormViewFactory,
    private readonly messungformViewFactory: ProduktDetailLackmessungMessungFormViewFactory,
    private readonly modelFileService: ModelFileService,
    private readonly snackBarService: SnackBarService,
    private readonly viewportService: ViewportService,
    private readonly templateDialogService: TemplateDialogService) {
    super(produktConfigResolveService, produktDetailResolveService, produktLackmessungService);
    Assert.notNullOrUndefined(notizenService, 'notizenService');
    Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
    Assert.notNullOrUndefined(messungformViewFactory, 'messungformViewFactory');
    Assert.notNullOrUndefined(modelFileService, 'formViewFactory');
    Assert.notNullOrUndefined(snackBarService, 'modelFileService');
    Assert.notNullOrUndefined(viewportService, 'viewportService');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
  }

  public ngOnInit(): void {
    this.notizen$ = this.notizenService.init(this.produkt, this.name);
    this.viewport$ = this.viewportService.observe();
    this.modelFileConfigs = this.modelFileService.get(this.produkt.fahrzeug.fahrzeugart, this.produkt.fahrzeug.bauform);
    this.mobileLandscapeOptimization = this.viewportService.mobileLandscapeOptimization();
    this.init(this.name);
  }

  public ngOnDestroy(): void {
    this.mobileLandscapeOptimization.unsubscribe();
  }

  public onDisplayChange(display: ProduktDetailLackmessungDisplay): void {
    Assert.notNullOrUndefined(display, 'display');
    this.display = display;
  }

  public onModelLoad(modelLoadResult: ModelLoadResult): void {
    Assert.notNullOrUndefined(modelLoadResult, 'modelLoadResult');
    if (modelLoadResult === ModelLoadResult.None) {
      this.modelDisabled$.next(true);
      this.onDisplayChange(ProduktDetailLackmessungDisplay.Manuell);
      this.snackBarService.warning('modell.couldNotLoad');
    } else if (modelLoadResult === ModelLoadResult.Fallback) {
      this.snackBarService.info('modell.fallback');
    }
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  public onPositionSelect(position: LackmessungPosition): void {
    Assert.notNullOrUndefined(position, 'position');
    this.createMessung(position);
  }

  public onRowOpen(row: ViewFormGroup): void {
    Assert.notNullOrUndefined(row, 'row');
    const index = this.messungen.controls.indexOf(row);
    this.editMessung(index, row.getRawValue());
  }

  public onRowOpenByIndex(index: number, item: any, panel: MatExpansionPanel, $event: MouseEvent): void {
    Assert.notNullOrUndefined(index, 'index');
    $event.stopPropagation();
    this.editMessung(index, item);
  }

  public onRowRemoveByIndex(index: number, panel: MatExpansionPanel, $event: MouseEvent): void {
    Assert.notNullOrUndefined(index, 'index');
    panel.close();
    $event.stopPropagation();
    this.messungen.removeAt(index);
  }

  public onRowRemove(row: ViewFormGroup): void {
    Assert.notNullOrUndefined(row, 'row');
    const index = this.messungen.controls.indexOf(row);
    this.messungen.removeAt(index);
  }

  public drop(event: CdkDragDrop<string[]>): void {
    const item = this.messungen.at(event.previousIndex);
    this.messungen.controls.splice(event.previousIndex, 1);
    this.messungen.controls.splice(event.currentIndex, 0, item);
    this.messungen.updateValueAndValidity();
  }

  protected createForm(): ViewFormGroup {
    const form = this.formViewFactory.create(this.produkt.lackmessung, this.fields);
    this.messungen = <ViewFormArray>form.get(this.messungenName);
    this.messungenFields = (<FeatureFieldArray>this.fields
      .find((x: FeatureFieldArray) => x.arrayName === this.messungenName))
      .fields;
    this.rows$ = this.getRows$();
    return form;
  }

  private createMessung(position: LackmessungPosition): void {
    const title = `${this.name}.${LackmessungPosition[position].toLowerCase()}`;
    const fields = this.messungenFields;
    const form = this.messungformViewFactory.create({ position }, fields);
    const buttons = [`${this.name}.cancel`, `${this.name}.save`];
    const data: ProduktDetailLackmessungDialogData = { form, fields };

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogTemplate, data).subscribe(result => {
        if (result && result.name === buttons[1]) {
          this.messungen.push(result.data.form);
        }
      });
  }

  private editMessung(index: number, messung: LackmessungMessung): void {
    const title = `${this.name}.${LackmessungPosition[messung.position].toLowerCase()}`;
    const fields = this.messungenFields;
    const form = this.messungformViewFactory.create(messung, fields);
    const buttons = [`${this.name}.cancel`, `${this.name}.save`];
    const data: ProduktDetailLackmessungDialogData = { form, fields };

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogTemplate, data).subscribe(result => {
        if (result && result.name === buttons[1]) {
          (<ViewFormGroup>this.messungen.controls[index]) = result.data.form;
          this.messungen.updateValueAndValidity();
        }
      });
  }

  private getRows$(): Observable<AbstractViewFormControl[]> {
    return this.messungen.valueChanges.pipe(
      startWith({}),
      map(() => <AbstractViewFormControl[]>[...this.messungen.controls]),
    );
  }
}
