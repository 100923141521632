import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ProduktArt } from '@data/domain/schema/enum';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { Observable, of, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProduktAuftragCanActivate  {

  constructor(readonly produktService: ProduktDetailResolveService,
              readonly router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    Assert.notNullOrUndefined(route, 'route');
    const canActivate = new Subject<boolean>();
    const produktId = route.paramMap.get('id') ?? route.parent.paramMap.get('id');
    this.produktService.resolve(produktId, false).pipe(first()).subscribe(
      produkt => {
        if (produkt.art === ProduktArt.VtiTooling) {
          this.router.navigate(['produkt', 'detail', produkt.id, 'vtiAuftrag']);
          canActivate.next(false);
        } else {
          canActivate.next(true);
        }
      }
    );
    return canActivate.asObservable();
  }
}
