<ng-container *ngIf="result$ | async as result; else loading">
    <ng-container *ngIf="result.file; else reload">
        <div [class.clickable]="result.thumb" (click)="onFileClick(result)">
            <app-image *ngIf="result.image" [file]="result.file"></app-image>
        </div>
    </ng-container>
    <ng-template #reload>
        <button mat-button (click)="onReloadClick()">
            {{'file.reload' | translate}}
        </button>
    </ng-template>
</ng-container>
<ng-template #loading>
    <app-progress-spinner></app-progress-spinner>
</ng-template>
