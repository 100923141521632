import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { EnumValues } from '@shared/helper/values';
import { ViewportOrientation, ViewportOrientationService } from '@shared/service/viewport-orientation.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CameraViewState } from '../camera-view/camera-view.component';

@Component({
  selector: 'app-camera-controls',
  templateUrl: './camera-controls.component.html',
  styleUrls: ['./camera-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CameraControlsComponent implements OnInit, OnDestroy {
  private orientationSubscription: Subscription;

  public orientation$ = new BehaviorSubject(ViewportOrientation.Portrait);
  public orientations = new EnumValues(ViewportOrientation);

  @Input()
  public state: CameraViewState;

  @Output()
  public trigger = new EventEmitter<ViewportOrientation>();

  @Output()
  public flip = new EventEmitter<void>();

  @Output()
  public save = new EventEmitter<void>();

  @ViewChild('orientation')
  public viewportOrientationButton: MatButton;

  constructor(private readonly viewportOrientationService: ViewportOrientationService) { }

  public ngOnInit(): void {
    this.orientationSubscription = this.viewportOrientationService.observe().subscribe(value => {
      this.orientation$.next(value);
      this.animateButton();
    });
  }

  public ngOnDestroy(): void {
    if (this.orientationSubscription) {
      this.orientationSubscription.unsubscribe();
    }
  }

  public onFlipClick(): void {
    this.flip.next();
  }

  public onOrientationChange(): void {
    if (this.orientationSubscription) {
      this.orientationSubscription.unsubscribe();
      this.orientationSubscription = undefined;
    }

    this.orientation$.next(
      this.orientation$.value === ViewportOrientation.Landscape
        ? ViewportOrientation.Portrait : ViewportOrientation.Landscape);
  }

  public onTrigger(): void {
    this.trigger.emit(this.orientation$.value);
  }

  public onSave(): void {
    this.save.emit(null);
  }

  private animateButton(): void {
    if (this.viewportOrientationButton) {
      this.viewportOrientationButton.ripple.fadeOutAll();
      this.viewportOrientationButton.ripple.launch({
        centered: true,
        radius: 99
      });
    }
  }
}
