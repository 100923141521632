import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BueroEinstellungen } from '@data/api-gateway/service/einstellungen.service';
import { EinstellungenBueroFormFactory } from '@modules/einstellungen/factory';
import { EinstellungenViewModelResolveService } from '@modules/einstellungen/service';
import { Assert } from '@shared/helper/assert';

@Component({
  selector: 'app-einstellungen-buero-form',
  templateUrl: './einstellungen-buero-form.component.html',
  styleUrls: ['./einstellungen-buero-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EinstellungenBueroFormComponent implements OnInit {
  public readonly name = 'einstellungen';
  public form: UntypedFormGroup;
  public maxFileUploadSizeInKb = 500;

  @Input()
  public saving: boolean;

  @Output()
  public save = new EventEmitter<BueroEinstellungen>();

  constructor(
    private readonly resolve: EinstellungenViewModelResolveService,
    private readonly factory: EinstellungenBueroFormFactory) {
    Assert.notNullOrUndefined(resolve, 'resolve');
    Assert.notNullOrUndefined(factory, 'factory');
  }

  public ngOnInit(): void {
    const { buero } = this.resolve.get();

    this.form = this.factory.create(buero);
  }

  public onSave(): void {
    this.save.next(this.form.value);
  }
}
