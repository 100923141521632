import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-list-option',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListOptionComponent {
  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public id: string;

  @Input()
  public displayName: string;

  public get selected(): boolean {
    return this.form.get(this.name).value;
  }

  public set selected(selected: boolean) {
    this.form.get(this.name).setValue(selected);
  }
}
