import { Clipboard } from '@angular/cdk/clipboard';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { guid } from '@app/function/guid';
import { ExternalDataServiceResponseCode, WerteRequest, WerteService, WerteServiceResponse } from '@data/api-gateway';
import { AbrechnungProvider, AbrechnungService, BesteuerungArt, ProduktArt } from '@data/domain/schema/enum';
import { Abrechnung, Notiz, VtiWerte, VtiWerteInput } from '@data/domain/schema/type';
import { ProduktAbrechnungService } from '@data/domain/service/feature/produkt-abrechnung.service';
import { ProduktVtiWerteService } from '@data/domain/service/feature/produkt-vti-werte.service';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import {
  ProduktDetailFeatureInputComponent
} from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureField, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import {
  ProduktDetailVtiWerteFormViewFactory
} from '@modules/produkt/factory/vti-werte/produkt-detail-vti-werte-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ExternalServiceOperation } from '@modules/produkt/page/produkt-detail-werte/produkt-detail-werte.component';
import { DatSearchService } from '@modules/produkt/service/dat-search.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable, PartialObserver, Subscription } from 'rxjs';

@Component({
  selector: 'app-produkt-detail-vti-werte',
  templateUrl: './produkt-detail-vti-werte.component.html',
  styleUrls: ['./produkt-detail-vti-werte.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailVtiWerteComponent extends ProduktDetailFeatureInputComponent<VtiWerte, VtiWerteInput> implements OnInit, AfterViewInit, OnDestroy {

  public feature = PRODUKT_CONFIG_FEATURES.VtiWerte.name;
  public fieldWertAmMarktBemerkungen = PRODUKT_CONFIG_FEATURES.VtiWerte.fields.WertAmMarktBemerkungen.name;

  public trackByField = TrackBy.trackByField;

  public notizen$: Observable<Notiz[]>;
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;

  public hasExternalServiceIdentifier = false;
  public datRequestActive$: BehaviorSubject<boolean>;

  private mwstRegelbesteuerungSatz = 19;
  private mwstDifferenzbesteuerungSatz = 2.4;

  private subscriptions: Subscription[] = [];

  @ViewChild('wertAmMarktBemerkungen')
  public wertAmMarktBemerkungenElement: TextbausteineComponent;

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktVtiWerteService: ProduktVtiWerteService,
    private readonly clipboard: Clipboard,
    private readonly datSearchService: DatSearchService,
    private readonly werteService: WerteService,
    private readonly currencyFormatter: CurrencyFormatterService,
    private readonly formViewFactory: ProduktDetailVtiWerteFormViewFactory,
    private readonly produktAbrechnungService: ProduktAbrechnungService,
    private readonly snackBarService: SnackBarService,
    private readonly viewportService: ViewportService,
    private readonly notizenService: ProduktDetailFeatureNotizenService,
    private readonly textbausteineService: TextbausteineService,
  ) {
    super(produktConfigResolveService, produktDetailResolveService, produktVtiWerteService);
  }

  public ngOnInit(): void {
    const name = PRODUKT_CONFIG_FEATURES.VtiWerte.name;
    this.notizen$ = this.notizenService.init(this.produkt, name);
    this.viewport$ = this.viewportService.observe();
    this.hasExternalServiceIdentifier = this.hasExternalServiceIdentified();
    this.init(name);
    this.datRequestActive$ = this.datSearchService.getDatRequestActive();
  }

  public ngAfterViewInit(): void {
    if (this.form.get(this.fieldWertAmMarktBemerkungen) && this.produkt.werte.wertAmMarktBemerkungen === null) {
      this.textbausteineService.prefillWithStandardTextbausteine(this.feature, this.fieldWertAmMarktBemerkungen, this.produkt.art, <ViewFormGroup>this.form, this.wertAmMarktBemerkungenElement);
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  public onGetWerteClick(event: MouseEvent): void {
    Assert.notNullOrUndefined(event, 'event');
    event.stopPropagation();
    this.getWerte();
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  public copy(elementId: any): void {
    const inputValue = (<HTMLInputElement>document.getElementById(elementId)).value;
    this.clipboard.copy(inputValue);
    this.snackBarService.success('Wert kopiert: ' + inputValue);
  }

  protected createForm(): ViewFormGroup {
    const form = this.formViewFactory.create(this.produkt.vtiWerte, this.fields);
    return form;
  }

  private hasExternalServiceIdentified(): boolean {
    const fahrzeugExternalServiceReference = this.produkt.fahrzeug?.fahrzeugExternalServiceReference;
    if (!fahrzeugExternalServiceReference?.identifier) {
      return false;
    }
    const {constructionTime, container, identifier} = fahrzeugExternalServiceReference;
    return !!constructionTime && !!container && !!identifier;
  }

  private getWerte(): void {
    this.datRequestActive$.next(true);
    const request = this.createWerteRequest();
    if (!request.referenceId) {
      const observer = this.createObserver(ExternalServiceOperation.Post);
      this.werteService.post(request).subscribe(observer);
    } else {
      const observer = this.createObserver(ExternalServiceOperation.Put);
      this.werteService.put(request).subscribe(observer);
    }
  }

  private createWerteRequest(): WerteRequest {
    return {
      erstzulassung: this.produkt.fahrzeug.erstzulassung,
      laufleistung: this.produkt.fahrzeug.laufleistung,
      identifier: this.produkt.fahrzeug.fahrzeugExternalServiceReference.identifier,
      constructionTime: this.produkt.fahrzeug.fahrzeugExternalServiceReference.constructionTime,
      container: this.produkt.fahrzeug.fahrzeugExternalServiceReference.container,
      referenceId: this.produkt.werte.externalServicesReferenceId,
      specialEquipmentIds: this.getSpecialEquipmentIds(),
    };
  }

  private getSpecialEquipmentIds(): string[] {
    const specialEquipmentIds: string[] = [];
    this.produkt.ausstattung.gruppen.forEach(gruppe => {
      if (gruppe.sonderausstattung) {
        (gruppe.teile || []).forEach(teil => {
          if (teil.vorhanden) {
            specialEquipmentIds.push(teil.externalServiceId);
          }
        });
      }
    });
    return specialEquipmentIds;
  }

  private createObserver(operation: string): PartialObserver<WerteServiceResponse> {
    const observer: PartialObserver<WerteServiceResponse> = {
      next: response => {
        this.handleResponse(response);
        this.handleAbrechung(response, operation);
      },
      error: () => this.snackBarService.error(`werte.externalDataServiceResponseCode.error${operation}`),
      complete: () => {
        this.datRequestActive$.next(false);
      }
    };
    return observer;
  }

  private handleAbrechung(response: WerteServiceResponse, operation: string): void {
    if (operation === ExternalServiceOperation.Post && response.responseCode === ExternalDataServiceResponseCode.Success) {
      const abrechnung = this.CreateAbrechnung(response.werte.externalServicesReferenceId);
      this.produktAbrechnungService.saveAbrechnung(this.produkt.art, this.produkt.id, guid(), abrechnung);
    }
  }

  private CreateAbrechnung(externalServicesReferenceId: string): Abrechnung {
    return {
      id: guid(),
      datum: moment.utc().toISOString(),
      externalProvider: AbrechnungProvider.Dat,
      externalService: AbrechnungService.Werte,
      identifier: externalServicesReferenceId,
    };
  }

  private handleResponse(response: WerteServiceResponse): void {
    if (!response) {
      this.snackBarService.error('werte.externalDataServiceResponseCode.error');
      return;
    }
    switch (response.responseCode) {
      case ExternalDataServiceResponseCode.Error:
        this.snackBarService.error('werte.externalDataServiceResponseCode.error');
        break;
      case ExternalDataServiceResponseCode.ErrorCreateDossier:
        this.snackBarService.error('werte.externalDataServiceResponseCode.errorCreateDossier');
        break;
      case ExternalDataServiceResponseCode.ErrorNoValidConstructionTime:
        this.snackBarService.error('werte.externalDataServiceResponseCode.errorNoValidConstructionTime');
        break;
      default:
        this.snackBarService.success('werte.externalDataServiceResponseCode.success');
        this.updateWerte(response);
        break;
    }
  }

  private updateWerte(response: WerteServiceResponse): void {
    const fields = PRODUKT_CONFIG_FEATURES.VtiWerte.fields;
    this.fields.forEach((field: FeatureField) => {
      const name = field.name;
      switch (name) {
        case fields.Bezugsdatum.name:
          this.form.setControlValue(name, new Date());
          break;
        case fields.Haendlereinkaufswert.name:
          this.form.setControlValue(name, response.werte.haendlereinkaufswert);
          break;
        case fields.HaendlereinkaufswertNetto.name:
          this.form.setControlValue(name, response.werte.haendlereinkaufswertNetto);
          break;
        case fields.Haendlerverkaufswert.name:
          this.form.setControlValue(name, response.werte.haendlerverkaufswert);
          break;
        case fields.HaendlerverkaufswertNetto.name:
          this.form.setControlValue(name, response.werte.haendlerverkaufswertNetto);
          this.form.setControlValue('haendlerverkaufswertBesteuerung', BesteuerungArt.Differenzbesteuert);
          break;
        case fields.Neuwert.name:
          this.form.setControlValue(name, response.werte.neuwert);
          break;
        case fields.RelativerWert.name:
          this.form.setControlValue(name, response.werte.relativerWert);
          break;
        case fields.RelativerWertDrucken.name:
          const istRuecknahmebewertungUndRelativerWertPositiv = (this.produkt.art === ProduktArt.Ruecknahmebewertung) && (response.werte.relativerWert > 0);
          this.form.setControlValue(name, istRuecknahmebewertungUndRelativerWertPositiv);
          break;
        case fields.ExternalServicesReferenceId.name:
          this.form.setControlValue(name, response.werte.externalServicesReferenceId);
          break;
      }
    });
  }

  private getMwstSatz(besteuerungsart: BesteuerungArt) {
    if (besteuerungsart === BesteuerungArt.Differenzbesteuert) {
      return this.mwstDifferenzbesteuerungSatz;
    }
    return this.mwstRegelbesteuerungSatz;
  }
}
