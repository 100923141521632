import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, UntypedFormControl } from '@angular/forms';
import { FileValidationService, FileValidationTypes } from '@app/service/file-validation.service';
import { FileService } from '@app/service/file.service';
import { ProduktArt } from '@data/domain/schema/enum';
import { Notiz, Uebersicht, UebersichtInput } from '@data/domain/schema/type';
import { ProduktUebersichtService } from '@data/domain/service/feature';
import {
  ProduktDetailFeatureInputComponent
} from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import {
  ProduktDetailUebersichtFormViewFactory
} from '@modules/produkt/factory/produkt-detail-uebersicht-form-view.factory';
import {
  ProduktDetailUebersichtSonstigesFormViewFactory
} from '@modules/produkt/factory/produkt-detail-uebersicht-sonstiges-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ModelFileService } from '@modules/produkt/service/model-file.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { CaptureDialogService } from '@shared/service/capture-dialog.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { UploadDialogService } from '@shared/service/upload-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { Scene } from 'three';

export enum UebersichtDetailUploadArt {
  Capture = 1,
  File = 2
}

interface ProduktDetailUebersichtSonstigesDialogData {
  form: ViewFormGroup;
  fields: FeatureFields;
  produktArt: ProduktArt;
}

@Component({
  selector: 'app-produkt-detail-uebersicht',
  templateUrl: './produkt-detail-uebersicht.component.html',
  styleUrls: ['./produkt-detail-uebersicht.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailUebersichtComponent extends ProduktDetailFeatureInputComponent<Uebersicht, UebersichtInput> implements OnInit, OnDestroy {
  public trackByField = TrackBy.trackByField;

  public notizen$: Observable<Notiz[]>;
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;

  public scene: Scene;

  public icon$ = new BehaviorSubject('photo_camera');
  public iconSonstiges = 'add';
  public count$: Observable<number>;

  public uploadArt = UebersichtDetailUploadArt.Capture;

  public isModelLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public modelFileConfigs: ModelFileConfig[];
  public hasImages$ = new BehaviorSubject<boolean>(false);

  public subscriptions: Subscription[] = [];

  @ViewChild('dialog', {static: true})
  public dialogTemplate: TemplateRef<any>;

  @ViewChild('dialogDeleteAll', {static: true})
  public dialogDeleteAllTemplate: TemplateRef<any>;

  public sonstigesFields: FeatureFields;
  public sonstigeFields = PRODUKT_CONFIG_FEATURES.Uebersicht.fields.Sonstiges.fields;
  public sonstiges: ViewFormArray;
  private sonstigesName = PRODUKT_CONFIG_FEATURES.Uebersicht.fields.Sonstiges.name;

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktUebersichtService: ProduktUebersichtService,
    private readonly uebersichtFormViewFactory: ProduktDetailUebersichtFormViewFactory,
    private readonly uebersichtSonstigesFormViewFactory: ProduktDetailUebersichtSonstigesFormViewFactory,
    private readonly uploadService: UploadDialogService,
    private readonly captureService: CaptureDialogService,
    private readonly fileFieldService: ProduktDetailFileFieldService,
    private readonly modelFileService: ModelFileService,
    private readonly snackBarService: SnackBarService,
    private readonly viewportService: ViewportService,
    private readonly templateDialogService: TemplateDialogService,
    private readonly notizenService: ProduktDetailFeatureNotizenService,
    private readonly fileService: FileService,
    private readonly fileValidationService: FileValidationService) {
    super(produktConfigResolveService, produktDetailResolveService, produktUebersichtService);
    Assert.notNullOrUndefined(uebersichtFormViewFactory, 'uebersichtFormViewFactory');
    Assert.notNullOrUndefined(uploadService, 'uploadService');
    Assert.notNullOrUndefined(captureService, 'captureService');
    Assert.notNullOrUndefined(fileFieldService, 'fileFieldService');
    Assert.notNullOrUndefined(modelFileService, 'modelFileService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    Assert.notNullOrUndefined(viewportService, 'viewportService');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
    Assert.notNullOrUndefined(fileValidationService, 'fileValidationService');
  }

  public ngOnInit(): void {
    const name = PRODUKT_CONFIG_FEATURES.Uebersicht.name;
    this.notizen$ = this.notizenService.init(this.produkt, name);
    this.viewport$ = this.viewportService.observe();
    this.modelFileConfigs = this.modelFileService.get(this.produkt.fahrzeug.fahrzeugart, this.produkt.fahrzeug.bauform);
    this.init(name);
    this.hasImages$.next(this.getImages().length > 0);
    this.initSubscriptions();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public getSonstigesFormGroup(id: number): ViewFormGroup {
    return (<ViewFormGroup>this.sonstiges.controls[ id ]);
  }

  public onSceneLoad(scene: Scene): void {
    Assert.notNullOrUndefined(scene, 'scene');
    this.scene = scene;
  }

  public onModelLoad(modelLoadResult: ModelLoadResult): void {
    Assert.notNullOrUndefined(modelLoadResult, 'modelLoadResult');
    if (modelLoadResult === ModelLoadResult.None) {
      this.isModelLoaded$.next(false);
      this.snackBarService.warning('modell.couldNotLoad');
    } else if (modelLoadResult === ModelLoadResult.Fallback) {
      this.snackBarService.info('modell.fallback');
    }
  }

  public onUploadArtChange(uploadArt: UebersichtDetailUploadArt): void {
    Assert.notNullOrUndefined(uploadArt, 'uploadArt');
    this.uploadArt = uploadArt;
    this.icon$.next(uploadArt === UebersichtDetailUploadArt.Capture ? 'photo_camera' : 'folder');
  }

  public onIndicatorClick(field: UntypedFormControl, name: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(name, 'fieldName');
    const title = `uebersicht.${name}`;
    const files$ = this.uploadArt === UebersichtDetailUploadArt.Capture
      ? this.captureService.captureImage(title)
      : this.uploadService.uploadFiles(title, '.png, .jpg, .jpeg, .bmp');
    files$.subscribe(files => {
      const validFiles = this.fileValidationService.validateFileTypeAndExtension(files, FileValidationTypes.Image).validFiles;
      if (validFiles) {
        this.fileFieldService.add(field, validFiles);
      }
    });
  }

  public onSonstigesClick(field: UntypedFormControl, name: string): void {
    const title = `uebersicht.${name}`;
    this.sonstigesFields = (<FeatureFieldArray>this.fields
      .find((x: FeatureFieldArray) => x.arrayName === 'sonstiges'))
      .fields;
    const fields = this.sonstigesFields;
    const form = this.uebersichtSonstigesFormViewFactory.create({
      id: '',
      bilder: [],
      bezeichnung: '',
    }, fields);

    const buttons = [`${this.name}.cancel`, `${this.name}.save`];
    const data: ProduktDetailUebersichtSonstigesDialogData = {form, fields, produktArt: this.produkt.art};

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogTemplate, data).subscribe(result => {

      if (result.data.form.get('bilder').value.length === 0) {
        this.snackBarService.info('uebersicht.noImageSelected');
        return;
      }

      if (!result.data.form.get('bezeichnung').value) {
        result.data.form.patchValue({bezeichnung: 'Sonstige Bilder'});
      }

      this.sonstiges.push(result.data.form);
    });

  }

  public onFileDelete(field: UntypedFormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.remove(field, fileId);
    if (this.form.get('deckblatt').value === fileId) {
      this.form.patchValue({deckblatt: null});
    }
  }

  public onFileDeleteSonstiges(field: ViewFormControl<any>, fileId: string, id: number): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');

    if (this.sonstiges.value[ id ].bilder.length === 1) {
      const newValue = (<ViewFormArray>this.form.get(this.sonstigesName)).controls.splice(id, 1);
      this.form.patchValue({[ this.sonstigesName ]: newValue});
    } else {
      const index: number = this.sonstiges.value[ id ].bilder.indexOf(fileId);
      if (index !== -1) {
        const newValue = (<ViewFormArray>this.form.get(this.sonstigesName)).value[ id ].bilder.splice(index, 1);
        this.form.patchValue({[ this.sonstigesName ]: newValue});
      }
    }
  }

  public onFileUpdate(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(event, 'event');
    this.fileFieldService.update(field, event);
  }

  public onFileReset(field: UntypedFormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.reset(field, fileId);
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  public isFileResetable(fileId: string): boolean {
    Assert.notNullOrEmpty(fileId, 'fileId');
    return this.fileFieldService.isUpdateable(fileId);
  }

  public onDeleteAll(): void {
    const title = 'Alle Übersichtsbilder löschen';
    const buttons = [`${this.name}.cancel`, `${this.name}.confirm`];

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogDeleteAllTemplate).subscribe(result => {
      if (result.name && result.name === buttons[ 1 ]) {
        this.deleteAllImages();
      }
    });
  }

  public onDeckblattSelect(deckblattInput: string): void {
    if (deckblattInput) {
      this.form.patchValue({
        deckblatt: deckblattInput
      });
    }
  }

  protected createForm(): ViewFormGroup {
    const form = this.uebersichtFormViewFactory.create(this.produkt.uebersicht, this.fields);
    this.sonstiges = <ViewFormArray>form.get(this.sonstigesName);
    // this.count$ = form.valueChanges.pipe(
    //   startWith({}),
    //   map(() => this.fields
    //     .reduce((pv, field) => pv + this.form.get((<FeatureField>field).name).value.length, 0)));
    return form;
  }

  public drop($event: CdkDragDrop<any>): void {
    const item = this.sonstiges.at($event.previousIndex);
    this.sonstiges.controls.splice($event.previousIndex, 1);
    this.sonstiges.controls.splice($event.currentIndex, 0, item);
    this.sonstiges.updateValueAndValidity();
  }

  private initSubscriptions(): void {
    this.subscriptions.push(this.form.valueChanges.subscribe(_value => this.hasImages$.next(this.getImages().length > 0)));
    this.subscriptions.push(this.viewportService.mobileLandscapeOptimization());

    const controls = [
      this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.VorneLinks.name),
      this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.VorneRechts.name),
      this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.HintenLinks.name),
      this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.HintenRechts.name),
      this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.Innenraum.name)
    ];

    controls.forEach(control => {
      this.subscriptions.push(control.valueChanges.pipe().subscribe(result => this.setDeckblattBild(result)));
    });
  }

  private setDeckblattBild(result: any): void {
    const deckblattField = this.form.get(PRODUKT_CONFIG_FEATURES.Uebersicht.fields.Deckblatt.name);
    if (deckblattField.value) {
      return;
    }
    if (result) {
      this.form.patchValue({
        deckblatt: result[0]
      });
    } else {
      console.error('Cannot to set Deckblatt Bild, result is undefined.');
    }
  }

  private getImages(): string[] {
    let images: string[] = [];
    const uebersichtRawValue: Uebersicht = this.form.getRawValue();
    uebersichtRawValue.hintenLinks.forEach(value => images = images.concat(value));
    uebersichtRawValue.hintenRechts.forEach(value => images = images.concat(value));
    uebersichtRawValue.innenraum.forEach(value => images = images.concat(value));
    uebersichtRawValue.sonstiges.forEach(value => images = images.concat(value.bilder));
    uebersichtRawValue.vorneLinks.forEach(value => images = images.concat(value));
    uebersichtRawValue.vorneRechts.forEach(value => images = images.concat(value));
    return images;
  }

  private deleteAllImages(): void {
    const imagesToDelete = this.getImages();

    imagesToDelete.forEach(image => this.fileService.delete(image).pipe(first())
      .subscribe(next => this.fileService.syncCount()));

    this.form.patchValue({
      deckblatt: '',
      [ 'hintenLinks' ]: [],
      [ 'hintenRechts' ]: [],
      [ 'innenraum' ]: [],
      [ 'vorneLinks' ]: [],
      [ 'vorneRechts' ]: [],
    });
    (<FormArray>this.form.get('sonstiges')).clear();
  }
}
