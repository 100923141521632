import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { deleteVtiAuftragNotiz, saveVtiAuftragNotiz } from '@data/domain/graphql/mutations';
import { Notiz, Produkt } from '@data/domain/schema/type';
import { ProduktService } from '../../produkt.service';
import { ProduktFeatureNotizenService } from './produkt-feature-notizen.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktVtiAuftragNotizenService extends ProduktFeatureNotizenService {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    public getNotizen(produkt: Produkt): Notiz[] {
        if (!produkt.vtiAuftrag.notizen) {
            produkt.vtiAuftrag.notizen = [];
        }
        return produkt.vtiAuftrag.notizen;
    }

    protected getSaveNotizMutation(): any {
        return saveVtiAuftragNotiz;
    }

    protected getSaveNotizMutationName(): string {
        return 'saveVtiAuftragNotiz';
    }

    protected getDeleteNotizMutation(): any {
        return deleteVtiAuftragNotiz;
    }

    protected getDeleteNotizMutationName(): any {
        return 'deleteVtiAuftragNotiz';
    }
}
