import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ErrorComponent } from './page/error/error.component';
import { SigninComponent } from './page/signin/signin.component';
import { SignoutComponent } from './page/signout/signout.component';
@NgModule({
  declarations: [
    SignoutComponent,
    SigninComponent,
    ErrorComponent
  ],
  imports: [
    SharedModule
  ]
})
export class AuthModule { }
