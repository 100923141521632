<div class="main-content">
  <app-card class="max-width-33">
      <h3>{{name + '.letzterService' | translate}}</h3>
      <app-slide-toggle [form]="form" [name]="'letzterServiceNichtBekannt'" [displayName]="name + '.nichtBekannt'"
                        (change)="letzterServiceNichtBekannt$.next($event.checked)"></app-slide-toggle>
      <div [class.disabled]="letzterServiceNichtBekannt$ | async">
      <ng-container *ngFor="let field of fields;trackBy:trackByField">
          <ng-container [ngSwitch]="field.name || field.groupName">
              <ng-container *ngSwitchCase="'letzterServiceGroup'">
                  <ng-container
                      *ngTemplateOutlet="distanceDate, context: { distance: 'letzterServiceBei', date: 'letzterServiceAm',
                       unit: 'letzterServiceFaelligEinheit'}">
                  </ng-container>
             </ng-container>
         </ng-container>
      </ng-container>
      </div>
  </app-card>

  <app-card class="max-width-33">
      <h3>{{name + '.naechsterService' | translate}}</h3>
      <app-slide-toggle [form]="form" [name]="'naechsterServiceFaelligNichtBekannt'" [displayName]="name + '.nichtBekannt'"
                        (change)="naechsterServiceFaelligNichtBekannt$.next($event.checked)"></app-slide-toggle>
      <div [class.disabled]="naechsterServiceFaelligNichtBekannt$ | async">
      <ng-container *ngFor="let field of fields;trackBy:trackByField" >
          <ng-container [ngSwitch]="field.name || field.groupName || field.arrayName">
              <ng-container *ngSwitchCase="'naechsterServiceGroup'">
                  <ng-container
                      *ngTemplateOutlet="distanceDay, context: { distance: 'naechsterServiceFaelligIn', unit: 'naechsterServiceFaelligEinheit',
                      time: 'naechsterServiceFaelligInZeit', unitTime: 'naechsterServiceFaelligEinheitZeit', date: 'naechsterServiceFaelligAm' }">
                  </ng-container>
             </ng-container>
             <ng-container *ngSwitchCase="'naechsterServiceFaelligKosten'">
                  <ng-container *ngTemplateOutlet="currency, context: { field: field }">
                  </ng-container>
             </ng-container>
              <ng-container *ngSwitchCase="'naechsterServiceBeschreibung'">
                  <ng-container *ngTemplateOutlet="beschreibung, context: { field: field }">
                  </ng-container>
             </ng-container>
          </ng-container>
      </ng-container>
      </div>
  </app-card>

  <app-card class="max-width-33">
      <h3>{{name + '.huAu' | translate}}</h3>
      <app-slide-toggle [form]="form" [name]="'naechsteHauptAbgasUntersuchungNichtBekannt'" [displayName]="name + '.nichtBekannt'"
                        (change)="naechsteHauptAbgasUntersuchungNichtBekannt$.next($event.checked)"></app-slide-toggle>
      <div [class.disabled]="naechsteHauptAbgasUntersuchungNichtBekannt$ | async">
      <ng-container *ngFor="let field of fields;trackBy:trackByField">
          <ng-container [ngSwitch]="field.name">
              <ng-container *ngSwitchCase="'naechsteHauptAbgasUntersuchung'">
                  <ng-container *ngTemplateOutlet="date, context: { name: field.name }">
                  </ng-container>
             </ng-container>
              <ng-container *ngSwitchCase="'hauptAbgasUntersuchungFaelligKosten'">
                  <ng-container *ngTemplateOutlet="currency, context: { field: field}">
                  </ng-container>
              </ng-container>
          </ng-container>
     </ng-container>
     </div>
  </app-card>

  <app-card class="max-width-50">
      <h3>{{name + '.zahnriemen' | translate}}</h3>
      <ng-container *ngFor="let field of fields;trackBy:trackByField">
          <ng-container [ngSwitch]="field.name || field.groupName">
              <ng-container *ngSwitchCase="'zahnriemenwechselGroup'">
                  <ng-container
                      *ngTemplateOutlet="distanceDate, context: { distance: 'zahnriemenwechselBei', date: 'zahnriemenwechselAm',
                       unit: 'zahnriemenwechselFaelligEinheit'}">
                  </ng-container>
             </ng-container>
              <ng-container *ngSwitchCase="'zahnriemenFaelligKosten'">
                  <ng-container *ngTemplateOutlet="currency, context: { field: field }">
                  </ng-container>
              </ng-container>
          </ng-container>
      </ng-container>
  </app-card>

  <app-card class="max-width-50">
      <h3>{{name + '.zusatzarbeiten' | translate}}</h3>
      <ng-container *ngFor="let field of fields;trackBy:trackByField">
          <ng-container [ngSwitch]="field.arrayName">
              <ng-container *ngSwitchCase="'positionen'">
                  <app-action-list [values]="positionenValues" (action)="onPositionSelect($event)"
                      (submit)="onPositionSubmit($event)" [searchable]="true">
                  </app-action-list>
              </ng-container>
          </ng-container>
     </ng-container>
  </app-card>
</div>

<!-- Side Panel -->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop">
  <label>{{( name + '.total' | translate) + '&nbsp;(' + positionen.controls.length + ')'}}</label>
  <ng-container *ngTemplateOutlet="wartungskosten"></ng-container>
</app-side-panel>

<!-- Bottom Sheet -->
<app-bottom-sheet *ngIf="(viewport$ | async) !== viewport.Desktop"
                  [title]="( name + '.total' | translate) + '&nbsp;(' + positionen.controls.length + ')&nbsp;'"
                  [value]="(sums$ | async)"
                  [seamless]="true">
  <ng-container *ngTemplateOutlet="wartungskosten"></ng-container>
</app-bottom-sheet>

<!-- Templates -->
<ng-template #wartungskosten>
  <app-form-table [rows]="rows$ | async" (rowOpen)="onRowOpen($event)" (rowRemove)="onRowRemove($event)">
    <ng-container *ngFor="let field of positionenFields;trackBy:trackByField">
      <ng-container [ngSwitch]="field.name">
        <ng-container *ngSwitchCase="'bezeichnung'">
          <app-form-table-column [name]="field.name" [displayName]="name + '.' + field.name"
                                 [footerDisplayName]="name + '.total'" [fullWidth]="true" type="text">
          </app-form-table-column>
        </ng-container>
        <ng-container *ngSwitchCase="'kosten'">
          <app-form-table-column [name]="field.name" [displayName]="name + '.' + field.name" type="currency">
          </app-form-table-column>
        </ng-container>
      </ng-container>
    </ng-container>
  </app-form-table>
</ng-template>

<!-- Templates -->

<ng-template #distanceDate let-distance="distance" let-date="date" let-min="min" let-max="max"
             let-disableDay="disableDay" let-unit="unit">
    <app-form-group cols="5">
        <app-form-field colspan="3">
            <app-input [form]="form" [name]="distance" [displayName]="name + '.' + distance" type="text"
                inputmode="numeric">
            </app-input>
        </app-form-field>

        <app-form-field colspan="2">
          <app-select [form]="form" [name]="unit" [displayName]="name + '.' + unit" [values]="wartungEinheit"
                    [valuePrefix]="name + '.'" [hasEmptyField]="false">
          </app-select>
        </app-form-field>
    </app-form-group>
  <app-form-group>
     <app-form-field>
        <app-date [form]="form" [name]="date" [displayName]="name + '.' + date" [min]="min" [max]="max" [disableDay]="disableDay">
        </app-date>
      </app-form-field>
  </app-form-group>
</ng-template>

<ng-template #date let-fieldName="name" let-min="min" let-max="max">
    <app-date [form]="form" [name]="fieldName" [displayName]="name + '.' + fieldName" [min]="min" [max]="max" [disableDay]="true">
    </app-date>
</ng-template>

<ng-template #currency let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
        inputmode="numeric">
        <ng-container appSuffix>&nbsp;€</ng-container>
    </app-input>
</ng-template>
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)">
</app-produkt-detail-notizen-button>

<ng-template #beschreibung let-field="field">
  <app-textbausteine #naechsterServiceBeschreibung [form]="form" [name]="'naechsterServiceBeschreibung'" [displayName]="'Beschreibung'"
  [feature]="'Wartung'" [textbausteine]="textbausteine$" [produktArt]="this.produkt.art">
</app-textbausteine>
</ng-template>

<ng-template #distanceDay let-distance="distance" let-unit="unit" let-time="time" let-unitTime="unitTime" let-date="date">
  <app-form-group cols="5">
    <app-form-field colspan="3">
      <app-input [form]="form" [name]="distance" [displayName]="name + '.' + distance" type="text"
                 inputmode="numeric">
      </app-input>
    </app-form-field>
    <app-form-field colspan="2">
      <app-select [form]="form" [name]="unit" [displayName]="name + '.' + unit" [values]="wartungEinheit"
                  [valuePrefix]="name + '.'" [hasEmptyField]="false">
      </app-select>
    </app-form-field>
  </app-form-group>
  <app-form-group cols="5">
    <app-form-field colspan="3">
      <app-input [form]="form" [name]="time" [displayName]="name + '.' + time" type="text"
                 inputmode="numeric">
      </app-input>
    </app-form-field>
    <app-form-field colspan="2">
      <app-select [form]="form" [name]="unitTime" [displayName]="name + '.' + unitTime" [values]="wartungEinheitZeit"
                  [valuePrefix]="name + '.'" [hasEmptyField]="false">
      </app-select>
    </app-form-field>
  </app-form-group>
  <app-form-group *ngIf="form.get(date)">
    <app-form-field>
      <app-date [form]="form" [name]="date" [displayName]="name + '.' + date">
      </app-date>
    </app-form-field>
  </app-form-group>
</ng-template>

<!-- Dialog -->
<ng-template #dialog let-data="data">
    <app-produkt-detail-wartung-position [form]="data.form" [name]="name" [fields]="data.fields">
    </app-produkt-detail-wartung-position>
</ng-template>
