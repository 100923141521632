import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { AuthService } from '@app/service/auth.service';
import { Assert } from '@shared/helper/assert';
import { from, of } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-signin',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider,
    private readonly router: Router) {
    Assert.notNullOrUndefined(authService, 'authService');
    Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
    Assert.notNullOrUndefined(router, 'router');
  }

  public ngOnInit(): void {
    from(
      this.awsAppSyncClientProvider.provide().resetStore()
    ).pipe(
      catchError(() => of(null)),
      flatMap(() => this.authService.completeSignin().pipe(
        flatMap(() => this.router.navigate(['/'], {
          replaceUrl: true,
        })),
        catchError(error => this.router.navigate(['auth', 'error'], {
          replaceUrl: true
        })),
      ))
    ).subscribe();
  }
}
