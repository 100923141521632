import { Directive, OnDestroy } from '@angular/core';
import { ProduktStatus } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktFeatureService } from '@data/domain/service/feature';
import { Feature, FeatureFields } from '@modules/produkt/config/produkt-config';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { asyncScheduler, Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, mergeMap, tap, throttleTime } from 'rxjs/operators';

const SAVE_INTERVAL = 1000 * 5;
const SAVE_DEBOUNCE_TIME = 300;

export abstract class ProduktDetailFeatureComponent {
    public name: string;
    public fields: FeatureFields;

    public get produkt(): Produkt {
        return this.produktDetailResolveService.get();
    }

    constructor(
        private readonly produktConfigResolveService: ProduktConfigResolveService,
        private readonly produktDetailResolveService: ProduktDetailResolveService) {
        Assert.notNullOrUndefined(produktConfigResolveService, 'produktConfigResolveService');
        Assert.notNullOrUndefined(produktDetailResolveService, 'produktDetailResolveService');
    }

    public save(): Observable<any> {
        return of(null);
    }

    protected init(featureName: string): void {
        const { name, fields } = this.getFeatureByName(featureName);
        this.name = name;
        this.fields = fields;
    }

    protected getFeatures(): Feature[] {
        const { features } = this.produktConfigResolveService.get();
        return features;
    }

    protected getFeatureByName(featureName: string): Feature {
        const { features } = this.produktConfigResolveService.get();
        const feature = features.find(x => x.name === featureName);
        Assert.notNullOrUndefined(feature, 'feature', `The config is missing a definition for feature with name: '${featureName}'.`);
        return feature;
    }
}

@Directive()
export abstract class ProduktDetailFeatureInputComponent<TFeature, TFeatureInput> extends ProduktDetailFeatureComponent implements OnDestroy {
    private changeSubscription: Subscription;
    private dirty = false;

    public form: ViewFormGroup;

    constructor(
        produktConfigResolveService: ProduktConfigResolveService,
        produktDetailResolveService: ProduktDetailResolveService,
        private readonly produktFeatureService: ProduktFeatureService<TFeature, TFeatureInput>) {
        super(produktConfigResolveService, produktDetailResolveService);
        Assert.notNullOrUndefined(produktFeatureService, 'produktFeatureService');
    }

    public ngOnDestroy(): void {
        this.changeSubscription?.unsubscribe();
    }

    public save(force = false): Observable<TFeature> {
        if (force || this.dirty) {
            const form = this.form.getRawValue();
            return this.produktFeatureService.save(this.produkt.id, form);
        }
        return of(null);
    }

    protected init(featureName: string): void {
        super.init(featureName);
        this.form = this.createForm();
        if (this.produkt.status === ProduktStatus.Offen) {
            this.changeSubscription = this.form.valueChanges.pipe(
                tap(() => this.dirty = true),
                debounceTime(SAVE_DEBOUNCE_TIME),
                throttleTime(SAVE_INTERVAL, asyncScheduler, {
                    leading: true,
                    trailing: true
                }),
                distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
                mergeMap(() => {
                    const form = this.form.getRawValue();
                    return this.produktFeatureService.save(this.produkt.id, form);
                }, 1),
                tap(() => this.dirty = false)
            ).subscribe();
        }
    }

    protected abstract createForm(): ViewFormGroup;
}
