<mat-hint>
    <ng-content select="[appHint]"></ng-content>
</mat-hint>
<div class="header" *ngIf="header">
    <div>{{header | translate}}</div>
    <hr>
</div>
<mat-selection-list [class.multiline]="multiline" (selectionChange)="onSelectionChange($event)">
    <mat-list-option *ngFor="let option of (options$ | async);trackBy:trackByName" [selected]="option.selected"
        [value]="option.id || option.name">
        {{option.displayName | translate}}
    </mat-list-option>
</mat-selection-list>