import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { Ausstattung, AusstattungInput, Notiz } from '@data/domain/schema/type';
import { ProduktAusstattungService } from '@data/domain/service/feature';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL } from '@modules/produkt/config/produkt-ausstattung-gruppen.config';
import { PRODUKT_CONFIG_FEATURES} from '@modules/produkt/config/produkt-config';
import { ProduktDetailAusstattungFormViewFactory } from '@modules/produkt/factory/ausstattung/produkt-detail-ausstattung-form-view.factory';
import { ProduktDetailAusstattungTeilFormViewFactory } from '@modules/produkt/factory/ausstattung/produkt-detail-ausstattung-teil-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { AccordionComponent, ExpansionPanelComponent } from '@shared/component/layout/expansion';
import { SelectionListComponent } from '@shared/component/layout/list';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { BehaviorSubject, Observable } from 'rxjs';

const GRUPPE_NAME_TO_ICONS_MAP = {
  [PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL[0].name]: 'exterieur',
  [PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL[1].name]: 'interieur',
  [PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL[2].name]: 'sicherheit',
  [PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL[3].name]: 'assistenz',
  [PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL[4].name]: 'komfort',
  [PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL[5].name]: 'sonderumbauten',
  [PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL[6].name]: 'sonderzubehoer',
  [PRODUKT_AUSSTATTUNG_GRUPPEN_MANUELL[7].name]: 'erfassteAusstattung',
};

@Component({
  selector: 'app-produkt-detail-ausstattung',
  templateUrl: './produkt-detail-ausstattung.component.html',
  styleUrls: ['./produkt-detail-ausstattung.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailAusstattungComponent extends ProduktDetailFeatureInputComponent<Ausstattung, AusstattungInput> implements OnInit, AfterViewInit {

  public feature = PRODUKT_CONFIG_FEATURES.Ausstattung.name;
  public fieldIndividualaufbauten = PRODUKT_CONFIG_FEATURES.Ausstattung.fields.Individualaufbauten.name;

  public trackByField = TrackBy.trackByField;
  public trackByInstance = TrackBy.trackByInstance;

  public notizen$: Observable<Notiz[]>;
  public active$ = new BehaviorSubject<number>(-1);

  public icons = GRUPPE_NAME_TO_ICONS_MAP;

  @ViewChild('bemerkungen')
  public individualaufbautenElement: TextbausteineComponent;

  constructor(produktConfigResolveService: ProduktConfigResolveService,
              produktDetailResolveService: ProduktDetailResolveService,
              produktAusstattungService: ProduktAusstattungService,
              private readonly textbausteineService: TextbausteineService,
              private readonly formViewFactory: ProduktDetailAusstattungFormViewFactory,
              private readonly teilFormFactory: ProduktDetailAusstattungTeilFormViewFactory,
              private readonly notizenService: ProduktDetailFeatureNotizenService) {
    super(produktConfigResolveService, produktDetailResolveService, produktAusstattungService);
    Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
    Assert.notNullOrUndefined(teilFormFactory, 'teilFormFactory');
  }

  public ngOnInit(): void {
    const name = PRODUKT_CONFIG_FEATURES.Ausstattung.name;
    this.notizen$ = this.notizenService.init(this.produkt, name);
    this.init(name);
  }

  public ngAfterViewInit(): void {
    if (this.form.get(this.fieldIndividualaufbauten) && this.produkt.ausstattung.individualaufbauten === null) {
      this.textbausteineService.prefillWithStandardTextbausteine(this.feature, this.fieldIndividualaufbauten, this.produkt.art, this.form, this.individualaufbautenElement);
    }
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  protected createForm(): ViewFormGroup {
    return this.formViewFactory.create(this.produkt.ausstattung, this.fields);
  }

  public onPanelOpened(panel: ExpansionPanelComponent, active: number): void {
    Assert.notNullOrUndefined(panel, 'panel');
    if (this.active$.value !== active) {
      this.active$.next(active);
    }
  }

  public next(accordion: AccordionComponent): void {
    const active = Math.min(this.form.get('gruppen').value.length, this.active$.value + 1);
    accordion.next();
    if (this.active$.value !== active) {
      this.active$.next(active);
    }
  }

  public prev(accordion: AccordionComponent): void {
    const active = Math.max(0, this.active$.value - 1);
    accordion.next();
    if (this.active$.value !== active) {
      this.active$.next(active);
    }
  }

  public onAddClick(gruppe: ViewFormGroup, inputElement: HTMLInputElement, teileList: SelectionListComponent): void {
    if (inputElement.value.length <= 0) {
      return;
    }
    const teil = new ViewFormGroup({
      name: new ViewFormControl(inputElement.value),
      vorhanden: new ViewFormControl(true),
      externalServiceId: new ViewFormControl(null),
    });

    (<ViewFormArray>gruppe.get('teile')).push(teil);
    teileList.ngAfterViewInit();
    inputElement.value = '';
  }

  public isManualAdded(gruppe: ViewFormGroup): boolean {
    if (gruppe.value.name === 'Serienausstattung' || gruppe.value.name === 'Sonderausstattung') {
      return false;
    }
    return true;
  }
}
