<div class="footer" [class.display-none]="!(visible$ | async)"
     [ngClass]="(viewport$ | async) === viewport.Desktop ? 'desktop' : 'mobile'">
  <div class="content-wrapper" [ngClass]="isProdEnvironment() ? 'env-prod' : 'env-dev'" >
    <div class="menu-item-button dashboard menu-item" routerLink="dashboard"
         routerLinkActive="is-active" matTooltip="Dashboard">
      <app-mat-icon [newStyle]="true" class="new-style" icon="icon-layout"></app-mat-icon>
    </div>
    <div class="user-menu-wrapper">
      <div class="menu-item" [ngClass]="isActive ? 'is-active' : ''"
           routerLinkActive="is-active"
           routerLink="produkt/erstellen"
           matTooltip="Produkt erstellen">
        <span>P</span>
      </div>
      <div class="menu-item"
           routerLinkActive="is-active"
           routerLink="produkt/uebersicht/gtue-em"
           matTooltip="Produktuebersicht">
        <span>A</span>
      </div>
    </div>
    <div class="spacer">
    </div>
  </div>
</div>

