import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileDialogComponent, FileDialogSettings } from '@shared/component/popup-modal/file-dialog/file-dialog.component';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileDialogService {
    constructor(private readonly matDialog: MatDialog) {
        Assert.notNullOrUndefined(matDialog, 'matDialog');
    }

    public displayFile(title: string, fileId: string, buttons: string[] = [] ): Observable<any> {
        Assert.notNullOrUndefined(title, 'title');
        Assert.notNullOrUndefined(fileId, 'fileId');

        const settings: FileDialogSettings = {
            title,
            fileId,
            buttons
        };

        return this.matDialog.open(FileDialogComponent, {
            data: settings
        }).afterClosed();
    }
}
