import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {

  @Input()
  public count: string;

  @Input()
  public disabled: boolean;

  @Output()
  public action = new EventEmitter<MouseEvent>();

}
