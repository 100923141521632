<ng-container [formGroup]="form">
  <span class="mat-caption" *ngIf="displayName">{{displayName | translate}}</span>
    <mat-form-field>
        <textarea matInput
                  [id]="name" [formControlName]="name" [name]="name"
                  [placeholder]="displayName | translate"
            cdkTextareaAutosize></textarea>
        <mat-hint>
            <ng-content select="[appHint]"></ng-content>
        </mat-hint>
        <mat-error
            *ngIf="(control.touchedChanges | async) && !(control.validChanges | async) && (control.hasError('required') || control.hasError('pattern'))">
            {{ control.hasError('pattern')
                        ? ('control.pattern' | translate:{name: (displayName | translate)})
                        : ('control.required' | translate:{name: (displayName | translate)})
                    }}
        </mat-error>
    </mat-form-field>
</ng-container>
