import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
  styleUrls:  ['./fab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FabComponent {
  @Input()
  public count = 0;

  @Input()
  public color = 'accent';

  @Output()
  public action = new EventEmitter<MouseEvent>();
}
