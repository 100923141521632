import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { guid } from '@app/function/guid';
import { ExternalDataServiceResponseCode, WerteRequest, WerteService, WerteServiceResponse } from '@data/api-gateway';
import {
  AbrechnungProvider,
  AbrechnungService,
  BesteuerungArt,
  FahrzeugLaufleistungBeschreibung,
  FahrzeugLaufleistungEinheit,
  ProduktArt,
  WerteRoundValue
} from '@data/domain/schema/enum';
import { Abrechnung, ManuellerWert, Notiz, Werte, WerteInput } from '@data/domain/schema/type';
import { ProduktWerteService } from '@data/domain/service/feature';
import { ProduktAbrechnungService } from '@data/domain/service/feature/produkt-abrechnung.service';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import {
  ProduktDetailFeatureInputComponent
} from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import {
  Feature,
  FeatureField,
  FeatureFieldArray,
  FeatureFields,
  PRODUKT_CONFIG_FEATURES
} from '@modules/produkt/config/produkt-config';
import {
  ProduktDetailFahrzeugFormViewFactory
} from '@modules/produkt/factory/fahrzeug/produkt-detail-fahrzeug-form-view.factory';
import {
  ProduktDetailWerteFormViewFactory
} from '@modules/produkt/factory/werte/produkt-detail-werte-form-view.factory';
import {
  ProduktDetailWerteManuelleWerteFormViewFactory
} from '@modules/produkt/factory/werte/produkt-detail-werte-manuelleWerte-form-view.factory';
import {
  ProduktDetailWertePositionenFormViewFactory
} from '@modules/produkt/factory/werte/produkt-detail-werte-positionen-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { DatSearchService } from '@modules/produkt/service/dat-search.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { ProduktDetailWerteTotalService } from '@modules/produkt/service/produkt-detail-werte-total.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues, ObjectValues, Values } from '@shared/helper/values';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable, PartialObserver, Subscription } from 'rxjs';
import { filter, first, map, skip } from 'rxjs/operators';

export enum ExternalServiceOperation {
  Post = 'Post',
  Put = 'Put',
}

interface ProduktDetailManuelleWerteDialogData {
  form: ViewFormGroup;
  fields: FeatureFields;
}


@Component({
  selector: 'app-produkt-detail-werte',
  templateUrl: './produkt-detail-werte.component.html',
  styleUrls: ['./produkt-detail-werte.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailWerteComponent extends ProduktDetailFeatureInputComponent<Werte, WerteInput> implements OnInit, AfterViewInit, OnDestroy {

  public feature = PRODUKT_CONFIG_FEATURES.Werte.name;
  public fieldWertAmMarktBemerkungen = PRODUKT_CONFIG_FEATURES.Werte.fields.WertAmMarktBemerkungen.name;

  public produktArt = ProduktArt;

  public trackByField = TrackBy.trackByField;

  // public loading$ = new BehaviorSubject(false);
  public datRequestActive$: BehaviorSubject<boolean>;

  public notizen$: Observable<Notiz[]>;
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;

  public positionen$ = new BehaviorSubject<ViewFormGroup[][]>([]);
  public differenzAbzuegeWerterhoehung$: BehaviorSubject<number>;
  public aufwendungenReadonly$ = new BehaviorSubject<boolean>(false);
  public aufwendungenDetailsDisabled = false;
  public hasExternalServiceIdentifier = false;

  public fahrzeug: Feature;
  public fahrzeugForm: ViewFormGroup;
  public fahrzeugFields: FeatureFields;

  public fahrzeugLaufleistungEinheit = new EnumValues(FahrzeugLaufleistungEinheit);
  public fahrzeugLaufleistungBeschreibung = new ObjectValues(FahrzeugLaufleistungBeschreibung);

  public manuelleWerteName = PRODUKT_CONFIG_FEATURES.Werte.fields.ManuelleWerte.name;
  public manuelleWerte: ViewFormArray;
  public manuelleWerteFields: FeatureFields;
  public manuelleWerteDrucken$: Observable<boolean>;
  public manuelleWerteDruckenSubject$: BehaviorSubject<boolean>;

  public isZustandsbericht: boolean;

  public drucken: Values = {
    keys: [true, false],
    values: {
      false: 'nein',
      true: 'ja',
    }
  };

  public besteuerungArt = new EnumValues(BesteuerungArt);
  public enumRoundValue = WerteRoundValue;
  public werteRoundValue = new EnumValues(WerteRoundValue);

  private roundValue$ = new BehaviorSubject<WerteRoundValue>(WerteRoundValue.Netto);
  private rundungsFaktor = 10;
  private mwstRegelbesteuerungSatz = 19;
  private mwstDifferenzbesteuerungSatz = 2.4;

  private subscriptions: Subscription[] = [];

  @ViewChild('wertAmMarktBemerkungen')
  public wertAmMarktBemerkungenElement: TextbausteineComponent;

  @ViewChild('dialog', { static: true })
  public dialogTemplate: TemplateRef<any>;

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktWerteService: ProduktWerteService,
    private readonly datSearchService: DatSearchService,
    private readonly werteService: WerteService,
    private readonly currencyFormatter: CurrencyFormatterService,
    private readonly formViewFactory: ProduktDetailWerteFormViewFactory,
    private readonly fahrzeugFormViewFactory: ProduktDetailFahrzeugFormViewFactory,
    private readonly positionFormViewFactory: ProduktDetailWertePositionenFormViewFactory,
    private readonly snackBarService: SnackBarService,
    private readonly viewportService: ViewportService,
    private readonly notizenService: ProduktDetailFeatureNotizenService,
    private readonly produktAbrechnungService: ProduktAbrechnungService,
    private readonly textbausteineService: TextbausteineService,
    private readonly updateWerteService: UpdateWerteService,
    private readonly templateDialogService: TemplateDialogService,
    private readonly produktDetailWerteManuelleWerteFormViewFactory: ProduktDetailWerteManuelleWerteFormViewFactory,
    private readonly produktDetailWerteTotalService: ProduktDetailWerteTotalService) {
    super(produktConfigResolveService, produktDetailResolveService, produktWerteService);
    Assert.notNullOrUndefined(datSearchService, 'datSearchService');
    Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
    Assert.notNullOrUndefined(positionFormViewFactory, 'positionFormViewFactory');
    Assert.notNullOrUndefined(werteService, 'werteService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    Assert.notNullOrUndefined(viewportService, 'viewportService');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
    Assert.notNullOrUndefined(produktAbrechnungService, 'produktAbrechnungService');
    Assert.notNullOrUndefined(updateWerteService, 'updateWerteService');
    Assert.notNullOrUndefined(produktDetailWerteTotalService, 'produktDetailWerteTotalService');
  }

  public ngOnInit(): void {
    const name = PRODUKT_CONFIG_FEATURES.Werte.name;
    this.notizen$ = this.notizenService.init(this.produkt, name);
    this.viewport$ = this.viewportService.observe();
    this.hasExternalServiceIdentifier = this.hasExternalServiceIdentified();
    this.isZustandsbericht = this.produkt.art === ProduktArt.Zustandsbericht ? true : false;
    this.roundValue$.next(this.produkt.werte.roundValue);
    this.init(name);
    if (this.produkt.art !== this.produktArt.VtiTooling) {
    this.manuelleWerteDruckenSubject$ = new BehaviorSubject<boolean>(this.form.get('manuelleWerteDrucken').value);
    this.manuelleWerteDrucken$ = this.manuelleWerteDruckenSubject$.asObservable();
    this.differenzAbzuegeWerterhoehung$ = new BehaviorSubject<number>(0);
    this.initSubscriptions();
    this.setZustandsberichtAufwendungenDruckenStandardAufFalse();
    this.onClickedCurrencyPrintButton();
    this.datRequestActive$ = this.datSearchService.getDatRequestActive();
    }
  }

  public ngAfterViewInit(): void {
    if (this.form.get(this.fieldWertAmMarktBemerkungen) && this.produkt.werte.wertAmMarktBemerkungen === null) {
      this.textbausteineService.prefillWithStandardTextbausteine(this.feature, this.fieldWertAmMarktBemerkungen, this.produkt.art, <ViewFormGroup>this.form, this.wertAmMarktBemerkungenElement);
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  public onGetWerteClick(event: MouseEvent): void {
    Assert.notNullOrUndefined(event, 'event');
    event.stopPropagation();
    this.getWerte();
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  public onRowOpenByIndex(index: number, item: any, panel: MatExpansionPanel, $event: MouseEvent): void {
    Assert.notNullOrUndefined(index, 'index');
    panel.close();
    $event.stopPropagation();
    this.editPosition(index, item);
  }

  public onRowRemoveByIndex(index: number, panel: MatExpansionPanel, $event: MouseEvent): void {
    Assert.notNullOrUndefined(index, 'index');
    panel.close();
    $event.stopPropagation();
    this.manuelleWerte.removeAt(index);
  }

  public onAddManuelleWerteClick(): void {
    this.createManuellenWert();
  }

  public onManuelleWerteDruckenChange(event: MatSlideToggleChange): void {
    this.manuelleWerteDruckenSubject$.next(event.checked);
  }

  public onAufwendungenDetailsDrucken(event: MatSlideToggleChange): void {
    this.manuelleWerteDruckenSubject$.next(event.checked);
  }

  public onClickedCurrencyPrintButton(): void {
    if (this.form.get('aufwendungenDrucken').value === false) {
      this.form.setControlValue('aufwendungenDetailsDrucken', false);
      this.aufwendungenDetailsDisabled = true;
    } else {
      this.aufwendungenDetailsDisabled = false;
    }
  }

  public isNumber(value: string): boolean {
    const regExp = new RegExp(/^[+-]?(\d*\.)?\d+$/);
    return regExp.test(value);
  }

  protected createForm(): ViewFormGroup {
    const form = this.formViewFactory.create(this.produkt.werte, this.fields);
    if (this.produkt.art !== this.produktArt.VtiTooling) {
      this.manuelleWerte = <ViewFormArray>form.get(this.manuelleWerteName);
      this.manuelleWerteFields = (<FeatureFieldArray>this.fields
        .find((x: FeatureFieldArray) => x.arrayName === this.manuelleWerteName))
        .fields;
      this.subscriptions.push(
        form.valueChanges.subscribe(() => this.updatePositionen(form.getRawValue()))
      );
      this.updatePositionen(form.getRawValue());
      this.createFahrzeug();
    }

    return form;
  }

  private createFahrzeug(): void {
    this.fahrzeug = this.getFeatureByName(PRODUKT_CONFIG_FEATURES.Fahrzeug.name);
    this.fahrzeugFields = this.fahrzeug.fields;
    this.fahrzeugForm = this.fahrzeugFormViewFactory.create(this.produkt.fahrzeug, this.fahrzeugFields);
  }

  private editPosition(index: number, manuellerWert: ManuellerWert): void {
    this.openPosition(manuellerWert).pipe(first()).subscribe(update => {
      if (this.isNumber(update.get('wert').value)) {
        update.patchValue({wert: this.currencyFormatter.parse(update.get('wert').value)});
      }
      (<ViewFormArray>this.form.get('manuelleWerte')).controls[index] = update;
      this.form.get('manuelleWerte').updateValueAndValidity();
    });
  }

  private openPosition(manuellerWert: ManuellerWert): Observable<ViewFormGroup> {
    const title = 'Manuellen Wert bearbeiten';
    const fields = this.manuelleWerteFields;
    const isNumber = this.isNumber(manuellerWert.wert);
    const form = new ViewFormGroup({
      bezeichnung: new ViewFormControl(manuellerWert.bezeichnung),
      wert: new ViewFormControl(isNumber ? this.currencyFormatter.format(+manuellerWert.wert) : manuellerWert.wert)
    });
    const buttons = [`${this.name}.cancel`, `${this.name}.save`];
    const data: ProduktDetailManuelleWerteDialogData = { form, fields };
    return this.templateDialogService.openTemplate(title, buttons, this.dialogTemplate, data).pipe(
      filter(result => result && result.name === buttons[1]),
      map(result => result.data.form)
    );
  }

  private initSubscriptions(): void {
    this.subscriptions.push(this.onRoundValueChanged());
    this.subscriptions.push(this.onAufwendungenChange());
    this.subscriptions.push(this.onAufwendungenFormChange());
    this.subscriptions.push(this.onPositionenChange());
    this.subscriptions.push(this.onHEKChange());
    this.subscriptions.push(this.onHEKNettoChange());
    this.subscriptions.push(this.onHVKChange());
    this.subscriptions.push(this.onHVKNettoChange());
    this.subscriptions.push(this.onHVKNettoBesteuerungsartChange());
    this.subscriptions.push(this.onRealtiverWertChange());
  }

  private onRoundValueChanged(): Subscription {
    return this.form.get('roundValue').valueChanges.subscribe(value => {
      this.roundValue$.next(value);
    });
  }

  private onHEKChange(): Subscription {
    return this.form.get('haendlereinkaufswert').valueChanges.subscribe(value => {
      if (this.differenzAbzuegeWerterhoehung$.getValue() === 0) {
        this.substractHEKAbzuege(this.currencyFormatter.parse(value), this.currencyFormatter.parse(this.form.get('aufwendungen').value));
      } else {
        this.substractHEKAbzuege(this.currencyFormatter.parse(value), this.differenzAbzuegeWerterhoehung$.getValue());
      }

      if (this.roundValue$.value === WerteRoundValue.Brutto) {
        const hekBruttoGerundet = this.round(value, this.rundungsFaktor);
        const hekNettoBerechnet = hekBruttoGerundet / (1 + (this.mwstRegelbesteuerungSatz) / 100);
        this.form.patchValue({
          haendlereinkaufswertNetto:
            this.currencyFormatter.format(Math.max(0, hekNettoBerechnet))
        });
      }
    });
  }

  private onHEKNettoChange(): Subscription {
    return this.form.get('haendlereinkaufswertNetto').valueChanges.subscribe(value => {
      if (this.differenzAbzuegeWerterhoehung$.getValue() === 0) {
        this.substractHEKAbzuegeNetto(this.currencyFormatter.parse(value), this.currencyFormatter.parse(this.form.get('aufwendungen').value));
      } else {
        this.substractHEKAbzuegeNetto(this.currencyFormatter.parse(value), this.differenzAbzuegeWerterhoehung$.getValue());
      }
      if (this.roundValue$.value === WerteRoundValue.Netto) {
        const hekNettoGerundet = this.round(value, this.rundungsFaktor);
        const hekBruttoBerechnet = hekNettoGerundet * (1 + (this.mwstRegelbesteuerungSatz) / 100);
        this.form.patchValue({
          haendlereinkaufswert:
            this.currencyFormatter.format(Math.max(0, hekBruttoBerechnet))
        });
      }
    });
  }

  private onHVKChange(): Subscription {
    return this.form.get('haendlerverkaufswert').valueChanges.subscribe(value => {
      if (this.differenzAbzuegeWerterhoehung$.getValue() === 0) {
        this.substractHVKAbzuege(this.currencyFormatter.parse(value), this.form.get('haendlerverkaufswertBesteuerung').value, this.currencyFormatter.parse(this.form.get('aufwendungen').value));
      } else {
        this.substractHVKAbzuege(this.currencyFormatter.parse(value), this.form.get('haendlerverkaufswertBesteuerung').value, this.differenzAbzuegeWerterhoehung$.getValue());
      }

      if (this.roundValue$.value === WerteRoundValue.Brutto) {
        const hvkBruttoGerundet = this.round(value, this.rundungsFaktor);
        const hvkNettoBerechnet = hvkBruttoGerundet / (1 + (this.getMwstSatz(this.form.get('haendlerverkaufswertBesteuerung').value)) / 100);
        this.form.patchValue({
          haendlerverkaufswertNetto:
            this.currencyFormatter.format(Math.max(0, hvkNettoBerechnet))
        });
      }
    });
  }

  private onHVKNettoChange(): Subscription {
    return this.form.get('haendlerverkaufswertNetto').valueChanges.subscribe(value => {
      if (this.differenzAbzuegeWerterhoehung$.getValue() === 0) {
        this.substractHVKAbzuegeNetto(this.currencyFormatter.parse(value), this.currencyFormatter.parse(this.form.get('aufwendungen').value));
      } else {
        this.substractHVKAbzuegeNetto(this.currencyFormatter.parse(value), this.differenzAbzuegeWerterhoehung$.getValue());
      }
      if (this.roundValue$.value === WerteRoundValue.Netto) {
        const hvkNettoGerundet = this.round(value, this.rundungsFaktor);
        const hvkBruttoBerechnet = hvkNettoGerundet * (1 + (this.getMwstSatz(this.form.get('haendlerverkaufswertBesteuerung').value)) / 100);
        this.form.patchValue({
          haendlerverkaufswert:
            this.currencyFormatter.format(Math.max(0, hvkBruttoBerechnet))
        });
      }
    });
  }

  private onPositionenChange(): Subscription {
    return this.positionen$.subscribe(
      positionen => {
        const totalWerterhoehendesZubehoer = this.produktDetailWerteTotalService.calculate(positionen[1], false);
        const totalAbzuege = this.produktDetailWerteTotalService.calculate(positionen[2], false);
        const differenzAbzuegeWerterhoehung = totalAbzuege - totalWerterhoehendesZubehoer;

        const isAutomaticCalculatedBefore = this.updateWerteService.isAutomaticCalculated;
        const isAutomaticCalculated = totalWerterhoehendesZubehoer > 0 || totalAbzuege > 0;
        const isAutomaticToManual = isAutomaticCalculatedBefore && isAutomaticCalculated === false;
        const differenzAbzuegeWerterhoehungChanged = this.differenzAbzuegeWerterhoehung$.getValue() !== differenzAbzuegeWerterhoehung;

        if (!isAutomaticCalculated && isAutomaticToManual) {
          this.updateWerteService.isAutomaticCalculated = false;
          this.differenzAbzuegeWerterhoehung$.next(differenzAbzuegeWerterhoehung);
          this.form.patchValue({ aufwendungen: this.currencyFormatter.format(Math.max(0, differenzAbzuegeWerterhoehung)) });
          return;
        }

        if (isAutomaticCalculated) {
          this.aufwendungenReadonly$.next(true);
          this.updateWerteService.isAutomaticCalculated = true;
        } else {
          this.aufwendungenReadonly$.next(false);
          this.updateWerteService.isAutomaticCalculated = false;
        }

        if (differenzAbzuegeWerterhoehungChanged) {
          this.updateWerteService.isAutomaticCalculated = true;
          this.differenzAbzuegeWerterhoehung$.next(differenzAbzuegeWerterhoehung);
          this.form.patchValue({ aufwendungen: this.currencyFormatter.format(Math.max(0, differenzAbzuegeWerterhoehung)) });
        }
      }
    );
  }

  private onAufwendungenChange(): Subscription {
    return this.differenzAbzuegeWerterhoehung$.pipe(skip(1)).subscribe(aufwendungen => {
      this.form.patchValue({ aufwendungen: this.currencyFormatter.format(aufwendungen) });
      this.substractAufwendungen(aufwendungen);
    });
  }

  private onAufwendungenFormChange(): Subscription {
    return this.form.get('aufwendungen').valueChanges.subscribe(aufwendungen => {
      if (this.differenzAbzuegeWerterhoehung$.getValue() === 0) {
        this.substractAufwendungen(this.currencyFormatter.parse(aufwendungen));
      } else {
        this.substractAufwendungen(this.differenzAbzuegeWerterhoehung$.getValue());
      }
    });
  }

  private onRealtiverWertChange(): Subscription {
    return this.form.get('relativerWert').valueChanges.subscribe(value => {
      (this.produkt.art === ProduktArt.Ruecknahmebewertung) && (value > 0) ? this.form.setControlValue('relativerWertDrucken', true) : this.form.setControlValue('relativerWertDrucken', false);
    });
  }

  private substractAufwendungen(aufwendungen: number): void {
    this.substractHEKAbzuege(this.currencyFormatter.parse(this.form.get('haendlereinkaufswertNetto').value), aufwendungen);
    this.substractHEKAbzuegeNetto(this.currencyFormatter.parse(this.form.get('haendlereinkaufswertNetto').value), aufwendungen);
    this.substractHVKAbzuege(this.currencyFormatter.parse(this.form.get('haendlerverkaufswertNetto').value), this.form.get('haendlerverkaufswertBesteuerung').value, aufwendungen);
    this.substractHVKAbzuegeNetto(this.currencyFormatter.parse(this.form.get('haendlerverkaufswertNetto').value), aufwendungen);
  }

  private substractHEKAbzuege(value: number, aufwendungen = 0): void {
    let result = 0;
    if (this.roundValue$.value === WerteRoundValue.Brutto) {
      const hekBrutto = this.currencyFormatter.parse(this.form.get('haendlereinkaufswert').value);
      const hekBruttoGerundet = Math.round(hekBrutto / this.rundungsFaktor) * this.rundungsFaktor;
      const hekNetto = hekBruttoGerundet / (1 + this.mwstRegelbesteuerungSatz / 100);
      const hekNettoAbzuegeBerechnet = hekNetto - aufwendungen;
      const hekBruttoAbzuegeBerechnet = hekNettoAbzuegeBerechnet * (1 + this.mwstRegelbesteuerungSatz / 100);
      result = hekBruttoAbzuegeBerechnet;
    } else {
      const hekAbzuegeNettoGerundet = Math.round((value - aufwendungen) / this.rundungsFaktor) * this.rundungsFaktor;
      const hekAbzuegeBruttoGerundet = hekAbzuegeNettoGerundet * (1 + this.mwstRegelbesteuerungSatz / 100);
      result = hekAbzuegeBruttoGerundet;
    }

    this.form.patchValue({
      haendlereinkaufswertAbzuege:
        this.currencyFormatter.format(Math.max(0, result))
    });
  }

  private substractHEKAbzuegeNetto(value: number, aufwendungen = 0): void {
    let result = 0;
    if (this.roundValue$.value === WerteRoundValue.Brutto) {
      const hekBrutto = this.currencyFormatter.parse(this.form.get('haendlereinkaufswert').value);
      const hekBruttoGerundet = Math.round(hekBrutto / this.rundungsFaktor) * this.rundungsFaktor;
      const hekNetto = hekBruttoGerundet / (1 + this.mwstRegelbesteuerungSatz / 100);
      result = hekNetto;
    } else {
      const hekAbzuegeNettoGerundet = Math.round((value - aufwendungen) / this.rundungsFaktor) * this.rundungsFaktor;
      result = hekAbzuegeNettoGerundet;
    }

    this.form.patchValue({
      haendlereinkaufswertAbzuegeNetto:
        this.currencyFormatter.format(Math.max(0, result))
    });
  }

  private substractHVKAbzuege(value: number, besteuerungArt: BesteuerungArt, aufwendungen = 0): void {
    let result = 0;
    if (this.roundValue$.value === WerteRoundValue.Brutto) {
      const hvkBrutto = this.currencyFormatter.parse(this.form.get('haendlerverkaufswert').value);
      const hvkBruttoGerundet = Math.round(hvkBrutto / this.rundungsFaktor) * this.rundungsFaktor;
      const aufwendungenBrutto = aufwendungen * (1 + this.mwstRegelbesteuerungSatz / 100);
      const hvkAbzuegeBrutto = hvkBruttoGerundet - aufwendungenBrutto;
      result = hvkAbzuegeBrutto;
    } else {
      const hvkNettoGerundet = Math.round((value) / this.rundungsFaktor) * this.rundungsFaktor;
      const hvkBrutto = hvkNettoGerundet * (1 + this.getMwstSatz(besteuerungArt) / 100);
      const aufwendungenBrutto = aufwendungen * (1 + this.mwstRegelbesteuerungSatz / 100);
      const hvkAbzuegeBrutto = hvkBrutto - aufwendungenBrutto;
      result = hvkAbzuegeBrutto;
    }

    this.form.patchValue({
      haendlerverkaufswertAbzuege:
        this.currencyFormatter.format(Math.max(0, result))
    });
  }

  private substractHVKAbzuegeNetto(value: number, aufwendungen = 0): void {
    let result = 0;
    if (this.roundValue$.value === WerteRoundValue.Brutto) {
      const hvkBrutto = this.currencyFormatter.parse(this.form.get('haendlerverkaufswert').value);
      const hvkBruttoGerundet = Math.round(hvkBrutto / this.rundungsFaktor) * this.rundungsFaktor;
      const hvkNetto = hvkBruttoGerundet / (1 + this.mwstRegelbesteuerungSatz / 100);
      const hvkAbzuegeNettoBerechnet = hvkNetto - aufwendungen;
      result = hvkAbzuegeNettoBerechnet;
    } else {
      const hvkAbzuegeNettoGerundet = Math.round((value - aufwendungen) / this.rundungsFaktor) * this.rundungsFaktor;
      result = hvkAbzuegeNettoGerundet;
    }
   this.form.patchValue({
      haendlerverkaufswertAbzuegeNetto:
        this.currencyFormatter.format(Math.max(0, result))
    });
  }

  private getWerte(): void {
    this.datRequestActive$.next(true);
    const request = this.createWerteRequest();
    if (!request.referenceId) {
      const observer = this.createObserver(ExternalServiceOperation.Post);
      this.werteService.post(request).subscribe(observer);
    } else {
      const observer = this.createObserver(ExternalServiceOperation.Put);
      this.werteService.put(request).subscribe(observer);
    }
  }

  private createObserver(operation: string): PartialObserver<WerteServiceResponse> {
    const observer: PartialObserver<WerteServiceResponse> = {
      next: response => {
        this.handleResponse(response);
        this.handleAbrechung(response, operation);
      },
      error: () => this.snackBarService.error(`werte.externalDataServiceResponseCode.error${operation}`),
      complete: () => {
        this.datRequestActive$.next(false);
      }
    };
    return observer;
  }

  private handleResponse(response: WerteServiceResponse): void {
    if (!response) {
      this.snackBarService.error('werte.externalDataServiceResponseCode.error');
      return;
    }
    switch (response.responseCode) {
      case ExternalDataServiceResponseCode.Error:
        this.snackBarService.error('werte.externalDataServiceResponseCode.error');
        break;
      case ExternalDataServiceResponseCode.ErrorCreateDossier:
        this.snackBarService.error('werte.externalDataServiceResponseCode.errorCreateDossier');
        break;
      case ExternalDataServiceResponseCode.ErrorNoValidConstructionTime:
        this.snackBarService.error('werte.externalDataServiceResponseCode.errorNoValidConstructionTime');
        break;
      default:
        this.snackBarService.success('werte.externalDataServiceResponseCode.success');
        this.updateWerte(response);
        break;
    }
  }

  private isHvkNettoDatValue(): boolean {
    const hvkNetto = this.currencyFormatter.parse(this.form.get('haendlerverkaufswertNetto').value);
    const datHvkNettoDifferenz = this.form.get('datHvkNettoDifferenz').value;
    const datHvkNettoRegel = this.form.get('datHvkNettoRegel').value;

    if (hvkNetto === datHvkNettoRegel || hvkNetto === datHvkNettoDifferenz) {
      return true;
    }
    return false;
  }

  private onHVKNettoBesteuerungsartChange(): Subscription {
    return this.form.get('haendlerverkaufswertBesteuerung').valueChanges.subscribe(besteuerung => {
      switch (besteuerung) {
        case BesteuerungArt.Differenzbesteuert: {
          const isDatValue = this.isHvkNettoDatValue();
          if (isDatValue) {
            this.form.setControlValue('haendlerverkaufswertNetto', this.form.get('datHvkNettoDifferenz').value);
          }
          break;
        }
        case BesteuerungArt.Regelbesteuert: {
          const isDatValue = this.isHvkNettoDatValue();
          if (isDatValue) {
            this.form.setControlValue('haendlerverkaufswertNetto', this.form.get('datHvkNettoRegel').value);
          }
          break;
        }
      }
    });
  }

  private updateWerte(response: WerteServiceResponse): void {
    const fields = PRODUKT_CONFIG_FEATURES.Werte.fields;
    this.fields.forEach((field: FeatureField) => {
      const name = field.name;
      switch (name) {
        case fields.Bezugsdatum.name:
          this.form.setControlValue(name, new Date());
          break;
        case fields.Haendlereinkaufswert.name:
          this.form.setControlValue(name, response.werte.haendlereinkaufswert);
          break;
        case fields.HaendlereinkaufswertNetto.name:
          this.form.setControlValue(name, response.werte.haendlereinkaufswertNetto);
          break;
        case fields.Haendlerverkaufswert.name:
          this.form.setControlValue(name, response.werte.haendlerverkaufswert);
          break;
        case fields.HaendlerverkaufswertNetto.name:
          this.form.setControlValue(name, response.werte.haendlerverkaufswertNetto);
          this.form.setControlValue('haendlerverkaufswertBesteuerung', BesteuerungArt.Differenzbesteuert);
          this.form.setControlValue('datHvkNettoDifferenz', response.werte.haendlerverkaufswertNetto);
          this.form.setControlValue('datHvkNettoRegel', response.werte.haendlerverkaufswertRegelbesteuertNetto);
          break;
        case fields.Neuwert.name:
          this.form.setControlValue(name, response.werte.neuwert);
          break;
        case fields.RelativerWert.name:
          this.form.setControlValue(name, response.werte.relativerWert);
          break;
        case fields.RelativerWertDrucken.name:
          const istRuecknahmebewertungUndRelativerWertPositiv = (this.produkt.art === ProduktArt.Ruecknahmebewertung) && (response.werte.relativerWert > 0);
          this.form.setControlValue(name, istRuecknahmebewertungUndRelativerWertPositiv);
          break;
        case fields.ExternalServicesReferenceId.name:
          this.form.setControlValue(name, response.werte.externalServicesReferenceId);
          break;
      }
    });
  }

  private createWerteRequest(): WerteRequest {
    return {
      erstzulassung: this.produkt.fahrzeug.erstzulassung,
      laufleistung: this.produkt.fahrzeug.laufleistung,
      identifier: this.produkt.fahrzeug.fahrzeugExternalServiceReference.identifier,
      constructionTime: this.produkt.fahrzeug.fahrzeugExternalServiceReference.constructionTime,
      container: this.produkt.fahrzeug.fahrzeugExternalServiceReference.container,
      referenceId: this.produkt.werte.externalServicesReferenceId,
      specialEquipmentIds: this.getSpecialEquipmentIds(),
    };
  }

  private getSpecialEquipmentIds(): string[] {
    const specialEquipmentIds: string[] = [];
    this.produkt.ausstattung.gruppen.forEach(gruppe => {
      if (gruppe.sonderausstattung) {
        (gruppe.teile || []).forEach(teil => {
          if (teil.vorhanden) {
            specialEquipmentIds.push(teil.externalServiceId);
          }
        });
      }
    });
    return specialEquipmentIds;
  }

  private updatePositionen(werte: Werte): void {
    const positionen = this.positionFormViewFactory.create(
      werte, this.produkt.vorschaden, this.produkt.schaden, this.produkt.art,
      this.produkt.fehlteile, this.produkt.wartung, this.roundValue$.value);
    this.positionen$.next(positionen);
  }

  private hasExternalServiceIdentified(): boolean {
    const fahrzeugExternalServiceReference = this.produkt.fahrzeug?.fahrzeugExternalServiceReference;
    if (!fahrzeugExternalServiceReference) {
      return false;
    }
    const { constructionTime, container, identifier } = fahrzeugExternalServiceReference;
    return !!constructionTime && !!container && !!identifier;
  }

  private handleAbrechung(response: WerteServiceResponse, operation: string): void {
    if (operation === ExternalServiceOperation.Post && response.responseCode === ExternalDataServiceResponseCode.Success) {
      const abrechnung = this.CreateAbrechnung(response.werte.externalServicesReferenceId);
      this.produktAbrechnungService.saveAbrechnung(this.produkt.art, this.produkt.id, guid(), abrechnung);
    }
  }

  private CreateAbrechnung(externalServicesReferenceId: string): Abrechnung {
    return {
      id: guid(),
      datum: moment.utc().toISOString(),
      externalProvider: AbrechnungProvider.Dat,
      externalService: AbrechnungService.Werte,
      identifier: externalServicesReferenceId,
    };
  }

  private drop(event: CdkDragDrop<string[]>): void {
    const item = this.manuelleWerte.at(event.previousIndex);
    this.manuelleWerte.controls.splice(event.previousIndex, 1);
    this.manuelleWerte.controls.splice(event.currentIndex, 0, item);
    this.manuelleWerte.updateValueAndValidity();
  }

  private createManuellenWert(): void {
    const title = 'Manuellen Wert hinzufügen';
    const fields = this.manuelleWerteFields;
    const form = new ViewFormGroup({
      bezeichnung: new ViewFormControl(''),
      wert: new ViewFormControl('')
    });

    const buttons = [`${this.name}.cancel`, `${this.name}.save`];
    const data: ProduktDetailManuelleWerteDialogData = { form, fields };

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogTemplate, data).subscribe(result => {
        if (result && result.name === buttons[1]) {
          result.data.form.patchValue({wert: this.currencyFormatter.parse(result.data.form.get('wert').value)});
          this.manuelleWerte.push(result.data.form);
        }
      });
  }

  private setZustandsberichtAufwendungenDruckenStandardAufFalse() {
    if (this.produkt.art === ProduktArt.Zustandsbericht) {
      const werte = this.produkt.werte;
      (werte.aufwendungenDrucken === undefined || werte.aufwendungenDrucken === null)
        ? this.form.setControlValue('aufwendungenDrucken', false) :
        this.form.setControlValue('aufwendungenDrucken', werte.aufwendungenDrucken);
    }
  }

  private getMwstSatz(besteuerungsart: BesteuerungArt) {
    if (besteuerungsart === BesteuerungArt.Regelbesteuert) {
      return this.mwstRegelbesteuerungSatz;
    }
    return this.mwstDifferenzbesteuerungSatz;
  }

  private round(value: any, rundungsFaktor: number): number {
    return Math.round((this.currencyFormatter.parse(value) || 0) / rundungsFaktor) * rundungsFaktor;
  }
}
