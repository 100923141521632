import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BilderDownloadService {
    private readonly restApiUrl: string;

    constructor(
        private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/datei/upload/zip`;
    }

    public get(produktId: string): Observable<string> {
        Assert.notNullOrUndefined(produktId, 'produktId');
        const params = {
            params: {
                produktId
            }
        };

        return this.httpClient.get<string>(this.restApiUrl, params);
    }
}
