import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-expansion-panel-single',
  templateUrl: './expansion-panel-single.component.html',
  styleUrls: ['./expansion-panel-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelSingleComponent {

  @ViewChild('matExpansionPanel') _matExpansionPanel: any;

  @Input()
  public set expanded(expanded: boolean) {
    this.expanded$.next(expanded);
  }

  public expanded$ = new BehaviorSubject<boolean>(undefined);

  @Input()
  public dynamicHeight = true;

  @Output()
  public opened = new EventEmitter<void>();

  public onClick($event: MouseEvent): void {
    $event.stopPropagation();
  }

  togglePanel($event: MouseEvent) {
    $event.stopPropagation();
    if (this.expanded$.value) {
      this.expanded$.next(false);
    } else {
      this.expanded$.next(true);
    }
  }
}
