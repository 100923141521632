import { Injectable } from '@angular/core';
import { FileDatabase } from '@app/class/file-database';
import { FileDatabaseFactory } from '@app/factory/file-database.factory';
import { Assert } from '@shared/helper/assert';

@Injectable({
    providedIn: 'root'
})
export class FileDatabaseProvider {
    private client: FileDatabase;

    constructor(private readonly databaseFactory: FileDatabaseFactory) {
        Assert.notNullOrUndefined(databaseFactory, 'databaseFactory');
    }

    public provide(): FileDatabase {
        return this.client || (this.client = this.createClient());
    }

    private createClient(): FileDatabase {
        return this.databaseFactory.create();
    }
}
