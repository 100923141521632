import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ProduktArt } from '@data/domain/schema/enum';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { TemplateDialogService } from '@shared/service/template-dialog.service';

@Component({
  selector: 'app-produkt-detail-feststellungen-umfang',
  templateUrl: './produkt-detail-feststellungen-umfang.component.html',
  styleUrls: ['./produkt-detail-feststellungen-umfang.component.scss']
})
export class ProduktDetailFeststellungenUmfangComponent {

  @Input()
  public umfaenge: ViewFormArray;

  @Output()
  public addClicked = new EventEmitter<string>();

  @Output()
  public deleteClicked = new EventEmitter<number>();

  @ViewChild('dialogDelete', {static: true})
  public dialogDeleteTemplate: TemplateRef<any>;

  public produktArt = ProduktArt;

  constructor( private readonly templateDialogService: TemplateDialogService) { }

  public onDelete(index: number): void {
    const buttons = [`feature.cancel`, `feature.delete`];

    this.templateDialogService.openTemplate('Umfang löschen', buttons,
      this.dialogDeleteTemplate).subscribe(result => {
      if (result.name && result.name === `feature.delete`) {
        this.deleteClicked.emit(index);
      }
    });
  }
}
