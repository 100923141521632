import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CombiPlusOnlineDto, CombiPlusOnlineStatus } from '@data/api-gateway/service/bvws-combi-plus-online.service';
import { ProduktUebersichtCpoResolveService } from '@modules/produkt/service/produkt-uebersicht-cpo-resolve.service';
import { Assert } from '@shared/helper/assert';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';

@Component({
  selector: 'app-produkt-uebersicht-gtue-modul',
  templateUrl: './produkt-uebersicht-gtue-modul.component.html',
  styleUrls: ['./produkt-uebersicht-gtue-modul.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktUebersichtGtueModulComponent implements OnInit {

  public name = 'produktUebersicht';
  public cpoStatus = new EnumValues(CombiPlusOnlineStatus);
  public cpoVorgaenge: CombiPlusOnlineDto[];

  constructor(
    private readonly produktUebersichtCpoResolveService: ProduktUebersichtCpoResolveService,
    private readonly snackBarService: SnackBarService) {
  }

  public ngOnInit(): void {
    this.cpoVorgaenge = this.produktUebersichtCpoResolveService.get();
  }

  public onAction(vorgang: CombiPlusOnlineDto): void {
    Assert.notNullOrUndefined(vorgang, 'vorgang');
    if (!vorgang.url) {
      this.snackBarService.error('Datenfehler: Url zu CombliPlus nicht definiert');
    }
    window.open(vorgang.url);
  }

  public filterPredicate(vorgang: CombiPlusOnlineDto, filter: string): boolean {
    if (vorgang.vorgang) {
      if (vorgang.vorgang?.toLocaleLowerCase().includes(filter)) {
        return true;
      }
    }

    if (vorgang.kennzeichen) {
      if (vorgang.kennzeichen?.toLocaleLowerCase().includes(filter)) {
        return true;
      }
    }

    return false;
  }

}
