import { Injectable } from '@angular/core';
import { SchadenPosition } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { guid } from '@app/function/guid';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailSchadenPositionFormViewFactory extends FormViewModelBaseFactory<SchadenPosition> {

    constructor(private readonly currencyFormatter: CurrencyFormatterService) {
        super();
        Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    }

    protected createField(model: SchadenPosition, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Schaden.fields.Positionen.fields;
        switch (name) {
            case fields.Obergruppe.name:
                return new ViewFormControl(model.obergruppe);
            case fields.Untergruppe.name:
                return new ViewFormControl(model.untergruppe, {
                    updateOn: 'change'
                });
            case fields.Beschaedigungart.name:
                return new ViewFormControl(model.beschaedigungart, {
                    updateOn: 'change'
                });
            case fields.Reparaturweg.name:
                return new ViewFormControl(model.reparaturweg, {
                    updateOn: 'change'
                });
            case fields.Bilder.name:
                return new ViewFormControl(model.bilder || []);
            case fields.RelativerWert.name:
                return new ViewFormControl(null, {
                    updateOn: 'change'
                });
            case fields.Intensitaet.name:
                return new ViewFormControl(model.intensitaet, {
                    updateOn: 'change'
                });
            case fields.Preis.name:
                return new ViewFormControl(model.preis, {
                    formatter: this.currencyFormatter
                });
            case fields.Minderwert.name:
                return new ViewFormControl(model.minderwert, {
                    formatter: this.currencyFormatter
                });
            case fields.ExternalId.name:
                return new ViewFormControl(model.externalId || guid());
            case fields.Extern.name:
                return new ViewFormControl(model.extern);
            case fields.Bestaetigt.name:
                return new ViewFormControl(model.bestaetigt);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
