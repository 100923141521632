import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { TrackBy } from '@shared/helper/track-by';
import { Values } from '@shared/helper/values';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { BehaviorSubject } from 'rxjs';
import { FormControlBase } from '../form-control-base.component';

@Component({
  selector: 'app-extendable-radio-list',
  templateUrl: './extendable-radio-list.component.html',
  styleUrls: ['./extendable-radio-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtendableRadioListComponent extends FormControlBase implements OnInit {
  private ref: MatDialogRef<any>;

  public trackByKey = TrackBy.trackByKey;

  public filteredValues$ = new BehaviorSubject<string[]>([]);
  public visibleValues: string[];

  @Input()
  public values: Values;

  @Input()
  public keys: string[] | number;

  @ViewChild('list', { static: true })
  public listTemplate: TemplateRef<any>;

  constructor(formControlFocusService: FormControlFocusService,
    private readonly templateDialogService: TemplateDialogService) {
    super(formControlFocusService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.init();
    this.filter();
  }

  public onShowListClick(): void {
    const buttons = [`list.cancel`];
    this.filter();
    this.ref = this.templateDialogService.open('list.more', buttons, this.listTemplate, { value: this.control.value }, true);
  }

  public onValueChange(value: string): void {
    this.setValue(value);
  }

  public onAddClick(input: MatInput): void {
    if (input.value.length <= 0) {
      return;
    }
    const value = ViewFormControlFormatters.firstLetterToUppercase.format(input.value);
    this.setValue(value);
  }

  public onFilterKeyUp(input: MatInput): void {
    const filterValue = (input.value || '').toLocaleLowerCase();
    this.filter(filterValue);
  }

  public blur(): void {
    // not implemented
  }

  public focus(): void {
    // not implemented
  }

  private filter(filterValue: string = ''): void {
    const values = this.values.keys.map(key => this.values.values[key]);
    const selected = this.control.value;
    if (selected && !this.values.keys.find(key => this.values.values[key] === selected)) {
      values.unshift(selected);
    }
    this.filteredValues$.next(values.filter(value => value.toLowerCase().indexOf(filterValue) !== -1));
  }

  private setValue(value: string): void {
    if (this.ref) {
      this.ref.afterClosed().subscribe(() => {
        this.control.setRawValue(value);
      });
      this.ref.close();
      this.ref = null;
    }
  }

  private init(): void {
    let keys = <string[]>this.keys;
    if (!(keys instanceof Array)) {
      const count = +this.keys;
      keys = this.values.keys.slice(0, count);
    }
    this.visibleValues = keys.map(key => this.values.values[key]);
  }
}
