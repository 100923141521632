import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-mini-fab',
  templateUrl: './mini-fab.component.html',
  styleUrls: ['./mini-fab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiniFabComponent {
  @Input()
  public disabled: boolean;

  @Input()
  public count = 0;

  @Input()
  public color: ThemePalette = 'accent';

  @Input()
  public size = '1.5rem';

  @Input()
  public tooltip = '';

  @Output()
  public action = new EventEmitter<MouseEvent>();
}
