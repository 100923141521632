import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActionRowComponent } from '../action-row/action-row.component';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelComponent {
  @Input()
  public set expanded(expanded: boolean) {
    this.expanded$.next(expanded);
  }

  public expanded$ = new BehaviorSubject<boolean>(undefined);

  @Input()
  public dynamicHeight = true;

  @Output()
  public opened = new EventEmitter<void>();

  @ViewChild('header', { static: true })
  public header: TemplateRef<any>;

  @ViewChild('content', { static: true })
  public content: TemplateRef<any>;

  @ViewChild('row', { static: true })
  public row: TemplateRef<any>;

  @ContentChild(ActionRowComponent, { static: true })
  public actionRow: ActionRowComponent;
}
