<div *ngFor="let message of messages">
    <div class="content-wrapper">
        <p class="text">{{message.text}}</p>
        <p class="date">{{message.date | date:'short'}}</p>
        <app-mini-fab class="delete" *ngIf="delete.observers.length > 0" (click)="delete.emit(message.id)">
            <app-mat-icon icon="close"></app-mat-icon>
        </app-mini-fab>
    </div>
</div>
<ng-container *ngIf="add.observers.length > 0">
    <div class="add">
        <mat-form-field>
            <textarea matInput #text [placeholder]="'chat.message' | translate" rows="1" cdkTextareaAutosize></textarea>
        </mat-form-field>
        <button mat-mini-fab (click)="onClick(text)" color="">
            <app-mat-icon icon="send"></app-mat-icon>
        </button>
    </div>
</ng-container>
