import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GltfGruppe, parse } from '@app/config/gltf';
import { SchadenGruppe, SchadenObergruppe } from '@data/domain/schema/enum';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { ObjectViewControlComponent } from '@shared/component/three/object-view-control/object-view-control.component';
import { Assert } from '@shared/helper/assert';
import { Intersection, Scene } from 'three';


const EXTERIEUR_NAME = 'EXT';
const INTERIEUR_NAME = 'INT';
const TECHNIK_NAME = 'TEC';

const OBERGRUPPE_TO_MODEL_MAP = new Map<SchadenObergruppe, GltfGruppe[]>([
  [SchadenObergruppe.Abgasanlage, [GltfGruppe.TEC_Abgasanlage]],
  [SchadenObergruppe.Antriebsstrang, [GltfGruppe.TEC_Antriebsstrang]],
  [SchadenObergruppe.Armaturenbrett, [GltfGruppe.INT_Armaturenbrett]],
  [SchadenObergruppe.AussenspiegelLinks, [GltfGruppe.EXT_Aussenspiegel_links]],
  [SchadenObergruppe.AussenspiegelRechts, [GltfGruppe.EXT_Aussenspiegel_rechts]],
  [SchadenObergruppe.BremsanlageVorne, [
    GltfGruppe.TEC_Bremssattel_vorne_links,
    GltfGruppe.TEC_Bremsscheibe_vorne_links,
    GltfGruppe.TEC_Bremssattel_vorne_rechts,
    GltfGruppe.TEC_Bremsscheibe_vorne_rechts
  ]],
  [SchadenObergruppe.BremsanlageHinten, [
    GltfGruppe.TEC_Bremssattel_hinten_links,
    GltfGruppe.TEC_Bremsscheibe_hinten_links,
    GltfGruppe.TEC_Bremssattel_hinten_rechts,
    GltfGruppe.TEC_Bremsscheibe_hinten_rechts
  ]],
  [SchadenObergruppe.Dach, [
    GltfGruppe.EXT_Fahrzeugdach
  ]],
  [SchadenObergruppe.Dachhimmel, [GltfGruppe.INT_Dachhimmel]],
  [SchadenObergruppe.Differential, [GltfGruppe.TEC_Differenzial]],
  [SchadenObergruppe.ElektrischesSystem, [
    GltfGruppe.INT_Airbagsystem,
    GltfGruppe.INT_Klimasystem_mit_Heizung,
    GltfGruppe.INT_Multimedia,
    GltfGruppe.INT_Instrumententafel
  ]],
  [SchadenObergruppe.FussraumVorneLinks, [GltfGruppe.INT_Fussraum_vorne_links]],
  [SchadenObergruppe.FussraumVorneRechts, [GltfGruppe.INT_Fussraum_vorne_rechts]],
  [SchadenObergruppe.FussraumHintenLinks, [GltfGruppe.INT_Fussraum_hinten_links]],
  [SchadenObergruppe.FussraumHintenRechts, [GltfGruppe.INT_Fussraum_hinten_rechts]],
  [SchadenObergruppe.Getriebe, [GltfGruppe.TEC_Getriebe]],
  [SchadenObergruppe.Handschuhfach, [GltfGruppe.INT_Handschuhfach]],
  [SchadenObergruppe.Heckklappe, [GltfGruppe.EXT_Heckklappe, GltfGruppe.EXT_Heckklappe_Glas]],
  [SchadenObergruppe.HeckleuchteLinks, [
    GltfGruppe.EXT_Heckleuchte_glas_links
  ]],
  [SchadenObergruppe.HeckleuchteRechts, [
    GltfGruppe.EXT_Heckleuchte_glas_rechts
  ]],
  [SchadenObergruppe.Hinterachse, [GltfGruppe.TEC_Hinterachse]],
  [SchadenObergruppe.Innenraum, []],
  [SchadenObergruppe.Innenspiegel, [GltfGruppe.INT_Rueckspiegel]],
  [SchadenObergruppe.Interieur, []],
  [SchadenObergruppe.Karosserie, []],
  [SchadenObergruppe.Kofferraum, []],
  [SchadenObergruppe.Komfortsysteme, []],
  [SchadenObergruppe.KotfluegelVorneLinks, [GltfGruppe.EXT_Kotfluegel_links]],
  [SchadenObergruppe.KotfluegelVorneRechts, [GltfGruppe.EXT_Kotfluegel_rechts]],
  [SchadenObergruppe.Lenkanlage, [
    GltfGruppe.INT_Lenkrad,
    GltfGruppe.TEC_Lenksaeule,
    GltfGruppe.TEC_Lenkgetriebe,
    GltfGruppe.TEC_Lenkrad
  ]],
  [SchadenObergruppe.Mittelkonsole, [GltfGruppe.INT_Mittelkonsole]],
  [SchadenObergruppe.Motorhaube, [GltfGruppe.EXT_Motorhaube]],
  [SchadenObergruppe.Motorraum, [
    GltfGruppe.TEC_Motor,
    GltfGruppe.TEC_Motorkuehlung_Fluessigkeitsbehaelter
  ]],
  [SchadenObergruppe.Pedalanlage, [GltfGruppe.INT_Pedalanlage]],
  [SchadenObergruppe.RadVorneLinks, [
    GltfGruppe.EXT_Felge_vorne_links,
    GltfGruppe.EXT_Reifen_vorne_links
  ]],
  [SchadenObergruppe.RadVorneRechts, [
    GltfGruppe.EXT_Felge_vorne_rechts,
    GltfGruppe.EXT_Reifen_vorne_rechts
  ]],
  [SchadenObergruppe.RadHintenLinks, [
    GltfGruppe.EXT_Felge_hinten_links,
    GltfGruppe.EXT_Reifen_hinten_links
  ]],
  [SchadenObergruppe.RadHintenRechts, [
    GltfGruppe.EXT_Felge_hinten_rechts,
    GltfGruppe.EXT_Reifen_hinten_rechts
  ]],
  [SchadenObergruppe.ScheinwerferLinks, [GltfGruppe.EXT_Scheinwerfereinheit_glas_links]],
  [SchadenObergruppe.ScheinwerferRechts, [GltfGruppe.EXT_Scheinwerfereinheit_glas_rechts]],
  [SchadenObergruppe.SchwellerLinks, [GltfGruppe.EXT_Schweller_links]],
  [SchadenObergruppe.SchwellerRechts, [GltfGruppe.EXT_Schweller_rechts]],
  [SchadenObergruppe.SeitenwandHintenLinks, [GltfGruppe.EXT_Seitenwand_links]],
  [SchadenObergruppe.SeitenwandHintenRechts, [GltfGruppe.EXT_Seitenwand_rechts]],
  [SchadenObergruppe.SitzVorneLinks, [GltfGruppe.INT_Sitz_vorne_links]],
  [SchadenObergruppe.SitzVorneRechts, [GltfGruppe.INT_Sitz_vorne_rechts]],
  [SchadenObergruppe.SitzHintenLinks, [
    GltfGruppe.INT_Sitz_hinten_links,
    GltfGruppe.INT_Sitz_Ruecksitzreihe_eins_links,
    GltfGruppe.INT_Sitz_Ruecksitzreihe_zwei_links
  ]],
  [SchadenObergruppe.SitzHintenMitte, [
    GltfGruppe.INT_Sitz_hinten_mittig,
    GltfGruppe.INT_Sitz_Ruecksitzreihe_zwei_mitte
  ]],
  [SchadenObergruppe.SitzHintenRechts, [
    GltfGruppe.INT_Sitz_hinten_rechts,
    GltfGruppe.INT_Sitz_Ruecksitzreihe_eins_rechts,
    GltfGruppe.INT_Sitz_Ruecksitzreihe_zwei_rechts
  ]],
  [SchadenObergruppe.StossfaengerVorne, [GltfGruppe.EXT_Stossfaenger_vorne]],
  [SchadenObergruppe.StossfaengerHinten, [GltfGruppe.EXT_Stossfaenger_hinten]],
  [SchadenObergruppe.TuerVorneLinks, [
    GltfGruppe.EXT_Tuer_vorne_links,
    GltfGruppe.EXT_Tuer_vorne_links_Glas
  ]],
  [SchadenObergruppe.TuerVorneRechts, [
    GltfGruppe.EXT_Tuer_vorne_rechts,
    GltfGruppe.EXT_Tuer_vorne_rechts_Glas
  ]],
  [SchadenObergruppe.TuerHintenLinks, [
    GltfGruppe.EXT_Tuer_hinten_links,
    GltfGruppe.EXT_Tuer_hinten_links_Glas
  ]],
  [SchadenObergruppe.TuerHintenRechts, [
    GltfGruppe.EXT_Tuer_hinten_rechts,
    GltfGruppe.EXT_Tuer_hinten_rechts_Glas
  ]],
  [SchadenObergruppe.TuerverkleidungVorneLinks, [GltfGruppe.INT_Tuerverkleidung_vorne_links]],
  [SchadenObergruppe.TuerverkleidungVorneRechts, [GltfGruppe.INT_Tuerverkleidung_vorne_rechts]],
  [SchadenObergruppe.TuerverkleidungHintenLinks, [GltfGruppe.INT_Tuerverkleidung_hinten_links]],
  [SchadenObergruppe.TuerverkleidungHintenRechts, [GltfGruppe.INT_Tuerverkleidung_hinten_rechts]],
  [SchadenObergruppe.Unterboden, [GltfGruppe.EXT_Unterboden]],
  [SchadenObergruppe.Vorderachse, [GltfGruppe.TEC_Vorderachse]],
  [SchadenObergruppe.Windschutzscheibe, [GltfGruppe.EXT_Windschutzscheibe_Glas]],
  [SchadenObergruppe.Zubehoer, []],
]);

@Component({
  selector: 'app-produkt-detail-schaden-scene',
  templateUrl: './produkt-detail-schaden-scene.component.html',
  styleUrls: ['./produkt-detail-schaden-scene.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailSchadenSceneComponent {
  private _gruppe: SchadenGruppe;
  public scene: Scene = null;

  @ViewChild(ObjectViewControlComponent, { static: true })
  public control: ObjectViewControlComponent;

  @Input()
  public set gruppe(gruppe: SchadenGruppe) {
    this._gruppe = gruppe;
    this.updateScene();
  }

  @Input()
  public modelFileConfigs: ModelFileConfig[];

  @Output()
  public obergruppeSelect = new EventEmitter<SchadenObergruppe>();

  @Output()
  public modelLoad = new EventEmitter<ModelLoadResult>();

  public onSceneLoad(scene: Scene): void {
    Assert.notNullOrUndefined(scene, 'scene');
    this.scene = scene;
    this.updateScene();
  }

  public onModelLoad(modelLoadResult: ModelLoadResult): void {
    Assert.notNullOrUndefined(modelLoadResult, 'modelLoadResult');
    this.modelLoad.emit(modelLoadResult);
  }

  public onUserTap(tap: Intersection): void {
    Assert.notNullOrUndefined(tap, 'tap');
    const obergruppe = this.getObergruppe(tap);
    if (obergruppe !== undefined) {
      this.obergruppeSelect.emit(obergruppe);
    }
  }

  private updateScene(): void {
    if (!this.scene) {
      return;
    }

    const children = this.scene.children;
    for (const child of children) {
      const name = child.name;
      if (name.includes(EXTERIEUR_NAME) || name.includes(INTERIEUR_NAME)) {
        child.visible = this._gruppe !== SchadenGruppe.Technik;
      } else if (name.includes(TECHNIK_NAME)) {
        child.visible = this._gruppe === SchadenGruppe.Technik;
      }
    }

    this.control.invert(this._gruppe === SchadenGruppe.Interieur);
  }

  private getObergruppe(tap: Intersection): SchadenObergruppe {
    if (tap && tap.object) {
      const gruppe = parse(tap.object.name);
      if (gruppe !== undefined) {
        for (const [k, v] of OBERGRUPPE_TO_MODEL_MAP) {
          if (v.indexOf(gruppe) !== -1) {
            return k;
          }
        }
      }
    }
    return undefined;
  }
}
