import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { AuftragArt, ProduktArt } from '@data/domain/schema/enum';
import { Auftrag } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import {
    ProduktDetailTextbausteinFormViewFactory
} from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailAuftragHinweisFormViewFactory } from './produkt-detail-auftrag-hinweis-form-view.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailAuftragFormViewFactory extends FormViewModelBaseFactory<Auftrag> {

    constructor(
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory,
        private readonly produktDetailAuftragHinweisFormViewFactory: ProduktDetailAuftragHinweisFormViewFactory) {
        super();
        Assert.notNullOrUndefined(produktDetailAuftragHinweisFormViewFactory, 'produktDetailAuftragHinweisFormViewFactory');
    }

    protected createField(model: Auftrag, name: string, produktArt: ProduktArt): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Auftrag.fields;
        switch (name) {
            case fields.Art.name:
                return new ViewFormControl(model.art || AuftragArt.Partner);
            case fields.Nummer.name:
                return new ViewFormControl(model.nummer);
            case fields.Vorgangsnummer.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.vorgangsnummer, {validators: Validators.required});
                } else {
                    return new ViewFormControl(model.vorgangsnummer);
                }
            case fields.Auftragstyp.name:
                return new ViewFormControl(model.auftragstyp);
            case fields.BezugsAuftragsnummer.name:
                return new ViewFormControl(model.bezugsAuftragsnummer);
            case fields.Kommentar.name:
                return new ViewFormControl(model.kommentar);
            case fields.ErstellungsTag.name:
                return new ViewFormControl(model.erstellungsTag);
            case fields.ErstellungsUhrzeit.name:
                return new ViewFormControl(model.erstellungsUhrzeit);
            case fields.BesichtigungsTag.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.besichtigungsTag, {validators: Validators.required});
                } else {
                    return new ViewFormControl(model.besichtigungsTag);
                }
            case fields.BesichtigungsUhrzeit.name:
                if (produktArt === ProduktArt.AlphaController) {
                    return new ViewFormControl(model.besichtigungsUhrzeit, {validators: Validators.required});
                } else {
                    return new ViewFormControl(model.besichtigungsUhrzeit);
                }
            case fields.FertigstellungsTag.name:
                return new ViewFormControl(model.fertigstellungsTag);
            case fields.FertigstellungsUhrzeit.name:
                return new ViewFormControl(model.fertigstellungsUhrzeit);
            case fields.Bemerkung.name:
                return new ViewFormControl(model.bemerkung, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase
                });
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: Auftrag, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Auftrag.fields;
        switch (arrayName) {
            case fields.Hinweise.name:
                const hinweise = model.hinweise || [];
                return new ViewFormArray(
                    hinweise.map(
                        hinweis => this.produktDetailAuftragHinweisFormViewFactory
                            .create(hinweis, arrayFields)
                    ));
            case fields.Auftragsbeschreibung.name:
                const auftragsbeschreibungen = model.auftragsbeschreibung || [];
                return new ViewFormArray(
                    auftragsbeschreibungen.map(
                        auftragsbeschreibung => this.produktDetailTextbausteinFormViewFactory
                            .create(auftragsbeschreibung, arrayFields)
                    ));
            case fields.Bemerkungen.name:
                const bemerkungen = model.bemerkungen || [];
                return new ViewFormArray(
                    bemerkungen.map(
                        bemerkung => this.produktDetailTextbausteinFormViewFactory
                            .create(bemerkung, arrayFields)
                    ));
            case fields.Besichtigungsbedingungen.name:
                const besichtigungsbedingungen = model.besichtigungsbedingungen || [];
                return new ViewFormArray(
                    besichtigungsbedingungen.map(
                        besichtigungsbedingung => this.produktDetailTextbausteinFormViewFactory
                            .create(besichtigungsbedingung, arrayFields)
                    ));
            default:
                throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
