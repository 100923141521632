import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Adresse } from '@data/domain/schema/type';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdresseElasticSearchService {
    private readonly restSearchApiUrl: string;
    private readonly restDeleteApiUrl: string;

    constructor(
        private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restSearchApiUrl = `${environment.aws.apiGateway.url}/adresse/search`;
        this.restDeleteApiUrl = `${environment.aws.apiGateway.url}/adresse`;
    }

    public get(firma?: string, name?: string, vorname?: string): Observable<Adresse[]> {
        return this.httpClient.get<Adresse[]>(this.restSearchApiUrl, {
            params: { firma, name, vorname }
        });
    }

    public delete(id: string): Observable<string> {
      return this.httpClient.delete<string>(this.restDeleteApiUrl, {
        params: { id }
      });
    }
}
