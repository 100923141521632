import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface VtiAuftragResponse {
  vti_auftragsnummer: string;
  bueronummer: string;
  vti_auftragstyp: string;
  fahrzeug: VtiAuftragFahrzeug;
  gtue_auftraggeber_adresse: VtiAuftragAuftraggeberAdresse;
}

export interface VtiAuftragFahrzeug {
  fin: string;
  kennzeichen: string;
  erstzulassung: string;
  laufleistung: number;
}

export interface VtiAuftragAuftraggeberAdresse {
  nummer: string;
  firma: string;
  strasse_nummer: string;
  plz: string;
  ort: string;
}

@Injectable({
  providedIn: 'root'
})
export class VtiAuftragService {
  private readonly restApiUrlVtiAuftragssuche: string;

  constructor(
    private readonly httpClient: HttpClient) {
    Assert.notNullOrUndefined(httpClient, 'httpClient');
    this.restApiUrlVtiAuftragssuche = `${environment.aws.apiGateway.url}/vti/auftragssuche`;
  }

  public getAuftrag(amsId: number): Observable<VtiAuftragResponse> {
    Assert.notNullOrUndefined(amsId, 'amsId');
    return this.httpClient.get<VtiAuftragResponse>(this.restApiUrlVtiAuftragssuche, {
      params: {
        amsAuftragsnummer: amsId.toString()
      }
    });
  }
}
