import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input, OnDestroy, OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { TrackBy } from '@shared/helper/track-by';
import { BehaviorSubject, Subscription } from 'rxjs';
import { StepComponent } from '../step/step.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepperComponent implements OnInit, OnDestroy {
  @ViewChild('stepperVertical', {static: true})
  private stepperVertical: MatStepper;

  @ViewChild('stepperHorizontal', {static: true})
  private stepperHorizontal: MatStepper;

  public trackByInstance = TrackBy.trackByInstance;

  public selectedIndexChange = new BehaviorSubject<number>(0);
  public selectedIndexSubsription: Subscription;

  @Input()
  public set selectedIndex(index: number) {
    this.selectedIndexChange.next(index);
  }

  @Input()
  public linear: boolean;

  @Input()
  public horizontal = false;

  @ContentChildren(StepComponent)
  public steps: QueryList<StepComponent>;

  public ngOnInit() {
    this.selectedIndexSubsription = this.selectedIndexChange.subscribe(index => this.setIndex(index));
  }

  public ngOnDestroy() {
    this.selectedIndexSubsription.unsubscribe();
  }

  public next(): void {
    this.stepperHorizontal.next();
    if (this.horizontal) {
      this.stepperHorizontal.next();

    } else {
      this.stepperVertical.next();
    }
  }

  public prev(): void {
    if (this.horizontal) {
      this.stepperHorizontal.previous();

    } else {
      this.stepperVertical.previous();
    }
  }

  public setIndex(index: number) {
    if (this.horizontal) {
      this.stepperHorizontal.linear = false;
      this.stepperHorizontal.selectedIndex = index;
      setTimeout(() => {
        this.stepperHorizontal.linear = true;
      });
    } else {
      this.stepperVertical.linear = false;
      this.stepperVertical.selectedIndex = index;
      setTimeout(() => {
        this.stepperVertical.linear = true;
      });
    }
  }
}
