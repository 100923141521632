<ng-container *ngIf="searchable">
    <mat-form-field>
        <input matInput #filter (keyup)="onFilterKeyUp(filter.value)" />
        <ng-container matSuffix *ngIf="submit.observers.length > 0">
            <button mat-button (click)="onSubmit(filter)" [disabled]="filter.value.length === 0">
              <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
            </button>
        </ng-container>
    </mat-form-field>
</ng-container>
<mat-action-list>
    <button mat-list-item *ngFor="let key of (keys$ | async);trackBy:trackByKey" (click)="onItemClick(key)" [disabled]="disabled[key]">
        {{keyValues[key]}}
    </button>
</mat-action-list>
