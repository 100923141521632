import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

declare let SphinxClass: any;

interface DatRestwertprognoseAuth {
  action: string;
  customerNumber?: string;
  user?: string;
  password?: string;
  interfacePartnerNumber?: string;
  interfacePartnerSignature?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DatFinanceLineService {

  private sphinx;
  private tokenUrl = 'https://www.datgroup.com/AuthorizationManager/service--/endpoint/tokenService';
  private auth: DatRestwertprognoseAuth;
  private readonly restApiUrl: string;

  constructor( private httpClient: HttpClient) {
    Assert.notNullOrUndefined(httpClient, 'httpClient');
    this.restApiUrl = `${environment.aws.apiGateway.url}/dat/restwertprognose/auth`;
    this.sphinx = SphinxClass.getInstance();
    this.getDatAuth().pipe(first()).subscribe(auth => this.auth = auth);
  }

  public getDatAuth(): Observable<DatRestwertprognoseAuth> {
    return this.httpClient.get<DatRestwertprognoseAuth>(this.restApiUrl);
}
  public getToken(): Observable<Object> {
    return this.httpClient.post(this.tokenUrl, this.auth);
  }
}
