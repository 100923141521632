import { Injectable } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { ProduktConfig, PRODUKT_CONFIG_ART_SUBART_MAP } from '../config/produkt-config';

@Injectable({
    providedIn: 'root'
})
export class ProduktConfigService {
    public get(produktArt: number, produktSubart: number): ProduktConfig {
        Assert.notNullOrUndefined(produktArt, 'produktArt');
        Assert.notNullOrUndefined(produktSubart, 'produktSubart');
        // TODO: Impl. Merge etc.
        const configArt = PRODUKT_CONFIG_ART_SUBART_MAP[produktArt];
        return configArt[produktSubart];
    }
}
