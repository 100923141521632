import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AusstattungArt, FahrzeugAntriebsart, FahrzeugAntriebstechnologie, FahrzeugArt, FahrzeugBauform, FahrzeugExternalServicesReferenceSource, FahrzeugGetriebart, FahrzeugSchadstoffklasse } from '@data/domain/schema/enum';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';
import { ExternalDataServiceResponseBase } from '../schema/external-data-service';

export interface FahrzeugIdentificationOption {
    identifier: string;
    key: string;
    value: string;
}

export interface FahrzeugIdentificationConstructionTime {
    key: string;
    value: string;
}

export interface AusstattungTeil {
    herstellerCode?: string;
    herstellerName: string;
    vorhanden?: boolean;
    externalServiceId?: string;
}

export interface FahrzeugExternalServicesReference {
    fahrzeugExternalServicesReferenceSource?: FahrzeugExternalServicesReferenceSource;
    identifier?: string;
    container?: string;
    constructionTime?: number;
    vxsData?: string;
}
export interface FahrzeugDto {
    externalServicesReference?: FahrzeugExternalServicesReference;
    datECode?: string;
    typCode?: string;
    herstellerCode?: string;
    art?: FahrzeugArt;
    bauform?: FahrzeugBauform;
    hersteller?: string;
    modell?: string;
    untertyp?: string;

    lackcode?: string;
    innenausstattung?: string;
    polsterfarbe?: string;
    polstermaterial?: string;
    antriebstechnologie?: FahrzeugAntriebstechnologie;
    antriebsart?: FahrzeugAntriebsart;

    schadstoffklasse?: FahrzeugSchadstoffklasse;
    hubraum?: number;
    leistung?: number;
    zylinder?: number;
    getriebeart?: FahrzeugGetriebart;
    anzahlGaenge?: number;
    leergewicht?: number;
    zulaessigesGesamtgewicht?: number;
    anzahlTueren?: number;
    anzahlSitze?: number;
    masseLaenge?: number;
    masseBreite?: number;
    masseHoehe?: number;
}

export interface AusstattungGruppe {
    name: string;
    teile: AusstattungTeil[];
    sonderausstattung?: boolean;
}

export interface AusstattungDto {
    art?: AusstattungArt;
    abfrageErfolgreich?: boolean;
    gruppen: AusstattungGruppe[];
}

export interface FahrzeugIdentification {
    constructionTime: FahrzeugIdentificationConstructionTime;
    identifiers: FahrzeugIdentificationOption[];
    nextIdentifiers: FahrzeugIdentificationOption[];
}

export interface FahrzeugServiceRequest {
    produktId?: string;
    kennzeichen?: string;
    vin?: string;
    kba?: string;
    suchbaum?: FahrzeugIdentification;
}

export interface FahrzeugServiceResponse extends ExternalDataServiceResponseBase {
    fahrzeug?: FahrzeugDto;
    ausstattung?: AusstattungDto;
    identifications?: FahrzeugIdentification[];
}

@Injectable({
    providedIn: 'root'
})
export class FahrzeugService {
    private readonly restApiUrl: string;

    constructor(
        private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/dat/fahrzeug`;
    }

    public getVehicleIdentification(request: FahrzeugServiceRequest): Observable<FahrzeugServiceResponse> {
        Assert.notNullOrUndefined(request, 'request');
        return this.httpClient.put<FahrzeugServiceResponse>(this.restApiUrl, request);
    }
}
