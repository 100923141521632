<ng-container *ngFor="let field of fields;trackBy:trackByField">
    <ng-container [ngSwitch]="field.arrayName">
        <!-- Gruppen -->
        <ng-container *ngSwitchCase="'gruppen'">
            <app-accordion #accordion
                           *ngFor="let gruppe of form.get(field.arrayName).controls;trackBy:trackByInstance;first as first;last as last; index as index">
                <app-expansion-panel #panel
                  (opened)="onPanelOpened(panel, index)"
                  [expanded]="(active$ | async) === index">
                    <!-- Header -->
                    <app-expansion-header>
                        <app-panel-title *ngIf="((gruppe.valueChanges | async) || gruppe.value) as value">
                            <app-icon *ngIf="icons[value.name]" [icon]="icons[value.name]"></app-icon>
                            &nbsp;<strong>{{value.name}}</strong>
                        </app-panel-title>
                    </app-expansion-header>
                    <!-- Input -->
                    <mat-form-field *ngIf="isManualAdded(gruppe)">
                      <input #input matInput [name]="gruppe.value"/>
                      <ng-container matSuffix>
                        <button mat-icon-button (click)="onAddClick(gruppe, input, teileList) "[disabled]="input.value.length === 0">
                          <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
                        </button>
                      </ng-container>
                    </mat-form-field>
                    <!-- List -->
                    <app-selection-list #teileList [multiline]="true">
                        <app-list-option
                            *ngFor="let teil of gruppe.get('teile').controls;trackBy:trackByInstance;index as id"
                            [form]="teil" [name]="'vorhanden'" [displayName]="teil.value.name" [id]="id + 1">
                        </app-list-option>
                    </app-selection-list>

                    <app-action-row>
                        <app-button [disabled]="first" (action)="prev(accordion)">
                            {{(name + '.zurueck') | translate }}
                        </app-button>
                        <app-divider [vertical]="true"></app-divider>
                        <app-button [disabled]="last" (action)="next(accordion)">
                            {{(name + '.weiter') | translate }}
                        </app-button>
                    </app-action-row>
                </app-expansion-panel>
            </app-accordion>
        </ng-container>
        <!-- Bemerkungen -->
        <ng-container *ngSwitchCase="'individualaufbauten'">
            <app-accordion>
                <app-expansion-panel>
                    <app-expansion-header>
                        <app-panel-title>
                            <app-icon class="material-icons-outlined" matSuffix>font_download</app-icon>
                            &nbsp;<strong>Individualaufbauten</strong>
                        </app-panel-title>
                    </app-expansion-header>
            <app-textbausteine #individualaufbauten [form]="form" [name]="field.arrayName" [displayName]="'Individualaufbauten'"
                               [feature]="feature" [produktArt]="this.produkt.art">
            </app-textbausteine>
                </app-expansion-panel>
            </app-accordion>
        </ng-container>
    </ng-container>
</ng-container>
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)">
</app-produkt-detail-notizen-button>
