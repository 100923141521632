import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import * as AWS from 'aws-sdk';
import { from, Observable, of } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class CognitoService {
    constructor(private readonly authService: AuthService) {
        Assert.notNullOrUndefined(authService, 'authService');
    }

    public secureContext(): Observable<any> {
        return this.getCredentials().pipe(
            flatMap(credentials => {
                if (credentials.needsRefresh()) {
                    const promise = credentials.refreshPromise();
                    return from(promise).pipe(
                        map(() => credentials.data)
                    );
                }
                return of(credentials.data);
            })
        );
    }

    private getCredentials(): Observable<AWS.CognitoIdentityCredentials> {
        const credentials = <AWS.CognitoIdentityCredentials>AWS.config.credentials;
        if (credentials) {
            return of(credentials);
        } else {
            return this.createCredentials();
        }
    }

    private createCredentials(): Observable<AWS.CognitoIdentityCredentials> {
        return this.authService.getUser().pipe(
            map(user => new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: `${environment.aws.region}:${environment.aws.cognito.identityPoolId}`,
                    Logins: {
                        [`cognito-idp.${environment.aws.region}.amazonaws.com/${environment.aws.region}_${environment.aws.cognito.userPoolId}`]: user.id_token,
                    }
                })),
            tap(credentials => {
                AWS.config.update({
                    region: environment.aws.region,
                    credentials
                });
            })
        );
    }
}
