import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  VorschadenArt,
  VorschadenObergruppe,
  VorschadenReparatur, VorschadenReparaturMapNames,
  VorschadenUntergruppe
} from '@data/domain/schema/enum';
import { Notiz, Vorschaden, VorschadenInput, VorschadenPosition } from '@data/domain/schema/type';
import { ProduktVorschadenService } from '@data/domain/service/feature';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, FeatureFieldGroup, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { OBERGRUPPE_TO_UNTERGRUPPE_MAP, VORSCHADEN_GRUPPEN } from '@modules/produkt/config/produkt-vorschaden-gruppen.config';
import { ProduktDetailVorschadenFormViewFactory } from '@modules/produkt/factory/vorschaden/produkt-detail-vorschaden-form-view.factory';
import { ProduktDetailVorschadenPositionFormViewFactory } from '@modules/produkt/factory/vorschaden/produkt-detail-vorschaden-position-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ModelFileService } from '@modules/produkt/service/model-file.service';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TableRowMoveEvent } from '@shared/component/data-table';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ArrayValues, EnumValues, Values } from '@shared/helper/values';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateButtonDisableEvent, TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { filter, flatMap, map, startWith } from 'rxjs/operators';

const OBERGRUPPE_STRING_MAP = {
  [VorschadenObergruppe.Frontschaden]: VORSCHADEN_GRUPPEN.find(x => x === 'Frontschaden'),
  [VorschadenObergruppe.SchwellerschadenLinks]: VORSCHADEN_GRUPPEN.find(x => x === 'Schwellerschaden links'),
  [VorschadenObergruppe.SeitenschadenLinks]: VORSCHADEN_GRUPPEN.find(x => x === 'Seitenschaden links'),
  [VorschadenObergruppe.Heckschaden]: VORSCHADEN_GRUPPEN.find(x => x === 'Heckschaden'),
  [VorschadenObergruppe.SchwellerschadenRechts]: VORSCHADEN_GRUPPEN.find(x => x === 'Schwellerschaden rechts'),
  [VorschadenObergruppe.SeitenschadenRechts]: VORSCHADEN_GRUPPEN.find(x => x === 'Seitenschaden rechts'),
  [VorschadenObergruppe.SchadenAmUnterboden]: VORSCHADEN_GRUPPEN.find(x => x === 'Schaden am Unterboden'),
  [VorschadenObergruppe.SchadenAmDach]: VORSCHADEN_GRUPPEN.find(x => x === 'Schaden am Dach')
};

export enum VorschadenDisplay {
  Modell = 1,
  Manuell = 2
}

interface VorschadenDialogData {
  form: ViewFormGroup;
  fields: FeatureFields;
}

interface VorschadenUntergruppeDialogData {
  form: ViewFormGroup;
  values: Values;
}

@Component({
  selector: 'app-produkt-detail-vorschaden',
  templateUrl: './produkt-detail-vorschaden.component.html',
  styleUrls: ['./produkt-detail-vorschaden.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailVorschadenComponent extends ProduktDetailFeatureInputComponent<Vorschaden, VorschadenInput> implements OnInit, OnDestroy {
  private positionenName = PRODUKT_CONFIG_FEATURES.Vorschaden.fields.Positionen.name;
  private positionenObergruppeName = PRODUKT_CONFIG_FEATURES.Vorschaden.fields.Positionen.fields.Obergruppe.name;

  public trackByField = TrackBy.trackByField;

  public notizen$: Observable<Notiz[]>;
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;
  public mobileLandscapeOptimization: Subscription;

  public positionen: ViewFormArray;
  public positionenFields: FeatureFields;
  public display = VorschadenDisplay.Modell;
  public obergruppenManuell = new EnumValues(VORSCHADEN_GRUPPEN);
  public reparatur = new EnumValues(VorschadenReparatur);
  public reparaturMap = new EnumValues(VorschadenReparaturMapNames);
  public art = new EnumValues(VorschadenArt);

  public rows$: Observable<AbstractViewFormControl[]>;
  public sums$: Observable<string>;
  public activeObergruppen$: Observable<string[]>;

  public modelFileConfigs: ModelFileConfig[];
  public manuellDisabled$ = new BehaviorSubject<boolean>(false);

  @ViewChild('dialog', { static: true })
  public dialogTemplate: TemplateRef<any>;

  @ViewChild('untergruppe', { static: true })
  public untergruppeTemplate: TemplateRef<any>;

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktVorschadenService: ProduktVorschadenService,
    private readonly formViewFactory: ProduktDetailVorschadenFormViewFactory,
    private readonly positionFormViewFactory: ProduktDetailVorschadenPositionFormViewFactory,
    private readonly templateDialogService: TemplateDialogService,
    private readonly currencyFormatter: CurrencyFormatterService,
    private readonly modelFileService: ModelFileService,
    private readonly snackBarService: SnackBarService,
    private readonly viewportService: ViewportService,
    private readonly notizenService: ProduktDetailFeatureNotizenService,
    private readonly updateWerteService: UpdateWerteService) {
    super(produktConfigResolveService, produktDetailResolveService, produktVorschadenService);
    Assert.notNullOrUndefined(formViewFactory, 'vorschadenFormViewFactory');
    Assert.notNullOrUndefined(positionFormViewFactory, 'vorschadenPositionFormViewFactory');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
    Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    Assert.notNullOrUndefined(modelFileService, 'modelFileService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    Assert.notNullOrUndefined(viewportService, 'viewportService');
  }

  public ngOnInit(): void {
    const name = PRODUKT_CONFIG_FEATURES.Vorschaden.name;
    this.notizen$ = this.notizenService.init(this.produkt, name);
    this.viewport$ = this.viewportService.observe();
    this.modelFileConfigs = this.modelFileService.get(this.produkt.fahrzeug.fahrzeugart, this.produkt.fahrzeug.bauform);
    this.mobileLandscapeOptimization = this.viewportService.mobileLandscapeOptimization();
    this.init(name);
  }

  public ngOnDestroy(): void {
    this.mobileLandscapeOptimization.unsubscribe();
  }

  public onDisplayChange(display: VorschadenDisplay): void {
    Assert.notNullOrUndefined(display, 'display');
    this.display = display;
  }

  public onObergruppeAction(index: number): void {
    Assert.notNullOrUndefined(index, 'index');
    this.createPosition(index);
  }

  public onObergruppeSelect(value: number): void {
    Assert.notNullOrUndefined(value, 'value');
    this.createPosition(value);
  }

  public onRowOpen(row: ViewFormGroup): void {
    Assert.notNullOrUndefined(row, 'row');
    const index = this.positionen.controls.indexOf(row);
    this.editPosition(index, row.getRawValue());
  }

  public onRowOpenByIndex(index: number, item: any, panel: MatExpansionPanel, $event: MouseEvent): void {
    Assert.notNullOrUndefined(index, 'index');
    $event.stopPropagation();
    panel.close();
    this.editPosition(index, item);
  }

  public onRowRemove(row: ViewFormGroup): void {
    Assert.notNullOrUndefined(row, 'row');
    const index = this.positionen.controls.indexOf(row);
    this.positionen.removeAt(index);
    this.updateWerteService.resetAufwendungen(this.produkt);
  }

  public onRowRemoveByIndex(index: number, panel: any, $event: MouseEvent): void {
    Assert.notNullOrUndefined(index, 'index');
    $event.stopPropagation();
    panel.close();
    this.positionen.removeAt(index);
    if (this.positionen.length === 0) {
      this.updateWerteService.resetAufwendungen(this.produkt);
    }
  }

  public onRowMove(event: TableRowMoveEvent): void {
    Assert.notNullOrUndefined(event, 'event');
    const index = this.positionen.controls.indexOf(event.row);
    this.positionen.controls.splice(index, 1);
    this.positionen.controls.splice(index + event.offset, 0, event.row);
    this.positionen.updateValueAndValidity();
  }

  public onModelLoad(modelLoadStatus: ModelLoadResult): void {
    Assert.notNullOrUndefined(modelLoadStatus, 'modelLoadStatus');
    if (modelLoadStatus === ModelLoadResult.None) {
      this.manuellDisabled$.next(true);
      this.onDisplayChange(VorschadenDisplay.Manuell);
      this.snackBarService.warning('modell.couldNotLoad');
    } else if (modelLoadStatus === ModelLoadResult.Fallback) {
      this.snackBarService.info('modell.fallback');
    }
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  protected createForm(): ViewFormGroup {
    const form = this.formViewFactory.create(this.produkt.vorschaden, this.fields);
    this.positionen = <ViewFormArray>form.get(this.positionenName);
    this.positionenFields = (<FeatureFieldArray>this.fields
      .find((x: FeatureFieldArray) => x.arrayName === this.positionenName))
      .fields;
    this.rows$ = this.getRows$();
    this.sums$ = this.getSums$();
    this.activeObergruppen$ = this.getActiveObergruppen$();
    return form;
  }

  private createPosition(obergruppe: number): void {
    this.selectUntergruppe(obergruppe).pipe(
      filter(value => !!value),
      flatMap(value => this.openPosition(value))
    ).subscribe(position => this.positionen.push(position));
  }

  private editPosition(index: number, position: VorschadenPosition): void {
    this.openPosition(position).subscribe(update => {
      (<ViewFormGroup>this.positionen.controls[index]) = update;
      this.positionen.updateValueAndValidity();
    });
  }

  private openPosition(position: VorschadenPosition): Observable<ViewFormGroup> {
    const title = position.obergruppe === position.untergruppe
      ? position.obergruppe
      : `${position.obergruppe} / ${position.untergruppe}`;

    const fields = this.positionenFields;
    const form = this.positionFormViewFactory.create(position, fields);
    const buttons = [`${this.name}.cancel`, `${this.name}.save`];
    const data: VorschadenDialogData = { form, fields };
    return this.templateDialogService.openTemplate(title, buttons, this.dialogTemplate, data, true).pipe(
      filter(result => result && result.name === buttons[1]),
      map(result => result.data.form)
    );
  }

  private getActiveObergruppen$(): Observable<string[]> {
    return this.positionen.valueChanges.pipe(
      startWith({}),
      map(() => [...(<ViewFormGroup[]>this.positionen.controls)]
        .map(control => control.getRawValue()[this.positionenObergruppeName])));
  }

  private selectUntergruppe(ogruppe: number): Observable<VorschadenPosition> {
    const fields = this.positionenFields;
    let obergruppe = OBERGRUPPE_STRING_MAP[ogruppe];

    if (!obergruppe) {
      obergruppe = ogruppe;
    }

    const form = this.positionFormViewFactory.create({ obergruppe }, fields);
    const button = [`${this.name}.cancel`];

    let untergruppen = OBERGRUPPE_TO_UNTERGRUPPE_MAP.get(ogruppe);
    if (!untergruppen) {
      untergruppen = [<any>obergruppe];
    }
    const values = new ArrayValues(<string[]>untergruppen, VorschadenUntergruppe);

    const data: VorschadenUntergruppeDialogData = { form, values };
    const dialog = this.templateDialogService.open(obergruppe, button, this.untergruppeTemplate, data, true);

    const promise = new Promise<VorschadenPosition>((resolve, reject) => {
      let valueChangeSubscription = form.valueChanges.subscribe(
        value => {
          valueChangeSubscription.unsubscribe();
          valueChangeSubscription = null;
          dialog.close();
          resolve(value);
        },
        () => reject()
      );
      dialog.afterClosed().subscribe(
        () => {
          if (valueChangeSubscription) {
            valueChangeSubscription.unsubscribe();
          } else {
            resolve(null);
          }
        },
        () => reject()
      );
    });
    return from(promise);
  }

  private getRows$(): Observable<AbstractViewFormControl[]> {
    return this.positionen.valueChanges.pipe(
      startWith({}),
      map(() => <AbstractViewFormControl[]>[...this.positionen.controls])
    );
  }

  private getSums$(): Observable<string> {
    return this.positionen.valueChanges.pipe(startWith({}), map(() => {
      const sums = [];

      const bestaetigtePositionen: ViewFormArray = this.getBestaetigtePositionen();

      this.positionenFields.forEach((field: FeatureFieldGroup) => {
        if (field.groupName === 'wertminderungGroup') {
          const schadenhoehe = bestaetigtePositionen.controls
            .reduce((cv, cy: ViewFormGroup) => cv + cy.getRawValue()['schadenhoehe'], 0);
          sums.push(this.currencyFormatter.format(schadenhoehe) + ' €');
          const wertminderung = bestaetigtePositionen.controls
            .reduce((cv, cy: ViewFormGroup) => cv + cy.getRawValue()['wertminderung'], 0);
          sums.push(this.currencyFormatter.format(wertminderung) + ' €');
        }
      });
      return sums.join(' | ');
    }));
  }

  private getBestaetigtePositionen(): ViewFormArray {
    const bestaetigtePositionen: ViewFormArray = new ViewFormArray([]);
    if (!this.positionen?.controls) {
      console.warn(`Unable to get bestaetigtePositionen: positionen.controls is undefined.`);
      return bestaetigtePositionen;
    }

    for (const control of this.positionen.controls) {
      const extern = control.get('extern')?.value;
      const bestaetigt = control.get('bestaetigt')?.value;

      if (!extern) {
        bestaetigtePositionen.push(control);
      } else {
        if (bestaetigt) {
          bestaetigtePositionen.push(control);
        }
      }
    }

    return bestaetigtePositionen;
  }

  private drop(event: CdkDragDrop<string[]>): void {
    const item = this.positionen.at(event.previousIndex);
    this.positionen.controls.splice(event.previousIndex, 1);
    this.positionen.controls.splice(event.currentIndex, 0, item);
    this.positionen.updateValueAndValidity();
  }

  public checkFieldBestaetigt(item: VorschadenPosition) {
    if (!item) {
      console.warn('checkFieldBestaetigt() - warning: item is null or undefined.');
    }

    let ret = false;

    if (!item.extern) {
      ret = true;
    } else {
      if (item.bestaetigt) {
        ret = true;
      }
    }

    return ret;
  }

  public changeTemplateButtonDisabled(event: TemplateButtonDisableEvent): void {
    if (!event) {
      return;
    }
    if (event.disable) {
      this.templateDialogService.disableButton(event.index);
    } else {
      this.templateDialogService.enableButton(event.index);
    }
  }
}
