import { Injectable } from '@angular/core';
import { File } from '@app/class/file';
import { FileDatabase } from '@app/class/file-database';
import { FileSize } from '@app/enum/file-size.enum';
import { FileDatabaseProvider } from '@app/provider/database.provider';
import { Assert } from '@shared/helper/assert';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, finalize, flatMap, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { S3FileService } from './s3-file.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    private readonly database: FileDatabase;
    private readonly syncCountChange = new BehaviorSubject<number>(0);
    private readonly syncInProgressChange = new BehaviorSubject<boolean>(false);

    constructor(
        private readonly s3FileService: S3FileService,
        fileDatabaseProvider: FileDatabaseProvider) {
        Assert.notNullOrUndefined(s3FileService, 's3FileService');
        Assert.notNullOrUndefined(fileDatabaseProvider, 'fileDatabaseProvider');
        this.database = fileDatabaseProvider.provide();
        this.updateSyncCount();
    }

    public put(file: File): Observable<string> {
        Assert.notNullOrUndefined(file, 'file');
        file.id = `${file.id}${this.getFileExtension(file.type)}`;
        const putPromise = this.database.files.put(file);
        return from(putPromise).pipe(
            tap(() => this.updateSyncCount())
        );
    }

    public get(id: string, size: FileSize): Observable<File> {
        Assert.notNullOrEmpty(id, 'id');
        const getPromise = this.database.files.get(id);
        return from(getPromise).pipe(
            mergeMap(file => {
                if (file) {
                    return of(file);
                }
                return this.s3FileService.download(id, size);
            })
        );
    }

    public getEinstellungenImage(id: string): Observable<File> {
        Assert.notNullOrEmpty(id, 'id');
        const getPromise = this.database.files.get(id);
        return from(getPromise).pipe(
            mergeMap(file => {
                if (file) {
                    return of(file);
                }
                return this.s3FileService.downloadEinstellungenImage(id);
            })
        );
    }

    public delete(id: string): Observable<void> {
        Assert.notNullOrEmpty(id, 'id');
        const deletePromise = this.database.files.delete(id);
        return from(deletePromise).pipe(
            tap(() => this.updateSyncCount())
        );
    }

    public sync(): Observable<boolean> {
        if (this.syncCountChange.getValue() > 0) {
            this.syncInProgressChange.next(true);

            const files$: Observable<boolean>[] = [];
            const filesPromise = this.database.files.each(file => {
                const upload$ = this.s3FileService.upload(file).pipe(
                    catchError(() => of(false)),
                    flatMap(result => result !== false ? this.delete(file.id).pipe(
                        map(() => true)) : of(false)
                    ));
                files$.push(upload$);
            });
            return from(filesPromise).pipe(
                mergeMap(() => from(files$).pipe(
                    mergeMap(file$ => file$, 1),
                    toArray(),
                    map(results => results.every(x => x)),
                    finalize(() => this.syncInProgressChange.next(false))
                ))
            );
        } else {
            return of(true);
        }
    }

    public syncCount(): Observable<number> {
        return this.syncCountChange;
    }

    public syncInProgress(): Observable<boolean> {
        return this.syncInProgressChange;
    }

    private updateSyncCount(): void {
        this.database.files.count().then(count => {
            this.syncCountChange.next(count);
        });
    }

    private getFileExtension(contentType: string): string {
        if (contentType === 'audio/wav') {
            return '.wav';
        }
        return '';
    }
}
