import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { ViewportOrientation } from '@shared/service/viewport-orientation.service';
import { BehaviorSubject } from 'rxjs';

const VIDEO_CONSTRAINT = {
  audio: false,
  video: {
    width: {
      ideal: 1400
    },
    height: {
      ideal: 800
    }
  }
};

export interface CameraViewOptions {
  imageMimeType?: string;
  imageQuality?: number;
  skipSave?: boolean;
}

export enum CameraViewState {
  Loading = 1,
  Playing = 2,
  ImageTaken = 3,
  Stopped = 4,
  CouldNotStartVideo = 5,
  CouldNotGetDeviceIds = 6,
}

@Component({
  selector: 'app-camera-view',
  templateUrl: './camera-view.component.html',
  styleUrls: ['./camera-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CameraViewComponent implements OnInit, OnDestroy {
  private static deviceId: string;
  private image: HTMLCanvasElement;
  private devices: MediaDeviceInfo[];

  @Input()
  public options: CameraViewOptions;

  @Output()
  public stateChange = new BehaviorSubject<CameraViewState>(CameraViewState.Loading);

  @Output()
  public imageSave = new EventEmitter<File>();

  @ViewChild('video', { static: true })
  public video: ElementRef<HTMLVideoElement>;

  @ViewChild('container', { static: true })
  public container: ElementRef<HTMLElement>;

  public ngOnInit(): void {
    this.initOptions();
    this.getDeviceIds()
      .then(() => this.playVideo())
      .catch(() => this.stateChange.next(CameraViewState.CouldNotGetDeviceIds));
  }

  public ngOnDestroy(): void {
    this.stopVideo();
  }

  public onVideoPlaying(): void {
    this.stateChange.next(CameraViewState.Playing);
  }

  public onVideoLoaded(): void {
    this.video.nativeElement.play();
  }

  public onDelete(): void {
    this.stateChange.next(CameraViewState.Loading);
    this.playVideo();
  }

  public trigger(orientation: ViewportOrientation): void {
    Assert.notNullOrUndefined(orientation, 'orientation');
    const [previewImage, rotatedImage] = this.takePicture(orientation);
    this.stopVideo();

    this.image = rotatedImage || previewImage;
    if (this.options.skipSave) {
      this.save();
    } else {
      this.stateChange.next(CameraViewState.ImageTaken);
      setTimeout(() => this.replaceCanvas(previewImage));
    }
  }

  public flip(): void {
    if (this.stateChange.value !== CameraViewState.Playing) {
      return;
    }

    this.stopVideo();
    const index = this.devices.findIndex(x => x.deviceId === CameraViewComponent.deviceId);
    CameraViewComponent.deviceId = (this.devices[index + 1] || this.devices[0]).deviceId;
    this.playVideo();
  }

  public save(): void {
    if (!this.image) {
      return;
    }

    this.image.toBlob(blob => {
      const file = new File([blob], `picture_${Date.now()}.jpg`, {
        type: this.options.imageMimeType
      });
      this.imageSave.emit(file);
    }, this.options.imageMimeType, this.options.imageQuality);
    this.image = undefined;
  }

  private playVideo(): void {
    if (this.stateChange.value === CameraViewState.Loading
      || this.stateChange.value === CameraViewState.Stopped) {
      this.startVideoElement().catch(() => this.stateChange.next(CameraViewState.CouldNotStartVideo));
    }
  }

  private stopVideo(): void {
    if (this.stateChange.value === CameraViewState.Playing) {
      this.stopVideoElement();
      this.stateChange.next(CameraViewState.Stopped);
    }
  }

  private getDeviceIds(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        if (this.onUpdateDevices(devices)) {
          return resolve();
        }
        return reject();
      }).catch(error => reject(error));
    });
  }

  private onUpdateDevices(devices: MediaDeviceInfo[]): boolean {
    this.devices = devices.filter(device => device.kind === 'videoinput');
    if (this.devices.length <= 0) {
      return false;
    }
    const selected = this.devices.find(x => x.deviceId === CameraViewComponent.deviceId)
      || this.devices.find(x => x.label.toLowerCase().includes('default'));
    CameraViewComponent.deviceId = (selected || this.devices[0]).deviceId;
    return true;
  }

  private startVideoElement(): Promise<void> {
    const constraints: MediaStreamConstraints = { ...VIDEO_CONSTRAINT };
    (<MediaTrackSettings>constraints.video).deviceId = CameraViewComponent.deviceId;
    return new Promise<void>((resolve, reject) => {
      navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        this.onGetUserMedia(stream);
        resolve();
      }).catch(error => reject(error));
    });
  }

  private onGetUserMedia(stream: MediaStream): void {
    this.video.nativeElement.srcObject = stream;
    this.video.nativeElement.onloadedmetadata = () => {
      this.video.nativeElement.play();
      this.video.nativeElement.onloadedmetadata = undefined;
    };
  }

  private stopVideoElement(): void {
    if (this.video.nativeElement.srcObject) {
      const tracks = (<MediaStream>this.video.nativeElement.srcObject).getTracks();
      tracks.forEach(track => track.stop());
      this.video.nativeElement.srcObject = undefined;
    }
  }

  private replaceCanvas(canvas: HTMLCanvasElement): void {
    canvas.style.maxWidth = '100%';
    this.container.nativeElement.replaceChild(canvas, this.container.nativeElement.children[0]);
  }

  private takePicture(orientation: ViewportOrientation): HTMLCanvasElement[] {
    const previewImage = this.takePreviewImage();
    if (orientation === ViewportOrientation.Portrait) {
      return [previewImage];
    }

    const rotatedImage = this.takeRotatedImage();
    return [previewImage, rotatedImage];
  }

  private takePreviewImage(): HTMLCanvasElement {
    const canvas = <HTMLCanvasElement>this.container.nativeElement.children[0];
    const ctx = canvas.getContext('2d');

    const { videoWidth, videoHeight } = this.video.nativeElement;

    canvas.width = videoWidth;
    canvas.height = videoHeight;

    ctx.translate(0, 0);

    ctx.drawImage(this.video.nativeElement, 0, 0, videoWidth, videoHeight);
    return canvas;
  }

  private takeRotatedImage(): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const { videoWidth, videoHeight } = this.video.nativeElement;

    canvas.width = videoHeight;
    canvas.height = videoWidth;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(-90 * Math.PI / 180);

    ctx.drawImage(this.video.nativeElement, -videoWidth / 2, -videoHeight / 2, videoWidth, videoHeight);
    return canvas;
  }

  private initOptions(): void {
    this.options = this.options || {};
    this.options.imageMimeType = this.options.imageMimeType || 'image/jpeg';
    this.options.imageQuality = this.options.imageQuality || 0.92;
    this.options.skipSave = this.options.skipSave || false;
  }
}
