import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import {
  GetAcProdukteData,
  getAlphaControllerProdukte
} from '@data/domain/graphql/queries';
import { FahrzeugAntriebsart, FahrzeugLaufleistungEinheit, FahrzeugSchadstoffklasse } from '@data/domain/schema/enum';
import { Fahrzeug, Produkt } from '@data/domain/schema/type';
import { from, Observable, of } from 'rxjs';
import { catchError, flatMap, map, timeout } from 'rxjs/operators';

export enum AC_Vorgang_Status {
    None = 0,
    Offen = 1,
    InBearbeitung = 2,
    Geschlossen = 3
}

export enum AcProduktStatus {
    None = 0,
    InBearbeitung = 1,
    Abgeschlossen = 2,
    Versendet = 3
}

export enum AcFahrzeugScheckheft {
    KeineAngabe = 0,
    NichtVollstaendig = 1,
    NichtVorhanden = 2,
    OhneEintraege = 3,
    Vollstaendig = 4
}

export interface AcProdukt extends Produkt {
  acMetaInformation?: AcMetaInformation;
}

export interface AcMetaInformation {
    id: string; // produktId
    xmlErstelldatum?: string;
    version?: string;
    subdomain?: string;
    xmlVersion?: number;
    globaleId?: string;
    fahrzeugId?: number;
    sender?: string;
    importMode?: string;
    acVorgangStatus?: AcProduktStatus;
}

export interface AcFahrzeug extends Fahrzeug {
    acBauform?: string; // fahrzeugart + bauform
    acSchadstoffklasse?: string; // pollutionClass aus AC als String übernehmen
    acGetriebeart?: string; // gear
    acScheckheft?: AcFahrzeugScheckheft; // checkbook
    acUnfallfahrzeug?: boolean; // accidentalVehicle
    acBaujahr?: number; // constructionYear
    acAntriebstechnologie?: string; // fuelType
}


// altes Schema -> erstmal drin lassen wegen der Notizen
export interface AC_Vorgang {
    // metadaten oder Auftragsdaten des Vorgangs -> Kundendaten?
    vorgangId: string; // eigene ID
    acGlobalId: string; // globalId
    produktId?: string; // id des EM Produkts, falls bereits erstellt
    sender: string; // sender
    erstellDatum: string;
    status: AC_Vorgang_Status;
    acStatus?: number; // status des Auftrags im AC 2 = Bewertung, 5 = Nachbewertung
    acFahrzeug: AC_Fahrzeug;
}

export interface AC_Fahrzeug {
    fahrzeug: {
        kennzeichen: string; // licensePlate
        identnummer: string; // vin
        erstzulassung: string; // firstPermission
        typecode?: string; // kba erster teil
        herstellercode?: string; // kba zweiter teil
        modell?: string; // model
        untertyp?: string; // vehicleType
        bauform?: string; // build -> wird als string gespeichert mit fahrzeugart + bauform
        hersteller?: string; // manufacturer
        laufleistung?: string; // mileageAccordingToCustomer
        laufleistungEinheit?: FahrzeugLaufleistungEinheit; // immer km
        laufleistungBeschreibung?: string; // auf "abgelesen" setzen und keine weiteren Laufleistungen
        hubraum?: number; // ccm
        leistung?: number; //kw
        leergewicht?: number; // unloadedWeight
        zulaessigesGesamtgewicht?: number; // permissableTotalWeight
        lackcode?: string; // carPaintColor + carPaint (e.g. basaltgrau Metallic)
        polsterfarbe?: string; // cussionColor
        polstermaterial?: string; // cussion
        schadstoffklasse?: FahrzeugSchadstoffklasse; // pollutionClass
        anzahlTueren?: number; // doors
        anzahlSitze?: number; // seats
        getriebeart?: number; // gear (string)
        anzahlGaenge?: number; // numberGears
        zylinder?: number; // cylinder
        anzahlVorbesitzer?: number; // previousOwnerAccordingToCustomer, previousOwnerAccordingToRegistrationCertificatePart2-> nichts übernehmen
        scheckheftGepflegt?: number; // checkbook (number) enumValues?


        id: string;
        innenausstattung?: string; // ?
        masseLaenge?: number; // ?
        masseBreite?: number; // ?
        masseHoehe?: number; // ?
        antriebsart?: FahrzeugAntriebsart; // ? -> kommt als String in der Ausstattung -> wir übernehmen nichts
    };
    ausstattung?: AC_Ausstattung;
    schaden?: AC_Schaden;
}

export interface AC_Schaden {
    positionen?: AC_SchadenPosition[];
}

export interface AC_SchadenPosition {
    beschaedigungsart?: string; // to be refined -> xml "what" ist nur ein string mit Schadensbeschreibung z.B. "Kratzer auf Motorhaube"
    reparaturweg?: string; // entspricht xml "what2"
    preis?: number; // entspricht xml "costPublic"
    minderwert?: number; // entspricht xml "costLossInValue"
}

export interface AC_Ausstattung {
    gruppen: AC_AusstattungGruppe[];
}

export interface AC_AusstattungGruppe {
    name: string;
    sonderausstattung: boolean;
    teile: AC_AusstattungTeil[];
}

export interface AC_AusstattungTeil {
    name: string;
    vorhanden: boolean;
}

const GET_NETWORK_TIMEOUT = 1000 * 5;

@Injectable({
    providedIn: 'root'
})
export class AlphaControllerVorgangService {

    private vorgaenge: AC_Vorgang[];

    constructor(private http: HttpClient,
                private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider) {
        // test
        this.getVorgaenge_Mock().subscribe(data => {
            // console.log(data);
        });
        this.getVorgangById_Mock('2').subscribe(data => {
            // console.log('Vorgang mit ID 2: ', data);
        });
    }

    // old
    public getVorgaenge_Mock(): Observable<AcProdukt[]> {
        return this.http.get('assets/mock/ac-produkte-mock.json') as Observable<AcProdukt[]>;
    }

    public getVorgangById_Mock(vorgangsId: string) {
        return this.getVorgaenge_Mock().pipe(
            map(vorgaenge => vorgaenge.find(vorgang => vorgang.id === vorgangsId))
        );
    }

    // new
    public getAcProdukteMock(): Observable<AcProdukt[]> {
        return this.http.get('assets/mock/ac-produkte-mock.json') as Observable<AcProdukt[]>;
    }

    public getAcProduktByIdMock(produktId: string) {
        return this.getAcProdukteMock().pipe(
            map(produkte => produkte.find(produkt => produkt.id === produktId))
        );
    }

    public getVorgaenge(identnummer?: string): Observable<Produkt[]> {
      return this.query<GetAcProdukteData, Produkt[]>({
        query: getAlphaControllerProdukte,
        variables: {identnummer}
      }, response => response.getAlphaControllerProdukte, GET_NETWORK_TIMEOUT);
    }

    public getVorgangById() {
        // api aufruf
    }


  private query<TResponse, TResult>(options: any, get: (response: TResponse) => TResult, due: number): Observable<TResult> {
    const client = this.awsAppSyncClientProvider.provide();
    client.hydrated();

    const cache$ = from(client.query<TResponse>({
      ...options,
      fetchPolicy: 'cache-only'
    }));
    const network$ = from(client.query<TResponse>({
      ...options,
      fetchPolicy: 'network-only'
    }));

    return cache$.pipe(
      flatMap(cache => {
        if (cache && cache.data && get(cache.data)) {
          return network$.pipe(
            timeout(due),
            catchError(() => of(cache)));
        } else {
          return network$;
        }
      }),
      map(response => get(response.data))
    );
  }
}
