<ng-container *ngFor="let form of forms;trackBy:trackByField">
    <ng-container [ngSwitch]="form.name">
        <!-- Fahrzeug -->
        <ng-container *ngSwitchCase="'fahrzeug'">
            <app-produkt-detail-fahrzeug-form [form]="form.form" [name]="form.name" [fields]="form.fields" [vinDisabled]="isVtiTooling()"
                [visibleFields]="form.visibleFields" [fluid]="false" (abrechnungResolved)="onAbrechnungResolved($event)"
                (ausstattungResolved)="onAusstattungResolved($event)">
            </app-produkt-detail-fahrzeug-form>
        </ng-container>
    </ng-container>
</ng-container>