import { Injectable } from '@angular/core';
import { Lackmessung } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import {
    ProduktDetailTextbausteinFormViewFactory
} from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { DecimalSeperatorFormatterService } from '@shared/service/form-controls/decimal-separator-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailLackmessungMessungFormViewFactory } from './produkt-detail-lackmessung-messung-form-view.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailLackmessungFormViewFactory extends FormViewModelBaseFactory<Lackmessung> {

    constructor(
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory,
        private readonly decimalSeperatorFormatter: DecimalSeperatorFormatterService,
        private readonly messungFormViewFactory: ProduktDetailLackmessungMessungFormViewFactory) {
        super();
        Assert.notNullOrUndefined(messungFormViewFactory, 'messungFormViewFactory');
    }

    protected createField(model: Lackmessung, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Lackmessung.fields;
        switch (name) {
            case fields.GesamtKarosserieVon.name:
                return new ViewFormControl(model.gesamtKarosserieVon, {
                  formatter: this.decimalSeperatorFormatter
                });
            case fields.GesamtKarosserieBis.name:
                return new ViewFormControl(model.gesamtKarosserieBis, {
                  formatter: this.decimalSeperatorFormatter
                });
            case fields.UntersuchungsErgebnis.name:
                return new ViewFormControl(model.untersuchungsErgebnis);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: Lackmessung, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Lackmessung.fields;
        switch (arrayName) {
            case fields.Messungen.name:
                const messungen = model.messungen || [];
                return new ViewFormArray(messungen.map(
                    messung => this.messungFormViewFactory
                        .create(messung, arrayFields)
                ));
            case fields.Beschreibungen.name:
                const beschreibungen = model.beschreibungen || [];
                return new ViewFormArray(
                    beschreibungen.map(
                        bemerkung => this.produktDetailTextbausteinFormViewFactory
                            .create(bemerkung, arrayFields)
                    ));
            default:
                throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
