import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { VtiAbschluss } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailVtiAbschlussFormViewFactory extends FormViewModelBaseFactory<VtiAbschluss> {

    constructor() {
        super();
    }

    protected createField(model: VtiAbschluss, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.VtiAbschluss.fields;
        if (name === fields.Adresse.name) {
            return new ViewFormControl('', {
                validators: Validators.required
            });
        } else {
            throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
