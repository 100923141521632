import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, Input, OnDestroy, QueryList } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { TrackBy } from '@shared/helper/track-by';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ExpansionPanelComponent } from '../expansion-panel/expansion-panel.component';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public trackByInstance = TrackBy.trackByInstance;

  @Input()
  public width = '25rem';

  @Input()
  public multi = false;

  @Input()
  public set active(active: number) {
    this.active$.next(active);
  }

  @ContentChildren(ExpansionPanelComponent)
  public panels: QueryList<ExpansionPanelComponent>;

  public panels$ = new BehaviorSubject<ExpansionPanelComponent[]>([]);
  public active$ = new BehaviorSubject<number>(-1);

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.panels$.next(this.panels.toArray());
    }, 1);
    this.subscriptions.push(
      this.panels.changes.subscribe(() => this.updatePanels$())
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  public onPanelOpened(panel: ExpansionPanelComponent, active: number): void {
    Assert.notNullOrUndefined(panel, 'panel');
    panel.opened.emit();
    if (this.active$.value !== active) {
      this.active$.next(active);
    }
  }

  public next(): void {
    const active = Math.min(this.panels$.value.length, this.active$.value + 1);
    if (this.active$.value !== active) {
      this.active$.next(active);
    }
  }

  public prev(): void {
    const active = Math.max(0, this.active$.value - 1);
    if (this.active$.value !== active) {
      this.active$.next(active);
    }
  }

  private updatePanels$(): void {
    this.panels$.next(this.panels.toArray());
    this.next();
    this.prev();
  }
}
