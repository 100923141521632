import { Injectable } from '@angular/core';
import { ViewFormControlArrayFormatter } from '@shared/helper/form-controls/view-form-control';

@Injectable({
    providedIn: 'root'
})
export class DecimalSeperatorFormatterArrayService implements ViewFormControlArrayFormatter<number[]> {
    public format(rawValue: number[]): string[] {
        if (rawValue === undefined || rawValue === null) {
            return undefined;
        }
        return rawValue.map(value => value.toLocaleString('de-DE'));
    }

    public parse(viewValue: string[]): number[] {
        if (viewValue === undefined || viewValue === null) {
            return undefined;
        }
        const result: number[] = [];
        viewValue.forEach(value => {
          if (value) {
            const stringValue = value + '';
            const parsedValue = +stringValue.replace(',', '.');
            if (!isNaN(parsedValue)) {
              result.push(parsedValue);
            }
          }
        });
        return result;
    }
}
