import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface AbschlussDownloadResponse {
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class AbschlussDownloadService {
    private readonly restApiUrl: string;

    constructor(
        private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/abschluss/download`;
    }

    public get(produktId: string): Observable<AbschlussDownloadResponse> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        return this.httpClient.get<AbschlussDownloadResponse>(this.restApiUrl, {
            params: { produktId }
        });
    }
}
