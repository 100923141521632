import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DatSearchService {

  private datRequestActive$ = new BehaviorSubject<boolean>(false);

  public getDatRequestActive(): BehaviorSubject<boolean> {
    return this.datRequestActive$;
  }

}

