<div class="template-wrapper">
  <div class="title-container">
    <h1 class="title" mat-dialog-title *ngIf="settings.title">
      {{settings.title | translate}}
    </h1>
    <app-icon-button *ngIf="!settings.disableClose" class="close" mat-button tabindex="-1" mat-dialog-close>
      <app-mat-icon icon="close"></app-mat-icon>
    </app-icon-button>
  </div>

  <div mat-dialog-content class="template-content">
    <ng-container *ngIf="template">
      <ng-container (disableTemplateButton)="disableButton($event)" *ngTemplateOutlet="template, context: { data: settings.templateData }"></ng-container>
    </ng-container>
    <ng-container #container (disableTemplateButton)="disableButton($event)"></ng-container>
  </div>
  <div mat-dialog-actions align="end">
    <app-button [type]="Buttontype.Flat" [color]="'primary'" (click)="onAction(button)"
                *ngFor="let button of settings.buttons;trackBy:trackByKey; let i=index"
                [disabled]="isButtonDisabled(i)">{{button | translate}}</app-button>
  </div>
</div>
