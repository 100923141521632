import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import {
  SchadenBeschaedigungart,
  SchadenIntensitaet,
  SchadenObergruppe,
  SchadenReparaturweg, SchadenUntergruppe
} from '@data/domain/schema/enum';
import { Produkt, SchadenPosition } from '@data/domain/schema/type';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { SchadenMinderwertService } from '@modules/produkt/service/schaden-minderwert.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ObjectValues } from '@shared/helper/values';
import { TemplateButtonDisableEvent } from '@shared/service/template-dialog.service';
import { merge, Subscription } from 'rxjs';

@Component({
  selector: 'app-produkt-detail-schaden-position',
  templateUrl: './produkt-detail-schaden-position.component.html',
  styleUrls: ['./produkt-detail-schaden-position.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailSchadenPositionComponent implements OnInit, OnDestroy, AfterViewInit {

  public feature = PRODUKT_CONFIG_FEATURES.Feststellungen.name;
  public fieldZustand = PRODUKT_CONFIG_FEATURES.Feststellungen.fields.Positionen.fields.Zustand.name;

  private subscriptions: Subscription[] = [];

  public trackByField = TrackBy.trackByField;

  @Input()
  public produkt: Produkt;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public fields: FeatureFields;

  @Input()
  public relativerWert: number;

  @Output()
  public disableTemplateButton = new EventEmitter<TemplateButtonDisableEvent>();

  public obergruppe = new ObjectValues(SchadenObergruppe);
  public obergruppen = [
    'Dach',
    'Innenraum',
    'Interieur',
    'Karosserie',
    'Unterboden',
  ];

  public untergruppe = new ObjectValues(SchadenUntergruppe);
  public untergruppen = [];

  public art = new ObjectValues(SchadenBeschaedigungart);
  public arten = [
    'Abschuerfung',
    'Beklebung',
    'Beule',
    'Brandloch',
    'Delle',
    'Gebrochen',
    'Gerissen',
    'Kratzer',
    'Steinschlag',
    'Verformt',
    'Verschlissen',
  ];

  public reparatur = new ObjectValues(SchadenReparaturweg);
  public reparaturen = [
    'Aufbereiten',
    'Erneuern',
    'Instandsetzen',
    'InstandsetzenUndLackieren',
    'KalkulationNoetig',
    'Oberflaechenlack',
    'Pruefen',
    'Reinigen',
    'SmartRepair',
    'Tupfen',
    'Vermessung',
  ];

  public intensitaet = new ObjectValues(SchadenIntensitaet);
  public hasRelativerWert = false;

  @ViewChild('zustand')
  public zustandElement: TextbausteineComponent;

  private intensitaetControl: AbstractViewFormControl;
  private minderwertControl: AbstractViewFormControl;
  private preisControl: AbstractViewFormControl;

  constructor(
    private readonly fileFieldService: ProduktDetailFileFieldService,
    private readonly textbausteineService: TextbausteineService,
    private readonly schadenMinderwertService: SchadenMinderwertService) {
    Assert.notNullOrUndefined(fileFieldService, 'fileFieldService');
    Assert.notNullOrUndefined(schadenMinderwertService, 'schadenMinderwertService');
  }

  public ngOnInit(): void {
    this.registerMindertwertUpdater();
    this.hasRelativerWert = this.relativerWert > 0 ? true : false;

    this.handleSchadenBestaetigung(this.form.getRawValue());
    this.subscriptions.push(this.form.valueChanges.subscribe(
      value => {
        this.handleSchadenBestaetigung(value);
      }
    ));
  }

  public ngAfterViewInit(): void {
    if (this.form.get(this.fieldZustand) && this.form.get(this.fieldZustand).value.length === 0) {
      this.textbausteineService.prefillWithStandardTextbausteine(this.feature, this.fieldZustand, this.produkt.art, <ViewFormGroup> this.form, this.zustandElement);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  public onFileAdd(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(files, 'files');
    this.fileFieldService.add(field, files);
  }

  public onFileDelete(field: UntypedFormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.remove(field, fileId);
  }

  public onFileUpdate(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(event, 'event');
    this.fileFieldService.update(field, event);
  }

  public onFileReset(field: UntypedFormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.reset(field, fileId);
  }

  public isFileResetable(fileId: string): boolean {
    Assert.notNullOrEmpty(fileId, 'fileId');
    return this.fileFieldService.isUpdateable(fileId);
  }

  public getEmptyFieldsForValidation(): string[] {
    if (!this.form.get('extern')?.value) {
      return [];
    }
    const ret: string[] = [];
    const fields = ['obergruppe', 'untergruppe', 'preis'];

    fields.forEach(field => {
      const value = this.form.get(field)?.value;
      if (value === null || value === undefined) {
        ret.push(field);
      }
    });

    return ret;
  }

  private registerMindertwertUpdater(): void {
    this.minderwertControl = <AbstractViewFormControl>this.form.get('minderwert');
    if (this.minderwertControl) {
      this.preisControl = <AbstractViewFormControl>this.form.get('preis');
      this.intensitaetControl = <AbstractViewFormControl>this.form.get('intensitaet');
      this.addMinderwertToSubscription(this.preisControl, this.intensitaetControl, this.minderwertControl);
    }
  }

  private addMinderwertToSubscription(preisControl: AbstractViewFormControl, intensitaetControl: AbstractViewFormControl, minderwertControl: AbstractViewFormControl): void {
    this.subscriptions.push(
      merge(preisControl.valueChanges, intensitaetControl.valueChanges).subscribe(() => {
        const preis = preisControl.getRawValue();
        const sollMinderwertBerechnetWerden = (preis > 0);

        if (sollMinderwertBerechnetWerden) {
          let berechneterMinderwert = 0;

          if (this.hasRelativerWert) {
            berechneterMinderwert = this.schadenMinderwertService.getMinderwertRelativerWert(preis, this.relativerWert);
          }

          const intensitaet = intensitaetControl.getRawValue();
          const istIntensitaetvorhanden = (intensitaet !== undefined);
          if (istIntensitaetvorhanden) {
            berechneterMinderwert = this.schadenMinderwertService.getMinderwertIntensitaet(preis, intensitaet);

          }
          if (berechneterMinderwert > 0) {
            minderwertControl.setRawValue(berechneterMinderwert);
          }
        }
      })
    );
  }

  private handleSchadenBestaetigung(value: SchadenPosition): void {
    if (!value) {
      return;
    }
    if (value.extern) {
      if (value.obergruppe && value.untergruppe && value.preis) {
        this.form.get('bestaetigt')?.setValue(true, { emitEvent: false });
        this.disableTemplateButton.emit({ index: 1, disable: false });
      } else {
        this.form.get('bestaetigt')?.setValue(false, { emitEvent: false });
        this.disableTemplateButton.emit({ index: 1, disable: true });
      }
    }
  }

  public onRelativerWertClicked(): void {
    const preis = this.preisControl.getRawValue();
    if (!preis) {
      return;
    }
    this.intensitaetControl.setRawValue(0);
    this.minderwertControl.setRawValue(this.schadenMinderwertService.getMinderwertRelativerWert(preis,
      this.relativerWert));
  }
}
