import { ViewFormControlFormatter } from './view-form-control';

export class ViewFormControlFormatters {
    public static toUppercase: ViewFormControlFormatter<string> = {
        format: (value: string) => `${value || ''}`.toUpperCase(),
        parse: value => value,
    };

    public static toLowercase: ViewFormControlFormatter<string> = {
        format: (value: string) => `${value || ''}`.toLowerCase(),
        parse: value => value,
    };

    public static firstLetterToUppercase: ViewFormControlFormatter<string> = {
        format: (value: string) => {
            const stringify = `${value || ''}`;
            if (stringify.length > 1) {
                return `${stringify[0].toUpperCase()}${stringify.substr(1)}`;
            }
            return stringify.toUpperCase();
        },
        parse: value => value,
    };
}
