import { Injectable } from '@angular/core';
import { AcProdukt, AlphaControllerVorgangService } from '@data/api-gateway/service/alphacontroller/ac-vorgang.service';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AcProduktUebersichtResolveService {

    private acProdukte: AcProdukt[];

    constructor(
        private readonly alphaControllerVorgangService: AlphaControllerVorgangService,
        private readonly produktService: ProduktService) {
        Assert.notNullOrUndefined(produktService, 'produktService');
    }

    public get(): AcProdukt[] {
        return this.acProdukte;
    }

    public resolve(identnummer?: string): Observable<AcProdukt[]> {
        return this.alphaControllerVorgangService.getVorgaenge(identnummer).pipe(
            tap(acProdukte => this.acProdukte = acProdukte)
        );
    }
}
