import { NgModule } from '@angular/core';
import { GtueMaterialModule } from '@gtue/material';
import { SharedModule } from '@shared/shared.module';
import { EinstellungenBenutzerFormComponent } from './form/einstellungen-benutzer-form/einstellungen-benutzer-form.component';
import { EinstellungenBueroFormComponent } from './form/einstellungen-buero-form/einstellungen-buero-form.component';
import { EinstellungenComponent } from './page/einstellungen/einstellungen.component';

@NgModule({
  declarations: [
    EinstellungenComponent,
    EinstellungenBenutzerFormComponent,
    EinstellungenBueroFormComponent
  ],
  imports: [
    SharedModule,
    GtueMaterialModule
  ]
})
export class EinstellungenModule { }
