<input hidden type="file" [accept]="accept" (change)="onSelected(fileInput)" #fileInput/>
<ng-container [formGroup]="form">
    <mat-form-field>
        <div class="img-container" *ngIf="(control.valueChanges | async) || control.value as value">
            <img class="img" [src]="img ? img : value" alt="">
            <app-button class="delete-button" (click)="onClear()">
                <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
            </app-button>
        </div>
        <input matInput [formControlName]="name">
        <span matSuffix>
            <button mat-button class="select-button" (click)="onSelect(fileInput)">
                <mat-icon>image_search</mat-icon>
            </button>
        </span>
        <mat-error
            *ngIf="!(control.validChanges | async) && (control.hasError('required') || control.hasError('pattern'))">
            {{ control.hasError('pattern')
                    ? ('control.pattern' | translate:{name: (displayName | translate)})
                    : ('control.required' | translate:{name: (displayName | translate)})
            }}
        </mat-error>
    </mat-form-field>
</ng-container>
