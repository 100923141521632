import { Injectable } from '@angular/core';
import { Restwertprognose } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import {
  ThousandsSeperatorFormatterService
} from '@shared/service/form-controls/thousands-seperator-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
  providedIn: 'root'
})
export class ProduktDetailRestwertprognoseFormViewFactory extends FormViewModelBaseFactory<Restwertprognose> {

  constructor(
    private readonly thousandsSeperatorFormatterService: ThousandsSeperatorFormatterService,
    private readonly currencyFormatterService: CurrencyFormatterService) {
    super();
    Assert.notNullOrUndefined(currencyFormatterService, 'currencyFormatterService');
  }

  protected createField(model: Restwertprognose, name: string): AbstractViewFormControl {
    const fields = PRODUKT_CONFIG_FEATURES.Restwertprognose.fields;
    switch (name) {
      case fields.Bezugsdatum.name:
        return new ViewFormControl(model.bezugsdatum);
      case fields.Vertragsnummer.name:
        return new ViewFormControl(model.vertragsnummer);
      case fields.Vertragsbeginn.name:
        return new ViewFormControl(model.vertragsbeginn);
      case fields.Vertragsende.name:
        return new ViewFormControl(model.vertragsende);
      case fields.VereinbarteKilometer.name:
        return new ViewFormControl(model.vereinbarteKilometer, {
          formatter: this.thousandsSeperatorFormatterService
        });
      case fields.TatsaechlicheKilometer.name:
        return new ViewFormControl(model.tatsaechlicheKilometer, {
          formatter: this.thousandsSeperatorFormatterService
        });
      case fields.DifferenzKilometer.name:
        return new ViewFormControl(model.differenzKilometer, {
          formatter: this.thousandsSeperatorFormatterService
        });
      case fields.DatDossierId.name:
        return new ViewFormControl(model.datDossierId);
      case fields.RestwertNetto.name:
        return new ViewFormControl(model.restwertNetto);
      case fields.RestwertBrutto.name:
        return new ViewFormControl(model.restwertBrutto);
      case fields.Dokument.name:
        return new ViewFormControl(model.dokument);
      default:
        throw new Error(`Could not create field for name: '${name}'.`);
    }
  }
}
