import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { Assert } from '@shared/helper/assert';

export interface FileDialogSettings {
  title: string;
  fileId: string;
  buttons?: string[];
}

@Component({
  selector: 'app-file-dialog',
  templateUrl: './file-dialog.component.html',
  styleUrls: ['./file-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDialogComponent {
  public Buttontype = ButtonType;

  constructor(
    public readonly dialogRef: MatDialogRef<FileDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly settings: FileDialogSettings) {
    Assert.notNullOrUndefined(settings, 'settings');
  }

  public onAction(button: string): void {
    this.dialogRef.close({
      name: button,
      data: this.settings
    });
  }
}
