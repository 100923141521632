import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveUnterlagen, SaveUnterlagenData } from '../../graphql/mutations';
import { Produkt, Unterlagen, UnterlagenInput } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktUnterlagenService extends ProduktFeatureService<Unterlagen, UnterlagenInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Unterlagen): UnterlagenInput {
        const input: UnterlagenInput = {
            ...feature,
            id: produktId,
            positionen: (feature.positionen || []).map((position, index) => ({
                    ...position,
                    id: `${produktId}:${index}`
                }))
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveUnterlagen;
    }

    protected getSaveVariables(input: UnterlagenInput): any {
        return {
            unterlagen: input
        };
    }

    protected getSaveOptimisticResponse(input: UnterlagenInput): any {
        return {
            saveUnterlagen: {
                ...input,
                __typename: 'Unterlagen',
                positionen: (input.positionen || []).map(position => ({
                        ...position,
                        __typename: 'UnterlagenPosition'
                    }))
            }
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveUnterlagenData>): Unterlagen {
        return response.data.saveUnterlagen;
    }

    protected update(produkt: Produkt, feature: Unterlagen): void {
        produkt.unterlagen = {
            ...produkt.unterlagen,
            ...feature
        };
    }
}
