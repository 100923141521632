import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-expansion-header',
  templateUrl: './expansion-header.component.html',
  styleUrls: ['./expansion-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionHeaderComponent {
}
