import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';

@Component({
  selector: 'app-signout',
  template: `<h2 class="center">{{'auth.logout' | translate}}</h2>`,
  styleUrls: ['./signout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignoutComponent implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly snackBarService: SnackBarService) {
    Assert.notNullOrUndefined(authService, 'authService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
  }

  public ngOnInit(): void {
    this.authService.completeSignout().subscribe({
      error: () => this.snackBarService.error('auth.completeSignoutError')
    });
  }
}
