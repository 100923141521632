import { Injectable } from '@angular/core';
import { TableTotalCalculator } from '@shared/component/data-table';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailWerteTotalService implements TableTotalCalculator {
    public calculate(rows: ViewFormGroup[], round = true): number {
        const total = rows.reduce((cx, cy) => {
            const value = cy.getRawValue();
            return cx + (value.sign === '-' ? -value.wert : value.wert);
        }, 0);

        if (round) {
            return Math.round(total / 10) * 10;
}

        return total;
    }
}
