import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum ViewportOrientation {
    Portrait,
    Landscape
}

const EVENT_THROTTLE = 1000;

@Injectable({
    providedIn: 'root'
})
export class ViewportOrientationService {
    private readonly onDeviceOrientationEventFn = this.onDeviceOrientationEvent.bind(this);
    private readonly viewportOrientationChange = new BehaviorSubject(ViewportOrientation.Portrait);
    private viewportOrientation = ViewportOrientation.Portrait;

    constructor() {
        this.registerDeviceOrientationEvent();
    }

    public observe(): Observable<ViewportOrientation> {
        return this.viewportOrientationChange;
    }

    private onDeviceOrientationEvent(event: DeviceOrientationEvent): void {
        const newViewportOrientation = this.detectIsLandscape(event)
            ? ViewportOrientation.Landscape
            : ViewportOrientation.Portrait;
        if (newViewportOrientation === this.viewportOrientation) {
            this.unregisterDeviceOrientationEvent();
            setTimeout(() => this.registerDeviceOrientationEvent(), EVENT_THROTTLE);
        } else {
            this.viewportOrientation = newViewportOrientation;
            this.viewportOrientationChange.next(this.viewportOrientation);
        }
    }

    private detectIsLandscape(event: DeviceOrientationEvent): boolean {
        const gammaThreshold = 40;
        const gamma = Math.abs(event.gamma);
        if (gamma <= gammaThreshold) {
            return false;
        }
        const betaMax = 180;
        const betaThreshold = 35;
        const beta = Math.abs(event.beta);
        return beta > (betaMax - betaThreshold) || beta < betaThreshold;
    }

    private registerDeviceOrientationEvent(): void {
        window.addEventListener('deviceorientation', this.onDeviceOrientationEventFn, false);
    }

    private unregisterDeviceOrientationEvent(): void {
        window.removeEventListener('deviceorientation', this.onDeviceOrientationEventFn);
    }
}
