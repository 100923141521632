import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, OnDestroy, QueryList } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ChipComponent } from '../chip/chip.component';

@Component({
  selector: 'app-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipListComponent implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @ContentChildren(ChipComponent)
  public chips: QueryList<ChipComponent>;

  public chips$ = new BehaviorSubject<ChipComponent[]>([]);

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateChips$();
    }, 1);
    this.subscriptions.push(
      this.chips.changes.subscribe(() => this.updateChips$())
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  private updateChips$(): void {
    this.chips$.next(this.chips.toArray());
  }
}
