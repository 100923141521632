import { Injectable } from '@angular/core';
import { Uebersicht } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailUebersichtSonstigesFormViewFactory } from '@modules/produkt/factory/produkt-detail-uebersicht-sonstiges-form-view.factory';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from './form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailUebersichtFormViewFactory extends FormViewModelBaseFactory<Uebersicht> {

  constructor(private readonly sonstigesFormViewFactory: ProduktDetailUebersichtSonstigesFormViewFactory) {
    super();
  }

  protected createField(model: Uebersicht, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Uebersicht.fields;
        switch (name) {
            case fields.Deckblatt.name:
                return new ViewFormControl(model.deckblatt);
            case fields.VorneLinks.name:
                return new ViewFormControl(model.vorneLinks || []);
            case fields.VorneRechts.name:
                return new ViewFormControl(model.vorneRechts || []);
            case fields.HintenLinks.name:
                return new ViewFormControl(model.hintenLinks || []);
            case fields.HintenRechts.name:
                return new ViewFormControl(model.hintenRechts || []);
            case fields.Innenraum.name:
                return new ViewFormControl(model.innenraum || []);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

  public createArray(model: Uebersicht, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
    const fields = PRODUKT_CONFIG_FEATURES.Uebersicht.fields;
    if (arrayName === fields.Sonstiges.name) {
        const sonstiges = model.sonstiges || [];
        return new ViewFormArray(
            sonstiges.map(
                sonstige => this.sonstigesFormViewFactory
                    .create(sonstige, arrayFields)
            ));
    } else {
        throw new Error(`Could not create array for name: '${arrayName}'.`);
    }
  }
}
