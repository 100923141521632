import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TrackBy } from '@shared/helper/track-by';
import { Values } from '@shared/helper/values';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent {
  public trackByKey = TrackBy.trackByKey;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public displayName: string;

  @Input()
  public values: Values;

  @Input()
  public valuePrefix = '';

  @Input()
  public disabled: any = {};

  @Input()
  public inline = true;
}
