import { NgModule } from '@angular/core';
import { VerwaltungComponent } from '@modules/verwaltung/page/verwaltung/verwaltung.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    VerwaltungComponent
  ],
  imports: [
    SharedModule
  ]
})
export class VerwaltungModule { }
