import { Assert } from '@shared/helper/assert';

export interface Values {
    keys: any[];
    values: {
        [key: string]: any;
    };
}

export class EnumValues implements Values {
    public keys: number[];
    public values: {
        [key: string]: any;
    };

    constructor(enumObject: any, start = 1) {
        Assert.notNullOrUndefined(enumObject, 'enumObject');
        this.keys = Object.keys(enumObject).filter(x => !isNaN(Number(x))).map(Number).slice(start);
        this.values = enumObject;
    }
}

export class ObjectValues implements Values {
    public keys: string[];
    public values: {
        [key: string]: string;
    };

    constructor(values: {
        [key: number]: string;
    }) {
        this.keys = Object.keys(values);
        this.values = values;
    }
}

export class ArrayValues implements Values {
    public keys: string[];
    public values: {
        [key: string]: string;
    };

    constructor(keys: string[], values: any) {
        this.keys = keys;
        this.values = {};
        this.keys.forEach(key => {
            this.values[key] = values[key] || key;
        });
    }
}
