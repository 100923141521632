<ng-container [formGroup]="form">
  <div class="label-container">
    <label>{{displayName | translate}} </label>
    <ng-container *ngIf="form.get(name).value as value">
      <span *ngIf="values">{{valuePrefix ? (valuePrefix + values.values[value] | lowercase | translate) : (values.values[value])}}</span>
    </ng-container>
  </div>
  <mat-button-toggle-group [formControlName]="name" [name]="name" tabindex="1" value="{{defaultValue$ | async}}" (change)="change.emit($event)">
    <mat-button-toggle disableRipple="true" class="button" *ngFor="let selection of selections" [value]="selection">
      {{selection}}
    </mat-button-toggle>
  </mat-button-toggle-group>
</ng-container>
