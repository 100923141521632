<h3 *ngIf="title">{{title | translate}}</h3>
<div class="file" *ngFor="let file of files;trackBy:trackByKey">
    <app-mini-fab class="delete" *ngIf="delete.observers.length > 0" (click)="delete.emit(file)">
        <app-mat-icon icon="close"></app-mat-icon>
    </app-mini-fab>
    <app-audio [fileId]="file"></app-audio>
</div>
<ng-container *ngIf="add.observers.length > 0">
    <div class="add">
        <mat-form-field>
            <input matInput #name [placeholder]="'audioGallery.name' | translate">
        </mat-form-field>
        <button mat-mini-fab (click)="onAction(name)" [color]="timerSubscription ? 'accent' : ''"
            [matBadgeHidden]="(timer$ | async) === -1" [matBadge]="(timer$ | async) + 's'">
            <app-mat-icon icon="icon-microphone" [newStyle]="true" class="new-style"></app-mat-icon>
        </button>
    </div>
</ng-container>
