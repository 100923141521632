import { Injectable } from '@angular/core';
import { Fehlteile } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailFehlteilePositionFormViewFactory } from './produkt-detail-fehlteile-position-form-view.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailFehlteileFormViewFactory extends FormViewModelBaseFactory<Fehlteile> {

    constructor(private readonly fehlteilePositionFormViewFactory: ProduktDetailFehlteilePositionFormViewFactory) {
        super();
        Assert.notNullOrUndefined(fehlteilePositionFormViewFactory, 'fehlteilePositionFormViewFactory');
    }

    protected createField(_: Fehlteile, name: string): AbstractViewFormControl {
        throw new Error(`Could not create field for name: '${name}'.`);
    }

    protected createArray(model: Fehlteile, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Fehlteile.fields;
        if (arrayName === fields.Positionen.name) {
            const positionen = model.positionen || [];
            return new ViewFormArray(
                positionen.map(
                    position => this.fehlteilePositionFormViewFactory
                        .create(position, arrayFields)
                ));
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
