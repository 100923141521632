import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { Assert } from '@shared/helper/assert';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Produkt } from '../../schema/type';
import { ProduktService } from '../produkt.service';

export abstract class ProduktFeatureService<TFeature, TFeatureInput> {
    constructor(
        private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider,
        private readonly produktService: ProduktService) {
        Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
        Assert.notNullOrUndefined(produktService, 'produktService');
    }

    public save(produktId: string, feature: TFeature): Observable<TFeature> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrUndefined(feature, 'feature');

        const input = this.mapSaveInput(produktId, feature);
        const client = this.awsAppSyncClientProvider.provide();
        const mutatePromise = client.mutate({
            mutation: this.getSaveMutation(),
            variables: {
                id: produktId,
                ...this.getSaveVariables(input),
            },
            optimisticResponse: this.getSaveOptimisticResponse(input),
            update: (store, response) => this.produktService.updateGetByIdCache(store, produktId, produkt => {
                if (produkt) {
                    const updateFeature = this.getSaveResponse(response);
                    this.update(produkt, updateFeature);
                } else {
                    console.warn('could not retrieve produkt from store.');
                }
                return produkt;
            })
        });
        return from(mutatePromise).pipe(
            map(response => this.getSaveResponse(response))
        );
    }

    protected abstract getSaveMutation(): any;

    protected abstract mapSaveInput(produktId: string, feature: TFeature): TFeatureInput;
    protected abstract getSaveVariables(input: TFeatureInput): any;

    protected abstract getSaveOptimisticResponse(input: TFeatureInput): any;
    protected abstract getSaveResponse(response: any): TFeature;

    protected abstract update(produkt: Produkt, feature: TFeature): void;
}
