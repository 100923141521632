import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-table-column',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableColumnComponent {
  @Input()
  public name: string;

  @Input()
  public displayName: string;

  @Input()
  public sort: boolean;

  @Input()
  public start: 'asc' | 'desc';

  @ContentChild(TemplateRef, { static: true })
  public template: TemplateRef<any>;
}
