import { Injectable } from '@angular/core';
import { FahrzeugLaufleistungEinheit } from '@data/domain/schema/enum';
import { Laufleistung } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import {
    ThousandsSeperatorFormatterService
} from '@shared/service/form-controls/thousands-seperator-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailFahrzeugLaufleistungenFormViewFactory extends FormViewModelBaseFactory<Laufleistung> {

    constructor(
        private readonly thousandsSeperatorFormatterService: ThousandsSeperatorFormatterService) {
        super();
        Assert.notNullOrUndefined(thousandsSeperatorFormatterService, 'thousandsSeperatorFormatterService');
    }

    protected createField(model: Laufleistung, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Laufleistungen.fields;
        switch (name) {
            case fields.Wert.name:
                return new ViewFormControl(model.wert, {
                    formatter: this.thousandsSeperatorFormatterService
                });
            case fields.Einheit.name:
                return new ViewFormControl(model.einheit || FahrzeugLaufleistungEinheit.Km);
            case fields.Beschreibung.name:
                return new ViewFormControl(model.beschreibung);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
