import gql from 'graphql-tag';
import { Produkt, Textbaustein } from '../schema/type';

export const PRODUKT = `` +
   `id
    idParent
    art
    status
    identnummer
    versandArt
    rechnungsArt
    erstelltAm
    anzahlBilder
    auftrag {
      id
      art
      nummer
      vorgangsnummer
      auftragstyp
      bezugsAuftragsnummer
      kommentar
      erstellungsTag
      erstellungsUhrzeit
      besichtigungsTag
      besichtigungsUhrzeit
      fertigstellungsTag
      fertigstellungsUhrzeit
      bemerkungen {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      auftragsbeschreibung {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      besichtigungsbedingungen {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      bemerkung
      hinweise {
        id
        name
        vorhanden
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    vtiAuftrag {
      id
      art
      nummer
      vorgangsnummer
      auftragstyp
      bezugsAuftragsnummer
      bemerkung
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    fahrzeug {
      id
      kennzeichen
      identnummer
      datECode
      typCode
      herstellerCode
      erstzulassung
      letzteZulassung
      laufleistung
      laufleistungEinheit
      laufleistungBeschreibung
      laufleistungen {
        id
        wert
        einheit
        beschreibung
      }
      fahrzeugart
      fahrzeugartManuell
      bauform
      bauformManuell
      hersteller
      modell
      untertyp
      lackcode
      innenausstattung
      polsterfarbe
      polstermaterial
      hubraum
      leistung
      zylinder
      schadstoffklasse
      leergewicht
      zulaessigesGesamtgewicht
      masseLaenge
      masseBreite
      masseHoehe
      anzahlTueren
      anzahlSitze
      antriebstechnologie
      antriebsart
      getriebeart
      anzahlGaenge
      anzahlVorbesitzer
      scheckheftGepflegt
      hvBatterie
      acFahrzeugart
      acBauform
      acLackfarbe
      acLackierung
      acAntriebstechnologie
      acGetriebeart
      acSchadstoffklasse
      acEhemaligeNutzung
      bemerkungen {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      fahrzeugExternalServiceReference {
        id
        source
        identifier
        container
        constructionTime
        vxsData
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    adressen {
      id
      adressen {
        id
        createdAt
        firma
        anrede
        vorname
        name
        strasseNr
        postleitzahl
        ort
        telefon
        mobil
        email
        arten
        externalId
        bestellnummer
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    raeder {
      id
      radSaetze {
        id
        lagerort
        rads {
          id
          radPosition
          hersteller
          reifenTyp
          radFelge
          reifenbreite
          querschnitt
          bauart
          felgengroesse
          tragfaehigkeit1
          geschwindigkeitsindex
          zulaessig
          profil
          felgenhersteller
        }
      }
      notlaufeinrichtung
      bemerkungen {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    nfzRaeder {
      id
      radSaetze {
        id
        lagerort
        achsen {
          id
          seiten {
            id
            achsenRaeder {
              id
              position
              hersteller
              typ
              felge
              reifenbreite
              querschnitt
              bauart
              felgengroesse
              tragfaehigkeit
              geschwindigkeitsindex
              zustand
              profiltiefe
              reifenDruck
              felgenart
              felgenhersteller
            }
            seite
          }
          bewertung
        }
      }
      notlaufeinrichtung
      bemerkungen {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    vorschaden {
      id
      positionen {
        id
        externalId
        obergruppe
        untergruppe
        art
        reparatur
        bilder
        schadendatum
        schadenhoehe
        wertminderung
        extern
        bestaetigt
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    fehlteile {
      id
      positionen {
        id
        bezeichnung
        preis
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    uebersicht {
      id
      deckblatt
      vorneLinks
      vorneRechts
      hintenLinks
      hintenRechts
      innenraum
      sonstiges {
        id
        bilder
        bezeichnung
        bezeichnungen {
          id
          kurztext
          langtext
          verfuegbarkeit
          produkte
          feature
          feld
          tags
          standard
          erstelltAm
          erstelltVon
        }
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    schaden {
      id
      positionen {
        id
        externalId
        obergruppe
        untergruppe
        beschaedigungart
        reparaturweg
        bilder
        intensitaet
        preis
        minderwert
        extern
        bestaetigt
      }
      beschreibungen {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    feststellungen {
      id
      umfaenge {
        id
        obergruppeName
        obergruppe
        beschreibung {
          id
          kurztext
          langtext
          verfuegbarkeit
          produkte
          feature
          feld
          tags
          standard
          erstelltAm
          erstelltVon
        }
      }
      positionen {
        id
        externalId
        obergruppe
        untergruppe
        zustand {
          id
          kurztext
          langtext
          verfuegbarkeit
          produkte
          feature
          feld
          tags
          standard
          erstelltAm
          erstelltVon
        }
        beschaedigungart
        reparaturweg
        bilder
        preis
        extern
        bestaetigt
      }
      beschreibungen {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    ausstattung {
      id
      gruppen {
        id
        name
        sonderausstattung
        teile {
          id
          name
          vorhanden
          externalServiceId
        }
      }
      individualaufbauten {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    unterlagen {
      id
      schluesselFahrzeug
      schluesselAHK
      fernbedienungStandheizung
      positionen {
        id
        bezeichnung
        bilder
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    wartung {
      id
      letzterServiceNichtBekannt
      letzterServiceBei
      letzterServiceFaelligEinheit
      letzterServiceAm
      naechsterServiceFaelligNichtBekannt
      naechsterServiceFaelligIn
      naechsterServiceFaelligEinheit
      naechsterServiceFaelligInZeit
      naechsterServiceFaelligEinheitZeit
      naechsterServiceFaelligAm
      naechsterServiceFaelligKosten,
      naechsterServiceBeschreibung {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      },
      naechsteHauptAbgasUntersuchungNichtBekannt
      naechsteHauptAbgasUntersuchung
      hauptAbgasUntersuchungFaelligKosten
      zahnriemenwechselBei
      zahnriemenwechselFaelligEinheit
      zahnriemenwechselAm
      zahnriemenFaelligKosten
      positionen {
        id
        bezeichnung
        kosten
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    werte {
      id
      bezugsdatum
      roundValue
      haendlereinkaufswert
      haendlereinkaufswertDrucken
      haendlereinkaufswertNetto
      haendlereinkaufswertNettoDrucken
      haendlerverkaufswert
      haendlerverkaufswertBesteuerung
      haendlerverkaufswertDrucken
      haendlerverkaufswertNetto
      haendlerverkaufswertNettoDrucken
      datHvkNettoDifferenz
      datHvkNettoRegel
      aufwendungen
      aufwendungenDrucken
      aufwendungenDetailsDrucken
      haendlereinkaufswertAbzuege
      haendlereinkaufswertAbzuegeDrucken
      haendlereinkaufswertAbzuegeNetto
      haendlereinkaufswertAbzuegeNettoDrucken
      haendlerverkaufswertAbzuege
      haendlerverkaufswertAbzuegeDrucken
      haendlerverkaufswertAbzuegeNetto
      haendlerverkaufswertAbzuegeNettoDrucken
      manuelleWerteDrucken
      manuelleWerte {
        bezeichnung
        wert
      }
      wertAmMarkt
      wertAmMarktBemerkung
      wertAmMarktBemerkungen {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      restwertAusUnfallschaden
      restwertAusUnfallschadenNetto
      neuwert
      neuwertDrucken
      relativerWert
      relativerWertDrucken
      werterhoehendesZubehoer
      werterhoehenderWartungszustand
      werterhoehenderReparaturzustand
      zweiterRadsatzAnteilig
      externalServicesReferenceId
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    vtiWerte {
      id
      bezugsdatum
      haendlereinkaufswert
      haendlereinkaufswertDrucken
      haendlereinkaufswertNetto
      haendlereinkaufswertNettoDrucken
      haendlerverkaufswert
      haendlerverkaufswertBesteuerung
      haendlerverkaufswertDrucken
      haendlerverkaufswertNetto
      haendlerverkaufswertNettoDrucken
      wertAmMarkt
      wertAmMarktBemerkungen {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      neuwert
      neuwertDrucken
      relativerWert
      relativerWertDrucken
      externalServicesReferenceId
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    lackmessung {
      id
      messungen {
        id
        position
        dicke
        ergebnis
        beschreibung
        bilder
        bemerkungen {
          id
          kurztext
          langtext
          verfuegbarkeit
          produkte
          feature
          feld
          tags
          standard
          erstelltAm
          erstelltVon
        }
      }
      gesamtKarosserieVon
      gesamtKarosserieBis
      untersuchungsErgebnis
      beschreibungen {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
      notizen {
        id
        createdAt
        datei
        sprachnachricht
        textnachricht
      }
    }
    anhaenge {
      id
      anhaenge {
        id
        quelle
        bezeichnung
      }
    }
    restwertprognose {
      id
      bezugsdatum
      vertragsnummer
      vertragsbeginn
      vertragsende
      vereinbarteKilometer
      tatsaechlicheKilometer
      differenzKilometer
      datDossierId
      restwertNetto
      restwertBrutto
      dokument
    }
    kalkulation {
      id
      bezugsdatum
      dossierId
      reparaturkostenNetto
      reparaturkostenBrutto
      dokument
    }
    vtiAbschluss {
      id
    }
    acAbschluss {
      id
      acVersendet
    }
    abschluss {
      id
      fazit {
        id
        kurztext
        langtext
        verfuegbarkeit
        produkte
        feature
        feld
        tags
        standard
        erstelltAm
        erstelltVon
      }
    }`;

export interface GetTextbausteineData {
  getTextbausteine: Textbaustein[];
}

export const getTextbausteine = gql`query GetTextbausteine($feature: String, $feld: String) {
  getTextbausteine(feature: $feature, feld: $feld) {
    id,
    kurztext,
    langtext,
    verfuegbarkeit,
    produkte,
    feature,
    feld,
    tags,
    standard,
    erstelltAm,
    erstelltVon,
  }
}`;

// Produkt

export interface GetProdukteData {
  getProdukte: Produkt[];
}

export const getProdukte = gql`query GetProdukte($identnummer: String) {
  getProdukte(identnummer: $identnummer) {
    id
    idParent
    art
    status
    identnummer
    erstelltAm
    anzahlBilder
    auftrag {
      id
      nummer
      vorgangsnummer
      erstellungsTag
    }
    fahrzeug {
      id
      kennzeichen
      identnummer
    }
  }
}`;

export interface GetProduktByIdData {
  getProduktById: Produkt;
}

export const getProduktInfoById = gql`query GetProduktInfoById($id: ID!) {
  getProduktById(id: $id) {
    id
    status,
    identnummer,
    versandArt,
    rechnungsArt,
    erstelltAm
  }
}`;

export const getProduktById = gql(`query GetProduktById($id: ID!) {
  getProduktById(id: $id) {`
  + PRODUKT +
  `}
}`);

export const getProdukteByIdentnummer = gql(`query GetProduktByIdentnummer($identnummer: String) {
  getProdukteByIdentnummer(identnummer: $identnummer) {`
  + PRODUKT +
  `}
}`);

export interface GetProdukteByIdentnummerData {
  getProdukteByIdentnummer: Produkt[];
}

export const getProduktDuplikat = gql(`query GetProduktDuplikat($produktId: String, $duplikatProduktId: String, $produktArtDestination: Int) {
  getProduktDuplikat(produktId: $produktId, duplikatProduktId: $duplikatProduktId, produktArtDestination: $produktArtDestination) {`
  + PRODUKT +
  `}
}`);

export interface GetProduktDuplikatData {
  getProduktDuplikat: Produkt;
}

export interface GetAcProdukteData {
  getAlphaControllerProdukte: Produkt[];
}

export const getAlphaControllerProdukte = gql`query GetAlphaControllerProdukte($identnummer: String) {
  getAlphaControllerProdukte(identnummer: $identnummer) {
    id
    idParent
    art
    status
    identnummer
    erstelltAm
    anzahlBilder
    acMetaInformation {
      xmlErstelldatum
      acVorgangStatus
    }
    auftrag {
      id
      nummer
      vorgangsnummer
      erstellungsTag
    }
    fahrzeug {
      id
      kennzeichen
      identnummer
    }
    adressen {
      id
      adressen {
        id
        createdAt
        firma
        anrede
        vorname
        name
        strasseNr
        postleitzahl
        ort
        telefon
        mobil
        email
        arten
        externalId
        bestellnummer
      }
    }
  }
}`;
