<app-card class="adresse-search-card">
    <div class="form">
        <div class="adresse">
            <ng-container *ngFor="let field of adresseFields;trackBy:trackByField">
                <ng-container [ngSwitch]="field.name">
                    <!-- Firma -->
                    <ng-container *ngSwitchCase="'firma'">
                      <ng-container *ngTemplateOutlet="searchField, context: { field: field, idPrefix: idPrefix }"></ng-container>
                    </ng-container>
                    <!-- Vorname -->
                    <ng-container *ngSwitchCase="'vorname'">
                      <ng-container *ngTemplateOutlet="searchField, context: { field: field, idPrefix: idPrefix }"></ng-container>
                    </ng-container>
                    <!-- Name -->
                    <ng-container *ngSwitchCase="'name'">
                      <ng-container *ngTemplateOutlet="searchField, context: { field: field, idPrefix: idPrefix }"></ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div class="adresse-add">
          <button class="add" type="button" mat-mini-fab color="basic" (click)="onAddAdresseClick(searchForm.getRawValue())">
            <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
          </button>
        </div>
    </div>
</app-card>

<ng-container *ngIf="search$ | async as adressen">
    <ng-container *ngIf="searching$ | async; else results">
        <app-card>
            <app-progress-spinner></app-progress-spinner>
        </app-card>
    </ng-container>
    <ng-template #results>
        <app-card *ngFor="let adresse of adressen">
            <div class="form">
                <div class="adresse">
                    <mat-icon>contact_mail</mat-icon>
                    <div class="name">
                        <span>{{adresse.firma || '-'}}&nbsp;</span>
                        <span>{{adresse.vorname || '-'}}&nbsp;</span>
                        <span>{{adresse.name || '-'}}</span>
                    </div>
                    <div *ngIf="adresse.strasseNr">{{adresse.strasseNr}}</div>
                    <span *ngIf="adresse.postleitzahl">{{adresse.postleitzahl}}&nbsp;</span>
                    <span *ngIf="adresse.ort">{{adresse.ort}}</span>
                    <div *ngIf="adresse.telefon">{{adresse.telefon}}</div>
                    <div *ngIf="adresse.mobil">{{adresse.mobil}}</div>
                    <div *ngIf="adresse.email">{{adresse.email}}</div>
                </div>
                <div class="btn">
                  <button class="add" type="button" mat-mini-fab color="basic" (click)="onAddAdresseClick(adresse)">
                    <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
                  </button>
                </div>
                <app-mini-fab class="delete" color="basic" (click)="onDelete(adresse.es_id)">
                  <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
                </app-mini-fab>
            </div>
        </app-card>
    </ng-template>
</ng-container>


<ng-container *ngFor="let field of fields;trackBy:trackByField">
    <ng-container [ngSwitch]="field.arrayName">
        <!-- Adressen -->
        <ng-container *ngSwitchCase="'adressen'">
          <app-card *ngIf="adressen.length > 0">
            <app-accordion [multi]="true">
              <app-expansion-panel
                *ngFor="let adresse of (form.get(field.arrayName).controls | produktDetailAdressenOrder);trackBy:trackByInstance;index as index;first as first"
                [expanded]="first" [dynamicHeight]="false">
                <app-expansion-header>
                  <app-panel-title class="title">
                    <ng-container>
                      <div class="name"
                           *ngIf="((adresse.get('nameGroup').valueChanges | async) || adresse.value.nameGroup) as nameGroup">
                        <strong>{{nameGroup}}</strong>
                      </div>
                      <div class="arten">
                        <app-chip-list>
                          <app-chip *ngFor="let art of adresse.value.arten" [displayName]="art">
                          </app-chip>
                        </app-chip-list>
                      </div>
                    </ng-container>
                  </app-panel-title>
                  <app-mini-fab class="delete" color="basic" (click)="onRemoveAdresseClick(index)">
                    <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
                  </app-mini-fab>
                </app-expansion-header>
                <app-produkt-detail-adressen-adresse [form]="adresse" [name]="name" [fields]="field.fields">
                </app-produkt-detail-adressen-adresse>
              </app-expansion-panel>
            </app-accordion>
          </app-card>
        </ng-container>
    </ng-container>
</ng-container>

<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)">
</app-produkt-detail-notizen-button>

<!-- Templates -->
<ng-template #searchField let-field="field" let-idPrefix="idPrefix">
  <app-input [form]="searchForm" [id]="idPrefix + field.name" [name]="field.name" [displayName]="name + '.' + field.name"
             type="text" inputmode="text">
    <ng-container appSuffix>
      <app-mini-fab [color]="'basic'">
        <app-mat-icon icon="search"></app-mat-icon>
      </app-mini-fab>
    </ng-container>
  </app-input>
</ng-template>
