<mat-form-field>
  <input matInput #input/>
  <ng-container matSuffix>
    <button mat-button (click)="onAddClick(input)" [disabled]="input.value.length === 0">
      <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
    </button>
  </ng-container>
</mat-form-field>

<ng-container *ngIf="!(loading$ | async);else loading">
  <mat-radio-group #group class="single">
    <div class="list-item key" *ngFor="let value of (textbausteine$ | async);">
      <mat-radio-button [value]="value" (change)="textbausteinEventEmitter.emit(value)">
        {{value.kurztext}}
      </mat-radio-button>

      <div *ngIf="isGTUEStandard(value)" class="show-icon-button">
        <app-icon-button (click)="onShowTextbausteinClicked(value)">
          <app-mat-icon icon="remove_red_eye"></app-mat-icon>
        </app-icon-button>
        <app-icon-button>
          <app-mat-icon svgIcon="gtue_logo"></app-mat-icon>
        </app-icon-button>
      </div>

      <div *ngIf="!isGTUEStandard(value)" class="show-icon-button">
        <app-icon-button (click)="onEditTextbausteinClicked(value)">
          <app-mat-icon icon="edit"></app-mat-icon>
        </app-icon-button>
        <app-icon-button (click)="onDeleteTextbausteinClicked(value)">
          <app-mat-icon icon="delete"></app-mat-icon>
        </app-icon-button>
      </div>
    </div>
  </mat-radio-group>
</ng-container>


<ng-template #loading>
  <app-progress-spinner></app-progress-spinner>
</ng-template>

<!-- Show dialog -->
<ng-template #dialogShow let-data="data">
  <app-textbausteine-dialog-edit [feature]="feature" [feld]="feld" [mode]="editMode.Textbaustein"
                                 [form]="data.form" [data]="data" [feldDisplayName]="feldDisplayName">
  </app-textbausteine-dialog-edit>
</ng-template>

<!-- Edit dialog -->
<ng-template #dialogEdit let-data="data">
  <app-textbausteine-dialog-edit [feature]="feature" [feld]="feld" [mode]="editMode.Verwaltung"
                                 [form]="data.form" [data]="data" [feldDisplayName]="feldDisplayName">
  </app-textbausteine-dialog-edit>
</ng-template>

<!-- Delete dialog -->
<ng-template #dialogDelete>
  <app-delete-dialog>
  </app-delete-dialog>
</ng-template>

