import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import {Values} from '@shared/helper/values';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss']
})
export class ButtonGroupComponent implements OnInit {

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public displayName: string;

  @Input()
  public default: any = null;

  @Input()
  public defaultValue$ = new BehaviorSubject<any>(undefined);

  @Input()
  public selections: any[];

  @Input()
  public values: Values;

  @Input()
  public valuePrefix = '';

  @Output()
  public change = new EventEmitter<MatButtonToggleChange>();

  public ngOnInit(): void {
    this.setDefaultValue();
  }

  private setDefaultValue(): void {
    if (this.default !== null && this.form.get(this.name).value === null) {
      this.form.patchValue({[this.name]: this.default});
    }
  }
}
