<div class="main-content">
  <div class="toggle">
    <app-toggle-group [value]="display" (valueChange)="onDisplayChange($event)">
      <app-toggle [value]="1" [disabled]="modelDisabled$ | async">
        {{name + '.modell' | translate}}
      </app-toggle>
      <app-toggle [value]="2">
        {{name + '.manuell' | translate}}
      </app-toggle>
      <app-toggle [value]="3">
        {{name + '.gesamt' | translate}}
      </app-toggle>
    </app-toggle-group>
  </div>

  <ng-container [ngSwitch]="display">
    <ng-container *ngSwitchDefault>
      <app-produkt-detail-lackmessung-scene [form]="form" [modelFileConfigs]="modelFileConfigs"
        (modelLoad)="onModelLoad($event)" (positionSelect)="onPositionSelect($event)">
      </app-produkt-detail-lackmessung-scene>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <app-card>
        <app-action-list [values]="positionen" [valuePrefix]="name + '.'" (action)="onPositionSelect($event)"
          [searchable]="true">
        </app-action-list>
      </app-card>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <app-card>
        <app-produkt-detail-lackmessung-gesamt [name]="name" [form]="form" [fields]="fields" [produkt]="produkt"
        ></app-produkt-detail-lackmessung-gesamt>
      </app-card>
    </ng-container>
  </ng-container>
</div>

<!-- side pnael -->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop && (rows$ | async) as rows">
  <h4>{{( name + '.messungen' | translate) + '&nbsp;(' + rows.length + ')'}}</h4>
  <ng-container *ngTemplateOutlet="lackmessungMessungen, context: { rows: rows }"></ng-container>
</app-side-panel>

<!-- bottom sheet -->
<ng-container *ngIf="(viewport$ | async) !== viewport.Desktop && (rows$ | async) as rows">
  <app-bottom-sheet [title]="( name + '.messungen' | translate) + '&nbsp;(' + rows.length + ')'" [seamless]="true">
    <ng-container *ngTemplateOutlet="lackmessungMessungen, context: { rows: rows }"></ng-container>
  </app-bottom-sheet>
</ng-container>

<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)">
</app-produkt-detail-notizen-button>

<!-- templates -->
<ng-template #lackmessungMessungen let-rows="rows">

  <div class="header">
    <ng-container *ngFor="let field of messungenFields;trackBy:trackByField">
      <ng-container [ngSwitch]="field.name">
        <ng-container *ngSwitchCase="'position'">
          <div class="group-header">
            <label>{{(name + '.' + field.name) | translate}}</label>
            <label>{{(name + '.' + 'bilder') | translate}}</label>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'dicke'">
          <div class="group-header">
            <label>{{(name + '.' + field.name) | translate}}</label>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'ergebnis'">
          <div class="group-header">
            <label>{{(name + '.' + field.name) | translate}}</label>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'beschreibung'">
          <div class="group-header">
            <label>{{(name + '.' + field.name) | translate}}</label>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <app-drop-list cdkDropList (cdkDropListDropped)="drop($event)">
    <mat-expansion-panel #panel="matExpansionPanel" cdkDrag hideToggle togglePosition="before" multiple="false"
                         *ngFor="let item of messungen.getRawValue();index as index; trackBy:trackByField">
      <mat-expansion-panel-header>

        <div class="panel-header">
        <ng-container *ngFor="let field of messungenFields;trackBy:trackByField">
          <ng-container [ngSwitch]="field.name">

            <ng-container *ngSwitchCase="'position'">
              <div class="group-header">
                <label>{{ (this.name + '.' + positionen.values[item.position]).toLowerCase() | translate}}</label>
                <label>{{item.bilder.length}}</label>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'dicke'">
              <div class="group-header">
                <label>{{item.dicke}}</label>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'ergebnis'">
              <div class="group-header">
                <label>{{item.ergebnis}}</label>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'beschreibung'">
              <div class="group-header">
                <label>{{item.beschreibung}}</label>
              </div>
            </ng-container>

          </ng-container>
        </ng-container>
        </div>

      </mat-expansion-panel-header>
      <!--body of expansion panel-->
      <div class="button-group">
        <app-button class="button edit-button"
                (click)="onRowOpenByIndex(index, item, panel, $event)">
          <mat-icon>edit</mat-icon>
        </app-button>
        <app-button class="button delete-button"
                (click)="onRowRemoveByIndex(index, panel, $event)">
          <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
        </app-button>
      </div>
    </mat-expansion-panel>
  </app-drop-list>

</ng-template>

<!-- dialog -->
<ng-template #dialog let-data="data">
  <app-produkt-detail-lackmessung-messung [form]="data.form" [name]="name" [fields]="data.fields" [produkt]="produkt">
  </app-produkt-detail-lackmessung-messung>
</ng-template>
