<ng-container *ngFor="let field of fields;trackBy:trackByField">
    <ng-container [ngSwitch]="field.name || field.groupName">
        <!-- Hersteller -->
        <ng-container *ngSwitchCase="'hersteller'">
            <ng-container *ngTemplateOutlet="text, context: { field: field }">
            </ng-container>
        </ng-container>
        <!-- ReifenTyp -->
        <ng-container *ngSwitchCase="'typ'">
            <ng-container *ngTemplateOutlet="select, context: { field: field, values: reifentyp }">
            </ng-container>
        </ng-container>
        <!-- ReifenGroup -->
        <ng-container *ngSwitchCase="'reifenGroup'">
            <ng-container *ngTemplateOutlet="reifenGroup">
            </ng-container>
        </ng-container>
        <!-- Profil -->
        <ng-container *ngSwitchCase="'profiltiefe'">
            <ng-container *ngTemplateOutlet="mm, context: { field: field }">
            </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'reifenDruck'">
            <ng-container *ngTemplateOutlet="number, context: { field: field }">
            </ng-container>
          </ng-container>
        <!-- Zulaessig -->
        <ng-container *ngSwitchCase="'zustand'">
            <ng-container *ngTemplateOutlet="select, context: { field: field, values: zulaessig }">
            </ng-container>
        </ng-container>
        <!-- RadFelge -->
        <ng-container *ngSwitchCase="'felge'">
            <ng-container *ngTemplateOutlet="select, context: { field: field, values: felge }">
            </ng-container>
        </ng-container>
        <!-- Felgenhersteller -->
        <ng-container *ngSwitchCase="'felgenhersteller'">
            <ng-container *ngTemplateOutlet="text, context: { field: field }">
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>

<!-- ReifenGroup -->
<ng-template #reifenGroup>
    <app-form-group cols="6">
        <!-- Reifenbreite -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="reifenbreite, context: { field: {
                    name: 'reifenbreite'
                } }">
            </ng-container>
        </app-form-field>
        <!-- Querschnitt -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="querschnitt, context: { field: {
                    name: 'querschnitt'
                } }">
            </ng-container>
        </app-form-field>
        <!-- Bauart -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="select, context: { field: {
                    name: 'bauart'
                 }, values: bauart }">
            </ng-container>
        </app-form-field>
        <!-- Felgengroesse -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="felgengroesse, context: { field: {
                    name: 'felgengroesse'
                } }">
            </ng-container>
        </app-form-field>
        <!-- Tragfaehigkeit -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="tragfaehigkeit, context: { field: {
                    name: 'tragfaehigkeit'
                } }">
        </ng-container>
      </app-form-field>
      <!-- Geschwindigkeitsindex -->
      <app-form-field>
        <ng-container *ngTemplateOutlet="select, context: { field: {
                    name: 'geschwindigkeitsindex'
                }, values: geschwindigkeitsindex }">
        </ng-container>
      </app-form-field>
    </app-form-group>
</ng-template>

<!-- Templates -->
<ng-template #number let-field="field">
  <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
             inputmode="numeric">
  </app-input>
</ng-template>
<ng-template #mm let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
        inputmode="numeric">
        <ng-container appSuffix>&nbsp;mm</ng-container>
    </app-input>
</ng-template>
<ng-template #reifenbreite let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" autoTabLength="3"
        inputmode="numeric">
        <ng-container appSuffix>&nbsp;/</ng-container>
    </app-input>
</ng-template>
<ng-template #querschnitt let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" autoTabLength="2"
        autoTabIndexSkip="2" inputmode="numeric">
        <ng-container appSuffix>&nbsp;/</ng-container>
    </app-input>
</ng-template>
<ng-template #felgengroesse let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" autoTabLength="2"
        inputmode="numeric">
        <ng-container appSuffix>&nbsp;/</ng-container>
    </app-input>
</ng-template>
<ng-template #tragfaehigkeit let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" autoTabLength="3"
        inputmode="numeric">
        <ng-container appSuffix>&nbsp;/</ng-container>
    </app-input>
</ng-template>
<ng-template #text let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text">
    </app-input>
</ng-template>
<ng-template #number let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text"
        inputmode="numeric">
    </app-input>
</ng-template>
<ng-template #select let-field="field" let-values="values">
    <app-select [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [values]="values"
        [valuePrefix]="name + '.'">
    </app-select>
</ng-template>
