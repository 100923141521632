<ng-container *ngIf="position$ | async as position">
    <ng-container *ngIf="scale$ | async as scale">
        <div class="indicator" [class.opaque]="!(visible$ | async)" [style.top]="position.top"
            [style.left]="position.left" [style.transform]="'scale(' + scale + ')'" [style.z-index]="getZIndex(scale)">
            <div class="content-wrapper">
                <ng-content></ng-content>
            </div>
        </div>
    </ng-container>
</ng-container>
