import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SwService } from '@app/service/sw.service';
import { TranslateService } from '@ngx-translate/core';
import { Assert } from '@shared/helper/assert';
import { PullToRefreshService } from '@shared/service/pull-to-refresh.service';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { flatMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  constructor(
    private readonly translate: TranslateService,
    private readonly snackbar: SnackBarService,
    private matIconRegistry: MatIconRegistry,
    private pullToRefreshService: PullToRefreshService,
    private domSanitizer: DomSanitizer,
    private readonly sw: SwService) {
    Assert.notNullOrUndefined(translate, 'translate');
    Assert.notNullOrUndefined(snackbar, 'snackbar');
    Assert.notNullOrUndefined(sw, 'sw');

    this.registerDashboardIcons();
  }

  public ngOnInit(): void {
    this.translate.setDefaultLang('de');
    this.translate.use('de');

    this.sw.available().pipe(
      flatMap(() => this.sw.activateUpdate()),
      flatMap(() => this.snackbar.info('sw.update', null, 'sw.install', null)),
      // eslint-disable-next-line
      tap(() => document.location.reload())
    ).subscribe();
  }

  private registerDashboardIcons(): void {
    this.matIconRegistry.addSvgIcon('bewertung',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/bewertung.svg')
    );
    this.matIconRegistry.addSvgIcon('dynarex-on-demand',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/dynarex-on-demand.svg')
    );
    this.matIconRegistry.addSvgIcon('gtue-classic',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/gtue-classic.svg')
    );
    this.matIconRegistry.addSvgIcon('gtue-modul',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/gtue-modul.svg')
    );
    this.matIconRegistry.addSvgIcon('kfz-haftpflichtschaden',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/kfz-haftpflichtschaden.svg')
    );
    this.matIconRegistry.addSvgIcon('kfz-kaskoschaden',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/kfz-kaskoschaden.svg')
    );
    this.matIconRegistry.addSvgIcon('kostenvoranschlag',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/kostenvoranschlag.svg')
    );
    this.matIconRegistry.addSvgIcon('ruecknahmebewertung',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/ruecknahmebewertung.svg')
    );
    this.matIconRegistry.addSvgIcon('zustandsbericht',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/zustandsbericht.svg')
    );
    this.matIconRegistry.addSvgIcon('product_add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/product_add.svg')
    );
    this.matIconRegistry.addSvgIcon('product_my',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/product_my.svg')
    );
    this.matIconRegistry.addSvgIcon('alphacontroller',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/alphacontroller.svg')
    );
    this.matIconRegistry.addSvgIcon('product_gtue',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/product_gtue.svg')
    );
    this.matIconRegistry.addSvgIcon('settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/settings.svg')
    );
    this.matIconRegistry.addSvgIcon('settings_my',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/settings_my.svg')
    );
    this.matIconRegistry.addSvgIcon('text-module-settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/dashboard/text-module-settings.svg')
    );
    this.matIconRegistry.addSvgIcon('gtue_logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/GTUE_Logo_1c.svg')
    );
  }
}
