<ng-container [formGroup]="form">
    <label [innerHTML]="displayName | translate"></label>
    <mat-radio-group #group [formControlName]="name" class="single">
        <mat-radio-button class="key" *ngFor="let value of visibleValues;trackBy:trackByKey" [value]="value">
            {{value}}
        </mat-radio-button>
        <ng-container *ngIf="((control.valueChanges | async) || control.value) as value">
            <mat-radio-button *ngIf="value && visibleValues.indexOf(value) === -1" [value]="value">
                {{value}}
            </mat-radio-button>
        </ng-container>
    </mat-radio-group>
</ng-container>

<mat-divider></mat-divider>

<button mat-button (click)="onShowListClick()">
    {{'list.more' | translate }}
</button>

<ng-template #list let-data="data">
    <mat-form-field>
        <input matInput #input (keyup)="onFilterKeyUp(input)" />
        <ng-container matSuffix>
            <app-button (click)="onAddClick(input)" [disabled]="input.value.length === 0">
              <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
            </app-button>
        </ng-container>
    </mat-form-field>
    <ng-container *ngIf="filteredValues$ | async as filteredValues">
        <mat-radio-group [(ngModel)]="data.value" (ngModelChange)="onValueChange($event)" class="single">
            <mat-radio-button class="key" *ngFor="let value of filteredValues;trackBy:trackByKey" [value]="value">
                {{value}}
            </mat-radio-button>
        </mat-radio-group>
    </ng-container>
</ng-template>
