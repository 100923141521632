import { NgModule } from '@angular/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {TextbausteineVerwaltenComponent} from '@modules/textbausteine-verwalten/page/textbausteine-verwalten/textbausteine-verwalten.component';
import {MaterialModule} from '@shared/module/material.module';
import { SharedModule } from '@shared/shared.module';
import { TextbausteineVerwaltenListComponent } from './component/textbausteine-verwalten-list/textbausteine-verwalten-list.component';

@NgModule({
  declarations: [
    TextbausteineVerwaltenComponent,
    TextbausteineVerwaltenListComponent
  ],
  imports: [
    SharedModule,
    MaterialModule,
    MatSlideToggleModule
  ]
})
export class TextbausteineModule { }
