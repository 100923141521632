import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Assert } from '@shared/helper/assert';
import { forkJoin, from, Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

const SNACK_BAR_DURATION_DEFAULT = 5 * 1000;

@Injectable({
    providedIn: 'root'
})
export class SnackBarService {
    constructor(
        private readonly matSnackBar: MatSnackBar,
        private readonly translate: TranslateService) {
        Assert.notNullOrUndefined(matSnackBar, 'matSnackBar');
        Assert.notNullOrUndefined(translate, 'translate');
    }

    public error(message: string, messageObject?: Object, action?: string, duration: number = SNACK_BAR_DURATION_DEFAULT): Observable<void> {
        Assert.notNullOrEmpty(message, 'message');
        return this.show(message, messageObject, action, duration, 'error');
    }

    public info(message: string, messageObject?: Object, action?: string, duration: number = SNACK_BAR_DURATION_DEFAULT): Observable<void> {
        Assert.notNullOrEmpty(message, 'message');
        return this.show(message, messageObject, action, duration, 'info');
    }

    public warning(message: string, messageObject?: Object, action?: string, duration: number = SNACK_BAR_DURATION_DEFAULT): Observable<void> {
        Assert.notNullOrEmpty(message, 'message');
        return this.show(message, messageObject, action, duration, 'warning');
    }

    public success(message: string, messageObject?: Object, action?: string, duration: number = SNACK_BAR_DURATION_DEFAULT): Observable<void> {
        Assert.notNullOrEmpty(message, 'message');
        return this.show(message, messageObject, action, duration , 'success');
    }

    private show(message: string, messageObject: Object, action: string, duration: number, panelClass: string): Observable<void> {
        const showPromise = new Promise<void>((resolve, reject) => {
            forkJoin([
                this.translate.get(message, messageObject),
                action ? this.translate.get(action) : of(undefined),
            ]).pipe(
                flatMap(translations => {
                    const ref = this.matSnackBar.open(translations[0], translations[1], {
                        duration,
                        verticalPosition: 'bottom',
                        panelClass: `snack-bar-service-${panelClass}`,
                    });
                    return ref.onAction();
                })
            ).subscribe(() => resolve(), () => reject());
        });
        return from(showPromise);
    }
}
