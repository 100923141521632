import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-mat-icon',
  templateUrl: './mat-icon.component.html',
  styleUrls: ['./mat-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MatIconComponent {

  @HostBinding('style.width')
  public width: string;

  @HostBinding('style.height')
  public height: string;

  public style = {};

  @Input()
  public icon: string;

  @Input()
  public newStyle: boolean;

  @Input()
  public color: string;

  @Input()
  public svgIcon: string;

  @Input()
  public set size(size: string) {
    this.width = size;
    this.height = size;
    this.style = {
      'font-size': size,
      width: size,
      height: size
    };
  }
}
