import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface AbschlussVersandArtPostResponse {
    message?: string;
}

export enum AbschlussVersandArt {
    NoDelivery = 'NoDelivery',
    DeliveryWithoutInvoice = 'DeliveryWithoutInvoice',
    DeliveryWithInvoice = 'DeliveryWithInvoice',
}

@Injectable({
    providedIn: 'root'
})
export class AbschlussVersandService {
    private readonly restApiUrl: string;

    constructor(
        private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/abschluss/lieferart`;
    }

    public post(produktId: string, type: AbschlussVersandArt): Observable<AbschlussVersandArtPostResponse> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrEmpty(type, 'type');

        return this.httpClient.post(this.restApiUrl, {
            produktId,
            type
        });
    }
}
