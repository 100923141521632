import { Injectable } from '@angular/core';

import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProduktDetailFeatureComponent } from '../component/produkt-detail-feature/produkt-detail-feature.component';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailFeatureCanDeactivate  {
    public canDeactivate(
        component: ProduktDetailFeatureComponent): Observable<boolean> {
        Assert.notNullOrUndefined(component, 'component');
        return component.save().pipe(
            map(() => true)
        );
    }
}
