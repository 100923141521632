import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GltfGruppe, parse } from '@app/config/gltf';
import { VorschadenObergruppe } from '@data/domain/schema/enum';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { VORSCHADEN_GRUPPEN } from '@modules/produkt/config/produkt-vorschaden-gruppen.config';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { EnumValues } from '@shared/helper/values';
import { Intersection, Scene } from 'three';

const OBERGRUPPE_STRING_MAP = {
  [VorschadenObergruppe.Frontschaden]: VORSCHADEN_GRUPPEN.find(x => x === 'Frontschaden'),
  [VorschadenObergruppe.SchwellerschadenLinks]: VORSCHADEN_GRUPPEN.find(x => x === 'Schwellerschaden links'),
  [VorschadenObergruppe.SeitenschadenLinks]: VORSCHADEN_GRUPPEN.find(x => x === 'Seitenschaden links'),
  [VorschadenObergruppe.Heckschaden]: VORSCHADEN_GRUPPEN.find(x => x === 'Heckschaden'),
  [VorschadenObergruppe.SchwellerschadenRechts]: VORSCHADEN_GRUPPEN.find(x => x === 'Schwellerschaden rechts'),
  [VorschadenObergruppe.SeitenschadenRechts]: VORSCHADEN_GRUPPEN.find(x => x === 'Seitenschaden rechts'),
  [VorschadenObergruppe.SchadenAmUnterboden]: VORSCHADEN_GRUPPEN.find(x => x === 'Schaden am Unterboden'),
  [VorschadenObergruppe.SchadenAmDach]: VORSCHADEN_GRUPPEN.find(x => x === 'Schaden am Dach')
};

const OBERGRUPPE_TO_MODEL_MAP = new Map<VorschadenObergruppe, GltfGruppe[]>([
  [VorschadenObergruppe.Frontschaden, [
    GltfGruppe.EXT_Motorhaube,
    GltfGruppe.EXT_Scheinwerfereinheit_glas_links,
    GltfGruppe.EXT_Scheinwerfereinheit_glas_rechts,
    GltfGruppe.EXT_Stossfaenger_vorne,
    GltfGruppe.EXT_Windschutzscheibe_Glas,
  ]],
  [VorschadenObergruppe.SchadenAmUnterboden, [
    GltfGruppe.EXT_Unterboden
  ]],
  [VorschadenObergruppe.SchwellerschadenLinks, [
    GltfGruppe.EXT_Schweller_links
  ]],
  [VorschadenObergruppe.SchwellerschadenRechts, [
    GltfGruppe.EXT_Schweller_rechts
  ]],
  [VorschadenObergruppe.SeitenschadenLinks, [
    GltfGruppe.EXT_Aussenspiegel_links,
    GltfGruppe.EXT_Kotfluegel_links,
    GltfGruppe.EXT_Seitenwand_links,
    GltfGruppe.EXT_Tuer_hinten_links,
    GltfGruppe.EXT_Tuer_hinten_links_Glas,
    GltfGruppe.EXT_Tuer_vorne_links,
    GltfGruppe.EXT_Tuer_vorne_links_Glas,
    GltfGruppe.EXT_Felge_hinten_links,
    GltfGruppe.EXT_Felge_vorne_links,
    GltfGruppe.EXT_Reifen_hinten_links,
    GltfGruppe.EXT_Reifen_vorne_links
  ]],
  [VorschadenObergruppe.SeitenschadenRechts, [
    GltfGruppe.EXT_Aussenspiegel_rechts,
    GltfGruppe.EXT_Kotfluegel_rechts,
    GltfGruppe.EXT_Seitenwand_rechts,
    GltfGruppe.EXT_Tuer_hinten_rechts,
    GltfGruppe.EXT_Tuer_hinten_rechts_Glas,
    GltfGruppe.EXT_Tuer_vorne_rechts,
    GltfGruppe.EXT_Tuer_vorne_rechts_Glas,
    GltfGruppe.EXT_Felge_hinten_rechts,
    GltfGruppe.EXT_Felge_vorne_rechts,
    GltfGruppe.EXT_Reifen_hinten_rechts,
    GltfGruppe.EXT_Reifen_vorne_rechts
  ]],
  [VorschadenObergruppe.Heckschaden, [
    GltfGruppe.EXT_Heckklappe,
    GltfGruppe.EXT_Heckklappe_Glas,
    GltfGruppe.EXT_Heckleuchte_glas_links,
    GltfGruppe.EXT_Heckleuchte_glas_rechts,
    GltfGruppe.EXT_Stossfaenger_hinten,
  ]],
  [VorschadenObergruppe.SchadenAmDach, [
    GltfGruppe.EXT_Fahrzeugdach
  ]]
]);

@Component({
  selector: 'app-produkt-detail-vorschaden-scene',
  templateUrl: './produkt-detail-vorschaden-scene.component.html',
  styleUrls: ['./produkt-detail-vorschaden-scene.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailVorschadenSceneComponent {
  private _activeObergruppen: VorschadenObergruppe[] = [];
  private obergruppen = new EnumValues(VorschadenObergruppe);

  public scene: Scene = null;

  @Input()
  public set activeObergruppen(activeObergruppen: VorschadenObergruppe[]) {
    this._activeObergruppen = activeObergruppen;
    this.updateObergruppenState();
  }

  @Input()
  public modelFileConfigs: ModelFileConfig[];

  @Output()
  public obergruppeSelect = new EventEmitter<VorschadenObergruppe>();

  @Output()
  public modelLoad = new EventEmitter<ModelLoadResult>();

  public onSceneLoad(scene: Scene): void {
    Assert.notNullOrUndefined(scene, 'scene');
    this.scene = scene;
    this.updateObergruppenState();
  }

  public onModelLoad(modelLoadResult: ModelLoadResult): void {
    Assert.notNullOrUndefined(modelLoadResult, 'modelLoadResult');
    this.modelLoad.emit(modelLoadResult);
  }

  public onUserTap(tap: Intersection): void {
    Assert.notNullOrUndefined(tap, 'tap');
    const obergruppe = this.getObergruppe(tap);
    if (obergruppe !== undefined) {
      this.obergruppeSelect.emit(<VorschadenObergruppe>obergruppe);
    }
  }

  private updateObergruppenState(): void {
    this.obergruppen.keys.forEach(obergruppe => {
      const state = this._activeObergruppen.indexOf(OBERGRUPPE_STRING_MAP[obergruppe]) !== -1;
      this.setObergruppeState(obergruppe, state);
    });
  }

  private setObergruppeState(obergruppe: VorschadenObergruppe, state: boolean): void {
    if (!this.scene) {
      return;
    }

    const gruppen = OBERGRUPPE_TO_MODEL_MAP.get(obergruppe);
    if (!gruppen) {
      return;
    }

    this.scene.traverse((child: any) => {
      if (!child.isMesh || child.material.transparent || gruppen.indexOf(parse(child.name)) === -1) {
        return;
      }
      if (child.orgMaterial === undefined) {
        child.orgMaterial = child.material.clone();
      }
      if (child.darkMaterial === undefined) {
        child.darkMaterial = child.material.clone();
        child.darkMaterial.envMapIntensity = 0.3;
      }
      child.material = !state ? child.orgMaterial : child.darkMaterial;
    });
  }

  private getObergruppe(tap: Intersection): VorschadenObergruppe {
    if (tap && tap.object) {
      const gruppe = parse(tap.object.name);
      if (gruppe !== undefined) {
        for (const [k, v] of OBERGRUPPE_TO_MODEL_MAP) {
          if (v.indexOf(gruppe) !== -1) {
            return k;
          }
        }
      }
    }
    return undefined;
  }
}
