<ng-container *ngIf="responseChange | async as response">
  <app-accordion>
    <app-expansion-panel *ngFor="let identifier of selectedIdentifiersChange | async">
      <app-expansion-header>
        <app-panel-title>
          <strong>{{ identifier.identifier | translate }}</strong>
          {{ identifier.value }}
        </app-panel-title>
      </app-expansion-header>
    </app-expansion-panel>
    <app-expansion-panel expanded="true">
      <app-expansion-header>
        <app-panel-title>
          <strong>{{nextIdentifierChange | async | translate}}</strong>
        </app-panel-title>
      </app-expansion-header>
      <app-action-list *ngIf="!(requestActiveChange | async)" [values]="identifiersChange | async"
        (action)="onSearchWithNewIdentity($event)">
      </app-action-list>
      <app-progress-spinner *ngIf="requestActiveChange | async"></app-progress-spinner>
    </app-expansion-panel>
  </app-accordion>
</ng-container>