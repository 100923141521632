import { ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { TrackBy } from '@shared/helper/track-by';
import { Subscription } from 'rxjs';
import { ToggleComponent } from '../toggle/toggle.component';

@Component({
  selector: 'app-toggle-group',
  templateUrl: './toggle-group.component.html',
  styleUrls: ['./toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleGroupComponent implements OnInit, OnDestroy {
  private changeSubscription: Subscription;
  private _value: any;

  public trackByValue = TrackBy.trackByValue;

  @ContentChildren(ToggleComponent)
  public toggles: QueryList<ToggleComponent>;

  @ViewChild(MatButtonToggleGroup, { static: true })
  public toggleGroup: MatButtonToggleGroup;

  public get value(): any {
    return this._value;
  }

  @Input()
  public set value(value: any) {
    this._value = value;
    if (this.toggleGroup) {
      this.toggleGroup.value = value;
    }
  }

  @Output()
  public valueChange = new EventEmitter();

  public ngOnInit(): void {
    this.toggleGroup.value = this.value;
    this.changeSubscription = this.toggleGroup.change
      .subscribe((change: MatButtonToggleChange) => {
        this._value = change.value;
        this.valueChange.emit(this.value);
      });
  }

  public ngOnDestroy(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
  }
}
