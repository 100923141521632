import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Notiz } from '@data/domain/schema/type';

export interface ProduktDetailNotizenDialogData {
  notizen: Notiz[];
}

@Component({
  selector: 'app-produkt-detail-notizen-dialog',
  templateUrl: './produkt-detail-notizen-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailNotizenDialogComponent {
  public data: ProduktDetailNotizenDialogData;
}
