import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';
import { of, PartialObserver } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';
import { ProduktDetailFileService } from './produkt-detail-file.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailFileFieldService {
    constructor(
        private readonly produktDetailFileService: ProduktDetailFileService) {
        Assert.notNullOrUndefined(produktDetailFileService, 'produktDetailFileService');
    }

    public isUpdateable(fileId: string): boolean {
        Assert.notNullOrEmpty(fileId, 'fileId');
        return this.produktDetailFileService.isUpdated(fileId);
    }

    public add(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): void {
        if(!files ||!field) {
          return;
        }
        const observer = this.createAddObserver(field);
        of(files).pipe(
            filter(x => !!x && x.length > 0),
            flatMap(x => x.map(file => this.produktDetailFileService.put(file))),
            flatMap(id => id)
        ).subscribe(observer);
    }

    public remove(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        const observer = this.createDeleteObserver(field, fileId);
        this.produktDetailFileService.delete(fileId).subscribe(observer);
    }

    public update(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrUndefined(event, 'event');
        const { id, data } = event;
        const observer = this.createUpdateObserver(field, id);
        this.produktDetailFileService.update(id, data).subscribe(observer);
    }

    public reset(field: UntypedFormControl, fileId: string): void {
        Assert.notNullOrUndefined(field, 'field');
        Assert.notNullOrEmpty(fileId, 'fileId');
        const observer = this.createUpdateObserver(field, fileId);
        this.produktDetailFileService.reset(fileId).subscribe(observer);
    }

    private createAddObserver(field: UntypedFormControl): PartialObserver<string> {
        const observer: PartialObserver<string> = {
            next: id => {
                const newValue = [...field.value, id];
                field.setValue(newValue);
            }
        };
        return observer;
    }

    private createDeleteObserver(field: UntypedFormControl, fileId: string): PartialObserver<void> {
        const observer: PartialObserver<void> = {
            next: () => {
                const index = field.value.indexOf(fileId);
                if (index !== -1) {
                    const newValue = [...field.value];
                    newValue.splice(index, 1);
                    field.setValue(newValue);
                }
            }
        };
        return observer;
    }

    private createUpdateObserver(field: UntypedFormControl, fileId: string): PartialObserver<string> {
        const { value } = field;
        const observer: PartialObserver<string> = {
            next: newId => {
                const index = value.indexOf(fileId);
                if (index !== -1) {
                    const newValue = [...value];
                    newValue[index] = newId;
                    field.setValue(newValue);
                }
            }
        };
        return observer;
    }
}
