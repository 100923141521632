import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-produkt-uebersicht',
  templateUrl: './produkt-uebersicht.component.html',
  styleUrls: ['./produkt-uebersicht.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktUebersichtComponent  {

}
