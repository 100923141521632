<mat-card class="card-list-item">

  <div class="header">
    <div class="header-text">
      <ng-content select="[title]"></ng-content>
      <ng-content select="[subtitle]"></ng-content>
    </div>
    <div class="space"></div>
    <div class="header-status">
      <ng-content select="[date]"></ng-content>
      <ng-content select="[status]"></ng-content>
    </div>
  </div>
    <ng-content></ng-content>
</mat-card>

