import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { guid } from '@app/function/guid';
import { VtiAuftragResponse, VtiAuftragService } from '@data/api-gateway';
import { AdresseAnrede, AuftragArt, ProduktArt } from '@data/domain/schema/enum';
import { AdresseInput, Auftrag, AuftragInput, Fahrzeug, FahrzeugInput, Notiz, VtiAuftrag, VtiAuftragInput } from '@data/domain/schema/type';
import { ProduktAdressenService, ProduktAuftragService, ProduktFahrzeugService, ProduktVtiAuftragService } from '@data/domain/service/feature';
import {
  ProduktDetailFeatureInputComponent
} from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailVtiAuftragFormViewFactory } from '@modules/produkt/factory/vti-auftrag/produkt-detail-vti-auftrag-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { VtiAuftragstypPipe } from '@modules/produkt/pipe/vti-auftragstyp.pipe';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-produkt-detail-auftrag',
  templateUrl: './produkt-detail-vti-auftrag.component.html',
  styleUrls: ['./produkt-detail-vti-auftrag.component.scss'],
  providers: [ProduktDetailFeatureNotizenService, VtiAuftragstypPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailVtiAuftragComponent extends ProduktDetailFeatureInputComponent<VtiAuftrag, VtiAuftragInput> implements OnInit {
  private readonly vtiMinDate = '0001-01-01T00:00:00Z';
  public trackByField = TrackBy.trackByField;
  public trackByInstance = TrackBy.trackByInstance;

  public feature = PRODUKT_CONFIG_FEATURES.VtiAuftrag.name;
  public produktArt = ProduktArt;

  public searchForm: ViewFormGroup;

  public notizen$: Observable<Notiz[]>;

  public art = new EnumValues(AuftragArt);
  public artDisabled = {
    [AuftragArt.Zentral]: true
  };

  public amsSearchInputMax = 6;
  public searchResult: VtiAuftragResponse = null;

  @ViewChild('dialogConfirm', { static: true })
  public dialogConfirmTemplate: TemplateRef<any>;

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktVtiAuftragService: ProduktVtiAuftragService,
    private readonly formViewFactory: ProduktDetailVtiAuftragFormViewFactory,
    private readonly notizenService: ProduktDetailFeatureNotizenService,
    private readonly templateDialogService: TemplateDialogService,
    private readonly vtiAuftragService: VtiAuftragService,
    private readonly produktAuftragService: ProduktAuftragService,
    private readonly produktFahrzeugService: ProduktFahrzeugService,
    private readonly produktAdressenService: ProduktAdressenService,
    private readonly snackbar: SnackBarService,
    private readonly vtiAuftragstypPipe: VtiAuftragstypPipe) {
    super(produktConfigResolveService, produktDetailResolveService, produktVtiAuftragService);
    Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
  }

  public ngOnInit(): void {
    const name = PRODUKT_CONFIG_FEATURES.VtiAuftrag.name;
    this.notizen$ = this.notizenService.init(this.produkt, name);
    this.init(name);
    this.searchForm = new ViewFormGroup({
      searchField: new ViewFormControl('', {
        validators: [
          Validators.pattern('[0-9]{6}'),
        ]
      })
    });
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  public onSearch(amsId: number): void {
    this.vtiAuftragService.getAuftrag(amsId).subscribe(vtiAuftrag => {
      vtiAuftrag ?
        this.searchResult = vtiAuftrag : this.searchResult = null;
      this.openModalDialog();
    }, err => {
      console.error(err);
      switch (err.status) {
        case 400:
          this.snackbar.error('vtiAuftrag.suche.error.badRequest');
          break;
        case 404:
          this.snackbar.error('vtiAuftrag.suche.error.notFound');
          break;
        case 500:
        default:
          this.snackbar.error('vtiAuftrag.suche.error.general');
          break;
      }
    });
  }

  protected createForm(): ViewFormGroup {
    return this.formViewFactory.create(this.produkt.vtiAuftrag, this.fields);
  }

  private openModalDialog() {
    const title = 'AMS Abfrage';
    const buttons = ['feature.cancel', 'feature.confirm'];

    if (!this.searchResult) {
      buttons.pop();
    }
    this.templateDialogService.openTemplate(title, buttons,
      this.dialogConfirmTemplate).subscribe(result => {
        if (result.name && result.name === buttons[1]) {
          const save$ = [];
          save$.push(this.setAuftragData());
          save$.push(this.setFahrzeugData());
          save$.push(this.setAdresseData());
          forkJoin(save$).subscribe({
            next: () => this.snackbar.success('vtiAuftrag.uebernehmen.success'),
            error: err => {
              console.error(err);
              this.snackbar.error('vtiAuftrag.uebernehmen.error');
            }
          });
        }
      });
  }

  private setAuftragData(): Observable<Auftrag> {
    const localizedAuftragstyp = this.vtiAuftragstypPipe.transform(this.searchResult.vti_auftragstyp);

    this.form.get('nummer').patchValue(this.searchResult.vti_auftragsnummer);
    this.form.get('auftragstyp').patchValue(localizedAuftragstyp);

    const auftragInput: AuftragInput = {
      id: this.produkt.id,
      nummer: this.searchResult.vti_auftragsnummer ? this.searchResult.vti_auftragsnummer : null,
      auftragstyp: localizedAuftragstyp ? localizedAuftragstyp : null
    };

    return this.produktAuftragService.save(this.produkt.id, auftragInput);
  }

  private setFahrzeugData(): Observable<Fahrzeug> {
    const resultFahrzeug = this.searchResult.fahrzeug;
    const fahrzeugInput: FahrzeugInput = {
      id: this.produkt.id,
      identnummer: resultFahrzeug.fin ? resultFahrzeug.fin : null,
      kennzeichen: resultFahrzeug.kennzeichen ? resultFahrzeug.kennzeichen : null,
      erstzulassung: (resultFahrzeug.erstzulassung && resultFahrzeug.erstzulassung !== this.vtiMinDate) ?
        resultFahrzeug.erstzulassung : null,
      laufleistung: (resultFahrzeug.laufleistung && resultFahrzeug.laufleistung !== 0) ?
        resultFahrzeug.laufleistung : null
    };

    return this.produktFahrzeugService.save(this.produkt.id, fahrzeugInput);
  }

  private setAdresseData(): Observable<boolean> {
    const resultAuftraggeber = this.searchResult.gtue_auftraggeber_adresse;
    const adresseInput: AdresseInput = {
      id: this.produkt.id,
      createdAt: new Date().toDateString(),
      anrede: AdresseAnrede.Firma,
      firma: resultAuftraggeber.firma ? resultAuftraggeber.firma : null,
      strasseNr: resultAuftraggeber.strasse_nummer ? resultAuftraggeber.strasse_nummer : null,
      postleitzahl: resultAuftraggeber.plz ? resultAuftraggeber.plz : null,
      ort: resultAuftraggeber.ort ? resultAuftraggeber.ort : null,
      externalId: resultAuftraggeber.nummer ? resultAuftraggeber.nummer : null,
      arten: ['Auftraggeber']
    };

    return this.produktAdressenService.saveAdresse(this.produkt.id, guid(), adresseInput);
  }
}
