import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { guid } from '@app/function/guid';
import { AuthService } from '@app/service/auth.service';
import { ProduktArt } from '@data/domain/schema/enum';
import { Textbaustein } from '@data/domain/schema/type';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { Verfuegbarkeit } from '@modules/textbausteine-verwalten/config/textbausteine-verwalten.config';
import {
  TextbausteinFormViewFactory
} from '@modules/textbausteine-verwalten/factory/textbaustein/textbaustein-form-view.factory';
import { EditMode } from '@shared/component/popup-modal/textbausteine-dialog-edit/textbausteine-dialog-edit.component';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { Values } from '@shared/helper/values';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

interface NewTextbausteinData {
  form: ViewFormGroup;
  fields: FeatureFields;
}

@Component({
  selector: 'app-textbausteine-list',
  templateUrl: './textbausteine-list.component.html',
  styleUrls: ['./textbausteine-list.component.scss']
})
export class TextbausteineListComponent implements OnInit {

  @Input()
  public feature = '';

  @Input()
  public feld = '';

  @Input()
  public feldDisplayName = '';

  @Input()
  public values: Values;

  @Input()
  public textbausteine$ = new BehaviorSubject<Textbaustein[]>([]);

  @Input()
  public fields: FeatureFields;

  @Input()
  public produktArt: ProduktArt;

  @Output()
  public textbausteinEventEmitter = new EventEmitter<Textbaustein>();

  @Output()
  public reloadEventEmitter = new EventEmitter();

  @ViewChild('dialogShow', { static: true })
  public dialogShowTemplate: TemplateRef<any>;

  @ViewChild('dialogEdit', { static: true })
  public dialogEditTemplate: TemplateRef<any>;

  @ViewChild('dialogDelete', { static: true })
  public dialogDeleteTemplate: TemplateRef<any>;

  public editMode = EditMode;
  public loading$ = new BehaviorSubject(false);

  private user = '';
  private buero = '';

  constructor(private readonly textbausteineService: TextbausteineService,
    private readonly textbausteinFormViewFactory: TextbausteinFormViewFactory,
    private readonly authService: AuthService,
    private readonly templateDialogService: TemplateDialogService) {
    this.authService.getClaims().pipe(take(1)).subscribe(value => {
      this.user = value['custom:personal_nummer'];
      this.buero = value['custom:buero_id'];
    });
  }

  public ngOnInit(): void {
    this.reloadTextbausteine();
  }

  public onAddClick(input: MatInput): void {
    if (input.value.length <= 0) {
      return;
    }
    this.createNewTextbaustein(input.value);
  }
  public isGTUEStandard(value: Textbaustein): boolean {
    return value.verfuegbarkeit.includes(Verfuegbarkeit.GTUE);
  }

  public onShowTextbausteinClicked(value: Textbaustein): void {
    const buttons = [`feature.cancel`];
    this.openTextbausteinDialog(value, this.dialogShowTemplate, buttons);
  }

  public onEditTextbausteinClicked(value: Textbaustein): void {
    const buttons = [`feature.save`, `feature.cancel`];
    this.openTextbausteinDialog(value, this.dialogEditTemplate, buttons);
  }

  private openTextbausteinDialog(value: Textbaustein, dialog: TemplateRef<any>, buttons = []): void {

    const data = {
      form: new ViewFormGroup({
        id: new ViewFormControl(value.id),
        kurztext: new ViewFormControl(value.kurztext),
        langtext: new ViewFormControl(value.langtext),
        verfuegbarkeit: new ViewFormControl(value.verfuegbarkeit),
        produkte: new ViewFormControl(value.produkte),
        standard: new ViewFormControl(value.standard),
        feature: new ViewFormControl(value.feature),
        feld: new ViewFormControl(value.feld),
        tags: new ViewFormControl(value.tags),
        erstelltAm: new ViewFormControl(value.erstelltAm),
        erstelltVon: new ViewFormControl(value.erstelltVon)
      }),
      fields: []
    };

    this.templateDialogService.openTemplate('Textbaustein bearbeiten', buttons,
      dialog, data, true).subscribe(result => {
        if (result && result.name === 'feature.save') {
          this.loading$.next(true);
          this.textbausteineService.saveTextbaustein(result.data.form.value).pipe(take(1)).subscribe({
            next: res => {
              this.reloadTextbausteine(900);
            }
          });
        }
      });
  }

  private createNewTextbaustein(value: string) {
    const form = new ViewFormGroup({
      kurztext: new ViewFormControl(value),
      langtext: new ViewFormControl(value),
      verfuegbarkeit: new ViewFormControl([Verfuegbarkeit.Benutzer]),
      produkte: new ViewFormControl([this.produktArt]),
      standard: new ViewFormControl(false),
      erstelltAm: new ViewFormControl(new Date().toISOString()),
    });

    const buttons = [`feature.cancel`, `feature.save`];
    const data: NewTextbausteinData = {
      form,
      fields: []
    };

    this.templateDialogService.openTemplate('Textbaustein hinzufügen', buttons,
      this.dialogEditTemplate, data).subscribe(result => {
        const newTextbaustein: Textbaustein = {
          id: guid(),
          buero: this.buero,
          kurztext: result.data.form.get('kurztext').value,
          langtext: result.data.form.get('langtext').value,
          verfuegbarkeit: result.data.form.get('verfuegbarkeit').value,
          produkte: result.data.form.get('produkte').value,
          feature: this.feature,
          feld: this.feld,
          tags: [],
          standard: result.data.form.get('standard').value,
          erstelltAm: new Date().toISOString(),
          erstelltVon: this.user,
        };

        this.textbausteineService.createTextbaustein(newTextbaustein);
        this.textbausteinEventEmitter.emit(newTextbaustein);
        this.loading$.next(true);
        this.reloadTextbausteine(900);
      });
  }

  public onDeleteTextbausteinClicked(textbaustein: Textbaustein): void {
    const buttons = [`feature.cancel`, `feature.delete`];

    this.templateDialogService.openTemplate('Textbaustein löschen', buttons,
      this.dialogDeleteTemplate).subscribe(result => {
        this.loading$.next(true);
        if (result.name && result.name === `feature.delete`) {
          this.textbausteineService.deleteTextbaustein(textbaustein.id).pipe(take(1)).subscribe({
            complete: () => {
              this.reloadTextbausteine(900);
            }
          });
        }
      });
  }

  private reloadTextbausteine(timer: number = 900) {
    setTimeout(() => {
      this.textbausteineService.getTextbausteine(this.feature, this.feld).pipe(take(1)).subscribe({
        next: res => {
          this.textbausteine$.next(this.textbausteineService.sortByErstelltAmAscending(res).filter(
            value => this.produktArt ? value.produkte.includes(this.produktArt) : true
          ));
          this.loading$.next(false);
        }
      });
    }, timer);
  }
}
