import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { guid } from '@app/function/guid';
import { FahrzeugArt, FahrzeugBauform, ProduktArt } from '@data/domain/schema/enum';
import { Abrechnung, Ausstattung, Fahrzeug, FahrzeugInput, Notiz, Produkt } from '@data/domain/schema/type';
import { ProduktAusstattungService } from '@data/domain/service/feature';
import { ProduktAbrechnungService } from '@data/domain/service/feature/produkt-abrechnung.service';
import { ProduktFahrzeugService } from '@data/domain/service/feature/produkt-fahrzeug.service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailFahrzeugFormViewFactory } from '@modules/produkt/factory/fahrzeug/produkt-detail-fahrzeug-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-produkt-detail-fahrzeug',
  templateUrl: './produkt-detail-fahrzeug.component.html',
  styleUrls: ['./produkt-detail-fahrzeug.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProduktDetailFahrzeugComponent extends ProduktDetailFeatureInputComponent<Fahrzeug, FahrzeugInput> implements OnInit {
  public trackByField = TrackBy.trackByField;

  public notizen$: Observable<Notiz[]>;
  public manuellFahrzeugartDisabled = true;
  public manuellBauformDisabled = true;


  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktFahrzeugService: ProduktFahrzeugService,
    private readonly formViewFactory: ProduktDetailFahrzeugFormViewFactory,
    private readonly produktAusstattungService: ProduktAusstattungService,
    private readonly notizenService: ProduktDetailFeatureNotizenService,
    private readonly produktAbrechnungService: ProduktAbrechnungService) {
    super(produktConfigResolveService, produktDetailResolveService, produktFahrzeugService);
    Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
    Assert.notNullOrUndefined(produktAusstattungService, 'produktAusstattungService');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
    Assert.notNullOrUndefined(produktAbrechnungService, 'produktAbrechnungService');

  }

  public ngOnInit(): void {
    const name = PRODUKT_CONFIG_FEATURES.Fahrzeug.name;
    this.notizen$ = this.notizenService.init(this.produkt, name);
    this.init(name);
    this.setBauformGroupManuell();
  }

  public isVtiTooling(): boolean {
    if (!this.produkt) {
      return false;
    }

    if (this.produkt.art === ProduktArt.VtiTooling) {
      return true;
    } else {
      return false;
    }
  }

  public onAbrechnungResolved(abrechnung: Abrechnung): void {
    Assert.notNullOrUndefined(abrechnung, 'abrechnung');
    const abrechnungId = guid();
    this.produktAbrechnungService.saveAbrechnung(this.produkt.art, this.produkt.id, abrechnungId, abrechnung);
  }

  public onAusstattungResolved(ausstattung: Ausstattung): void {
    Assert.notNullOrUndefined(ausstattung, 'ausstattung');
    ausstattung.gruppen.push(this.produktAusstattungService.getErfassteAusstattung(this.produkt));
    this.produktAusstattungService.save(this.produkt.id, ausstattung);
  }

  public saveProdukt(_$event: Produkt): void {
    this.save();
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  protected createForm(): ViewFormGroup {
    return this.formViewFactory.create(this.produkt.fahrzeug, this.fields, this.produkt.art);
  }

  private setBauformGroupManuell(): void {
    if (this.produkt.fahrzeug.fahrzeugart === FahrzeugArt.Manuell) {
      this.manuellFahrzeugartDisabled = false;
    }
    if (this.produkt.fahrzeug.bauform === FahrzeugBauform.Manuell) {
      this.manuellBauformDisabled = false;
    }
  }
}
