import { EventEmitter } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { Box3, BoxGeometry, Intersection, MathUtils, Mesh, MeshBasicMaterial, Object3D, PerspectiveCamera, Raycaster, Vector2, Vector3 } from 'three';

export enum ObjectViewVertical {
    Top,
    Side,
    Bottom
}

export enum ObjectViewHorizontal {
    Front,
    Left,
    Rear,
    Right
}

export interface ObjectViewState {
    zoom?: number;
    rotation?: Vector2;
}

const ZOOM_FACTOR = 1.1;

export class ObjectViewControl {
    private events: {} = {
        mousewheel: this.onMouseWheel.bind(this),
        mousedown: this.onMouseDown.bind(this),
        mouseup: this.onMouseUp.bind(this),
        mouseout: this.onMouseOut.bind(this),
        mousemove: this.onMouseMove.bind(this),
        touchstart: this.onTouchStart.bind(this),
        touchend: this.onTouchEnd.bind(this),
        touchmove: this.onTouchMove.bind(this)
    };
    private rotation = new Vector2(0, 0);
    private pointer = new Vector2(0, 0);
    private pointer2 = new Vector2(0, 0);
    private velocity = new Vector2(0, 0);

    private maxZoom = Infinity;
    private minZoom = 0.2;
    private zoom = 3;

    private latRange = new Vector2(-25, 45);

    private userInteracting = false;
    private userTapped = false;

    private ray = new Raycaster();
    private userRay = new Raycaster();
    private camera: PerspectiveCamera;
    private element: HTMLElement;

    private targetObject: Object3D;
    private targetBox = new Box3();
    private targetChildren: Object3D[] = [];
    private targetCenter = new Vector3();
    private targetSize = new Vector3();
    private targetRadius = 1.0;
    private targetBoxObject = new Mesh();

    private state: ObjectViewState = {
        zoom: 0.0,
        rotation: new Vector2(0, 0)
    };

    public userTap = new EventEmitter<Intersection>();

    public enable = true;
    public enableZoom = true;
    public enableDynamicZoom = true;
    public invert = false;
    public lockAxis = new Vector2(0, 0);

    public init(camera: PerspectiveCamera, element: HTMLElement): void {
        Assert.notNullOrUndefined(camera, 'camera');
        Assert.notNullOrUndefined(element, 'element');
        this.camera = camera;
        this.element = element;
        this.addEventListeners();
    }

    public target(target: Object3D): void {
        Assert.notNullOrUndefined(target, 'target');
        this.targetObject = target;
        this.targetBox = new Box3().setFromObject(target);
        this.targetChildren = target.children;
        this.targetCenter = this.targetBox.getCenter(new Vector3());
        this.targetSize = this.targetBox.getSize(new Vector3());
        this.targetBoxObject = new Mesh(new BoxGeometry(
            this.targetSize.x,
            this.targetSize.y,
            this.targetSize.z), new MeshBasicMaterial());
        this.targetBoxObject.position.set(
            this.targetCenter.x,
            this.targetCenter.y,
            this.targetCenter.z);
        this.targetBoxObject.updateMatrixWorld(true);

        const radius = this.targetBox.min.distanceTo(this.targetBox.max) * 0.5;
        this.targetRadius = radius * 1.5;
        this.calculateZoom();
    }

    public focus(horizontal: ObjectViewHorizontal, vertical: ObjectViewVertical): void {
        Assert.notNullOrUndefined(horizontal, 'horizontal');
        Assert.notNullOrUndefined(vertical, 'vertical');
        switch (horizontal) {
            case ObjectViewHorizontal.Front:
                this.rotation.x = 180;
                break;
            case ObjectViewHorizontal.Left:
                this.rotation.x = 90;
                break;
            case ObjectViewHorizontal.Rear:
                this.rotation.x = 0;
                break;
            case ObjectViewHorizontal.Right:
                this.rotation.x = 270;
                break;
        }
        switch (vertical) {
            case ObjectViewVertical.Top:
                this.rotation.y = 45;
                break;
            case ObjectViewVertical.Side:
                this.rotation.y = 25;
                break;
            case ObjectViewVertical.Bottom:
                this.rotation.y = -45;
                break;
        }
        this.updateCamera();
    }

    public reset(): void {
        this.setFromState(this.state);
        this.updateCamera();
        this.resetObject();
    }

    public setZoom(minZoom: number, maxZoom: number): void {
        Assert.notNullOrUndefined(minZoom, 'minZoom');
        Assert.notNullOrUndefined(maxZoom, 'maxZoom');
        this.minZoom = minZoom;
        this.maxZoom = maxZoom;
        this.clampZoom();
    }

    public setLat(minLat: number, maxLat: number): void {
        Assert.notNullOrUndefined(minLat, 'minLat');
        Assert.notNullOrUndefined(maxLat, 'maxLat');
        this.latRange.set(minLat, maxLat);
    }

    public setFromState(state: ObjectViewState): void {
        Assert.notNullOrUndefined(state, 'state');
        if (state.zoom) {
            this.zoom = state.zoom;
        }
        if (state.rotation) {
            this.rotation.set(state.rotation.x, state.rotation.y);
        }
        this.updateCamera();
    }

    public saveState(): void {
        this.state.zoom = this.zoom;
        this.state.rotation.set(this.rotation.x, this.rotation.y);
    }

    public dispose(): void {
        this.removeEventListeners();
    }

    public animate(): boolean {
        if (this.userTapped) {
            return false;
        }

        this.velocity = this.velocity.multiplyScalar(0.4);

        const lon = this.rotation.x + this.velocity.x;
        const lat = MathUtils.clamp(this.rotation.y + this.velocity.y, this.latRange.x, this.latRange.y);

        if (this.rotation.x !== lon || this.rotation.y !== lat) {
            this.rotation.set(lon, lat);
            this.updateCamera();
        }

        return this.userInteracting;
    }

    public calculateZoom(): void {
        const fov = MathUtils.degToRad(this.camera.fov);
        const fovAspectRatio = 2 * Math.tan(fov / 2) * this.camera.aspect;
        const zoom = this.targetRadius / fovAspectRatio;
        this.zoom = 1.5 + zoom;
        this.maxZoom = this.zoom * 1.5;
        this.updateCamera();
    }

    private onMouseWheel(event: WheelEvent): void {
        event.preventDefault();

        if (event.deltaY > 0) {
            this.zoomOut();
        } else {
            this.zoomIn();
        }
    }

    private onMouseDown(event: MouseEvent): void {
        event.preventDefault();

        this.pointer.set(event.clientX, event.clientY);
        this.userInteracting = true;
        this.userTapped = true;
    }

    private onMouseUp(event: MouseEvent): void {
        event.preventDefault();

        this.checkUserTapped();

        this.userInteracting = false;
        this.userTapped = false;
    }

    private onMouseOut(event: MouseEvent): void {
        event.preventDefault();

        this.userInteracting = false;
        this.userTapped = false;
    }

    private onMouseMove(event: MouseEvent): void {
        event.preventDefault();

        if (this.userInteracting) {
            const newPointer = new Vector2(event.clientX, event.clientY);
            this.drag(newPointer);
        }
    }

    private onTouchStart(event: TouchEvent): void {
        if (!this.enable) {
            return;
        }

        if (this.userTap.observers.length > 0) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.pointer.set(event.touches[0].clientX, event.touches[0].clientY);
        if (event.touches.length > 1) {
            this.pointer2.set(event.touches[1].clientX, event.touches[1].clientY);
        } else {
            this.userTapped = true;
        }
        this.userInteracting = true;
    }

    private onTouchEnd(event: TouchEvent): void {
        if (!this.enable) {
            return;
        }

        if (this.userTap.observers.length > 0) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.checkUserTapped();

        this.userInteracting = false;
        this.userTapped = false;
    }

    private onTouchMove(event: TouchEvent): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.userInteracting) {
            switch (event.touches.length) {
                case 1: {
                    const newPointer = new Vector2(event.touches[0].clientX, event.touches[0].clientY);
                    this.drag(newPointer);
                    break;
                }
                case 2: {
                    const newPointer = new Vector2(event.touches[0].clientX, event.touches[0].clientY);
                    const newPointer2 = new Vector2(event.touches[1].clientX, event.touches[1].clientY);
                    this.pinch(newPointer, newPointer2);
                    break;
                }
            }
        }
    }

    private zoomIn(): void {
        if (!this.enable || !this.enableZoom) {
            return;
        }

        this.zoom *= (1 / ZOOM_FACTOR);
        this.clampZoom();
        this.updateCamera();
    }

    private zoomOut(): void {
        if (!this.enable || !this.enableZoom) {
            return;
        }

        this.zoom *= ZOOM_FACTOR;
        this.clampZoom();
        this.updateCamera();
    }

    private clampZoom(): void {
        this.zoom = Math.min(Math.max(this.zoom, this.minZoom), this.maxZoom);
    }

    private drag(newPointer: Vector2): void {
        if (!this.enable) {
            return;
        }

        const diff = newPointer.clone().sub(this.pointer);
        this.pointer.set(newPointer.x, newPointer.y);
        if (this.lockAxis.x === 1) {
            this.velocity.x = 0;
        } else {
            this.velocity.x += diff.x * (this.invert ? -1 : 1);
        }

        if (this.lockAxis.y === 1) {
            this.velocity.y = 0;
        } else {
            this.velocity.y += diff.y * (this.invert ? -1 : 1);
        }

        if (this.userTapped && this.velocity.length() > 5) {
            this.userTapped = false;
        }
        this.updateCamera();
    }

    private pinch(newPointer: Vector2, newPointer2: Vector2): void {
        if (!this.enable) {
            return;
        }

        this.userTapped = false;

        const oldDistance = this.pointer.distanceTo(this.pointer2);
        const newDistance = newPointer.distanceTo(newPointer2);
        const distance = oldDistance - newDistance;
        if (Math.abs(distance) > 5) {
            if (distance < 0) {
                this.zoomIn();
            } else {
                this.zoomOut();
            }
            this.pointer.set(newPointer.x, newPointer.y);
            this.pointer2.set(newPointer2.x, newPointer2.y);
        }
    }

    private updateCamera(): void {
        const phi = MathUtils.degToRad(90 - this.rotation.y);
        const theta = MathUtils.degToRad(this.rotation.x);

        const origin = new Vector3(
            this.targetRadius * Math.sin(phi) * Math.cos(theta),
            this.targetRadius * Math.cos(phi),
            this.targetRadius * Math.sin(phi) * Math.sin(theta));

        if (this.enableDynamicZoom) {
            const direction = this.targetCenter.clone().sub(origin).setLength(1);

            this.ray.set(origin, direction);

            const intersects = this.ray.intersectObject(this.targetBoxObject, false)[0];
            if (intersects) {
                const dir = origin.sub(intersects.point).setLength(this.zoom);
                const pos = intersects.point.add(dir);

                this.setCameraPosition(pos, this.targetCenter);
            }
        } else {
            origin.multiplyScalar(this.zoom);
            this.setCameraPosition(origin, this.targetCenter);

            const cameraBox = new Box3(
                origin.clone().subScalar(0.1),
                origin.clone().addScalar(0.1));

            if (this.targetObject) {
                this.targetObject.traverse(child => {
                    if (child.children.length === 0) {
                        const box = new Box3().setFromObject(child);
                        child.visible = !box.intersectsBox(cameraBox);
                    }
                });
            }
        }
    }

    private setCameraPosition(position: Vector3, center: Vector3): void {
        this.camera.position.set(position.x, position.y, position.z);
        this.camera.lookAt(center);
    }

    private checkUserTapped(): void {
        if (this.userTapped) {
            this.velocity.set(0, 0);
            if (this.userTap.observers.length > 0) {

                const origin = this.getUserTapOrigin();
                this.userRay.setFromCamera(origin, this.camera);

                const intersections = this.userRay.intersectObjects(this.targetChildren, true) || [];
                const intersection = intersections.find(x => x.distance >= 0.1);
                if (intersection) {
                    this.userTap.emit(intersection);
                }
            }
        }
    }

    private getUserTapOrigin(): Vector2 {
        const element = this.element;
        const rect = element.getBoundingClientRect();

        return new Vector2(
            ((this.pointer.x - rect.left) / element.clientWidth) * 2 - 1,
            -((this.pointer.y - rect.top) / element.clientHeight) * 2 + 1
        );
    }

    private resetObject(): void {
        if (!this.targetObject) {
            return;
        }

        this.targetObject.traverse(child => {
            if (child.children.length === 0) {
                child.visible = true;
            }
        });
    }

    private removeEventListeners(): void {
        if (this.element) {
            for (const event in this.events) {
                if (this.events.hasOwnProperty(event)) {
                    this.element.removeEventListener(event, this.events[event], false);
                }
            }
        }
    }

    private addEventListeners(): void {
        if (this.element) {
            for (const event in this.events) {
                if (this.events.hasOwnProperty(event)) {
                    this.element.addEventListener(event, this.events[event], false);
                }
            }
        }
    }
}
