import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { DisplayService } from '@shared/service/display.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export enum Viewport {
  None = 0,
  Mobile = 1 << 0,
  Tablet = 1 << 1,
  Desktop = 1 << 2,
}

export enum Orientation {
  None,
  MobilePortrait,
  MobileLandscape,
}

export enum Breakpoint {
  Desktop = 1366, // 992 before
  Tablet = 768, // 576 before
}

const desktopMediaQuery = '(min-width: ' + Breakpoint.Desktop + 'px)';
const tabletMediaQuery = '(min-width: ' + Breakpoint.Tablet + 'px)';

@Injectable({
  providedIn: 'root'
})
export class ViewportService {
  constructor(
    private readonly displayService: DisplayService,
    private readonly breakpointObserver: BreakpointObserver) {
    Assert.notNullOrUndefined(breakpointObserver, 'breakpointObserver');
  }

  public observe(): Observable<Viewport> {
    return this.breakpointObserver.observe([
      desktopMediaQuery,
      tabletMediaQuery
    ]).pipe(
      map(state => {
        if (state.breakpoints[ desktopMediaQuery ]) {
          return Viewport.Desktop;
        }
        if (state.breakpoints[ tabletMediaQuery ]) {
          return Viewport.Tablet;
        }
        return Viewport.Mobile;
      })
    );
  }

  public orientation(): Observable<Orientation> {
    return this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
    ]).pipe(
      map(orientation => {
        if (orientation.breakpoints[ Breakpoints.HandsetPortrait ]) {
          return Orientation.MobilePortrait;
        }
        if (orientation.breakpoints[ Breakpoints.HandsetLandscape ]) {
          return Orientation.MobileLandscape;
        }
        return Orientation.MobilePortrait;
      })
    );
  }

  public mobileLandscapeOptimization(): Subscription {
    return this.orientation().subscribe(
      orientation => {
        if (orientation === Orientation.MobileLandscape) {
          this.displayService.hideAll();
        } else {
          this.displayService.showAll();
        }
      }
    );
  }

}
