import { Injectable } from '@angular/core';
import { SwUpdate, UpdateAvailableEvent } from '@angular/service-worker';
import { from, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SwService {
    constructor(
        private readonly sw: SwUpdate) {
    }

    public available(): Observable<UpdateAvailableEvent> {
        return this.sw.available;
    }

    public activateUpdate(): Observable<boolean> {
        return from(this.sw.activateUpdate());
    }
}
