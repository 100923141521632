import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FahrzeugscheinResponse, FahrzeugscheinResponseCode, FahrzeugscheinService } from '@data/api-gateway';
import { AbstractTemplateDialogComponent } from '@shared/component/popup-modal/template-dialog/template-dialog.component';
import { Assert } from '@shared/helper/assert';
import { CaptureDialogService } from '@shared/service/capture-dialog.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, flatMap, tap } from 'rxjs/operators';

export interface ProduktDetailFahrzeugscheinDialogData {
  vin?: string;
}

@Component({
  selector: 'app-produkt-detail-fahrzeugschein-dialog',
  templateUrl: './produkt-detail-fahrzeugschein-dialog.component.html',
  styleUrls: ['./produkt-detail-fahrzeugschein-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailFahrzeugscheinDialogComponent implements AbstractTemplateDialogComponent, OnInit {
  public vins$ = new BehaviorSubject<UntypedFormGroup[]>(null);
  public error$ = new BehaviorSubject<string>(null);
  public loading$ = new BehaviorSubject(false);

  // input
  public data: ProduktDetailFahrzeugscheinDialogData;

  constructor(
    private readonly capture: CaptureDialogService,
    private readonly schein: FahrzeugscheinService) {
    Assert.notNullOrUndefined(capture, 'capture');
    Assert.notNullOrUndefined(schein, 'schein');
  }

  public ngOnInit(): void {
    this.execute();
  }

  public onRetryClick(): void {
    this.execute();
  }

  public onAccept(vin: string): void {
    Assert.notNullOrEmpty(vin, 'vin');
    this.data.vin = vin;
    this.close('fahrzeugschein.save');
  }

  public onAction(action: string): Observable<boolean> {
    if (action === 'fahrzeugschein.retry') {
      this.execute();
      return of(false);
    }
    return of(true);
  }

  public close(_: string): void {
    // stub method
  }

  private execute(): void {
    if (this.loading$.value) {
      return;
    }

    this.loading$.next(true);

    this.vins$.next(null);
    this.error$.next(null);

    this.capture.captureImage('fahrzeugschein.vin', true).pipe(
      flatMap(data => {
        if (!data || data.length !== 1) {
          this.close('fahrzeugschein.close');
          return of(null);
        }

        return this.schein.post(data[0]).pipe(
          catchError(() => of({ error: '' })),
          tap((response: FahrzeugscheinResponse) => {
            if (response.error) {
              this.error$.next('fahrzeugschein.analyzeError');
            } else {
              switch (response.code) {
                case FahrzeugscheinResponseCode.NotRecognized:
                  this.error$.next('fahrzeugschein.analyzeNotRecognized');
                  break;
                case FahrzeugscheinResponseCode.SingleRecognized:
                case FahrzeugscheinResponseCode.MultipleRecognized:
                  this.vins$.next(response.vins.map(vin => new UntypedFormGroup({
                    vin: new UntypedFormControl(vin.text)
                  })));
                  break;
              }
            }
          })
        );
      }),
      finalize(() => this.loading$.next(false))
    ).subscribe();
  }
}
