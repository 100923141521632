import { Injectable } from '@angular/core';
import { Achse} from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailNfzRaederRadsatzAchseSeiteFormViewFactory } from '@modules/produkt/factory/nfz-raeder/produkt-detail-nfz-raeder-radsatz-achse-seite-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailNfzRaederRadsatzAchseFormViewFactory extends FormViewModelBaseFactory<Achse> {

    constructor(private readonly nfzRaederRadsatzAchseSeiteFormViewFactory: ProduktDetailNfzRaederRadsatzAchseSeiteFormViewFactory) {
        super();
        Assert.notNullOrUndefined(nfzRaederRadsatzAchseSeiteFormViewFactory, 'nfzRadFormViewFactory');
    }

    protected createField(model: Achse, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.fields.Achsen.fields;
        if (name === fields.Bewertung.name) {
            return new ViewFormControl(model.bewertung);
        } else {
            throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: Achse, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.fields.Achsen.fields;
        if (arrayName === fields.Seiten.name) {
            const seiten = model.seiten || [];
            return new ViewFormArray(
                seiten.map(
                    seite => this.nfzRaederRadsatzAchseSeiteFormViewFactory
                        .create(seite, arrayFields)
                ));
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
