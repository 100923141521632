<ng-container [formGroup]="form">
  <div class="select-component-wrapper"
       [ngClass]="((control.touchedChanges | async) && !(control.validChanges | async) && control.hasError('required')) ? 'error' : ''">
    <h4 class="mat-caption text-field-label">{{(displayName | translate) + (required ? ' *' : '')}}</h4>
    <mat-form-field floatLabel="never" [class.disabled]="disabled">
      <mat-select disableRipple disableOptionCentering [id]="name" [formControlName]="name"
                  (selectionChange)="selectionChange.emit($event)"
                  [placeholder]="displayName | translate"
                  panelClass="mat-dropdown"
                  #matSelect>
        <mat-option *ngIf="hasEmptyField"></mat-option>
        <mat-option *ngFor="let key of values.keys;trackBy:trackByKey" [value]="key">
          {{valuePrefix ? (valuePrefix + values.values[key] | lowercase | translate) : (values.values[key])}}
        </mat-option>
      </mat-select>
      <mat-icon class="dropdown-icon"
                [ngClass]="matSelect.panelOpen ? 'icon-chevron-up' : 'icon-chevron-down'"></mat-icon>
    </mat-form-field>
    <mat-error>
      {{'control.required' | translate:({name: (displayName | translate)})}}
    </mat-error>
  </div>
</ng-container>
