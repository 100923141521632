<div class="main-content">
    <app-card>
        <ng-container *ngFor="let field of fields;trackBy:trackByField">
            <ng-container [ngSwitch]="field.name">
                <ng-container *ngSwitchCase="'bezugsdatum'">
                    <ng-container *ngTemplateOutlet="dateDAT, context: { field: field }">
                    </ng-container>
                    <ng-container *ngIf="!hasExternalServiceIdentifier">
                        <p class="form-warn">
                            {{'werte.externalServiceIdentifier.info' | translate}}
                        </p>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'roundValue'">
                  <h4>{{'werte.ueberschrift.dat' | translate}}</h4>
                  <app-select [form]="form" [name]="'roundValue'" [displayName]="'werte.' + 'roundValue'" [values]="werteRoundValue"
                              [valuePrefix]="'werte.'" [hasEmptyField]="false">
                  </app-select>
                </ng-container>
                <ng-container *ngSwitchCase="'haendlereinkaufswert'">
                    <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'haendlereinkaufswert', print: 'haendlereinkaufswertDrucken', readonly: (roundValue$ |async) === enumRoundValue.Netto }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'haendlereinkaufswertNetto'">
                    <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'haendlereinkaufswertNetto', print: 'haendlereinkaufswertNettoDrucken', readonly: (roundValue$ |async) === enumRoundValue.Brutto }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'haendlerverkaufswert'">
                    <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'haendlerverkaufswert', print: 'haendlerverkaufswertDrucken', readonly: (roundValue$ |async) === enumRoundValue.Netto }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'haendlerverkaufswertNetto'">
                    <ng-container *ngTemplateOutlet="currencyprintbesteuerung, context: { value: 'haendlerverkaufswertNetto', readonly: (roundValue$ |async) === enumRoundValue.Brutto,
                    print: 'haendlerverkaufswertNettoDrucken', besteuerung:'haendlerverkaufswertBesteuerung' }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'neuwert'">
                    <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'neuwert', print: 'neuwertDrucken' }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'relativerWert'">
                    <ng-container *ngTemplateOutlet="relativerWert, context: { value: 'relativerWert', print: 'relativerWertDrucken' }">
                    </ng-container>
                </ng-container>
      </ng-container>
    </ng-container>
  </app-card>

  <app-card *ngIf="this.produkt.art !== produktArt.VtiTooling">
    <h4>Werte mit Aufrechnung der Abzüge</h4>
    <ng-container *ngFor="let field of fields;trackBy:trackByField">
      <ng-container [ngSwitch]="field.name">
        <ng-container *ngSwitchCase="'aufwendungen'">
          <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'aufwendungen', print: 'aufwendungenDrucken',
             readonly: (aufwendungenReadonly$ | async), manuelleWerteDrucken: (manuelleWerteDrucken$ | async), click: 'click'}">
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'aufwendungenDetailsDrucken'">
            <app-slide-toggle [form]="form" [name]="'aufwendungenDetailsDrucken'" [readonly]="aufwendungenDetailsDisabled" [class.disabled]="aufwendungenDetailsDisabled">
                {{'werte.aufwendungenDetailsDrucken' | translate}}
            </app-slide-toggle>
          </ng-container>
<!--        <ng-container *ngSwitchCase="'haendlereinkaufswertAbzuege'">-->
<!--          <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'haendlereinkaufswertAbzuege',-->
<!--            print: 'haendlereinkaufswertAbzuegeDrucken' , readonly: true, manuelleWerteDrucken: (manuelleWerteDrucken$ | async)}">-->
<!--          </ng-container>-->
<!--        </ng-container>-->
       <ng-container *ngSwitchCase="'haendlereinkaufswertAbzuegeNetto'">
         <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'haendlereinkaufswertAbzuegeNetto',
           print: 'haendlereinkaufswertAbzuegeNettoDrucken' , readonly: true, manuelleWerteDrucken: (manuelleWerteDrucken$ | async)}">
         </ng-container>
       </ng-container>
<!--        <ng-container *ngSwitchCase="'haendlerverkaufswertAbzuege'">-->
<!--          <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'haendlerverkaufswertAbzuege',-->
<!--            print: 'haendlerverkaufswertAbzuegeDrucken' , readonly: true, manuelleWerteDrucken: (manuelleWerteDrucken$ | async)}">-->
<!--          </ng-container>-->
<!--        </ng-container>-->
       <ng-container *ngSwitchCase="'haendlerverkaufswertAbzuegeNetto'">
         <ng-container *ngTemplateOutlet="currencyprint, context: { value: 'haendlerverkaufswertAbzuegeNetto',
           print: 'haendlerverkaufswertAbzuegeNettoDrucken' , readonly: true, manuelleWerteDrucken: (manuelleWerteDrucken$ | async)}">
         </ng-container>
       </ng-container>
          </ng-container>
    </ng-container>
  </app-card>

    <app-card  *ngIf="this.produkt.art !== produktArt.VtiTooling">
        <h4>Manuelle Werte</h4>

        <app-slide-toggle [form]="form" [name]="'manuelleWerteDrucken'" (change)="onManuelleWerteDruckenChange($event)">
            Manuelle Werte drucken aktivieren
        </app-slide-toggle>

        <h4></h4>

        <ng-container *ngIf="(manuelleWerteDrucken$ | async)">
            <label>{{(name + '.manuelleWerte' | translate) + '&nbsp;(' + manuelleWerte.controls.length + ')&nbsp;'}}</label>
            <app-mini-fab class="add-manueller-wert" mat-icon-button (click)="onAddManuelleWerteClick()" [color]="'primary'">
                <mat-icon>add</mat-icon>
            </app-mini-fab>
            <ng-container *ngTemplateOutlet="manuelleWerteTabelle">
            </ng-container>
        </ng-container>

        <ng-container *ngFor="let field of fields;trackBy:trackByField">
            <ng-container [ngSwitch]="field.name || field.arrayName">
                <ng-container *ngSwitchCase="'wertAmMarkt'">
                    <ng-container *ngTemplateOutlet="currency, context: { field: field }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'wertAmMarktBemerkung'">
                    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text">
                    </app-input>
                </ng-container>
                <ng-container *ngSwitchCase="'wertAmMarktBemerkungen'">
                    <app-textbausteine #wertAmMarktBemerkungen [form]="form" [name]="field.arrayName" [displayName]="name + '.' + field.arrayName"
                                    [feature]="'Werte'" [produktArt]="this.produkt.art">
                    </app-textbausteine>
              </ng-container>
                <ng-container *ngSwitchCase="'restwertAusUnfallschaden'">
                    <ng-container *ngTemplateOutlet="currency, context: { field: field , placeholder: name + '.manuelleWerte' | translate}">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'restwertAusUnfallschadenNetto'">
                    <ng-container *ngTemplateOutlet="currency, context: { field: field , placeholder: name + '.manuelleWerte' | translate}">
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </app-card>

    <app-card  *ngIf="this.produkt.art !== produktArt.VtiTooling">
        <h4>{{name + '.wertbeeinflussendeFaktoren' | translate }}</h4>
        <ng-container *ngFor="let field of fields;trackBy:trackByField">
            <ng-container [ngSwitch]="field.name">
                <ng-container *ngSwitchCase="'werterhoehendesZubehoer'">
                    <ng-container *ngTemplateOutlet="currency, context: { field: field }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'werterhoehenderWartungszustand'">
                    <ng-container *ngTemplateOutlet="currency, context: { field: field }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'werterhoehenderReparaturzustand'">
                    <ng-container *ngTemplateOutlet="currency, context: { field: field }">
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'zweiterRadsatzAnteilig'">
                    <ng-container *ngTemplateOutlet="currency, context: { field: field }">
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </app-card>
</div>

<!-- side panel -->
<app-side-panel class="side-panel" *ngIf="((viewport$ | async) !== viewport.Mobile) && this.produkt.art !== produktArt.VtiTooling">
    <label>{{name + '.total' | translate}}</label>
    <ng-container *ngTemplateOutlet="werteKosten"></ng-container>
</app-side-panel>

<!-- bottom sheet -->
<app-bottom-sheet *ngIf="((viewport$ | async) === viewport.Mobile) && this.produkt.art !== produktArt.VtiTooling" [title]="name + '.total' | translate" [seamless]="true">
    <ng-container *ngTemplateOutlet="werteKosten"></ng-container>
</app-bottom-sheet>

<!-- notizen button -->
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)">
</app-produkt-detail-notizen-button>

<!-- Templates -->
<ng-template #werteKosten>
    <ng-container *ngFor="let positionen of (positionen$ | async); index as i">
        <app-form-table [rows]="positionen" [header]="i === 0" [footer]="i !== 0">
            <app-form-table-column name="bezeichnung" [displayName]="name + '.bezeichnung'" [footerDisplayName]="name + '.total.' + i" [fullWidth]="true" type="text">
            </app-form-table-column>
            <app-form-table-column name="wert" [displayName]="name + '.wert'" type="currency">
            </app-form-table-column>
        </app-form-table>
    </ng-container>
</ng-template>

<ng-template #currency let-field="field" let-placeholder="placeholder">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [placeholder]="placeholder" type="text" inputmode="numeric">
        <ng-container appSuffix>&nbsp;€</ng-container>
    </app-input>
</ng-template>

<ng-template #currencyprint let-value="value" let-print="print" let-readonly="readonly" let-manuelleWerteDrucken="manuelleWerteDrucken" let-click ="click">
    <app-form-group cols="8">
        <app-form-field colspan="7">
            <app-input [form]="form" [name]="value" [displayName]="name + '.' + value" [readonly]="readonly" type="text" inputmode="numeric">
                <ng-container appSuffix [class.readonly]="readonly">&nbsp;€</ng-container>
            </app-input>
        </app-form-field>
        <app-form-field colspan="1">
          <app-print-button *ngIf="click !== 'click'" class="print-button" [form]="form" [name]="print"
                            [disabled]="manuelleWerteDrucken"></app-print-button>
          <app-print-button *ngIf="click === 'click'" class="print-button" [form]="form" [name]="print" (click)="onClickedCurrencyPrintButton()"
                            [disabled]="manuelleWerteDrucken"></app-print-button>
        </app-form-field>
    </app-form-group>
</ng-template>

<ng-template #relativerWert let-value="value" let-print="print" let-readonly="readonly" let-manuelleWerteDrucken="relativerWertDrucken">
    <app-form-group cols="8">
        <app-form-field colspan="7">
            <app-input [form]="form" [name]="value" [displayName]="name + '.' + value" [readonly]="readonly" type="text" inputmode="numeric">
                <ng-container appSuffix [class.readonly]="readonly">&nbsp;%</ng-container>
            </app-input>
        </app-form-field>
        <app-form-field colspan="1">
          <app-print-button class="print-button" [form]="form" [name]="print"
                            [disabled]="manuelleWerteDrucken"></app-print-button>
        </app-form-field>
    </app-form-group>
</ng-template>

<ng-template #currencyprintbesteuerung let-value="value" let-print="print" let-readonly="readonly" let-besteuerung="besteuerung">
    <app-form-group cols="8">
        <app-form-field colspan="7">
            <app-input [form]="form" [name]="value" [displayName]="name + '.' + value" [readonly]="readonly" type="text" inputmode="numeric">
                <ng-container appSuffix [class.readonly]="readonly">&nbsp;€</ng-container>
            </app-input>
        </app-form-field>
        <app-form-field colspan="1">
          <app-print-button class="print-button" [form]="form" [name]="print"></app-print-button>
        </app-form-field>
    </app-form-group>
    <app-form-group cols="1">
        <app-form-field colspan="1">
            <app-select [form]="form" [name]="besteuerung" [displayName]="name + '.' + besteuerung" [values]="besteuerungArt" [valuePrefix]="name + '.'">
            </app-select>
        </app-form-field>
    </app-form-group>
</ng-template>

<ng-template #percent let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" inputmode="numeric">
        <ng-container appSuffix>&nbsp;%</ng-container>
    </app-input>
</ng-template>

<ng-template #dateDAT let-field="field">
    <app-date [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [readonly]="hasExternalServiceIdentifier" [hasDatepicker]="!hasExternalServiceIdentifier">
        <ng-container appSuffix *ngIf="!(datRequestActive$ | async);else loading">
            <app-mini-fab *ngIf="hasExternalServiceIdentifier" [color]="'basic'" (action)="onGetWerteClick($event)"
                          [disabled]="!hasExternalServiceIdentifier || (datRequestActive$ |async)">
                <app-mat-icon icon="search"></app-mat-icon>
            </app-mini-fab>
        </ng-container>
    </app-date>

    <ng-container *ngTemplateOutlet="fahrzeug">
    </ng-container>
</ng-template>

<ng-template #fahrzeug>
    <ng-container *ngFor="let field of fahrzeugFields;trackBy:trackByField">
        <ng-container [ngSwitch]="field.name || field.groupName">
            <ng-container *ngSwitchCase="'zulassungGroup'">
                <app-date [form]="fahrzeugForm" [name]="'erstzulassung'" [displayName]="'fahrzeug.erstzulassung'" [hasDatepicker]="false" disabled="true">
                </app-date>
            </ng-container>
            <ng-container *ngSwitchCase="'laufleistung'">
                <app-form-group cols="21">
                    <app-form-field colspan="8">
                        <app-input [form]="fahrzeugForm" [name]="field.name" [displayName]="'fahrzeug.' + field.name" type="text"
                             inputmode="numeric" disabled="true">
                        </app-input>
                    </app-form-field>
                  <app-form-field colspan="5">
                      <app-select [form]="fahrzeugForm" [name]="'laufleistungEinheit'" [displayName]="'fahrzeug.' + 'laufleistungEinheit'" [values]="fahrzeugLaufleistungEinheit"
                              [valuePrefix]="'fahrzeug.'" [hasEmptyField]="false" disabled="true">
                      </app-select>
                  </app-form-field>
                    <app-form-field colspan="8">
                        <app-select [form]="fahrzeugForm" [name]="'laufleistungBeschreibung'" [displayName]="'fahrzeug.' + 'laufleistungBeschreibung'" [values]="fahrzeugLaufleistungBeschreibung"
                                    [valuePrefix]="'fahrzeug.'" [hasEmptyField]="false" disabled="true">
                        </app-select>
                    </app-form-field>
                </app-form-group>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #loading>
  <app-progress-spinner class="loading-spinner" [diameter]="25"></app-progress-spinner>
</ng-template>


<ng-template #manuelleWerteTabelle let-field="field">
    <!--header-->
    <div class="header" *ngIf="manuelleWerte.controls.length">
        <ng-container *ngFor="let field of manuelleWerteFields;trackBy:trackByField">
            <ng-container [ngSwitch]="field.name || field.groupName">
                <ng-container *ngSwitchCase="'bezeichnung'">
                    <div class="group-header">
                        <label>{{(name + '.' + 'bezeichnung') | translate}}</label>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'wert'">
                    <div class="group-header">
                        <label>{{(name + '.' + 'wert') | translate}}</label>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>


    <!--header of expansion panel-->
    <div class="drop-list" cdkDropList (cdkDropListDropped)="drop($event)">
        <mat-expansion-panel #panel="matExpansionPanel" cdkDrag hideToggle togglePosition="before" *ngFor="let item of manuelleWerte.getRawValue() ;index as index; trackBy:trackByField">
            <mat-expansion-panel-header>
                <ng-container *ngFor="let field of manuelleWerteFields;trackBy:trackByField">
                    <ng-container [ngSwitch]="field.name">

                        <ng-container *ngSwitchCase="'bezeichnung'">
                            <div class="group-header">
                                <label>{{item.bezeichnung}}</label>
                            </div>
                        </ng-container>

                        <ng-container *ngSwitchCase="'wert'">
                            <div *ngIf="isNumber(item.wert)" class="group-header">
                                <label>{{currencyFormatter.format(item.wert)}}</label>
                            </div>
                            <div *ngIf="!isNumber(item.wert)" class="group-header">
                                <label>{{item.wert}}</label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <button class="button drag-button" type="button" mat-icon-button cdkDragHandle>
          <mat-icon>drag_indicator</mat-icon>
        </button>
            </mat-expansion-panel-header>

            <!--body of expansion panel-->
            <div class="button-group">
                <button class="button edit-button" type="button" mat-icon-button (click)="onRowOpenByIndex(index, item, panel, $event)">
          <mat-icon>edit</mat-icon>
        </button>
                <button class="button delete-button" type="button" mat-icon-button (click)="onRowRemoveByIndex(index, panel, $event)">
          <mat-icon>delete</mat-icon>
        </button>
            </div>
        </mat-expansion-panel>
    </div>
</ng-template>

<ng-template #dialog let-data="data">
    <app-produkt-detail-werte-manuelle-werte [form]="data.form" [name]="name" [fields]="data.fields">
    </app-produkt-detail-werte-manuelle-werte>
</ng-template>
