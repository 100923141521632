<h3>{{name + '.entwurf' | translate}}</h3>
<ng-container *ngIf="(entwurfUrl$ | async) as url; else entwurfErstellen">
    <p>{{name + '.entwurfErstellt' | translate}}</p>
    <div align="end">
        <app-button (action)="onEntwurfOpen()">
            {{name + '.oeffnen' | translate}}</app-button>
    </div>
</ng-container>
<ng-template #entwurfErstellen>
    <ng-container *ngIf="!(entwurfRequestActiveChange | async)">
        <ng-container *ngFor="let field of fields;trackBy:trackByField">
            <div *ngIf="field.name === 'adresse'">
                <ng-container [ngSwitch]="field.name">
                    <app-select [form]="entwurf" [name]="field.name" [displayName]="name + '.' + field.name"
                                (selectionChange)="onAdressenChange($event)" [values]="entwurfAdressen">
                    </app-select>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
    <app-progress-spinner *ngIf="entwurfRequestActiveChange | async"></app-progress-spinner>
    <div align="end">
        <app-button (action)="onEntwurf()" [disabled]="(entwurfRequestActiveChange | async) || !(adresseSelected$ | async)">
            {{name + '.erstellen' | translate}}</app-button>
    </div>
</ng-template>
