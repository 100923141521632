import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileData } from '@app/class/file-data';
import { readFile } from '@app/function/file';
import { Assert } from '@shared/helper/assert';
import { forkJoin, Observable } from 'rxjs';

export interface UploadDialogSettings {
  title: string;
  accept: string;
  multiple: boolean;
}

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadDialogComponent implements AfterViewInit {
  @ViewChild('files', { static: true })
  public files: ElementRef<HTMLInputElement>;

  constructor(
    private readonly dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public readonly settings: UploadDialogSettings) {
    Assert.notNullOrUndefined(dialogRef, 'dialogRef');
    Assert.notNullOrUndefined(settings, 'settings');
  }

  public ngAfterViewInit(): void {
    this.files.nativeElement.click();
  }

  public onChange(): void {
    const files = this.files.nativeElement.files;
    if (files && files.length > 0) {
      const fileReads$: Observable<FileData<ArrayBuffer>>[] = [];
      for (let i = 0; i < files.length; ++i) {
        const file = files.item(i);
        fileReads$.push(readFile(file));
      }
      this.dialogRef.close(forkJoin(fileReads$));
    }
  }
}
