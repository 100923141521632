<div class="video-container" [hidden]="(stateChange | async) === 3">
    <div class="loading" *ngIf="(stateChange | async) === 1">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>
    <video class="video" width="1080" height="1920" #video (playing)="onVideoPlaying()" muted autoplay playsinline></video>
</div>
<div class="image-container" #container [hidden]="(stateChange | async) !== 3">
    <canvas></canvas>
    <button class="delete" type="button" mat-mini-fab (click)="onDelete()">
      <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
    </button>
</div>
