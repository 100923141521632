<app-accordion #accordion>

    <app-expansion-panel *ngIf="form.get('obergruppe') as control">
        <app-expansion-header>
            <app-panel-title>
                {{name + '.obergruppe' | translate}}
            </app-panel-title>
            <app-panel-value [value]="((control.valueChanges | async) || control.value) || '-'"></app-panel-value>
            <ng-template #empty>
                <app-panel-value value="-"></app-panel-value>
            </ng-template>
        </app-expansion-header>
        <ng-container *ngTemplateOutlet="list, context: { field: 'obergruppe', values: obergruppe, keys: obergruppe }">
        </ng-container>
    </app-expansion-panel>

    <app-expansion-panel *ngIf="form.get('untergruppe') as control">
        <app-expansion-header>
            <app-panel-title>
                {{name + '.untergruppe' | translate}}
            </app-panel-title>
            <app-panel-value [value]="((control.valueChanges | async) || control.value) || '-'"></app-panel-value>
            <ng-template #empty>
                <app-panel-value value="-"></app-panel-value>
            </ng-template>
        </app-expansion-header>
        <ng-container *ngTemplateOutlet="list, context: { field: 'untergruppe', values: untergruppe, keys: untergruppen }">
        </ng-container>
    </app-expansion-panel>

    <app-expansion-panel *ngIf="form.get('art') as control" [expanded]="true">
        <app-expansion-header>
            <app-panel-title>
                <span [innerHTML]="name + '.art' | translate"></span>
            </app-panel-title>
            <app-panel-value [value]="((control.valueChanges | async) || control.value) || '-'"></app-panel-value>
        </app-expansion-header>
        <ng-container *ngTemplateOutlet="list, context: { field: 'art', values: beschaedigungart, keys: beschaedigungarten }">
        </ng-container>
    </app-expansion-panel>

    <app-expansion-panel *ngIf="form.get('reparatur') as control">
        <app-expansion-header>
            <app-panel-title>
                <span [innerHTML]="name + '.reparatur' | translate"></span>
            </app-panel-title>
            <app-panel-value [value]="((control.valueChanges | async) || control.value) || '-'"></app-panel-value>
        </app-expansion-header>
        <ng-container *ngTemplateOutlet="list, context: { field: 'reparatur', values: vorschadenReparatur, keys: vorschadenReparaturen }">
        </ng-container>
    </app-expansion-panel>

    <app-expansion-panel *ngIf="form.get('bilder') as control">
        <app-expansion-header>
            <app-panel-title>
                {{name + '.bilder' | translate}}
            </app-panel-title>
            <app-panel-value [value]="(((control.valueChanges | async) || control.value) || []).length" [next]="false">
            </app-panel-value>
        </app-expansion-header>
        <ng-container *ngIf="((control.valueChanges | async) || control.value) as files">
            <label>{{name + '.bilder' | translate}}</label>
            <app-file-gallery [fileIds]="files" [dialogTitle]="name + '.addfile'" [showCamera]="true" [showFolder]="true" [resetable]="isFileResetable.bind(this)" (add)="onFileAdd(control, $event)" (delete)="onFileDelete(control, $event)" (update)="onFileUpdate(control, $event)"
                (reset)="onFileReset(control, $event)">
            </app-file-gallery>
        </ng-container>
        <app-action-row>
            <app-button (action)="accordion.next()">
                {{(name + '.next') | translate }}
            </app-button>
        </app-action-row>
    </app-expansion-panel>

    <app-expansion-panel>
        <app-expansion-header>
            <app-panel-title>
                <div class="title">
                    {{name + '.vorschaden' | translate}}
                    <div class="spacer"></div>
                    <div class="value">
                        <ng-container *ngFor="let field of fields;trackBy:trackByField; last as last">
                            <ng-container [ngSwitch]="field.name || field.groupName">
                                <!-- Schadendatum -->
                                <ng-container *ngSwitchCase="'schadendatum'">
                                    <ng-container *ngIf="form.get(field.name) as control">
                                        <ng-container *ngIf="((control.valueChanges | async) || control.value) as value; else empty">
                                            {{control.getRawValue() | date}}
                                        </ng-container>
                                        <span *ngIf="!last">&nbsp;|&nbsp;</span>
                                    </ng-container>
                                </ng-container>
                                <!-- WertminderungGroup -->
                                <ng-container *ngSwitchCase="'wertminderungGroup'">
                                    <ng-container *ngTemplateOutlet="value; context: {field:'schadenhoehe'}">
                                    </ng-container>
                                    <span>&nbsp;|&nbsp;</span>
                                    <ng-container *ngTemplateOutlet="value; context: {field:'wertminderung'}">
                                    </ng-container>
                                    <span *ngIf="!last">&nbsp;|&nbsp;</span>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </app-panel-title>
        </app-expansion-header>
        <ng-container *ngFor="let field of fields;trackBy:trackByField">
            <ng-container [ngSwitch]="field.name || field.groupName">
                <!-- Schadendatum -->
                <ng-container *ngSwitchCase="'schadendatum'">
                    <ng-container *ngTemplateOutlet="date, context: { field: field, max: now }">
                    </ng-container>
                </ng-container>
                <!-- WertminderungGroup -->
                <ng-container *ngSwitchCase="'wertminderungGroup'">
                    <ng-container *ngTemplateOutlet="wertminderungGroup">
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </app-expansion-panel>
</app-accordion>

<div *ngIf="getEmptyFieldsForValidation().length > 0" class="validation">
    {{ 'schaden.position.validierung.text' | translate }}
    <ng-container *ngFor="let field of getEmptyFieldsForValidation()" class="validation">{{ 'vorschaden.' + field | translate }} </ng-container>
</div>

<!-- WertminderungGroup -->
<ng-template #wertminderungGroup>
    <app-form-group cols="2">
        <!-- Schadenhoehe -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="currency, context: { field: {
                name: 'schadenhoehe'
             } }">
            </ng-container>
        </app-form-field>
        <!-- Wertminderung -->
        <app-form-field>
            <ng-container *ngTemplateOutlet="currency, context: { field: {
                name: 'wertminderung'
             } }">
            </ng-container>
        </app-form-field>
    </app-form-group>
</ng-template>


<!-- Templates -->
<ng-template #currency let-field="field">
    <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" inputmode="numeric">
        <ng-container appSuffix>&nbsp;€</ng-container>
    </app-input>
</ng-template>

<ng-template #radio let-field="field" let-values="values">
    <app-radio [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [values]="values" [valuePrefix]="name + '.'" [inline]="false">
    </app-radio>
</ng-template>

<ng-template #list let-field="field" let-values="values" let-keys="keys">
    <app-extendable-radio-list [form]="form" [name]="field" [displayName]="name + '.' + field" [values]="values" [keys]="keys">
    </app-extendable-radio-list>
</ng-template>

<ng-template #date let-field="field" let-min="min" let-max="max">
    <app-date [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [min]="min" [max]="max">
    </app-date>
</ng-template>

<ng-template #value let-field="field">
    <ng-container *ngIf="form.get(field) as control">
        <ng-container *ngIf="((control.valueChanges | async) || control.value) as value; else empty">
            {{control.getRawValue() | number:'0.2-2'}}&nbsp;€
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #empty>-</ng-template>