<table mat-table [dataSource]="dataSource" [class.mat-elevation-z8]="elevation">
    <ng-container [matColumnDef]="column.name" *ngFor="let column of (columns$ | async);trackBy:trackByName">
        <th mat-header-cell [class.number]="column.type === 'currency'" *matHeaderCellDef
            [innerHTML]="column.displayName | translate" scope="col"></th>
        <td mat-cell [class.number]="column.type === 'currency'" [class.full-width]="column.fullWidth"
            *matCellDef="let row">
            <ng-container *ngIf="row.get(column.name) as col">
                <ng-container [ngSwitch]="(col.value === undefined || col.value === null) ? '-' : column.type">
                    <ng-container *ngSwitchCase="'currency'">{{col.value}}&nbsp;€</ng-container>
                    <ng-container *ngSwitchCase="'array'">{{(col.value || []).length}}</ng-container>
                    <ng-container *ngSwitchCase="'array-number'">
                      <ng-container  *ngFor="let item of col.value;">
                        <div>{{item}}</div>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'date'">{{col.value | date}}</ng-container>
                    <ng-container *ngSwitchCase="'text'">{{col.value | translate}}</ng-container>
                    <ng-container *ngSwitchCase="'select'">
                        {{column.valuePrefix + column.values.values[col.value] | lowercase | translate}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'-'">-</ng-container>
                    <ng-container *ngSwitchDefault>{{col.value}}</ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="column.suffix">&nbsp;{{column.suffix}}</ng-container>
        </td>
        <td mat-footer-cell [class.number]="column.type === 'currency'" *matFooterCellDef>
            <ng-container *ngIf="column.type !== 'currency'">
                {{column.footerDisplayName | translate}}
            </ng-container>
            <ng-container *ngIf="column.type === 'currency'">
                {{getTotal(column.name)}}&nbsp;€
            </ng-container>
        </td>
    </ng-container>
    <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef scope="col"></th>
        <td mat-cell *matCellDef="let row">
            <ng-container *ngIf="!row.value.readonly">
              <app-icon-button class="button delete-button" (action)="onRowRemoveClick(row)">
                <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
              </app-icon-button>
            </ng-container>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>
    <ng-container matColumnDef="sortable">
        <th mat-header-cell *matHeaderCellDef scope="col"></th>
        <td mat-cell *matCellDef="let row; let first = first; let last = last">
            <div class="sortable">
                <app-mat-icon *ngIf="!first" icon="keyboard_arrow_up" size="18px" (click)="onRowMove($event, row, -1)">
                </app-mat-icon>
                <app-mat-icon *ngIf="!last" icon="keyboard_arrow_down" size="18px" (click)="onRowMove($event, row, 1)">
                </app-mat-icon>
            </div>
        </td>
        <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="names$ | async" [class.hidden]="!header"></tr>
    <tr mat-row *matRowDef="let row; columns: names$ | async;"
        [class.clickable]="!row.value.readonly && rowOpen.observers.length > 0"
        [class.invalid]="!(row.validChanges | async) && row.invalid" (click)="onRowClick(row)"> </tr>
    <tr mat-footer-row *matFooterRowDef="names$ | async" [class.hidden]="!footer"></tr>
</table>
