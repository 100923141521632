import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList
} from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { TrackBy } from '@shared/helper/track-by';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabGroupComponent {
  public trackByInstance = TrackBy.trackByInstance;

  @ContentChildren(TabComponent)
  public tabs: QueryList<TabComponent>;

  @Input()
  public selectedIndex = 0;

  @Output()
  public selectedIndexChange = new EventEmitter<number>();

  public onSelectedIndexChange(selectedIndex: number): void {
    Assert.notNullOrUndefined(selectedIndex, 'selectedIndex');
    this.selectedIndex = selectedIndex;
    this.selectedIndexChange.emit(this.selectedIndex);
  }

  public onLeft(): void {
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }

  public onRight(): void {
    if (this.selectedIndex < this.tabs.length) {
      this.selectedIndex++;
    }
  }
}
