<h1 class="title" mat-dialog-title *ngIf="settings.title">
    {{settings.title | translate}}
    <button class="close" mat-button tabindex="-1" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div class="content-wrapper" mat-dialog-content>
    <input type="file" [multiple]="settings.multiple" [accept]="settings.accept" #files (change)="onChange()">
</div>
<div mat-dialog-actions>
</div>
