<h1 class="title" mat-dialog-title *ngIf="settings.title">
    {{settings.title | translate}}
    <button class="close" mat-button tabindex="-1" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</h1>
<div class="content-wrapper" mat-dialog-content>
    <input type="file" accept="image/*" #files (change)="onChange()" capture="true"
        [style.display]="captureSupported ? 'block' : 'none'">
    <ng-container *ngIf="!captureSupported && getUserMediaSupported && (state$ | async) !== 5">
        <app-camera-view #camera [options]="settings" (stateChange)="onStateChange($event)" (imageSave)="onImageSave($event)">
        </app-camera-view>
    </ng-container>
    <ng-container *ngIf="(!captureSupported && !getUserMediaSupported) || (state$ | async) === 5">
        {{'capturedialog.notsupported' | translate}}
    </ng-container>
</div>
<div mat-dialog-actions align="center">
    <ng-container *ngIf="!captureSupported && getUserMediaSupported && camera && (state$ | async) !== 5">
        <app-camera-controls [state]="state$ | async" (save)="camera.save()" (trigger)="camera.trigger($event)" (flip)="camera.flip()">
        </app-camera-controls>
    </ng-container>
</div>
