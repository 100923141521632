import { Injectable } from '@angular/core';
export const DEFAULT_FORMAT = 'DD.MM.YYYY';
export const MONTH_YEAR_FORMAT = 'MM.YYYY';

@Injectable({
    providedIn: 'root'
})
export class DateFormatService {
    public locale = 'de-DE';
    public format = DEFAULT_FORMAT;
}
