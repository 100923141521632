import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, ComponentRef, Inject, OnDestroy, OnInit, TemplateRef, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { Assert } from '@shared/helper/assert';
import { TrackBy } from '@shared/helper/track-by';
import { PullToRefreshService } from '@shared/service/pull-to-refresh.service';

export interface TemplateDialogSettings {
  title: string;
  buttons: string[];
  templateOrComponent: TemplateRef<any> | Type<any>;
  templateData: any;
  disableClose: boolean;
}

export interface AbstractTemplateDialogComponent {
  close(action: string): void;
}

@Component({
  selector: 'app-template-dialog',
  templateUrl: './template-dialog.component.html',
  styleUrls: ['./template-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateDialogComponent implements OnInit, OnDestroy {
  private componentRef: ComponentRef<any>;

  public trackByKey = TrackBy.trackByKey;
  public Buttontype = ButtonType;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  public container: ViewContainerRef;

  public template: TemplateRef<any>;

  public disabledButtons: number[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly settings: TemplateDialogSettings,
    public readonly dialogRef: MatDialogRef<TemplateDialogComponent>,
    public readonly pullToRefreshService: PullToRefreshService,
    private readonly componentFactoryResolver: ComponentFactoryResolver) {
    Assert.notNullOrUndefined(settings, 'settings');
    Assert.notNullOrUndefined(dialogRef, 'dialogRef');
    Assert.notNullOrUndefined(pullToRefreshService, 'pullToRefreshService');
    Assert.notNullOrUndefined(componentFactoryResolver, 'componentFactoryResolver');
  }

  public ngOnInit(): void {
    if (this.settings.templateOrComponent instanceof Type) {
      const component = this.settings.templateOrComponent;
      const factory = this.componentFactoryResolver.resolveComponentFactory(component);
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.data = this.settings.templateData;
      this.componentRef.instance.close = (action: string) => this.closeDialog(action);
    } else {
      this.template = this.settings.templateOrComponent;
    }
    this.pullToRefreshService.deactivate();
  }

  public ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  public onAction(action: string): void {
    if (this.componentRef && this.componentRef.instance.onAction) {
      this.componentRef.instance.onAction(action).subscribe(close => {
        if (close) {
          this.closeDialog(action);
        }
      });
    } else {
      this.closeDialog(action);
    }
  }

  public disableButton(index: number): void {
    if (!index) {
      return;
    }
    if (!this.disabledButtons.includes(index)) {
      this.disabledButtons.push(index);
    }
  }

  public enableButton(index): void {
    if (!index) {
      return;
    }
    if (this.disabledButtons.includes(index)) {
      const indexOfButtonInList = this.disabledButtons.indexOf(index);
      this.disabledButtons.splice(indexOfButtonInList, 1);
    }
  }

  public isButtonDisabled(index: number): boolean {
    return this.disabledButtons.includes(index);
  }

  private closeDialog(action: string): void {
    this.dialogRef.close({
      name: action,
      data: this.settings.templateData
    });
    this.pullToRefreshService.activate();
  }
}
