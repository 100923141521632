import { UntypedFormGroup } from '@angular/forms';
import { AbstractViewFormControl, CalculatedViewFormControl, ViewFormControl } from './view-form-control';

export class ViewFormGroup extends UntypedFormGroup {
    public getRawValue(): any {
        const groupValue = {};
        Object.keys(this.controls).forEach(key => {
            const control = <AbstractViewFormControl>this.controls[key];
            if (control instanceof CalculatedViewFormControl) {
                return;
            }

            let value = control.getRawValue();
            if (value === undefined || value === '') {
                value = null;
            }
            groupValue[key] = value;
        });
        return groupValue;
    }

    public setRawValue(value: any): void {
        Object.keys(this.controls).forEach(key => {
            const control = <AbstractViewFormControl>this.controls[key];
            control.setRawValue(value[key]);
        });
    }

    public setControlValue<TValue>(name: string, value: TValue): void {
        const control = <ViewFormControl<TValue>>this.get(name);
        control.setRawValue(value);
    }
}
