import { Injectable } from '@angular/core';
import { ProduktStatus } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { FilterValues } from '@modules/produkt/page/produkt-uebersicht-gtue-em/produkt-uebersicht-gtue-em.component';

@Injectable({
    providedIn: 'root'
})
export class ProduktUebersichtFilterService {

  public static checkStatusNotOpen(filterObject: FilterValues, produkt: Produkt): boolean {
    return filterObject.status && produkt.status !== ProduktStatus.Offen;
  }

  public static checkProduktArtNotMatching(filterObject: FilterValues, produkt: Produkt): boolean {
    return filterObject.art && filterObject.art !== produkt.art;
  }

  public static checkORFilters(filterObject: FilterValues): boolean {
    return !filterObject.nummer && !filterObject.kennzeichen && !filterObject.fin;
  }

  public static checkNummer(filterObject: FilterValues, produkt: Produkt): boolean {
    return filterObject.nummer && produkt.auftrag.nummer?.toLocaleLowerCase().includes(filterObject.nummer.toLowerCase());
  }

  public static checkKennzeichen(filterObject: FilterValues, produkt: Produkt): boolean {
    return filterObject.kennzeichen && produkt.fahrzeug.kennzeichen?.toLocaleLowerCase().includes(filterObject.kennzeichen.toLowerCase());
  }

  public static checkFin(filterObject: FilterValues, produkt: Produkt): boolean {
    return filterObject.fin && produkt.fahrzeug.identnummer?.toLocaleLowerCase().includes(filterObject.fin.toLowerCase());
  }

  public static filterByDate(filterObject: FilterValues, produkt: Produkt): boolean {
    let result;
    if (ProduktUebersichtFilterService.checkVonAndBisIsTrue(filterObject)) {
      if (new Date(filterObject.von).getTime() <= new Date(produkt.erstelltAm).getTime()
        && new Date(filterObject.bis).getTime() >= new Date(produkt.erstelltAm).getTime()) {
        result = true;
      } else {
        return false;
      }
    } else if (!filterObject.von && filterObject.bis) {
      if (new Date(filterObject.bis).getTime() >= new Date(produkt.erstelltAm).getTime()) {
        result = true;
      } else {
        return false;
      }
    } else if (!filterObject.bis && filterObject.von) {
      if (new Date(filterObject.von).getTime() <= new Date(produkt.erstelltAm).getTime()) {
        result = true;
      } else {
        return false;
      }
    }
    return result;
  }

  public static checkVonAndBisIsTrue(filterObject: FilterValues): boolean {
    return (!!filterObject.von && !!filterObject.bis);
  }
}
