import { ChangeDetectionStrategy, Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { File } from '@app/class/file';
import { getOrientation } from '@app/function/image';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent implements OnInit {
  @Input()
  public file: File;

  public src: SafeUrl;
  public orientation: number;

  constructor(private readonly domSanitizer: DomSanitizer) { }

  public ngOnInit(): void {
    if (this.file) {
      const objectUrl = URL.createObjectURL(new Blob([this.file.data]));
      this.src = this.domSanitizer.bypassSecurityTrustUrl(objectUrl);
      this.orientation = getOrientation(this.file.data);
    }
  }

  public onImgLoad(container: HTMLElement): void {
    const objectUrl = this.domSanitizer.sanitize(SecurityContext.URL, this.src);
    URL.revokeObjectURL(objectUrl);

    if (4 < this.orientation && this.orientation < 9) {
      const { clientWidth, clientHeight } = container;
      if (clientWidth > clientHeight) {
        const padding = Math.round((clientWidth - clientHeight) * 0.5);
        container.style.padding = `${padding}px 0`;
      }
    }
  }
}
