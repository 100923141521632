import { Injectable } from '@angular/core';
import * as localforage from 'localforage';
import { from, Observable } from 'rxjs';

const PRIVACY_ACCEPTED_KEY = 'gtue-evaluate-mobility-privacy-accepted-20220331';

@Injectable({
    providedIn: 'root'
})
export class PrivacyService {
    public isAccepted(): Observable<boolean> {
        const getItem = localforage.getItem<boolean>(PRIVACY_ACCEPTED_KEY);
        return from(getItem);
    }

    public setIsAccepted(value: boolean): Observable<boolean> {
        const setItem = localforage.setItem<boolean>(PRIVACY_ACCEPTED_KEY, value);
        return from(setItem);
    }
}
