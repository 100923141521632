import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GltfGruppe } from '@app/config/gltf';
import { LackmessungPosition } from '@data/domain/schema/enum';
import { Lackmessung } from '@data/domain/schema/type';
import { ModelFileConfig } from '@modules/produkt/config/produkt-model-config';
import { ModelLoadResult } from '@shared/component/three/gltf/gltf.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { TrackBy } from '@shared/helper/track-by';
import { EnumValues } from '@shared/helper/values';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Scene } from 'three';

const GLTF_POSITION_MAP = {
  [LackmessungPosition.TuerVorneLinks]: GltfGruppe[GltfGruppe.EXT_Tuer_vorne_links],
  [LackmessungPosition.TuerHintenLinks]: GltfGruppe[GltfGruppe.EXT_Tuer_hinten_links],
  [LackmessungPosition.SeitenwandHintenLinks]: GltfGruppe[GltfGruppe.EXT_Seitenwand_links],
  [LackmessungPosition.Heckklappe]: GltfGruppe[GltfGruppe.EXT_Heckklappe],
  [LackmessungPosition.StossfaengerHinten]: GltfGruppe[GltfGruppe.EXT_Stossfaenger_hinten],
  [LackmessungPosition.SeitenwandHintenRechts]: GltfGruppe[GltfGruppe.EXT_Seitenwand_rechts],
  [LackmessungPosition.TuerHintenRechts]: GltfGruppe[GltfGruppe.EXT_Tuer_hinten_rechts],
  [LackmessungPosition.TuerVorneRechts]: GltfGruppe[GltfGruppe.EXT_Tuer_vorne_rechts],
  [LackmessungPosition.KotfluegelVorneRechts]: GltfGruppe[GltfGruppe.EXT_Kotfluegel_rechts],
  [LackmessungPosition.Motorhaube]: GltfGruppe[GltfGruppe.EXT_Motorhaube],
  [LackmessungPosition.StossfaengerVorne]: GltfGruppe[GltfGruppe.EXT_Stossfaenger_vorne],
  [LackmessungPosition.KotfluegelVorneLinks]: GltfGruppe[GltfGruppe.EXT_Kotfluegel_links],
  [LackmessungPosition.Dach]: GltfGruppe[GltfGruppe.EXT_Fahrzeugdach],
};

@Component({
  selector: 'app-produkt-detail-lackmessung-scene',
  templateUrl: './produkt-detail-lackmessung-scene.component.html',
  styleUrls: ['./produkt-detail-lackmessung-scene.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailLackmessungSceneComponent implements OnInit {
  public trackByIndex = TrackBy.trackByIndex;

  public scene: Scene;
  public animating$ = new BehaviorSubject<boolean>(false);

  public positionen = new EnumValues(LackmessungPosition);
  public names = GLTF_POSITION_MAP;

  public disabled$: Observable<{
    [key: number]: boolean;
  }>;

  @Input()
  public form: ViewFormGroup;

  @Input()
  public modelFileConfigs: ModelFileConfig[];

  @Output()
  public modelLoad = new EventEmitter<ModelLoadResult>();

  @Output()
  public positionSelect = new EventEmitter<LackmessungPosition>();

  public ngOnInit(): void {
    this.disabled$ = this.form.valueChanges.pipe(
      startWith({}),
      map(() => {
        const result = {};
        const value = <Lackmessung>this.form.getRawValue();
        value.messungen.forEach(messung => result[messung.position] = true);
        return result;
      })
    );
  }

  public onSceneLoad(scene: Scene): void {
    Assert.notNullOrUndefined(scene, 'scene');
    this.scene = scene;
  }

  public onModelLoad(modelLoadResult: ModelLoadResult): void {
    Assert.notNullOrUndefined(modelLoadResult, 'modelLoadResult');
    this.modelLoad.emit(modelLoadResult);
  }

  public onMessungClick(position: LackmessungPosition): void {
    Assert.notNullOrUndefined(position, 'position');
    this.positionSelect.next(position);
  }
}
