import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { BehaviorSubject } from 'rxjs';
import { Box3, Camera, Intersection, Object3D, Raycaster, Scene, Vector2, Vector3 } from 'three';

export enum Point {
  BackBottomLeft = 'bbl',
  BackBottomRight = 'bbr',
  BackTopRight = 'btr',
  BackTopLeft = 'btl',
  FrontBottomLeft = 'fbl',
  FrontBottomRight = 'fbr',
  FrontTopRight = 'ftr',
  FrontTopLeft = 'ftl',
  Center = 'cen'
}

interface Position {
  left: string;
  top: string;
}

interface Points {
  [key: string]: () => Vector3;
}

const PLANES = [
  [Point.BackBottomLeft, Point.BackTopRight],
  [Point.BackTopRight, Point.FrontTopLeft],
  [Point.FrontTopLeft, Point.FrontBottomRight],
  [Point.FrontBottomRight, Point.BackBottomLeft],
  [Point.BackBottomLeft, Point.FrontTopLeft],
  [Point.FrontBottomRight, Point.BackTopRight],
];

const DOTS = [
  [Point.BackBottomLeft, Point.BackBottomRight, Point.BackTopLeft],
  [Point.BackTopRight, Point.BackTopLeft, Point.FrontTopRight],
  [Point.FrontTopLeft, Point.FrontBottomLeft, Point.FrontTopRight],
  [Point.FrontBottomRight, Point.BackBottomRight, Point.FrontBottomLeft],
  [Point.BackBottomLeft, Point.BackTopLeft, Point.FrontBottomLeft],
  [Point.FrontBottomRight, Point.BackBottomRight, Point.FrontTopRight],
];

@Component({
  selector: 'app-gltf-indicator',
  templateUrl: './gltf-indicator.component.html',
  styleUrls: ['./gltf-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GltfIndicatorComponent {
  private ray = new Raycaster();
  private object: Object3D;
  private scene: Scene;
  private camera: Camera;
  private element: Element;
  private distance = 0;

  private origin: Vector3;
  private cameraPosition = new Vector3();
  private elementSize = new Vector2();

  public position$ = new BehaviorSubject<Position>(undefined);
  public visible$ = new BehaviorSubject<boolean>(true);
  public scale$ = new BehaviorSubject<number>(0);

  @Input()
  public name: string;

  @Input()
  public positions: Point[];

  @Input()
  public checkVisible = false;

  public init(camera: Camera, element: Element, scene: Scene): void {
    Assert.notNullOrUndefined(camera, 'camera');
    Assert.notNullOrUndefined(element, 'element');
    Assert.notNullOrUndefined(scene, 'scene');
    this.camera = camera;
    this.element = element;
    this.scene = scene;
    this.updateObject();
  }

  public update(): number {
    if (!this.object) {
      return this.distance;
    }

    const { clientWidth, clientHeight } = this.element;
    const cameraPosition = this.camera.position.clone();
    if (this.cameraPosition.equals(cameraPosition)
      && this.elementSize.x === clientWidth
      && this.elementSize.y === clientHeight) {
      return this.distance;
    }

    this.cameraPosition = cameraPosition;
    this.elementSize.set(clientWidth, clientHeight);

    if (!this.origin) {
      this.origin = this.calculateOrigin();
    }

    this.distance = this.origin.distanceToSquared(cameraPosition);

    if (this.checkVisible) {
      this.updateVisible(cameraPosition);
    }
    this.updatePosition(clientWidth, clientHeight);

    return this.distance;
  }

  public getZIndex(scale: number): number {
    return Math.round(scale * 2);
  }

  private updatePosition(clientWidth: number, clientHeight: number): void {
    const position = this.origin.clone().project(this.camera);
    const left = (position.x + 1) / 2 * clientWidth;
    const top = -(position.y - 1) / 2 * clientHeight;
    this.position$.next({
      left: `${Math.floor(left)}px`,
      top: `${Math.floor(top)}px`,
    });
  }

  private updateVisible(cameraPosition: Vector3): void {
    const direction = this.origin.clone().sub(cameraPosition).setLength(1);
    this.ray.set(cameraPosition, direction);

    const hit = this.ray.intersectObject(this.scene, true)[0];
    const visible = hit && hit.object && hit.object.name.includes(this.name);
    this.visible$.next(visible);
  }

  private calculateOrigin(): Vector3 {
    const points = this.getPoints();
    if (this.positions) {
      if (this.positions.length > 1) {
        const position = new Vector3();
        this.positions.forEach(x => position.add(points[x]()));
        position.divideScalar(this.positions.length);
        return position;
      }
      return points[this.positions[0]]();
    } else {
      const sceneBox = new Box3().setFromObject(this.scene);
      sceneBox.applyMatrix4(this.scene.matrixWorld);

      const sceneCenter = new Vector3();
      sceneBox.getCenter(sceneCenter);

      const objectCenter = points[Point.Center]();
      const objectPoint = this.getIntersection(objectCenter, sceneCenter)?.point;
      if (objectPoint) {
        return objectPoint;
      }

      // find farthest and largest plane

      let max = Number.MIN_VALUE;
      let dot: Point[];
      PLANES.forEach((plane, index) => {
        const p1 = points[plane[0]]();
        const p2 = points[plane[1]]();
        const mid = p1.clone().add(p2).multiplyScalar(0.5);

        const area = p1.distanceToSquared(p2);
        const dist = mid.distanceToSquared(sceneCenter);
        const value = area * 2 + dist;

        if (value > max) {
          max = value;
          dot = DOTS[index];
        }
      });

      return this.calculateCenterOfLongestLine(dot, points, sceneCenter);
    }
  }

  private calculateCenterOfLongestLine(dot: Point[], points: Points, sceneCenter: Vector3): Vector3 {
    const origin = points[dot[0]]();
    const targetX = points[dot[1]]();
    const targetY = points[dot[2]]();

    const xDir = new Vector3().subVectors(targetX, origin);
    const xLength = xDir.length();
    xDir.multiplyScalar(0.01);

    const yDir = new Vector3().subVectors(targetY, origin);
    const yLength = yDir.length();
    yDir.multiplyScalar(0.01);

    const center = new Vector3();
    let counter = 0;
    let counterMax = 0;
    let point: Vector3;

    const inverse = yLength > xLength;
    for (let x = 0; x < 99; ++x) {
      const start = origin.add(inverse ? xDir : yDir).clone();
      for (let y = 0; y < 99; ++y) {
        start.add(inverse ? yDir : xDir);
        const intersection = this.getIntersection(start, sceneCenter);
        if (intersection) {
          center.add(intersection.point);
          if (++counter > counterMax) {
            counterMax = counter;
            point = center.clone();
          }
        } else {
          counter = 0;
          center.set(0, 0, 0);
        }
      }
    }

    if (!point) {
      throw new Error(`Could not find any matching points for '${this.name}'`);
    }

    return point.divideScalar(counterMax);
  }

  /*
     3----2
    /|   /|
   7----6 |
   | 0--|-1
   |/   |/
   4----5
  */

  private getPoints(): Points {
    const box = new Box3().setFromObject(this.object);
    return { // Corners
      [Point.BackBottomLeft]: () => new Vector3(box.min.x, box.min.y, box.min.z),
      [Point.BackBottomRight]: () => new Vector3(box.max.x, box.min.y, box.min.z),
      [Point.BackTopRight]: () => new Vector3(box.max.x, box.max.y, box.min.z),
      [Point.BackTopLeft]: () => new Vector3(box.min.x, box.max.y, box.min.z),
      [Point.FrontBottomLeft]: () => new Vector3(box.min.x, box.min.y, box.max.z),
      [Point.FrontBottomRight]: () => new Vector3(box.max.x, box.min.y, box.max.z),
      [Point.FrontTopRight]: () => new Vector3(box.max.x, box.max.y, box.max.z),
      [Point.FrontTopLeft]: () => new Vector3(box.min.x, box.max.y, box.max.z),
      [Point.Center]: () => new Vector3(
        (box.min.x + box.max.x) * 0.5,
        (box.min.y + box.max.y) * 0.5,
        (box.min.z + box.max.z) * 0.5),
    };
  }

  private updateObject(): void {
    if (this.name) {
      this.scene.traverse(obj => {
        if (obj.name.endsWith(this.name)) {
          this.object = obj;
        }
      });
    } else {
      this.object = this.scene;
    }
  }

  private getIntersection(target: Vector3, origin: Vector3): Intersection {
    const direction = target.clone().sub(origin).setLength(1);
    this.ray.set(origin, direction);
    // intersection
    return this.ray.intersectObject(this.object)[0];
  }
}
