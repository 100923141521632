<div class="nav">
  <div class="header-wrapper" [ngClass]="isProdEnvironment() ? 'env-prod' : 'env-dev'">
    <div class="footer-wrapper" *ngIf="(viewport$ | async) === viewport.Desktop">
      <app-footer></app-footer>
    </div>
    <div *ngIf="isActive && (viewport$ | async) !== viewport.Desktop && (produkt$ | async)"
         class="title-value">{{auftragsnummer$ | async}}</div>
    <div class="quick-menu-wrapper">
      <app-button class="quick-menu-item" [title]="'footer.support' | translate" [matMenuTriggerFor]="menu">
        <app-mat-icon [newStyle]="true" class="new-style" icon="icon-alert-help"></app-mat-icon>
      </app-button>
      <mat-menu #menu="matMenu">
        <app-help-menu></app-help-menu>
      </mat-menu>
      <div class="divider-right"></div>
      <div class="sync">
        <app-button (action)="onSyncClick()" [count]="syncCount$ | async" countAlign="center">
          <app-mat-icon [class.animation-cycle]="syncInProgress$ | async" icon="sync" size="2rem"> </app-mat-icon>
        </app-button>
      </div>
    </div>
  </div>
  <app-progress-bar class="navigating" *ngIf="navigating$ | async"></app-progress-bar>
</div>