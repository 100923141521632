import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailKalkulationErrorCanActivate  {

  constructor( readonly snackBarService: SnackBarService,
                readonly router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    Assert.notNullOrUndefined(route, 'route');
    const produktId = route.paramMap.get('id') ?? route.parent.paramMap.get('id');
    const subject = new Subject<boolean>();
    this.snackBarService.error('Kalkulation konnte nicht abgeschlossen werden');
    this.navigateToBaseRoute(produktId);
    subject.next(true);
    return subject.asObservable();
  }

  protected navigateToBaseRoute(produktId: string): void {
    this.router.navigate(['produkt', 'detail', produktId, 'kalkulation']);
  }
}
