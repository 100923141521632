import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import * as AWS from 'aws-sdk';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CognitoService } from './cognito.service';

@Injectable({
    providedIn: 'root'
})
export class S3EntwurfFileService {
    constructor(private readonly cognito: CognitoService) {
        Assert.notNullOrUndefined(cognito, 'cognito');
    }

    public getSignedUrl(path: string, downloadFilename: string): Observable<string> {
        Assert.notNullOrEmpty(path, 'path');
        Assert.notNullOrEmpty(downloadFilename, 'downloadFilename');
        return this.cognito.secureContext().pipe(map(() => {
            const s3 = new AWS.S3({ apiVersion: '2006-03-01' });
            const url = s3.getSignedUrl('getObject', {
                Bucket: environment.aws.s3.entwurf.name,
                Key: path,
                Expires: 5 * 60,
                ResponseContentDisposition: `inline; filename="${encodeURIComponent(downloadFilename)}"`,
            });
            return url;
        }));
    }

    public downloadKalkulation(): Observable<string> {
      return this.getSignedUrl('buero/prouktId/kalkulation.pdf', 'timeStamp_kalkulation.pdf');
    }
}
