import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@app/service/auth.service';
import { Assert } from '@shared/helper/assert';
import { DisplayService } from '@shared/service/display.service';
import { PrivacyDialogService } from '@shared/service/privacy-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import packageInfo from '../../../../package.json';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, OnDestroy {
  public visible$: BehaviorSubject<boolean>;
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;

  public version = packageInfo.version;
  public environmentName = '';
  public context$: Observable<any>;

  public isActive = false;

  private routerSubscription: Subscription;

  constructor(private readonly authService: AuthService,
              private readonly privacyDialogService: PrivacyDialogService,
              private readonly displayService: DisplayService,
              private readonly viewportService: ViewportService,
              private router: Router) {
    Assert.notNullOrUndefined(authService, 'authService');
    Assert.notNullOrUndefined(privacyDialogService, 'privacyDialogService');
    Assert.notNullOrUndefined(displayService, 'displayService');
    Assert.notNullOrUndefined(viewportService, 'viewportService');
    Assert.notNullOrUndefined(router, 'router');
  }

  public ngOnInit(): void {
    this.environmentName = environment.name;
    this.viewport$ = this.viewportService.observe();
    this.visible$ = this.displayService.showFooter;
    this.context$ = this.authService.getClaims().pipe(
      map(claims => ({
        ...claims,
        version: this.version,
        userAgent: `${location}, ${navigator.userAgent}`
      }))
    );
    if (this.router.url.includes('produkt/detail')) {
      this.isActive = true;
    }
    this.routerSubscription = this.router.events.subscribe((val: NavigationEnd) => {
      this.isActive = val.url && val.url.includes('produkt/detail');
    });
  }

  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  public onPolicyClick(): void {
    this.privacyDialogService.open().subscribe();
  }

  public getMailTo(mail: string, subject: string, body: string): string {
    return `mailto:${mail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }

  public isProdEnvironment(): boolean {
    return this.environmentName === 'PROD';
  }
}
