import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { Assert } from '@shared/helper/assert';
import { TrackBy } from '@shared/helper/track-by';
import { BehaviorSubject } from 'rxjs';
import { ListOptionComponent } from '../list-option/list-option.component';

export interface SelectionListChangeEvent {
  name: string;
  value: boolean;
}

@Component({
  selector: 'app-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectionListComponent implements AfterViewInit {
  public trackByName = TrackBy.trackByName;

  public options$ = new BehaviorSubject<ListOptionComponent[]>([]);

  @ContentChildren(ListOptionComponent, { descendants: true })
  public optionsQuery: QueryList<ListOptionComponent>;

  @Input()
  public multiline: boolean;

  @Input()
  public header: string;

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.options$.next(this.optionsQuery.toArray());
    }, 100);
  }

  public onSelectionChange(event: MatSelectionListChange): void {
    Assert.notNullOrUndefined(event, 'event');
    this.options$.subscribe(options => {
      options.forEach(option => {
        if ((option.id || option.name) === event.options[0].value) {
          option.selected = event.options[0].selected;
        }
      });
    });
  }

}
