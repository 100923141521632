<app-card spacing="8px">
    <h3>{{name + '.dateien' | translate}}</h3>
    <app-file-gallery [fileIds]="dateien$ | async" [dialogTitle]="name + '.addfile'" (add)="onFileAdd($event)"
        [showCamera]="true" [showFolder]="true" (delete)="onFileDelete($event)"></app-file-gallery>
</app-card>
<app-card spacing="8px">
    <h3>{{name + '.sprachnachrichten' | translate}}</h3>
    <app-audio-gallery [files]="sprachnachrichten$ | async" (add)="onAudioAdd($event)" (delete)="onAudioDelete($event)"
        (error)="onAudioError()"></app-audio-gallery>
</app-card>
<app-card spacing="8px">
    <h3>{{name + '.textnachrichten' | translate}}</h3>
    <app-chat [messages]="textnachrichten$ | async" (add)="onChatAdd($event)" (delete)="onChatDelete($event)">
    </app-chat>
</app-card>