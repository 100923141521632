import { Injectable } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProduktDetailFahrzeugscheinDialogComponent, ProduktDetailFahrzeugscheinDialogData } from '../component/produkt-detail-fahrzeugschein-dialog/produkt-detail-fahrzeugschein-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailFahrzeugscheinDialogService {
    constructor(private readonly templateDialog: TemplateDialogService) {
        Assert.notNullOrUndefined(templateDialog, 'templateDialog');
    }

    public open(title: string): Observable<string> {
        Assert.notNullOrEmpty(title, 'title');

        const data: ProduktDetailFahrzeugscheinDialogData = {};
        const buttons = [
            'fahrzeugschein.retry',
            'fahrzeugschein.cancel',
        ];
        return this.templateDialog.openTemplate(title, buttons, ProduktDetailFahrzeugscheinDialogComponent, data, true).pipe(
            map(result => result.name === buttons[1] ? null : result.data.vin)
        );
    }
}
