import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { guid } from '@app/function/guid';
import {
  VtiKalkulationDto,
  VtiKalkulationService
} from '@data/api-gateway/service/vti-kalkulation.service';
import { AbrechnungProvider, AbrechnungService, AnhangId } from '@data/domain/schema/enum';
import { Abrechnung, KalkulationInput, Produkt } from '@data/domain/schema/type';
import { ProduktAbrechnungService } from '@data/domain/service/feature/produkt-abrechnung.service';
import { ProduktAnhaengeService } from '@data/domain/service/feature/produkt-anhaenge.service';
import { ProduktKalkulationService } from '@data/domain/service/feature/produkt-kalkulation.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProduktDetailKalkulationCanActivate  {

  constructor(readonly vtiSchadenKalkulationService: VtiKalkulationService,
              readonly produktService: ProduktDetailResolveService,
              readonly produktAnhaengeService: ProduktAnhaengeService,
              readonly produktKalkulationService: ProduktKalkulationService,
              readonly snackBarService: SnackBarService,
              readonly router: Router,
              private readonly produktAbrechnungService: ProduktAbrechnungService) {
  }

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    Assert.notNullOrUndefined(route, 'route');
    const produktId = route.paramMap.get('id') ?? route.parent.paramMap.get('id');
    const dossierId = route.paramMap.get('dossierId') ?? route.parent.paramMap.get('dossierId');
    const subject = new Subject<boolean>();
    this.vtiSchadenKalkulationService.get(+dossierId, produktId).pipe(
      first()
    ).subscribe(
      response => {
        if (response && response.kalkulation) {
          this.produktService.resolve(produktId, false).pipe(first()).subscribe(
            produkt => {
              const input = this.mapToKalkulationInput(produktId, <VtiKalkulationDto>(JSON).parse(JSON.stringify(response.kalkulation)));
              this.produktKalkulationService.save(produktId, input);
              this.saveKalkulationInAnhaenge(produkt);
              const abrechnungKalkulation = this.createKalkulationAbrechnung(dossierId);
              this.produktAbrechnungService.saveAbrechnung(produkt.art, produktId, abrechnungKalkulation.id, abrechnungKalkulation);
              this.navigateToBaseRoute(produktId);
              subject.next(true);
            }
          );
        } else {
          this.snackBarService.error('Kalkulation konnte nicht mit EM synchronisiert werden');
          this.navigateToBaseRoute(produktId);
          subject.next(true);
          console.error('Response /dat/kalkulation GET is null ', response);
        }
      },
      error => {
        this.snackBarService.error('Kalkulation konnte nicht mit EM synchronisiert werden');
        console.error(error);
        this.navigateToBaseRoute(produktId);
        subject.next(true);
      }
    );
    return subject.asObservable();
  }

  protected navigateToBaseRoute(produktId: string): void {
    this.router.navigate(['produkt', 'detail', produktId, 'kalkulation']);
  }

  private mapToKalkulationInput(produktId: string, vtiKalkulationDto: VtiKalkulationDto): KalkulationInput {
    return {
      id: produktId,
      bezugsdatum: vtiKalkulationDto.bezugsdatum,
      dossierId: vtiKalkulationDto.dossierId,
      reparaturkostenNetto: vtiKalkulationDto.reparaturkostenNetto,
      reparaturkostenBrutto: vtiKalkulationDto.reparaturkostenBrutto,
      dokument: `${produktId}/kalkulation`
    };
  }

  private saveKalkulationInAnhaenge(produkt: Produkt): void {
    this.produktAnhaengeService.saveAnhang(produkt.id, AnhangId.Kalkulation, {
      id: AnhangId.Kalkulation,
      bezeichnung: `kalkulation.pdf`,
      quelle: `${produkt.id}/kalkulation`
    });
  }

  private createKalkulationAbrechnung(dossierId: string): Abrechnung {
    return {
      id: guid(),
      datum: moment.utc().toISOString(),
      externalProvider: AbrechnungProvider.Dat,
      externalService: AbrechnungService.Kalkulation,
      identifier: dossierId,
    };
  }
}
