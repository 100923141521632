import { Injectable } from '@angular/core';
import { UebersichtManuell } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import {
    ProduktDetailTextbausteinFormViewFactory
} from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { FormViewModelBaseFactory } from './form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailUebersichtSonstigesFormViewFactory extends FormViewModelBaseFactory<UebersichtManuell> {

    constructor(
        private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory) {
        super();
        Assert.notNullOrUndefined(produktDetailTextbausteinFormViewFactory, 'produktDetailTextbausteinFormViewFactory');
    }

    public createField(model: UebersichtManuell, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Uebersicht.fields.Sonstiges.fields;
        switch (name) {
            case fields.Bilder.name:
                return new ViewFormControl(model.bilder || []);
            case fields.Bezeichnung.name:
                return new ViewFormControl(model.bezeichnung, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase
                });
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: UebersichtManuell, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Uebersicht.fields.Sonstiges.fields;

        if (arrayName === fields.Bezeichnungen.name) {
            const bezeichnungen = model.bezeichnungen || [];
            return new ViewFormArray(
                bezeichnungen.map(
                    bezeichnung => this.produktDetailTextbausteinFormViewFactory
                        .create(bezeichnung, arrayFields)
                ));
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
