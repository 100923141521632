<mat-stepper [class.display-none]="!horizontal" #stepperHorizontal [linear]="linear">
    <mat-step *ngFor="let step of steps;trackBy:trackByInstance"
              [label]="(step.completedChange | async) ? ((step.labelCompletedChange | async) || step.label) : step.label"
              [completed]="step.completedChange | async" [editable]="step.editableChange | async">
        <ng-container *ngIf="horizontal">
            <ng-template matStepLabel>
                <ng-container *ngTemplateOutlet="step.labelContent"></ng-container>
            </ng-template>
            <ng-container *ngTemplateOutlet="step.content"></ng-container>
        </ng-container>
    </mat-step>
</mat-stepper>
<mat-vertical-stepper [class.display-none]="horizontal" #stepperVertical [linear]="linear">
    <mat-step *ngFor="let step of steps;trackBy:trackByInstance"
              [label]="(step.completedChange | async) ? ((step.labelCompletedChange | async) || step.label) : step.label"
              [completed]="step.completedChange | async" [editable]="step.editableChange | async">
        <ng-container *ngIf="!horizontal">
            <ng-template matStepLabel>
                <ng-container *ngTemplateOutlet="step.labelContent"></ng-container>
            </ng-template>
            <ng-container *ngTemplateOutlet="step.content"></ng-container>
        </ng-container>
    </mat-step>
</mat-vertical-stepper>
