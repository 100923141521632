import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AbschlussDownloadService } from '@data/api-gateway';
import { BilderDownloadService } from '@data/api-gateway/service/bilder-download.service';
import { ProduktArt, ProduktArtNachbewertung, ProduktArtUebersicht, ProduktStatus } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import {
  ProduktDetailNachbewertungDialogComponent
} from '@modules/produkt/component/produkt-detail-nachbewertung-dialog/produkt-detail-nachbewertung-dialog.component';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktUebersichtFilterService } from '@modules/produkt/service/produkt-uebersicht-filter.service';
import { ProduktUebersichtResolveService } from '@modules/produkt/service/produkt-uebersicht-resolve.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { TableComponent } from '@shared/component/data-table';
import { Assert } from '@shared/helper/assert';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, take } from 'rxjs/operators';

interface ProduktNachbewertungDialogData {
  produkt: Produkt;
}

export interface FilterValues {
  art: number;
  nummer: string;
  kennzeichen: string;
  fin: string;
  von: string;
  bis: string;
  status: number;
}

@Component({
  selector: 'app-produkt-uebersicht-gtue-em',
  templateUrl: './produkt-uebersicht-gtue-em.component.html',
  styleUrls: ['./produkt-uebersicht-gtue-em.component.scss']
})
export class ProduktUebersichtGtueEmComponent implements OnInit, OnDestroy {
  public trackById = TrackBy.trackById;

  public produkte: Produkt[];
  public name = 'produktUebersicht';
  public produktStatus = new EnumValues(ProduktStatus);
  public produktStatusEnum = ProduktStatus;
  public loading$ = new BehaviorSubject(false);
  public searchTerm = '';
  public form: ViewFormGroup;
  public produktArt = ProduktArt;
  public produktArtNachbewertungValues = new EnumValues(ProduktArtNachbewertung);
  public readonly ButtonType = ButtonType;

  public produktArtEnumValues = new EnumValues(ProduktArtUebersicht);

  public filter$ = new BehaviorSubject<string>('');
  public filterValues: FilterValues = {
    art: undefined,
    nummer: '',
    kennzeichen: '',
    fin: '',
    von: '',
    bis: '',
    status: 0,
  };

  public minDate: Date;
  public maxDate: Date = new Date();
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;

  @ViewChild('table')
  public table: TableComponent;

  @ViewChild('nachbewertungArtSelection')
  public selectedNachbewertungArt: ProduktDetailNachbewertungDialogComponent;

  @ViewChild('dialogConfirmDuplicate', {static: true})
  public dialogConfirmDuplicateTemplate: TemplateRef<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly produktUebersichtResolveService: ProduktUebersichtResolveService,
    private readonly router: Router,
    private readonly viewportService: ViewportService,
    private readonly bilderDownloadService: BilderDownloadService,
    private readonly templateDialogService: TemplateDialogService,
    private readonly produktService: ProduktService,
    private readonly filterService: ProduktUebersichtFilterService,
    private readonly abschlussDownloadService: AbschlussDownloadService,
    private readonly snackBarService: SnackBarService) {
    Assert.notNullOrUndefined(produktUebersichtResolveService, 'produktUebersichtResolveService');
    Assert.notNullOrUndefined(router, 'router');
    Assert.notNullOrUndefined(bilderDownloadService, 'bilderDownloadService');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
    Assert.notNullOrUndefined(produktService, 'produktService');
    Assert.notNullOrUndefined(filterService, 'filterService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    this.viewport$ = this.viewportService.observe();
  }

  public ngOnInit(): void {
    this.produkte = this.produktUebersichtResolveService.get();
    this.setProdukteErstelltAmMitAuftragDatum();
    this.setProdukteAuftragNummer();
    this.minDate = this.getMinDate(this.produkte);
    this.form = new ViewFormGroup({
      searchTerm: new ViewFormControl(this.searchTerm, {
        formatter: ViewFormControlFormatters.toUppercase
      }),
      art: new ViewFormControl(0),
      nummer: new ViewFormControl(''),
      kennzeichen: new ViewFormControl(''),
      fin: new ViewFormControl(''),
      von: new ViewFormControl(this.minDate),
      bis: new ViewFormControl(this.maxDate),
      status: new ViewFormControl(false),
    });
    this.createFilterSubscriptions();
  }

  public onAction(produkt: Produkt): void {
    Assert.notNullOrUndefined(produkt, 'produkt');
    this.router.navigate(['produkt', 'detail', produkt.id]);
  }

  public onDownloadClick(event: MouseEvent, identnummer: string, disabled: boolean): void {
    event.stopPropagation();
    if (disabled) {
      return;
    }
    this.abschlussDownloadService.get(identnummer).pipe(take(1)
    ).subscribe(response => {
      if (response.url) {
        this.onDownloadResponse(response.url);
      }
    });
  }

  public filterPredicate(produkt: Produkt, filter: string): boolean {
    const filterObject = <FilterValues>JSON.parse(filter);

    if (ProduktUebersichtFilterService.checkStatusNotOpen(filterObject, produkt)) {
      return false;
    }

    if (ProduktUebersichtFilterService.checkProduktArtNotMatching(filterObject, produkt)) {
      return false;
    }

    if (produkt.erstelltAm) {
      const dateResult = ProduktUebersichtFilterService.filterByDate(filterObject, produkt);
      if (dateResult !== undefined) {
        if (!dateResult) {
          return false;
        }
      }
    }

    if (ProduktUebersichtFilterService.checkORFilters(filterObject)) {
      return true;
    }

    if (ProduktUebersichtFilterService.checkNummer(filterObject, produkt)) {
      return true;
    }

    if (ProduktUebersichtFilterService.checkKennzeichen(filterObject, produkt)) {
      return true;
    }

    if (ProduktUebersichtFilterService.checkFin(filterObject, produkt)) {
      return true;
    }
    return false;
  }

  public onBilderDownload(event: MouseEvent, produktId: string, disabled: boolean): void {
    Assert.notNullOrUndefined(produktId, 'produktId');
    event.stopPropagation();

    if (disabled) {
      return;
    }

    this.loading$.next(true);
    this.bilderDownloadService.get(produktId).pipe(take(1)).subscribe({
      next: url => {
        if (url) {
          window.open(url);
          this.loading$.next(false);
        }
      },
      error: error => {
        this.loading$.next(false);
        error.status === 404 ? this.snackBarService.info('produkt.uebersicht.downloadKeineBilder')
          : this.snackBarService.error('produkt.uebersicht.downloadBilderError');
      }
    });
  }

  public onClickProduktSearch(searchText: string): void {
    this.searchProdukt(searchText);
  }

  public onResetIdentAndSearch(): void {
    this.form.patchValue({searchTerm: ''});
    this.searchProdukt();
  }

  public alphaNumericOnly(e: KeyboardEvent): boolean {
    return String(e.key).match(/[^a-zA-Z0-9]/g) === null;
  }

  public onClickDuplicate($event: MouseEvent, element: Produkt): void {
    const title = `nachbewertung.title`;
    const buttons = [`feature.cancel`, `feature.confirm`];
    const data: ProduktNachbewertungDialogData = {produkt: element};
    $event.stopPropagation();

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogConfirmDuplicateTemplate, data).subscribe(result => {
      if (result.name && result.name === buttons[ 1 ]) {
        this.loading$.next(true);
        const selectedProduktArt = this.selectedNachbewertungArt.getSelectedValue();
        this.produktService.create(selectedProduktArt).pipe(first()).subscribe(
          produkt => {
            this.subscriptions.push(this.produktService.getDuplikat(element.id, produkt.id, selectedProduktArt).pipe(first()).subscribe({
                next: (v) => {
                  this.loading$.next(false);
                  this.router.navigateByUrl(`/produkt/detail/${produkt.id}`);
                }, error: (err) => {
                  this.loading$.next(false);
                  console.error(err);
                  this.snackBarService.error('Fehler beim duplizieren des Produktes!');
                }
              }
            ));
          });
      }
    });
  }

  public onResetFilter(): void {
    this.form.patchValue({
      art: undefined,
      nummer: '',
      kennzeichen: '',
      fin: '',
      von: this.minDate,
      bis: this.maxDate,
      status: 0,
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private setProdukteErstelltAmMitAuftragDatum(): void {
    this.produkte.forEach((p => {
      if (p.erstelltAm === null && p.auftrag.erstellungsTag) {
        p.erstelltAm = p.auftrag.erstellungsTag;
      }
    }));
  }

  private setProdukteAuftragNummer(): void {
    this.produkte.forEach((p => {
      if (p.auftrag.nummer === null && p.auftrag.vorgangsnummer) {
        p.auftrag.nummer = p.auftrag.vorgangsnummer;
      }
    }));
  }

  private searchProdukt(value: string = ''): void {
    this.produktUebersichtResolveService.resolve(value).pipe(first()).subscribe(
      {
        next:
          result => {
            if (result.length < 1) {
              this.snackBarService.info('produkt.uebersicht.IdentSucheNothingFound');
              return;
            }
            this.produkte = result;
            this.table.refresh(this.produkte);
          },
        error: error => {
          this.snackBarService.info('produkt.uebersicht.Suche', error);
        }
      });
  }

  private onDownloadResponse(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }

  private createFilterSubscriptions() {
    this.subscriptions.push(this.form.get('art').valueChanges.subscribe(art => {
      this.filterValues.art = art;
      this.filter$.next(JSON.stringify(this.filterValues));
    }));

    this.subscriptions.push(this.form.get('nummer').valueChanges.subscribe(nummer => {
      this.filterValues.nummer = nummer;
      this.filter$.next(JSON.stringify(this.filterValues));
    }));

    this.subscriptions.push(this.form.get('kennzeichen').valueChanges.subscribe(kennzeichen => {
      this.filterValues.kennzeichen = kennzeichen;
      this.filter$.next(JSON.stringify(this.filterValues));
    }));

    this.subscriptions.push(this.form.get('fin').valueChanges.subscribe(fin => {
      this.filterValues.fin = fin;
      this.filter$.next(JSON.stringify(this.filterValues));
    }));

    this.subscriptions.push(this.form.get('von').valueChanges.subscribe(von => {
      this.filterValues.von = von;
      this.filter$.next(JSON.stringify(this.filterValues));
    }));

    this.subscriptions.push(this.form.get('bis').valueChanges.subscribe(bis => {
      this.filterValues.bis = bis;
      this.filter$.next(JSON.stringify(this.filterValues));
    }));

    this.subscriptions.push(this.form.get('status').valueChanges.subscribe(status => {
      this.filterValues.status = status;
      this.filter$.next(JSON.stringify(this.filterValues));
    }));
  }

  private getMinDate(produkte: Produkt[]): Date {
    if (!produkte || produkte.length === 0) {
      return new Date();
    }
    produkte.sort((a, b) =>
      new Date(b.erstelltAm).getTime() - new Date(a.erstelltAm).getTime());
    return new Date(produkte[ produkte.length - 1 ].erstelltAm);
  }
}
