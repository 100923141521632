import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output, TemplateRef,
  ViewChild
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { guid } from '@app/function/guid';
import {
  AusstattungDto,
  ExternalDataServiceResponseCode,
  FahrzeugDto,
  FahrzeugService,
  FahrzeugServiceRequest,
  FahrzeugServiceResponse
} from '@data/api-gateway';
import {
  AbrechnungProvider,
  AbrechnungService,
  FahrzeugAntriebsart,
  FahrzeugAntriebstechnologie,
  FahrzeugAnzahlVorbesitzer,
  FahrzeugAnzahlVorbesitzerLegacy,
  FahrzeugArt,
  FahrzeugBauform, FahrzeugGetriebart, FahrzeugLaufleistungBeschreibung,
  FahrzeugLaufleistungEinheit,
  FahrzeugSchadstoffklasse,
  FahrzeugScheckheftGepflegt, ProduktArt
} from '@data/domain/schema/enum';
import {
  Abrechnung,
  Ausstattung,
  AusstattungGruppe,
  AusstattungTeil,
  FahrzeugExternalServicesReference,
  Produkt
} from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import {
  FeatureField, FeatureFieldArray,
  FeatureFieldGroup,
  FeatureFields,
  PRODUKT_CONFIG_FEATURES
} from '@modules/produkt/config/produkt-config';
import {
  ProduktDetailFahrzeugLaufleistungenFormViewFactory
} from '@modules/produkt/factory/fahrzeug/produkt-detail-fahrzeug-laufleistungen-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { DatSearchService } from '@modules/produkt/service/dat-search.service';
import { ProduktDetailFahrzeugDialogService } from '@modules/produkt/service/produkt-detail-fahrzeug-dialog.service';
import {
  ProduktDetailFahrzeugscheinDialogService
} from '@modules/produkt/service/produkt-detail-fahrzeugschein-dialog.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues, ObjectValues, Values } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, first, map, tap } from 'rxjs/operators';

interface ProduktSelectDialogData {
  identnummer: string;
  produkte: Produkt[];
}

@Component({
  selector: 'app-produkt-detail-fahrzeug-form',
  templateUrl: './produkt-detail-fahrzeug-form.component.html',
  styleUrls: ['./produkt-detail-fahrzeug-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailFahrzeugFormComponent implements OnInit, AfterViewInit, OnDestroy {
  public trackByField = TrackBy.trackByField;
  public trackByIndex = TrackBy.trackByIndex;

  public now = new Date();
  public fieldBemerkungen = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Bemerkungen.name;

  @Input()
  public laufleistungenVisible = false;

  @Input()
  public produkt: Produkt;

  @Input()
  public form: ViewFormGroup;

  @Input()
  public name: string;

  @Input()
  public fields: FeatureFields;

  @Input()
  public visibleFields: FeatureFields;

  @Output()
  public ausstattungResolved = new EventEmitter<Ausstattung>();

  @Output()
  public abrechnungResolved = new EventEmitter<Abrechnung>();

  @Input()
  public fluid = true;

  @Input()
  public manuellFahrzeugartDisabled = true;

  @Input()
  public manuellBauformDisabled = true;

  @Input()
  public vinDisabled = false;

  @Output()
  public save = new EventEmitter<Produkt>();

  public isAcProdukt = false;
  public fahrzeugart = new EnumValues(FahrzeugArt);
  public bauform = new EnumValues(FahrzeugBauform);
  public fahrzeugLaufleistungEinheit = new EnumValues(FahrzeugLaufleistungEinheit);
  public antriebsart = new EnumValues(FahrzeugAntriebsart);
  public antriebstechnologie = new EnumValues(FahrzeugAntriebstechnologie);
  public schadstoffklasse = new EnumValues(FahrzeugSchadstoffklasse);
  public getriebeart = new EnumValues(FahrzeugGetriebart);
  public scheckheftGepflegt = new EnumValues(FahrzeugScheckheftGepflegt);
  public anzahlVorbesitzer = new EnumValues(FahrzeugAnzahlVorbesitzer);

  public kbaRequestActiveChange = new BehaviorSubject<boolean>(false);
  public datRequestActive$: BehaviorSubject<boolean>;
  public datRequestEnabled$ = new BehaviorSubject<boolean>(true);

  public get identnummer(): ViewFormControl<string> {
    const fields = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields;
    return <ViewFormControl<string>>this.form.get(fields.Identnummer.name);
  }

  public manuellFahrzeugartDisabled$ = new BehaviorSubject<boolean>(true);
  public manuellBauformDisabled$ = new BehaviorSubject<boolean>(true);

  public Buttontype = ButtonType;
  public laufleistungenFields: FeatureFields;
  public formLaufleistungen: ViewFormArray;
  public formLaufleistungenFirst: ViewFormGroup = undefined;
  public formLaufleistungenSecond: ViewFormGroup = undefined;
  public buttonLaufleistungenIndex0Visible$ = new BehaviorSubject<boolean>(true);
  public buttonLaufleistungenIndex1Visible$ = new BehaviorSubject<boolean>(false);
  public laufleistungBeschreibung$ = new BehaviorSubject<Values>(new ObjectValues(FahrzeugLaufleistungBeschreibung));
  public laufleistungenBeschreibungFirst$ = new BehaviorSubject<Values>(new ObjectValues(FahrzeugLaufleistungBeschreibung));
  public laufleistungenBeschreibungSecond$ = new BehaviorSubject<Values>(new ObjectValues(FahrzeugLaufleistungBeschreibung));

  @ViewChild('bemerkungen')
  public bemerkungenElement: TextbausteineComponent;

  @ViewChild('dialogConfirm', {static: true})
  public dialogConfirmTemplate: TemplateRef<any>;

  @ViewChild('dialogSelectProdukt', {static: true})
  public dialogSelectProduktTemplate: TemplateRef<any>;

  private produkteWithSameIdentnummer: Produkt[];
  private selectedProduktIndex = 0;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly datSearchService: DatSearchService,
    private readonly produktService: ProduktService,
    private readonly fahrzeugService: FahrzeugService,
    private readonly snackBarService: SnackBarService,
    private readonly fahrzeugDialogService: ProduktDetailFahrzeugDialogService,
    private readonly textbausteineService: TextbausteineService,
    private readonly templateDialogService: TemplateDialogService,
    private readonly produktDetailFahrzeugLaufleistungenFormViewFactory: ProduktDetailFahrzeugLaufleistungenFormViewFactory,
    private readonly fahrzeugscheinDialogService: ProduktDetailFahrzeugscheinDialogService) {
    Assert.notNullOrUndefined(datSearchService, 'datSearchService');
    Assert.notNullOrUndefined(produktService, 'produktService');
    Assert.notNullOrUndefined(fahrzeugService, 'fahrzeugService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    Assert.notNullOrUndefined(fahrzeugDialogService, 'fahrzeugDialogService');
    Assert.notNullOrUndefined(textbausteineService, 'textbausteineService');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
    Assert.notNullOrUndefined(produktDetailFahrzeugLaufleistungenFormViewFactory, 'produktDetailFahrzeugLaufleistungenFormViewFactory');
    Assert.notNullOrUndefined(fahrzeugscheinDialogService, 'fahrzeugscheinDialogService');
  }

  public ngOnInit(): void {
    this.manuellFahrzeugartDisabled$.next(this.manuellFahrzeugartDisabled);
    this.manuellBauformDisabled$.next(this.manuellBauformDisabled);
    this.datRequestActive$ = this.datSearchService.getDatRequestActive();
    this.laufleistungenFields = (<FeatureFieldArray>this.fields
      .find((x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Laufleistungen.name)).fields;
    this.formLaufleistungen = <ViewFormArray>this.form.get(PRODUKT_CONFIG_FEATURES.Fahrzeug.fields.Laufleistungen.name);

    this.datRequestEnabled$.next(!(this.hasKalkulation()
      || this.hasWerteBewertung() || this.hasVtiWerteBewertung()));
    if (this.laufleistungenVisible) {
      if (this.produkt.fahrzeug.laufleistungen === null) {
        this.produkt.fahrzeug.laufleistungen = [];
      }
      this.buttonLaufleistungenIndex0Visible$.next(this.produkt.fahrzeug?.laufleistungen?.length === 0);
      this.buttonLaufleistungenIndex1Visible$.next(this.produkt.fahrzeug?.laufleistungen?.length === 1);

      this.initLaufleistungBeschreibungen();
      this.initLaufleistungBeschreibungSubscriptions();

      this.isAcProdukt = this.produkt.art === ProduktArt.AlphaController;
    }
  }

  public ngAfterViewInit(): void {
    if (this.produkt && this.form.get(this.fieldBemerkungen) && this.produkt.fahrzeug.bemerkungen === null) {
      this.textbausteineService.prefillWithStandardTextbausteine(this.name, this.fieldBemerkungen, this.produkt.art, this.form, this.bemerkungenElement);
    }
    if (this.produkt) {
      this.anzahlVorbesitzer = this.getAnzahlVorbesitzerEnum(this.produkt.fahrzeug.anzahlVorbesitzer);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


  public onVinSearchClick(event: MouseEvent): void {
    Assert.notNullOrUndefined(event, 'event');
    event.stopPropagation();
    this.handleIdentnummer();
  }

  public setProdukteWithSameIdentnummer(produkte: Produkt[]): void {
    this.produkteWithSameIdentnummer = produkte;
  }

  public onVinCaptureClick(event: MouseEvent): void {
    Assert.notNullOrUndefined(event, 'event');
    event.stopPropagation();

    this.fahrzeugscheinDialogService.open('fahrzeugschein.vin').subscribe(vin => {
      if (vin) {
        this.identnummer.setRawValue(vin);
        this.handleIdentnummer();
      }
    });
  }

  public onKbaSearchClick(event: MouseEvent): void {
    Assert.notNullOrUndefined(event, 'event');
    event.stopPropagation();

    const fields = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields;
    const typCode = (<ViewFormControl<string>>this.form.get(fields.TypCode.name)).getRawValue();
    const herstellerCode = (<ViewFormControl<string>>this.form.get(fields.HerstellerCode.name)).getRawValue();

    const kba = `${herstellerCode}/${typCode}`;
    this.kbaRequestActiveChange.next(true);
    this.executeFahrzeugSearch({
      kba
    }).pipe(
      finalize(() => this.kbaRequestActiveChange.next(false))
    ).subscribe();
  }

  public onChangeBauformGroup(value: number, name: string): void {
    Assert.notNullOrUndefined(value, 'value');
    Assert.notNullOrUndefined(name, 'name');
    switch (name) {
      case 'fahrzeugart': {
        value === FahrzeugArt.Manuell ? this.manuellFahrzeugartDisabled$.next(false) :
          this.manuellFahrzeugartDisabled$.next(true);
        break;
      }
      case 'bauform': {
        value === FahrzeugArt.Manuell ? this.manuellBauformDisabled$.next(false) :
          this.manuellBauformDisabled$.next(true);
        break;
      }
    }
  }

  public setSelectedProduktIndex(index: number): void {
    this.selectedProduktIndex = index;
  }

  public onAddLaufleistungen(index: number): void {
    Assert.notNullOrUndefined(index, 'index');
    this.formLaufleistungen.controls.push(this.createLaufleistung(index));
    this.formLaufleistungen.updateValueAndValidity();
    if (index === 0) {
      this.buttonLaufleistungenIndex0Visible$.next(false);
      this.buttonLaufleistungenIndex1Visible$.next(true);
    } else if (index === 1) {
      this.buttonLaufleistungenIndex1Visible$.next(false);
    }
    this.initLaufleistungBeschreibungen();
  }

  public onRemoveLaufleistungen(index: number): void {
    Assert.notNullOrUndefined(index, 'index');
    this.formLaufleistungen.controls.splice(index, 1);
    this.formLaufleistungen.updateValueAndValidity();
    if (this.formLaufleistungen.controls.length === 0) {
      this.buttonLaufleistungenIndex0Visible$.next(true);
      this.buttonLaufleistungenIndex1Visible$.next(false);
    } else if (this.formLaufleistungen.controls.length === 1) {
      this.buttonLaufleistungenIndex0Visible$.next(false);
      this.buttonLaufleistungenIndex1Visible$.next(true);
    }
    this.initLaufleistungBeschreibungen();
  }

  public onBeschreibungChange($event: MatSelect): void {
    this.form.patchValue({
      laufleistungBeschreibung: $event.value

    });
    this.form.updateValueAndValidity();
    this.initLaufleistungBeschreibungen();
  }

  public onLaufleistungenBeschreibungChange($event: MatSelect, index: number): void {
    if (index === 0) {
      this.form.patchValue({
        laufleistungen: [
          {
            wert: this.form.get('laufleistungen').value[ 0 ].wert,
            einheit: this.form.get('laufleistungen').value[ 0 ].einheit,
            beschreibung: $event.value
          }
        ]
      });
      this.form.updateValueAndValidity();
      this.initLaufleistungBeschreibungen();
    }
  }

  private handleIdentnummer(): void {
    // this.produktService.get(this.identnummer.getRawValue()).pipe(first()).subscribe(
    //   produkte => {
    //     this.setProdukteWithSameIdentnummer(produkte.filter(value => value.id !== this.produkt.id)
    //       .sort((produktA, produktB) => Date.parse(produktB.erstelltAm) - Date.parse(produktA.erstelltAm))
    //       .slice(0, this.showMaxProdukte));
    //
    //     if (this.produkteWithSameIdentnummer.length > 0) {
    //       this.openSelectProduktDialog();
    //     } else {
    //       this.searchFahrzeugWithConfirm();
    //     }
    //   }, _err => {
    //     this.searchFahrzeugWithConfirm();
    //   }
    // );
    this.searchFahrzeugWithConfirm();
  }

  private searchFahrzeugWithConfirm(): void {
    if (this.form.get('fahrzeugExternalServiceReference')?.value
      && (<FahrzeugExternalServicesReference>this.form.get('fahrzeugExternalServiceReference')?.value)?.source != null) {
      this.openConfirmWerteDialog();
    } else {
      this.callExecuteFahrzeugSearch();
    }
  }

  private executeFahrzeugSearch(request: FahrzeugServiceRequest): Observable<void> {
    return this.fahrzeugService.getVehicleIdentification(request).pipe(
      tap(response => this.handleResponse(response, request)),
      map(() => null),
      catchError(() => {
        this.snackBarService.error('fahrzeug.externalDataServiceResponseCode.error', this.createMessageObject(request));
        return of(null);
      }));
  }

  private handleResponse(response: FahrzeugServiceResponse, request: FahrzeugServiceRequest): void {
    if (!response || response.responseCode === ExternalDataServiceResponseCode.Error) {
      this.snackBarService.error('fahrzeug.externalDataServiceResponseCode.error', this.createMessageObject(request));
      return;
    }
    switch (response.responseCode) {
      case ExternalDataServiceResponseCode.SuccessWithoutResult:
        this.snackBarService.warning('fahrzeug.externalDataServiceResponseCode.successWithoutResult', this.createMessageObject(request));
        break;
      case ExternalDataServiceResponseCode.ErrorCouldNotIdentifiedByKba:
        this.snackBarService.warning('fahrzeug.externalDataServiceResponseCode.errorCouldNotIdentifiedByKba', this.createMessageObject(request));
        break;
      case ExternalDataServiceResponseCode.SuccessWithIdentification:
        this.fahrzeugDialogService.open('fahrzeug.produktDetailFahrzeugDialogService.title', response).subscribe(result => {
          if (result) {
            this.snackBarService.success('fahrzeug.externalDataServiceResponseCode.success', this.createMessageObject(request));
            this.handleSuccessResponse(result);
          }
        }, () => {
          this.snackBarService.error('fahrzeug.externalDataServiceResponseCode.error', this.createMessageObject(request));
        });
        break;
      default:
        this.snackBarService.success('fahrzeug.externalDataServiceResponseCode.success', this.createMessageObject(request));
        this.handleSuccessResponse(response);
        break;
    }
  }

  private handleSuccessResponse(response: FahrzeugServiceResponse): void {
    this.handleAusstattungResponse(response.ausstattung);
    this.handleFahrzeugResponse(response.fahrzeug);
    this.handleAbrechnung();
  }

  private handleAusstattungResponse(ausstattungDto: AusstattungDto): void {
    const ausstattung: Ausstattung = {
      id: null,
      gruppen: (ausstattungDto.gruppen || []).map(x => {
        const gruppe: AusstattungGruppe = {
          name: x.name,
          sonderausstattung: x.sonderausstattung,
          teile: (x.teile || []).map(y => {
            const teil: AusstattungTeil = {
              name: y.herstellerName,
              vorhanden: y.vorhanden,
              externalServiceId: y.externalServiceId,
            };
            return teil;
          })
        };
        return gruppe;
      })
    };
    this.ausstattungResolved.emit(ausstattung);
  }

  private handleFahrzeugResponse(fahrzeug: FahrzeugDto): void {
    const fields = PRODUKT_CONFIG_FEATURES.Fahrzeug.fields;
    this.fields.forEach((field: FeatureField & FeatureFieldGroup) => {
      const name = field.name || field.groupName;
      switch (name) {
        case fields.FahrzeugExternalServiceReference.name:
          this.form.setControlValue(fields.FahrzeugExternalServiceReference.name, fahrzeug.externalServicesReference);
          break;
        case 'codeGroup':
          this.form.setControlValue(fields.TypCode.name, fahrzeug.typCode);
          this.form.setControlValue(fields.HerstellerCode.name, fahrzeug.herstellerCode);
          break;
        case 'bauformGroup':
          this.form.setControlValue(fields.Fahrzeugart.name, fahrzeug.art);
          this.form.setControlValue(fields.Bauform.name, fahrzeug.bauform);
          break;
        case fields.Hersteller.name:
          this.form.setControlValue(name, fahrzeug.hersteller);
          break;
        case fields.DatECode.name:
          this.form.setControlValue(name, fahrzeug.datECode);
          break;
        case fields.Modell.name:
          this.form.setControlValue(name, fahrzeug.modell);
          break;
        case fields.Untertyp.name:
          this.form.setControlValue(name, fahrzeug.untertyp);
          break;
        case fields.Lackcode.name:
          this.form.setControlValue(name, fahrzeug.lackcode);
          break;
        case fields.Innenausstattung.name:
          this.form.setControlValue(name, fahrzeug.innenausstattung);
          break;
        case fields.Polsterfarbe.name:
          this.form.setControlValue(name, fahrzeug.polsterfarbe);
          break;
        case fields.Polstermaterial.name:
          this.form.setControlValue(name, fahrzeug.polstermaterial);
          break;
        case fields.Antriebstechnologie.name:
          this.form.setControlValue(name, fahrzeug.antriebstechnologie);
          break;
        case fields.Antriebsart.name:
          this.form.setControlValue(name, fahrzeug.antriebsart);
          break;
        case fields.Schadstoffklasse.name:
          this.form.setControlValue(name, fahrzeug.schadstoffklasse);
          break;
        case 'motorGroup':
          this.form.setControlValue(fields.Hubraum.name, fahrzeug.hubraum);
          this.form.setControlValue(fields.Leistung.name, fahrzeug.leistung);
          this.form.setControlValue(fields.Zylinder.name, fahrzeug.zylinder);
          break;
        case 'getriebeGroup':
          this.form.setControlValue(fields.Getriebeart.name, fahrzeug.getriebeart);
          this.form.setControlValue(fields.AnzahlGaenge.name, fahrzeug.anzahlGaenge);
          break;
        case 'gewichtGroup':
          this.form.setControlValue(fields.Leergewicht.name, fahrzeug.leergewicht);
          this.form.setControlValue(fields.ZulaessigesGesamtgewicht.name, fahrzeug.zulaessigesGesamtgewicht);
          break;
        case 'anzahlGroup':
          this.form.setControlValue(fields.AnzahlTueren.name, fahrzeug.anzahlTueren);
          this.form.setControlValue(fields.AnzahlSitze.name, fahrzeug.anzahlSitze);
          break;
        case 'masseGroup':
          this.form.setControlValue(fields.MasseLaenge.name, fahrzeug.masseLaenge);
          this.form.setControlValue(fields.MasseBreite.name, fahrzeug.masseBreite);
          this.form.setControlValue(fields.MasseHoehe.name, fahrzeug.masseHoehe);
          break;
        default:
          break;
      }
    });
  }

  private createMessageObject(request: FahrzeugServiceRequest): any {
    return {
      identifier: request.kba ? 'KBA' : 'Identnummer',
      value: request.kba ? request.kba : request.vin,
    };
  }

  private setLetzteZulassung(value: Moment): void {
    if (value && !this.form.get('letzteZulassung').value) {
      this.form.patchValue({letzteZulassung: value});
    }
  }

  private CreateAbrechnung(): Abrechnung {
    return {
      id: guid(),
      datum: moment.utc().toISOString(),
      externalProvider: AbrechnungProvider.Dat,
      externalService: AbrechnungService.Vin,
      identifier: this.identnummer.getRawValue(),
    };
  }

  private handleAbrechnung(): void {
    const abrechnung = this.CreateAbrechnung();
    return this.abrechnungResolved.emit(abrechnung);
  }

  private openConfirmWerteDialog(): void {
    const title = 'DAT Abfrage';
    const buttons = [`${this.name}.cancel`, `${this.name}.confirm`];

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogConfirmTemplate).subscribe(result => {
      if (result.name && result.name === buttons[ 1 ]) {
        this.callExecuteFahrzeugSearch();
      }
    });
  }

  private openSelectProduktDialog(): void {
    const title = `${this.name}.selectProduktTitle`;
    const buttons = [`${this.name}.copy`, `${this.name}.continue`];

    const produkte = this.produkteWithSameIdentnummer;
    const identnummer = this.form.get('identnummer').value;
    const data: ProduktSelectDialogData = {identnummer, produkte};

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogSelectProduktTemplate, data).subscribe(result => {
      if (result.name && result.name === buttons[ 0 ]) {
        this.copySelectedProdukt();
      } else {
        this.searchFahrzeugWithConfirm();
      }
    });
  }

  private callExecuteFahrzeugSearch(): void {
    this.datSearchService.getDatRequestActive().next(true);
    this.executeFahrzeugSearch({
      produktId: this.produkt.id,
      kennzeichen: this.produkt.fahrzeug?.kennzeichen,
      vin: this.identnummer.getRawValue()
    }).pipe(
      finalize(() => this.datSearchService.getDatRequestActive().next(false))
    ).subscribe();
  }

  private getAnzahlVorbesitzerEnum(anzahlVorbesitzer: number): EnumValues {
    if (anzahlVorbesitzer > 0 && anzahlVorbesitzer < 10) {
      return new EnumValues(FahrzeugAnzahlVorbesitzerLegacy);
    } else {
      return new EnumValues(FahrzeugAnzahlVorbesitzer);
    }
  }

  private copySelectedProdukt() {
    this.produktService.getDuplikat(this.produkteWithSameIdentnummer[ this.selectedProduktIndex ].id, this.produkt.id, this.produkt.art).pipe(first()).subscribe(
      next => {
        this.produkt = next;
        this.save.emit(this.produkt);
        window.location.reload();
      }, error => {
        console.error(error);
        this.snackBarService.error('Ausgewähltes Produkt konnte nicht übernommen werden.');
      }
    );

  }

  private createLaufleistung(index: number): ViewFormGroup {
    if (index === 0) {
      const values = new ObjectValues(FahrzeugLaufleistungBeschreibung);
      values.keys = values.keys.filter(beschreibungen => !beschreibungen.includes(this.form.get('laufleistungBeschreibung').value));
      return this.produktDetailFahrzeugLaufleistungenFormViewFactory.create({
        wert: 0,
        einheit: FahrzeugLaufleistungEinheit.Km,
        beschreibung: values.keys[ 0 ]
      }, this.laufleistungenFields);
    }

    if (index === 1) {
      const values = new ObjectValues(FahrzeugLaufleistungBeschreibung);
      this.form.updateValueAndValidity();
      values.keys = values.keys.filter(beschreibungen => !beschreibungen.includes(this.form.get('laufleistungBeschreibung').value));
      values.keys = values.keys.filter(beschreibungen => !beschreibungen.includes(this.form.get('laufleistungen').value[ 0 ].beschreibung));
      return this.produktDetailFahrzeugLaufleistungenFormViewFactory.create({
        wert: 0,
        einheit: FahrzeugLaufleistungEinheit.Km,
        beschreibung: values.keys[ 0 ]
      }, this.laufleistungenFields);
    }
  }

  private hasKalkulation(): boolean {
    return this.produkt.kalkulation?.dossierId > 0;
  }

  private hasWerteBewertung() {
    return (+this.produkt.werte?.externalServicesReferenceId > 0);
  }

  private hasVtiWerteBewertung() {
    return (+this.produkt.vtiWerte?.externalServicesReferenceId > 0);
  }

  private initLaufleistungBeschreibungen(): void {
    const newLaufleistungBeschreibung = new ObjectValues(FahrzeugLaufleistungBeschreibung);
    const newLaufleistungenBeschreibungFirst = new ObjectValues(FahrzeugLaufleistungBeschreibung);
    const newLaufleistungenBeschreibungSecond = new ObjectValues(FahrzeugLaufleistungBeschreibung);

    if (!this.form.get('laufleistungen')?.value || this.form.get('laufleistungen').value.length <= 0) {
      this.laufleistungBeschreibung$.next(newLaufleistungBeschreibung);
      return;
    }

    if (this.form.get('laufleistungen').value.length === 1) {
      newLaufleistungBeschreibung.keys = newLaufleistungBeschreibung.keys.filter(
        beschreibungen => !beschreibungen.includes(this.form.get('laufleistungen').value[ 0 ].beschreibung));
      this.laufleistungBeschreibung$.next(newLaufleistungBeschreibung);

      newLaufleistungenBeschreibungFirst.keys = newLaufleistungenBeschreibungFirst.keys.filter(
        beschreibungen => !beschreibungen.includes(this.form.get('laufleistungBeschreibung').value));
      this.laufleistungenBeschreibungFirst$.next(newLaufleistungenBeschreibungFirst);
    }

    if (this.form.get('laufleistungen').value.length === 2) {
      newLaufleistungBeschreibung.keys = newLaufleistungBeschreibung.keys.filter(
        beschreibungen => beschreibungen.includes(this.form.get('laufleistungBeschreibung').value));
      this.laufleistungBeschreibung$.next(newLaufleistungBeschreibung);

      newLaufleistungenBeschreibungFirst.keys = newLaufleistungenBeschreibungFirst.keys.filter(
        beschreibungen => beschreibungen.includes(this.form.get('laufleistungen').value[ 0 ].beschreibung));
      this.laufleistungenBeschreibungFirst$.next(newLaufleistungenBeschreibungFirst);

      newLaufleistungenBeschreibungSecond.keys = newLaufleistungenBeschreibungSecond.keys.filter(
        beschreibungen => beschreibungen.includes(this.form.get('laufleistungen').value[ 1 ].beschreibung));
      this.laufleistungenBeschreibungSecond$.next(newLaufleistungenBeschreibungSecond);
    }
  }

  private initLaufleistungBeschreibungSubscriptions(): void {
    this.subscriptions.push(this.form.get('laufleistungBeschreibung')?.valueChanges.pipe().subscribe(
      laufleistungBeschreibung => {
        if (!this.form.get('laufleistungen')?.value || this.form.get('laufleistungen').value.length <= 0) {
          return;
        }
        this.initLaufleistungBeschreibungen();
      }
    ));
  }
}
