import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Assert } from '@shared/helper/assert';
import { DisplayService } from '@shared/service/display.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomSheetComponent implements AfterViewInit {
  private ref: MatBottomSheetRef;
  private _title: string;
  private _value: string;

  public tickle$ = new BehaviorSubject(false);
  public title$ = new BehaviorSubject<string>('');
  public value$ = new BehaviorSubject<string>('');

  @Input()
  public set title(title: string) {
    if (this._title && this._title !== title) {
      this.doVisualFeedback();
    }
    this._title = title;
    this.title$.next(this._title);
  }

  @Input()
  public set value(value: string) {
    if (this._value && this._title !== value) {
      this.doVisualFeedback();
    }
    this._value = value;
    this.value$.next(this._value);
  }

  @Input()
  public seamless = false;

  @ViewChild(TemplateRef, { static: true })
  public content: TemplateRef<any>;

  @ViewChild('styles', { static: true })
  public styles: ElementRef<HTMLElement>;

  @ViewChild('drawer', { static: true })
  public drawer: ElementRef<HTMLElement>;

  @HostListener('window:resize')
  public onResize(): void {
    setInterval(() => {
      this.updateStyle();
    }, 10);
  }

  @HostListener('window:scroll')
  public onScroll(): void {
    this.updateStyle();
  }

  constructor(private readonly displayService: DisplayService,
              private readonly bottomSheet: MatBottomSheet) {
    Assert.notNullOrUndefined(bottomSheet, 'bottomSheet');
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateStyle();
    }, 1);
  }

  public onDrawerClick(): void {
    this.open();
  }

  private open(): void {
    this.updateStyle();
    this.ref = this.bottomSheet.open(this.content, {
      backdropClass: 'bottom-sheet',
      panelClass: this.seamless ? 'bottom-sheet-panel' : '',
    });
    this.ref.afterDismissed().subscribe(() => this.ref = null);
  }

  private doVisualFeedback(): void {
    this.tickle$.next(true);
    setTimeout(() => this.tickle$.next(false), 400);
  }

  private updateStyle(): void {
    const styles = this.styles.nativeElement;
    if (!styles.firstChild) {
      styles.appendChild(document.createElement('style'));
    }

    const footer = document.getElementsByClassName('footer')[0];
    if (!footer && this.displayService.showFooter) {
      return;
    }

    let bottom;
    if(this.displayService.showFooter.value) {
      const footerRect = footer.getBoundingClientRect();
      bottom = Math.max(0, window.innerHeight - footerRect.top);
    } else {
      bottom = 0;
    }

    const height = window.innerHeight - bottom;

    const style = <HTMLStyleElement>styles.firstChild;
    style.innerHTML = `
        .drawer {
          bottom: ${bottom}px;
          display: block !important;
        }

        .bottom-sheet + .cdk-global-overlay-wrapper {
          overflow: hidden;
          height: ${height}px;
        }
      `;
  }
}
