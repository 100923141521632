import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';

@Component({
  selector: 'app-bagde',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {

  @Input()
  public label: string;

  @Input()
  public icon: string;

  @Input()
  public color = 'white';
}
