import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface AcAbschlussVersendenPostResponse {
    error?: string;
    message?: string;
}

@Injectable({
    providedIn: 'root'
})
export class AcAbschlussVersendenService {
    private readonly restApiUrl: string;

    constructor(
        private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/alphacontroller/outbound/fahrzeug`;
    }

    public post(produktId: string): Observable<AcAbschlussVersendenPostResponse> {
        Assert.notNullOrUndefined(produktId, 'produktId');
        return this.httpClient.post<AcAbschlussVersendenPostResponse>(this.restApiUrl, {
            produktId
        });
    }
}

