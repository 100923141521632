import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Produkt } from '@data/domain/schema/type';
import { ProduktKalkulationService } from '@data/domain/service/feature/produkt-kalkulation.service';
import { environment } from '@env/environment';
import { DatCalculateProService } from '@modules/produkt/service/dat-calculate-pro-service';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ExternalDataServiceResponseBase } from '../schema/external-data-service';

export interface VtiKalkulationDto {
  dossierId: number;
  bezugsdatum: string;
  reparaturkostenNetto: number;
  reparaturkostenBrutto: number;
  dokument: string;
}

export interface VtiKalkulationPostRequest {
  produktId: string;
  dossierId?: number;
  vtiAuftragsnummer: string;
  identnummer: string;
  kennzeichen?: string;
  laufleistung?: number;
  erstzulassung?: string;
  datVxsVehicle: string; // fahrzeugExternalServiceReference -> vxsData
}

export interface VtiKalkulationGetResponse extends ExternalDataServiceResponseBase {
  kalkulation?: VtiKalkulationDto;
  message?: string;
}

declare let DatTokenInformation: any;

@Injectable({
  providedIn: 'root'
})
export class VtiKalkulationService {
  private readonly restApiUrl: string;

  constructor(
    readonly produktKalkulationService: ProduktKalkulationService,
    private readonly httpClient: HttpClient) {
    Assert.notNullOrUndefined(httpClient, 'httpClient');
    this.restApiUrl = `${environment.aws.apiGateway.url}/dat/kalkulation`;
  }

  public get(dossierId: number, produktId: string): Observable<VtiKalkulationGetResponse> {
    Assert.notNullOrUndefined(dossierId, 'dossierId');
    Assert.notNullOrUndefined(produktId, 'produktId');
    const params = {
      params: {
        dossierId: dossierId.toString(),
        produktId
      }
    };
    return this.httpClient.get<VtiKalkulationGetResponse>(this.restApiUrl, params);
  }

   public post(request: VtiKalkulationPostRequest): Observable<number> {
    Assert.notNullOrUndefined(request, 'request');
    return this.httpClient.post<number>(this.restApiUrl, request);
  }

  public createDossier(produkt: Produkt): Observable<number> {
    Assert.notNullOrUndefined(produkt, 'produkt');
    return this.post(this.getVtiKalkulationPostRequest(produkt)).pipe(first());
  }

  public setupSphinx(token: any, dossierId: number, sphinx: any): void {
    const values = {
      az: dossierId,
      displayHeader: true,
      showProcessMenu: true,
      defaultReadyHandler: true,
      urlReadyHandler: window.location.href + '/kalkulation/' + dossierId,
      tires: false,
      hidePrintIcon: true,
    };

    sphinx.onError = function(response) {
      alert('DAT login failed!\nReason: ' + response.message);
    };

    sphinx.hostUrl = window.location.href;
    sphinx.credentials = {
      token
    };

    sphinx.productVariant = 'calculateExpert';
    sphinx.firstPage = 'graphicSelectionPage';
    sphinx.lastPage = 'calculationResult';
    sphinx.host = 'https://www.dat.de/VehicleRepairOnline';
    const loginInfo = sphinx.encryptPassword(new DatTokenInformation(token));
    const DAF = sphinx.getDAFXml(values);
    const callBackFunction = function(_object, _xml) {
      // This is intentional
    };
    sphinx.init(sphinx.host + '/VehicleRepairOnline/grapaselservice/GraphicalPartSelectionPage.html', 'graphicSelectionPage', document.getElementById('iframeContainer'), 'modelIFrame', null, callBackFunction);
    sphinx.execute(loginInfo, DAF, function(e) {
      alert(e);
    });
  };

  public initIframe(
    datCalculateProService: DatCalculateProService,
    snackBarService: SnackBarService,
    documentInput: Document,
    sphinx: any,
    loading$: BehaviorSubject<boolean>,
    produkt: Produkt,
    form: UntypedFormGroup, openInTab = false): void {
    datCalculateProService.getToken().pipe(first()).subscribe(
      token => {
        if (token) {
          if (produkt.kalkulation?.dossierId) {
            this.setupSphinx(token, produkt.kalkulation?.dossierId, sphinx);
            if (openInTab) {
              this.openIframeInTab(documentInput);
            }
          } else {
            loading$.next(true);
            this.createDossier(produkt).subscribe(dossierId => {
                this.setupSphinx(token, dossierId, sphinx);
                produkt.kalkulation.dossierId = dossierId;
                form.patchValue({dossierId});
                this.produktKalkulationService.save(produkt.id, {id: produkt.id, dossierId});
                loading$.next(false);
                if (openInTab) {
                  this.openIframeInTab(documentInput);
                }
              },
              _error => {
                loading$.next(false);
                snackBarService.error('Fehler beim erstellen des Dossiers');
              }
            );
          }

        } else {
          snackBarService.error('Fehler bei der Anmeldung bei DAT. Token konnte nicht erstellt werden.');
        }
      }
    );
  }

  private getVtiKalkulationPostRequest(produkt: Produkt): VtiKalkulationPostRequest {
    return {
      produktId: produkt.id,
      vtiAuftragsnummer: produkt.auftrag?.vorgangsnummer,
      identnummer: produkt.fahrzeug?.identnummer,
      kennzeichen: produkt.fahrzeug?.kennzeichen,
      laufleistung: produkt.fahrzeug?.laufleistung,
      erstzulassung: produkt.fahrzeug?.erstzulassung,
      datVxsVehicle: produkt.fahrzeug?.fahrzeugExternalServiceReference?.vxsData,
    };
  }

  private openIframeInTab(documentInput: Document): void {
    setTimeout(() => {
      const iframe = documentInput.getElementById('sphinx.iFrame') as HTMLIFrameElement;
      window.open(iframe.src, '_blank').focus();
    }, 500);
  }
}
