<ng-container [formGroup]="form">
  <div class="date-component-wrapper gtue-information"
       [ngClass]="((control.touchedChanges | async) && !(control.validChanges | async) && control.hasError('required')) ? 'error' : ''"
       [class.mat-form-field-appearance-legacy]="readonly" [class.mat-form-field-disabled]="readonly">
    <h4 class="mat-caption text-field-label">{{(displayName | translate) + (required ? ' *' : '')}}</h4>
    <mat-form-field appearance="fill" floatLabel="never">
      <input matInput [id]="name" [formControlName]="name" [name]="name" [matDatepicker]="datepicker"
        [class.disabled]="disabled" [readonly]="readonly" [max]="max" [min]="min" [value]="value"
        (focusin)='focusInputField.emit($event)' onFocus="this.select()" (dateChange)="dateChange.emit($event.value)"
        autocomplete="off">
      <mat-datepicker-toggle *ngIf="hasDatepicker" matSuffix [for]="datepicker"></mat-datepicker-toggle>
      <mat-datepicker startView='{{startView}}' (yearSelected)="chosenYearHandler($event)"
        (monthSelected)="chosenMonthHandler($event, datepicker)" #datepicker></mat-datepicker>
      <span matSuffix>
        <ng-content select="[appSuffix]"></ng-content>
      </span>

    </mat-form-field>
    <mat-error>
      {{'control.required' | translate:({name: (displayName | translate)})}}
    </mat-error>
  </div>
</ng-container>
