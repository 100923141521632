import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { SignoutResponse, User, UserManager, WebStorageStateStore } from 'oidc-client';
import { from, Observable, of, timer } from 'rxjs';
import { catchError, finalize, map, mergeMap, tap } from 'rxjs/operators';

export interface AuthUserClaims {
    email?: string;
    'custom:buero_id'?: string;
    'cognito:name'?: string;
    'cognito:gtueid'?: string;
    'istVti'?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private manager: UserManager;

    private readonly authority = `https://cognito-idp.${environment.aws.region}.amazonaws.com/${environment.aws.region}_${environment.aws.cognito.userPoolId}`;
    private readonly postLogoutRedirectUri = `${environment.appUrl}/auth/signout`;
    private readonly redirectUri = `${environment.appUrl}/auth/signin`;

    constructor() {
        this.init();
    }

    public isLoggedIn(): Observable<boolean> {
        return this.getUser().pipe(
            map(user => user && !user.expired)
        );
    }

    public getClaims(): Observable<AuthUserClaims> {
        return this.getUser().pipe(
            map(user => user?.profile),
            map(profile => {
                if (profile) {
                    if (profile['istVti']) {
                        profile['istVti'] = JSON.parse(profile['istVti']);
                    }
                    return profile;
                } else {
                    console.error('Could not get user claims.');
                    return null;
                }
            })
        );
    }

    public startSignin(): Observable<void> {
        const signinRedirect = this.manager.signinRedirect();
        return from(signinRedirect);
    }

    public completeSignin(): Observable<User> {
        const signinRedirectCallback = this.manager.signinRedirectCallback();
        return from(signinRedirectCallback);
    }

    public startSignout(): Observable<number | void> {
        const closePopupWaitTimeInMs = 3000;
        const endsessionUrl = environment.aws.cognito.endsession || null;

        if(!endsessionUrl)  {
            console.error('No endsession url found!');
            return of();
        }

        const idpLogoutPopup = window.open(
            endsessionUrl
        );
        idpLogoutPopup.blur();

        return timer(closePopupWaitTimeInMs).pipe(
            tap(() => {
                idpLogoutPopup.close();
            }),
            finalize(() => {
                this.manager.signoutRedirect().finally(() => {
                    const signoutUrl = `${environment.aws.cognito.url}/logout?client_id=${environment.aws.cognito.clientId}&logout_uri=${encodeURIComponent(this.postLogoutRedirectUri)}`;
                    window.location.replace(signoutUrl);
                });
            })
        );
    }

    public completeSignout(): Observable<SignoutResponse> {
        const signoutRedirectCallback = this.manager.signoutRedirectCallback();
        return from(signoutRedirectCallback);
    }

    public getUser(): Observable<User> {
        const getUser = this.manager.getUser();
        return from(getUser).pipe(
            mergeMap(user => {
                if (user && user.expired && user.refresh_token) {
                    const signinSilent = this.manager.signinSilent();
                    return from(signinSilent).pipe(
                        catchError(() => of(null))
                    );
                }
                return of(user);
            })
        );
    }

    private init(): void {
        this.manager = new UserManager({
            authority: this.authority,
            client_id: environment.aws.cognito.clientId,
            redirect_uri: this.redirectUri,
            post_logout_redirect_uri: this.postLogoutRedirectUri,
            response_type: 'code',
            scope: 'openid profile',
            automaticSilentRenew: true,
            loadUserInfo: true,
            filterProtocolClaims: true,
            userStore: new WebStorageStateStore({
                store: window.localStorage
            }),
        });
    }
}
