import { Injectable } from '@angular/core';
import { AusstattungGruppe } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailAusstattungTeilFormViewFactory } from './produkt-detail-ausstattung-teil-form-view.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailAusstattungGruppeFormViewFactory extends FormViewModelBaseFactory<AusstattungGruppe> {
    constructor(private readonly produktDetailAusstattungTeilFormViewFactory: ProduktDetailAusstattungTeilFormViewFactory) {
        super();
        Assert.notNullOrUndefined(produktDetailAusstattungTeilFormViewFactory, 'produktDetailAusstattungTeilFormViewFactory');
    }

    protected createField(model: AusstattungGruppe, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Ausstattung.fields.Gruppen.fields;
        switch (name) {
            case fields.Name.name:
                return new ViewFormControl(model.name);
            case fields.Sonderausstattung.name:
                return new ViewFormControl(model.sonderausstattung);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: AusstattungGruppe, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Ausstattung.fields.Gruppen.fields;
        if (arrayName === fields.Teile.name) {
            const teile = model.teile || [];
            return new ViewFormArray(
                teile.map(
                    teil => this.produktDetailAusstattungTeilFormViewFactory
                        .create(teil, arrayFields)
                ));
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
