<ng-container *ngIf="statusChanged$ | async as status; else loading">
    <app-stepper #stepperComponent [linear]="true">

        <!-- schließen -->
        <app-step [editable]="false" [completed]="status > statusEnum.Beendet">
            <app-step-label>
                <ng-container *ngIf="status <= 1; else geschlossenLabel">
                    {{name + '.workflow.schliessen' | translate}}
                </ng-container>
                <ng-template #geschlossenLabel>
                    <ng-container>
                        {{name + '.workflow.geschlossen' | translate}}
                    </ng-container>
                </ng-template>
            </app-step-label>
            <ng-container *ngIf="!(loading$ | async);else loading">
                <ng-container *ngIf="status <= 1; else geschlossen">
                    <div>
                        {{name + '.workflow.schliessenLabel' | translate}}
                    </div>
                    <div>
                        <app-button (action)="onProduktCloseClick()" [name]="name + '.workflow.schliessen'">
                            {{name + '.workflow.schliessen' | translate}}
                        </app-button>
                    </div>
                </ng-container>
                <ng-template #geschlossen>
                    <div>
                        {{name + '.workflow.geschlossenLabel' | translate}}
                    </div>
                    <div>
                        <app-button (action)="onProduktOpenClick()" [name]="name + '.workflow.geschlossenErneut'">
                            {{name + '.workflow.geschlossenErneut' | translate}}
                        </app-button>
                    </div>
                </ng-template>
            </ng-container>
        </app-step>

        <!-- beendet -->
        <app-step [editable]="false" [completed]="status > statusEnum.Beendet">
            <app-step-label>
                {{name + '.workflow.beendetLabel' | translate}}
            </app-step-label>
            <ng-container *ngIf="!(loading$ | async);else loading">
                <div> {{name + '.workflow.beendet' | translate}}</div>
                <div class="buttons column">
                    <app-button class="btn-icon" (action)="onAbschlussResetClick()" [name]="name + '.workflow.reset.beendet'">
                        <ng-container>
                            <app-mat-icon icon="lock_open" color="primary"></app-mat-icon>
                            <p>{{name + '.workflow.label.reopen' | translate}}</p>
                        </ng-container>
                    </app-button>
                  <app-button class="btn-icon" (action)="onDownloadClick()" [name]="name + '.workflow.download'">
                    <ng-container>
                      <app-mat-icon icon="picture_as_pdf" color="primary"></app-mat-icon>
                        <p>{{name + '.workflow.label.download' | translate}}</p>
                    </ng-container>
                  </app-button>
                    <div class="spacer"></div>
                        <app-button class="btn-icon" (action)="onVersendenClick()" [name]="name + '.workflow.download.open'">
                            <ng-container>
                                <app-mat-icon icon="send" color="primary"></app-mat-icon>
                                <p>{{name + '.workflow.label.versenden' | translate}}</p>
                            </ng-container>
                        </app-button>
                </div>
            </ng-container>
        </app-step>

        <!-- uebertragen -->
        <app-step [editable]="false" [completed]="status === statusEnum.AcVersendet">
            <app-step-label>
                {{name + '.workflow.uebertragen' | translate}}
            </app-step-label>
            <ng-container *ngIf="!(loading$ | async);else loading">
                <div class="buttons">
                    <app-button class="btn-icon" (action)="onDownloadClick()" *ngIf="!(downloadUrl$ | async); else open" [name]="name + '.workflow.download'">
                        <ng-container>
                            <app-mat-icon icon="picture_as_pdf" color="primary"></app-mat-icon>
                            <label>Herunterladen</label>
                        </ng-container>
                    </app-button>
                    <ng-template #open>
                        <app-button class="btn-icon" (action)="onOpenDownloadClick()" [name]="name + '.workflow.download.open'">
                            <ng-container>
                                <app-mat-icon icon="picture_as_pdf" color="primary"></app-mat-icon>
                            </ng-container>
                        </app-button>
                    </ng-template>
                </div>
            </ng-container>
        </app-step>
    </app-stepper>
</ng-container>

<ng-template #loading>
    <app-progress-spinner></app-progress-spinner>
</ng-template>

<ng-template #acAbschlussResetDialog>
    <mat-label>
        {{ name + '.workflow.reset.confirm' | translate}}
    </mat-label>
</ng-template>

<ng-template #acAbschlussVersendenDialog>
    <mat-label>
        {{ name + '.workflow.versenden.confirm' | translate}}
    </mat-label>
</ng-template>
