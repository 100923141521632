import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Assert } from '@shared/helper/assert';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';

@Directive()
export abstract class FormControlBase implements OnInit, OnDestroy {
    @Input()
    public form: UntypedFormGroup;

    @Input()
    public id: string;

    @Input()
    public name: string;

    @Input()
    public displayName: string;

    public get control(): AbstractViewFormControl {
        return <AbstractViewFormControl>this.form.get(this.name);
    }

    constructor(private readonly formControlFocusService: FormControlFocusService) {
        Assert.notNullOrUndefined(formControlFocusService, 'formControlFocusService');
    }

    public ngOnInit(): void {
        this.formControlFocusService.register(this);
    }

    public ngOnDestroy(): void {
        this.formControlFocusService.unregister(this);
    }

    public next(step = 1): void {
        this.formControlFocusService.next(this, step);
    }

    public prev(): void {
        this.formControlFocusService.prev(this);
    }

    public abstract blur(): void;
    public abstract focus(): void;
}
