import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

const auftragsttypDictionary: { [key: string]: string } = {
    EVMI01: 'auftrag.typ.zustandsbericht',
    EVMI02: 'auftrag.typ.zustandsbericht',
    EVMI03: 'auftrag.typ.zustandsbericht',
    EVMI04: 'auftrag.typ.zustandsbericht',
    EVMI05: 'auftrag.typ.nachgutachten.dezentral',
    EVMI06: 'auftrag.typ.zustandsberichtMinderwerte',
    EVMI07: 'auftrag.typ.nachgutachten.zentral',
    EVMI08: 'auftrag.typ.zustandsbericht',
    EVMI09: 'auftrag.typ.nachgutachten.dezentral',
    EVMI10: 'auftrag.typ.zustandsberichtAenderung',
    EVMI11: 'auftrag.typ.zustandsbericht',
    EVMI12: 'auftrag.typ.zustandsbericht',
    EVMI13: 'auftrag.typ.nachgutachten.dezentral',
    EVMIH01: 'auftrag.typ.zustandsbericht',
    EVMIH02: 'auftrag.typ.zustandsbericht',
    EVMIH03: 'auftrag.typ.zustandsbericht',
    EVMIH04: 'auftrag.typ.zustandsbericht',
    EVMIH05: 'auftrag.typ.nachgutachten.dezentral',
    EVMIH06: 'auftrag.typ.zustandsberichtMinderwerte',
    EVMIH07: 'auftrag.typ.nachgutachten.zentral',
    EVMIH08: 'auftrag.typ.zustandsbericht',
    EVMIH09: 'auftrag.typ.nachgutachten.dezentral',
    EVMIH10: 'auftrag.typ.zustandsberichtMinderwerte',
    EVMIH12: 'auftrag.typ.zustandsberichtAenderung',
    EVMA01: 'auftrag.typ.zustandsbericht',
    EVMA02: 'auftrag.typ.zustandsbericht',
    EVMA03: 'auftrag.typ.zustandsbericht',
    EVMA04: 'auftrag.typ.nachgutachten.dezentral',
    EVMA05: 'auftrag.typ.zustandsberichtOhneMinderwerte',
    EVMA06: 'auftrag.typ.zustandsberichtOhneMinderwerte',
    EVMA07: 'auftrag.typ.zustandsberichtAenderung',
    EVMA08: 'auftrag.typ.nachgutachten.zentral',
    EVRWP01: 'auftrag.typ.restwertprognose',
    EVHEK01: 'auftrag.typ.ausweisHEK',
    HVMI01: 'auftrag.typ.zustandsbericht',
    HVMI02: 'auftrag.typ.zustandsbericht',
    SGA01: 'auftrag.typ.beweissicherungsgutachten',
    SGA02: 'auftrag.typ.beweissicherungsgutachtenHEK'
};

@Pipe({
    name: 'vtiAuftragstyp',
    pure: false
})
export class VtiAuftragstypPipe extends TranslatePipe implements PipeTransform {
    public transform(auftragstyp: string): string {
        return auftragsttypDictionary[auftragstyp] ? super.transform(auftragsttypDictionary[auftragstyp]) +
            ` (${auftragstyp})` : auftragstyp;
    }
}
