<app-icon-button class="close" tabindex="-1" mat-dialog-close>
  <mat-icon>close</mat-icon>
</app-icon-button>
<h1 class="title" mat-dialog-title *ngIf="settings.title">
    {{settings.title | translate}}
</h1>
<div class="content-wrapper" mat-dialog-content>
    <app-file [fileId]="settings.fileId" [thumb]="false"></app-file>
</div>
<div class="actions" *ngIf="settings.buttons" mat-dialog-actions>
  <app-button *ngFor="let button of settings.buttons;" [type]="Buttontype.Flat" [color]="'primary'"
              (click)="onAction(button)">{{button | translate}}</app-button>
</div>
