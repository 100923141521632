<ng-container [formGroup]="form">
  <div class="gtue-information input-component-wrapper"
       [ngClass]="((control.touchedChanges | async) && !(control.validChanges | async) && (control.hasError('required') || control.hasError('pattern'))) ? 'error' : ''">
    <h4 class="mat-caption text-field-label">{{(displayName | translate) + (required ? ' *' : '')}}</h4>
    <mat-form-field [class.read_only]="readonly"
                    [class.mat-form-field-disabled]="disabled"
                    appearance="fill" floatLabel="never">
      <app-vin *ngIf="type === 'vin'"
               [placeholder]="displayName | translate"
               [id]="name" [formControlName]="name"
               [vinDisabled]="vinDisabled"></app-vin>
      <input *ngIf="type !== 'vin'" matInput [type]="type" [readonly]="disabled || readonly" [class.input-disabled]="disabled"
             [placeholder]="(placeholder ? placeholder : (displayName | translate))"
             [formControlName]="name" [name]="name" [id]= "id ? id : name" [attr.inputmode]="inputmode" [autocomplete]="inputmode ==='text' ? '' : 'off'"
              [appOnlyNumber]="onlyNumber">
      <span matSuffix [class.readonly]="readonly">
            <ng-content select="[appSuffix]"></ng-content>
        </span>
    </mat-form-field>
    <mat-error>
      {{ control.hasError('pattern')
      ? ('control.pattern' | translate:{name: (displayName | translate)})
      : ('control.required' | translate:{name: (displayName | translate)})
      }}
    </mat-error>

  </div>
</ng-container>
