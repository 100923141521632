import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Assert } from '@shared/helper/assert';
import { ObjectViewControl, ObjectViewHorizontal, ObjectViewVertical } from '@shared/helper/three/object-view-control';
import { Object3D, PerspectiveCamera, Vector2 } from 'three';

@Component({
  selector: 'app-object-view-control',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObjectViewControlComponent implements OnDestroy {
  private control = new ObjectViewControl();
  private animating = false;

  @Input()
  public horizontal = ObjectViewHorizontal.Left;

  @Input()
  public vertical = ObjectViewVertical.Top;

  @Input()
  public set disabled(disabled: boolean) {
    this.control.enable = !disabled;
  }

  @Input()
  public set target(target: Object3D) {
    if (target) {
      this.control.target(target);
      this.control.focus(this.horizontal, this.vertical);
    }
  }

  @Output()
  public userTap = this.control.userTap;

  @Output()
  public animatingChange = new EventEmitter<boolean>();

  public ngOnDestroy(): void {
    this.control.dispose();
  }

  public init(camera: PerspectiveCamera, element: HTMLElement): void {
    Assert.notNullOrUndefined(camera, 'camera');
    Assert.notNullOrUndefined(element, 'element');
    this.control.init(camera, element);
  }

  public animate(): void {
    const animating = this.control.animate();
    if (this.animating !== animating) {
      this.animating = animating;
      this.animatingChange.next(this.animating);
    }
  }

  public update(): void {
    this.control.calculateZoom();
  }

  public invert(zoom: boolean): void {
    if (zoom) {
      this.control.enableDynamicZoom = false;
      this.control.invert = true;
      this.control.saveState();
      this.control.setFromState({
        zoom: 0.25,
        rotation: new Vector2(180, 60)
      });
      this.control.setZoom(0.1, 0.5);
      this.control.setLat(25, 70);
    } else {
      if (!this.control.enableDynamicZoom) {
        this.control.enableDynamicZoom = true;
        this.control.invert = false;
        this.control.setZoom(0.2, Infinity);
        this.control.setLat(-25, 45);
        this.control.reset();
      }
    }
  }

  public lockAxis(x: boolean, y: boolean): void {
    this.control.lockAxis.set(
      x ? 1 : 0,
      y ? 1 : 0);
  }
}
