import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';

@Component({
    selector: 'app-produkt-detail-werte-manuelle-werte',
    templateUrl: './produkt-detail-werte-manuelle-werte.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailWerteManuelleWerteComponent {
    public trackByField = TrackBy.trackByField;

    @Input()
    public form: UntypedFormGroup;

    @Input()
    public name: string;

    @Input()
    public fields: FeatureFields;

}
