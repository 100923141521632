<ng-container [formGroup]="form">
    <h4>{{displayName | translate}} </h4>
    <ng-container *ngIf="((control.valueChanges | async) || control.value) as values">
        <mat-list>
            <mat-list-item *ngFor="let value of values; let index = index">
              <span>{{property ? value[property] : value}}</span>
              <app-icon-button class="button delete-button" (action)="onRemoveClick(index)">
                <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
              </app-icon-button>
              <app-icon-button *ngIf="hasImages" class="button add-button" (action)="onAddImagesClick(index)"
                               [count]="value.bilder?.length ? value.bilder?.length : ''">
                <app-mat-icon icon="add_photo_alternate"></app-mat-icon>
              </app-icon-button>
            </mat-list-item>
        </mat-list>
    </ng-container>
    <mat-form-field>
        <input matInput [attr.inputmode]="inputmode" (keyup)="onFilterKeyUp()" />
        <ng-container matSuffix>
            <app-mini-fab class="add" type="button" mat-mini-fab [color]="'basic'" (click)="onAddClick()"
                  [disabled]="input.nativeElement.value.length === 0">
              <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
            </app-mini-fab>
        </ng-container>
    </mat-form-field>
    <mat-action-list>
        <button mat-list-item *ngFor="let value of (filteredValues$ | async)" (click)="onItemClick(value)">
            {{value}}
        </button>
    </mat-action-list>
</ng-container>
