import { Injectable } from '@angular/core';
import { FileDatabase } from '@app/class/file-database';

@Injectable({
    providedIn: 'root'
})
export class FileDatabaseFactory {
    public create(): FileDatabase {
        return new FileDatabase();
    }
}
