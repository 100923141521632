import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { User } from 'oidc-client';
import { Observable, throwError } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private readonly authService: AuthService) {
        Assert.notNullOrUndefined(authService, 'authService');
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        Assert.notNullOrUndefined(request, 'request');
        Assert.notNullOrUndefined(next, 'next');
        if (request.url.indexOf(environment.aws.apiGateway.url) === -1) {
            return next.handle(request);
        }
        return this.authService.getUser().pipe(
            flatMap(user => this.executeRequest(next, request, user))
        );
    }

    private executeRequest(next: HttpHandler, request: HttpRequest<any>, user: User): Observable<HttpEvent<any>> {
        const modifiedRequest = user ? this.getModifiedRequest(user, request) : request;
        return next.handle(modifiedRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error) {
                    if (error.status === 401) {         // Unauthorized
                        // handle ??
                        alert('Unauthorized');
                    } else if (error.status === 403) {  // Forbidden
                        // handle ??
                        alert('Forbidden');
                    }
                }
                return throwError(error);
            })
        );
    }

    private getModifiedRequest(user: User, request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
          setHeaders: {
            Authorization: `${user.token_type} ${user.id_token}`
          }
        });
    }
}

