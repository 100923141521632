import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { guid } from '@app/function/guid';
import { AdresseElasticSearchService } from '@data/api-gateway';
import { Adresse, Notiz } from '@data/domain/schema/type';
import { ProduktAdressenService } from '@data/domain/service/feature';
import { ProduktDetailFeatureComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailAdressenAdresseFormViewFactory } from '@modules/produkt/factory/adressen/produkt-detail-adressen-adresse-form-view.factory';
import { ProduktDetailAdressenFormViewFactory } from '@modules/produkt/factory/adressen/produkt-detail-adressen-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject, concat, forkJoin, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, debounceTime, flatMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-produkt-detail-adressen',
  templateUrl: './produkt-detail-adressen.component.html',
  styleUrls: ['./produkt-detail-adressen.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailAdressenComponent extends ProduktDetailFeatureComponent implements OnInit, OnDestroy {
  private oldAdressen: Adresse[];

  public idPrefix = 'suche-';

  public trackByInstance = TrackBy.trackByInstance;
  public trackByField = TrackBy.trackByField;

  public notizen$: Observable<Notiz[]>;

  public form: ViewFormGroup;

  public searchForm: ViewFormGroup;
  public search$: Observable<Adresse[]>;
  public searching$ = new BehaviorSubject(false);

  public deleteSubscription: Subscription;

  public adresseFields: FeatureFields;
  public adressen: ViewFormArray;

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    private readonly produktAdressenService: ProduktAdressenService,
    private readonly adresseSearchService: AdresseElasticSearchService,
    private readonly adressenFormViewFactory: ProduktDetailAdressenFormViewFactory,
    private readonly adresseFormViewFactory: ProduktDetailAdressenAdresseFormViewFactory,
    private readonly snackBarService: SnackBarService,
    private readonly notizenService: ProduktDetailFeatureNotizenService) {
    super(produktConfigResolveService, produktDetailResolveService);
    Assert.notNullOrUndefined(produktAdressenService, 'produktAdressenService');
    Assert.notNullOrUndefined(adresseSearchService, 'adresseSearchService');
    Assert.notNullOrUndefined(adressenFormViewFactory, 'adressenFormViewFactory');
    Assert.notNullOrUndefined(adresseFormViewFactory, 'adresseFormViewFactory');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
  }

  public ngOnInit(): void {
    const name = PRODUKT_CONFIG_FEATURES.Adressen.name;
    this.init(name);
    this.notizen$ = this.notizenService.init(this.produkt, name);
    this.createForm();
    this.initSearch();
  }

  public ngOnDestroy(): void {
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

  public onAddAdresseClick(adresse: Adresse): void {
    this.addAdresse({
      ...adresse,
      id: guid(),
      createdAt: `${Date.now()}`
    });
    this.searchForm.reset();
  }

  public onRemoveAdresseClick(index: number): void {
    Assert.notNullOrUndefined(index, 'index');
    this.removeAdresse(index);
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  public save(): Observable<any> {
    const toSaves: Adresse[] = [];
    const toDeletes: Adresse[] = [];

    const currentAdressen = <Adresse[]>this.adressen.getRawValue();
    this.oldAdressen.forEach(adresse => {
      const current = currentAdressen.find(x => x.id === adresse.id);
      if (!current) {
        toDeletes.push(adresse);
      }
    });
    currentAdressen.forEach(current => {
      const old = this.oldAdressen.find(x => x.id === current.id);
      if (!old || JSON.stringify(old) !== JSON.stringify(current)) {
        toSaves.push(current);
      }
    });

    const produktId = this.produkt.id;
    const actions$ = [].concat(
      toSaves.map(toSave => this.produktAdressenService.saveAdresse(produktId, toSave.id, toSave)),
      toDeletes.map(toDelete => this.produktAdressenService.deleteAdresse(produktId, toDelete.id)));
    if (actions$.length > 0) {
      return forkJoin(actions$);
    }
    return of({});
  }

  public onDelete(elasticId: string): void {
    this.deleteSubscription = this.adresseSearchService.delete(elasticId).pipe()
      .subscribe(data => this.searching$.next(true),
        error => {
          console.warn(`An unexpected error occured while deleting adresse with ID: ${elasticId}.`, error);
          this.snackBarService.error('adressen.delete.error');
          this.searching$.next(false);
          return of([]);
        },
        () => {
          this.updateSearch();
        }
      );
  }

  private addAdresse(adresse: Adresse): void {
    const form = this.adresseFormViewFactory.create(adresse, this.adresseFields);
    this.adressen.controls.push(form);
  }

  private removeAdresse(index: number): void {
    this.adressen.removeAt(index);
  }

  private createForm(): void {
    this.form = this.adressenFormViewFactory.create(this.produkt.adressen, this.fields);
    this.adresseFields = (<FeatureFieldArray>this.fields
      .find((x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.name)).fields;
    this.adressen = <ViewFormArray>this.form.get(PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.name);
    this.searchForm = this.adresseFormViewFactory.create(<Adresse>{}, this.adresseFields, this.produkt.art, 'change');
    this.oldAdressen = this.adressen.getRawValue();
  }

  private initSearch(): void {
    this.search$ = concat(of(null), this.searchForm.valueChanges).pipe(
      debounceTime(200),
      flatMap(({ firma, name, vorname }) => this.onSearch(firma, name, vorname))
    );
  }

  private onSearch(firma: string, name: string, vorname: string): Observable<Adresse[]> {
    if (firma?.length > 2 || name?.length > 2 || vorname?.length > 2) {
      this.searching$.next(true);
      return this.adresseSearchService.get(firma, name, vorname).pipe(
        catchError(error => {
          console.warn(`An unexpected error occured while searching for adresses: ${firma}, ${name}, ${vorname}.`, error);
          this.snackBarService.error('adressen.search.error');
          return of([]);
        }),
        tap(() => this.searching$.next(false))
      );
    }
    return of([]);
  }

  private updateSearch(): void {
    const source = timer(700);
    source.pipe(take(1)).subscribe(value => {
      this.searchForm.patchValue({
        firma: this.searchForm.get('firma').value,
        vorname: this.searchForm.get('vorname').value,
        name: this.searchForm.get('name').value
      });
    });
  }
}
