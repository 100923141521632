import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RadLagerort, RaederNotlaufeinrichtung } from '@data/domain/schema/enum';
import {
  Achse,
  NfzRadsatz,
  NfzRaeder,
  NfzRaederInput,
  Notiz,
  Raeder
} from '@data/domain/schema/type';
import { ProduktNfzRaederService } from '@data/domain/service/feature/produkt-nfz-raeder.service';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import {
  ProduktDetailFeatureInputComponent
} from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureFieldArray, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import {
  ProduktDetailNfzRaederFormViewFactory
} from '@modules/produkt/factory/nfz-raeder/produkt-detail-nfz-raeder-form-view.factory';
import {
  ProduktDetailNfzRaederRadsatzAchseFormViewFactory
} from '@modules/produkt/factory/nfz-raeder/produkt-detail-nfz-raeder-radsatz-achse-form-view.factory';
import {
  ProduktDetailNfzRaederRadsatzAchseSeiteRadFormViewFactory
} from '@modules/produkt/factory/nfz-raeder/produkt-detail-nfz-raeder-radsatz-achse-seite-rad-form-view.factory';
import {
  ProduktDetailNfzRaederRadsatzFormViewFactory
} from '@modules/produkt/factory/nfz-raeder/produkt-detail-nfz-raeder-radsatz-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-produkt-detail-raeder',
  templateUrl: './produkt-detail-nfz-raeder.component.html',
  styleUrls: ['./produkt-detail-nfz-raeder.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailNfzRaederComponent extends ProduktDetailFeatureInputComponent<NfzRaeder, NfzRaederInput> implements OnInit, AfterViewInit, OnDestroy {

  public feature = PRODUKT_CONFIG_FEATURES.NfzRaeder.name;
  public fieldBemerkungen = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.Bemerkungen.name;

  public fieldsRadSaetze;
  public fieldsAchsen;
  public fieldsSeiten;
  public fieldsAchsenRaeder;

  public trackByField = TrackBy.trackByField;
  public trackByIndex = TrackBy.trackByIndex;

  public notizen$: Observable<Notiz[]>;

  public notlaufeinrichtung = new EnumValues(RaederNotlaufeinrichtung);
  public valuesLagerort = new EnumValues(RadLagerort);

  @ViewChild('bemerkungen')
  public bemerkungenElement: TextbausteineComponent;

  public get radSaetze(): ViewFormArray {
    return <ViewFormArray>this.form.get(PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.name);
  }

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktNfzRaederService: ProduktNfzRaederService,
    private readonly formViewFactory: ProduktDetailNfzRaederFormViewFactory,
    private readonly textbausteineService: TextbausteineService,
    private readonly nfzRadSatzFormViewFactory: ProduktDetailNfzRaederRadsatzFormViewFactory,
    private readonly nfzRaederRadsatzAchseFormViewFactory: ProduktDetailNfzRaederRadsatzAchseFormViewFactory,
    private readonly nfzRaederRadsatzAchseSeiteRadFormViewFactory: ProduktDetailNfzRaederRadsatzAchseSeiteRadFormViewFactory,
    private readonly notizenService: ProduktDetailFeatureNotizenService) {
    super(produktConfigResolveService, produktDetailResolveService, produktNfzRaederService);
    Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
    Assert.notNullOrUndefined(nfzRadSatzFormViewFactory, 'nfzRadSatzFormViewFactory');
    Assert.notNullOrUndefined(textbausteineService, 'textbausteineService');
    Assert.notNullOrUndefined(nfzRaederRadsatzAchseFormViewFactory, 'nfzRaederRadsatzAchseFormViewFactory');
    Assert.notNullOrUndefined(nfzRaederRadsatzAchseSeiteRadFormViewFactory, 'nfzRaederRadsatzAchseSeiteRadFormViewFactory');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
  }

  public ngOnInit(): void {
    this.notizen$ = this.notizenService.init(this.produkt, this.feature);
    this.init(this.feature);
    if (this.radSaetze && this.radSaetze.length === 0) {
      this.addSatz();
    }

    this.fieldsRadSaetze = (<FeatureFieldArray>this.fields[ 0 ]).fields;
    this.fieldsAchsen = (<FeatureFieldArray>this.fieldsRadSaetze[ 1 ]).fields;
    this.fieldsSeiten = (<FeatureFieldArray>this.fieldsAchsen[ 1 ]).fields;
    this.fieldsAchsenRaeder = (<FeatureFieldArray>this.fieldsSeiten[ 1 ]).fields;
  }

  public ngAfterViewInit(): void {
    if (this.form.get(this.fieldBemerkungen) && this.produkt.raeder.bemerkungen === null) {
      this.textbausteineService.prefillWithStandardTextbausteine(this.feature, this.fieldBemerkungen, this.produkt.art, this.form, this.bemerkungenElement);
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.produkt.nfzRaeder = this.form.getRawValue();
  }


  public onAddSatzClick(): void {
    this.addSatz();
  }

  public onDuplicateSatzClick(index: number): void {
    this.duplicateSatz(index);
  }

  public onRemoveSatzClick(index: number): void {
    this.removeSatz(index);
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  public onDuplicateAchseClick(radsatz: ViewFormGroup, achse: ViewFormGroup) {
    (<ViewFormArray> radsatz.get('achsen')).controls.push(achse);
  }

  public onAddAchseClick(radsatz: ViewFormGroup) {
     const newAchse: Achse = {
          bewertung: '',
          seiten: [
            {
              achsenRaeder: [{}]
            },
            {
              achsenRaeder: [{}]
            }
          ]
    };
    const form = this.nfzRaederRadsatzAchseFormViewFactory.create(newAchse, this.fieldsAchsen);
    (<ViewFormArray> radsatz.get('achsen')).controls.push(form);
  }

  public onDeleteAchseClick(radsatz: ViewFormGroup, achseIndex: number) {
    (<ViewFormArray> radsatz.get('achsen')).controls.splice(achseIndex, 1);
  }

  public onDuplicateRadClick(achse: ViewFormGroup, seite: number, rad: ViewFormGroup) {
    const seiten = (<ViewFormArray> achse.get('seiten')).controls[seite];
    seiten.patchValue({['seite']: seite});
    (<ViewFormArray> seiten.get('achsenRaeder')).controls.push(rad);
  }

  public onAddRadClick(achse: ViewFormGroup, seite: number) {
    const seiten = (<ViewFormArray> achse.get('seiten')).controls[seite];
    seiten.patchValue({['seite']: seite});
    (<ViewFormArray> seiten.get('achsenRaeder')).controls.push(this.nfzRaederRadsatzAchseSeiteRadFormViewFactory.create({}, this.fieldsAchsenRaeder));
  }

  public onDeleteRadClick(achse: ViewFormGroup, seite: number, radIndex: number) {
    const seiten = (<ViewFormArray> achse.get('seiten')).controls[seite];
    (<ViewFormArray> seiten.get('achsenRaeder')).controls.splice(radIndex, 1);
  }

  public onDuplicateSeiteClick(radsatz: ViewFormGroup, radsatzIndex: number, seite: number) {
    const achsen = (<ViewFormArray> radsatz.get('achsen')).controls[radsatzIndex];
    const seiteToCopy = <ViewFormGroup> (<ViewFormArray> achsen.get('seiten')).controls[seite];
      if (seite === 0) {
        (<ViewFormArray> achsen.get('seiten')).controls[1] = seiteToCopy;
      } else if (seite === 1) {
        (<ViewFormArray> achsen.get('seiten')).controls[0] = seiteToCopy;
      }
  }

  protected getFeature(): Raeder {
    return this.form.getRawValue();
  }

  protected createForm(): ViewFormGroup {
    return this.formViewFactory.create(this.produkt.nfzRaeder, this.fields);
  }

  private addSatz(): void {
    const radSaetzeFields = (<FeatureFieldArray>this.fields.find((x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.name)).fields;
    const nfzRadsatz: NfzRadsatz = {
      achsen: [
        {
          bewertung: '',
          seiten: [
            {
              achsenRaeder: [{}]
            },
            {
              achsenRaeder: [{}]
            }
          ]
        }
      ]
    };
    const form = this.nfzRadSatzFormViewFactory.create(nfzRadsatz, radSaetzeFields);
    this.radSaetze.push(form);
  }

  private duplicateSatz(index: number): void {
    const radSaetzeFields = (<FeatureFieldArray>this.fields.find((x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.name)).fields;
    const radSatz = <NfzRadsatz>(<ViewFormGroup>this.radSaetze.controls[ index ]).getRawValue();
    const form = this.nfzRadSatzFormViewFactory.create(radSatz, radSaetzeFields);
    this.radSaetze.push(form);
  }

  private removeSatz(index: number): void {
    this.radSaetze.removeAt(index);
  }
}
