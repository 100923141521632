<app-card *ngIf="validations.length > 0; else valid">
    <div class="form-error" *ngFor="let validation of validations">
        <h3>{{validation.name | translate}}</h3>
        <ul>
            <li *ngFor="let field of validation.incorrectFields">
                {{name + '.field' | translate:{name: validation.name + '.' + field | translate} }}
            </li>
        </ul>
    </div>
</app-card>
<ng-template #valid>
    <ng-container *ngFor="let field of fields;trackBy:trackByField">
        <ng-container [ngSwitch]="field.arrayName">
            <ng-container *ngSwitchCase="'fazit'">
                <app-card spacing="8px">
                    <app-textbausteine #fazit [form]="form" [name]="'fazit'" [displayName]="'Zusammenfassung'"
                                       [feature]="'Abschluss'" [produktArt]="this.produkt.art">
                    </app-textbausteine>
                </app-card>
            </ng-container>
        </ng-container>
    </ng-container>
    <app-card spacing="8px">
        <app-produkt-detail-abschluss-entwurf [name]="name" [produkt]="produkt" [fields]="fields" [adressen]="adressen">
        </app-produkt-detail-abschluss-entwurf>
    </app-card>
    <app-card spacing="8px">
      <app-produkt-detail-abschluss-anhaenge [name]="name" [produkt]="produkt" [statusChanged$]="statusChanged$">
      </app-produkt-detail-abschluss-anhaenge>
    </app-card>
    <app-card>
        <app-produkt-detail-abschluss-workflow [name]="name" [produkt]="produkt" (statusChangedEvent)="onStatusChanged($event)">
        </app-produkt-detail-abschluss-workflow>
    </app-card>
</ng-template>
