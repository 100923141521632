import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveUebersicht, SaveUebersichtData } from '../../graphql/mutations';
import { Produkt, Uebersicht, UebersichtInput } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktUebersichtService extends ProduktFeatureService<Uebersicht, UebersichtInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Uebersicht): UebersichtInput {
        const input: UebersichtInput = {
            ...feature,
            id: produktId,
            sonstiges: (feature.sonstiges || []).map((sonstiges, index) => ({
                ...sonstiges,
                id: `${produktId}:${index}`
              }))
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveUebersicht;
    }

    protected getSaveVariables(input: UebersichtInput): any {
        return {
            uebersicht: input
        };
    }

    protected getSaveOptimisticResponse(input: UebersichtInput): any {
        return {
            saveUebersicht: {
                ...input,
                __typename: 'Uebersicht',
                sonstiges: (input.sonstiges || []).map(sonstige => ({
                        ...sonstige,
                        __typename: 'UebersichtManuell'
                    }))
            }
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveUebersichtData>): Uebersicht {
        return response.data.saveUebersicht;
    }

    protected update(produkt: Produkt, feature: Uebersicht): void {
        produkt.uebersicht = {
            ...produkt.uebersicht,
            ...feature
        };
    }
}
