<app-table [data]="cpoVorgaenge" [pageSizeOptions]="[25, 50, 100]"
           [filterPredicate]="filterPredicate" active="erstelltAm" direction="desc" (action)="onAction($event)">
  <app-table-column name="art" [displayName]="name + '.art'">
    <ng-template let-element="element">
      <app-mat-icon [svgIcon]="'kfz-haftpflichtschaden'" color="primary" *ngIf="element.art == 301"></app-mat-icon>
      <app-mat-icon [svgIcon]="'kfz-kaskoschaden'" color="primary" *ngIf="element.art == 302"></app-mat-icon>
    </ng-template>
  </app-table-column>
  <app-table-column name="'nummer'" [displayName]="name + '.vorgang'" [sort]="true">
    <ng-template let-element="element">{{element.vorgang}}</ng-template>
  </app-table-column>
  <app-table-column name="fahrzeug.kennzeichen" [displayName]="name + '.kennzeichen'">
    <ng-template let-element="element">
      {{element.kennzeichen}}
    </ng-template>
  </app-table-column>
  <app-table-column name="schadenDatum" [displayName]="name + '.schadenDatum'" [sort]="true" start="desc">
    <ng-template let-element="element">{{element.schadendatum | date}}</ng-template>
  </app-table-column>
  <app-table-column name="status" [displayName]="name + '.status'" [sort]="true">
    <ng-template let-element="element">{{cpoStatus.values[element.status]}}</ng-template>
  </app-table-column>
</app-table>

