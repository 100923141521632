export enum ProduktAdresseArt {
    Auftraggeber = 0,
}

export const PRODUKT_ADRESSEN_ARTEN = [
    'Auftraggeber',
    'Besichtigungsort',
    'Fahrzeughalter',
    'GTÜ Sachverständigenbüro',
    'Leasingnehmer',
    'Prüfstützpunkt',
    'Rechtsanwalt',
    'Vermittler',
    'Versicherung',
    'Werkstatt',
];
