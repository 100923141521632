import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipListbox } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ProduktArt } from '@data/domain/schema/enum';
import { Textbaustein } from '@data/domain/schema/type';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import {
    TextbausteinFormViewFactory
} from '@modules/textbausteine-verwalten/factory/textbaustein/textbaustein-form-view.factory';
import { FormControlBase } from '@shared/component/form-controls/form-control-base.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { TrackBy } from '@shared/helper/track-by';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

interface ProduktDetailTextbausteineDialogData {
  form: ViewFormGroup;
  feature: string;
  feld: string;
}

interface ProduktDetailTextbausteineDialogEditData {
  form: ViewFormGroup;
  feature: string;
  fields: FeatureFields;
}

@Component({
  selector: 'app-textbausteine',
  templateUrl: './textbausteine.component.html',
  styleUrls: ['./textbausteine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextbausteineComponent extends FormControlBase implements OnInit {

  public trackByKey = TrackBy.trackByKey;

  public seperators = [ENTER, COMMA];
  public viewControl = new UntypedFormControl();
  public optionsFiltered$: Observable<string[]>;

  public viewControlArray: ViewFormArray;

  @Input()
  public options: string[] = [];

  @Input()
  public feature = '';

  @Input()
  public userId = '';

  @Input()
  public textbausteine = new BehaviorSubject<Textbaustein[]>([]);

  @Input()
  public produktArt: ProduktArt;

  @Input()
  public multiple = true;

  @ViewChild(MatInput, {static: true, read: ElementRef})
  public input: ElementRef<HTMLElement>;

  @ViewChild('list', {static: true})
  public chipList: MatChipListbox;

  @ViewChild('trigger', {static: true})
  public trigger: MatAutocompleteTrigger;

  @ViewChild('dialogEdit', {static: true})
  public dialogEditTemplate: TemplateRef<any>;

  @ViewChild('dialog', {static: true})
  public dialogTemplate: TemplateRef<any>;

  @ViewChild('delete', {static: true})
  public deleteTemplate: TemplateRef<any>;

  private dialogRef: MatDialogRef<any>;

  constructor(formControlFocusService: FormControlFocusService,
              private readonly textbausteineService: TextbausteineService,
              private readonly textbausteinFormViewFactory: TextbausteinFormViewFactory,
              private readonly templateDialogService: TemplateDialogService) {
    super(formControlFocusService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.textbausteine.subscribe(values => {
        this.options = values.map(value => value.kurztext);
      }
    );
    this.prefillStandardValues();
    this.optionsFiltered$ = this.viewControl.valueChanges
      .pipe(
        startWith(<string>null),
        map(term => this.filterOptions(term))
      );
    this.viewControlArray = <ViewFormArray>this.form.get(this.name);
  }

  public blur(): void {
    this.input.nativeElement.blur();
  }

  public focus(): void {
    this.input.nativeElement.focus();
    this.openPanel();
  }

  public onFieldClick(): void {
    this.openPanel();
  }

  public onOptionSelected(event: MatAutocompleteSelectedEvent, input: HTMLInputElement): void {
    Assert.notNullOrUndefined(event, 'event');
    Assert.notNullOrUndefined(input, 'input');
  }

  public onOptionAdd(event: MatChipInputEvent, input: HTMLInputElement): void {
    Assert.notNullOrUndefined(event, 'event');
    Assert.notNullOrUndefined(input, 'input');
  }

  public onChipRemoveClicked(index: number): void {
    Assert.notNullOrUndefined(index, 'index');

    const buttons = [`feature.cancel`, `feature.delete`];
    this.templateDialogService.openTemplate('Textbaustein löschen', buttons,
      this.deleteTemplate).subscribe(result => {
      if (result.name && result.name === `feature.delete`) {
        this.removeOption(index);
      }
    });
  }

  public onAddTextbausteinClicked(): void {
    const buttons = [`feature.close`];
    const data: ProduktDetailTextbausteineDialogData = {
      form: null,
      feature: this.feature,
      feld: this.name
    };

    this.dialogRef = this.templateDialogService.open('Textbaustein hinzufügen', buttons,
      this.dialogTemplate, data, true);
  }

  public onTextbausteinAdd($event: Textbaustein): void {
    this.addTextbaustein($event);
    this.dialogRef.close();
  }

  private openPanel(): void {
    this.trigger.openPanel();
  }

  private removeOption(index: number): void {
    this.viewControlArray.removeAt(index);
  }

  private filterOptions(term: string): string[] {
    const availableOptions = this.options.filter(option =>
      this.control.value.indexOf(option) === -1);

    term = (term || '').toLowerCase();
    if (term.length > 0) {
      return availableOptions.filter(option =>
        option.toLowerCase().indexOf(term) !== -1);
    }
    return availableOptions;
  }

  public onChipClicked(value: any, index: number): void {
    const buttons = [`feature.save`];
    const data: ProduktDetailTextbausteineDialogEditData = {
      form: value,
      feature: this.feature,
      fields: []
    };

    this.templateDialogService.openTemplate('Textbaustein bearbeiten', buttons,
      this.dialogEditTemplate, data).subscribe();
  }

  public getKurztext(): String[] {
    return this.control.value.map(value => value.value.kurztext);
  }

  private prefillStandardValues(): void {
    const gtueTextbaustein = this.textbausteine.value.find(value => value.verfuegbarkeit.find(verfügbarkeit => verfügbarkeit === 'GTÜ') && value.standard);
    if (gtueTextbaustein) {
      const temp = new ViewFormGroup({
        kurztext: new ViewFormControl(gtueTextbaustein.kurztext),
        langtext: new ViewFormControl(gtueTextbaustein.langtext),
      });
      this.form.markAllAsTouched();
    }
  }

  private addTextbaustein(textbaustein: Textbaustein) {
    this.viewControlArray.push(this.textbausteineService.createTextbausteinFormGroup(textbaustein));
    this.chipList.focus();
  }

  // private createTextbaustein(value: string) {
  //   const form = new ViewFormGroup({
  //     kurztext: new ViewFormControl(value),
  //     langtext: new ViewFormControl(''),
  //   });
  //
  //   const buttons = [`feature.cancel`, `feature.save`];
  //   const data: ProduktDetailTextbausteineDialogEditData = {
  //     form: form,
  //     fields: []
  //   };
  //
  //   this.templateDialogService.openTemplate('Textbaustein bearbeiten', buttons,
  //     this.dialogEditTemplate, data).subscribe(result => {
  //     const newTextbaustein: Textbaustein = {
  //       id: guid(),
  //       kurztext: result.data.form.get('kurztext').value,
  //       langtext: result.data.form.get('langtext').value,
  //       verfuegbarkeit: ['Buero'],
  //       produkte: [ProduktArt.Zustandsbericht],
  //       feature: this.feature,
  //       feld: this.name,
  //       tags: [],
  //       standard: false,
  //       erstelltAm: new Date().getDate().toString(),
  //       erstelltVon: '001', // Replace with user ID
  //     };
  //     this.textbausteine.value.push(newTextbaustein);
  //   });
  //
  // }
}
