import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@app/service/auth.service';
import { ProduktArt } from '@data/domain/schema/enum';
import { environment } from '@env/environment';
import { GridTile } from '@shared/component';
import { Assert } from '@shared/helper/assert';

const TILE_VTI_TOOLING = {
  icon: 'ruecknahmebewertung',
  link: `/produkt/erstellen/${ProduktArt.VtiTooling}`,
  id: 'id-vti-tooling',
  title: 'VTI Tooling'
};

@Component({
  selector: 'app-produkt-erstellen',
  templateUrl: './produkt-erstellen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktErstellenComponent {
  public tiles: GridTile[] = [
    {
      icon: 'bewertung',
      link: `/produkt/erstellen/${ProduktArt.Bewertung}`,
      id: 'id-bewertung',
      title: 'Bewertung',
    },
    {
      icon: 'ruecknahmebewertung',
      link: `/produkt/erstellen/${ProduktArt.Ruecknahmebewertung}`,
      id: 'id-ruecknahmebewertung',
      title: 'Rücknahme\u200Bbewertung'
    },
    {
      icon: 'zustandsbericht',
      link: `/produkt/erstellen/${ProduktArt.Zustandsbericht}`,
      id: 'id-zustandsbericht',
      title: 'Zustandsbericht'
    },
    // {
    //   icon: 'ruecknahmebewertung',
    //   link: `/produkt/erstellen/${ProduktArt.NfzRuecknahmebewertung}`,
    //   id: 'id-nfz-ruecknahmebewertung',
    //   title: 'NFZ Rücknahme\u200Bbewertung'
    // },
    {
      icon: 'dynarex-on-demand',
      externalLink: 'https://gtue.onrex.de/auth/',
      id: 'id-dynarex-on-demand',
      title: 'GTÜ Dynarex on Demand',
    },
    {
      icon: 'gtue-modul',
      externalLink: `${environment.bvws.combiPlusOnline.url}`,
      id: 'id-gtue-modul',
      title: 'GTÜ-Modul',
    },
    {
      icon: 'gtue-classic',
      link: '/produkt/erstellen',
      id: 'id-oldtimer',
      title: 'Oldtimer',
      disabled: true
    }
  ];

  constructor(private readonly authService: AuthService) {
    Assert.notNullOrUndefined(authService, 'authService');

    this.setupTiles();
  }

  private setupTiles() {
    const indexVtiTooling = 3;

    this.authService.getClaims().subscribe({
      next: (claims) => {
        if (claims) {
          if (claims['istVti'] && claims['istVti'] === true) {
            this.tiles.splice(indexVtiTooling, 0, TILE_VTI_TOOLING);
          }
        } else {
          console.error('Could not get user claims.');
        }
      },
      error: (err) => console.error(err)
    });
  }
}
