import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveLackmessung, SaveLackmessungData } from '@data/domain/graphql/mutations';
import { Lackmessung, LackmessungInput, Produkt } from '@data/domain/schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktLackmessungService extends ProduktFeatureService<Lackmessung, LackmessungInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Lackmessung): LackmessungInput {
        const input: LackmessungInput = {
            id: produktId,
            messungen: (feature.messungen || []).map((messung, index) => ({
                    ...messung,
                    id: `${produktId}:${index}`
                })),
            gesamtKarosserieVon: feature.gesamtKarosserieVon,
            gesamtKarosserieBis: feature.gesamtKarosserieBis,
            untersuchungsErgebnis: feature.untersuchungsErgebnis,
            beschreibungen: (feature.beschreibungen || []).map((beschreibungen, _beschreibungenIndex) => ({
                    ...beschreibungen
                }))
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveLackmessung;
    }

    protected getSaveVariables(input: LackmessungInput): any {
        return {
            lackmessung: input
        };
    }

    protected getSaveOptimisticResponse(input: LackmessungInput): any {
        return {
            saveLackmessung: {
                ...input,
                __typename: 'Lackmessung',
                messungen: (input.messungen || []).map(messung => ({
                        ...messung,
                        __typename: 'LackmessungMessungen',
                    })),
                gesamtKarosserieVon: input.gesamtKarosserieVon,
                gesamtKarosserieBis: input.gesamtKarosserieBis,
                untersuchungsErgebnis: input.untersuchungsErgebnis,
                beschreibungen: (input.beschreibungen || []).map(beschreibungen => ({
                        ...beschreibungen,
                        __typename: 'Textbaustein'
                    }))
            }
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveLackmessungData>): Lackmessung {
        return response.data.saveLackmessung;
    }

    protected update(produkt: Produkt, feature: Lackmessung): void {
        produkt.lackmessung.id = feature.id;
        produkt.lackmessung.messungen = feature.messungen;
        produkt.lackmessung.gesamtKarosserieVon = feature.gesamtKarosserieVon;
        produkt.lackmessung.gesamtKarosserieBis = feature.gesamtKarosserieBis;
        produkt.lackmessung.untersuchungsErgebnis = feature.untersuchungsErgebnis;
        produkt.lackmessung.beschreibungen = feature.beschreibungen;
    }
}
