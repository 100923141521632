<ng-container *ngFor="let field of fields;trackBy:trackByField">
    <ng-container [ngSwitch]="field.arrayName">
        <!-- RadSaetze -->
        <ng-container *ngSwitchCase="'radSaetze'">
            <app-card class="satz"
                *ngFor="let radsatz of form.get(field.arrayName).controls;trackBy:trackByIndex; let i = index"
                spacing="8px">
                <h3>{{('raeder.radsatz.' + i) | translate}}</h3>
                <app-produkt-detail-raeder-radsatz [form]="radsatz" [name]="name" [fields]="field.fields">
                </app-produkt-detail-raeder-radsatz>
                <app-mini-fab class="remove" (click)="onRemoveSatzClick()" *ngIf="i >= 1">
                    <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
                </app-mini-fab>
            </app-card>
            <app-card *ngIf="form.get(field.arrayName).controls.length !== 2" spacing="8px">
                <div align="center">
                    <app-button (action)="onDuplicateSatzClick()">
                        <app-mat-icon icon="icon-duplicate-report" [newStyle]="true" class="new-style"></app-mat-icon>
                        {{'raeder.duplicatesatz' | translate}}
                    </app-button>
                    <app-button (action)="onAddSatzClick()">
                        <app-mat-icon icon="icon-add" [newStyle]="true" class="new-style"></app-mat-icon>
                        {{'raeder.addsatz' | translate}}
                    </app-button>
                </div>
            </app-card>
        </ng-container>
    </ng-container>
</ng-container>

<app-card>
    <h3>{{'raeder.sonstiges' | translate}}</h3>
    <ng-container *ngFor="let field of fields;trackBy:trackByField">
        <ng-container [ngSwitch]="field.name || field.arrayName">
            <!-- Notlaufeinrichtung -->
            <ng-container *ngSwitchCase="'notlaufeinrichtung'">
                <app-select [form]="form" [name]="field.name" [displayName]="name + '.' + field.name"
                    [values]="notlaufeinrichtung" [valuePrefix]="name + '.'">
                </app-select>
            </ng-container>
            <!-- Bemerkungen -->
            <ng-container *ngSwitchCase="'bemerkungen'">
                <app-textbausteine #bemerkungen [form]="form" [name]="'bemerkungen'" [displayName]="'Bemerkungen'"
                [feature]="'raeder'" [produktArt]="this.produkt.art">
                </app-textbausteine>
            </ng-container>
        </ng-container>
    </ng-container>
</app-card>
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)">
</app-produkt-detail-notizen-button>
