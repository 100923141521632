<mat-grid-list [cols]="cols$ | async" [rowHeight]="rowHeight" [gutterSize]="gutterSize">
    <mat-grid-tile class="tile" [id]="tile.id" [class.disabled]="tile.disabled || (loading$ | async)" [routerLink]="[tile.link ? tile.link : undefined]"
                   (click)="onProduktClick()"
        *ngFor="let tile of tiles;trackBy:trackByLink">
        <a *ngIf="tile.externalLink" class="external-link" target="_blank" rel="noopener noreferrer" href="{{tile.externalLink}}"></a>
        <a *ngIf="tile.id === 'id-kfz-haftplichtschaden'" class="external-link" (click)="onHaftpflichtschadenClick()"></a>
        <a *ngIf="tile.id === 'id-kfz-kaskoschaden'" class="external-link" (click)="onKaskoSchadenClick()"></a>
        <div class="icon">
            <mat-icon *ngIf="tile.icon" [svgIcon]="tile.icon" color="primary"></mat-icon>
        </div>
        <div class="footer">
            <h3>
                <span [matBadge]="tile.badgeCount" [matBadgeHidden]="tile.badgeCount <= 0" matBadgeOverlap="false"
                    matBadgeColor="warn">
                    <span class="title">{{tile.title | translate}}</span>
                </span>
            </h3>
        </div>
    </mat-grid-tile>
</mat-grid-list>

<div class="loading" *ngIf="loading$ | async">
  <app-progress-spinner [diameter]="150"></app-progress-spinner>
</div>

