import { Injectable } from '@angular/core';
import { ProduktArt, ProduktStatus } from '../schema/enum';
import { Produkt } from '../schema/type';

@Injectable({
    providedIn: 'root'
})
export class ProduktFactory {
    public create(id: string, art: ProduktArt): Produkt {
        const produkt: Produkt = {
            id,
            __typename: 'Produkt',
            idParent: null,
            art,
            status: ProduktStatus.Offen,
            identnummer: null,
            erstelltAm: null,
            abgeschlossenAm: null,
            abgeschlossenVon: null,
            abrechnungen: {
                id,
                __typename: 'Abrechnungen',
                abrechnungen: null,
            },
            auftrag: {
                id,
                __typename: 'Auftrag',
                art: null,
                nummer: null,
                vorgangsnummer: null,
                auftragstyp: null,
                bezugsAuftragsnummer: null,
                kommentar: null,
                bemerkung: null,
                erstellungsTag: null,
                erstellungsUhrzeit: null,
                besichtigungsTag: null,
                besichtigungsUhrzeit: null,
                fertigstellungsTag: null,
                fertigstellungsUhrzeit: null,
                bemerkungen: [],
                auftragsbeschreibung: [],
                besichtigungsbedingungen: [],
                notizen: [],
                hinweise: null,
            },
            vtiAuftrag: {
                id,
                __typename: 'VtiAuftrag',
                art: null,
                nummer: null,
                vorgangsnummer: null,
                auftragstyp: null,
                bezugsAuftragsnummer: null,
                bemerkung: null,
                notizen: [],
            },
            fahrzeug: {
                id,
                __typename: 'Fahrzeug',
                kennzeichen: null,
                identnummer: null,
                datECode: null,
                typCode: null,
                herstellerCode: null,
                erstzulassung: null,
                letzteZulassung: null,
                laufleistung: null,
                laufleistungEinheit: null,
                laufleistungBeschreibung: null,
                laufleistungen: [],
                fahrzeugart: null,
                fahrzeugartManuell: null,
                bauform: null,
                bauformManuell: null,
                hersteller: null,
                modell: null,
                untertyp: null,
                lackcode: null,
                innenausstattung: null,
                polsterfarbe: null,
                polstermaterial: null,
                hubraum: null,
                leistung: null,
                zylinder: null,
                schadstoffklasse: null,
                leergewicht: null,
                zulaessigesGesamtgewicht: null,
                masseLaenge: null,
                masseBreite: null,
                masseHoehe: null,
                anzahlTueren: null,
                anzahlSitze: null,
                antriebstechnologie: null,
                antriebsart: null,
                getriebeart: null,
                anzahlGaenge: null,
                scheckheftGepflegt: null,
                fahrzeugExternalServiceReference: null,
                anzahlVorbesitzer: null,
                hvBatterie: null,
                acFahrzeugart: null,
                acBauform: null,
                acLackfarbe: null,
                acLackierung: null,
                acAntriebstechnologie: null,
                acGetriebeart: null,
                acSchadstoffklasse: null,
                acEhemaligeNutzung: null,
                bemerkungen: [],
                notizen: [],
            },
            adressen: {
                id,
                __typename: 'Adressen',
                adressen: null,
                notizen: [],
            },
            raeder: {
                id,
                __typename: 'Raeder',
                notlaufeinrichtung: null,
                radSaetze: null,
                bemerkungen: [],
                notizen: [],
            },
            nfzRaeder: {
                id,
                __typename: 'NfzRaeder',
                notlaufeinrichtung: null,
                radSaetze: [],
                bemerkungen: [],
                notizen: [],
            },
            vorschaden: {
                id,
                __typename: 'Vorschaden',
                positionen: null,
                notizen: []
            },
            schaden: {
                id,
                __typename: 'Schaden',
                positionen: null,
                beschreibungen: [],
                notizen: []
            },
            feststellungen: {
                id,
                __typename: 'Feststellungen',
                umfaenge: null,
                positionen: null,
                beschreibungen: [],
                notizen: []
            },
            ausstattung: {
                id,
                __typename: 'Ausstattung',
                gruppen: null,
                individualaufbauten: [],
                notizen: []
            },
            unterlagen: {
                id,
                __typename: 'Unterlagen',
                fernbedienungStandheizung: null,
                positionen: null,
                schluesselAHK: null,
                schluesselFahrzeug: null,
                notizen: []
            },
            wartung: {
                id,
                __typename: 'Wartung',
                letzterServiceNichtBekannt: null,
                letzterServiceBei: null,
                letzterServiceFaelligEinheit: null,
                letzterServiceAm: null,
                naechsterServiceFaelligNichtBekannt: null,
                naechsterServiceFaelligIn: null,
                naechsterServiceFaelligEinheit: null,
                naechsterServiceFaelligInZeit: null,
                naechsterServiceFaelligEinheitZeit: null,
                naechsterServiceFaelligAm: null,
                naechsterServiceFaelligKosten: null,
                naechsterServiceBeschreibung: [],
                naechsteHauptAbgasUntersuchungNichtBekannt: null,
                naechsteHauptAbgasUntersuchung: null,
                hauptAbgasUntersuchungFaelligKosten: null,
                zahnriemenwechselBei: null,
                zahnriemenwechselFaelligEinheit: null,
                zahnriemenwechselAm: null,
                zahnriemenFaelligKosten: null,
                positionen: null,
                notizen: []
            },
            werte: {
                id,
                __typename: 'Werte',
                roundValue: null,
                bezugsdatum: null,
                haendlereinkaufswert: null,
                haendlereinkaufswertDrucken: null,
                haendlereinkaufswertNetto: null,
                haendlereinkaufswertNettoDrucken: null,
                haendlerverkaufswert: null,
                haendlerverkaufswertDrucken: null,
                haendlerverkaufswertNetto: null,
                haendlerverkaufswertNettoDrucken: null,
                datHvkNettoDifferenz: null,
                datHvkNettoRegel: null,
                haendlerverkaufswertBesteuerung: null,
                aufwendungen: null,
                aufwendungenDrucken: null,
                aufwendungenDetailsDrucken: null,
                haendlereinkaufswertAbzuege: null,
                haendlereinkaufswertAbzuegeDrucken: null,
                haendlereinkaufswertAbzuegeNetto: null,
                haendlereinkaufswertAbzuegeNettoDrucken: null,
                haendlerverkaufswertAbzuege: null,
                haendlerverkaufswertAbzuegeDrucken: null,
                haendlerverkaufswertAbzuegeNetto: null,
                haendlerverkaufswertAbzuegeNettoDrucken: null,
                manuelleWerteDrucken: null,
                manuelleWerte: [],
                wertAmMarkt: null,
                wertAmMarktBemerkung: null,
                wertAmMarktBemerkungen: [],
                restwertAusUnfallschaden: null,
                restwertAusUnfallschadenNetto: null,
                neuwert: null,
                neuwertDrucken: null,
                relativerWert: null,
                relativerWertDrucken: null,
                werterhoehendesZubehoer: null,
                werterhoehenderWartungszustand: null,
                werterhoehenderReparaturzustand: null,
                zweiterRadsatzAnteilig: null,
                externalServicesReferenceId: null,
                notizen: []
            },
            vtiWerte: {
                id,
                __typename: 'VtiWerte',
                bezugsdatum: null,
                haendlereinkaufswert: null,
                haendlereinkaufswertDrucken: null,
                haendlereinkaufswertNetto: null,
                haendlereinkaufswertNettoDrucken: null,
                haendlerverkaufswert: null,
                haendlerverkaufswertDrucken: null,
                haendlerverkaufswertNetto: null,
                haendlerverkaufswertNettoDrucken: null,
                haendlerverkaufswertBesteuerung: null,
                wertAmMarkt: null,
                wertAmMarktBemerkungen: [],
                neuwert: null,
                neuwertDrucken: null,
                relativerWert: null,
                relativerWertDrucken: null,
                externalServicesReferenceId: null,
                notizen: []
            },
            fehlteile: {
                id,
                __typename: 'Fehlteile',
                positionen: null,
                notizen: []
            },
            uebersicht: {
                id,
                __typename: 'Uebersicht',
                deckblatt: null,
                vorneLinks: null,
                vorneRechts: null,
                hintenLinks: null,
                hintenRechts: null,
                innenraum: null,
                sonstiges: null,
                notizen: []
            },
            lackmessung: {
                id,
                __typename: 'Lackmessung',
                messungen: null,
                gesamtKarosserieVon: null,
                gesamtKarosserieBis: null,
                untersuchungsErgebnis: null,
                beschreibungen: [],
                notizen: []
            },
            rechnungsArt: null,
            versandArt: null,
            anzahlBilder: null,
            anhaenge: {
                id,
                __typename: 'Anhaenge',
                anhaenge: null
            },
            restwertprognose: {
                id,
                __typename: 'Restwertprognose',
                bezugsdatum: null,
                vertragsnummer: null,
                vertragsbeginn: null,
                vertragsende: null,
                vereinbarteKilometer: null,
                tatsaechlicheKilometer: null,
                differenzKilometer: null,
                datDossierId: null,
                restwertNetto: null,
                restwertBrutto: null,
                dokument: null,
                notizen: []
            },
            kalkulation: {
                id,
                __typename: 'Kalkulation',
                bezugsdatum: null,
                dossierId: null,
                reparaturkostenNetto: null,
                reparaturkostenBrutto: null,
                dokument: null
            },
            vtiAbschluss: {
                id,
                __typename: 'VtiAbschluss',
            },
            acAbschluss: {
                id,
                __typename: 'AcAbschluss',
                acVersendet: null
            },
            abschluss: {
                id,
                __typename: 'Abschluss',
                fazit: []
            }
        };
        return produkt;
    }
}
