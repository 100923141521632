import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { Breakpoint } from '@shared/service/viewport.service';
import { Object3D, PerspectiveCamera, Scene, sRGBEncoding, WebGLRenderer } from 'three';
import { GltfComponent } from '../gltf/gltf.component';
import { ObjectViewControlComponent } from '../object-view-control/object-view-control.component';

@Component({
    selector: 'app-scene',
    template:
        '<div class="scene" #container>' +
        '<ng-content></ng-content>' +
        '</div>',
    styleUrls: ['./scene.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SceneComponent implements AfterViewInit, OnDestroy {

    private animationLoopRunning = false;
    private scene: Scene;
    private camera: PerspectiveCamera;
    private renderer: WebGLRenderer;

    @ViewChild('container', { static: true })
    public container: ElementRef<HTMLElement>;

    @ContentChild(GltfComponent, { static: true })
    public gltf: GltfComponent;

    @ContentChild(ObjectViewControlComponent, { static: true })
    public objectViewControl: ObjectViewControlComponent;

    @HostListener('window:resize')
    public onResize(): void {
      this.update();
    }

    public ngAfterViewInit(): void {
        setTimeout(() => this.init(), 1);
    }

    public ngOnDestroy(): void {
        this.destroy();
    }

    // STARTUP & SHUTDOWN

    private init(): void {
        this.create();
        if (this.objectViewControl) {
            this.objectViewControl.init(this.camera, this.container.nativeElement);
        }
        if (this.gltf) {
            this.gltf.init(this.camera, this.renderer, this.scene);
        }
        this.startAnimationLoop();
    }

    private destroy(): void {
        this.stopAnimationLoop();
        this.cleanup();
    }

    // CREATE

    private create(): void {
        this.createScene();
        this.createCamera();
        this.createRenderer();
    }

    private createScene(): void {
        this.scene = new Scene();
    }

    private createCamera(): void {
        this.camera = new PerspectiveCamera(45, this.getAspectRatio(), 0.1, 1500);
        this.camera.position.set(0, 2500, 0);
    }

    private createRenderer(): void {
        const { clientWidth, clientHeight } = this.getClientSize();

        this.renderer = new WebGLRenderer({
            antialias: true
        });
        this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.physicallyCorrectLights = true;
        this.renderer.shadowMap.enabled = true;
        this.renderer.setClearColor(0xFFFFFF, 1);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(clientWidth, clientHeight);

        const container = this.container.nativeElement;
        container.appendChild(this.renderer.domElement);
    }

    // ANIMATION

    private startAnimationLoop(): void {
        this.animationLoopRunning = true;
        this.animate();
    }

    private stopAnimationLoop(): void {
        this.animationLoopRunning = false;
    }

    private animate(): void {
        if (this.animationLoopRunning) {
            requestAnimationFrame(this.animate.bind(this));
            if (this.renderer) {
                this.renderer.render(this.scene, this.camera);
                if (this.gltf) {
                    this.gltf.animate();
                }
                if (this.objectViewControl) {
                    this.objectViewControl.animate();
                }
            }
        }
    }

    // UPDATE

    private update(): void {
        if (this.container) {
            if (this.camera) {
                this.updateCamera();
            }
            if (this.renderer) {
                this.updateRenderer();
            }
            if (this.objectViewControl) {
                this.objectViewControl.update();
            }
        }
    }

    private updateCamera(): void {
        this.camera.aspect = this.getAspectRatio();
        this.camera.updateProjectionMatrix();
    }

    private updateRenderer(): void {
        const { clientWidth, clientHeight } = this.getClientSize();
        this.renderer.setSize(clientWidth, clientHeight);
    }

    // CLEANUP

    private cleanup(): void {
        if (this.scene) {
            this.disposeObj(this.scene);
        }

        if (this.renderer) {
            this.renderer.forceContextLoss();
            this.renderer.renderLists.dispose();
            this.renderer.dispose();
        }

        delete this.renderer;
        delete this.camera;
        delete this.scene;
    }

    private disposeObj(obj: Object3D): void {
        obj.traverse((child: any) => {
            [
                child.material,
                child.orgMaterial,
                child.darkMaterial
            ].forEach(material => {
                if (material) {
                    material.dispose();
                    if (material.map) {
                        material.map?.dispose();
                    }
                    if (material.lightMap) {
                        material.lightMap.dispose();
                    }
                    if (material.aoMap) {
                        material.aoMap.dispose();
                    }
                    if (material.emissiveMap) {
                        material.emissiveMap.dispose();
                    }
                    if (material.bumpMap) {
                        material.bumpMap.dispose();
                    }
                    if (material.normalMap) {
                        material.normalMap.dispose();
                    }
                    if (material.displacementMap) {
                        material.displacementMap.dispose();
                    }
                    if (material.roughnessMap) {
                        material.roughnessMap.dispose();
                    }
                    if (material.metalnessMap) {
                        material.metalnessMap.dispose();
                    }
                    if (material.alphaMap) {
                        material.alphaMap.dispose();
                    }
                }
            });

            if (child.geometry) {
                child.geometry.dispose();
                if (child.geometry.attributes) {
                    child.geometry.attributes.color = {};
                    child.geometry.attributes.normal = {};
                    child.geometry.attributes.position = {};
                    child.geometry.attributes.uv = {};
                    child.geometry.attributes = {};
                }
            }
            child.material = {};
        });
    }

    // HELPER

    private getAspectRatio(): number {
        const { clientWidth, clientHeight } = this.getClientSize();
        return clientWidth / clientHeight;
    }

    private getClientSize(): {
        clientWidth: number;
        clientHeight: number;
    } {
        const container = this.container.nativeElement;
        const factor = this.isDesktopView() ? 1 / 2 : 1;
        const clientWidth = window.innerWidth * factor;
        const clientHeight = container.clientHeight;

        return { clientWidth, clientHeight };
    }

    public isDesktopView(): boolean {
        return window.innerWidth > Breakpoint.Desktop;
    }
}
