import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PullToRefreshService {

  private static PULL_TO_REFRESH_OFF = 'pull-to-refresh-off';
  private active$ = new BehaviorSubject<boolean>(true);
  private renderer: Renderer2;

  constructor( @Inject(DOCUMENT) private document: Document,
               private rendererFactory: RendererFactory2 ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.activate();
  }

  public get active(): BehaviorSubject<boolean> {
    return this.active$;
  }

  public activate(): void {
    this.renderer.removeClass(this.document.documentElement, PullToRefreshService.PULL_TO_REFRESH_OFF);
    this.renderer.removeClass(this.document.body, PullToRefreshService.PULL_TO_REFRESH_OFF);
    this.active$.next(true);
  }

  public deactivate(): void {
    this.renderer.addClass(this.document.documentElement, PullToRefreshService.PULL_TO_REFRESH_OFF);
    this.renderer.addClass(this.document.body, PullToRefreshService.PULL_TO_REFRESH_OFF);
    this.active$.next(false);
  }
}
