import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ProduktArt } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { Assert } from '@shared/helper/assert';
import { NEVER, Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProduktErstellenResolve  {
    constructor(
        private readonly produktService: ProduktService,
        private readonly router: Router) {
        Assert.notNullOrUndefined(produktService, 'produktService');
        Assert.notNullOrUndefined(router, 'router');
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<Produkt> {
        Assert.notNullOrUndefined(route, 'route');
        const art = <ProduktArt>+route.paramMap.get('art');
        return this.produktService.create(art).pipe(
            flatMap(produkt => {
                this.router.navigateByUrl(`/produkt/detail/${produkt.id}`);
                return NEVER;
            })
        );
    }
}
