import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FehlteileBezeichnung } from '@data/domain/schema/enum';
import { Fehlteile, FehlteileInput, FehlteilePosition, Notiz } from '@data/domain/schema/type';
import { ProduktFehlteileService } from '@data/domain/service/feature';
import { UpdateWerteService } from '@data/domain/service/feature/update-werte-service';
import { ProduktDetailFeatureInputComponent } from '@modules/produkt/component/produkt-detail-feature/produkt-detail-feature.component';
import { FeatureField, FeatureFieldArray, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailFehlteileFormViewFactory } from '@modules/produkt/factory/fehlteile/produkt-detail-fehlteile-form-view.factory';
import { ProduktDetailFehlteilePositionFormViewFactory } from '@modules/produkt/factory/fehlteile/produkt-detail-fehlteile-position-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktConfigResolveService } from '@modules/produkt/service/produkt-config-resolve.service';
import { ProduktDetailFeatureNotizenService } from '@modules/produkt/service/produkt-detail-feature-notizen.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { TranslateService } from '@ngx-translate/core';
import { TableRowMoveEvent } from '@shared/component/data-table';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

interface ProduktDetailFehlteileDialogData {
  form: ViewFormGroup;
  fields: FeatureFields;
}

@Component({
  selector: 'app-produkt-detail-fehlteile',
  templateUrl: './produkt-detail-fehlteile.component.html',
  styleUrls: ['./produkt-detail-fehlteile.component.scss'],
  providers: [ProduktDetailFeatureNotizenService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailFehlteileComponent extends ProduktDetailFeatureInputComponent<Fehlteile, FehlteileInput> implements OnInit {
  private positionenName = PRODUKT_CONFIG_FEATURES.Fehlteile.fields.Positionen.name;

  public trackByField = TrackBy.trackByField;

  public notizen$: Observable<Notiz[]>;
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;

  public positionen: ViewFormArray;
  public positionenFields: FeatureFields;

  public rows$: Observable<AbstractViewFormControl[]>;
  public sums$: Observable<string>;

  public bezeichnung = new EnumValues(FehlteileBezeichnung);

  @ViewChild('dialog', { static: true })
  public dialogTemplate: TemplateRef<any>;

  constructor(
    produktConfigResolveService: ProduktConfigResolveService,
    produktDetailResolveService: ProduktDetailResolveService,
    produktFehlteileService: ProduktFehlteileService,
    private readonly formViewFactory: ProduktDetailFehlteileFormViewFactory,
    private readonly positonFormViewFactory: ProduktDetailFehlteilePositionFormViewFactory,
    private readonly templateDialogService: TemplateDialogService,
    private readonly currencyFormatter: CurrencyFormatterService,
    private readonly translateService: TranslateService,
    private readonly viewportService: ViewportService,
    private readonly notizenService: ProduktDetailFeatureNotizenService,
    private readonly updateWerteService: UpdateWerteService) {
    super(produktConfigResolveService, produktDetailResolveService, produktFehlteileService);
    Assert.notNullOrUndefined(formViewFactory, 'formViewFactory');
    Assert.notNullOrUndefined(positonFormViewFactory, 'positonFormViewFactory');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
    Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    Assert.notNullOrUndefined(translateService, 'translateService');
    Assert.notNullOrUndefined(viewportService, 'viewportService');
    Assert.notNullOrUndefined(notizenService, 'notizenService');
  }

  public ngOnInit(): void {
    const name = PRODUKT_CONFIG_FEATURES.Fehlteile.name;
    this.notizen$ = this.notizenService.init(this.produkt, name);
    this.viewport$ = this.viewportService.observe();
    this.init(name);
  }

  public onFehlteilSelect(fehlteileBezeichnung: FehlteileBezeichnung): void {
    Assert.notNullOrUndefined(fehlteileBezeichnung, 'fehlteileBezeichnung');
    const title = `${this.name}.${FehlteileBezeichnung[fehlteileBezeichnung].toLowerCase()}`;
    this.translateService.get(title).subscribe(bezeichnung => {
      this.createPosition(bezeichnung);
    });
  }

  public onFehlteilSubmit(bezeichnung: string): void {
    Assert.notNullOrEmpty(bezeichnung, 'bezeichnung');
    this.createPosition(ViewFormControlFormatters.firstLetterToUppercase.format(bezeichnung));
  }

  public onRowOpen(row: ViewFormGroup): void {
    Assert.notNullOrUndefined(row, 'row');
    const index = this.positionen.controls.indexOf(row);
    this.editPosition(index, row.getRawValue());
  }

  public onRowRemove(row: ViewFormGroup): void {
    Assert.notNullOrUndefined(row, 'row');
    const index = this.positionen.controls.indexOf(row);
    this.positionen.removeAt(index);
    if (this.positionen.length === 0) {
      this.updateWerteService.resetAufwendungen(this.produkt);
    }
  }

  public onRowMove(event: TableRowMoveEvent): void {
    Assert.notNullOrUndefined(event, 'event');
    const index = this.positionen.controls.indexOf(event.row);
    this.positionen.controls.splice(index, 1);
    this.positionen.controls.splice(index + event.offset, 0, event.row);
    this.positionen.updateValueAndValidity();
  }

  public onNotizenChange(notizen: Notiz[]): void {
    Assert.notNullOrUndefined(notizen, 'notizen');
    this.notizenService.save(notizen).subscribe();
  }

  protected createForm(): ViewFormGroup {
    const form = this.formViewFactory.create(this.produkt.fehlteile, this.fields);
    this.positionen = <ViewFormArray>form.get(this.positionenName);
    this.positionenFields = (<FeatureFieldArray>this.fields
      .find((x: FeatureFieldArray) => x.arrayName === this.positionenName))
      .fields;
    this.rows$ = this.getRows$();
    this.sums$ = this.getSums$();
    return form;
  }

  private editPosition(index: number, position: FehlteilePosition): void {
    const fields = this.positionenFields;
    const form = this.positonFormViewFactory.create(position, fields);
    const buttons = [`${this.name}.cancel`, `${this.name}.save`];
    const data: ProduktDetailFehlteileDialogData = { form, fields };

    this.templateDialogService.openTemplate(position.bezeichnung, buttons,
      this.dialogTemplate, data).subscribe((result) => {
        if (result && result.name === buttons[1]) {
          (<ViewFormGroup>this.positionen.controls[index]) = result.data.form;
          this.positionen.updateValueAndValidity();
        }
      });
  }

  private createPosition(bezeichnung: string): void {
    const fields = this.positionenFields;
    const form = this.positonFormViewFactory.create({ bezeichnung }, fields);
    const buttons = [`${this.name}.cancel`, `${this.name}.save`];
    const data: ProduktDetailFehlteileDialogData = { form, fields };

    this.templateDialogService.openTemplate(bezeichnung, buttons,
      this.dialogTemplate, data).subscribe((result) => {
        if (result && result.name === buttons[1]) {
          this.positionen.push(result.data.form);
        }
      });
  }

  private getRows$(): Observable<AbstractViewFormControl[]> {
    return this.positionen.valueChanges.pipe(
      startWith({}),
      map(() => <AbstractViewFormControl[]>[...this.positionen.controls]),
    );
  }

  private getSums$(): Observable<string> {
    return this.positionen.valueChanges.pipe(startWith({}), map(() => {
      const sums = [];
      this.positionenFields.forEach((field: FeatureField) => {
        if (field.name === 'preis') {
          const sum = this.positionen.controls
            .reduce((cv, cy: ViewFormGroup) => cv + cy.getRawValue()[field.name], 0);
          sums.push(this.currencyFormatter.format(sum) + '&nbsp;€');
        }
      });
      return sums.join('&nbsp;|&nbsp;');
    }));
  }
}
