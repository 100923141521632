import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { FileData } from '@app/class/file-data';
import { readFile } from '@app/function/file';
import { Assert } from '@shared/helper/assert';
import * as MediaRecorder from 'audio-recorder-polyfill';
import { from, Observable, of, throwError } from 'rxjs';
import { flatMap, map, retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AudioRecorderService {
    private recorder: MediaRecorder;

    constructor(private readonly platform: Platform) {
        Assert.notNullOrUndefined(platform, 'platform');
    }

    public start(): Observable<void> {
        if (this.platform.IOS) {
            return throwError('ios is not supported.');
        }
        if (this.recorder) {
            return throwError('already recording.');
        }

        return from(navigator.mediaDevices.getUserMedia({
            audio: true, video: false
        })).pipe(
            retry(1),
            map(stream => {
                this.recorder = new MediaRecorder(stream);
                this.recorder.addEventListener('stop', () => {
                    const tracks = stream.getTracks();
                    tracks.forEach(track => {
                        track.stop();
                    });
                });
                this.recorder.start();
            })
        );
    }

    public stop(): Observable<FileData<ArrayBuffer>> {
        if (!this.recorder) {
            return of(null);
        }
        const promise = new Promise<Observable<FileData<ArrayBuffer>>>((resolve, _error) => {
            this.recorder.addEventListener('dataavailable', (event: any) => {
                this.recorder = null;
                const file = event.data;
                resolve(readFile(file));
            });
            this.recorder.stop();
        });
        return from(promise).pipe(flatMap(x => x));
    }
}
