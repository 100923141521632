import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GridTile } from '@shared/component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent {
  public tiles: GridTile[] = [
    {
      icon: 'product_gtue',
      link: '/auftraege',
      id: 'id-auftraege',
      title: 'Aufträge',
      disabled: true
    },
    {
      icon: 'product_add',
      link: '/produkt/erstellen',
      id: 'id-neues-produkt',
      title: 'Neues Produkt'
    },
    {
      icon: 'product_my',
      link: '/produkt/uebersicht/gtue-em',
      id: 'id-meine-produkte',
      title: 'Meine Produkte'
    },
    {
      icon: 'alphacontroller',
      link: '/produkt/uebersicht-ac',
      id: 'id-alphacontroller',
      title: 'AlphaController'
    },
    {
      icon: 'settings',
      link: '/verwaltung',
      id: 'id-verwaltung',
      title: 'Einstellungen',
    },
  ];
}
