<h4 *ngIf="!this.hasFahrzeugExternalServiceReference">⚠ Kalkulation kann nur bei einer erfolgreichen DAT-Identabfrage durchgeführt werden!</h4>

  <div class="button-row-container">
    <div class="toggle">
      <app-toggle-group [value]="display$ | async" (valueChange)="onDisplayChange($event)">
        <app-toggle [value]="displayEnum.Daten">
          {{name + '.daten' | translate}}
        </app-toggle>
        <app-toggle [value]="displayEnum.KalkulationDisplay" [disabled]="!this.hasFahrzeugExternalServiceReference">
          {{name + '.kalkulation' | translate}}
        </app-toggle>
      </app-toggle-group>
      <app-button class='align-right' [loading]="loading$ | async" [type]="ButtonType.Flat" [color]="'primary'"
                  [disabled]="!this.hasFahrzeugExternalServiceReference" (action)="onOpenKalkulationInTab()">
        {{name + '.kalkulation.tab' | translate}}
      </app-button>
    </div>
  </div>

  <div class="main-content">
  <ng-container [ngSwitch]="display$ | async">
    <app-card class="daten" *ngSwitchCase="displayEnum.Daten">
        <ng-container *ngTemplateOutlet="kalkulation"></ng-container>
    </app-card>

    <app-card *ngSwitchDefault>
      <div id="iframeContainer"></div>
      <app-progress-spinner class="loading-spinner" [class.loading-finished]="loading$ | async" [diameter]="150"></app-progress-spinner>
    </app-card>

  </ng-container>
</div>

<ng-template #kalkulation>
  <ng-container *ngFor="let field of kalkulationFields;trackBy:trackByField">
    <ng-container [ngSwitch]="field.name">
      <ng-container *ngSwitchCase="'dossierId'">
        <app-input [disabled]="true" [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text">
        </app-input>
      </ng-container>
      <ng-container *ngSwitchCase="'bezugsdatum'">
        <app-date [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" [readonly]="true"
                  [hasDatepicker]="false" [disabled]="true">
        </app-date>
      </ng-container>
      <ng-container *ngSwitchCase="'reparaturkostenNetto'">
        <ng-container *ngTemplateOutlet="textCopy, context: { field: field }">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'reparaturkostenBrutto'">
        <ng-container *ngTemplateOutlet="textCopy, context: { field: field }">
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="download-kalkulation-container">
    <p>{{name + '.' + 'dokument' | translate}}</p>
    <app-button [disabled]="!this.produkt.kalkulation.dokument" class="download-button" (action)="downloadKalkulation()">
      <app-mat-icon icon="picture_as_pdf" color="primary"></app-mat-icon>
    </app-button>
  </div>
</ng-template>

<ng-template #textCopy let-field="field">
  <div class="text-copy-container">
    <app-input #userinput [disabled]="true" [form]="form" [name]="field.name" [displayName]="name + '.' + field.name"
               type="text" inputmode="text">
    </app-input>
    <app-button class="copy-button" (action)="copy(userinput.input.nativeElement.id)">
      <app-mat-icon icon="file_copy"></app-mat-icon>
    </app-button>
  </div>
</ng-template>

<ng-template #loading>
  <app-progress-spinner class="loading-spinner" [diameter]="30"></app-progress-spinner>
</ng-template>
