import { Injectable } from '@angular/core';
import { BesteuerungArt, ProduktArt, WerteRoundValue } from '@data/domain/schema/enum';
import { Fehlteile, Schaden, Vorschaden, Wartung, Werte } from '@data/domain/schema/type';
import { Assert } from '@shared/helper/assert';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailWertePositionenFormViewFactory {
    constructor(private readonly currencyFormatterService: CurrencyFormatterService) {
        Assert.notNullOrUndefined(currencyFormatterService, 'currencyFormatterService');
    }

    private rundungsFaktor = 10;
    private mwstRegelbesteuerungSatz = 19;
    private mwstDifferenzbesteuerungSatz = 2.4;

    public create(
        werte: Werte,
        vorschaden: Vorschaden,
        schaden: Schaden,
        produktArt: ProduktArt,
        fehlteile: Fehlteile,
        wartung: Wartung,
        roundValue = WerteRoundValue.Netto): ViewFormGroup[][] {

        const abzuegeGroups = [
            this.addVorschaden(vorschaden),
            this.addSchaden(schaden, produktArt),
            this.addFehlteile(fehlteile),
            this.addWartung(wartung),
        ].filter(x => x !== null);

        return [
            this.addBase(werte, roundValue),
            this.addWerte(werte),
            abzuegeGroups,
        ];
    }

    private addBase(werte: Werte, roundValue = WerteRoundValue.Netto): ViewFormGroup[] {
      let hekNetto; let hekBrutto; let hvkNetto; let hvkBrutto;

      if (roundValue === WerteRoundValue.Brutto) {
        hekBrutto = Math.round(werte.haendlereinkaufswert / this.rundungsFaktor) * this.rundungsFaktor;
        hekNetto = hekBrutto / (1 + this.mwstRegelbesteuerungSatz / 100);
        hvkBrutto = Math.round(werte.haendlerverkaufswert / this.rundungsFaktor) * this.rundungsFaktor;
        hvkNetto= hvkBrutto / (1 + this.getMwstSatz(werte.haendlerverkaufswertBesteuerung) / 100);
      } else {
        hekNetto = Math.round(werte.haendlereinkaufswertNetto / this.rundungsFaktor) * this.rundungsFaktor;
        hekBrutto = hekNetto * (1 + this.mwstRegelbesteuerungSatz / 100);
        hvkNetto= Math.round(werte.haendlerverkaufswertNetto / this.rundungsFaktor) * this.rundungsFaktor;
        hvkBrutto = hvkNetto * (1 + this.getMwstSatz(werte.haendlerverkaufswertBesteuerung) / 100);
      }

        return [
            this.createGroup('werte.haendlereinkaufswert', hekBrutto || 0),
            this.createGroup('werte.haendlereinkaufswertNetto', hekNetto || 0),
            this.createGroup('werte.haendlerverkaufswert', hvkBrutto || 0),
            this.createGroup('werte.haendlerverkaufswertNetto', hvkNetto || 0),
            this.createGroup('werte.neuwert', werte.neuwert || 0),
        ].filter(x => x.getRawValue().wert !== 0);
    }

    private addWerte(werte: Werte): ViewFormGroup[] {
        return [
            this.createGroup('werte.werterhoehendesZubehoer', werte.werterhoehendesZubehoer || 0),
            this.createGroup('werte.werterhoehenderWartungszustand', werte.werterhoehenderWartungszustand || 0),
            this.createGroup('werte.werterhoehenderReparaturzustand', werte.werterhoehenderReparaturzustand || 0),
            this.createGroup('werte.zweiterRadsatzAnteilig', werte.zweiterRadsatzAnteilig || 0),
        ].filter(x => x.getRawValue().wert !== 0);
    }

    private addVorschaden(vorschaden: Vorschaden): ViewFormGroup {
        if (!vorschaden || !vorschaden.positionen) {
            return null;
        }

        const bestaetigtePositionen = vorschaden.positionen.filter(pos => pos.extern ? pos.bestaetigt : true);
        const wertminderung = bestaetigtePositionen
            ? bestaetigtePositionen.reduce((x, y) => x + (y.wertminderung || 0), 0)
            : 0;

        if (wertminderung === 0) {
            return null;
        }

        return this.createGroup('werte.abzug.wertminderung', wertminderung);
    }

    private addSchaden(schaden: Schaden, produktArt: ProduktArt): ViewFormGroup {
        if (!schaden || !schaden.positionen) {
            return null;
        }

        if (produktArt === ProduktArt.Ruecknahmebewertung || produktArt === ProduktArt.AlphaController) {
            const bestaetigtePositionen = schaden.positionen.filter(pos => pos.extern ? pos.bestaetigt : true);
            const minderwerte = bestaetigtePositionen
                ? bestaetigtePositionen.reduce((x, y) => x + (y.minderwert || 0), 0)
                : 0;
            if (minderwerte === 0) {
                return null;
            }
            return this.createGroup('werte.abzug.minderwerte', minderwerte);
        } else {
            const bestaetigtePositionen = schaden.positionen.filter(pos => pos.extern ? pos.bestaetigt : true);
            const preise = bestaetigtePositionen
                ? bestaetigtePositionen.reduce((x, y) => x + (y.preis || 0), 0)
                : 0;
            if (preise === 0) {
                return null;
            }
            return this.createGroup('werte.abzug.schaeden', preise);
        }
    }

    private addFehlteile(fehlteile: Fehlteile): ViewFormGroup {
        if (!fehlteile || !fehlteile.positionen) {
            return null;
        }

        const preise = fehlteile.positionen
            ? fehlteile.positionen.reduce((x, y) => x + (y.preis || 0), 0)
            : 0;

        if (preise === 0) {
            return null;
        }
        return this.createGroup('werte.abzug.fehlteile', preise);
    }

    private addWartung(wartung: Wartung): ViewFormGroup {
        if (!wartung) {
            return null;
        }

        const kosten = [
            wartung.naechsterServiceFaelligKosten,
            wartung.hauptAbgasUntersuchungFaelligKosten,
            wartung.zahnriemenFaelligKosten,
            ...(wartung.positionen || []).map(position => position.kosten),
        ].reduce((x, y) => x + (y || 0), 0);

        if (kosten === 0) {
            return null;
        }
        return this.createGroup('werte.abzug.wartung', kosten);
    }

    private createGroup(bezeichnung: string, wert: number): ViewFormGroup {
        return new ViewFormGroup({
            bezeichnung: new ViewFormControl(bezeichnung),
            wert: new ViewFormControl(wert, {
                formatter: this.currencyFormatterService
            }),
        });
    }

    private getMwstSatz(besteuerungsart: BesteuerungArt) {
        if (besteuerungsart === BesteuerungArt.Differenzbesteuert) {
            return this.mwstDifferenzbesteuerungSatz;
        }
        return this.mwstRegelbesteuerungSatz;
    }
}
