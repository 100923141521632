import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProduktStatusService } from '@app/service/produkt-status.service';
import {
  ProduktArt,
  ProduktArtSelectBewertung,
  ProduktArtSelectZustandsbericht,
  ProduktStatus
} from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktartService } from '@data/domain/service/feature/produktart.service';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { EnumValues } from '@shared/helper/values';
import { DisplayService } from '@shared/service/display.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-produkt-detail-toolbar',
  templateUrl: './produkt-detail-toolbar.component.html',
  styleUrls: ['./produkt-detail-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ProduktDetailToolbarComponent implements OnInit, OnDestroy {
  public produkt$: Observable<Produkt>;
  public visible$: BehaviorSubject<boolean>;

  public produktArt = ProduktArt;
  public artBewertungValues = new EnumValues(ProduktArtSelectBewertung);
  public artZustandsberichtValues = new EnumValues(ProduktArtSelectZustandsbericht);

  public produkt: Produkt;
  public SelectProduktArt: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  constructor(private readonly produktDetailResolveService: ProduktDetailResolveService,
    private readonly produktartService: ProduktartService,
    private fb: UntypedFormBuilder,
    private readonly displayService: DisplayService,
    private readonly produktStatusService: ProduktStatusService) {
    Assert.notNullOrUndefined(produktDetailResolveService, 'produktDetailResolveService');
    Assert.notNullOrUndefined(produktartService, 'produktartService');
    Assert.notNullOrUndefined(displayService, 'displayService');
    Assert.notNullOrUndefined(produktStatusService, 'produktStatusService');
  }

  public ngOnInit(): void {
    this.produkt$ = this.produktDetailResolveService.change();
    this.produkt = this.produktDetailResolveService.get();
    this.SelectProduktArt = this.fb.group({
      SelectProduktArt: [this.produkt.art, Validators.required]
    });
    this.subscriptions.push(this.getStatusChangedSubscription());
    this.produktStatusService.update(this.produkt.status);
    this.visible$ = this.displayService.showToolbar;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public onChangeProduktart($event: any): void {
    Assert.notNullOrUndefined($event, '$event');
    this.produktartService.save(this.produkt.id, $event.value);
    window.location.reload();
  }

  private getStatusChangedSubscription(): Subscription {
    return this.produktStatusService.currentStatus$.subscribe(
      status => {
 status !== ProduktStatus.Offen ? this.SelectProduktArt.disable() : this.SelectProduktArt.enable();
}
    );
  }
}
