<mat-form-field *ngIf="filterPredicate && customFilterVisible">
    <mat-label>{{'table.filter' | translate}}</mat-label>
    <input matInput (keyup)="applyFilter($event)">
</mat-form-field>
<div class="content-wrapper mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>
        <ng-container [matColumnDef]="column.name" *ngFor="let column of columns$ | async">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.sort" [start]="column.start"
                scope="col">
                {{column.displayName | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngTemplateOutlet="column.template, context: { element: element }"></ng-container>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async;"
            [class.clickable]="action.observers.length > 0" (click)="onRowClick(row)"></tr>
    </table>
    <mat-paginator *ngIf="pageSizeOptions" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
</div>
