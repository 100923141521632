import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import { Produkt } from '@data/domain/schema/type';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { TextbausteineComponent } from '@shared/component/form-controls/textbausteine/textbausteine.component';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { DecimalSeperatorFormatterService } from '@shared/service/form-controls/decimal-separator-formatter.service';
import { SnackBarService } from '@shared/service/snack-bar.service';

@Component({
  selector: 'app-produkt-detail-lackmessung-messung',
  templateUrl: './produkt-detail-lackmessung-messung.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailLackmessungMessungComponent implements  AfterViewInit {
  public trackByField = TrackBy.trackByField;

  public fieldBemerkungen = PRODUKT_CONFIG_FEATURES.Lackmessung.fields.Messungen.fields.Bemerkungen.name;

  @Input()
  public produkt: Produkt;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public fields: FeatureFields;

  @ViewChild('bemerkungen')
  public bemerkungenElement: TextbausteineComponent;

  constructor(
    private readonly fileFieldService: ProduktDetailFileFieldService,
    private readonly textbausteineService: TextbausteineService,
    private readonly decimalSeperatorFormatter: DecimalSeperatorFormatterService,
    private readonly snackBarService: SnackBarService) {
  }

  public ngAfterViewInit(): void {
    if (this.form.get(this.fieldBemerkungen) && this.form.get(this.fieldBemerkungen).value.length === 0) {
      this.textbausteineService.prefillWithStandardTextbausteine(this.name, this.fieldBemerkungen, this.produkt.art, <ViewFormGroup> this.form, this.bemerkungenElement);
    }
  }

  public onErgebnisAdd($event: string): void {
    Assert.notNullOrUndefined($event, '$event');
    const parsedNumber = this.decimalSeperatorFormatter.parse($event);

    if (parsedNumber === undefined) {
      this.snackBarService.error('Invalid input format! | Falsches Format!');
      return;
    }

    const formattedNumber = this.decimalSeperatorFormatter.format(parsedNumber);
    this.form.get('ergebnis').value.push(formattedNumber);
  }

  public onErgebnisRemove(index: number): void {
    this.form.get('ergebnis').value.splice(index, 1);
  }

  public onFileAdd(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(files, 'files');
    this.fileFieldService.add(field, files);
  }

  public onFileDelete(field: UntypedFormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.remove(field, fileId);
  }

  public onFileUpdate(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(event, 'event');
    this.fileFieldService.update(field, event);
  }

  public onFileReset(field: UntypedFormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.reset(field, fileId);
  }

  public isFileResetable(fileId: string): boolean {
    Assert.notNullOrEmpty(fileId, 'fileId');
    return this.fileFieldService.isUpdateable(fileId);
  }
}
