import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { TrackBy } from '@shared/helper/track-by';
import { Subscription } from 'rxjs';
import { FormControlBase } from '../form-control-base.component';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent extends FormControlBase {
  private checkboxChangeSubscription$: Subscription = undefined;

  public trackByKey = TrackBy.trackByKey;

  @Input()
  public checked: boolean;

  @Input()
  public valuePrefix = '';

  @Input()
  public disabled = false;

  @ViewChild(MatCheckbox, { static: true})
  public checkbox: MatCheckbox;

  @Output()
  public valueChanged = new EventEmitter<MatCheckbox>();

  public blur(): void {
    if (this.checkboxChangeSubscription$) {
      this.checkboxChangeSubscription$.unsubscribe();
      this.checkboxChangeSubscription$ = undefined;
    }
  }

  public focus(): void {
    this.checkbox.focus();
    if (this.checkboxChangeSubscription$ === undefined) {
      this.checkboxChangeSubscription$ = this.checkbox.change.subscribe((value: boolean) => {
        if (!value) {
          this.next();
        }
      });
    }
  }
}
