import { AbstractControlOptions, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Assert } from '@shared/helper/assert';
import { BehaviorSubject, Subscription } from 'rxjs';

export interface ViewFormControlFormatter<TValue> {
    format: (rawValue: TValue) => string;
    parse: (viewValue: string) => TValue;
}

export interface ViewFormControlArrayFormatter<TValue> {
  format: (rawValue: TValue) => string[];
  parse: (viewValue: string[]) => TValue;
}

export interface ViewFormControlOptions<TValue> extends AbstractControlOptions {
    formatter?: ViewFormControlFormatter<TValue>;
    arrayFormatter?: ViewFormControlArrayFormatter<TValue>;
}

export interface ReadonlyViewFormControlOptions<TForm, TValue> extends AbstractControlOptions {
    calculateValue: (parent: UntypedFormGroup | UntypedFormArray, form: TForm) => TValue;
    continuous: boolean;
}

export abstract class AbstractViewFormControl extends UntypedFormControl {
    public touchedChanges = new BehaviorSubject<boolean>(false);
    public validChanges = new BehaviorSubject<boolean>(true);

    constructor(formState: any, options: AbstractControlOptions) {
        super(formState, options);
        this.registerOnStatusChanges();
    }

    public markAllAsTouched(): void {
        super.markAllAsTouched();
        this.touchedChanges.next(true);
    }

    public markAsTouched(opts?: {
        onlySelf?: boolean;
    }): void {
        super.markAsTouched(opts);
        this.touchedChanges.next(true);
    }

    public markAsUntouched(opts?: {
        onlySelf?: boolean;
    }): void {
        super.markAsUntouched(opts);
        this.touchedChanges.next(false);
    }

    public getRawValue(): any {
        return null;
    }

    public setRawValue(_rawValue: any): void {
        throw new Error('not implemented');
    }

    private registerOnStatusChanges(): void {
        this.statusChanges.subscribe(status => {
            this.validChanges.next(status === 'VALID');
        });
    }
}

export class ViewFormControl<TValue> extends AbstractViewFormControl {
    constructor(rawValue: TValue, private readonly options: ViewFormControlOptions<TValue> = {}) {
        super(rawValue, options);
        Assert.notNullOrUndefined(options, 'options');
        this.registerOnValueChanges();
        this.setRawValue(rawValue);
    }

    public getRawValue(): TValue {
        if (this.options.formatter) {
            return this.options.formatter.parse(this.value);
        }
        if (this.options.arrayFormatter) {
            return this.options.arrayFormatter.parse(this.value);
        }
        return this.value;
    }

    public setRawValue(rawValue: TValue): void {
        if (this.options.formatter) {
            this.setValue(this.options.formatter.format(rawValue));
        } else if(this.options.arrayFormatter) {
          this.setValue(this.options.arrayFormatter.format(rawValue));
        } else{
            this.setValue(rawValue);
        }
    }

    private registerOnValueChanges(): void {
        this.valueChanges.subscribe(viewValue => {
            if (this.options.formatter) {
                const rawValue = this.options.formatter.parse(viewValue);
                const newViewValue = this.options.formatter.format(rawValue);
                this.setValue(newViewValue, {
                    emitEvent: false
                });
            } else if (this.options.arrayFormatter) {
              const rawValue = this.options.arrayFormatter.parse(viewValue);
              const newViewValue = this.options.arrayFormatter.format(rawValue);
              this.setValue(newViewValue, {
                emitEvent: false
              });
            }
        });
    }
}

export class CalculatedViewFormControl<TForm, TValue> extends AbstractViewFormControl {
    private subscription: Subscription;

    constructor(private readonly options: ReadonlyViewFormControlOptions<TForm, TValue>) {
        super(null, options);
        Assert.notNullOrUndefined(options, 'options');
    }

    public setParent(parent: UntypedFormGroup | UntypedFormArray): void {
        super.setParent(parent);
        this.registerOnValueChanges(parent);
    }

    private registerOnValueChanges(parent: UntypedFormGroup | UntypedFormArray): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
        if (parent) {
            this.subscription = parent.valueChanges.subscribe((form: TForm) => {
                const value = this.options.calculateValue(parent, form);
                this.setValue(value, {
                    emitEvent: this.value !== value
                });
                if (!this.options.continuous) {
                    this.subscription.unsubscribe();
                }
            });
            parent.updateValueAndValidity();
        }
    }
}
