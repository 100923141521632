import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AbschlussDownloadResponse, AbschlussDownloadService, AbschlussGetResponse, AbschlussRechnungsArt, AbschlussRechnungsService, AbschlussService, AbschlussVersandArt, AbschlussVersandService, AbschlussWorkflowStatus, AbschlussWorkflowStep } from '@data/api-gateway';
import { ProduktArtNachbewertung, ProduktRechnungsArt, ProduktStatus, ProduktVersandArt } from '@data/domain/schema/enum';
import { Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { StepperComponent } from '@shared/component/layout/stepper';
import { Assert } from '@shared/helper/assert';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { BehaviorSubject, iif, Observable, of, PartialObserver, Subscription, throwError } from 'rxjs';
import { concatMap, delay, finalize, first, flatMap, retryWhen, tap } from 'rxjs/operators';
import { ProduktDetailNachbewertungDialogComponent } from '../produkt-detail-nachbewertung-dialog/produkt-detail-nachbewertung-dialog.component';

const STATUS_INTERVAL = 1000;
const STATUS_INTERVAL_FACTOR = [3, 2, 1, 2, 3, 5, 5, 10, 10, 15, 30];

interface ProduktNachbewertungDialogData {
  produkt: Produkt;
}

@Component({
  selector: 'app-produkt-detail-abschluss-workflow',
  templateUrl: './produkt-detail-abschluss-workflow.component.html',
  styleUrls: ['./produkt-detail-abschluss-workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailAbschlussWorkflowComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public trackByField = TrackBy.trackByField;

  @Input()
  public name: string;

  @Input()
  public produkt: Produkt;

  @ViewChild(StepperComponent)
  public stepper: StepperComponent;

  @ViewChild('abschlussResetDialog', { static: true })
  public abschlussResetTemplate: TemplateRef<any>;

  @Output()
  public statusChangedEvent = new EventEmitter<ProduktStatus>();

  public statusChanged$ = new BehaviorSubject<ProduktStatus>(undefined);

  public rechnungsArt = new EnumValues(ProduktRechnungsArt);
  public rechnungsArtDisabled = {
    [ProduktRechnungsArt.Manuell]: true,
  };
  public rechnungsArtChanged = new BehaviorSubject<ProduktRechnungsArt>(undefined);

  public versandArt = new EnumValues(ProduktVersandArt);
  public versandArtDisabled = {
    [ProduktVersandArt.MitRechnung]: true,
    [ProduktVersandArt.OhneRechnung]: true,
  };
  public versandArtChanged = new BehaviorSubject<ProduktVersandArt>(undefined);

  public loading$ = new BehaviorSubject(false);
  public downloadUrl$ = new BehaviorSubject<string>(undefined);

  public produktArtNachbewertungValues = new EnumValues(ProduktArtNachbewertung);
  @ViewChild('dialogConfirmDuplicate', { static: true })
  public dialogConfirmDuplicateTemplate: TemplateRef<any>;
  @ViewChild('nachbewertungArtSelection')
  public selectedNachbewertungArt: ProduktDetailNachbewertungDialogComponent;

  constructor(
    private readonly router: Router,
    private readonly abschlussService: AbschlussService,
    private readonly abschlussRechnungsService: AbschlussRechnungsService,
    private readonly abschlussVersandService: AbschlussVersandService,
    private readonly abschlussDownloadService: AbschlussDownloadService,
    private readonly snackbarService: SnackBarService,
    private readonly produktService: ProduktService,
    private readonly templateDialogService: TemplateDialogService) {
    Assert.notNullOrUndefined(abschlussService, 'abschlussService');
    Assert.notNullOrUndefined(abschlussRechnungsService, 'abschlussRechnungsService');
    Assert.notNullOrUndefined(abschlussVersandService, 'abschlussVersandService');
    Assert.notNullOrUndefined(abschlussDownloadService, 'abschlussDownloadService');
    Assert.notNullOrUndefined(snackbarService, 'snackbarService');
    Assert.notNullOrUndefined(produktService, 'produktService');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.checkStatus().subscribe());
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  public onProduktCloseClick(): void {
    const observer = this.getCheckStatusObserver(`${this.name}.workflow.close.failed`);
    this.subscriptions.push(
      this.checkStatus(this.abschlussService.post(this.produkt.id))
        .subscribe(observer)
    );
  }

  public onProduktOpenClick(): void {
    const observer = this.getCheckStatusObserver(`${this.name}.workflow.reopen.failed`);
    this.subscriptions.push(
      this.checkStatus(this.abschlussRechnungsService.post(
        this.produkt.id, AbschlussRechnungsArt.Aborted
      )).subscribe(observer)
    );
  }

  public onRechungsArtSelect(rechnungsArt: ProduktRechnungsArt): void {
    Assert.notNullOrUndefined(rechnungsArt, 'rechnungsArt');

    let art = AbschlussRechnungsArt.Without;
    switch (rechnungsArt) {
      case ProduktRechnungsArt.None:
        return;
      case ProduktRechnungsArt.MitBVWS:
        art = AbschlussRechnungsArt.Bvws;
        break;
      case ProduktRechnungsArt.Manuell:
        art = AbschlussRechnungsArt.Manual;
        break;
      case ProduktRechnungsArt.Ohne:
        break;
    }

    const observer = this.getCheckStatusObserver(`${this.name}.workflow.rechnung.failed`);
    this.subscriptions.push(
      this.checkStatus(this.abschlussRechnungsService.post(
        this.produkt.id, art
      )).subscribe(observer)
    );
  }

  public onRechnungUploadClick(): void {
    this.nextStep();
  }

  public onVersandArtSelect(versandArt: ProduktVersandArt): void {
    Assert.notNullOrUndefined(versandArt, 'versandArt');

    let art = AbschlussVersandArt.NoDelivery;
    switch (versandArt) {
      case ProduktVersandArt.None:
        return;
      case ProduktVersandArt.OhneRechnung:
        art = AbschlussVersandArt.DeliveryWithoutInvoice;
        break;
      case ProduktVersandArt.MitRechnung:
        art = AbschlussVersandArt.DeliveryWithInvoice;
        break;
      case ProduktVersandArt.Kein:
        art = AbschlussVersandArt.NoDelivery;
        break;
    }

    const observer = this.getCheckStatusObserver(`${this.name}.workflow.versand.failed`);
    this.subscriptions.push(
      this.checkStatus(this.abschlussVersandService.post(
        this.produkt.id, art
      )).subscribe(observer)
    );
  }

  public onDownloadClick(): void {
    const observer = this.getActionObserver<AbschlussDownloadResponse>(
      response => this.onDownloadResponse(response),
      `${this.name}.workflow.download.failed`
    );
    this.loading$.next(true);
    this.subscriptions.push(
      this.abschlussDownloadService.get(this.produkt.id).pipe(
        finalize(() => this.loading$.next(false))
      ).subscribe(observer));
  }

  public onOpenDownloadClick(): void {
    const { value } = this.downloadUrl$;
    window.open(value, '_blank');
  }

  public onAbschlussResetClick(): void {
    this.openAbschlussResetConfirmDialog();
  }

  public onClickDuplicate($event: MouseEvent, element: Produkt): void {
    const title = `nachbewertung.title`;
    const buttons = [`feature.cancel`, `feature.confirm`];
    const data: ProduktNachbewertungDialogData = { produkt: element };
    $event.stopPropagation();
    this.templateDialogService.closeAll();

    this.templateDialogService.openTemplate(title, buttons,
      this.dialogConfirmDuplicateTemplate, data, true).subscribe(result => {
      if (result.name && result.name === buttons[1]) {
        this.loading$.next(true);
        const selectedProduktArt = this.selectedNachbewertungArt.getSelectedValue();
        this.produktService.create(selectedProduktArt).pipe(first()).subscribe(
          produkt => {
            this.produktService.getDuplikat(element.id, produkt.id, selectedProduktArt).pipe(first()).subscribe(
              _next => {
                this.loading$.next(false);
                this.router.navigateByUrl(`/produkt/detail/${produkt.id}/auftrag`);
              }, _err => {
                this.loading$.next(false);
                console.error(_err);
                this.snackbarService.error('Fehler beim duplizieren des Produktes!');
              }
            );
          });
      }
    });
  }

  public canAbschlussReset(): boolean {
    if (!this.produkt || !this.produkt.erstelltAm) {
      console.error('Either produkt or produkt.erstelltAm is undefined.');
      return false;
    }

    if (this.produkt.status === ProduktStatus.Beendet) {
      const currentMonth = new Date().getMonth();
      const produktCreatedMonth = new Date(this.produkt.erstelltAm).getMonth();

      return currentMonth === produktCreatedMonth;
    } else {
      return true;
    }
  }

  private openAbschlussResetConfirmDialog() {
    const title = this.name + '.workflow.reset.title';
    const buttons = ['feature.cancel', 'feature.confirm'];

    if (!this.canAbschlussReset()) {
      buttons.pop();
    }

    this.templateDialogService.openTemplate(title, buttons, this.abschlussResetTemplate, null, true).subscribe(
      result => {
        if (result.name && result.name === buttons[1]) {
          this.resetAbschluss();
        }
      }
    );
  }

  private resetAbschluss() {
    const observer = this.getActionObserver(
      () => window.location.reload(),
      `${this.name}.workflow.reset.failed`
    );
    this.subscriptions.push(this.abschlussService.delete(
      this.produkt.id).subscribe(observer));
  }

  private nextStep(count: number = 1): void {
    setTimeout(() => this.onNextStep(count), 1);
  }

  private updateProdukt(): Observable<Produkt> {
    return this.produktService.getInfoById(this.produkt.id).pipe(
      tap(produkt => this.onUpdateProdukt(produkt))
    );
  }

  private getActionObserver<T>(action: (value: T) => void, error: string): PartialObserver<T> {
    return {
      next: value => action(value),
      error: ex => {
        console.warn('An unexpected error occured while checking the status', ex);
        this.snackbarService.error(error);
      }
    };
  }

  private getCheckStatusObserver(error: string): PartialObserver<boolean> {
    return {
      next: (success: boolean) => {
        if (!success) {
          this.snackbarService.error(error);
        }
      },
      error: ex => {
        console.warn('An unexpected error occured while checking the status', ex);
        this.snackbarService.error(error);
      }
    };
  }

  private checkStatus(start?: Observable<any>): Observable<boolean | Produkt> {
    this.loading$.next(true);
    return (start ? start : of(null)).pipe(
      flatMap(() => this.waitUntilStep([
        AbschlussWorkflowStep.Abgebrochen,
        AbschlussWorkflowStep.InvoiceTypeDecision,
        AbschlussWorkflowStep.ProductDeliveryTypeDecision,
        AbschlussWorkflowStep.WaitForInvoice,
        AbschlussWorkflowStep.StartBilling,
        AbschlussWorkflowStep.FinishProduct,
      ])),
      flatMap(reached => iif(() => reached, this.updateProdukt(), of(null))),
      tap(() => this.nextStep(6)),
      flatMap(() => this.waitUntilStep([
        AbschlussWorkflowStep.Abgebrochen,
        AbschlussWorkflowStep.InvoiceTypeDecision,
        AbschlussWorkflowStep.ProductDeliveryTypeDecision,
        AbschlussWorkflowStep.StartBilling,
        AbschlussWorkflowStep.FinishProduct,
      ])),
      flatMap(reached => iif(() => reached, this.updateProdukt(), of(null))),
      tap(() => this.nextStep(6)),
      finalize(() => this.loading$.next(false))
    );
  }

  private waitUntilStep(requiredSteps: AbschlussWorkflowStep[]): Observable<boolean> {
    return this.abschlussService.get(this.produkt.id).pipe(
      flatMap(response => this.onStatusResponse(response, requiredSteps)),
      retryWhen(errors => errors.pipe(
        concatMap((error, retry) => this.onStatusError(error, retry))
      ))
    );
  }

  private onNextStep(count: number): void {
    if (this.stepper) {
      for (let i = 0; i < count; ++i) {
        this.stepper.next();
      }
    }
  }

  private onUpdateProdukt(produkt: Produkt): void {
    this.statusChanged$.next(produkt.status);
    this.statusChangedEvent.emit(produkt.status);
    this.rechnungsArtChanged.next(produkt.rechnungsArt);
    this.versandArtChanged.next(produkt.versandArt);
  }

  private onDownloadResponse(response: AbschlussDownloadResponse): void {
    if (response?.url?.length) {
      window.open(response.url, '_blank');
      this.downloadUrl$.next(response.url);
    }
  }

  private onStatusError(error: any, retry: number): Observable<void> {
    const retryDelay = (STATUS_INTERVAL_FACTOR[retry] || 30) * STATUS_INTERVAL;
    return of(error).pipe(delay(retryDelay));
  }

  private onStatusResponse(response: AbschlussGetResponse, requiredSteps: AbschlussWorkflowStep[]): Observable<boolean> {
    if (!response) {
      return throwError('could not retrieve status.');
    }
    const { status } = response;
    if (status === AbschlussWorkflowStatus.None
      || status === AbschlussWorkflowStatus.Aborted) {
      return of(true);
    }
    if (!response.details || !response.details.step) {
      return throwError('could not retrieve details.');
    }

    const { details } = response;
    if (status !== AbschlussWorkflowStatus.Running
      && status !== AbschlussWorkflowStatus.Succeeded) {
      return of(false);
    }

    const { step } = details;
    if (!requiredSteps.includes(step)) {
      return throwError('step not reached yet.');
    }
    return of(true);
  }
}
