import { Injectable } from '@angular/core';
import { AusstattungTeil } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailAusstattungTeilFormViewFactory extends FormViewModelBaseFactory<AusstattungTeil> {
    protected createField(model: AusstattungTeil, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Ausstattung.fields.Gruppen.fields.Teile.fields;
        switch (name) {
            case fields.Name.name:
                return new ViewFormControl(model.name);
            case fields.Vorhanden.name:
                return new ViewFormControl(model.vorhanden);
            case fields.ExternalServiceId.name:
                return new ViewFormControl(model.externalServiceId);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
