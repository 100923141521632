import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { FileService } from '@app/service/file.service';
import { EinstellungenService } from '@data/api-gateway/service/einstellungen.service';
import { Produkt } from '@data/domain/schema/type';
import { ProduktDetailResolveService } from '@modules/produkt/service/produkt-detail-resolve.service';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { Viewport, ViewportService } from '@shared/service/viewport.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, startWith, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

interface NavigationHeader {
  backUrl: string;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent implements OnInit, OnDestroy {

  public produkt$: Observable<Produkt>;
  public auftragsnummer$ = new BehaviorSubject<string>('');
  public vorgangsnummerSubscription: Subscription;
  public produkt: Produkt;
  public navigating$: Observable<boolean>;
  public backUrl$: Observable<string>;
  public syncCount$: Observable<number>;
  public syncInProgress$: Observable<boolean>;
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;
  public isActive = true;
  public routerSubscription: Subscription;
  public syncCountSubscription: Subscription;
  public environmentName = '';

  constructor(
    private readonly produktDetailResolveService: ProduktDetailResolveService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly fileService: FileService,
    private readonly snackBarService: SnackBarService,
    private readonly viewportService: ViewportService,
    private readonly einstellungenService: EinstellungenService) {
    Assert.notNullOrUndefined(router, 'router');
    Assert.notNullOrUndefined(activatedRoute, 'activatedRoute');
    Assert.notNullOrUndefined(fileService, 'fileService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
  }

  public ngOnInit(): void {
    this.produkt$ = this.produktDetailResolveService.change();
    this.environmentName = environment.name;
    this.vorgangsnummerSubscription = this.produkt$.subscribe(produkt =>  {
      produkt && produkt.auftrag ?
        this.auftragsnummer$.next(produkt.auftrag.nummer) :
        this.auftragsnummer$.next('');
    });
    this.routerSubscription = this.router.events.subscribe((val: NavigationEnd) => {
      if (val instanceof NavigationEnd) {
        this.isActive = val.url && val.url.includes('produkt/detail');
      }
    });
    this.produkt = this.produktDetailResolveService.get();
    this.registerRouterEvents();
    this.registerFileServiceEvents();
    this.viewport$ = this.viewportService.observe();
  }

  public ngOnDestroy(): void {
    this.vorgangsnummerSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    this.syncCountSubscription.unsubscribe();
  }

  public onNavigateBefore(): void {
    this.router.navigate([this.getBackUrl()]);
  }

  public onSyncClick(): void {
    this.fileService.sync().subscribe(result => {
      if (result) {
        this.snackBarService.success('fileService.sync.success');
      } else {
        this.snackBarService.error('fileService.sync.error');
      }
    });
  }

  public isProdEnvironment(): boolean {
    return this.environmentName === 'PROD';
  }

  private registerFileServiceEvents(): void {
    this.syncCount$ = this.fileService.syncCount();
    this.syncInProgress$ = this.fileService.syncInProgress();
    this.syncCountSubscription = this.syncCount$.pipe(
      switchMap(syncCount => (syncCount > 0) ? this.checkDirectSync() : of(false)),
      map(directSync => {
        if (directSync === null || directSync === undefined) {
          console.error('Unable to get benutzereinstellung - please check internet connection.');
          this.snackBarService.error('fileService.sync.error');
        }
        if (directSync) {
          this.onSyncClick();
        }
      })
    ).subscribe();
  }

  private checkDirectSync(): Observable<boolean> {
    return this.einstellungenService.getBenutzer().pipe(
      map(benutzer => benutzer.directFileSync),
      catchError(err => {
        console.error(`Error getting the directSync user configuration: ${err}`);
        return of(null);
      }
    ));
  }

  private registerRouterEvents(): void {
    this.navigating$ = this.router.events.pipe(
      filter(event => event instanceof NavigationStart
        || event instanceof NavigationEnd
        || event instanceof NavigationCancel
        || event instanceof NavigationError),
      map(event => event instanceof NavigationStart),
    );
    this.backUrl$ = this.router.events.pipe(
      startWith(new NavigationEnd(null, null, null)),
      filter(event => event instanceof NavigationEnd),
      map(() => this.getBackUrl()),
    );
  }

  private getBackUrl(): string {
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild;
    }

    let header = route.snapshot.data as NavigationHeader;
    while (!header.backUrl && route.parent) {
      route = route.parent;
      header = route.snapshot.data as NavigationHeader;
    }
    return header.backUrl;
  }
}
