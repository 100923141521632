import { Injectable, TemplateRef, Type } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TemplateDialogComponent, TemplateDialogSettings } from '@shared/component/popup-modal/template-dialog/template-dialog.component';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface TemplateDialogResult<TData> {
    name: string;
    data: TData;
}

export interface TemplateButtonDisableEvent {
    index: number;
    disable: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class TemplateDialogService {
    constructor(private readonly matDialog: MatDialog) {
        Assert.notNullOrUndefined(matDialog, 'matDialog');
    }

    private currentDialog: MatDialogRef<any>;

    public openTemplate<TData>(
        title: string,
        buttons: string[],
        templateOrComponent: TemplateRef<any> | Type<any>,
        templateData?: TData,
        disableClose: boolean = false): Observable<TemplateDialogResult<TData>> {
        this.currentDialog = this.open(title, buttons, templateOrComponent, templateData, disableClose);
        return this.currentDialog.afterClosed();
    }

    public open<TData>(
        title: string,
        buttons: string[],
        templateOrComponent: TemplateRef<any> | Type<any>,
        templateData?: TData,
        disableClose: boolean = false): MatDialogRef<any> {
        Assert.notNullOrUndefined(buttons, 'buttons');
        Assert.notNullOrUndefined(templateOrComponent, 'template');

        const settings: TemplateDialogSettings = {
            title,
            buttons,
            templateOrComponent,
            templateData,
            disableClose
        };

        return this.matDialog.open(TemplateDialogComponent, {
            data: settings,
            disableClose
        });
    }

    public enableButton(index: number): void {
        this.currentDialog.componentInstance?.enableButton(index);
    }

    public disableButton(index: number): void {
        this.currentDialog.componentInstance?.disableButton(index);
    }

    public closeAll() {
        return this.matDialog.closeAll();
    }
}
