<nav mat-tab-nav-bar (swipeLeft)="onLeft()" (swipeRight)="onRight()" [class.disabled]="disabled" [tabPanel]="tabPanel"
    color="accent">
    <a mat-tab-link class="link" [id]="link.id" *ngFor="let link of links;trackBy:trackByPath" [routerLink]="link.path" routerLinkActive
        #rla="routerLinkActive" [active]="rla.isActive">
        <ng-container *ngTemplateOutlet="iconTemplate, context: { $implicit: link }"></ng-container>
        &nbsp;{{link.title}}
    </a>
  <ng-content>
  </ng-content>
</nav>
<mat-divider></mat-divider>
