import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { S3EntwurfFileService } from '@app/service/s3-entwurf-file.service';
import { EntwurfService } from '@data/api-gateway';
import { Produkt } from '@data/domain/schema/type';
import { Feature, FeatureFieldArray, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailAbschlussEntwurfFormViewFactory } from '@modules/produkt/factory/abschluss/produkt-detail-abschluss-entwurf-form-view.factory';
import { ProduktDetailAdressenFormViewFactory } from '@modules/produkt/factory/adressen/produkt-detail-adressen-form-view.factory';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ObjectValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import * as moment from 'moment';
import { BehaviorSubject, of } from 'rxjs';
import { finalize, flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-produkt-detail-abschluss-entwurf',
  templateUrl: './produkt-detail-abschluss-entwurf.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailAbschlussEntwurfComponent implements OnInit {
  public trackByField = TrackBy.trackByField;

  public adressenAdressenForms: AbstractControl[];
  public adressenFields: FeatureFields;

  public entwurf: ViewFormGroup;
  public entwurfAdressen: ObjectValues;
  public entwurfRequestActiveChange = new BehaviorSubject(false);
  public entwurfUrl$ = new BehaviorSubject<string>(undefined);

  public adresseSelected$ = new BehaviorSubject(false);

  @Input()
  public name: string;

  @Input()
  public produkt: Produkt;

  @Input()
  public fields: FeatureFields;

  @Input()
  public adressen: Feature;

  constructor(
    private readonly entwurfService: EntwurfService,
    private readonly s3EntwurfFileService: S3EntwurfFileService,
    private readonly snackBarService: SnackBarService,
    private readonly entwurfFormViewFactory: ProduktDetailAbschlussEntwurfFormViewFactory,
    private readonly adressenFormViewFactory: ProduktDetailAdressenFormViewFactory) {
    Assert.notNullOrUndefined(entwurfService, 'entwurfService');
    Assert.notNullOrUndefined(s3EntwurfFileService, 's3EntwurfFileService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
    Assert.notNullOrUndefined(entwurfFormViewFactory, 'produktDetailAbschlussEntwurfFormViewFactory');
    Assert.notNullOrUndefined(adressenFormViewFactory, 'produktDetailAdressenFormViewFactory');
  }

  public ngOnInit(): void {
    this.adressenFields = (<FeatureFieldArray>this.adressen.fields
      .find((x: FeatureFieldArray) => x.arrayName === PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.name))
      .fields;
    const adressenForm = this.adressenFormViewFactory.create(this.produkt.adressen, this.adressen.fields);
    this.adressenAdressenForms = (<ViewFormArray>adressenForm.get(PRODUKT_CONFIG_FEATURES.Adressen.fields.Adressen.name)).controls;

    this.entwurf = this.entwurfFormViewFactory.create(this.fields);
    const entwurfAdressen = this.adressenAdressenForms.filter(form => form.valid).map(form => form.value.nameGroup);
    this.entwurfAdressen = new ObjectValues(entwurfAdressen);
  }

  public onEntwurf(): void {
    if (!this.entwurf.valid) {
      this.entwurf.updateValueAndValidity();
      this.entwurf.get('adresse').markAsTouched();
      return;
    }

    const form = this.entwurf.getRawValue();
    const produktId = this.produkt.id;
    const adresseId = this.produkt.adressen.adressen[form.adresse].id;
    const downloadFilename = this.getDownloadFilename();

    this.entwurfRequestActiveChange.next(true);
    this.entwurfService.get(produktId, adresseId).pipe(
      flatMap(response => {
        if (!response.error) {
          return this.s3EntwurfFileService.getSignedUrl(response.path, downloadFilename);
        } else {
          this.snackBarService.error(`${this.name}.entwurfService.error.${response.error}`);
          return of(null);
        }
      }),
      finalize(() => this.entwurfRequestActiveChange.next(false))
    ).subscribe(url => {
      if (url && window.open(url, '_blank') === null) {
        this.entwurfUrl$.next(url);
      }
    }, () => {
      this.snackBarService.error(`${this.name}.entwurfService.error`);
    });
  }

  public onEntwurfOpen(): void {
    const url = this.entwurfUrl$.value;
    if (url && url.length > 0) {
      window.open(url, '_blank');
    }
  }

  public onAdressenChange($event: MatSelect) {
    if ($event.value === undefined) {
      this.adresseSelected$.next(false);
    } else {
      this.adresseSelected$.next(true);
    }
  }

  private getDownloadFilename(): string {
    const fileDate = (moment(Date.now())).format('YYYYMMDD-HHmm');
    const produktArt = (this.produkt.art === 1) ? '_bewertung' : (this.produkt.art === 2) ? '_leasingruecknahme' : '_evaluate-mobility';
    const kennzeichen = (this.produkt.fahrzeug.kennzeichen) ? `_${this.produkt.fahrzeug.kennzeichen}` : '';
    return `${fileDate}_entwurf${produktArt}${kennzeichen}.pdf`;
  }
}
