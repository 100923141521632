import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  private showToolbar$ = new BehaviorSubject<boolean>(true);
  private showNav$ = new BehaviorSubject<boolean>(true);
  private showNotizButton$ = new BehaviorSubject<boolean>(true);
  private showFooter$ = new BehaviorSubject<boolean>(true);

  public get showToolbar(): BehaviorSubject<boolean> {
    return this.showToolbar$;
  }

  public get showNav(): BehaviorSubject<boolean> {
    return this.showNav$;
  }

  public get showNotizButton(): BehaviorSubject<boolean> {
    return this.showNotizButton$;
  }

  public get showFooter(): BehaviorSubject<boolean> {
    return this.showFooter$;
  }

  public showAll(): void {
    this.showToolbar$.next(true);
    this.showNav$.next(true);
    this.showNotizButton$.next(true);
    this.showFooter$.next(true);
  }

  public hideAll(): void {
    this.showToolbar$.next(false);
    this.showNav$.next(false);
    this.showNotizButton$.next(false);
    this.showFooter$.next(false);
  }
}
