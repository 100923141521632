import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tab',
  template: `
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @Input()
  public label: string;

  @ViewChild('content', { static: true })
  public template: TemplateRef<any>;
}
