import { SchadenObergruppe, SchadenUntergruppe } from '@data/domain/schema/enum';

const o = SchadenObergruppe;
const u = SchadenUntergruppe;

export const OBERGRUPPE_TO_UNTERGRUPPE_MAP = new Map<SchadenObergruppe, SchadenUntergruppe[]>([
    [o.Abgasanlage, [
        u.Abgasanlage,
        u.AbgasanlageEndschalldaempfer,
        u.AbgasanlageAuspuffblenden,
    ]],
    [o.Antriebsstrang, [
        u.Antriebsstrang,
    ]],
    [o.Armaturenbrett, [
        u.Armaturenbrett,
        u.ArmaturenbrettAblagefach,
        u.ArmaturenbrettKombiinstrument,
        u.ArmaturenbrettBedienelementHeizungKlima,
        u.ArmaturenbrettBezugArmaturenbrett,
        u.ArmaturenbrettAbdeckungSicherungskastenLinks,
        u.ArmaturenbrettAbdeckungSicherungskastenRechts,
        u.SchalteinheitArmaturenbrettAllgemein,
        u.ArmaturenbrettLuftfuehrungLinks,
        u.ArmaturenbrettLuftfuehrungRechts,
        u.ArmaturenbrettLuftfuehrungMitte,
        u.ArmaturenbrettBordcomputer,
        u.ArmaturenbrettGetraenkehalter,
        u.ArmaturenbrettDekor,
    ]],
    [o.AussenspiegelLinks, [
        u.AussenspiegelLinks,
        u.AussenspiegelLinksSpiegelkappeLackiert,
        u.AussenspiegelLinksSpiegelkappeUnterteil,
        u.AussenspiegelLinksSpiegelglas,
        u.AussenspiegelLinksSpiegelblinker,
        u.AussenspiegelLinksSpiegelmotor,
        u.AussenspiegelLinksSpiegelfuss,
        u.AussenspiegelLinksBefestigungssatzSpiegeleinheit,
        u.AussenspiegelLinksSpiegelglasBeheizt,
        u.AussenspiegelLinksSpiegelglasMitAnzeigeAssistentsystemen,
        u.AussenspiegelLinksModulSpiegelstelleinheitInnenraum,
    ]],
    [o.AussenspiegelRechts, [
        u.AussenspiegelRechts,
        u.AussenspiegelRechtsSpiegelkappeLackiert,
        u.AussenspiegelRechtsSpiegelkappeUnterteil,
        u.AussenspiegelRechtsSpiegelglas,
        u.AussenspiegelRechtsSpiegelblinker,
        u.AussenspiegelLinksSpiegelmotor,
        u.AussenspiegelLinksSpiegelfuss,
        u.AussenspiegelLinksBefestigungssatzSpiegeleinheit,
        u.AussenspiegelLinksSpiegelglasBeheizt,
        u.AussenspiegelLinksSpiegelglasMitAnzeigeAssistentsystemen,
        u.AussenspiegelLinksModulSpiegelstelleinheitInnenraum,
    ]],
    [o.BremsanlageVorne, [
        u.BremsanlageVorne,
        u.BremsanlageVorneBremsscheibe,
        u.BremsanlageVorneBremsbelag,
        u.BremsanlageVorneBremsfluessigkeit,
    ]],
    [o.BremsanlageHinten, [
        u.BremsanlageHinten,
        u.BremsanlageHintenBremsscheibe,
        u.BremsanlageHintenBremsbelag,
        u.BremsanlageHintenBremsfluessigkeit,
    ]],
    [o.Dach, [
        u.Dach,
        u.DachAntenne,
        u.Dachhimmel,
        u.DachSchiebedach,
        u.DachVerdeck,
        u.DachFolierung,
        u.DachDachreling,
        u.DachPanoramadach,
        u.DachVerdeckscheibe,
    ]],
    [o.Dachhimmel, [
        u.Dachhimmel,
        u.DachhimmelSonnenblendeVorneRechts,
        u.DachhimmelSonnenblendeVorneLinks,
        u.DachhimmelBedienheitVorneMittig,
        u.DachhimmelHaltegriffHintenLinks,
        u.DachhimmelHaltegriffHintenRechts,
        u.DachhimmelHaltegriffVorneLinks,
        u.DachhimmelHaltegriffVorneRechts,
        u.DachhimmelAblagefachMittigVorne,
        u.DachhimmelAblagefachMittigHinten,
        u.DachhimmelSpiegeleinheitVorneLinks,
        u.DachhimmelSpiegeleinheitVorneRechts,
        u.DachhimmelSpiegeleinheitHintenLinks,
        u.DachhimmelSpiegeleinheitHintenRechts,
        u.DachhimmelBedienheitHintenMittig,
    ]],
    [o.Differential, [
        u.Differential,
    ]],
    [o.ElektrischesSystem, [
        u.ElektrischesSystem,
        u.ElektrischesSystemAirbagsystem,
        u.ElektrischesSystemEinparkassistent,
        u.ElektrischesSystemSchliessanlage,
        u.ElektrischesSystemHeizungKlima,
        u.ArmaturenbrettBedienelementHeizungKlima,
        u.ElektrischesSystemMultimediasystem,
    ]],
    [o.FussraumVorneLinks, [
        u.FussraumVorneLinks,
        u.FussraumVorneLinksFussmatte,
        u.FussraumVorneLinksArretierungFussmatte,
    ]],
    [o.FussraumVorneRechts, [
        u.FussraumVorneRechts,
        u.FussraumVorneRechtsFussmatte,
        u.FussraumVorneRechtsArretierungFussmatte,
    ]],
    [o.FussraumHintenLinks, [
        u.FussraumHintenLinks,
        u.FussraumHintenLinksFussmatte,
        u.FussraumHintenLinksArretierungFussmatte,
    ]],
    [o.FussraumHintenRechts, [
        u.FussraumHintenRechts,
        u.FussraumHintenRechtsFussmatte,
        u.FussraumHintenRechtsArretierungFussmatte,
    ]],
    [o.Getriebe, [
        u.Getriebe,
    ]],
    [o.Handschuhfach, [
        u.Handschuhfach,
        u.HandschuhfachDeaktivierungSeitenairbag,
        u.HandschuhfachScharniere,
        u.HandschuhfachBetaetigung,
        u.HandschuhfachOberhalb,
        u.HandschuhfachUnterhalb,
        u.HandschuhfachKuehlung,
        u.HandschuhfachHalterung,
    ]],
    [o.Heckklappe, [
        u.Heckklappe,
        u.HeckklappeHeckscheibe,
        u.HeckklappeHeckwischer,
        u.HeckklappeDichtung,
        u.HeckklappeBeschriftungSchriftzug,
        u.HeckklappeLichteinheitHintenLinks,
        u.HeckklappeLichteinheitHintenRechts,
        u.HeckklappeHeckscheibendichtung,
        u.HeckklappeDritteBremsleuchte,
        u.HeckklappeHeckklappenschloss,
        u.HeckklappeSchalterDrueckerHeckklappe,
        u.HeckklappeFanghaken,
        u.HeckklappeSpritzdueseHeckwischeranlage,
        u.HeckklappeHeckwischermotor,
        u.HeckklappeInnenverkleidung,
        u.HeckklappeVerkleidungUnterhalb,
        u.HeckklappeSpoileraufsatz,
        u.HeckklappeScharniere,
        u.HeckklappeDaempfung,
        u.HeckklappeKennzeichen,
        u.HeckklappeKennzeichenhalter,
        u.HeckklappeKennzeichenbeleuchtung,
        u.HeckklappeHeckrolloMechanisch,
        u.HeckklappeHeckrolloElektrisch,
        u.HeckklappeBetaetigung,
        u.HeckklappeArretierung,
        u.HeckklappeSensorik,
    ]],
    [o.HeckleuchteLinks, [
        u.HeckleuchteLinksRueckstrahler,
        u.HeckleuchteLinksBlinker,
        u.HeckleuchteLinksNebelleuchte,
    ]],
    [o.HeckleuchteRechts, [
        u.HeckleuchteRechstRueckstrahler,
        u.HeckleuchteRechtsBlinker,
        u.HeckleuchteRechtsNebelleuchte,
    ]],
    [o.Hinterachse, [
        u.Hinterachse,
        u.HinterachseStossdaempferLinks,
        u.HinterachseStossdaempferRechts,
        u.HinterachseStossfaenger,
    ]],
    [o.Innenraum, [
        u.Innenraum,
    ]],
    [o.Innenspiegel, [
        u.Innenspiegel,
        u.InnenspiegelSpiegelglas,
        u.InnenspiegelKamera,
        u.InnenspiegelRegensensor,
        u.InnenspiegelSpiegelverstellung,
        u.InnenspiegelSpiegelfuss,
        u.InnenspiegelDisplay,
        u.InnenspiegelTaxometer,
        u.InnenspiegelAbblendeinheit,
    ]],
    [o.Interieur, [
        u.Interieur,
        u.InterieurAblagenetz,
        u.InterieurGetraenkehalter,
        u.InterieurBrillenfach,
        u.InterieurASaeulenverkleidung,
        u.InterieurBSaeulenverkleidung,
        u.InterieurCSaeulenverkleidung,
        u.InterieurDSaeulenverkleidung,
        u.InterieurTeppichVorneLinks,
        u.InterieurTeppichVorneRechts,
        u.InterieurTeppichHintenLinks,
        u.InterieurTeppichHintenRechts,
        u.InterieurTeppichbefestigungVorneLinks,
        u.InterieurTeppichbefestigungVorneRechts,
        u.InterieurTeppichbefestigungHintenLinks,
        u.InterieurTeppichbefestigungHintenRechts,
        u.InterieurSonnenblendeRechts,
        u.InterieurSonnenblendeLinks,
        u.InterieurSonnenblendenspiegelAbdeckungLinks,
        u.InterieurSonnenblendeRechts,
        u.InterieurBeleuchtungKomfortSonnenblendeLinks,
        u.InterieurBeleuchtungKomfortSonnenblendeRechts,
        u.InterieurHaltegriffVorneLinks,
        u.InterieurHaltegriffVorneRechts,
        u.InterieurHaltegriffHintenLinks,
        u.InterieurHaltegriffHintenRechts,
    ]],
    [o.Karosserie, [
        u.Karosserie,
    ]],
    [o.Kofferraum, [
        u.Kofferraum,
        u.KofferraumAbdeckungLaderaum,
        u.KofferraumTeppich,
        u.KofferraumBoden,
        u.KofferraumLadekantenschutz,
        u.KofferraumAbdeckungLinks,
        u.KofferraumAbdeckungRechts,
        u.KofferraumNetz,
        u.KofferraumEinhaengeoesenLinks,
        u.KofferraumEinhaengeoesenRechts,
    ]],
    [o.Komfortsysteme, [
        u.Komfortsysteme,
    ]],
    [o.KotfluegelVorneLinks, [
        u.KotfluegelVorneLinks,
        u.KotfluegelVorneLinksBlinker,
        u.KotfluegelVorneLinksKotfluegelverbreiterung,
        u.KotfluegelVorneLinksRadhausschale,
        u.KotfluegelVorneLinksSchriftzug,
        u.KotfluegelVorneLinksFolierung,
    ]],
    [o.KotfluegelVorneRechts, [
        u.KotfluegelVorneRechts,
        u.KotfluegelVorneRechtsBlinker,
        u.KotfluegelVorneRechtsKotfluegelverbreiterung,
        u.KotfluegelVorneRechtsRadhausschale,
        u.KotfluegelVorneRechtsSchriftzug,
        u.KotfluegelVorneRechtsFolierung,
    ]],
    [o.Lenkanlage, [
        u.LenkanlageLenkrad,
        u.LenkanlageHebelanlage,
        u.LenkanlageLenkradverstelleinheit,
        u.LenkanlageLenksaule,
        u.LenkanlageLenkgetriebe,
        u.LenkanlageBedienfelder,
        u.LenkanlageAirbagAbdeckung,
        u.LenkanlageEmblem,
    ]],
    [o.Mittelkonsole, [
        u.Mittelkonsole,
        u.MittelkonsoleSchaltsack,
        u.MittelkonsoleAblagefach,
        u.MittelkonsoleArmlehne,
        u.MittelkonsoleAbdeckung,
        u.MittelkonsoleSchalthebel,
        u.MittelkonsoleSchalthebelleder,
        u.MittelkonsoleAschenbecher,
        u.MittelkonsoleSchalteinheitJoystick,
        u.MittelkonsoleZigarettenanzuender,
        u.MittelkonsoleSchalteinheitLueftungHinten,
        u.MittelkonsoleLuftfuehrungHinten,
    ]],
    [o.Motorhaube, [
        u.Motorhaube,
        u.MotorhaubeDaemmmatte,
        u.MotorhaubeSpritzduesen,
        u.MotorhaubeEmblem,
        u.MotorhaubeSchliessteil,
        u.MotorhaubeFanghaken,
        u.MotorhaubeScharnierLinks,
        u.MotorhaubeScharnierRechts,
        u.MotorhaubeScharniere,
        u.MotorhaubeSchlauchSpritzduesen,
        u.MotorhaubeBefestigungssatz,
        u.MotorhaubeBeklebungFolierung,
        u.MotorhaubeLufteinlasskanal,
        u.MotorhaubeZiergitter,
        u.MotorhaubeKuehlergrill,
    ]],
    [o.Motorraum, [
        u.Motorraum,
        u.MotorraumMotor,
        u.MotorraumMotorabdeckung,
        u.MotorraumOelmessstab,
        u.MotorraumKuehler,
        u.MotorraumLuftfilter,
        u.MotorraumKuehlwasserverschlussdeckel,
        u.MotorraumScheibenklarverschlussdeckel,
        u.MotorraumBremsfluessigkeitdeckel,
        u.MotorraumMessstabGetriebeoel,
        u.MotorraumFuellstandOel,
        u.MotorraumFuellstandSpritzwasseranlage,
        u.MotorraumFuellstandGetriebe,
        u.MotorraumFuellstandKuehlwasser,
        u.MotorraumFuellstandBremsfluessigkeit,
    ]],
    [o.Pedalanlage, [
        u.Pedalanlage,
        u.PedalanlageGaspedal,
        u.PedalanlageBremspedal,
        u.PedalanlageKupplungspedal,
        u.PedalanlageGummierungGaspedal,
        u.PedalanlageGummierungBremspedal,
        u.PedalanlageGummierungKupplungspedal,
        u.PedalanlageUmbauSonderfahrzeuge,
    ]],
    [o.RadVorneLinks, [
        u.RadVorneLinks,
        u.RadVorneLinksFelge,
        u.RadVorneLinksReifen,
        u.RadVorneLinksNabendeckel,
        u.RadVorneLinksVentildeckel,
        u.RadVorneLinksRDKS,
        u.RadVorneLinksFelgenschloss,
    ]],
    [o.RadVorneRechts, [
        u.RadVorneRechts,
        u.RadVorneRechtsFelge,
        u.RadVorneRechtsReifen,
        u.RadVorneRechtsNabendeckel,
        u.RadVorneRechtsNabendeckel,
        u.RadVorneRechtsVentildeckel,
        u.RadVorneRechtsRDKS,
        u.RadVorneRechtsFelgenschloss,
    ]],
    [o.RadHintenLinks, [
        u.RadHintenLinks,
        u.RadHintenLinksFelge,
        u.RadHintenLinksReifen,
        u.RadHintenLinksNabendeckel,
        u.RadHintenLinksVentildeckel,
        u.RadHintenLinksRDKS,
        u.RadHintenLinksFelgenschloss,
    ]],
    [o.RadHintenRechts, [
        u.RadHintenRechts,
        u.RadHintenRechtsFelge,
        u.RadHintenRechtsReifen,
        u.RadHintenRechtsNabendeckel,
        u.RadHintenRechtsVentildeckel,
        u.RadHintenRechtsRDKS,
        u.RadHintenRechtsFelgenschloss,
    ]],
    [o.ScheinwerferLinks, [
        u.ScheinwerferLinks,
        u.ScheinwerferLinksGlas,
        u.ScheinwerferLinksLeuchtmittel,
    ]],
    [o.ScheinwerferRechts, [
        u.ScheinwerferRechts,
        u.ScheinwerferRechtsGlas,
        u.ScheinwerferRechtsLeuchtmittel,
    ]],
    [o.SchwellerLinks, [
        u.SchwellerLinks,
        u.SchwellerLinksVerkleidung,
        u.SchwellerLinksLufteinlassverkleidung,
        u.SchwellerLinksDekor,
    ]],
    [o.SchwellerRechts, [
        u.SchwellerRechts,
        u.SchwellerRechtsVerkleidung,
        u.SchwellerRechtsLufteinlassverkleidung,
        u.SchwellerRechtsDekor,
    ]],
    [o.SeitenwandHintenLinks, [
        u.SeitenwandHintenLinks,
        u.SeitenwandHintenLinksSeitenscheibe,
        u.SeitenwandHintenLinksRadhausverbreiterung,
        u.SeitenwandHintenLinksTankdeckel,
        u.SeitenwandHintenLinksRadhausschale,
        u.SeitenwandHintenLinksFolierung,
        u.SeitenwandHintenLinksLichteinheit,
        u.SeitenwandHintenLinksTankverschluss,
        u.SeitenwandHintenLinksTankverschlusssicherung,
    ]],
    [o.SeitenwandHintenRechts, [
        u.SeitenwandHintenRechts,
        u.SeitenwandHintenRechtsSeitenscheibe,
        u.SeitenwandHintenRechtsRadhausverbreiterung,
        u.SeitenwandHintenRechtsTankdeckel,
        u.SeitenwandHintenRechtsRadhausschale,
        u.SeitenwandHintenRechtsFolierung,
        u.SeitenwandHintenRechtsLichteinheit,
        u.SeitenwandHintenRechtsTankverschluss,
        u.SeitenwandHintenRechtsTankverschlusssicherung,
    ]],
    [o.SitzVorneLinks, [
        u.SitzVorneLinks,
        u.SitzVorneLinksSitzbezugUnterhalb,
        u.SitzVorneLinksSitzbezugOberhalb,
        u.SitzVorneLinksSicherheitsgurt,
        u.SitzVorneLinksSitzheizung,
        u.SitzVorneLinksSitzlueftung,
        u.SitzVorneLinksLordose,
        u.SitzVorneLinksSitzverstellung,
        u.SitzVorneLinksKopfstuetze,
        u.SitzVorneLinksAblagefach,
        u.SitzVorneLinksSeitlicheAbdeckung,
        u.SitzVorneLinksAblagenetz,
    ]],
    [o.SitzVorneRechts, [
        u.SitzVorneRechts,
        u.SitzVorneRechtsSitzbezugUnterhalb,
        u.SitzVorneRechtsSitzbezugOberhalb,
        u.SitzVorneLinksSicherheitsgurt,
        u.SitzVorneRechtsSitzheizung,
        u.SitzVorneRechtsSitzlueftung,
        u.SitzVorneRechtsLordose,
        u.SitzVorneRechtsSitzverstellung,
        u.SitzVorneRechtsKopfstuetze,
        u.SitzVorneRechtsAblagefach,
        u.SitzVorneRechtsSeitlicheAbdeckung,
        u.SitzVorneRechtsAblagenetz,
    ]],
    [o.SitzHintenLinks, [
        u.SitzHintenLinks,
        u.SitzHintenLinksSitzbezugUnterhalb,
        u.SitzHintenLinksSitzbezugOberhalb,
        u.SitzHintenLinksSicherheitsgurt,
        u.SitzHintenLinksSitzheizung,
        u.SitzHintenLinksSitzlueftung,
        u.SitzHintenLinksKopfstuetze,
        u.SitzHintenLinksGurtschnalle,
        u.SitzHintenLinksGurtschloss,
        u.SitzHintenLinksGurtverstelleinheit,
    ]],
    [o.SitzHintenMitte, [
        u.SitzHintenMitte,
        u.SitzHintenMitteSitzbezugUnterhalb,
        u.SitzHintenMitteSitzbezugOberhalb,
        u.SitzHintenMitteSicherheitsgurt,
        u.SitzHintenMitteSitzheizung,
        u.SitzHintenMitteSitzlueftung,
        u.SitzHintenMitteKopfstuetze,
        u.SitzHintenMitteGurtschnalle,
        u.SitzHintenMitteGurtschloss,
        u.SitzHintenMitteGurtverstelleinheit,
    ]],
    [o.SitzHintenRechts, [
        u.SitzHintenRechts,
        u.SitzHintenRechtsSitzbezugUnterhalb,
        u.SitzHintenRechtsSitzbezugOberhalb,
        u.SitzHintenRechtsSicherheitsgurt,
        u.SitzHintenRechtsSitzheizung,
        u.SitzHintenRechtsSitzlueftung,
        u.SitzHintenRechtsKopfstuetze,
        u.SitzHintenRechtsGurtschnalle,
        u.SitzHintenRechtsGurtschloss,
        u.SitzHintenRechtsGurtverstelleinheit,
    ]],
    [o.Sicherheitssysteme, [
        u.Sicherheitssysteme,
    ]],
    [o.StossfaengerVorne, [
        u.StossfaengerVorne,
        u.StossfaengerVorneSpoilerlippe,
        u.StossfaengerVorneNebelscheinwerferRechts,
        u.StossfaengerVorneNebelscheinwerferLinks,
        u.StossfaengerVorneVerkleidung,
        u.StossfaengerVorneKuehlergrill,
        u.StossfaengerVorneEmblem,
        u.StossfaengerVorneLuftgitterLinks,
        u.StossfaengerVorneLuftgitterRechts,
        u.StossfaengerVorneFolierung,
        u.StossfaengerVorneKennzeichen,
        u.StossfaengerVorneKennzeichenhalter,
        u.StossfaengerVorneEinparksensorUltraschallgeberLinks,
        u.StossfaengerVorneEinparksensorUltraschallgeberLinksInnen,
        u.StossfaengerVorneEinparksensorUltraschallgeberRechts,
        u.StossfaengerVorneEinparksensorUltraschallgeberRechtsInnen,
        u.StossfaengerVorneRadarsensorSpurhaltesystemLinks,
        u.StossfaengerVorneRadarsensorSpurhaltesystemRechts,
        u.StossfaengerVorneRadarsensorDistronicAbstandsmessung,
        u.StossfaengerVorneScheinwerferreinigungsanlageLinks,
        u.StossfaengerVorneKappeScheinwerferreinigungsanlageLinks,
        u.StossfaengerVorneScheinwerferreinigungsanlageRechts,
        u.StossfaengerVorneKappeScheinwerferreinigungsanlageRechts,
        u.StossfaengerVorneAbschleppoesendeckel,
        u.StossfaengerVorneFrontkamera,
    ]],
    [o.StossfaengerHinten, [
        u.StossfaengerHinten,
        u.StossfaengerHintenSpoilerlippe,
        u.StossfaengerVorneNebelscheinwerferRechts,
        u.StossfaengerVorneNebelscheinwerferLinks,
        u.StossfaengerHintenNebelleuchte,
        u.StossfaengerHintenStossfaengerverkleidung,
        u.StossfaengerHintenKennzeichenhalter,
        u.StossfaengerHintenKennzeichen,
        u.StossfaengerHintenFolierung,
        u.StossfaengerHintenAbschleppoesenblende,
        u.StossfaengerHintenKennzeichenbeleuchtungLinks,
        u.StossfaengerHintenKennzeichenbeleuchtungRechts,
        u.StossfaengerHintenLichteinheitLinks,
        u.StossfaengerHintenLichteinheitRechts,
        u.StossfaengerHintenRueckfahrkamera,
        u.StossfaengerHintenEinparksensorUltraschallgeberLinks,
        u.StossfaengerHintenEinparksensorUltraschallgeberLinksInnen,
        u.StossfaengerHintenEinparksensorUltraschallgeberRechts,
        u.StossfaengerHintenEinparksensorUltraschallgeberRechtsInnen,
        u.StossfaengerHintenStossRadarsensorSpurhaltesystemLinks,
        u.StossfaengerHintenStossRadarsensorSpurhaltesystemRechts,
    ]],
    [o.TuerVorneLinks, [
        u.TuerVorneLinks,
        u.TuerVorneLinksTuerblatt,
        u.TuerVorneLinksScheibe,
        u.TuerVorneLinksTuerschloss,
        u.TuerVorneLinksTuerrahmen,
        u.TuerVorneLinksZierleisteMittig,
        u.TuerVorneLinksScheibenfuehrungAussen,
        u.TuerVorneLinksScheibenfuehrungInnen,
        u.TuerVorneLinksRahmendichtungFensterfuehrung,
        u.TuerVorneLinksElektrischeFensterheber,
        u.TuerVorneLinksFensterheberkurbel,
        u.TuerVorneLinksFolierung,
        u.TuerVorneLinksScheibenheberMechanik,
        u.TuerVorneLinksFensterhebermotor,
        u.TuerVorneLinksModulFensterheberElektrisch,
        u.TuerVorneLinksDaemmmatte,
        u.TuerVorneLinksSchlosstraeger,
        u.TuerVorneLinksSchliesszylinder,
        u.TuerVorneLinksTuergriff,
        u.TuerVorneLinksBefestigungssatzTuer,
        u.TuerVorneLinksTuerdichtung,
        u.TuerVorneLinksInnenverkleidung,
        u.TuerVorneLinksInnengriff,
        u.TuerVorneLinksSchliessteilInnen,
        u.TuerVorneLinksRahmenSchliessteilInnen,
        u.TuerVorneLinksTuerscharnier,
        u.TuerVorneLinksTuerfangband,
        u.TuerVorneLinksZierleisteTuerInnen,
        u.TuerVorneLinksLautsprecherTuer,
        u.TuerVorneLinksModulElektrischeVerrieglungEntriegelung,
        u.TuerVorneLinksVerkleidungTuerUnterhalb,
        u.TuerVorneLinksBeschriftungFolierungTuer,
    ]],
    [o.TuerVorneRechts, [
        u.TuerVorneRechts,
        u.TuerVorneRechtsTuerblatt,
        u.TuerVorneRechtsScheibe,
        u.TuerVorneRechtsTuerschloss,
        u.TuerVorneRechtsTuerrahmen,
        u.TuerVorneRechtsZierleisteMittig,
        u.TuerVorneRechtsScheibenfuehrungAussen,
        u.TuerVorneRechtsScheibenfuehrungInnen,
        u.TuerVorneRechtsRahmendichtungFensterfuehrung,
        u.TuerVorneRechtsElektrischeFensterheber,
        u.TuerVorneRechtsFensterheberkurbel,
        u.TuerVorneRechtsFolierung,
        u.TuerVorneRechtsScheibenheberMechanik,
        u.TuerVorneRechtsFensterhebermotor,
        u.TuerVorneRechtsModulFensterheberElektrisch,
        u.TuerVorneRechtsDaemmmatte,
        u.TuerVorneRechtsSchlosstraeger,
        u.TuerVorneRechtsSchliesszylinder,
        u.TuerVorneRechtsTuergriff,
        u.TuerVorneRechtsBefestigungssatzTuer,
        u.TuerVorneRechtsTuerdichtung,
        u.TuerVorneRechtsInnenverkleidung,
        u.TuerVorneRechtsInnengriff,
        u.TuerVorneRechtsSchliessteilInnen,
        u.TuerVorneRechtsRahmenSchliessteilInnen,
        u.TuerVorneRechtsTuerscharnier,
        u.TuerVorneRechtsTuerfangband,
        u.TuerVorneRechtsZierleisteTuerInnen,
        u.TuerVorneRechtsLautsprecherTuer,
        u.TuerVorneRechtsModulElektrischeVerrieglungEntriegelung,
        u.TuerVorneRechtsVerkleidungTuerUnterhalb,
        u.TuerVorneRechtsBeschriftungFolierungTuer,
    ]],
    [o.TuerHintenLinks, [
        u.TuerHintenLinks,
        u.TuerHintenLinksTuerblatt,
        u.TuerHintenLinksScheibe,
        u.TuerHintenLinksTuerschloss,
        u.TuerHintenLinksTuerrahmen,
        u.TuerHintenLinksZierleisteMittig,
        u.TuerHintenLinksScheibenfuehrungAussen,
        u.TuerHintenLinksScheibenfuehrungAussen,
        u.TuerHintenLinksScheibenfuehrungInnen,
        u.TuerHintenLinksRahmendichtungFensterfuehrung,
        u.TuerHintenLinksFensterheberkurbel,
        u.TuerHintenLinksElektrischeFensterheber,
        u.TuerHintenLinksScheibenheberMechanik,
        u.TuerHintenLinksFensterhebermotor,
        u.TuerHintenLinksFolierung,
        u.TuerHintenLinksModulFensterheberElektrisch,
        u.TuerHintenLinksDaemmmatte,
        u.TuerHintenLinksSchlosstraeger,
        u.TuerHintenLinksSchliesszylinder,
        u.TuerHintenLinksTuergriff,
        u.TuerHintenLinksBefestigungssatzTuere,
        u.TuerHintenLinksTuerdichtung,
        u.TuerHintenLinksInnengriff,
        u.TuerHintenLinksSchliessteilInnen,
        u.TuerHintenLinksRahmenSchliessteilInnen,
        u.TuerHintenLinksTuerscharnier,
        u.TuerHintenLinksTuerfangband,
        u.TuerHintenLinksZierleisteTuerInnen,
        u.TuerHintenLinksLautsprecherTuer,
        u.TuerHintenLinksModulElektrischeVerrieglungEntriegelung,
        u.TuerHintenLinksVerkleidungTuerUnterhalb,
        u.TuerHintenLinksBeschriftungFolierungTuer,
    ]],
    [o.TuerHintenRechts, [
        u.TuerHintenRechts,
        u.TuerHintenRechtsTuerblatt,
        u.TuerHintenRechtsScheibe,
        u.TuerHintenRechtsTuerschloss,
        u.TuerHintenRechtsTuerrahmen,
        u.TuerHintenRechtsZierleisteMittig,
        u.TuerHintenRechtsScheibenfuehrungAussen,
        u.TuerHintenRechtsScheibenfuehrungAussen,
        u.TuerHintenRechtsScheibenfuehrungInnen,
        u.TuerHintenRechtsRahmendichtungFensterfuehrung,
        u.TuerHintenRechtsFensterheberkurbel,
        u.TuerHintenRechtsElektrischeFensterheber,
        u.TuerHintenRechtsScheibenheberMechanik,
        u.TuerHintenRechtsFensterhebermotor,
        u.TuerHintenRechtsFolierung,
        u.TuerHintenRechtsModulFensterheberElektrisch,
        u.TuerHintenRechtsDaemmmatte,
        u.TuerHintenRechtsSchlosstraeger,
        u.TuerHintenRechtsSchliesszylinder,
        u.TuerHintenRechtsTuergriff,
        u.TuerHintenRechtsBefestigungssatzTuere,
        u.TuerHintenRechtsTuerdichtung,
        u.TuerHintenRechtsInnengriff,
        u.TuerHintenRechtsSchliessteilInnen,
        u.TuerHintenRechtsRahmenSchliessteilInnen,
        u.TuerHintenRechtsTuerscharnier,
        u.TuerHintenRechtsTuerfangband,
        u.TuerHintenRechtsZierleisteTuerInnen,
        u.TuerHintenRechtsLautsprecherTuer,
        u.TuerHintenRechtsModulElektrischeVerrieglungEntriegelung,
        u.TuerHintenRechtsVerkleidungTuerUnterhalb,
        u.TuerHintenRechtsBeschriftungFolierungTuer,
    ]],
    [o.TuerverkleidungVorneLinks, [
        u.TuerverkleidungVorneLinks,
        u.TuerverkleidungVorneLinksHaltegriff,
        u.TuerverkleidungVorneLinksFensterheberschalter,
        u.TuerverkleidungVorneLinksTuergriff,
        u.TuerverkleidungVorneLinksOberhalb,
        u.TuerverkleidungVorneLinksUnterhalb,
        u.TuerverkleidungVorneLinksSpiegelverstelleinheit,
        u.TuerverkleidungVorneLinksZierleiste,
        u.TuerverkleidungVorneLinksAblagefach,
        u.TuerverkleidungVorneLinksSitzverstellungseinheit,
        u.TuerverkleidungVorneLinksLautsprecher,
        u.TuerverkleidungVorneLinksLautsprecherabdeckung,
        u.TuerverkleidungVorneLinksTuerverriegelungsschalter,
        u.TuerverkleidungVorneLinksTuerbeleuchtung,
    ]],
    [o.TuerverkleidungVorneRechts, [
        u.TuerverkleidungVorneRechts,
        u.TuerverkleidungVorneRechtsHaltegriff,
        u.TuerverkleidungVorneRechtsFensterheberschalter,
        u.TuerverkleidungVorneRechtsTuergriff,
        u.TuerverkleidungVorneRechtsOberhalb,
        u.TuerverkleidungVorneRechtsUnterhalb,
        u.TuerverkleidungVorneRechtsSpiegelverstelleinheit,
        u.TuerverkleidungVorneRechtsZierleiste,
        u.TuerverkleidungVorneRechtsAblagefach,
        u.TuerverkleidungVorneRechtsSitzverstellungseinheit,
        u.TuerverkleidungVorneRechtsLautsprecher,
        u.TuerverkleidungVorneRechtsLautsprecherabdeckung,
        u.TuerverkleidungVorneRechtsTuerverriegelungsschalter,
        u.TuerverkleidungVorneRechtsTuerbeleuchtung,
    ]],
    [o.TuerverkleidungHintenLinks, [
        u.TuerverkleidungHintenLinks,
        u.TuerverkleidungHintenLinksHaltegriff,
        u.TuerverkleidungHintenLinksFensterheberschalter,
        u.TuerverkleidungHintenLinksTuergriff,
        u.TuerverkleidungHintenLinksOberhalb,
        u.TuerverkleidungHintenLinksUnterhalb,
        u.TuerverkleidungHintenLinksZierleiste,
        u.TuerverkleidungHintenLinksAblagefach,
        u.TuerverkleidungHintenLinksLautsprecher,
        u.TuerverkleidungHintenLinksLautsprecherabdeckung,
        u.TuerverkleidungHintenLinksTuerbeleuchtung,
    ]],
    [o.TuerverkleidungHintenRechts, [
        u.TuerverkleidungHintenRechts,
        u.TuerverkleidungHintenRechtsHaltegriff,
        u.TuerverkleidungHintenRechtsFensterheberschalter,
        u.TuerverkleidungHintenRechtsTuergriff,
        u.TuerverkleidungHintenRechtsOberhalb,
        u.TuerverkleidungHintenRechtsUnterhalb,
        u.TuerverkleidungHintenRechtsZierleiste,
        u.TuerverkleidungHintenRechtsAblagefach,
        u.TuerverkleidungHintenRechtsLautsprecher,
        u.TuerverkleidungHintenRechtsLautsprecherabdeckung,
        u.TuerverkleidungHintenRechtsTuerbeleuchtung,
    ]],
    [o.Unterboden, [
        u.Unterboden,
        u.Unterbodenschutz,
        u.UnterbodenVerkleidungGetriebe,
        u.UnterbodenverkleidungVorne,
        u.UnterbodenverkleidungMittig,
        u.UnterbodenverkleidungHinten,
        u.UnterbodenVerkleidungSchweller,
    ]],
    [o.Vorderachse, [
        u.Vorderachse,
        u.VorderachseStossdaempferLinks,
        u.VorderachseStossdaempferRechts,
        u.VorderachseStossfaenger,
    ]],
    [o.Windschutzscheibe, [
        u.Windschutzscheibe,
        u.WindschutzscheibeWischeranlage,
        u.WindschutzscheibeScheibenwischerLinks,
        u.WindschutzscheibeScheibenwischerRechts,
        u.WindschutzscheibeDichtung,
        u.WindschutzscheibeRegensensor,
        u.WindschutzscheibeKamera,
    ]],
    [o.Zubehoer, [
        u.Zubehoer,
    ]],
]);
