import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { EnumValues } from '@shared/helper/values';

@Component({
  selector: 'app-form-table-column',
  template: `
    <ng-template #content>
        <ng-content></ng-content>
    </ng-template>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFormColumnComponent {
  @Input()
  public name: string;

  @Input()
  public displayName: string;

  @Input()
  public footerDisplayName: string;

  @Input()
  public type: string;

  @Input()
  public values: EnumValues;

  @Input()
  public valuePrefix = '';

  @Input()
  public suffix = '';

  @Input()
  public fullWidth = false;

  @ViewChild('content', { static: true })
  public template: TemplateRef<any>;
}
