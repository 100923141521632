import Dexie from 'dexie';
import { File } from './file';

export class FileDatabase extends Dexie {
    public files: Dexie.Table<File, string>;

    constructor() {
        super('files');
        this.version(1).stores({
            files: '&id'
        });
    }
}
