import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Produkt } from '@data/domain/schema/type';
import { ProduktUebersichtResolveService } from '@modules/produkt/service/produkt-uebersicht-resolve.service';
import { Assert } from '@shared/helper/assert';

@Component({
  selector: 'app-produkt-sidepanel',
  templateUrl: './produkt-sidepanel.component.html',
  styleUrls: ['./produkt-sidepanel.component.scss']
})
export class ProduktSidepanelComponent implements OnInit {
  public produkte: Produkt[];
  public produktId: string;
  public selectedIndex: number;

  constructor(
    private readonly produktUebersichtResolveService: ProduktUebersichtResolveService,
    private readonly router: Router) {
    Assert.notNullOrUndefined(produktUebersichtResolveService, 'produktUebersichtResolveService');
  }

  public ngOnInit(): void {
    this.getProdukte();
  }

  public getProdukte(): void {
    this.produkte = this.produktUebersichtResolveService.get().sort((a, b) => new Date(b.erstelltAm).getTime() / 1000 - new Date(a.erstelltAm).getTime() / 1000);

    const url = this.router.url;
    this.produktId = url.substring(url.indexOf('detail/')).split('/')[1];
    this.setSelectedIndex();
  }

  public onChange($event): void {
    const index = Number($event);
    const produkt = this.produkte[this.selectedIndex + index];
    Assert.notNullOrUndefined(produkt, 'produkt');
    this.router.navigateByUrl('produkt/detail/' + produkt.id, { skipLocationChange: true }).then(() => {
      this.router.navigate(['produkt', 'detail', produkt.id, 'auftrag']);
    });
  }

  private setSelectedIndex(): void {
    if (this.produkte) {
      this.selectedIndex = this.produkte.findIndex(produkt => produkt.auftrag.id === this.produktId);
    } else {
      this.selectedIndex = 0;
    }
  }

}
