import { Injectable } from '@angular/core';
import { RadBauart } from '@data/domain/schema/enum';
import { AchsenRad } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { ThousandsSeperatorFormatterService } from '@shared/service/form-controls/thousands-seperator-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailNfzRaederRadsatzAchseSeiteRadFormViewFactory extends FormViewModelBaseFactory<AchsenRad> {
    constructor(private readonly thousandsSeperatorFormatter: ThousandsSeperatorFormatterService) {
        super();
    }

    protected createField(model: AchsenRad, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields.AchsenRaeder.fields;
        switch (name) {
            case fields.Position.name:
                return new ViewFormControl(model.position);
            case fields.Hersteller.name:
                return new ViewFormControl(model.hersteller, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase
                });
            case fields.Typ.name:
                return new ViewFormControl(model.typ);
            case fields.Felge.name:
                return new ViewFormControl(model.felge);
            case fields.Reifenbreite.name:
                return new ViewFormControl(model.reifenbreite, {
                    formatter: this.thousandsSeperatorFormatter
                });
            case fields.Querschnitt.name:
                return new ViewFormControl(model.querschnitt, {
                    formatter: this.thousandsSeperatorFormatter
                });
            case fields.Bauart.name:
                return new ViewFormControl(
                    (model.bauart === undefined || model.bauart === null) ? RadBauart.R : model.bauart
                    );
            case fields.Felgengroesse.name:
                return new ViewFormControl(model.felgengroesse, {
                    formatter: this.thousandsSeperatorFormatter
                });
            case fields.Tragfaehigkeit.name:
                return new ViewFormControl(model.tragfaehigkeit, {
                    formatter: this.thousandsSeperatorFormatter
                });
            case fields.Geschwindigkeitsindex.name:
                return new ViewFormControl(model.geschwindigkeitsindex);
            case fields.Zustand.name:
                return new ViewFormControl(model.zustand);
            case fields.Profiltiefe.name:
                return new ViewFormControl(model.profiltiefe, {
                    formatter: this.thousandsSeperatorFormatter
                });
            case fields.ReifenDruck.name:
                return new ViewFormControl(model.reifenDruck);
            case fields.Felgenhersteller.name:
                return new ViewFormControl(model.felgenhersteller, {
                    formatter: ViewFormControlFormatters.firstLetterToUppercase
                });
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
