<mat-accordion [multi]="multi">
    <mat-expansion-panel *ngFor="let panel of (panels$ | async);index as index;trackBy:trackByInstance"
        [expanded]="(active$ | async) === index || (panel.expanded$ | async)" (opened)="onPanelOpened(panel, index)">
        <mat-expansion-panel-header [collapsedHeight]="panel.dynamicHeight ? undefined : '100%'"
            [expandedHeight]="panel.dynamicHeight ? undefined : '100%'">
            <ng-container *ngTemplateOutlet="panel.header"></ng-container>
          <mat-icon class="icon-chevron-down"></mat-icon>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="panel.content"></ng-container>
        <mat-action-row *ngIf="panel.actionRow">
            <ng-container *ngTemplateOutlet="panel.row"></ng-container>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>
