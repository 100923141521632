<div class="help-menu-wrapper" *ngIf="context$ | async as context">
  <button mat-menu-item (click)="goToUrl('https://www.gtue.de/partnerbereich/page/aktueller-updatestand/102508')">
    <span>{{'footer.whatsNew' | translate}}</span>
  </button>
  <ng-container *ngIf="'footer.support.subject' | translate:context as subject">
    <ng-container *ngIf="'footer.support.body' | translate:context as body">
      <button mat-menu-item (click)="sendMailTo('bewertung-support@gtue.de',
                              subject,
                              body)">
        <span>{{'footer.support' | translate}}</span>
      </button>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="'footer.feedback.subject' | translate:context as subject">
    <ng-container *ngIf="'footer.feedback.body' | translate:context as body">
      <button mat-menu-item (click)="sendMailTo('bewertung-feedback@gtue.de',
                              subject,
                              body)">
        <span>{{'footer.feedback' | translate}}</span>
      </button>
    </ng-container>
  </ng-container>
    <button mat-menu-item (click)="onPolicyClick()">
      <span>{{'footer.privacy' | translate}}</span>
    </button>
  <mat-divider></mat-divider>
  <div mat-menu-item [disabled]="true">{{'footer.version' | translate}}: {{version}} {{environment}}</div>
</div>
