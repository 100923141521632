import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';
import { ExternalDataServiceResponseBase } from '../schema/external-data-service';

export interface RestwertprognosDto {
    dossierId: string;
    bezugsdatum: string;
    restwertprognoseNetto: number;
    restwertprognoseBrutto: number;
    dokument: string;
    // fahrzeug: Fahrzeug; ToDO: Was passiert wenn Anwender die FZ-Daten in DAT ändert?
}

export interface RestwertprognoseRequest {
    produktId: string;
    dossierId: string;
    dossierBezeichnung: string;
    vertragsnummer: string;
    vertragsbeginn: string;
    vertragsende: string;
    vereinbarteKilometer: number;
    tatsaechlicheKilometer: number;
    differenzKilometer: number;
    kennzeichen: string;
    identnummer: string;
    laufleistung: number;
    erstzulassung: string;
    specialEquipmentIds: string[];
    identifier: string;
    container: string;
    constructionTime: number;
}

export interface RestwertprognoseGetResponse extends ExternalDataServiceResponseBase {
    restwertprognose?: RestwertprognosDto;
    message?: string;
}

@Injectable({
    providedIn: 'root'
})
export class RestwertprognoseService {
    private readonly restApiUrl: string;

    constructor(
        private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/dat/restwertprognose`;
    }

    public get(dossierId: string, produktId: string): Observable<RestwertprognosDto> {
        Assert.notNullOrUndefined(dossierId, 'dossierId');
        Assert.notNullOrUndefined(produktId, 'produktId');
        const params = {
            params: {
                dossierId,
                produktId
            }
        };
        return this.httpClient.get<RestwertprognosDto>(this.restApiUrl, params);
    }

    // Es reicht wenn die POST Methode (CreateDossier) die DossierId liefert.
    // Danach wird die GET (getDossier) aufgerufen und liefert das Response Objekt (mit dem Fahrzeug)
    public post(request: RestwertprognoseRequest): Observable<string> {
        Assert.notNullOrUndefined(request, 'request');
        const dossierId = this.httpClient.post<string>(this.restApiUrl, request);
        return dossierId;
    }
}

    // erst einmal ignorieren
    // public put(request: RestwertprognoseRequest): Observable<RestwertprognoseServiceResponse> {
    //     Assert.notNullOrUndefined(request, 'request');
    //     return this.httpClient.put<RestwertprognoseServiceResponse>(this.restApiUrl, request);
    // }
