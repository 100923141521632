<ng-container *ngFor="let field of fields;trackBy:trackByField">
  <ng-container [ngSwitch]="field.name || field.arrayName">
        <!-- Bezeichnung -->
        <ng-container *ngSwitchCase="'bezeichnungen'">
            <app-textbausteine [form]="form" [name]="field.arrayName" [displayName]="'Bezeichnung'"
                               [feature]="'Uebersicht'" [produktArt]="this.produktArt" [multiple]="false">
            </app-textbausteine>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngFor="let field of fields;trackBy:trackByField">
  <ng-container [ngSwitch]="field.name">
    <!-- Bilder -->
    <ng-container *ngSwitchCase="'bilder'">
      <label>{{name + '.bilder' | translate}}</label>
      <ng-container *ngIf="form.get('bilder') as control">
        <ng-container *ngIf="((control.valueChanges | async) || control.value) as files">
          <app-file-gallery [fileIds]="files" [dialogTitle]="name + '.addfile'" [showCamera]="true"
                            [showFolder]="true" [resetable]="isFileResetable.bind(this)" (add)="onFileAdd(control, $event)"
                            (delete)="onFileDelete(control, $event)" (update)="onFileUpdate(control, $event)"
                            (reset)="onFileReset(control, $event)">
          </app-file-gallery>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
