import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Input, Output, QueryList } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { TrackBy } from '@shared/helper/track-by';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { BehaviorSubject } from 'rxjs';
import { TableFormColumnComponent } from '../table-form-column/table-form-column.component';

export interface TableTotalCalculator {
  calculate(rows: AbstractControl[]): number;
}

export interface TableRowMoveEvent {
  row: AbstractControl;
  offset: -1 | 1;
}

@Component({
  selector: 'app-form-table',
  templateUrl: './table-form.component.html',
  styleUrls: ['./table-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFormComponent implements AfterViewInit {
  public trackByName = TrackBy.trackByName;

  public columns$ = new BehaviorSubject<TableFormColumnComponent[]>([]);
  public names$ = new BehaviorSubject<string[]>([]);
  public dataSource = new MatTableDataSource<AbstractControl>();

  @ContentChildren(TableFormColumnComponent, { descendants: true })
  public columnQuery: QueryList<TableFormColumnComponent>;

  @Input()
  public set rows(rows: AbstractControl[]) {
    this.dataSource.data = rows;
  }

  @Input()
  public elevation = true;

  @Input()
  public footer = true;

  @Input()
  public header = true;

  @Input()
  public totalCalculators: {
    [name: string]: TableTotalCalculator;
  } = {};

  @Output()
  public rowOpen = new EventEmitter<AbstractControl>();

  @Output()
  public rowRemove = new EventEmitter<AbstractControl>();

  @Output()
  public rowMove = new EventEmitter<TableRowMoveEvent>();

  constructor(private readonly currencyFormatter: CurrencyFormatterService) {
    Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const columns = this.columnQuery.toArray();
      this.columns$.next(columns);

      const names = columns.map(column => column.name);
      if (this.rowRemove.observers.length > 0) {
        names.push('remove');
      }
      if (this.rowMove.observers.length > 0) {
        names.unshift('sortable');
      }
      this.names$.next(names);
    }, 1);
  }

  public onRowClick(row: AbstractControl): void {
    Assert.notNullOrUndefined(row, 'row');
    if (row.valid && !row.value.readonly) {
      this.rowOpen.emit(row);
    }
  }

  public onRowRemoveClick(row: AbstractControl): void {
    Assert.notNullOrUndefined(row, 'row');
    this.rowRemove.emit(row);
  }

  public onRowMove(event: MouseEvent, row: AbstractControl, offset: -1 | 1): void {
    Assert.notNullOrUndefined(event, 'event');
    Assert.notNullOrUndefined(row, 'row');
    Assert.notNullOrUndefined(offset, 'offset');
    event.stopPropagation();
    this.rowMove.next({ row, offset });
  }

  public getTotal(name: string): string {
    const total = this.totalCalculators[name]
      ? this.totalCalculators[name].calculate(this.dataSource.data)
      : this.dataSource.data.reduce((cv, cy: ViewFormGroup) => cv + cy.getRawValue()[name] || 0, 0);
    return this.currencyFormatter.format(total);
  }
}
