import { Injectable } from '@angular/core';
import { NfzRaeder } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { ProduktDetailTextbausteinFormViewFactory } from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailNfzRaederRadsatzFormViewFactory } from './produkt-detail-nfz-raeder-radsatz-form-view.factory';
@Injectable({
    providedIn: 'root'
})
export class ProduktDetailNfzRaederFormViewFactory extends FormViewModelBaseFactory<NfzRaeder> {

    constructor(private readonly nfzRadSatzFormViewFactory: ProduktDetailNfzRaederRadsatzFormViewFactory,
                private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory) {
        super();
        Assert.notNullOrUndefined(nfzRadSatzFormViewFactory, 'nfzRadSatzFormViewFactory');
    }

    protected createField(model: NfzRaeder, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields;
        if (name === fields.Notlaufeinrichtung.name) {
            return new ViewFormControl(model.notlaufeinrichtung);
        } else {
            throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: NfzRaeder, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields;
        switch (arrayName) {
            case fields.RadSaetze.name:
                const radsaetze = model.radSaetze || [];
                return new ViewFormArray(
                    radsaetze.map(
                        radsatz => this.nfzRadSatzFormViewFactory
                            .create(radsatz, arrayFields)
                    ));
            case fields.Bemerkungen.name:
                const bemerkungen = model.bemerkungen || [];
                return new ViewFormArray(
                    bemerkungen.map(
                        bemerkung => this.produktDetailTextbausteinFormViewFactory
                            .create(bemerkung, arrayFields)
                    ));
            default:
                throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
