<h4 *ngIf="title">{{title | translate}}</h4>
<div class="add-wrapper" *ngIf="add.observers.length > 0">
  <div class="add">
    <app-button *ngIf="showCamera" (action)="onAddClick(1)" [disabled]="disabled">
      <app-mat-icon icon="photo_camera"></app-mat-icon>
    </app-button>
    <app-button *ngIf="showFolder" (action)="onAddClick(2)" [disabled]="disabled">
      <app-mat-icon icon="folder"></app-mat-icon>
    </app-button>
  </div>
</div>
<div class="file-gallery-wrapper">
  <div class="actions-wrapper" *ngFor="let fileId of fileIds;trackBy:trackByKey.bind(this)">
    <app-file #file [fileId]="fileId" (save)="onFileSaved(fileId, $event)"
              [buttonLabel]="buttonLabel" (buttonClick)="buttonClick.emit($event)"></app-file>
    <div class="actions">
      <app-mini-fab *ngIf="reset.observers.length > 0 && resetable(fileId)" (click)="reset.emit(fileId)" color="basic">
        <app-mat-icon icon="undo" size="1.3rem"></app-mat-icon>
      </app-mini-fab>
      <app-mini-fab *ngIf="update.observers.length > 0" (click)="file.edit()" color="basic">
        <app-mat-icon icon="edit" size="1.3rem"></app-mat-icon>
      </app-mini-fab>
      <app-mini-fab *ngIf="delete.observers.length > 0" (click)="delete.emit(fileId)" color="basic">
        <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
      </app-mini-fab>
    </div>
  </div>
</div>
