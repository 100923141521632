import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BenutzerEinstellungen } from '@data/api-gateway/service/einstellungen.service';
import { EinstellungenBenutzerFormFactory } from '@modules/einstellungen/factory';
import { EinstellungenViewModelResolveService } from '@modules/einstellungen/service';
import { Assert } from '@shared/helper/assert';
import { Values } from '@shared/helper/values';

@Component({
  selector: 'app-einstellungen-benutzer-form',
  templateUrl: './einstellungen-benutzer-form.component.html',
  styleUrls: ['./einstellungen-benutzer-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EinstellungenBenutzerFormComponent implements OnInit {
  public readonly name = 'einstellungen';
  public form: UntypedFormGroup;
  public maxFileUploadSizeInKb = 500;

  public selectValues: Values = {
    keys: [true, false],
    values: {
      false: 'nein',
      true: 'ja',
    }
  };

  @Input()
  public saving: boolean;

  @Output()
  public save = new EventEmitter<BenutzerEinstellungen>();

  constructor(
    private readonly resolve: EinstellungenViewModelResolveService,
    private readonly factory: EinstellungenBenutzerFormFactory) {
    Assert.notNullOrUndefined(resolve, 'resolve');
    Assert.notNullOrUndefined(factory, 'factory');
  }

  public ngOnInit(): void {
    const { benutzer } = this.resolve.get();
    this.form = this.factory.create(benutzer);
  }

  public onSave(): void {
    this.save.next(this.form.value);
  }
}
