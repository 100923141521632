import { Injectable } from '@angular/core';
import { guid } from '@app/function/guid';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { Assert } from '@shared/helper/assert';
import { DataProxy } from 'apollo-cache';
import { from, Observable, of } from 'rxjs';
import { catchError, finalize, flatMap, map, shareReplay, timeout } from 'rxjs/operators';
import { ProduktFactory } from '../factory/produkt.factory';
import { createProdukt, CreateProduktData, GraphQLResponse } from '../graphql/mutations';
import {
  getProduktById,
  GetProduktByIdData,
  getProduktDuplikat,
  GetProduktDuplikatData,
  getProdukte,
  getProdukteByIdentnummer,
  GetProdukteByIdentnummerData,
  GetProdukteData,
  getProduktInfoById
} from '../graphql/queries';
import { ProduktArt } from '../schema/enum';
import { Produkt } from '../schema/type';

const GET_NETWORK_TIMEOUT = 1000 * 5;
const GET_ID_NETWORK_TIMEOUT = 1000 * 2;
const GET_ID_REFRESH_INTERVAL = 1000 * 60 * 5;

@Injectable({
  providedIn: 'root'
})
export class ProduktService {
  private readonly nextUpdate: {
    [ id: string ]: number;
  } = {};
  private readonly queryCache: {
    [ id: string ]: Observable<Produkt>;
  } = {};

  constructor(
    private readonly awsAppSyncClientProvider: AwsAppSyncClientProvider,
    private readonly produktFactory: ProduktFactory) {
    Assert.notNullOrUndefined(awsAppSyncClientProvider, 'awsAppSyncClientProvider');
    Assert.notNullOrUndefined(produktFactory, 'produktFactory');
  }

  public get(identnummer?: string): Observable<Produkt[]> {
    return this.query<GetProdukteData, Produkt[]>({
      query: getProdukte,
      variables: {identnummer}
    }, response => response.getProdukte, GET_NETWORK_TIMEOUT);
  }

  public getById(id: string, ignoreCache: boolean): Observable<Produkt> {
    Assert.notNullOrEmpty(id, 'id');
    if (ignoreCache) {
      return this.fetchQuery<GetProduktByIdData, Produkt>({
        query: getProduktById,
        variables: {id},
      }, response => response.getProduktById);
    } else {
      const now = Date.now();
      if (!this.nextUpdate[ id ] || now >= this.nextUpdate[ id ]) {
        if (!this.queryCache[ id ]) {
          this.queryCache[ id ] = this.query<GetProduktByIdData, Produkt>({
            query: getProduktById,
            variables: {id},
          }, response => response.getProduktById, GET_ID_NETWORK_TIMEOUT).pipe(
            finalize(() => {
              this.nextUpdate[ id ] = now + GET_ID_REFRESH_INTERVAL;
              this.queryCache[ id ] = undefined;
            }),
            shareReplay(1)
          );
        }
        return this.queryCache[ id ];
      }
      return this.readQuery<GetProduktByIdData, Produkt>({
        query: getProduktById,
        variables: {id},
      }, response => response.getProduktById);
    }
  }

  public getByIdentnummer(identnummer: string): Observable<Produkt[]> {
    Assert.notNullOrEmpty(identnummer, 'identnummer');
    return this.fetchQuery<GetProdukteByIdentnummerData, Produkt[]>({
      query: getProdukteByIdentnummer,
      variables: {identnummer},
    }, response => response.getProdukteByIdentnummer);
  }

  public getDuplikat(produktId: string, duplikatProduktId: string, produktArtDestination: number): Observable<Produkt> {
    Assert.notNullOrEmpty(produktId, 'produktId');
    Assert.notNullOrEmpty(duplikatProduktId, 'duplikatProduktId');
    return this.fetchQuery<GetProduktDuplikatData, Produkt>({
      query: getProduktDuplikat,
      variables: {
        produktId,
        duplikatProduktId,
        produktArtDestination
      },
    }, response => response.getProduktDuplikat);
  }

  public getInfoById(id: string): Observable<Produkt> {
    Assert.notNullOrEmpty(id, 'id');
    return this.fetchQuery<GetProduktByIdData, Produkt>({
      query: getProduktInfoById,
      variables: {id},
    }, response => response.getProduktById);
  }

  public create(art: ProduktArt): Observable<Produkt> {
    Assert.notNullOrUndefined(art, 'art');

    const id = guid();
    const produkt = this.produktFactory.create(id, art);

    const client = this.awsAppSyncClientProvider.provide();
    const mutatePromise = client.mutate<CreateProduktData>({
      mutation: createProdukt,
      variables: {
        ...produkt
      },
      optimisticResponse: {
        createProdukt: {
          ...produkt,
          __typename: 'Produkt',
        }
      },
      update: (store) => {
        this.updateGetCache(store, produkte => {
          produkte.push(produkt);
          return produkte;
        });
        this.updateGetByIdCache(store, id, () => produkt, false);
      }
    });
    return from(mutatePromise).pipe(
      map((response: GraphQLResponse<CreateProduktData>) => response.data.createProdukt)
    );
  }

  public updateGetByIdCache(store: DataProxy, produktId: string, update: (produkt: Produkt) => Produkt, read: boolean = true): void {
    let data: GetProduktByIdData = {
      getProduktById: null
    };
    if (read) {
      try {
        data = store.readQuery<GetProduktByIdData>({
          query: getProduktById,
          variables: {
            id: produktId
          }
        });
      } catch (error) {
        console.warn(`Could not readQuery 'getProduktById' from store.`);
      }
    }
    data.getProduktById = update(data.getProduktById);
    store.writeQuery({
      query: getProduktById,
      data,
      variables: {
        id: produktId
      }
    });
  }

  private updateGetCache(store: DataProxy, update: (produkte: Produkt[]) => Produkt[]): void {
    let data: GetProdukteData = {
      getProdukte: null
    };

    try {
      data = store.readQuery<GetProdukteData>({
        query: getProdukte
      });
    } catch (error) {
      console.warn(`Could not readQuery 'getProdukte' from store.`);
    }

    data.getProdukte = update(data.getProdukte || []);
    store.writeQuery({
      query: getProdukte,
      data
    });
  }

  private readQuery<TResponse, TResult>(options: any, get: (response: TResponse) => TResult): Observable<TResult> {
    const client = this.awsAppSyncClientProvider.provide();
    client.hydrated();
    const cache$ = from(client.query<TResponse>({
      ...options,
      fetchPolicy: 'cache-only'
    }));
    return cache$.pipe(
      map(response => get(response.data))
    );
  }

  private fetchQuery<TResponse, TResult>(options: any, get: (response: TResponse) => TResult): Observable<TResult> {
    const client = this.awsAppSyncClientProvider.provide();
    client.hydrated();

    const network$ = from(client.query<TResponse>({
      ...options,
      fetchPolicy: 'network-only'
    }));
    return network$.pipe(
      map(response => get(response.data))
    );
  }

  private query<TResponse, TResult>(options: any, get: (response: TResponse) => TResult, due: number): Observable<TResult> {
    const client = this.awsAppSyncClientProvider.provide();
    client.hydrated();

    const cache$ = from(client.query<TResponse>({
      ...options,
      fetchPolicy: 'cache-only'
    }));
    const network$ = from(client.query<TResponse>({
      ...options,
      fetchPolicy: 'network-only'
    }));

    return cache$.pipe(
      flatMap(cache => {
        if (cache && cache.data && get(cache.data)) {
          return network$.pipe(
            timeout(due),
            catchError(() => of(cache)));
        } else {
          return network$;
        }
      }),
      map(response => get(response.data))
    );
  }
}
