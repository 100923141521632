<div class="content-wrapper">
  <h3>{{'produkt.uebersicht' | translate}}</h3>

  <nav mat-tab-nav-bar [tabPanel]="tabPanel" color="accent">
    <a mat-tab-link class="link" [id]="'produkt-uebersicht-gtue-em'" [routerLink]="'gtue-em'" routerLinkActive
      #rla="routerLinkActive" [active]="rla.isActive">{{'produkt.uebersicht.gtue-em' | translate}}
    </a>
    <a mat-tab-link class="link" [id]="'produkt-uebersicht-gtue-modul'" [routerLink]="'gtue-modul'" routerLinkActive
       #rla2="routerLinkActive" [active]="rla2.isActive">{{'produkt.uebersicht.gtue-modul' | translate}}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>
