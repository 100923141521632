import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveVorschaden, SaveVorschadenData } from '../../graphql/mutations';
import { Produkt, Vorschaden, VorschadenInput } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktVorschadenService extends ProduktFeatureService<Vorschaden, VorschadenInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Vorschaden): VorschadenInput {
        const input: VorschadenInput = {
            id: produktId,
            positionen: (feature.positionen || []).map((position, index) => ({
                    ...position,
                    id: `${produktId}:${index}`
                }))
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveVorschaden;
    }

    protected getSaveVariables(input: VorschadenInput): any {
        return {
            vorschaden: input
        };
    }

    protected getSaveOptimisticResponse(input: VorschadenInput): any {
        return {
            saveVorschaden: {
                ...input,
                __typename: 'Vorschaden',
                positionen: (input.positionen || []).map(position => ({
                        ...position,
                        __typename: 'Position',
                    }))
            }
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveVorschadenData>): Vorschaden {
        return response.data.saveVorschaden;
    }

    protected update(produkt: Produkt, feature: Vorschaden): void {
        produkt.vorschaden.id = feature.id;
        produkt.vorschaden.positionen = feature.positionen;
    }
}
