import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CombiPlusOnlineDto } from '@data/api-gateway/service/bvws-combi-plus-online.service';
import { ProduktUebersichtCpoResolveService } from '@modules/produkt/service/produkt-uebersicht-cpo-resolve.service';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProduktUebersichtCpoResolve  {
    constructor(
        private readonly produktUebersichtCpoResolveService: ProduktUebersichtCpoResolveService,
        private readonly router: Router,
        private readonly location: Location,
        private readonly snackbar: SnackBarService) {
        Assert.notNullOrUndefined(produktUebersichtCpoResolveService, 'produktUebersichtResolveService');
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(location, 'location');
        Assert.notNullOrUndefined(snackbar, 'snackbar');
    }

    public resolve(): Observable<CombiPlusOnlineDto[]> {
        return this.produktUebersichtCpoResolveService.resolve().pipe(
            catchError((error) => {
                this.snackbar.warning('produkt.uebersicht.resolveError');
                this.router.navigateByUrl(this.location.path());
                console.error(error);
                return EMPTY;
            })
        );
    }
}
