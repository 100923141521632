import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { deleteVorschadenNotiz, saveVorschadenNotiz } from '@data/domain/graphql/mutations';
import { Notiz, Produkt } from '@data/domain/schema/type';
import { ProduktService } from '../../produkt.service';
import { ProduktFeatureNotizenService } from './produkt-feature-notizen.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktVorschadenNotizenService extends ProduktFeatureNotizenService {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    public getNotizen(produkt: Produkt): Notiz[] {
        if (!produkt.vorschaden.notizen) {
            produkt.vorschaden.notizen = [];
        }
        return produkt.vorschaden.notizen;
    }

    protected getSaveNotizMutation(): any {
        return saveVorschadenNotiz;
    }

    protected getSaveNotizMutationName(): string {
        return 'saveVorschadenNotiz';
    }

    protected getDeleteNotizMutation(): any {
        return deleteVorschadenNotiz;
    }

    protected getDeleteNotizMutationName(): any {
        return 'deleteVorschadenNotiz';
    }
}
