import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface EntwurfResponse {
    error?: string;
    path?: string;
}

@Injectable({
    providedIn: 'root'
})
export class EntwurfService {
    private readonly restApiUrl: string;

    constructor(
        private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/entwurf`;
    }

    public get(produktId: string, adresseId: string): Observable<EntwurfResponse> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrEmpty(adresseId, 'adresseId');
        return this.httpClient.get<EntwurfResponse>(this.restApiUrl, {
            params: {
                produktId,
                adresseId
            }
        });
    }
}
