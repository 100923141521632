export const UNTERLAGEN = [
    'Zulassungsbescheinigung Teil 1',
    'Zulassungsbescheinigung Teil 2',
    'Bordbuchmappe',
    'Serviceheft',
    'Kugelkopf für Anhängevorrichtung',
    'SD Karte',
    'Navi DVD',
    'Key-Cards',
    'Ladekabel',
    'Displayschlüssel',
    'Geldbeutelschlüssel',
    'Fahrzeugschein',
    'Fahrzeugbrief',
    'Fahrzeugschlüssel',
    'Schlüssel der Anhängevorrichtung',
];
