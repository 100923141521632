export enum ExternalDataServiceResponseCode {
    Success = 1,
    Error = 2,
    SuccessWithoutResult = 3,
    ErrorNoECodeFound = 4,
    ErrorNoValidConstructionTime = 5,
    SuccessWithIdentification = 6,
    ErrorCouldNotParseErrorXml = 7,
    ErrorCouldNotIdentifiedByKba = 8,
    ErrorVehicleDataSelection = 9,
    ErrorCreateDossier = 10,
    ErrorNoConstructionTime = 11,
}

export interface ExternalDataServiceResponseBase {
    responseCode: ExternalDataServiceResponseCode;
}
