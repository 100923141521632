import { Injectable } from '@angular/core';
import { EinstellungenService } from '@data/api-gateway/service/einstellungen.service';
import { Assert } from '@shared/helper/assert';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EinstellungenViewModel } from '../view/einstellungen-view.model';

@Injectable({
    providedIn: 'root'
})
export class EinstellungenViewModelResolveService {
    private model: EinstellungenViewModel;

    constructor(
        private readonly einstellungenService: EinstellungenService) {
        Assert.notNullOrUndefined(einstellungenService, 'einstellungenService');
    }

    public get(): EinstellungenViewModel {
        return this.model;
    }

    public resolve(): Observable<EinstellungenViewModel> {
        return forkJoin([
            this.einstellungenService.getBuero(),
            this.einstellungenService.getBenutzer()
        ]).pipe(
            map(([buero, benutzer]) => {
                const model: EinstellungenViewModel = {
                    buero, benutzer
                };
                return model;
            }),
            tap(model => this.model = model)
        );
    }
}
