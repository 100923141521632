import { Injectable } from '@angular/core';
import { FahrzeugServiceResponse } from '@data/api-gateway';
import { Assert } from '@shared/helper/assert';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { Observable, of, throwError } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { ProduktDetailFahrzeugDialogComponent, ProduktDetailFahrzeugDialogData } from '../component/produkt-detail-fahrzeug-dialog/produkt-detail-fahrzeug-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailFahrzeugDialogService {
    constructor(private readonly templateDialog: TemplateDialogService) {
        Assert.notNullOrUndefined(templateDialog, 'templateDialog');
    }

    public open(title: string, response: FahrzeugServiceResponse): Observable<FahrzeugServiceResponse> {
        Assert.notNullOrEmpty(title, 'title');
        Assert.notNullOrUndefined(response, 'response');

        const data: ProduktDetailFahrzeugDialogData = {
            response,
        };
        const buttons = [
            'fahrzeug.cancel'
        ];
        return this.templateDialog.openTemplate(title, buttons, ProduktDetailFahrzeugDialogComponent, data, true).pipe(
            flatMap(result => {
                if (result.name === 'fahrzeug.error') {
                    return throwError('fahrzeug.error');
                }
                return of(result.name === buttons[0] ? null : result.data.response);
            })
        );
    }
}
