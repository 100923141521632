<ng-container [ngSwitch]="loaded$ | async">
    <ng-container *ngSwitchCase="false">
        <button mat-button (click)="onReloadClick()">
            {{'audio.reload' | translate}}
        </button>
    </ng-container>
    <ng-container *ngSwitchCase="true">
        <ng-container *ngIf="audioSrc$ | async as audioSrc">
            <audio controls [src]="audioSrc" (load)="onAudioLoad(audioSrc)">
                {{'audio.unsupported' | translate}}
            </audio>
            <ng-container *ngIf="name$ | async as name">
                <em>{{name}}</em>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>