import { Injectable } from '@angular/core';
import { AchsenSeite } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';
import { ProduktDetailNfzRaederRadsatzAchseSeiteRadFormViewFactory } from './produkt-detail-nfz-raeder-radsatz-achse-seite-rad-form-view.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailNfzRaederRadsatzAchseSeiteFormViewFactory extends FormViewModelBaseFactory<AchsenSeite> {

    constructor(private readonly nfzRadFormViewFactory: ProduktDetailNfzRaederRadsatzAchseSeiteRadFormViewFactory) {
        super();
        Assert.notNullOrUndefined(nfzRadFormViewFactory, 'nfzRadFormViewFactory');
    }

    protected createField(model: AchsenSeite, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields;
        if (name === fields.Seite.name) {
            return new ViewFormControl(model.seite);
        } else {
            throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: AchsenSeite, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.NfzRaeder.fields.RadSaetze.fields.Achsen.fields.Seiten.fields;
        if (arrayName === fields.AchsenRaeder.name) {
            const achsenRaeder = model.achsenRaeder || [];
            return new ViewFormArray(
                achsenRaeder.map(
                    achsenRad => this.nfzRadFormViewFactory
                        .create(achsenRad, arrayFields)
                ));
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
