import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { Assert } from '@shared/helper/assert';

export interface ChatMessage {
  id: string;
  text: string;
  date: Date | string;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatComponent {

  @Input()
  public messages: ChatMessage[];

  @Output()
  public add = new EventEmitter<string>();

  @Output()
  public delete = new EventEmitter<ChatMessage>();

  public onClick(input: MatInput): void {
    Assert.notNullOrUndefined(input, 'input');

    const text = input.value || '';
    if (text.length > 0) {
      this.add.emit(text);
    }

    input.value = '';
  }
}
