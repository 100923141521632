import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface BueroEinstellungen {
    logo?: string;
    logoTop?: string;
    fusszeile?: string;
    datCustomerNumber?: string;
    datCustomerLogin?: string;
    datInterfacePartnerNumber?: string;
    datInterfacePartnerSignature?: string;
    datProductVariant?: string;
    datCustomerPassword?: string;
    watermarkEnable?: boolean;
    stempel?: string;
    unterschrift?: string;
    sachverstaendigerText?: string;
    benutzerEinstellungen?: BenutzerEinstellungen[];
}

export interface BenutzerEinstellungen extends BueroEinstellungen {
    benutzerId?: string;
    directFileSync?: boolean;
    wifiOnlyFileSync?: boolean;
    customTextBewertung?: string;
    customTextRuecknahmebewertung?: string;
    customTextZustandsbericht?: string;
    customDescriptionBewertung?: string;
    customDescriptionRuecknahmebewertung?: string;
    customDescriptionZustandsbericht?: string;
}

@Injectable({
    providedIn: 'root'
})
export class EinstellungenService {
    private readonly restApiUrlBuero: string;
    private readonly restApiUrlBenutzer: string;

    constructor(
        private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrlBuero = `${environment.aws.apiGateway.url}/einstellungen`;
        this.restApiUrlBenutzer = `${environment.aws.apiGateway.url}/einstellungen/benutzer`;

    }

    public getBuero(): Observable<BueroEinstellungen> {
        return this.httpClient.get<BueroEinstellungen>(
            this.restApiUrlBuero
        );
    }

    public putBuero(bueroRequest: BueroEinstellungen): Observable<BueroEinstellungen> {
        Assert.notNullOrUndefined(bueroRequest, 'bueroRequest');
        return this.httpClient.put<BueroEinstellungen>(
            this.restApiUrlBuero, bueroRequest
        );
    }

    public getBenutzer(): Observable<BenutzerEinstellungen> {
        return this.httpClient.get<BenutzerEinstellungen>(
            this.restApiUrlBenutzer
        );
    }

    public putBenutzer(benutzerRequest: BenutzerEinstellungen): Observable<BenutzerEinstellungen> {
        Assert.notNullOrUndefined(benutzerRequest, 'benutzerRequest');
        return this.httpClient.put<BenutzerEinstellungen>(
            this.restApiUrlBenutzer, benutzerRequest
        );
    }
}
