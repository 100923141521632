import { Injectable } from '@angular/core';
import { AuftragHinweis } from '@data/domain/schema/type';
import { PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailAuftragHinweisFormViewFactory extends FormViewModelBaseFactory<AuftragHinweis> {
    protected createField(model: AuftragHinweis, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Auftrag.fields.Hinweise.fields;
        switch (name) {
            case fields.Name.name:
                return new ViewFormControl(model.name);
            case fields.Vorhanden.name:
                return new ViewFormControl(model.vorhanden);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }
}
