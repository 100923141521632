import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Viewport, ViewportService} from '@shared/service/viewport.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentLayoutComponent implements OnInit {
  public viewport$: Observable<Viewport>;
  public viewport = Viewport;

  constructor(private readonly viewportService: ViewportService) {
  }

  public ngOnInit(): void {
    this.viewport$ = this.viewportService.observe();
  }
}
