<app-progress-spinner class="spinner" *ngIf="(loading$ |async)" [diameter]="150">
</app-progress-spinner>

<div class="uebersicht-header">
    <div class="search-field">
        <app-input [form]="form" [readonly]="false" name="searchTerm" [displayName]="'Suche'"
                   [placeholder]="name +'.search' | translate"
                   type="text" inputmode="text" #input>
        </app-input>
        <app-mini-fab [color]="'basic'" [disabled]="form.get('searchTerm').value.length < 3"
                      (action)="onClickProduktSearch(form.get('searchTerm').value)">
            <app-mat-icon icon="search"></app-mat-icon>
        </app-mini-fab>
        <app-mini-fab [color]="'basic'" (action)="onResetIdentAndSearch()">
            <app-mat-icon icon="delete"></app-mat-icon>
        </app-mini-fab>
    </div>

    <div class="filter-container">
        <app-input [form]="form" [readonly]="false" name="filterTerm" [displayName]="'Filter'"
                   [placeholder]="name +'.filter' | translate" (keydown)="onFilter(form.get('filterTerm').value)"
                   type="text" inputmode="text" #input>
        </app-input>
    </div>

    <app-button-group [form]="form" class="button-group" [name]="'filter'" [displayName]="'Status'"
                      [selections]="selections" [defaultValue$]="defaultValue$" (change)="onFilterStatus($event)">
    </app-button-group>
</div>

<ng-container>
    <app-card-list>
        <app-card-list-item *ngFor="let vorgang of (vorgaengeFiltered | async |
                         slice: lowValue : highValue); trackBy: trackById">
            <h4 title>{{vorgang.fahrzeug.identnummer}}</h4>
            <h4 subtitle>{{vorgang.fahrzeug.kennzeichen}}</h4>
            <div class="date" date>{{vorgang.acMetaInformation.xmlErstelldatum | date: 'short'}}</div>

            <app-bagde status *ngIf="vorgang.acMetaInformation.acVorgangStatus === status.InBearbeitung"
                       [icon]="'icon-pencil'" [color]="'orange'" [label]="'offen'"></app-bagde>
            <app-bagde status *ngIf="vorgang.acMetaInformation.acVorgangStatus === status.Abgeschlossen"
                       [icon]="'icon-pencil'" [color]="'grey'" [label]="'bearbeitet'">bearbeitet
            </app-bagde>
            <app-bagde status *ngIf="vorgang.acMetaInformation.acVorgangStatus === status.Versendet"
                       [icon]="'icon-check'" [color]="'green'" [label]="'erledigt'"></app-bagde>
            <mat-card-content class="card-content">
                <span
                    *ngIf="vorgang.fahrzeug.hersteller"><strong>{{vorgang.fahrzeug.hersteller}} </strong>{{vorgang.fahrzeug.modell}}</span>
                <br *ngIf="vorgang.fahrzeug.hersteller">
                <span *ngIf="vorgang.fahrzeug.erstzulassung || vorgang.fahrzeug.laufleistung">
                <ng-container *ngIf="vorgang.fahrzeug.erstzulassung">{{vorgang.fahrzeug.erstzulassung | date}}
                    |</ng-container>
                <strong *ngIf="vorgang.fahrzeug.laufleistung">{{vorgang.fahrzeug.laufleistung}} km</strong>
              </span>
            </mat-card-content>

            <mat-card-actions>
                <ng-container *ngIf="vorgang.acMetaInformation.acVorgangStatus === status.InBearbeitung">
                    <app-button [type]="ButtonType.Flat" [color]="'basic'" (action)="this.onOpenProdukt(vorgang)" >
                        Bearbeiten
                    </app-button>
                </ng-container>

                <ng-container *ngIf="vorgang.acMetaInformation.acVorgangStatus > status.InBearbeitung">
                    <app-button [type]="ButtonType.Flat" [color]="'basic'" (action)="this.onOpenProdukt(vorgang)">
                        Öffnen
                    </app-button>
                    <app-button [type]="ButtonType.Flat" [color]="'basic'" (action)="onDownloadClick(vorgang)">
                        PDF
                    </app-button>
                </ng-container>
            </mat-card-actions>
        </app-card-list-item>
    </app-card-list>
    <mat-paginator #paginator
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [length]="totalSize"
                   [pageIndex]="currentPage"
                   (page)="handlePage($event)"></mat-paginator>
</ng-container>

<!--
<app-table *ngIf="(loading$ | async) === false" [data]="produkte" [pageSizeOptions]="[25, 50, 100]" [filterPredicate]="filterPredicate" active="erstelltAm" direction="desc" (action)="onAction($event)" #table>
  <app-table-column name="art" [displayName]="name + '.art'">
    <ng-template let-element="element">
      <app-mat-icon [svgIcon]="'bewertung'" color="primary" *ngIf="element.art == 1"></app-mat-icon>
      <app-mat-icon [svgIcon]="'ruecknahmebewertung'" color="primary" *ngIf="element.art == 2"></app-mat-icon>
      <app-mat-icon [svgIcon]="'zustandsbericht'" color="primary" *ngIf="element.art == 3"></app-mat-icon>
      <h4 *ngIf="element.art === produktArt.VtiTooling">VTI</h4>
    </ng-template>
  </app-table-column>
  <app-table-column name="auftrag.nummer" [displayName]="name + '.nummer'" [sort]="true">
    <ng-template let-element="element">{{element.auftrag.nummer}}</ng-template>
  </app-table-column>
  <app-table-column name="fahrzeug.kennzeichen" [displayName]="name + '.kennzeichen'">
    <ng-template let-element="element">
      {{element.fahrzeug.kennzeichen ? element.fahrzeug.kennzeichen : element.fahrzeug.identnummer}}
    </ng-template>
  </app-table-column>
  <app-table-column name="erstelltAm" [displayName]="name + '.erstelltAm'" [sort]="true" start="desc">
    <ng-template let-element="element">{{element.erstelltAm | date}}</ng-template>
  </app-table-column>
  <app-table-column name="status" [displayName]="name + '.status'" [sort]="true">
    <ng-template let-element="element">{{produktStatus.values[element.status]}}</ng-template>
  </app-table-column>
  <app-table-column name="download" [displayName]="name + '.download'" [sort]="false">
    <ng-template let-element="element">
      <app-button [matBadge]="element.anzahlBilder" [matBadgeHidden]="element.anzahlBilder <= 0" [class.disabled]="element.anzahlBilder <= 0" (action)="onBilderDownload($event, element.id, element.anzahlBilder <= 0)">
        <ng-container>
          <app-mat-icon icon="cloud_download" color="primary"></app-mat-icon>
        </ng-container>
      </app-button>
    </ng-template>
  </app-table-column>
  <app-table-column name="duplicate" [displayName]="name + '.duplicate'" [sort]="false">
    <ng-template let-element="element">
      <app-button *ngIf="element.art !== produktArt.VtiTooling" (action)="onClickDuplicate($event, element)">
        <ng-container>
          <app-mat-icon icon="control_point_duplicate" color="primary"></app-mat-icon>
        </ng-container>
      </app-button>
    </ng-template>
  </app-table-column>
</app-table>
-->
<!-- Confirm dialog -->
<ng-template #dialogConfirmDuplicate let-data="data">

    <mat-label class="dialog-text">{{ name + '.duplicate.text' | translate}}</mat-label>
    <app-produkt-detail-nachbewertung-dialog #nachbewertungArtSelection [defaultSelection]="data.produkt.art">

    </app-produkt-detail-nachbewertung-dialog>
</ng-template>

