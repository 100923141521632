import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Values } from '@shared/helper/values';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent {
  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public displayName: string;

  @Input()
  public showTicks = true;

  @Input()
  public discrete = false;

  @Input()
  public min: number;

  @Input()
  public max: number;

  @Input()
  public values: Values;

  @Input()
  public valuePrefix = '';

  @Input()
  public step = 1;

  @Input()
  public tickInterval = 1;

  @Input()
  public thumbLabel = true;

  @Input()
  public rangeLabel = true;
}
