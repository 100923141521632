import { Injectable } from '@angular/core';
import { Umfang } from '@data/domain/schema/type';
import { FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import {
    ProduktDetailTextbausteinFormViewFactory
} from '@modules/produkt/factory/produkt-detail-textbaustein-form-view.factory';
import { Assert } from '@shared/helper/assert';
import { ViewFormArray } from '@shared/helper/form-controls/view-form-array';
import { AbstractViewFormControl, ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { CurrencyFormatterService } from '@shared/service/form-controls/currency-formatter.service';
import { FormViewModelBaseFactory } from '../form-view-base.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailFeststellungenUmfangFormViewFactory extends FormViewModelBaseFactory<Umfang> {

    constructor(private readonly currencyFormatter: CurrencyFormatterService,
                private readonly produktDetailTextbausteinFormViewFactory: ProduktDetailTextbausteinFormViewFactory) {
        super();
        Assert.notNullOrUndefined(currencyFormatter, 'currencyFormatter');
    }

    protected createField(model: Umfang, name: string): AbstractViewFormControl {
        const fields = PRODUKT_CONFIG_FEATURES.Feststellungen.fields.Umfaenge.fields;
        switch (name) {
            case fields.Obergruppe.name:
                return new ViewFormControl(model.obergruppe);
            case fields.ObergruppeName.name:
                return new ViewFormControl(model.obergruppeName);
            default:
                throw new Error(`Could not create field for name: '${name}'.`);
        }
    }

    protected createArray(model: Umfang, arrayName: string, arrayFields: FeatureFields): ViewFormArray {
        const fields = PRODUKT_CONFIG_FEATURES.Feststellungen.fields.Umfaenge.fields;
        if (arrayName === fields.Beschreibung.name) {
            const beschreibung = model.beschreibung || [];
            return new ViewFormArray(
                beschreibung.map(
                    value => this.produktDetailTextbausteinFormViewFactory
                        .create(value, arrayFields)
                ));
        } else {
            throw new Error(`Could not create array for name: '${arrayName}'.`);
        }
    }
}
