import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { AbschlussDownloadService } from '@data/api-gateway';
import {
  AcProduktStatus, AcProdukt,
  AlphaControllerVorgangService
} from '@data/api-gateway/service/alphacontroller/ac-vorgang.service';
import { BilderDownloadService } from '@data/api-gateway/service/bilder-download.service';
import { ProduktArt, ProduktArtNachbewertung, ProduktStatus } from '@data/domain/schema/enum';
import { Adresse, Produkt } from '@data/domain/schema/type';
import { ProduktService } from '@data/domain/service/produkt.service';
import {
  ProduktDetailNachbewertungDialogComponent
} from '@modules/produkt/component/produkt-detail-nachbewertung-dialog/produkt-detail-nachbewertung-dialog.component';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { AcProduktUebersichtResolveService } from '@modules/produkt/service/ac-produkt-uebersicht-resolve.service';
import { ButtonType } from '@shared/component/button-indicator/button/button.component';
import { TableComponent } from '@shared/component/data-table';
import { StepperComponent } from '@shared/component/layout/stepper';
import { Assert } from '@shared/helper/assert';
import { ViewFormControl } from '@shared/helper/form-controls/view-form-control';
import { ViewFormControlFormatters } from '@shared/helper/form-controls/view-form-control-formatters';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { EnumValues } from '@shared/helper/values';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { TemplateDialogService } from '@shared/service/template-dialog.service';
import { BehaviorSubject } from 'rxjs';
import { first, take } from 'rxjs/operators';

@Component({
  selector: 'app-produkt-uebersicht-ac',
  templateUrl: './produkt-uebersicht-ac.component.html',
  styleUrls: ['./produkt-uebersicht-ac.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktUebersichtAcComponent implements OnInit {
  public trackById = TrackBy.trackByIndex;

  public produkte: Produkt[];
  public name = 'produktUebersicht';
  public produktStatus = new EnumValues(ProduktStatus);
  public loading$ = new BehaviorSubject(true);
  public searchTerm = '';
  public form: ViewFormGroup;
  public produktArt = ProduktArt;
  public produktArtNachbewertungValues = new EnumValues(ProduktArtNachbewertung);

  public vorgaenge: AcProdukt[] = [];
  public vorgaengeFiltered = new BehaviorSubject<AcProdukt[]>([]);
  public slideToggleFilterOffenName = 'slide-filter-offen';
  public filterName = 'filterTerm';


  public status = AcProduktStatus;
  public dataSource: any;
  public pageSizeOptions: number[] = [20, 40, 80];
  public pageSize = 20;
  public currentPage = 0;
  public totalSize = 0;
  public lowValue = 0;
  public highValue = 20;

  public ButtonType = ButtonType;
  public selections = ['alle', 'offen', 'bearbeitet', 'fertig'];
  public defaultValue$ = new BehaviorSubject('alle');

  @ViewChild('table')
  public table: TableComponent;

  @ViewChild('nachbewertungArtSelection')
  public selectedNachbewertungArt: ProduktDetailNachbewertungDialogComponent;

  @ViewChild('dialogConfirmDuplicate', {static: true})
  public dialogConfirmDuplicateTemplate: TemplateRef<any>;

  @ViewChild('paginator', {static: true})
  public paginator: MatPaginator;

  constructor(
    private readonly acProduktUebersichtResolveService: AcProduktUebersichtResolveService,
    private readonly router: Router,
    private readonly bilderDownloadService: BilderDownloadService,
    private readonly datePipe: DatePipe,
    private readonly templateDialogService: TemplateDialogService,
    private readonly produktService: ProduktService,
    private readonly abschlussDownloadService: AbschlussDownloadService,
    private readonly acVorgangService: AlphaControllerVorgangService,
    private readonly snackBarService: SnackBarService) {
    Assert.notNullOrUndefined(acProduktUebersichtResolveService, 'acProduktUebersichtResolveService');
    Assert.notNullOrUndefined(router, 'router');
    Assert.notNullOrUndefined(bilderDownloadService, 'bilderDownloadService');
    Assert.notNullOrUndefined(templateDialogService, 'templateDialogService');
    Assert.notNullOrUndefined(produktService, 'produktService');
    Assert.notNullOrUndefined(abschlussDownloadService, 'abschlussDownloadService');
    Assert.notNullOrUndefined(acVorgangService, 'acVorgangService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
  }

  public ngOnInit(): void {

    this.acVorgangService.getVorgaenge().pipe(first()).subscribe(data => {
      this.loading$.next(true);
      this.vorgaenge = data;
      this.vorgaengeFiltered.next(this.filter(this.vorgaenge, 'alle'));
      this.totalSize = this.vorgaengeFiltered.value.length;
      this.dataSource = this.vorgaengeFiltered.value;
      this.loading$.next(false);
    });

    this.form = new ViewFormGroup({
      searchTerm: new ViewFormControl(this.searchTerm, {
        formatter: ViewFormControlFormatters.toUppercase
      }),
      filter: new ViewFormControl('')
    });
    this.form.addControl(this.slideToggleFilterOffenName, new ViewFormControl(false));
    this.form.addControl(this.filterName, new ViewFormControl(''));
  }

  public onFilterStatus(matButtonToggleChange: MatButtonToggleChange): void {
    this.vorgaengeFiltered.next(this.filter(this.vorgaenge, matButtonToggleChange.value));
    this.totalSize = this.vorgaengeFiltered.value.length;
    this.updateCardList();
  }

  public onFilter(filterTerm: string): void {
    this.vorgaengeFiltered.next(this.searchVorgaenge(filterTerm));
    this.totalSize = this.vorgaengeFiltered.value.length;
    this.updateCardList();
  }

  public onOpenProdukt(produkt: Produkt): void {
    Assert.notNullOrUndefined(produkt, 'produkt');
    this.router.navigate(['produkt', 'detail', produkt.id]);
  }

  public filterPredicate(produkt: Produkt, filter: string): boolean {
    if (produkt.auftrag) {
      if (produkt.auftrag.nummer?.toLocaleLowerCase().includes(filter)) {
        return true;
      }
    }
    if (produkt.fahrzeug) {
      if (produkt.fahrzeug.kennzeichen?.toLocaleLowerCase().includes(filter)) {
        return true;
      }
      if (produkt.fahrzeug.identnummer?.toLocaleLowerCase().includes(filter)) {
        return true;
      }
    }
    return false;
  }

  public onBilderDownload(event: MouseEvent, produktId: string, disabled: boolean): void {
    Assert.notNullOrUndefined(produktId, 'produktId');
    event.stopPropagation();

    if (disabled) {
      return;
    }

    this.loading$.next(true);
    this.bilderDownloadService.get(produktId).pipe(take(1)).subscribe({
      next: url => {
        if (url) {
          window.open(url);
          this.loading$.next(false);
        }
      },
      error: error => {
        this.loading$.next(false);
        error.status === 404 ? this.snackBarService.info('produkt.uebersicht.downloadKeineBilder')
          : this.snackBarService.error('produkt.uebersicht.downloadBilderError');
      }
    });
  }

  public onClickProduktSearch(searchText: string): void {
    this.searchAcProdukt(searchText);
    // this.vorgaengeFiltered.next(this.filter(this.searchVorgaenge(searchText), false));
    this.updateCardList();
  }

  public onResetIdentAndSearch(): void {
    this.form.patchValue({searchTerm: ''});
    this.searchAcProdukt('');
    this.updateCardList();
  }

  public alphaNumericOnly(e: KeyboardEvent): boolean {
    return String(e.key).match(/[^a-zA-Z0-9]/g) === null;
  }

  public handlePage(event: any) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateCardList();
  }

  private updateCardList(): void {
    this.lowValue = this.currentPage * this.pageSize;
    this.highValue = this.lowValue + this.pageSize;
  }

  private filter(vorgaenge: AcProdukt[], filter: string): AcProdukt[] {
    let result = vorgaenge;
    result = result.sort(function (a, b) {
      // @ts-ignore
      return new Date(b.acMetaInformation.xmlErstelldatum) - new Date(a.acMetaInformation.xmlErstelldatum);
    });

    switch (filter) {
      case 'offen' : result = result.filter(vorgang => vorgang.acMetaInformation.acVorgangStatus === this.status.InBearbeitung);
        break;
      case 'bearbeitet': result = result.filter(vorgang => vorgang.acMetaInformation.acVorgangStatus === this.status.Abgeschlossen);
        break;
      case 'fertig': result = result.filter(vorgang => vorgang.acMetaInformation.acVorgangStatus === this.status.Versendet);
        break;
    }
    return result;
  }

  private searchVorgaenge(searchTerm: string = ''): AcProdukt[] {

    let foundVorgaenge = this.vorgaenge;
    foundVorgaenge = foundVorgaenge.filter(vorgang => {
      if (this.includes(vorgang.fahrzeug.identnummer, searchTerm)
        || this.includes(vorgang.fahrzeug.kennzeichen, searchTerm)
        || this.includes(vorgang.fahrzeug.modell, searchTerm)
        || this.includes(vorgang.fahrzeug.hersteller, searchTerm)
        || this.includesInAdressen(vorgang.adressen.adressen, searchTerm)
        || this.includes(this.datePipe.transform(vorgang.acMetaInformation.xmlErstelldatum), searchTerm)) {
        return true;
      }
      return false;
    });
    return foundVorgaenge;
  }

  private searchAcProdukt(searchTerm: string = ''): void {
    this.acProduktUebersichtResolveService.resolve(searchTerm).pipe(first()).subscribe(
      {
        next:
          result => {
            if (result.length < 1) {
              this.snackBarService.info('produkt.uebersicht.IdentSucheNothingFound');
              return;
            }
            this.vorgaengeFiltered.next(result);
            // this.table.refresh(this.produkte);
          },
        error: error => {
          this.snackBarService.info('produkt.uebersicht.Suche', error);
        }
      });
  }

  private includes(value: string, searchTerm: string): boolean {
    if (!value) {
      return false;
    }
    return value.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
  }

  private includesInAdressen(adressen: Adresse[], searchTerm: string): boolean {
    let found = false;
    if (!adressen) {
      return false;
    }
    adressen.forEach(adresse => {
      if (!adresse?.arten?.includes('Auftraggeber')) {
        return false;
      }
      if (this.includes(adresse.firma, searchTerm)
        || this.includes(adresse.name, searchTerm)
        || this.includes(adresse.vorname, searchTerm)) {
        found = true;
      } else {
        found = false;
      }
    });
    return found;
  }

  public selectIndex(stepper: StepperComponent, status: number): number {
    const index = status - 1;
    if (stepper) {
      stepper.setIndex(index);
    }
    return index;
  }



  public onDownloadClick(produkt: Produkt): void {
    this.abschlussDownloadService.get(produkt.id).pipe(take(1)
    ).subscribe(response => {
      if (response.url) {
        this.onDownloadResponse(response.url);
      }
    });
  }

  private onDownloadResponse(url: string): void {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
