import { Injectable } from '@angular/core';
import { Wartung } from '@data/domain/schema/type';
import { FeatureField, FeatureFields, PRODUKT_CONFIG_FEATURES } from '@modules/produkt/config/produkt-config';
import { Assert } from '@shared/helper/assert';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';
import { ProduktDetailWartungPositionFormViewFactory } from './produkt-detail-wartung-position-form-view.factory';

@Injectable({
    providedIn: 'root'
})
export class ProduktDetailWartungPositionenFormViewFactory {

    constructor(
        private readonly positionFormViewFactory: ProduktDetailWartungPositionFormViewFactory) {
        Assert.notNullOrUndefined(positionFormViewFactory, 'positionFormViewFactory');
    }

    public create(
        wartung: Wartung,
        fields: FeatureFields,
        positionenFields: FeatureFields): ViewFormGroup[] {
        const result = [];

        const configFields = PRODUKT_CONFIG_FEATURES.Wartung.fields;
        fields.forEach((field: FeatureField) => {
            const name = field.name;
            switch (name) {
                case configFields.NaechsterServiceFaelligKosten.name:
                case configFields.HauptAbgasUntersuchungFaelligKosten.name:
                case configFields.ZahnriemenFaelligKosten.name:
                    result.push(this.createPosition(`wartung.${name}.name`, wartung[field.name], true, positionenFields));
                    break;
                default:
                    break;
            }
        });

        return result;
    }

    private createPosition(bezeichnung: string, kosten: number, readonly: boolean, positionenFields: FeatureFields): ViewFormGroup {
        const control = this.positionFormViewFactory.create({
            bezeichnung,
            kosten
        }, positionenFields);
        control.value.readonly = readonly;
        return control;
    }
}
