import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveFahrzeug, SaveFahrzeugData } from '../../graphql/mutations';
import { Fahrzeug, FahrzeugInput, Produkt } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktFahrzeugService extends ProduktFeatureService<Fahrzeug, FahrzeugInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: Fahrzeug): FahrzeugInput {
        const reference = feature.fahrzeugExternalServiceReference || {};
        const input: FahrzeugInput = {
            ...feature,
            id: produktId,
            laufleistungen: (feature.laufleistungen || []).map((laufleistung, index) => ({
              ...laufleistung,
              id: `${produktId}:${index}`
            })),
            fahrzeugExternalServiceReference: {
                id: produktId,
                constructionTime: reference.constructionTime || null,
                container: reference.container || null,
                identifier: reference.identifier || null,
                source: reference.source || null,
                vxsData: reference.vxsData || null,
            },
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveFahrzeug;
    }

    protected getSaveVariables(input: FahrzeugInput): any {
        return {
            fahrzeug: input
        };
    }

    protected getSaveOptimisticResponse(input: FahrzeugInput): any {
        return {
            saveFahrzeug: {
                ...input,
                __typename: 'Fahrzeug',
                laufleistungen: (input.laufleistungen || []).map(laufleistung => ({
                    ...laufleistung,
                    __typename: 'Laufleistung',
                })),
                fahrzeugExternalServiceReference: {
                    ...input.fahrzeugExternalServiceReference,
                    __typename: 'FahrzeugExternalServicesReference',
                }
            }
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveFahrzeugData>): Fahrzeug {
        return response.data.saveFahrzeug;
    }

    protected update(produkt: Produkt, feature: Fahrzeug): void {
        produkt.fahrzeug = {
            ...produkt.fahrzeug,
            ...feature,
            laufleistungen: feature.laufleistungen,
            fahrzeugExternalServiceReference: {
                ...produkt.fahrzeug.fahrzeugExternalServiceReference,
                ...feature.fahrzeugExternalServiceReference
            }
        };
    }
}
