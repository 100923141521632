<div class="main-content">
  <div class="toggle">
    <app-toggle-group [value]="display$ | async" (valueChange)="onDisplayChange($event)">
        <app-toggle [value]="displayEnum.Umfang">
            {{name + '.umfang' | translate}}
        </app-toggle>
      <app-toggle [value]="displayEnum.Exterieur" [disabled]="manuellDisabled$ | async">
        {{name + '.exterieur' | translate}}
      </app-toggle>
      <app-toggle [value]="displayEnum.Interieur" [disabled]="manuellDisabled$ | async">
        {{name + '.interieur' | translate}}
      </app-toggle>
      <app-toggle [value]="displayEnum.Technik" [disabled]="manuellDisabled$ | async">
        {{name + '.technik' | translate}}
      </app-toggle>
      <app-toggle [value]="displayEnum.Manuell">
        {{name + '.manuell' | translate}}
      </app-toggle>
      <app-toggle [value]="displayEnum.Text">
        {{name + '.text' | translate}}
      </app-toggle>
      <app-toggle [value]="displayEnum.Daten" *ngIf="(viewport$ | async) === viewport.Desktop">
        {{name + '.daten' | translate}}
      </app-toggle>
    </app-toggle-group>

    <app-button *ngIf="(viewport$ | async) === viewport.Desktop" class='align-right' [type]="ButtonType.Flat" [color]="'primary'"
                [loading]="loading$ | async" [disabled]="!this.hasFahrzeugExternalServiceReference"
                (action)="onOpenKalkulation()"
                [tooltip]="!this.hasFahrzeugExternalServiceReference ? (name + '.kalkulation.deaktiviert' | translate) : ''">
      {{name + '.kalkulation' | translate}}
    </app-button>
  </div>

  <ng-container [ngSwitch]="display$ |async">
    <app-produkt-detail-schaden-scene *ngSwitchDefault [modelFileConfigs]="modelFileConfigs" [gruppe]="display$ |async"
                                      (obergruppeSelect)="onObergruppeSelect($event)" (modelLoad)="onModelLoad($event)">
    </app-produkt-detail-schaden-scene>

    <app-card *ngSwitchCase="displayEnum.Umfang">
      <app-produkt-detail-feststellungen-umfang
        [umfaenge]="umfaenge"
        (addClicked)="onAddUmfangClicked($event)"
        (deleteClicked)="onDeleteUmfangClicked($event)">
      </app-produkt-detail-feststellungen-umfang>
    </app-card>
    <app-card *ngSwitchCase="displayEnum.Manuell">
      <app-action-list [values]="obergruppe" (action)="onObergruppeAction($event)" (submit)="onObergruppeSelect($event)"
                       [searchable]="true">
      </app-action-list>
    </app-card>
    <app-card *ngSwitchCase="displayEnum.Text">
      <app-textbausteine #beschreibungen [form]="form" [name]="'beschreibungen'" [displayName]="'Beschreibungen'"
                         [feature]="name" [produktArt]="this.produkt.art">
      </app-textbausteine>
    </app-card>
    <app-card *ngSwitchCase="displayEnum.Daten">
      <ng-container *ngTemplateOutlet="kalkulationTemplate"></ng-container>
    </app-card>
    <div id="iframeContainer"></div>
  </ng-container>
</div>

<!--side panel-->
<app-side-panel class="side-panel" *ngIf="(viewport$ | async) === viewport.Desktop">
  <h4>{{(name + '.feststellungen' | translate) + '&nbsp;(' + positionen.controls.length + ')&nbsp;'}}</h4>
  <ng-container *ngTemplateOutlet="feststellungen"></ng-container>
</app-side-panel>

<!--bottom sheet-->
<app-bottom-sheet *ngIf="(viewport$ | async) !== viewport.Desktop"
                  [title]="(name + '.feststellungen' | translate) + '&nbsp;(' + positionen.controls.length + ')&nbsp;'"
                  [value]="(sums$ | async)"
                  [seamless]="true">
  <ng-container *ngTemplateOutlet="feststellungen"></ng-container>
</app-bottom-sheet>

<!--notizen button-->
<app-produkt-detail-notizen-button [notizen]="notizen$ | async" (notizenChange)="onNotizenChange($event)">
</app-produkt-detail-notizen-button>

<!--Templates-->
<ng-template #feststellungen let-data="data">

  <!--header-->
  <div class="header">
    <div class="group-header">
      <label>{{(name + '.' + 'obergruppe') | translate}}</label>
      <label>{{(name + '.' + 'untergruppe') | translate}}</label>
    </div>
    <ng-container *ngFor="let field of positionenFields;trackBy:trackByField">
      <ng-container [ngSwitch]="field.name || field.groupName">

        <ng-container *ngSwitchCase="'beschaedigungart'">
          <div class="group-header">
            <label>{{"Beschädigungsart"}}</label>
            <label>{{"Reparaturweg"}}</label>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'bilder'">
          <div class="group-header">
            <label>{{"Bilder"}}</label>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'preis'">
          <div class="group-header">
            <label>{{(name + '.' + 'preis') | translate}}</label>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <!--header of expansion panel-->
  <div class="drop-list" cdkDropList [class.mobile]="(viewport$ | async) !== viewport.Desktop"
       (cdkDropListDropped)="drop($event)">
    <mat-expansion-panel #panel="matExpansionPanel" cdkDrag hideToggle togglePosition="before"
                         *ngFor="let item of positionen.getRawValue() ;index as index; trackBy:trackByField">
      <mat-expansion-panel-header>
        <ng-container *ngFor="let field of positionenFields;trackBy:trackByField">
          <ng-container [ngSwitch]="field.name || field.groupName">

            <ng-container *ngSwitchCase="'obergruppe'">
              <div class="group-header">
                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{item.obergruppe}}</label>
                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{item.untergruppe}}</label>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'beschaedigungart'">
              <div class="group-header">
                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{item.beschaedigungart}}</label>
                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{item.reparaturweg ? item.reparaturweg: '-'}}</label>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'bilder'">
              <div class="group-header">
                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{(item.bilder.length == 0 ? 0 : item.bilder.length)}}</label>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'preis'">
              <div class="group-header align-right">
                <label [class.unbestaetigt]="!checkFieldBestaetigt(item)">{{(item.preis ? item.preis: '0') | number:'0.2-2'}} €</label>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
        <button class="button drag-button" type="button" mat-icon-button cdkDragHandle>
          <mat-icon>drag_indicator</mat-icon>
        </button>
      </mat-expansion-panel-header>

      <!--body of expansion panel-->
      <div class="button-group">
        <button class="button edit-button" type="button" mat-icon-button
                (click)="onRowOpenByIndex(index, item, panel, $event)">
          <mat-icon>edit</mat-icon>
        </button>
        <button class="button delete-button" type="button" mat-icon-button
                (click)="onRowRemoveByIndex(index, panel, $event)">
          <app-mat-icon icon="icon-trash" [newStyle]="true" class="new-style"></app-mat-icon>
        </button>
      </div>
    </mat-expansion-panel>
  </div>

  <!--footer-->
  <div class="footer">
    <label>{{(sums$ | async)}}</label>
  </div>
</ng-template>

<ng-template #kalkulationTemplate let-data="data">
  <ng-container *ngFor="let field of kalkulationFields;trackBy:trackByField">
    <ng-container [ngSwitch]="field.name">
      <ng-container *ngSwitchCase="'dossierId'">
        <app-input [disabled]="true" [form]="this.kalkulation" [name]="field.name" [displayName]="name + '.' + field.name" type="text">
        </app-input>
      </ng-container>
      <ng-container *ngSwitchCase="'bezugsdatum'">
        <app-date [form]="this.kalkulation" [name]="field.name" [displayName]="name + '.' + field.name" [readonly]="true"
                  [hasDatepicker]="false" [disabled]="true">
        </app-date>
      </ng-container>
      <ng-container *ngSwitchCase="'reparaturkostenNetto'">
        <ng-container *ngTemplateOutlet="textCopy, context: { field: field }">
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'reparaturkostenBrutto'">
        <ng-container *ngTemplateOutlet="textCopy, context: { field: field }">
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="download-kalkulation-container">
    <p>{{name + '.' + 'dokument' | translate}}</p>
    <app-button [disabled]="!this.produkt.kalkulation.dokument" class="download-button" (action)="downloadKalkulation()">
      <app-mat-icon icon="picture_as_pdf" color="primary"></app-mat-icon>
    </app-button>
  </div>
</ng-template>

<ng-template #untergruppe let-data="data">
  <ng-container *ngFor="let field of positionenFields;trackBy:trackByField">
    <ng-container [ngSwitch]="field.name">
      <ng-container *ngSwitchCase="'untergruppe'">
        <app-extendable-radio-list [form]="data.form" [name]="field.name" [displayName]="name + '.' + field.name"
                                   [values]="data.values" keys="4"></app-extendable-radio-list>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #textCopy let-field="field">
  <div class="text-copy-container">
    <app-input #userinput [disabled]="true" [form]="this.kalkulation" [name]="field.name" [displayName]="name + '.' + field.name"
               type="text" inputmode="text">
    </app-input>
    <app-button class="copy-button" (action)="copy(userinput.input.nativeElement.id)">
      <app-mat-icon icon="file_copy"></app-mat-icon>
    </app-button>
  </div>
</ng-template>

<ng-template #dialog let-data="data">
  <app-produkt-detail-schaden-position (disableTemplateButton)="changeTemplateButtonDisabled($event)" [form]="data.form" [name]="name" [fields]="data.fields" [produkt]="data.produkt">
  </app-produkt-detail-schaden-position>
</ng-template>

<!-- Confirm dialog -->
<ng-template #dialogKalkulationConfirm>
  <mat-label>{{ 'feststellungen.kalkulation.dialog.text' | translate}}</mat-label>
</ng-template>

<ng-template #spinner>
  <app-progress-spinner></app-progress-spinner>
</ng-template>
