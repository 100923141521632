<ng-container *ngFor="let field of fields;trackBy:trackByField">
    <ng-container [ngSwitch]="field.name">
        <ng-container *ngSwitchCase="'bezeichnung'">
            <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name"> </app-input>
        </ng-container>
        <ng-container *ngSwitchCase="'wert'">
          <app-input [form]="form" [name]="field.name" [displayName]="name + '.' + field.name" type="text" inputmode="numeric"
                      [onlyNumber]="true">
            <ng-container appSuffix>&nbsp;€</ng-container>
          </app-input>
        </ng-container>
    </ng-container>
</ng-container>
