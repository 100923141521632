import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TrackBy } from '@shared/helper/track-by';
import { Values } from '@shared/helper/values';
import { FormControlFocusService } from '@shared/service/form-controls/form-control-focus.service';
import { Subscription } from 'rxjs';
import { FormControlBase } from '../form-control-base.component';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent extends FormControlBase implements OnInit{
  private selectionChangeSubscription$: Subscription = undefined;

  public trackByKey = TrackBy.trackByKey;

  @Input()
  public values: Values;

  @Input()
  public valuePrefix = '';

  @Input()
  public hasEmptyField = true;

  @Input()
  public disabled = false;

  @ViewChild(MatSelect, { static: true })
  public select: MatSelect;

  @Output()
  public selectionChange = new EventEmitter<MatSelect>();

  public required = false;

  constructor(formControlFocusService: FormControlFocusService) {
    super(formControlFocusService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.setRequired();
  }

  public blur(): void {
    if (this.selectionChangeSubscription$) {
      this.selectionChangeSubscription$.unsubscribe();
      this.selectionChangeSubscription$ = undefined;
    }
  }

  public focus(): void {
    this.select.focus();
    this.select.open();
    if (this.selectionChangeSubscription$ === undefined) {
      this.selectionChangeSubscription$ = this.select.openedChange.subscribe((value: boolean) => {
        if (!value) {
          this.next();
        }
      });
    }
  }

  private setRequired(): void {
    if (!this.form || !this.control) {
      return;
    }

    const validator = this.control?.validator?.({} as AbstractControl);
    if (validator && validator.required) {
      this.required = true;
    }
  }
}
