import { ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';

@Component({
  selector: 'app-produkt-detail-unterlagen-bilder',
  templateUrl: './produkt-detail-unterlagen-bilder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailUnterlagenBilderComponent {

  @Input()
  public form: FormGroup;

  @Input()
  public name: string;

  constructor(private readonly fileFieldService: ProduktDetailFileFieldService
  ) {
    Assert.notNullOrUndefined(fileFieldService, 'fileFieldService');
  }

  public onFileAdd(field: FormControl, files: FileData<ArrayBuffer>[]): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(files, 'files');
    this.fileFieldService.add(field, files);
  }

  public onFileDelete(field: FormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.remove(field, fileId);
  }

  public onFileUpdate(field: FormControl, event: FileGalleryUpdateEvent): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(event, 'event');
    this.fileFieldService.update(field, event);
  }

  public onFileReset(field: FormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.reset(field, fileId);
  }

  public isFileResetable(fileId: string): boolean {
    Assert.notNullOrEmpty(fileId, 'fileId');
    return this.fileFieldService.isUpdateable(fileId);
  }
}
