import { Pipe, PipeTransform } from '@angular/core';
import { Adresse } from '@data/domain/schema/type';
import { ViewFormGroup } from '@shared/helper/form-controls/view-form-group';

@Pipe({
    name: 'produktDetailAdressenOrder',
    pure: false
})
export class ProduktDetailAdressenOrderPipe implements PipeTransform {
    public transform(adressen: ViewFormGroup[]): ViewFormGroup[] {
        return adressen.sort((
            a: {
                value: Adresse;
            }, b: {
                value: Adresse;
            }) => +b.value.createdAt - +a.value.createdAt);
    }
}
