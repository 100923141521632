import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

export interface AbschlussRechnungsArtPostResponse {
    message?: string;
}

export enum AbschlussRechnungsArt {
    Bvws = 'BvwsInvoiceUpload',
    Manual = 'FrontendInvoiceUpload',
    Without = 'NoInvoice',
    Aborted = 'Aborted',
}

@Injectable({
    providedIn: 'root'
})
export class AbschlussRechnungsService {
    private readonly restApiUrl: string;

    constructor(
        private readonly httpClient: HttpClient) {
        Assert.notNullOrUndefined(httpClient, 'httpClient');
        this.restApiUrl = `${environment.aws.apiGateway.url}/abschluss/rechnungsart`;
    }

    public post(produktId: string, type: AbschlussRechnungsArt): Observable<AbschlussRechnungsArtPostResponse> {
        Assert.notNullOrEmpty(produktId, 'produktId');
        Assert.notNullOrEmpty(type, 'type');

        return this.httpClient.post(this.restApiUrl, {
            produktId,
            type
        });
    }
}
