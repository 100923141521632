import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyDialogComponent } from '@shared/component/popup-modal/privacy-dialog/privacy-dialog.component';
import { Assert } from '@shared/helper/assert';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PrivacyDialogService {
    constructor(private readonly matDialog: MatDialog) {
        Assert.notNullOrUndefined(matDialog, 'matDialog');
    }

    public open(): Observable<void> {
        return this.matDialog.open(PrivacyDialogComponent, {
            maxWidth: '95vw',
            maxHeight: '95vh',
            width: '600px',
            disableClose: true,
            hasBackdrop: true
        }).afterClosed();
    }
}
