import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService, AuthUserClaims } from '@app/service/auth.service';
import { BenutzerEinstellungen, BueroEinstellungen, EinstellungenService } from '@data/api-gateway/service/einstellungen.service';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-einstellungen',
  templateUrl: './einstellungen.component.html',
  styleUrls: ['./einstellungen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EinstellungenComponent implements OnInit {
  public claims$: Observable<AuthUserClaims>;
  public saving$ = new BehaviorSubject(false);
  public loggingOut = false;

  constructor(
    private readonly auth: AuthService,
    private readonly einstellungenService: EinstellungenService,
    private readonly snackBarService: SnackBarService) {
    Assert.notNullOrUndefined(auth, 'auth');
    Assert.notNullOrUndefined(einstellungenService, 'einstellungenService');
    Assert.notNullOrUndefined(snackBarService, 'snackBarService');
  }

  public ngOnInit(): void {
    this.claims$ = this.auth.getClaims();
  }

  public onBueroSave(einstellungen: BueroEinstellungen): void {
    this.saving$.next(true);
    this.einstellungenService.putBuero(einstellungen).pipe(
      finalize(() => this.saving$.next(false))
    ).subscribe(
      () => this.snackBarService.info('einstellungen.buero.putBueroEinstellungen.success'),
      error => this.snackBarService.error('einstellungen.buero.putBueroEinstellungen.error', error)
    );
  }

  public onBenutzerSave(einstellungen: BenutzerEinstellungen): void {
    einstellungen = this.trimCustomTexts(einstellungen);
    this.saving$.next(true);
    this.einstellungenService.putBenutzer(einstellungen).pipe(
      finalize(() => this.saving$.next(false))
    ).subscribe(
      () => this.snackBarService.info('einstellungen.benutzer.putBenutzerEinstellungen.success'),
      error => this.snackBarService.error('einstellungen.benutzer.putBenutzerEinstellungen.error', error)
    );
  }

  public onLogoutClick(): void {
    this.loggingOut = true;
    this.auth.startSignout().subscribe(() => {
      this.loggingOut = false;
    });
  }

  private trimCustomTexts(einstellungen: BenutzerEinstellungen): BenutzerEinstellungen {
    const einstellungenTrimmed = einstellungen;
    einstellungenTrimmed.customTextBewertung = einstellungen.customTextBewertung.trim();
    einstellungenTrimmed.customTextRuecknahmebewertung = einstellungen.customTextRuecknahmebewertung.trim();
    einstellungenTrimmed.customTextZustandsbericht = einstellungen.customTextZustandsbericht.trim();
    einstellungenTrimmed.customDescriptionBewertung = einstellungen.customDescriptionBewertung.trim();
    einstellungenTrimmed.customDescriptionRuecknahmebewertung = einstellungen.customDescriptionRuecknahmebewertung.trim();
    einstellungenTrimmed.customDescriptionZustandsbericht = einstellungen.customDescriptionZustandsbericht.trim();

    return einstellungenTrimmed;
  }
}
