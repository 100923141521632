import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ProduktArt } from '@data/domain/schema/enum';
import { Textbaustein } from '@data/domain/schema/type';
import { TextbausteineService } from '@data/domain/service/textbausteine.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-textbausteine-dialog',
  templateUrl: './textbausteine-dialog.component.html',
  styleUrls: ['./textbausteine-dialog.component.scss']
})
export class TextbausteineDialogComponent implements OnInit {

  @Input()
  public feature = '';

  @Input()
  public feld = '';

  @Input()
  public feldDisplayName = '';

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public textbausteine$ = new BehaviorSubject<Textbaustein[]>([]);

  @Input()
  public produktArt: ProduktArt = undefined;

  @Output()
  public textbausteinEventEmitter = new EventEmitter<Textbaustein>();

  constructor(
    private readonly textbausteineService: TextbausteineService) {
  }

  public ngOnInit(): void {
    this.getTextbausteine(this.feature, this.feld, this.produktArt);
  }


  public getTextbausteine(feature, feld, produktArt): Textbaustein[] {
    this.textbausteineService.getTextbausteine(feature, feld).pipe(take(1)).subscribe({
      next: res => {
        const sortedList = this.textbausteineService.sortByErstelltAmAscending(res).filter(
            value => this.produktArt ? value.produkte.includes(produktArt) : true
        );
        this.textbausteine$.next(sortedList);
        return sortedList;
      },
      error: error => {
        console.error(error);
      }
    });
    return [];
  }

}
