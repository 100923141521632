import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Assert } from '@shared/helper/assert';
import { SnackBarService } from '@shared/service/snack-bar.service';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ProduktConfig } from '../config/produkt-config';
import { ProduktConfigResolveService } from '../service/produkt-config-resolve.service';


@Injectable({
    providedIn: 'root'
})
export class ProduktConfigResolve  {
    constructor(
        private readonly produktConfigResolveService: ProduktConfigResolveService,
        private readonly router: Router,
        private readonly location: Location,
        private readonly snackbar: SnackBarService) {
        Assert.notNullOrUndefined(produktConfigResolveService, 'produktConfigResolveService');
        Assert.notNullOrUndefined(router, 'router');
        Assert.notNullOrUndefined(location, 'location');
        Assert.notNullOrUndefined(snackbar, 'snackbar');
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<ProduktConfig> {
        Assert.notNullOrUndefined(route, 'route');
        const id = route.paramMap.get('id');
        return this.produktConfigResolveService.resolve(id).pipe(
            catchError((error) => {
                this.snackbar.warning('produkt.config.resolveError');
                this.router.navigateByUrl(this.location.path());
                console.error(`ProduktId: ${id}`, error);
                return EMPTY;
            })
        );
    }
}
