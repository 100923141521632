import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TrackBy } from '@shared/helper/track-by';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideToggleComponent {
  public trackByKey = TrackBy.trackByKey;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public displayName: string;

  @Input()
  public valuePrefix = '';

  @Input()
  public disabled = false;

  @Output()
  public change = new EventEmitter<boolean>();
}
