import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AdresseAnrede } from '@data/domain/schema/enum';
import { PRODUKT_ADRESSEN_ARTEN } from '@modules/produkt/config/produkt-adressen-arten.config';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { EnumValues } from '@shared/helper/values';

@Component({
  selector: 'app-produkt-detail-adressen-adresse',
  templateUrl: './produkt-detail-adressen-adresse.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailAdressenAdresseComponent {
  public trackByField = TrackBy.trackByField;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public fields: FeatureFields;

  public anrede = new EnumValues(AdresseAnrede);
  public arten = PRODUKT_ADRESSEN_ARTEN;
}
