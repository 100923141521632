import { Injectable } from '@angular/core';
import { AwsAppSyncClientProvider } from '@app/provider/aws-app-sync-client.provider';
import { GraphQLResponse, saveVtiAuftrag, SaveVtiAuftragData } from '../../graphql/mutations';
import { Produkt, VtiAuftrag, VtiAuftragInput } from '../../schema/type';
import { ProduktService } from '../produkt.service';
import { ProduktFeatureService } from './produkt-feature.service';

@Injectable({
    providedIn: 'root'
})
export class ProduktVtiAuftragService extends ProduktFeatureService<VtiAuftrag, VtiAuftragInput> {
    constructor(awsAppSyncClientProvider: AwsAppSyncClientProvider, produktService: ProduktService) {
        super(awsAppSyncClientProvider, produktService);
    }

    protected mapSaveInput(produktId: string, feature: VtiAuftrag): VtiAuftragInput {
        const input: VtiAuftragInput = {
            ...feature,
            id: produktId,
        };
        return input;
    }

    protected getSaveMutation(): any {
        return saveVtiAuftrag;
    }

    protected getSaveVariables(input: VtiAuftragInput): any {
        return {
            vtiAuftrag: input
        };
    }

    protected getSaveOptimisticResponse(input: VtiAuftragInput): any {
        return {
            saveVtiAuftrag: {
                ...input,
                __typename: 'VtiAuftrag',
            }
        };
    }

    protected getSaveResponse(response: GraphQLResponse<SaveVtiAuftragData>): VtiAuftrag {
        return response.data.saveVtiAuftrag;
    }

    protected update(produkt: Produkt, feature: VtiAuftrag): void {
        produkt.vtiAuftrag = {
            ...produkt?.vtiAuftrag,
            ...feature,
        };
    }
}
