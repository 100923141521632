import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileData } from '@app/class/file-data';
import { ProduktArt } from '@data/domain/schema/enum';
import { FeatureFields } from '@modules/produkt/config/produkt-config';
import { TrackBy } from '@modules/produkt/helper/track-by';
import { ProduktDetailFileFieldService } from '@modules/produkt/service/produkt-detail-file-field.service';
import { FileGalleryUpdateEvent } from '@shared/component/layout/file-gallery/file-gallery.component';
import { Assert } from '@shared/helper/assert';

@Component({
  selector: 'app-produkt-detail-uebersicht-sonstiges',
  templateUrl: './produkt-detail-uebersicht-sonstiges.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProduktDetailUebersichtSonstigesComponent {

  public trackByField = TrackBy.trackByField;

  @Input()
  public form: UntypedFormGroup;

  @Input()
  public name: string;

  @Input()
  public fields: FeatureFields;

  @Input()
  public produktArt: ProduktArt;


  constructor(private readonly fileFieldService: ProduktDetailFileFieldService) {
    Assert.notNullOrUndefined(fileFieldService, 'fileFieldService');
  }

  public onFileAdd(field: UntypedFormControl, files: FileData<ArrayBuffer>[]): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(files, 'files');
    this.fileFieldService.add(field, files);
  }

  public onFileDelete(field: UntypedFormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.remove(field, fileId);
  }

  public onFileUpdate(field: UntypedFormControl, event: FileGalleryUpdateEvent): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrUndefined(event, 'event');
    this.fileFieldService.update(field, event);
  }

  public onFileReset(field: UntypedFormControl, fileId: string): void {
    Assert.notNullOrUndefined(field, 'field');
    Assert.notNullOrEmpty(fileId, 'fileId');
    this.fileFieldService.reset(field, fileId);
  }

  public isFileResetable(fileId: string): boolean {
    Assert.notNullOrEmpty(fileId, 'fileId');
    return this.fileFieldService.isUpdateable(fileId);
  }
}
